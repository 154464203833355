import { Avatar } from "@/src/components/account/Avatar";
import { AnimatedModal } from "@/src/components/AnimatedModal";
import { CloseIcon } from "@/src/components/CloseIcon";
import Loading from "@/src/components/Loading";
import { Button } from "@/src/components/tailwind/Button";
import Dropdown, { Option } from "@/src/components/tailwind/Dropdown";
import { toasts } from "@/src/components/toasts/toasts";
import {
  AccessControlEntityType,
  AccessControlPermissionLevel,
  AccessControlResourceType,
  AccessControlAddMoreQuery,
  useCreateOrUpdateAccessControlsMutation,
  useAccessControlAddMoreQuery,
  AccessControlDealGroupFragment,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { classNames } from "@/src/utils/cn";
import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";

interface AddMoreModalProps {
  open: boolean;
  onClose: () => void;

  dealId: string;
  resourceId: string;
  access: AccessControlDealGroupFragment[];
  options: Option[];
  resourceType: AccessControlResourceType;
}

export function AddMoreModal(props: AddMoreModalProps) {
  const { open, dealId, resourceId, access, options, resourceType } = props;

  const client = useGqlClient();
  const addMoreQuery = useAccessControlAddMoreQuery(client, {
    dealId,
  });

  function onClose() {
    props.onClose();
  }

  return (
    <AnimatedModal open={open} onClose={onClose} size="lg" padding="0">
      <div className="flex text-md justify-between items-center p-4">
        <p className="font-semibold text-gray-700">
          Add more people to question list
        </p>
        <CloseIcon onClose={onClose} />
      </div>
      <AddMoreModalContent
        access={access}
        addMoreQuery={addMoreQuery}
        resourceId={resourceId}
        dealId={dealId}
        onClose={onClose}
        options={options}
        resourceType={resourceType}
      />
    </AnimatedModal>
  );
}

interface SelectedGroup {
  id: string;
  permissionLevel: AccessControlPermissionLevel;
}

function AddMoreModalContent(props: {
  access: AccessControlDealGroupFragment[];
  addMoreQuery: UseQueryResult<AccessControlAddMoreQuery, unknown>;
  resourceId: string;
  resourceType: AccessControlResourceType;
  dealId: string;
  onClose: () => void;
  options: Option[];
}) {
  const { addMoreQuery, dealId, onClose } = props;
  const [selectedGroups, setSelectedGroups] = useState<SelectedGroup[]>([]);
  const client = useGqlClient();
  const createOrUpdateAccessControlsMutation =
    useCreateOrUpdateAccessControlsMutation(client);

  if (addMoreQuery.error) {
    return <div>Error loading guest groups</div>;
  }

  if (addMoreQuery.isLoading || !addMoreQuery.data) {
    return <Loading />;
  }

  const groups = addMoreQuery.data.deal.guestGroups.filter(
    (group) => !props.access.some((g) => g.dealGroup.id === group.id),
  );

  return (
    <div className="">
      <div className="space-y-4">
        {groups.map((group) => {
          return (
            <DealGroupItem
              key={group.id}
              group={group}
              selectedGroups={selectedGroups}
              setSelectedGroups={setSelectedGroups}
              options={props.options}
            />
          );
        })}
      </div>

      <div className="flex justify-end p-4 mt-3">
        <Button
          variant="positive"
          text="Add groups"
          isDisabled={
            selectedGroups.length === 0 ||
            createOrUpdateAccessControlsMutation.isPending
          }
          isLoading={createOrUpdateAccessControlsMutation.isPending}
          loadingText="Adding groups..."
          onClick={() => {
            if (
              selectedGroups.length === 0 ||
              createOrUpdateAccessControlsMutation.isPending
            ) {
              return;
            }

            console.log(selectedGroups);
            createOrUpdateAccessControlsMutation.mutate(
              {
                input: {
                  accessControls: selectedGroups.map((group) => ({
                    dealID: props.dealId,
                    entityID: group.id,
                    entityType: AccessControlEntityType.DealGroup,
                    permissionLevel: group.permissionLevel,
                    resourceID: props.resourceId,
                    resourceType: props.resourceType,
                  })),
                },
              },
              {
                onError: () => {
                  toasts.error("Failed to add groups");
                },
                onSuccess: () => {
                  toasts.success("Groups added");
                  props.onClose();
                },
              },
            );
          }}
        />
      </div>
    </div>
  );
}

function DealGroupItem(props: {
  options: Option[];
  setSelectedGroups: (groups: SelectedGroup[]) => void;
  selectedGroups: SelectedGroup[];
  group: AccessControlAddMoreQuery["deal"]["guestGroups"][0];
}) {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    undefined,
  );

  const isSelected = props.selectedGroups.some((g) => g.id === props.group.id);

  useEffect(() => {
    if (!selectedOption) {
      props.setSelectedGroups(
        props.selectedGroups.filter((g) => g.id !== props.group.id),
      );
      return;
    }

    props.setSelectedGroups(
      props.selectedGroups.filter((g) => g.id !== props.group.id),
    );

    props.setSelectedGroups([
      ...props.selectedGroups,
      {
        id: props.group.id,
        permissionLevel: selectedOption.value as AccessControlPermissionLevel,
      },
    ]);
  }, [selectedOption]);

  function toggle() {
    if (!isSelected) {
      setSelectedOption(ddqlPermissionOptions[0]);
    } else {
      setSelectedOption(undefined);
    }
  }

  return (
    <div
      key={props.group.id}
      className={classNames(
        "flex items-center justify-between hover:bg-gray-100 py-2 px-4",
        isSelected ? "opacity-100" : "opacity-60 hover:opacity-100",
      )}
      onClick={() => {
        toggle();
      }}
    >
      <div className="flex items-center gap-x-3 ">
        <input
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-persian-600 focus:ring-persian-500"
          checked={isSelected}
          onChange={(e) => {
            toggle();
          }}
        />
        <div>
          <p className="text-md font-semibold text-gray-700">
            {props.group.name}
            <span className="ml-1 text-sm text-gray-500/80 font-normal">
              ({props.group.accounts.length} people)
            </span>
          </p>
          <div className="flex -space-x-1">
            {props.group.accounts.map((account) => {
              return (
                <Avatar size="s" key={account.id} account={account.account} />
              );
            })}
          </div>
        </div>
      </div>
      <Dropdown
        hideSelectedOptionDescription
        selectedOption={selectedOption}
        options={props.options}
        onSelect={(option) => {
          setSelectedOption(option);
        }}
        size="s"
      />
    </div>
  );
}

export const ddqlPermissionOptions: Option[] = [
  {
    label: "View",
    value: AccessControlPermissionLevel.View,
    description: "Can view the questions",
  },
  {
    label: "Edit",
    value: AccessControlPermissionLevel.Edit,
    description: "Can view, edit, create and delete questions",
  },
  {
    label: "Answer",
    value: AccessControlPermissionLevel.Answer,
    description: "Can view and answer the questions",
  },
];
