import {
  DocumentPlusIcon,
  FolderPlusIcon,
  MagnifyingGlassIcon,
  TrashIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/20/solid";
import { FolderIcon as FolderIconOutline } from "@heroicons/react/24/outline";
import { H3, H4 } from "../Heading";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AnimatedModal } from "../AnimatedModal";
import {
  DataRoomFile,
  DataRoomFolderFragmentFragment,
  useDataRoomFolderQuery,
  DataRoomPermission,
  useCreateDataRoomFolderMutation,
  DataRoomFolderQuery,
  useSearchFilesQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { CloseIcon } from "../CloseIcon";
import { TextInput } from "../tailwind/TextInput";
import { Button } from "../tailwind/Button";
import { useQueryClient } from "@tanstack/react-query";
import { Combobox } from "@headlessui/react";
import { classNames } from "../../utils/cn";
import Loading from "../Loading";
import { FileIcon } from "../FileIcon";
import { useInvalidateQueryKeys } from "../../hooks/useInvalidateQueryKeys";
import { NewFilesUploader } from "./file_uploaders/NewFilesUploader";
import { Breadcrumbs } from "./breadcrumbs";
import { useUploadContext } from "./file_uploaders/useUploadContext";

// Function to get recent searches from local storage
function getRecentSearches(key: string) {
  const searches = localStorage.getItem(key);
  return searches ? (JSON.parse(searches) as DataRoomFile[]) : [];
}

// Function to save a new search to local storage
function saveSearch(newSearch: DataRoomFile, key: string) {
  const searches = getRecentSearches(key);
  // Add new search at the beginning of the array
  searches.unshift(newSearch);
  // Keep only the 3 most recent searches
  const recentSearches = searches.slice(0, 3);
  localStorage.setItem(key, JSON.stringify(recentSearches));
}

let shortcutHintTimeout: NodeJS.Timeout | null = null;

export function DataRoomHeader(props: {
  folderId: string;
  permissions: DataRoomPermission[];
  recentSearchesKey: string;
  dataRoomId: string;
  root: string;
  title?: string;
  selectedFolders?: string[];
  selectedFiles?: string[];
  setSelectedFolders?: (folders: string[]) => void;
  setSelectedFiles?: (files: string[]) => void;
  setTriggerMoveItems?: (trigger: boolean) => void;
  setTriggerDeleteItems?: (trigger: boolean) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const [openModal, setOpenModal] = useState<"search" | "file" | "folder" | "">(
    ""
  );

  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [metaPressed, setMetaPressed] = useState(false);

  const [specialKeyPressed, setSpecialKeyPressed] = useState(false);

  useEffect(() => {
    const keyPressed = ctrlPressed || metaPressed;

    if (keyPressed) {
      if (shortcutHintTimeout) {
        clearTimeout(shortcutHintTimeout);
      }
      shortcutHintTimeout = setTimeout(() => {
        setSpecialKeyPressed(true);
      }, 700);
    } else {
      setSpecialKeyPressed(false);
    }

    return () => {
      if (shortcutHintTimeout) {
        clearTimeout(shortcutHintTimeout);
      }
    };
  }, [ctrlPressed, metaPressed]);

  const { uploadFiles, uploadFolders, setUploadFiles, setUploadFolders } =
    useUploadContext();

  // Combined effect for blocking navigation and warning on page refresh/close
  useEffect(() => {
    const hasPendingUploads =
      uploadFiles.some(
        (file) => file.status !== "complete" && file.status !== "error"
      ) ||
      uploadFolders.some(
        (folder) => folder.status !== "complete" && folder.status !== "error"
      );

    if (!hasPendingUploads) return;

    const warningMessage =
      "You have unsaved upload progress. Are you sure you want to leave this page?";

    // Block navigation using react-router's history.block
    const unblock = history.block((location, action) => {
      if (window.confirm(warningMessage)) {
        unblock();
      } else {
        return false;
      }
    });

    // Warn before refresh or tab close
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = warningMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      unblock();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploadFiles, uploadFolders, history]);

  const [searchTerm, setSearchTerm] = useState("");

  const [recentSearches, setRecentSearches] = useState<DataRoomFile[]>([]);

  useEffect(() => {
    // Load recent searches from local storage on component mount
    const searches = getRecentSearches(props.recentSearchesKey);
    setRecentSearches(searches);
  }, []);

  // Example function to handle a new search
  // This should be called with the actual search result when a search is performed
  function handleNewSearch(searchResult: DataRoomFile) {
    saveSearch(searchResult, props.recentSearchesKey);
    // Optionally, update the state to immediately reflect the new search without reloading
    setRecentSearches(getRecentSearches(props.recentSearchesKey));
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    // Check if CMD+F on macOS or CTRL+F on Windows/Linux
    if (event.metaKey || event.ctrlKey) {
      if (!["d", "f", "s", "Meta", "Control"].includes(event.key)) {
        setSpecialKeyPressed(false);
        return;
      }

      if (event.metaKey) {
        setMetaPressed(true);
      }

      if (event.ctrlKey) {
        setCtrlPressed(true);
      }

      if (event.key === "d") {
        event.preventDefault();
        setOpenModal("folder");
        return;
      }

      if (event.key === "f") {
        event.preventDefault();
        setOpenModal("file");
        return;
      }

      if (event.key === "s") {
        event.preventDefault();
        setOpenModal("search");
      }
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === "Meta") {
      event.preventDefault();
      if (shortcutHintTimeout) {
        clearTimeout(shortcutHintTimeout);
      }
      setMetaPressed(false);
    }

    if (event.key === "Control") {
      event.preventDefault();
      if (shortcutHintTimeout) {
        clearTimeout(shortcutHintTimeout);
      }
      setCtrlPressed(false);
    }
  };

  function handleFocusOut() {
    setSpecialKeyPressed(false);
  }

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    window.addEventListener("blur", handleFocusOut);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("blur", handleFocusOut);
    };
  }, []);

  const client = useGqlClient();
  const location = useLocation();
  const { data, isLoading, error } = useDataRoomFolderQuery(client, {
    id: props.folderId,
  });

  return (
    <div className="shadow-sm">
      <div className="bg-white border-b border-gray-200 z-20 px-8 py-2 w-full ">
        <div className="flex items-center justify-between">
          <div>
            <H4 margin="0 0 0 0">{props.title || "Documents"}</H4>
          </div>
          <div className="w-1/3">
            <div
              ref={inputRef}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal("search");
                if (inputRef.current) {
                  inputRef.current.blur();
                }
              }}
              onFocus={() => {}}
              className="h-8 hidden xl:flex cursor-text items-center w-full border-0 bg-gray-100 rounded-md shadow-sm pl-2 pr-3 text-gray-900 placeholder:text-gray-400  sm:text-sm focus:outline-none"
            >
              <MagnifyingGlassIcon className="pointer-events-none mr-2 h-5 w-5 text-gray-400" />
              <p className="text-gray-500">Search...</p>
              {specialKeyPressed ? (
                <kbd className="pointer-events-none font-mono text-xs justify-center border border-gray-200 bg-white rounded-lg text-gray-500 ml-auto flex items-center px-2 py-1.5 font-semibold">
                  s
                </kbd>
              ) : null}
            </div>
          </div>
          <div className="w-48 flex justify-end">
            <ReadAccessOnly folderId={props.folderId}>
              <div className="xl:w-44 ml-1 w-20"></div>
            </ReadAccessOnly>
            {(props?.selectedFolders?.length !== 0 ||
              props?.selectedFiles?.length !== 0) && (
                <div className="flex items-center space-x-2 md:space-x-4 bg-gray-100 rounded-lg p-2">
                  <button 
                    className="hover:text-gray-700"
                  >
                    <CloseIcon onClose={() => {
                      if (props.setSelectedFolders) props.setSelectedFolders([]);
                      if (props.setSelectedFiles) props.setSelectedFiles([]);
                    }} />
                  </button>
                  <div className="hidden md:flex md:space-x-2">
                    <span className="text-sm">
                      {(props?.selectedFolders?.length ?? 0) + (props?.selectedFiles?.length ?? 0) } selected
                    </span>
                  </div>
                  <span className="md:hidden text-sm">
                    {(props?.selectedFolders?.length ?? 0) + (props?.selectedFiles?.length ?? 0) }
                  </span>
                  <ArrowRightCircleIcon onClick={() => {
                    if (props.setTriggerMoveItems) props.setTriggerMoveItems(true);
                  }} className="h-5 w-5 cursor-pointer" />
                  <TrashIcon onClick={() => {
                    if (props.setTriggerDeleteItems) props.setTriggerDeleteItems(true);
                  }} className="h-5 w-5 cursor-pointer" />
                </div>
              )}
            {(props?.selectedFolders?.length === 0 &&
              props?.selectedFiles?.length === 0) && (
                <WriteAccessOnly folderId={props.folderId}>
                  <div className="space-x-2 flex items-center">
                    <div className="hidden xl:block">
                      <Button
                        icon={FolderPlusIcon}
                        text="Folder"
                        variant="neutral"
                        size="s"
                        keyboardShortcut={specialKeyPressed ? "d" : undefined}
                        onClick={() => {
                          setOpenModal("folder");
                        }}
                      />
                    </div>
                    <div className="block xl:hidden">
                      <Button
                        icon={FolderPlusIcon}
                        text=""
                        variant="neutral"
                        size="s"
                        keyboardShortcut={specialKeyPressed ? "d" : undefined}
                        onClick={() => {
                          setOpenModal("folder");
                        }}
                      />
                    </div>

                    <div className="hidden xl:block">
                      <div className="relative">
                        <Button
                          icon={DocumentPlusIcon}
                          text="Documents"
                          size="s"
                          variant="positive"
                          keyboardShortcut={specialKeyPressed ? "f" : undefined}
                          onClick={() => {
                            setOpenModal("file");
                          }}
                        />
                        {uploadFiles.length > 0 || uploadFolders.length > 0 ? (
                          <span className="absolute top-[-5px] right-[-5px] inline-flex items-center justify-center w-4 h-4 text-xs font-semibold text-white bg-red-500 rounded-full">
                            {uploadFiles.length + uploadFolders.length}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="block xl:hidden">
                      <Button
                        icon={DocumentPlusIcon}
                        text=""
                        size="s"
                        variant="positive"
                        keyboardShortcut={specialKeyPressed ? "f" : undefined}
                        onClick={() => {
                          setOpenModal("file");
                        }}
                      />
                    </div>
                  </div>
                </WriteAccessOnly>
              )}
          </div>
        </div>
        <WriteAccessOnly folderId={props.folderId}>
          <AnimatedModal
            size="md"
            open={openModal === "folder"}
            onClose={() => {
              setOpenModal("");
            }}
          >
            <NewFolder
              data={data}
              permissions={props.permissions}
              isLoading={isLoading}
              error={error}
              onClose={() => setOpenModal("")}
            />
          </AnimatedModal>
          <NewFilesUploader
            type={
              location.pathname.startsWith("/drive")
                ? "single-step"
                : "multi-step"
            }
            open={openModal === "file"}
            onClose={() => setOpenModal("")}
            permissions={props.permissions}
            data={data}
            isLoading={isLoading}
            error={error}
          />
        </WriteAccessOnly>
        <AnimatedModal
          open={openModal === "search"}
          onClose={() => {
            setOpenModal("");
          }}
          position="top"
          size="xl"
          padding="p-0"
        >
          <Combobox
            onChange={(v: any) => {
              if (v.shortcut) {
                return;
              }

              handleNewSearch(v);
              history.push(
                `/${props.root}/documents/folder/${props.folderId}/file/${v.id}`
              );
            }}
          >
            <div className="flex flex-col h-full ">
              <div className="relative">
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <Combobox.Input
                  className="h-12 w-full border-b select-none border-gray-100 rounded-t-md focus:outline-none focus:select-none focus:border-0 focus:border-b focus:border-gray-100 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  onChange={(event) => {
                    setSearchTerm(event.currentTarget.value);
                  }}
                />
              </div>
              <div className="flex-1">
                <SearchResults
                  searchTerm={searchTerm}
                  folderId={props.folderId}
                  recentSearches={recentSearches}
                  dataRoomId={props.dataRoomId}
                  setOpenModal={setOpenModal}
                />
              </div>
            </div>
          </Combobox>
        </AnimatedModal>
      </div>
      <div className="bg-white border-b border-gray-200 z-20 px-8 w-full ">
        <Breadcrumbs
          folders={data ? data.dataRoomFolder.parentFolders : []}
          isLoading={isLoading}
          root={props.root}
        />
      </div>
    </div>
  );
}

function NewFolder(props: {
  data: DataRoomFolderQuery | undefined;
  isLoading: boolean;
  error?: unknown;
  onClose: () => void;
  permissions: DataRoomPermission[];
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const createFolder = useCreateDataRoomFolderMutation(client);

  const createButtonRef = useRef<HTMLButtonElement>(null);
  const [folderName, setFolderName] = useState("");
  const [nameError, setNameError] = useState("");

  const { invalidateDealActivities } = useInvalidateQueryKeys();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey || event.ctrlKey) {
      if (event.key === "Enter") {
        event.preventDefault();
        if (createButtonRef.current) {
          createButtonRef.current.click();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [folders, setFolders] = useState<
    DataRoomFolderFragmentFragment["parentFolders"]
  >([]);

  useEffect(() => {
    if (props.isLoading || props.error || !props.data) {
      return;
    }

    setFolders(props.data.dataRoomFolder.parentFolders);
  }, [props.data, props.isLoading, props.error]);

  const crumbs = [
    "Home",
    ...folders
      .slice(0, folders.length - 1)
      .map((f) => f.name)
      .reverse(),
  ];

  if (props.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <H3>New folder</H3>
          <p className="text-sm text-gray-500 leading-none">
            In {`${crumbs.join(" / ")}`}
          </p>
        </div>
        <CloseIcon onClose={props.onClose} />
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!props.data) {
            return;
          }

          if (!folderName) {
            setNameError("Name is required");
            return;
          }

          createFolder.mutate(
            {
              dataRoomFolderID: props.data.dataRoomFolder.id,
              name: folderName,
            },
            {
              onSuccess: (data) => {
                queryClient.invalidateQueries({
                  queryKey: [
                    "DataRoomFolder",
                    { id: `${props.data?.dataRoomFolder.id}` },
                  ],
                });

                invalidateDealActivities();

                setFolderName("");

                props.onClose();
              },
            }
          );
        }}
      >
        <div className="mt-4">
          <TextInput
            name="folder name"
            id="folder_name"
            error={nameError}
            placeholder="The name of your new folder..."
            label="Name"
            value={folderName}
            onChange={(e) => {
              setNameError("");
              setFolderName(e.currentTarget.value);
            }}
          />

          <div className="mt-12 flex justify-end">
            <Button
              ref={createButtonRef}
              type="submit"
              text="Create folder"
              variant="positive"
              isLoading={createFolder.isPending}
              isDisabled={folderName === ""}
              loadingText="Creating folder..."
            />
          </div>
        </div>
      </form>
    </div>
  );
}

interface SearchResultsProps {
  folderId: string;
  searchTerm: string;
  recentSearches: DataRoomFile[];
  dataRoomId: string;
  setOpenModal: (modal: "search" | "file" | "folder" | "") => void;
}

let searchTimeout: NodeJS.Timeout | null = null;

const quickActions = [
  { name: "Add new file...", icon: DocumentPlusIcon, shortcut: "f", url: "#" },
  { name: "Add new folder...", icon: FolderPlusIcon, shortcut: "d", url: "#" },
];

function SearchResults(props: SearchResultsProps) {
  const history = useHistory();
  const [files, setFiles] = useState<DataRoomFile[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const client = useGqlClient();
  const searchQuery = useSearchFilesQuery(
    client,
    {
      query: searchTerm,
      dataRoomID: props.dataRoomId,
    },
    {
      enabled: searchTerm.length > 2,
    }
  );

  useEffect(() => {
    const term = props.searchTerm;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setSearchTerm(term);
    }, 1000);
  }, [props.searchTerm]);

  useEffect(() => {
    if (searchTerm.length < 2) {
      setFiles([]);
      return;
    }

    if (!searchQuery.data) {
      return;
    }

    setFiles(searchQuery.data.searchFiles as DataRoomFile[]);
  }, [searchTerm, searchQuery.data]);

  if (searchQuery.isError) {
    return <div>Something went wrong</div>;
  }

  if (searchQuery.isLoading) {
    return (
      <div className="h-full  justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (searchTerm === "") {
    return (
      <Combobox.Options static className="max-h-80 scroll-py-2 overflow-y-auto">
        <h2 className="mb-2 mt-4 px-6 text-xs font-semibold text-gray-500">
          Recent searches
        </h2>
        {props.recentSearches.length === 0 ? (
          <p className="text-sm px-6 text-gray-400">No recent searches</p>
        ) : (
          <div className="px-2">
            {props.recentSearches.map((file, i) => {
              return (
                <Combobox.Option
                  key={i}
                  value={file}
                  className={({ active }) =>
                    classNames(
                      "flex cursor-default select-none items-center rounded-md px-3 py-2",
                      active ? "bg-gray-700 text-white" : ""
                    )
                  }
                >
                  {({ active }) => (
                    <>
                      <div className="bg-white rounded-full h-7 w-7 justify-center items-center flex p-1">
                        <FileIcon fileType={file.fileType} />
                      </div>
                      <span className="ml-3 flex-auto truncate text-sm">
                        {file.parentFolders
                          .map((pf, i) => {
                            return `${
                              pf.name === "" ? "Home" : pf.name
                            }${" / "}`;
                          })
                          .reverse()}
                        <span className="font-bold">{file.name}</span>
                      </span>
                      {active && (
                        <span
                          className="ml-3 flex-none text-gray-100 text-sm cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.setOpenModal("");
                            setTimeout(() => {
                              history.push(
                                `/deal/documents/folder/${file.folderId}/file/${file.id}`
                              );
                            }, 10);
                          }}
                        >
                          Jump to...
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              );
            })}
          </div>
        )}
        <WriteAccessOnly folderId={props.folderId}>
          <div className="w-full h-px mt-4 bg-gray-100"></div>
        </WriteAccessOnly>

        <WriteAccessOnly folderId={props.folderId}>
          <li className="p-2">
            <h2 className="sr-only">Quick actions</h2>
            <ul className="text-sm text-gray-700">
              {quickActions.map((action) => (
                <Combobox.Option
                  key={action.shortcut}
                  value={action}
                  className={({ active }) =>
                    classNames(
                      "flex cursor-default select-none items-center rounded-md px-3 py-2",
                      active ? "bg-gray-200" : ""
                    )
                  }
                >
                  {({ active }) => (
                    <>
                      <action.icon
                        className={classNames(
                          "h-6 w-6 flex-none",
                          "text-gray-400"
                        )}
                        aria-hidden="true"
                      />
                      <span className="ml-3 flex-auto truncate">
                        {action.name}
                      </span>
                      <span
                        className={classNames(
                          "ml-3 flex-none text-xs font-semibold",
                          "text-gray-400"
                        )}
                      >
                        <kbd className="font-sans">⌘</kbd>
                        <kbd className="font-sans">{action.shortcut}</kbd>
                      </span>
                    </>
                  )}
                </Combobox.Option>
              ))}
            </ul>
          </li>
        </WriteAccessOnly>
      </Combobox.Options>
    );
  }

  if (!searchQuery.data || files.length === 0) {
    return (
      <div className="flex py-8 h-full justify-center items-center flex-col">
        <FolderIconOutline className="w-6 h-6 text-gray-400" />
        <p className="text-gray-600 text-sm">
          We couldn't find any files with that search term
        </p>
      </div>
    );
  }

  return (
    <>
      <Combobox.Options
        static
        className="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto"
      >
        <li className="p-2">
          <ul
            className="text-sm text-gray-700"
            key={files.map((f) => f.id).join("-")}
          >
            {files.map((file) => (
              <Combobox.Option
                key={file.id}
                value={file}
                className={({ active }) =>
                  classNames(
                    "flex cursor-default select-none items-center rounded-md px-3 py-2",
                    active ? "bg-gray-700 text-white" : ""
                  )
                }
              >
                {({ active }) => (
                  <>
                    <div className="bg-white rounded-full h-7 w-7 justify-center items-center flex p-1">
                      <FileIcon fileType={file.fileType} />
                    </div>
                    <span className="ml-3 flex-auto truncate">
                      {file.parentFolders
                        .map((pf, i) => {
                          return `${pf.name === "" ? "Home" : pf.name}${" / "}`;
                        })
                        .reverse()}
                      <span className="font-bold">{file.name}</span>
                    </span>
                    {active && (
                      <span
                        className="ml-3 flex-none text-gray-100 text-sm cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setOpenModal("");
                          setTimeout(() => {
                            history.push(
                              `/deal/documents/folder/${file.folderId}/file/${file.id}`
                            );
                          }, 0);
                        }}
                      >
                        Jump to...
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </ul>
        </li>
      </Combobox.Options>
    </>
  );
}

function ReadAccessOnly(props: {
  children: React.ReactNode;
  folderId: string;
}) {
  const client = useGqlClient();
  const dataRoomFolderQuery = useDataRoomFolderQuery(client, {
    id: props.folderId,
  });

  if (
    dataRoomFolderQuery.isPending ||
    !dataRoomFolderQuery.data ||
    dataRoomFolderQuery.error
  ) {
    return null;
  }

  if (!dataRoomFolderQuery.data.dataRoomFolder.hasFolderWriteAccess) {
    return <>{props.children}</>;
  }

  return null;
}

function WriteAccessOnly(props: {
  children: React.ReactNode;
  folderId: string;
}) {
  const client = useGqlClient();
  const dataRoomFolderQuery = useDataRoomFolderQuery(client, {
    id: props.folderId,
  });

  if (
    dataRoomFolderQuery.isPending ||
    !dataRoomFolderQuery.data ||
    dataRoomFolderQuery.error
  ) {
    return null;
  }

  if (dataRoomFolderQuery.data.dataRoomFolder.hasFolderWriteAccess) {
    return <>{props.children}</>;
  }

  return null;
}

