import Loading from "@/src/components/Loading";
import {
  DealGroup,
  DealRole,
  useGuestAccessTabQuery,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { authSelectors } from "@/src/store/auth/selector";
import { useSelector } from "react-redux";
import { AccessList } from "./AcessList";
import { Button } from "@/src/components/tailwind/Button";
import { UserPlusIcon } from "lucide-react";
import { useState } from "react";
import { InviteMoreToGroup } from "../InviteMoreToGroup";
import { useQueryClient } from "@tanstack/react-query";

export function GuestAccessTab() {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const [openModal, setOpenModal] = useState("");
  const questAccessTabQuery = useGuestAccessTabQuery(client, {
    dealId: activeDealId ? activeDealId : "",
  });
  const activeDealAccount = useSelector(authSelectors.activeDealAccount);

  if (questAccessTabQuery.isLoading) {
    return <Loading />;
  }

  if (questAccessTabQuery.error) {
    return (
      <div className="flex-1 flex justify-center items-center">
        <p className="text-gray-500 font-semibold text-md">
          Something went wrong
        </p>
      </div>
    );
  }

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-gray-800 font-semibold">Access</p>
          <p className="text-gray-500 text-sm">
            As an admin you can invite more people to your group
          </p>
        </div>
        <Button
          text="Invite more people"
          icon={UserPlusIcon}
          variant="neutral"
          size="s"
          onClick={() => {
            setOpenModal("invite_more");
          }}
        />
      </div>
      <AccessList
        dealId={activeDealId ?? ""}
        group={questAccessTabQuery.data?.deal.activeDealAccount?.dealGroup}
        accountSearch=""
      />
      <InviteMoreToGroup
        dealId={activeDealId ?? ""}
        isOpen={openModal === "invite_more"}
        inviteeRole={
          activeDealAccount ? activeDealAccount.role : DealRole.GuestAdmin
        }
        onClose={() => {
          setOpenModal("");
          queryClient.invalidateQueries({
            queryKey: [
              "GuestAccessTab",
              {
                dealId: activeDealId ?? "",
              },
            ],
          });
        }}
        dealGroup={
          questAccessTabQuery.data?.deal.activeDealAccount
            ?.dealGroup as DealGroup
        }
      />
    </div>
  );
}
