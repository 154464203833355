import { AnimatedModal } from "@/src/components/AnimatedModal";
import { CloseIcon } from "@/src/components/CloseIcon";
import { Button } from "@/src/components/tailwind/Button";
import {
  AccessQuery,
  DealRole,
  useCreateDealInviteMutation,
} from "@/src/graphql/generated";
import { CheckCircleIcon, XIcon } from "lucide-react";
import { useRef, useState } from "react";
import {
  GranularAccess,
  granularAccessToFolderInput,
  granularAccessToFileInput,
} from ".";
import { classNames } from "@/src/utils/cn";
import { EditDataRoomPermissions } from "./EditDataRoomPermissions";
import useGqlClient from "@/src/hooks/useGqlClient";
import { toasts } from "@/src/components/toasts/toasts";
import { useQueryClient } from "@tanstack/react-query";

export function InviteGuestModal(props: {
  open: boolean;
  onClose: () => void;
  dealId: string;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
}) {
  const [selectedDataRoomPermission, setSelectedDataRoomPermission] = useState(
    props.permissions[0]
  );
  const [overrideDataRoomAccess, setOverrideDataRoomAccess] = useState(false);
  const [autoAdmit, setAutoAdmit] = useState(false);
  const [granularAccess, setGranularAccess] = useState<GranularAccess>({
    files: {},
    folders: {},
  });

  const client = useGqlClient();
  const queryClient = useQueryClient();
  const createDealInvite = useCreateDealInviteMutation(client);

  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [inviting, setInviting] = useState(false);
  const [failedInvites, setFailedInvites] = useState<string[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === " " || e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const email = inputValue.trim();

      // Basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(email)) {
        setEmails([...emails, email]);
        setInputValue("");
      } else if (email !== "") {
        setError("Please enter a valid email address");
      }
    }

    // Handle backspace to remove last pill
    if (e.key === "Backspace" && inputValue === "" && emails.length > 0) {
      setEmails(emails.slice(0, -1));
    }
  };

  const removeEmail = (indexToRemove: number) => {
    setEmails(emails.filter((_, index) => index !== indexToRemove));
  };

  function onClose() {
    setEmails([]);
    setInputValue("");
    props.onClose();
  }

  return (
    <AnimatedModal padding="p-0" size="xl" open={props.open} onClose={onClose}>
      <div>
        <div className="p-4 flex justify-between items-center">
          <div>
            <p className="font-semibold">Invite guests</p>
            <p className="text-sm text-gray-500">
              Invite individual guests to access this deal.
            </p>
          </div>
          <CloseIcon onClose={onClose} />
        </div>

        <div className="px-4">
          <div className="w-full xl:w-2/3">
            <p className="mt-4 text-sm font-medium leading-6 text-gray-900">
              Emails
            </p>
            <div
              className="mt-1 py-2 px-0.5 shadow-sm border-0  rounded-md ring-inset focus:ring-inset ring-gray-300 ring-1  focus-within:ring-2 focus-within:ring-gray-600 focus-within:border-gray-600 cursor-text"
              onClick={() => inputRef.current?.focus()}
            >
              <div className="flex flex-wrap">
                {emails.map((email, index) => (
                  <span
                    key={index}
                    className="inline-flex mx-1 my-1 items-center px-2.5 py-1 rounded-full text-sm bg-persian-100 text-persian-900"
                  >
                    {email}
                    <button
                      type="button"
                      className="ml-1 inline-flex bg-persian-200 p-0.5 rounded-full text-persian-400 hover:text-persian-600"
                      onClick={() => removeEmail(index)}
                    >
                      <XIcon className="w-3 h-3" />
                    </button>
                  </span>
                ))}
                <input
                  ref={inputRef}
                  type="text"
                  className="placeholder:text-gray-400 py-0.5 text-sm outline-none focus:outline-none focus:ring-0 focus:border-none border-none flex-1 min-w-[200px]"
                  placeholder={
                    emails.length === 0
                      ? `john@acme.com, jane@acme.com...`
                      : "Add more..."
                  }
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <p className="font-semibold text-gray-600 text-sm">Permissions</p>
            <p className="text-sm text-gray-500 leading-tight">
              Select a permission group or specify the specific files you want
              to grant access to
            </p>
            <div className="mt-3 flex gap-x-3">
              {props.permissions.map((permission) => {
                return (
                  <button
                    key={permission.id}
                    onClick={() => {
                      setSelectedDataRoomPermission(permission);
                    }}
                    className={classNames(
                      "p-2 rounded-md border-2 relative  group",
                      overrideDataRoomAccess
                        ? "border-gray-300 opacity-60"
                        : "",
                      !overrideDataRoomAccess &&
                        permission.id === selectedDataRoomPermission.id
                        ? "border-persian-500"
                        : "",
                      !overrideDataRoomAccess &&
                        permission.accessLevel <
                          selectedDataRoomPermission.accessLevel
                        ? "border-persian-500 opacity-60"
                        : "",
                      !overrideDataRoomAccess &&
                        permission.accessLevel >
                          selectedDataRoomPermission.accessLevel
                        ? "opacity-50 "
                        : ""
                    )}
                  >
                    <div className="flex items-start gap-x-2 justify-between">
                      <div className="text-left">
                        <p className="text-sm font-semibold text-gray-800">
                          {permission.name}
                        </p>
                        <p className="leading-tight text-xs text-gray-500">
                          {permission.description}
                        </p>
                        <p className="mt-1 text-xs text-gray-500">
                          {permission.fileCount} files
                        </p>
                      </div>
                      {(!overrideDataRoomAccess &&
                        permission.id === selectedDataRoomPermission.id) ||
                      permission.accessLevel <
                        selectedDataRoomPermission.accessLevel ? (
                        <CheckCircleIcon
                          className={`w-4 h-4 ${
                            overrideDataRoomAccess
                              ? "text-gray-500"
                              : "text-persian-500"
                          }`}
                        />
                      ) : null}
                    </div>
                  </button>
                );
              })}
            </div>
            <div className="mt-3 relative flex items-start">
              <div className="flex h-6 items-center mt-2">
                <input
                  id="granularAccess"
                  aria-describedby="autoAdmit-description"
                  name="autoAdmit"
                  type="checkbox"
                  checked={autoAdmit}
                  onChange={(e) => {
                    setAutoAdmit(e.currentTarget.checked);
                  }}
                  className="h-4 w-4 rounded border-gray-300 text-persian-600 focus:ring-persian-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label
                  htmlFor="autoAdmit"
                  className="font-medium text-gray-900 leading-tight cursor-pointer"
                >
                  Auto-admit the account
                </label>
                <p
                  id="autoAdmit-description"
                  className="text-gray-500 leading-tight"
                >
                  Automatically admit the account when they accept the invite
                </p>
              </div>
            </div>
            <EditDataRoomPermissions
              dealGroupId=""
              showPermissions={false}
              initialDataRoomPermission={props.permissions[0]}
              initialGranularAccess={{
                files: [],
                folders: [],
              }}
              dataRoomPermissions={props.permissions}
              overrideDataRoomAccess={overrideDataRoomAccess}
              onOverrideDataRoomAccessChange={(checked) => {
                setOverrideDataRoomAccess(checked);
              }}
              granularAccess={granularAccess}
              onGranularAccessChange={(granularAccess) => {
                setGranularAccess(granularAccess);
              }}
            />
          </div>
        </div>
        <div className="mt-12 justify-between items-center py-2 px-4 gap-x-2 rounded-b-lg border-t border-gray-200 bg-gray-100/90 flex">
          <p className="text-sm text-red-600">{error}</p>
          <div className="flex justify-end gap-x-2">
            <Button text="Cancel" variant="neutral" onClick={onClose} />
            <Button
              text={`Invite ${emails.length} guests`}
              variant="positive"
              isDisabled={emails.length === 0}
              isLoading={inviting}
              loadingText="Sending invites..."
              onClick={async () => {
                if (emails.length === 0) {
                  return;
                }

                setInviting(true);
                await Promise.all(
                  emails.map(async (email) => {
                    await createDealInvite.mutate(
                      {
                        input: {
                          email: email,
                          overrideDataRoomAccess: overrideDataRoomAccess,
                          autoAdmit: autoAdmit,
                          dealID: props.dealId,
                          dealGroupID: "",
                          filePermissions:
                            granularAccessToFileInput(granularAccess),
                          folderPermissions:
                            granularAccessToFolderInput(granularAccess),
                          dataRoomPermissionID: selectedDataRoomPermission.id,
                          message: "",
                          role: DealRole.Guest,
                        },
                      },
                      {
                        onError: () => {
                          setFailedInvites([...failedInvites, email]);
                        },
                      }
                    );
                  })
                );
                setInviting(false);
                if (failedInvites.length === 0) {
                  onClose();
                  queryClient.invalidateQueries({
                    queryKey: ["Access", { dealId: props.dealId }],
                  });
                  toasts.success("Invites sent successfully");
                } else {
                  setError(
                    `Failed to invite ${
                      failedInvites.length
                    } guests: ${failedInvites.join(", ")}`
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
    </AnimatedModal>
  );
}
