import {
  ArrowUpIcon,
  BotIcon,
  CogIcon,
  PlusIcon,
  ToggleLeftIcon,
  ToggleRightIcon,
} from "lucide-react";
import { classNames } from "@/src/utils/cn";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useOutsideClick } from "@/src/hooks/useOutsideClick";
import { CloseIcon } from "@/src/components/CloseIcon";
import { deepSearchSelectors } from "@/src/store/deep-search/selector";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "@/src/store";
import useGqlClient from "@/src/hooks/useGqlClient";
import {
  DataRoomFileQuery,
  DealThreadMessageRole,
  DealThreadMessageStatus,
  DeepSearchQuery,
  DeepSearchThreadMessage,
  DeepSearchVectorScope,
  useCreateDeepSearchFileMutation,
  useCreateDeepSearchThreadMessageMutation,
  useDataRoomFileQuery,
  useDeepSearchQuery,
  useDeepSearchThreadMessagesQuery,
  useUpdateDeepSearchMutation,
  useUpdateDeepSearchThreadMessageMutation,
} from "@/src/graphql/generated";
import { v4 as uuidv4 } from "uuid";
import { actions } from "@/src/store/deep-search/slice";
import { useQueryClient } from "@tanstack/react-query";
import { useTransition } from "react-spring";
import { AssistantMessage } from "./AssistantMessage";
import { FileIcon } from "@/src/components/FileIcon";
import { StatusDot } from "@/src/components/StatusDot";
import { TripleDotMenu } from "@/src/components/TripleDotMenu";
import { Menu } from "@headlessui/react";
import { Spinner } from "@/src/components/icons/Spinner";
import { gqlDeepSearchThreadMessageToWebsocketDeepSearchThreadMessage } from "@/src/utils/typeconv";
import { getUnixTime } from "date-fns";

interface ScrollContextType {
  triggerScroll: () => void;
}

export const ScrollContext = createContext<ScrollContextType>({
  triggerScroll: () => {},
});

export function Assistant(props: {
  deepSearchId: string;
  deepSearch: DeepSearchQuery["deepSearch"];
}) {
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const chatRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  useOutsideClick(chatRef, (event) => {
    const isClickedInModal =
      event.target instanceof Element &&
      event.target.closest('[role="dialog"]');

    if (!isClickedInModal) {
      setIsChatExpanded(false);
    }
  });

  const [message, setMessage] = useState("");

  const scrollToBottom = useCallback(() => {
    if (isChatExpanded) {
      scrollRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [isChatExpanded]);

  const deepSearchThreadMessages = useSelector((state: AppState) =>
    deepSearchSelectors.deepSearchThreadMessages(state, props.deepSearchId),
  );

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const deepSearchThreadMessagesQuery = useDeepSearchThreadMessagesQuery(
    client,
    {
      deepSearchId: props.deepSearchId,
    },
  );

  const updateDeepSearch = useUpdateDeepSearchMutation(client);

  const updateDeepSearchThreadMessage =
    useUpdateDeepSearchThreadMessageMutation(client);

  useEffect(() => {
    if (deepSearchThreadMessagesQuery.data) {
      dispatch(
        actions.setDeepSearchThreadMessages({
          deepSearchId: props.deepSearchId,
          messages:
            deepSearchThreadMessagesQuery.data.deepSearch.threadMessages.map(
              (m) => {
                return gqlDeepSearchThreadMessageToWebsocketDeepSearchThreadMessage(
                  m as DeepSearchThreadMessage,
                  props.deepSearchId,
                );
              },
            ),
        }),
      );
    }
  }, [deepSearchThreadMessagesQuery.data, dispatch, props.deepSearchId]);

  const createDeepSearchThreadMessage =
    useCreateDeepSearchThreadMessageMutation(client);

  const totalMessageLength = deepSearchThreadMessages?.reduce(
    (total, message) =>
      total + (message.deepSearchThreadMessage.content.text.value.length || 0),
    0,
  );

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalMessageLength, deepSearchThreadMessages.length]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  const transitions = useTransition(deepSearchThreadMessages, {
    from: { transform: "translate3d(0,40px,0)", opacity: 0 },
    enter: { transform: "translate3d(0,0px,0)", opacity: 1 },
    leave: { transform: "translate3d(0,-40px,0)", opacity: 0 },
    keys: (message) => (message as any).deepSearchThreadMessageID,
  });

  const deepSearchThreadMessageIndexMap = useMemo(() => {
    return deepSearchThreadMessages.reduce(
      (acc, message, index) => {
        if (!message.deepSearchThreadMessageID) {
          return acc;
        }
        acc[message.deepSearchThreadMessageID] = index;
        return acc;
      },
      {} as { [key: string]: number },
    );
  }, [deepSearchThreadMessages]);

  const lastMessage =
    deepSearchThreadMessages.length > 0
      ? deepSearchThreadMessages[deepSearchThreadMessages.length - 1]
      : null;

  return (
    <ScrollContext.Provider value={{ triggerScroll: scrollToBottom }}>
      <div
        ref={chatRef}
        className={classNames(
          "absolute transition-all duration-300 ease-in-out z-20",
          isChatExpanded
            ? "bottom-6 left-1/2 -translate-x-1/2 w-[90%] max-w-4xl h-[600px] bg-white rounded-lg shadow-2xl border border-gray-400"
            : "bottom-6 left-1/2 -translate-x-1/2 w-[90%] overflow-hidden max-w-md h-12 opacity-80 hover:opacity-100",
        )}
      >
        <div
          className={classNames(
            "transition-opacity duration-200 ",
            isChatExpanded
              ? "flex flex-col opacity-100 h-[calc(100%-60px)] "
              : "opacity-0 h-0",
          )}
        >
          <div className="w-full flex items-center justify-between bg-concrete-50 border-b border-concrete-200 px-3 py-2 rounded-t-lg">
            <div>
              <div className="flex items-center gap-2 justify-between">
                <div className="flex items-center gap-x-2">
                  <BotIcon className="h-5 w-5 text-gray-700" />
                  <div>
                    <div className="flex items-center gap-x-2">
                      <p className="font-semibold text-md text-gray-700">
                        Referencing Assistant
                      </p>
                    </div>
                    <p className="text-sm text-gray-500">
                      To increase accuracy, this assistant defaults to only
                      looking at documents that have been added
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <TripleDotMenu
              width="w-52"
              customButton={
                <Menu.Button className="p-1 bg-gray-200 border flex items-center gap-x-1 border-gray-300 hover:bg-gray-300 rounded-full shadow-sm">
                  <CogIcon className="h-4 w-4 text-gray-500" />
                  <p className="text-xs text-gray-500">Settings</p>
                </Menu.Button>
              }
            >
              <TripleDotMenu.Button
                onClick={() => {
                  if (updateDeepSearch.isPending) {
                    return;
                  }

                  updateDeepSearch.mutate(
                    {
                      input: {
                        id: props.deepSearch.id,
                        vectorScope:
                          props.deepSearch.vectorScope ===
                          DeepSearchVectorScope.Selected
                            ? DeepSearchVectorScope.Deal
                            : DeepSearchVectorScope.Selected,
                      },
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries({
                          queryKey: ["DeepSearch", { id: props.deepSearch.id }],
                        });
                      },
                    },
                  );
                }}
                text="Search all documents"
                icon={
                  updateDeepSearch.isPending ? (
                    <Spinner size="s" color="gray" withMargin={false} />
                  ) : props.deepSearch.vectorScope ===
                    DeepSearchVectorScope.Selected ? (
                    <ToggleLeftIcon className="w-4 h-4 " />
                  ) : (
                    <ToggleRightIcon className="w-4 h-4 text-green-500" />
                  )
                }
              />
            </TripleDotMenu>
            <CloseIcon onClose={() => setIsChatExpanded(false)} />
          </div>
          <div className="flex flex-1 overflow-hidden">
            <div className="space-y-4 p-4 flex-1 overflow-y-scroll">
              {transitions((style, message) => {
                if (!message.deepSearchThreadMessage) {
                  return null;
                }

                return (
                  <AssistantMessage
                    style={style}
                    deepSearchId={props.deepSearchId}
                    deepThreadMessageId={message.deepSearchThreadMessageID}
                    index={
                      deepSearchThreadMessageIndexMap[
                        message.deepSearchThreadMessageID
                      ]
                    }
                    isLast={
                      deepSearchThreadMessageIndexMap[
                        message.deepSearchThreadMessageID
                      ] ===
                      deepSearchThreadMessages.length - 1
                    }
                  />
                );
              })}
              <div ref={scrollRef} />
            </div>
            {/* {deepSearchThreadFileIds.length > 0 ? (
              <Sidebar
                deepSearchThreadFileIds={deepSearchThreadFileIds}
                onDocumentAdded={() => {}}
                deepSearchId={props.deepSearchId}
              />
            ) : null} */}
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            const newId = uuidv4();
            dispatch(
              actions.addDeepThreadMessage({
                deepSearchId: props.deepSearchId,
                message: {
                  deepSearchId: props.deepSearchId,
                  deepSearchThreadMessageID: newId,
                  deepSearchThreadMessageFileIds: [],
                  deepSearchThreadMessage: {
                    id: newId,
                    createdAt: getUnixTime(new Date()),
                    content: {
                      role: DealThreadMessageRole.User,
                      status: DealThreadMessageStatus.Sending,
                      type: "text",
                      text: { value: message },
                    },
                    role: DealThreadMessageRole.User,
                    status: DealThreadMessageStatus.Sending,
                  },
                },
              }),
            );
            setMessage("");
            createDeepSearchThreadMessage.mutate(
              {
                input: {
                  deepSearchID: props.deepSearchId,
                  message: message,
                  id: newId,
                },
              },
              {
                onError: () => {
                  dispatch(
                    actions.updateDeepSearchThreadMessage({
                      deepSearchId: props.deepSearchId,
                      messageId: newId,
                      message: {
                        deepSearchId: props.deepSearchId,
                        deepSearchThreadMessageID: newId,
                        deepSearchThreadMessageFileIds: [],
                        deepSearchThreadMessage: {
                          id: newId,
                          createdAt: getUnixTime(new Date()),
                          content: {
                            type: "text",
                            text: { value: message },
                            role: DealThreadMessageRole.User,
                            status: DealThreadMessageStatus.Failed,
                          },
                          role: DealThreadMessageRole.User,
                          status: DealThreadMessageStatus.Failed,
                        },
                      },
                    }),
                  );
                },
                onSuccess: (res) => {
                  deepSearchThreadMessagesQuery.refetch();

                  queryClient.invalidateQueries({
                    queryKey: ["DeepSearch", props.deepSearchId],
                  });
                  dispatch(
                    actions.updateDeepSearchThreadMessage({
                      deepSearchId: props.deepSearchId,
                      messageId: newId,
                      message: {
                        deepSearchId: props.deepSearchId,
                        deepSearchThreadMessageID: newId,
                        deepSearchThreadMessageFileIds: [],
                        deepSearchThreadMessage: {
                          id: res.createDeepSearchThreadMessage.id,
                          createdAt:
                            res.createDeepSearchThreadMessage.createdAt,
                          content: {
                            type: "text",
                            text: { value: message },
                            role: DealThreadMessageRole.User,
                            status: DealThreadMessageStatus.Sent,
                          },
                          role: DealThreadMessageRole.User,
                          status: DealThreadMessageStatus.Sent,
                        },
                      },
                    }),
                  );
                },
              },
            );
          }}
          className={classNames(
            "absolute bottom-0 left-0 right-0 p-3 flex items-center gap-x-2",
            isChatExpanded
              ? "border-t border-concrete-200 bg-concrete-50 rounded-b-lg"
              : "",
          )}
        >
          <div className="flex-1">
            <div className="flex items-center gap-x-2">
              <input
                type="text"
                className={classNames(
                  "flex-1 border-0 text-gray-900 placeholder:text-gray-400 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6 bg-white",
                  isChatExpanded
                    ? "rounded-full py-1.5 px-4 ring-1 ring-inset ring-gray-300"
                    : "rounded-full py-1.5 px-4 shadow-lg ring-1 ring-inset ring-gray-300",
                )}
                placeholder="Find references for..."
                value={message}
                onChange={(e) => setMessage(e.currentTarget.value)}
                onFocus={() => setIsChatExpanded(true)}
                // Optional: Close on Escape key
                onKeyDown={(e) => {
                  if (e.key === "Escape") {
                    setIsChatExpanded(false);
                    e.currentTarget.blur();
                  }
                }}
              />
              {lastMessage &&
              lastMessage.deepSearchThreadMessage.role ===
                DealThreadMessageRole.Ai &&
              lastMessage.deepSearchThreadMessage.status ===
                DealThreadMessageStatus.Thinking ? (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateDeepSearchThreadMessage.mutate({
                      input: {
                        id: lastMessage.deepSearchThreadMessageID,
                        status: DealThreadMessageStatus.Cancelled,
                      },
                    });
                  }}
                  className={classNames(
                    "p-2 bg-persian-600 hover:bg-persian-700 rounded-full shadow-sm",
                    isChatExpanded ? "opacity-100" : "opacity-0",
                  )}
                >
                  <div className="h-3 w-3 bg-white" />
                </button>
              ) : (
                <button
                  className={classNames(
                    "p-1.5 bg-persian-600 hover:bg-persian-700 rounded-full shadow-sm",
                    isChatExpanded
                      ? message.length > 0
                        ? "opacity-100"
                        : "opacity-50 cursor-not-allowed"
                      : "opacity-0",
                  )}
                >
                  <ArrowUpIcon className="h-4 w-4 text-white" />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </ScrollContext.Provider>
  );
}

function Sidebar(props: {
  deepSearchThreadFileIds: string[];
  onDocumentAdded: (fileId: string, versionId: string) => void;
  deepSearchId: string;
}) {
  const client = useGqlClient();
  const deepSearchQuery = useDeepSearchQuery(client, {
    id: props.deepSearchId,
  });

  if (!deepSearchQuery.data) {
    return null;
  }

  return (
    <div className="w-64 border-l bg-concrete-50 border-concrete-200 p-3 space-y-2">
      <p className="text-xs text-gray-500 font-medium">Mentioned documents</p>
      {props.deepSearchThreadFileIds.map((fileId) => {
        return (
          <SidebarDocument
            fileId={fileId}
            onDocumentAdded={props.onDocumentAdded}
            deepSearch={deepSearchQuery.data.deepSearch}
          />
        );
      })}
    </div>
  );
}

function SidebarDocument(props: {
  fileId: string;
  onDocumentAdded: (fileId: string, versionId: string) => void;
  deepSearch: DeepSearchQuery["deepSearch"];
}) {
  const client = useGqlClient();
  const dataRoomFileQuery = useDataRoomFileQuery(client, {
    id: props.fileId,
  });

  if (!dataRoomFileQuery.data) {
    return null;
  }

  return (
    <div className="p-2 bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="flex flex-row items-center gap-x-2">
        <FileIcon
          fileType={dataRoomFileQuery.data.dataRoomFile.fileType}
          size="s"
        />
        <p className="text-sm text-gray-700 font-semibold line-clamp-1 hover:line-clamp-none">
          {dataRoomFileQuery.data.dataRoomFile.name}
        </p>
      </div>
      <p className="text-xs text-gray-500 mt-2">Versions</p>
      <div className="space-y-2">
        {dataRoomFileQuery.data.dataRoomFile.versions.map((version) => {
          return (
            <SidebarDocumentVersion
              key={version.id}
              dataRoomFile={dataRoomFileQuery.data.dataRoomFile}
              deepSearch={props.deepSearch}
              fileId={props.fileId}
              version={version}
            />
          );
        })}
      </div>
    </div>
  );
}

function SidebarDocumentVersion(props: {
  dataRoomFile: DataRoomFileQuery["dataRoomFile"];
  deepSearch: DeepSearchQuery["deepSearch"];
  fileId: string;
  version: DataRoomFileQuery["dataRoomFile"]["versions"][0];
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const createDeepSearchFile = useCreateDeepSearchFileMutation(client);

  return (
    <div className="flex flex-row items-center gap-x-2 justify-between">
      <div className="flex flex-row items-center gap-x-2">
        <StatusDot
          status={
            props.version.id === props.dataRoomFile.currentLiveVersion.id
              ? "positive"
              : "neutral"
          }
        />
        <p className="text-xs text-gray-600 font-semibold">
          Version {props.version.versionNumber}
        </p>
      </div>

      {props.deepSearch.files.filter(
        (f) =>
          f.file.id === props.fileId &&
          f.currentVersion.id === props.version.id,
      ).length > 0 ? (
        <div className="flex flex-row items-center gap-x-2">
          <p className="text-xs text-gray-600 font-semibold">Document added</p>
        </div>
      ) : (
        <div className="flex flex-row items-center gap-x-2">
          <button
            onClick={() => {
              if (createDeepSearchFile.isPending) {
                return;
              }

              createDeepSearchFile.mutate(
                {
                  input: {
                    deepSearchID: props.deepSearch.id,
                    dataRoomFileID: props.fileId,
                    dataRoomFileVersionID: props.version.id,
                    references: [],
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: ["DeepSearch", { id: props.deepSearch.id }],
                    });
                  },
                },
              );
            }}
            className="flex items-center gap-x-1 text-xs text-persian-600 hover:text-persian-900 font-semibold"
          >
            <PlusIcon className="h-3 w-3" />
            {createDeepSearchFile.isPending ? "Adding..." : "Add document"}
          </button>
        </div>
      )}
    </div>
  );
}
