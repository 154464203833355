import { H3, H4 } from "../../components/Heading";
import useGqlClient from "../../hooks/useGqlClient";
import {
  DealRole,
  Question as GqlQuestion,
  Ddql as GqlDdql,
  DataRoomFile,
  useCreateQuestionMutation,
  useDeleteQuestionMutation,
  QuestionNodeType,
  DealActivityType,
  useDealQuery,
  useDealGroupQuestionsQuery,
  useQuestionsQuery,
  DdqlStatus,
  useActiveDealAccountQuery,
  useUpdateQuestionMutation,
} from "../../graphql/generated";
import Loading from "../../components/Loading";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { Link, NavLink, Route, Switch } from "react-router-dom";
import { PageContent } from "../../components/PageContent";
import { Button } from "../../components/tailwind/Button";
import { PlusIcon } from "@heroicons/react/20/solid";
import { AnimatedModal } from "../../components/AnimatedModal";
import { TextInput } from "../../components/tailwind/TextInput";
import { TextArea } from "../../components/tailwind/TextArea";
import { CloseIcon } from "../../components/CloseIcon";
import { FilePill } from "../../components/FilePill";
import { toasts } from "../../components/toasts/toasts";
import { useQueryClient } from "@tanstack/react-query";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { Avatar } from "../../components/account/Avatar";
import { Card } from "../../components/Card";
import {
  DealRoleRestricted,
  adminRoles,
  guestRoles,
} from "../../components/DealRoleRestricted";
import { classNames } from "../../utils/cn";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { NewDDQLModal } from "./NewDDQLModal";
import { Option } from "../../components/tailwind/Dropdown";
import { ListPlusIcon, InfoIcon } from "lucide-react";
import { TripleDotMenu } from "@/src/components/TripleDotMenu";
import { Menu } from "@headlessui/react";
import { AssignToDealGroupModal } from "./AssignToDealGroupModal";
import { useInvalidateQueryKeys } from "@/src/hooks/useInvalidateQueryKeys";
import { DdqlListItem } from "./ddql/DdqlListItem";

export function Questions() {
  return (
    <div className="flex-1 flex">
      <Switch>
        <Route path="/deal/questions/internal">
          <DealRoleRestricted
            roles={adminRoles.concat([DealRole.DealAdminViewOnly])}
          >
            <DealRunnerQuestions />
          </DealRoleRestricted>
          <DealRoleRestricted roles={guestRoles}>
            <GuestQuestions />
          </DealRoleRestricted>
        </Route>
        <Route path="/deal/questions/guest">
          <DealRoleRestricted
            roles={adminRoles.concat([DealRole.DealAdminViewOnly])}
          >
            <DealRunnerQuestions />
          </DealRoleRestricted>
        </Route>
      </Switch>
    </div>
  );
}

function GroupedUserQuestions(props: {
  publishedDDQLs: GqlDdql[];
  questions: GqlQuestion[];
}) {
  const { publishedDDQLs, questions } = props;

  const entityIdToNameMap = {} as Record<
    string,
    { name: string; type: string }
  >;

  const questionsByEntity = questions.reduce(
    (acc, question) => {
      const entityId = question.dealGroup?.id || question.createdBy?.id;
      const entityName = question.dealGroup?.name || question.createdBy?.name;
      const entityType = question.dealGroup?.type || "GUEST";
      if (!acc[entityId]) {
        acc[entityId] = [];
      }
      acc[entityId].push(question);
      entityIdToNameMap[entityId] = { name: entityName, type: entityType };
      return acc;
    },
    {} as Record<string, GqlQuestion[]>,
  );

  const publishedDDQLSByEntity = publishedDDQLs.reduce(
    (acc, ddql) => {
      const entityId = ddql.dealGroup?.id || ddql.createdBy?.id;
      const entityType = ddql.dealGroup?.type || "GUEST";
      if (!acc[entityId]) {
        acc[entityId] = [];
      }
      acc[entityId].push(ddql);
      entityIdToNameMap[entityId] = {
        name: ddql.dealGroup?.name || ddql.createdBy?.name,
        type: entityType,
      };
      return acc;
    },
    {} as Record<string, GqlDdql[]>,
  );

  // get all unique record keys from above
  const entityKeys = Object.keys(questionsByEntity);
  const publishedDDQLSKeys = Object.keys(publishedDDQLSByEntity);

  const allKeys = [...entityKeys, ...publishedDDQLSKeys];
  let uniqueKeys = [...new Set(allKeys)];

  // total nested questions in all questions by entity
  const totalNestedQuestionsByEntity = uniqueKeys?.reduce(
    (acc, key) => {
      acc[key] =
        (publishedDDQLSByEntity[key]?.reduce((ddqlAcc, ddql) => {
          return ddqlAcc + ddql.totalChildQuestions;
        }, 0) || 0) + (questionsByEntity[key]?.length || 0);
      return acc;
    },
    {} as Record<string, number>,
  );

  // total answered nested questions in all questions by entity
  const totalAnsweredNestedQuestionsByEntity = uniqueKeys?.reduce(
    (acc, key) => {
      acc[key] =
        (publishedDDQLSByEntity[key]?.reduce((ddqlAcc, ddql) => {
          return ddqlAcc + ddql.answeredChildQuestions;
        }, 0) || 0) +
        (questionsByEntity[key]?.reduce((questionAcc, question) => {
          return questionAcc + (question.answeredAt ? 1 : 0);
        }, 0) || 0);
      return acc;
    },
    {} as Record<string, number>,
  );

  return (
    <>
      {uniqueKeys?.length === 0 ? (
        <div className="p-2 flex flex-col justify-center items-center">
          <p className="text-gray-500/80 text-sm font-semibold">
            No deal groups or accounts to show
          </p>
        </div>
      ) : (
        <>
          {uniqueKeys.map((entity) => (
            <NavLink key={entity} to={`/deal/questions/group/${entity}`}>
              <div
                className={classNames(
                  "flex items-center justify-between hover:bg-gray-100 px-3 py-2",
                )}
              >
                <div className="flex items-center gap-x-2">
                  <p className="text-sm my-1 font-bold text-gray-700">
                    {entityIdToNameMap[entity].name}
                  </p>
                </div>
                <div className="w-52 flex items-center gap-x-2">
                  <ProgressBar
                    progress={
                      totalNestedQuestionsByEntity[entity] === 0
                        ? 0
                        : (totalAnsweredNestedQuestionsByEntity[entity] /
                            totalNestedQuestionsByEntity[entity]) *
                          100
                    }
                  />
                  <p className="text-xs text-gray-500/80">
                    {totalAnsweredNestedQuestionsByEntity[entity]}/
                    {totalNestedQuestionsByEntity[entity]}
                  </p>
                </div>
              </div>
            </NavLink>
          ))}
        </>
      )}
    </>
  );
}

function DealRunnerQuestions(props: {}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();

  const activeDealId = useSelector(authSelectors.activeDealId);
  const account = useSelector(authSelectors.account);
  const accountId = account?.id;
  const activeDealAccount = useActiveDealAccountQuery(client, {
    id: activeDealId ?? "",
  });
  const questions = useQuestionsQuery(client, {
    dealId: activeDealId ?? "",
    input: {},
  });

  const [openModal, setOpenModal] = useState<
    "" | "create_ddql" | "new_question"
  >("");

  if (questions.isLoading || !questions.data) {
    return <Loading />;
  }

  const draftDdqls = questions.data?.ddqls.filter(
    (ddql) =>
      (ddql.status === DdqlStatus.Imported ||
        ddql.status === DdqlStatus.Initial ||
        ddql.status === DdqlStatus.Importing ||
        ddql.status === DdqlStatus.ImportFailed) &&
      ddql.createdBy?.id === accountId,
  );
  const allPublishedDdqls = questions.data?.ddqls.filter(
    (ddql) => ddql.status === DdqlStatus.Published,
  );
  const ddqls = questions.data?.ddqls;

  return (
    <div className="flex-1 flex-col flex">
      <div className="bg-white px-8 py-2 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H4>Questions</H4>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>

          {!activeDealAccount.data?.deal.activeDealAccount.role.includes(
            DealRole.DealAdminViewOnly,
          ) &&
            window.location.pathname.includes("/questions/internal") && (
              <div className="flex items-center gap-x-2">
                <Button
                  size="s"
                  variant="neutral"
                  icon={PlusIcon}
                  text="New question"
                  onClick={() => {
                    setOpenModal("new_question");
                  }}
                />
                <Button
                  icon={ListPlusIcon}
                  variant="positive"
                  size="s"
                  text="New list"
                  onClick={() => {
                    setOpenModal("create_ddql");
                  }}
                />
              </div>
            )}
        </div>
      </div>

      <div className="p-8 pt-4 w-3/4 2xl:w-1/2">
        <H3>Groups</H3>
        <p className="text-sm text-gray-500">
          Questions and lists grouped by account or deal group
        </p>
        <Card margin="s 0 0 0">
          <GroupedUserQuestions
            publishedDDQLs={allPublishedDdqls as GqlDdql[]}
            questions={questions.data.questions.questions as GqlQuestion[]}
          />
        </Card>

        <H3 margin="xxl 0 0 0">All question lists</H3>
        <p className="text-sm text-gray-500">
          Use lists to group questions e.g. for DDQLs
        </p>
        <Card margin="s 0 0 0">
          {ddqls?.length === 0 ? (
            <div className="p-3 flex flex-col justify-center items-center flex-1">
              <p className="text-gray-500/80 text-sm font-semibold">
                No published lists yet
              </p>
            </div>
          ) : (
            ddqls?.map((ddql, i) => {
              return (
                <DdqlListItem
                  key={ddql.id}
                  ddql={ddql as GqlDdql}
                  isFirst={i === 0}
                  isLast={i === ddqls.length - 1}
                />
              );
            })
          )}
        </Card>

        <NewQuestionModal
          open={openModal === "new_question"}
          onClose={() => {
            queryClient.invalidateQueries({
              queryKey: [
                "DealGroupQuestions",
                { dealId: activeDealId, input: {} },
              ],
            });
            setOpenModal("");
          }}
        />
        <NewDDQLModal
          open={openModal === "create_ddql"}
          onClose={() => {
            setOpenModal("");
            queryClient.invalidateQueries({
              queryKey: ["Questions", { dealId: activeDealId, input: {} }],
            });
            queryClient.invalidateQueries({
              queryKey: [
                "DealGroupQuestions",
                { id: questions.data.deal.activeDealAccount.dealGroup?.id },
              ],
            });
            queryClient.invalidateQueries({
              queryKey: ["DealGroupQuestions", { dealId: activeDealId }],
            });
            queryClient.invalidateQueries({
              queryKey: ["Deal"],
            });
          }}
          parentDdqlID=""
          dealFirmGroupId={
            questions.data.deal.activeDealAccount.dealGroup?.id ?? ""
          }
        />
      </div>
    </div>
  );
}

function QuestionList(props: { questions: GqlQuestion[] }) {
  const { questions } = props;

  if (questions.length === 0) {
    return <NoQuestions />;
  }

  return (
    <Card margin="s 0 0 0">
      {questions.map((question, i) => {
        return (
          <>
            <Question
              key={question.id}
              question={question as GqlQuestion}
              isFirst={i === 0}
              isLast={i === questions.length - 1}
              showAnswered={true}
            />
            {i !== questions.length - 1 ? (
              <div className="border-b border-gray-200" />
            ) : null}
          </>
        );
      })}
    </Card>
  );
}

function NoQuestions() {
  return (
    <Card padding="m">
      <div className="flex justify-center items-center flex-1 ">
        <div>
          <p className="text-center font-semibold text-gray-800">
            No questions yet
          </p>
          <p className="text-gray-500/80 text-center text-sm">
            Any questions your team ask will appear here
          </p>
        </div>
      </div>
    </Card>
  );
}

export function IndividualGuestQuestions(props: { accountId?: string }) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const actor = useSelector(authSelectors.actor);
  const guestQuestions = useQuestionsQuery(client, {
    dealId: activeDealId ?? "",
    input: {},
  });

  const [newQuestionModalOpen, setNewQuestionModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState("");

  if (guestQuestions.error) {
    return <div>Something went wrong</div>;
  }

  if (guestQuestions.isLoading || !guestQuestions.data) {
    return <Loading />;
  }

  const questions = props.accountId
    ? (guestQuestions.data?.questions.questions.filter(
        (question) => question.createdBy?.id === props.accountId,
      ) as GqlQuestion[])
    : (guestQuestions.data?.questions.questions as GqlQuestion[]);

  return (
    <div className="flex-1 flex-col flex ">
      <div className="bg-white px-8 py-3 w-full border-b border-gray-300/80 shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <H4>Questions</H4>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
          {window.location.pathname === "/deal/questions/internal" && (
            <div className="flex items-center gap-x-2">
              <Button
                variant="neutral"
                size="s"
                text="New question"
                icon={PlusIcon}
                isDisabled={actor !== undefined}
                onClick={() => {
                  setNewQuestionModalOpen(true);
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-3 ml-7 mr-5 px-3 flex items-center gap-x-1 rounded-md bg-gray-100 py-2 border border-gray-300 w-3/4 xl:w-1/2">
        <InfoIcon className="w-4 h-4 text-gray-500" />
        <p className="text-gray-500 font-semibold text-sm">
          Question lists are only available to guests in a deal group
        </p>
      </div>

      <PageContent>
        <div className=" w-3/4 xl:w-1/2">
          <H4>Questions</H4>
          <QuestionList questions={questions} />
        </div>
      </PageContent>

      <NewQuestionModal
        open={newQuestionModalOpen}
        onClose={() => {
          queryClient.invalidateQueries({
            queryKey: ["DealGroupQuestions", "Questions"],
          });
          setNewQuestionModalOpen(false);
        }}
      />
    </div>
  );
}

function GuestQuestions() {
  const client = useGqlClient();
  const activeDealAccount = useSelector(authSelectors.activeDealAccount);
  const activeDealId = useSelector(authSelectors.activeDealId);
  const dealGroupQuestions = useDealGroupQuestionsQuery(client, {
    id:
      activeDealAccount && activeDealAccount.dealGroup
        ? activeDealAccount.dealGroup.id
        : "",
  });

  const [newQuestionModalOpen, setNewQuestionModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState("");

  const queryClient = useQueryClient();
  const actor = useSelector(authSelectors.actor);

  if (!activeDealAccount?.dealGroup?.id) {
    return <IndividualGuestQuestions />;
  }

  if (dealGroupQuestions.error) {
    return <div>Something went wrong</div>;
  }

  if (dealGroupQuestions.isLoading || !dealGroupQuestions.data) {
    return <Loading />;
  }

  const questions = dealGroupQuestions.data.dealGroup.questions;
  const ddqls = dealGroupQuestions.data.dealGroup.ddqls;
  const draftDdqls = ddqls.filter(
    (ddql) =>
      ddql.status === DdqlStatus.Imported ||
      ddql.status === DdqlStatus.Initial ||
      ddql.status === DdqlStatus.Importing ||
      ddql.status === DdqlStatus.ImportFailed,
  );

  return (
    <div className="flex-1 flex-col flex ">
      <div className="bg-white px-8 py-3 w-full border-b border-gray-300/80 shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <H4>Questions</H4>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
          <div className="flex items-center gap-x-2">
            <Button
              variant="neutral"
              size="s"
              text="New question"
              icon={PlusIcon}
              isDisabled={actor !== undefined}
              onClick={() => {
                setNewQuestionModalOpen(true);
              }}
            />
            <Button
              icon={ListPlusIcon}
              variant="positive"
              size="s"
              text="New list"
              onClick={() => {
                setOpenModal("create_ddql");
              }}
            />
          </div>
        </div>
      </div>

      <div className="p-8 pt-4">
        <div className="w-3/4 xl:w-1/2">
          <H4>Questions</H4>
          {questions.length === 0 ? (
            <NoQuestions />
          ) : (
            <QuestionList questions={questions as GqlQuestion[]} />
          )}
        </div>
      </div>

      <div className="p-8 pt-4">
        <div className="w-3/4 xl:w-1/2">
          <H3>Question Lists</H3>
          <p className="text-sm text-gray-500 mb-2">
            Use lists to group questions e.g. for DDQLs
          </p>

          <Card margin="s 0 0 0">
            {ddqls?.length === 0 ? (
              <div className="p-3 flex flex-col justify-center items-center flex-1">
                <p className="text-gray-500/80 text-sm font-semibold">
                  No question lists yet
                </p>
              </div>
            ) : (
              ddqls?.map((ddql, i) => {
                return (
                  <DdqlListItem
                    key={ddql.id}
                    ddql={ddql as GqlDdql}
                    isFirst={i === 0}
                    isLast={i === ddqls.length - 1}
                  />
                );
              })
            )}
          </Card>
        </div>
      </div>
      <NewQuestionModal
        open={newQuestionModalOpen}
        onClose={() => {
          queryClient.invalidateQueries({
            queryKey: ["DealGroupQuestions"],
          });
          setNewQuestionModalOpen(false);
        }}
      />
      <NewDDQLModal
        open={openModal === "create_ddql"}
        onClose={() => {
          queryClient.invalidateQueries({
            queryKey: [
              "DealGroupQuestions",
              { id: activeDealAccount?.dealGroup?.id },
            ],
          });
          queryClient.invalidateQueries({
            queryKey: ["Questions", { dealId: activeDealId, input: {} }],
          });
          queryClient.invalidateQueries({
            queryKey: ["DealGroupQuestions", { dealId: activeDealId }],
          });
          queryClient.invalidateQueries({
            queryKey: ["Deal"],
          });

          setOpenModal("");
        }}
        parentDdqlID=""
        dealFirmGroupId={dealGroupQuestions.data.dealGroup.id}
      />
    </div>
  );
}

export function NewQuestionModal(props: {
  open: boolean;
  onClose: () => void;
  ddqlId?: string;
  forDealFirmGroupId?: string;
}) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const [files, setFiles] = useState<DataRoomFile[]>([]);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [details, setDetails] = useState("");
  const [selectedDfg, setSelectedDfg] = useState<Option | null>(null);

  const [error, setError] = useState("");

  const createButtonRef = useRef<HTMLButtonElement>(null);

  const client = useGqlClient();
  const queryClient = useQueryClient();

  const dealQuery = useDealQuery(client, {
    id: activeDealId ?? "",
  });

  const createQuestion = useCreateQuestionMutation(client);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey || event.ctrlKey) {
      if (event.key === "Enter") {
        event.preventDefault();
        if (createButtonRef.current) {
          createButtonRef.current.click();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function clearForm() {
    setFiles([]);
    setTitle("");
    setDetails("");
    setTitleError("");
    setError("");
  }

  return (
    <AnimatedModal
      size="lg"
      position="center"
      open={props.open}
      onClose={() => {
        clearForm();
        props.onClose();
      }}
    >
      <div>
        <div className="justify-between flex items-center">
          <H3>New question</H3>
          <CloseIcon onClose={props.onClose} />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (!title) {
              setTitleError("Title is required");
              return;
            }

            let questionForDealFirmGroupId = props.forDealFirmGroupId;
            if (
              !questionForDealFirmGroupId &&
              selectedDfg &&
              selectedDfg.value
            ) {
              questionForDealFirmGroupId = selectedDfg.value;
            }

            createQuestion.mutate(
              {
                title,
                body: details,
                nodeType: QuestionNodeType.DataRoomFile,
                nodeId: files.length > 0 ? files[0].id : "",
                ddqlID: props.ddqlId,
                questionForDealGroupID: questionForDealFirmGroupId,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["Questions", { input: {} }],
                  });

                  if (props.ddqlId) {
                    queryClient.invalidateQueries({
                      queryKey: ["Ddql", { id: props.ddqlId }],
                    });
                  }

                  if (props.forDealFirmGroupId) {
                    queryClient.invalidateQueries({
                      queryKey: [
                        "DealFirmGroupQuestions",
                        { id: props.forDealFirmGroupId },
                      ],
                    });
                  }

                  props.onClose();
                  clearForm();
                },
                onError: (e) => {
                  setError("Failed to create question");
                },
              },
            );

            props.onClose();
          }}
        >
          <div className="mt-4">
            <TextInput
              label="Title"
              placeholder="Title"
              value={title}
              error={titleError}
              onChange={(e) => {
                setTitle(e.currentTarget.value);
                setTitleError("");
              }}
            />

            <label
              htmlFor="details"
              className="block mt-3 text-sm font-medium leading-6 text-gray-900"
            >
              Details
            </label>
            <TextArea
              rows={3}
              name="details"
              value={details}
              onChange={(e) => {
                setDetails(e.currentTarget.value);
              }}
              placeholder="Any extra context/detail..."
            />

            {/* <div className="flex gap-x-8">
              <div>
                <label
                  htmlFor="files"
                  className="block mt-3 text-sm font-medium leading-6 text-gray-900"
                >
                  Related files
                </label>
                <Pills>
                  {files.map((file) => {
                    return (
                      <FilePill
                        id={file.id}
                        onRemove={() => {
                          setFiles(files.filter((f) => f.id !== file.id));
                        }}
                        showDetailsCard={false}
                        type={file.fileType}
                        name={file.name}
                      />
                    );
                  })}
                  {files.length === 0 ? (
                    <AddFilePill
                      onFileSelected={(file) => {
                        if (files.find((f) => f.id === file.id)) {
                          return;
                        }

                        setFiles([...files, file]);
                      }}
                    />
                  ) : null}
                </Pills>
              </div>
              <DealRoleRestricted roles={adminRoles}>
                {!props.forDealFirmGroupId && dealQuery.data ? (
                  <div>
                    <label
                      htmlFor="files"
                      className="block mt-3 text-sm font-medium leading-6 text-gray-900"
                    >
                      Question for
                      <span className="text-xs text-gray-500/80 ml-1">
                        Optional
                      </span>
                    </label>
                    <Dropdown
                      options={dealQuery.data.deal.guestGroups.map((b) => {
                        return {
                          label: b.name,
                          value: b.id,
                        };
                      })}
                      selectedOption={selectedDfg ?? undefined}
                      onSelect={(value) => {
                        setSelectedDfg(value);
                      }}
                    />
                  </div>
                ) : null}
              </DealRoleRestricted>
            </div> */}
            <div className="flex justify-end mt-6 z-0">
              <Button
                ref={createButtonRef}
                isLoading={createQuestion.isPending}
                loadingText="Posting..."
                text="Post question"
                variant="positive"
                type="submit"
              />
            </div>
            {error ? <div className="mt-4  text-red-500">{error}</div> : null}
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}

interface QuestionProps {
  question: GqlQuestion;
  isFirst: boolean;
  isLast: boolean;
  showAnswered?: boolean;
  showComments?: boolean;
  showAvatar?: boolean;
  showIndex?: boolean;
  showAssignToDealGroup?: boolean;
}

export function Question(props: QuestionProps) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const deleteQuestion = useDeleteQuestionMutation(client);
  const showAnswered = props.showAnswered ?? true;
  const showComments = props.showComments ?? true;
  const showAvatar = props.showAvatar ?? true;
  const showIndex = props.showIndex ?? true;
  const activeDealId = useSelector(authSelectors.activeDealId);
  const [openModal, setOpenModal] = useState("");
  const updateQuestion = useUpdateQuestionMutation(client);
  const { invalidateQuestions } = useInvalidateQueryKeys();

  return (
    <div
      key={props.question.id}
      className={classNames(
        "w-full 3xl:w-4/5 hover:bg-gray-200/70 cursor-pointer",
        props.question.answer ? "bg-gray-100/90" : "",
        props.isFirst ? "rounded-t-md" : "",
        props.isLast ? "rounded-b-md" : "",
      )}
    >
      <Link to={`/deal/questions/${props.question.id}`}>
        <div className="flex px-3 py-2 items-center space-x-3">
          {showAvatar && props.question.createdBy && (
            <Avatar account={props.question.createdBy} />
          )}
          <div className="flex-1">
            <p className="text-gray-600 text-sm">
              {showIndex &&
                props.question.index != null &&
                `${props.question.index} `}
              {props.question.title.slice(
                0,
                Math.min(props.question.title.length, 100),
              )}
              {props.question.title.length > 100 ? "..." : ""}
            </p>
          </div>
          {props.question.dataRoomFile ? (
            <FilePill
              id={props.question.dataRoomFile.id}
              name={props.question.dataRoomFile.name}
              type={props.question.dataRoomFile.fileType}
            />
          ) : null}
          <div className="flex gap-x-2 items-center">
            {props.question.forDealGroup ? (
              <p className="text-gray-500/80 text-sm">
                Assigned to{" "}
                <span className="font-semibold">
                  {props.question.forDealGroup.name}
                </span>
              </p>
            ) : null}
            {showComments && (
              <>
                <ChatBubbleLeftIcon className="mt-0.5 w-4 h-4 text-gray-500" />
                <p className="text-gray-600 text-sm">
                  {props.question.activity?.filter(
                    (a) => a.type === DealActivityType.Comment,
                  )?.length ?? 0}
                </p>
              </>
            )}
            {showAnswered && (
              <CheckCircleIcon
                className={classNames(
                  "mt-0.5 w-4 h-4",
                  props.question.answer ? "text-green-400" : "text-gray-300",
                )}
              />
            )}
            <DealRoleRestricted roles={adminRoles}>
              <TripleDotMenu width="w-44">
                <DealRoleRestricted roles={adminRoles}>
                  {props.showAssignToDealGroup !== false ? (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={classNames(
                            active ? "bg-gray-50 text--700" : "",
                            "w-full block px-3 py-1 text-sm leading-6 text-gray-600 text-left cursor-pointer",
                          )}
                          onClick={(e) => {
                            e.preventDefault();
                            if (props.question.forDealGroup) {
                              updateQuestion.mutate(
                                {
                                  input: {
                                    id: props.question.id,
                                    removeQuestionForDealGroup: true,
                                  },
                                },
                                {
                                  onSuccess: () => {
                                    invalidateQuestions();
                                  },
                                },
                              );
                            } else {
                              setOpenModal("assign_to_deal_group");
                            }
                          }}
                        >
                          {props.question.forDealGroup ? (
                            <p>Unassign from deal group</p>
                          ) : (
                            <p>Assign to deal group</p>
                          )}
                        </div>
                      )}
                    </Menu.Item>
                  ) : null}
                </DealRoleRestricted>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        deleteQuestion.mutate(
                          {
                            input: {
                              id: props.question.id,
                            },
                          },
                          {
                            onSuccess: () => {
                              queryClient.invalidateQueries({
                                queryKey: ["Questions"],
                              });
                              queryClient.invalidateQueries({
                                queryKey: ["Ddql"],
                              });
                              queryClient.invalidateQueries({
                                queryKey: ["DealGroupQuestions"],
                              });
                              toasts.success("Question deleted");
                            },
                            onError: (e) => {
                              toasts.error("Failed to delete question");
                            },
                          },
                        );
                      }}
                      className={classNames(
                        active ? "bg-gray-50 text-red-700" : "",
                        "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer",
                      )}
                    >
                      Delete
                    </div>
                  )}
                </Menu.Item>
              </TripleDotMenu>
            </DealRoleRestricted>
          </div>
        </div>
      </Link>
      <AssignToDealGroupModal
        open={openModal === "assign_to_deal_group"}
        onClose={() => {
          setOpenModal("");
        }}
        question={props.question}
        dealId={activeDealId ?? ""}
      />
    </div>
  );
}

function ProgressBar(props: { progress: number }) {
  return (
    <div className="h-1 w-full bg-green-500/20 rounded-md">
      <div
        className="h-1 bg-green-500 rounded-md transition-all duration-300 ease-in-out"
        style={{ width: `${props.progress}%` }}
      ></div>
    </div>
  );
}
