import { useEffect, useState } from "react";

/**
 * A hook that provides shift-selection functionality for lists of items,
 * similar to how file explorers allow selecting ranges of files.
 */
export const useShiftSelection = <T extends { id: string }>(
  items: T[],
  selectedIds: string[],
  setSelectedIds: (ids: string[]) => void
) => {
  const [lastClickedId, setLastClickedId] = useState<string | null>(null);
  const [isShiftSelectionActive, setIsShiftSelectionActive] = useState<boolean>(false);

  // Add effect to handle keydown/keyup for shift key
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.shiftKey) {
        setIsShiftSelectionActive(true);
        document.body.classList.add('shift-selection-mode');
      }
    };
    
    const handleKeyUp = (e: KeyboardEvent) => {
      if (!e.shiftKey && isShiftSelectionActive) {
        setIsShiftSelectionActive(false);
        document.body.classList.remove('shift-selection-mode');
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      document.body.classList.remove('shift-selection-mode');
    };
  }, [isShiftSelectionActive]);
  
  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      .shift-selection-mode * {
        user-select: none !important;
        -webkit-user-select: none !important;
        -moz-user-select: none !important;
        -ms-user-select: none !important;
      }
    `;
    document.head.appendChild(styleTag);
    
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.shiftKey) {
      e.preventDefault();
    }
  };

  // Function to handle item selection with shift key support
  const handleItemSelection = (itemId: string, shiftKey: boolean, e: React.MouseEvent) => {
    if (shiftKey) {
      e.preventDefault();
    }
    
    if (shiftKey && lastClickedId && items.length > 0) {
      const lastClickedIndex = items.findIndex(item => item.id === lastClickedId);
      const currentIndex = items.findIndex(item => item.id === itemId);
      
      if (lastClickedIndex !== -1 && currentIndex !== -1) {
        const startIndex = Math.min(lastClickedIndex, currentIndex);
        const endIndex = Math.max(lastClickedIndex, currentIndex);
        
        const idsInRange = items
          .slice(startIndex, endIndex + 1)
          .map(item => item.id);
        
        const idsNotInRange = selectedIds.filter(
          id => !idsInRange.includes(id)
        );
        
        setSelectedIds([...idsNotInRange, ...idsInRange]);
      }
    } else {
      // Regular selection logic
      if (selectedIds.includes(itemId)) {
        setSelectedIds(selectedIds.filter(id => id !== itemId));
      } else {
        setSelectedIds([...selectedIds, itemId]);
      }
      // Update last clicked item
      setLastClickedId(itemId);
    }
  };

  return {
    handleItemSelection,
    handleMouseDown,
    isShiftSelectionActive,
  };
}; 