import { useEffect, useState } from "react";
import { AnimatedModal } from "../../components/AnimatedModal";
import { CloseIcon } from "../../components/CloseIcon";
import { H3 } from "../../components/Heading";
import { Pills } from "../../components/Pills";
import { SelectUserPill } from "../../components/SelectUserPill";
import { TextArea } from "../../components/tailwind/TextArea";
import { TextInput } from "../../components/tailwind/TextInput";
import { UserPill } from "../../components/UserPill";
import {
  Account,
  Deal,
  TaskPriority,
  useCreateTaskMutation,
  useFirmQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { Button } from "../../components/tailwind/Button";
import { getUnixTime } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import { toasts } from "../../components/toasts/toasts";
import { SelectDealPill } from "../../components/SelectDealPill";
import { DealPill } from "../../components/DealPill";
import { DatePicker } from "@/src/components/DatePicker";
import { SelectTaskPriority } from "@/src/components/SelectTaskPriorityPill";
export function NewTaskModal(props: {
  open: boolean;
  onClose: () => void;
  firmID: string;
  deal?: Partial<Deal>;
  account?: Pick<Account, "id" | "name" | "avatar" | "avatarColor">;
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const firmQuery = useFirmQuery(client, {
    id: props.firmID,
  });

  const createTask = useCreateTaskMutation(client);

  const [assignTo, setAssignTo] = useState<
    Pick<Account, "id" | "name" | "avatar" | "avatarColor">[] | null
  >(null);

  const [selectedDeal, setSelectedDeal] = useState<Partial<Deal> | null>(null);

  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [priority, setPriority] = useState<TaskPriority | null>(null);

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");

  const [error, setError] = useState("");

  const [description, setDescription] = useState("");

  useEffect(() => {
    if (props.deal) {
      setSelectedDeal(props.deal);
    }

    if (props.account) {
      setAssignTo([props.account]);
    }
  }, [props.deal, props.account]);

  function clearForm() {
    setTitle("");
    setAssignTo(null);
    setDueDate(null);
    setDescription("");
    setSelectedDeal(null);
    setTitleError("");
    setError("");
  }

  return (
    <AnimatedModal
      size="xl"
      open={props.open}
      onClose={() => {
        clearForm();
        props.onClose();
      }}
    >
      <div className="flex items-center justify-between">
        <H3>New task</H3>
        <CloseIcon
          onClose={() => {
            clearForm();
            props.onClose();
          }}
        />
      </div>

      <form
        className="mt-5"
        onSubmit={(e) => {
          e.preventDefault();

          if (title === "") {
            setTitleError("Title is required");
            return;
          }

          if (!selectedDeal) {
            setError("A deal must be selected");
            return;
          }

          createTask.mutate(
            {
              input: {
                title,
                description,
                assignedTo: assignTo ? assignTo.map((a) => a.id) : undefined,
                dueDate: dueDate ? getUnixTime(dueDate) : undefined,
                dealID: selectedDeal ? selectedDeal.id : undefined,
                priority: priority ?? TaskPriority.Low,
              },
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: ["Tasks", { firmID: props.firmID }],
                });
                props.onClose();
                toasts.success("Task created");
                clearForm();
              },
            },
          );
        }}
      >
        <TextInput
          label="Title"
          placeholder="New task..."
          value={title}
          onChange={(e) => {
            setTitleError("");
            setTitle(e.currentTarget.value);
          }}
          error={titleError}
        />

        <p className="mt-3 block text-sm font-medium leading-6 text-gray-900">
          Description
        </p>
        <TextArea
          rows={5}
          name="Description"
          value={description}
          onChange={(e) => {
            setDescription(e.currentTarget.value);
          }}
          placeholder="Description of the task..."
        />

        <div className="flex mt-3 gap-x-4 items-center">
          <div className="">
            <Pills>
              {assignTo?.map((a) => (
                <UserPill
                  account={a}
                  onRemove={() => {
                    setAssignTo(assignTo?.filter((at) => at.id !== a.id));
                  }}
                />
              ))}
              <SelectUserPill
                excludedAccountIds={assignTo?.map((a) => a.id ?? "")}
                onUserSelected={(acc) => {
                  setAssignTo([...(assignTo ?? []), acc as Account]);
                }}
              />
            </Pills>
          </div>

          <div className="">
            <Pills>
              {selectedDeal ? (
                <DealPill
                  deal={selectedDeal as Deal}
                  onRemove={() => {
                    setSelectedDeal(null);
                    setError("");
                  }}
                />
              ) : (
                <SelectDealPill
                  onDealSelected={(deal) => {
                    setSelectedDeal(deal as Deal);
                    setError("");
                  }}
                />
              )}
            </Pills>
          </div>

          <div className="">
            <Pills>
              <SelectTaskPriority
                onPriorityChanged={(p) => {
                  setPriority(p);
                }}
                priority={priority ?? undefined}
              />
            </Pills>
          </div>

          <div className="mt-2">
            <DatePicker
              value={dueDate ?? undefined}
              onChange={(e) => {
                setDueDate(e);
              }}
              pretext="Due"
              onRemove={() => {
                setDueDate(null);
              }}
            />
          </div>
        </div>

        <div className="mt-12 items-center justify-end flex">
          {error && (
            <p className="mr-2 text-sm text-red-600 text-right">{error}</p>
          )}

          <Button
            isDisabled={title === ""}
            isLoading={createTask.isPending}
            variant="positive"
            text="Create task"
            loadingText="Creating..."
            type="submit"
          />
        </div>
      </form>
    </AnimatedModal>
  );
}
