import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DealThread, DealThreadMessage } from "../../graphql/generated";
import { DealThreadMessageCreatedData } from "@/src/contexts/websockets";

export type AssistantState = {
  deals: {
    [dealId: string]: DealThread[];
  };
  dealThreads: {
    [dealThreadId: string]: DealThreadMessageCreatedData[];
  };
  activeDealThreadId?: string;
  assistantPanelState: "pinned" | "unpinned";
};

const initialState: AssistantState = {
  deals: {},
  dealThreads: {},
  assistantPanelState: "unpinned",
};

export const { actions, reducer } = createSlice({
  name: "assistant",
  initialState,
  reducers: {
    setActiveDealThreadId: (state, action: PayloadAction<string>) => {
      state.activeDealThreadId = action.payload;
    },
    updateDealThreadMessageStatus: (
      state,
      action: PayloadAction<{
        dealThreadId: string;
        messageId: string;
        status: DealThreadMessage["status"];
      }>,
    ) => {
      const message = state.dealThreads[action.payload.dealThreadId].find(
        (msg) => msg.dealThreadMessageID === action.payload.messageId,
      );

      if (message) {
        message.dealThreadMessage.status = action.payload.status;
      }
    },
    addDealThreadMessage: (
      state,
      action: PayloadAction<{
        dealThreadId: string;
        message: DealThreadMessageCreatedData;
      }>,
    ) => {
      if (!state.dealThreads[action.payload.dealThreadId]) {
        state.dealThreads[action.payload.dealThreadId] = [];
      }

      if (
        state.dealThreads[action.payload.dealThreadId].find(
          (msg) =>
            msg.dealThreadMessageID ===
            action.payload.message.dealThreadMessageID,
        )
      ) {
        state.dealThreads[action.payload.dealThreadId] = state.dealThreads[
          action.payload.dealThreadId
        ].map((msg) =>
          msg.dealThreadMessageID === action.payload.message.dealThreadMessageID
            ? { ...msg, ...action.payload.message }
            : msg,
        );

        return;
      }

      state.dealThreads[action.payload.dealThreadId].push(
        action.payload.message,
      );
    },
    setDeals: (
      state,
      action: PayloadAction<{ dealId: string; threads: DealThread[] }>,
    ) => {
      state.deals[action.payload.dealId] = action.payload.threads;
    },
    setDealThreads: (
      state,
      action: PayloadAction<{
        dealThreadId: string;
        messages: DealThreadMessageCreatedData[];
      }>,
    ) => {
      state.dealThreads[action.payload.dealThreadId] = action.payload.messages;
    },
    updateDealThreadMessage: (
      state,
      action: PayloadAction<{
        dealThreadId: string;
        messageId: string;
        message: DealThreadMessageCreatedData;
      }>,
    ) => {
      state.dealThreads[action.payload.dealThreadId] = state.dealThreads[
        action.payload.dealThreadId
      ].map((msg) =>
        msg.dealThreadMessageID === action.payload.message.dealThreadMessageID
          ? action.payload.message
          : msg,
      );
    },
    setAssistantPanelState: (
      state,
      action: PayloadAction<"pinned" | "unpinned">,
    ) => {
      state.assistantPanelState = action.payload;
    },
  },
});
