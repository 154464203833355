import { formatDistance, formatISO, secondsToMilliseconds } from "date-fns";
import { Avatar } from "../../components/account/Avatar";
import { ActivityItemMode } from "../../components/activity/ActivityItem";
import {
  Deal,
  TaskActivityQuery,
  TaskActivityType,
  TaskPriority,
  TaskQuery,
  TaskStatus,
} from "../../graphql/generated";
import { classNames } from "../../utils/cn";
import { Link, NavLink } from "react-router-dom";
import { renderMentions } from "@/src/utils/renderMentions";
import { Pills } from "@/src/components/Pills";
import { DealPill } from "@/src/components/DealPill";
import { UserPill } from "@/src/components/UserPill";
import {
  TaskPriorityIcon,
  TaskPriorityPill,
} from "@/src/components/TaskPriorityPill";
import { CircleCheckIcon, PlayCircleIcon } from "lucide-react";
import { CircleIcon } from "lucide-react";
import { formatEnum } from "@/src/utils/enums";

export function TaskActivityItem(props: {
  activity:
    | TaskQuery["task"]["activity"][0]
    | TaskActivityQuery["taskActivity"]["activities"][0];
  isLast: boolean;
  isFirst: boolean;
  mode?: ActivityItemMode;
}) {
  if (props.activity.type === TaskActivityType.PriorityUpdated) {
    if (!props.activity.priority) {
      return null;
    }

    if (props.mode === "compact") {
      return (
        <li
          key={props.activity.id}
          className="relative flex gap-x-4 items-center"
        >
          <div
            className={classNames(
              props.isLast ? "h-6" : "-bottom-6",
              "absolute left-0 top-0 flex w-6 justify-center",
            )}
          >
            <div className="w-px bg-gray-200" />
          </div>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
            <TaskPriorityIcon priority={props.activity.priority} />
          </div>
          <div className="flex-auto ">
            <div className="flex justify-between gap-x-4">
              <div className="py-0.5 text-xs leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                {props.activity.priority === TaskPriority.None ? (
                  <>removed the priority </>
                ) : (
                  <>
                    updated priority to{" "}
                    <span className="font-semibold">
                      {formatEnum(props.activity.priority)}
                    </span>
                  </>
                )}
              </div>
              <time
                title={formatISO(
                  secondsToMilliseconds(props.activity.createdAt),
                )}
                dateTime={formatISO(
                  secondsToMilliseconds(props.activity.createdAt),
                )}
                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
              >
                {formatDistance(
                  secondsToMilliseconds(props.activity.createdAt),
                  new Date(),
                  {
                    addSuffix: true,
                  },
                )}
              </time>
            </div>
          </div>
        </li>
      );
    }

    return (
      <li
        key={props.activity.id}
        className="relative flex gap-x-4 items-center"
      >
        <div
          className={classNames(
            props.isLast ? "h-6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center",
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
          <TaskPriorityIcon priority={props.activity.priority} />
        </div>
        <div className="flex-auto ">
          <div className="flex justify-between gap-x-4">
            <div className="py-0.5 text-xs leading-5 text-gray-500">
              <span className="font-medium text-gray-900">
                {props.activity.account.name}
              </span>{" "}
              {props.activity.priority === TaskPriority.None ? (
                <>
                  removed the priority for{" "}
                  <NavLink
                    to={`/tasks/${props.activity.task.id}`}
                    className="font-semibold hover:text-gray-700"
                  >
                    {props.activity.task.title}
                  </NavLink>
                </>
              ) : (
                <>
                  updated the priority of{" "}
                  <NavLink
                    to={`/tasks/${props.activity.task.id}`}
                    className="font-semibold hover:text-gray-700"
                  >
                    {props.activity.task.title}
                  </NavLink>{" "}
                  to{" "}
                  <span className="font-semibold">
                    {formatEnum(props.activity.priority)}
                  </span>
                </>
              )}
            </div>
            <time
              title={formatISO(secondsToMilliseconds(props.activity.createdAt))}
              dateTime={formatISO(
                secondsToMilliseconds(props.activity.createdAt),
              )}
              className="flex-none py-0.5 text-xs leading-5 text-gray-500"
            >
              {formatDistance(
                secondsToMilliseconds(props.activity.createdAt),
                new Date(),
                {
                  addSuffix: true,
                },
              )}
            </time>
          </div>
        </div>
      </li>
    );
  }

  if (props.activity.type === TaskActivityType.StatusUpdated) {
    if (!props.activity.status) {
      return null;
    }

    if (props.mode === "compact") {
      return (
        <li
          key={props.activity.id}
          className="relative flex gap-x-4 items-center"
        >
          <div
            className={classNames(
              props.isLast ? "h-6" : "-bottom-6",
              "absolute left-0 top-0 flex w-6 justify-center",
            )}
          >
            <div className="w-px bg-gray-200" />
          </div>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
            <StatusIcon status={props.activity.status} />
          </div>
          <div className="flex-auto ">
            <div className="flex justify-between gap-x-4">
              <div className="py-0.5 text-xs leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                updated status to{" "}
                <span className="font-semibold">
                  {formatEnum(props.activity.status)}
                </span>
              </div>
              <time
                title={formatISO(
                  secondsToMilliseconds(props.activity.createdAt),
                )}
                dateTime={formatISO(
                  secondsToMilliseconds(props.activity.createdAt),
                )}
                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
              >
                {formatDistance(
                  secondsToMilliseconds(props.activity.createdAt),
                  new Date(),
                  {
                    addSuffix: true,
                  },
                )}
              </time>
            </div>
          </div>
        </li>
      );
    }

    return (
      <li
        key={props.activity.id}
        className="relative flex gap-x-4 items-center"
      >
        <div
          className={classNames(
            props.isLast ? "h-6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center",
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
          <StatusIcon status={props.activity.status} />
        </div>
        <div className="flex-auto ">
          <div className="flex justify-between gap-x-4">
            <div className="py-0.5 text-xs leading-5 text-gray-500">
              <span className="font-medium text-gray-900">
                {props.activity.account.name}
              </span>{" "}
              updated{" "}
              <NavLink
                to={`/tasks/${props.activity.task.id}`}
                className="font-semibold hover:text-gray-700"
              >
                {props.activity.task.title}
              </NavLink>{" "}
              to{" "}
              <span className="font-semibold">
                {formatEnum(props.activity.status)}
              </span>
            </div>
            <time
              title={formatISO(secondsToMilliseconds(props.activity.createdAt))}
              dateTime={formatISO(
                secondsToMilliseconds(props.activity.createdAt),
              )}
              className="flex-none py-0.5 text-xs leading-5 text-gray-500"
            >
              {formatDistance(
                secondsToMilliseconds(props.activity.createdAt),
                new Date(),
                {
                  addSuffix: true,
                },
              )}
            </time>
          </div>
        </div>
      </li>
    );
  }

  if (props.activity.type === TaskActivityType.Created) {
    return (
      <NavLink to={`/tasks/${props.activity.task.id}`}>
        <li
          key={props.activity.id}
          className={classNames(
            "relative flex gap-x-4 ",
            props.isFirst ? "mb-2" : "my-2",
          )}
        >
          <div
            className={classNames(
              props.isLast ? "h-6" : "-bottom-6",
              "absolute left-0 top-0 flex w-6 justify-center",
            )}
          >
            <div className="w-px bg-gray-200" />
          </div>
          <>
            <div
              className={classNames(
                props.isFirst ? "mt-0" : "mt-2",
                "flex-shrink-0",
              )}
            >
              <Avatar account={props.activity.account} size="s" />
            </div>
            <div
              className={classNames(
                props.mode === "verbose"
                  ? "rounded-md px-3 pt-1 pb-2 ring-1 ring-inset ring-gray-200 bg-white/50"
                  : "",
                "flex-auto",
              )}
            >
              <div className="flex justify-between gap-x-4">
                <div className="py-0.5 text-xs leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {props.activity.account.name}
                  </span>{" "}
                  created a new task{" "}
                  {props.mode === "verbose" ? (
                    <span className="font-semibold">
                      {props.activity.task.title}
                    </span>
                  ) : null}
                </div>
                <time
                  title={formatISO(
                    secondsToMilliseconds(props.activity.createdAt),
                  )}
                  dateTime={formatISO(
                    secondsToMilliseconds(props.activity.createdAt),
                  )}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {formatDistance(
                    secondsToMilliseconds(props.activity.createdAt),
                    new Date(),
                    {
                      addSuffix: true,
                    },
                  )}
                </time>
              </div>
              {props.mode === "verbose" ? (
                <div>
                  <TaskPills task={props.activity.task} />
                </div>
              ) : null}
            </div>
          </>
        </li>
      </NavLink>
    );
  }

  if (props.activity.type === TaskActivityType.Comment) {
    if (!props.activity.comment) {
      return null;
    }

    return (
      <li key={props.activity.id} className="relative flex gap-x-4">
        <div
          className={classNames(
            props.isLast ? "h-6" : "-bottom-6",
            "absolute left-0 top-0 flex w-6 justify-center",
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>
        <>
          <div
            className={classNames(
              props.isFirst ? "mt-0" : "mt-2",
              "flex-shrink-0",
            )}
          >
            <Avatar account={props.activity.account} size="s" />
          </div>
          <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-white/50">
            <div className="flex justify-between gap-x-4">
              <div className="py-0.5 text-xs leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {props.activity.account.name}
                </span>{" "}
                commented
                {props.mode === "verbose" ? (
                  <span>
                    {" "}
                    on <CommentNode activity={props.activity} />
                  </span>
                ) : null}
              </div>
              <time
                title={formatISO(
                  secondsToMilliseconds(props.activity.createdAt),
                )}
                dateTime={formatISO(
                  secondsToMilliseconds(props.activity.createdAt),
                )}
                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
              >
                {formatDistance(
                  secondsToMilliseconds(props.activity.createdAt),
                  new Date(),
                  {
                    addSuffix: true,
                  },
                )}
              </time>
            </div>
            <p className="text-sm leading-6 text-gray-500">
              {renderMentions(props.activity.comment.comment)}
            </p>
          </div>
        </>
      </li>
    );
  }

  return null;
}

function CommentNode(props: { activity: TaskQuery["task"]["activity"][0] }) {
  if (props.activity.task) {
    return (
      <Link to={`/tasks/${props.activity.task.id}`}>
        <span className="font-semibold hover:text-gray-700">
          {props.activity.task.title}
        </span>
      </Link>
    );
  }

  return null;
}

function TaskPills(props: {
  task: TaskActivityQuery["taskActivity"]["activities"][0]["task"];
}) {
  return (
    <Pills>
      {props.task.deal ? (
        <DealPill size="s" deal={props.task.deal as Deal} />
      ) : null}
      {props.task.assignedTo ? (
        <>
          {props.task.assignedTo.map((assignedTo) => (
            <UserPill key={assignedTo.id} account={assignedTo} size="s" />
          ))}
        </>
      ) : null}
      {props.task.priority ? (
        <TaskPriorityPill priority={props.task.priority} />
      ) : null}
    </Pills>
  );
}

function StatusIcon(props: { status: TaskStatus }) {
  switch (props.status) {
    case TaskStatus.Todo:
      return <CircleIcon className="w-4 h-4 text-gray-500" />;
    case TaskStatus.Completed:
      return <CircleCheckIcon className="w-4 h-4 text-green-500" />;
    case TaskStatus.InProgress:
      return <PlayCircleIcon className="w-4 h-4 text-gray-500" />;
    default:
      return null;
  }
}
