import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { Card } from "../components/Card";
import Loading from "../components/Loading";
import {
  DataRoomFileUploadStatus,
  DealAccessStatus,
  DealInviteQuery,
  FileType,
  useAcceptDealInviteMutation,
  useAccountQuery,
  useCreateAccountMutation,
  useDataRoomFileVersionQuery,
  useDealInviteQuery,
  useFileVersionDownloadUrlQuery,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import Logo from "../components/Logo";
import { Button } from "../components/tailwind/Button";
import { actions } from "../store/auth/slice";
import { FileIcon } from "../components/FileIcon";
import { classNames } from "../utils/cn";
import { AnimatedModal } from "../components/AnimatedModal";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { CloseIcon } from "../components/CloseIcon";
import { CheckIcon } from "@heroicons/react/20/solid";
import { SignIn, SignUp, useSession } from "@clerk/clerk-react";
import { toasts } from "../components/toasts/toasts";
import { Spinner } from "../components/icons/Spinner";
import React from "react";
import { PdfViewerDocumentId } from "../components/PdfViewer";
import { SignupSource } from "./Signup";

export function Invite() {
  return (
    <div className="flex flex-1 justify-center py-20 px-10">
      <div className="hidden xl:block z-0 absolute inset-0 bg-persian-950/90 w-1/2"></div>
      <div className="grid z-10 grid-cols-1 xl:grid-cols-2 max-w-5xl">
        <div className="mr-12 hidden xl:block">
          <Logo width={105} height={35} />
          <p className="text-blue-100/50 font-semibold">Making deals happen.</p>

          <p className="text-white/90 mt-4">
            Liquid pulls everything in a deal into one place. Powered by a
            powerful Data Room with a full audit log, granular access control
            and an AI toolkit to increase efficiency.
          </p>

          <div className="flex items-center mt-12 gap-x-3">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://liquidacquire.com/privacy-policy"
              className="text-persian-200 font-semibold hover:text-white text-sm"
            >
              Privacy Policy
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://liquidacquire.com/terms-of-service"
              className="text-persian-200 font-semibold hover:text-white text-sm"
            >
              Terms & Conditions
            </a>
          </div>
        </div>

        <div className="ml-12">
          <Switch>
            <Route path="/deal-invite/:token">
              <InviteDetails />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

function InviteDetails() {
  const { token } = useParams<{ token: string }>();

  const dispatch = useDispatch();
  const history = useHistory();

  const [ndaAccepted, setNdaAccepted] = useState(false);
  const [accountError, setAccountError] = useState<string | null>(null);

  const client = useGqlClient();
  const inviteQuery = useDealInviteQuery(client, {
    token,
  });

  const acceptInvite = useAcceptDealInviteMutation(client);

  const accountQuery = useAccountQuery(client);
  const account = accountQuery.data?.account;

  // check if account email matched invite email
  const emailMatch = account?.email === inviteQuery.data?.dealInvite.email;
  const emailMismatch = !emailMatch;

  useEffect(() => {
    if (accountQuery.error) {
      const errorString = JSON.stringify(accountQuery.error);
      if (errorString.includes('"message":"record not found"')) {
        setAccountError(
          "Your account is not registered on this Liquid domain. Note that accounts are not cross-compatible between domains like us.liquidacquire and uk.liquidacquire.",
        );
      }
    } else {
      setAccountError(null);
    }
  }, [accountQuery.error]);

  if (accountError) {
    return (
      <div>
        <div>
          <p className="text-gray-800 font-semibold text-lg">Account Error</p>
          <p className="text-gray-500">{accountError}</p>
        </div>
        <Card margin="l 0 0 0" padding="s m">
          <Button
            margin="l 0 0 0"
            text="Back to login"
            variant="positive"
            onClick={() => {
              history.push("/login");
            }}
          />
        </Card>
      </div>
    );
  }

  // Add email mismatch error handling
  if (account && inviteQuery.data && emailMismatch) {
    return (
      <div>
        <div>
          <p className="text-gray-800 font-semibold text-lg">Email Mismatch</p>
          <p className="text-gray-500">
            This invite was sent to{" "}
            <span className="font-semibold">
              {inviteQuery.data.dealInvite.email}
            </span>
            , but you're logged in with{" "}
            <span className="font-semibold">{account.email}</span>.
          </p>
        </div>
        <Card margin="l 0 0 0" padding="s m">
          <p className="text-gray-500">
            Please log in with the email address that received this invitation.
          </p>
          <Button
            margin="l 0 0 0"
            text="Back to login"
            variant="positive"
            onClick={() => {
              history.push("/login");
            }}
          />
        </Card>
      </div>
    );
  }

  if (inviteQuery.error) {
    return (
      <div>
        <div>
          <p className="text-gray-800 font-semibold text-lg">Invite expired</p>
          <p className="text-gray-500">
            It looks like this link doesn't work anymore.
          </p>
          <p className="text-gray-500"></p>
        </div>
        <Card margin="l 0 0 0" padding="s m">
          {account ? (
            <>
              <p className="font-semibold text-sm text-gray-700">
                Welcome back {account.name},
              </p>
              <p className="text-gray-500">
                You'll need to ask the Deal admin for a new invite
              </p>
              <Button
                margin="l 0 0 0"
                text="Take me home"
                variant="positive"
                onClick={() => {
                  history.push("/home");
                }}
              />
            </>
          ) : (
            <>
              <p className="font-semibold text-gray-700">
                Contact the Deal admin for a new invite
              </p>
              <Button
                margin="l 0 0 0"
                text="Back to login"
                variant="positive"
                onClick={() => {
                  history.push("/login");
                }}
              />
            </>
          )}
        </Card>
      </div>
    );
  }

  if (inviteQuery.isPending || !inviteQuery.data) {
    return <Loading />;
  }

  return (
    <>
      <div>
        <p className="text-gray-800 font-semibold text-lg">
          You've been invited to join a deal on Liquid
        </p>
        <p className="text-gray-500">
          {inviteQuery.data.dealInvite.invitedBy.name}{" "}
          <span className="font-semibold">
            ({inviteQuery.data.dealInvite.invitedBy.email})
          </span>
          {inviteQuery.data.dealInvite.invitedBy.firm ? (
            <span> from {inviteQuery.data.dealInvite.invitedBy.firm.name}</span>
          ) : null}{" "}
          has invited you to a deal on Liquid.
        </p>
      </div>
      <CardContent dealInvite={inviteQuery.data.dealInvite} />
    </>
  );
}

function CardContent(props: { dealInvite: DealInviteQuery["dealInvite"] }) {
  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);
  const account = accountQuery.data?.account;

  const { session, isSignedIn } = useSession();
  const createAccount = useCreateAccountMutation(client);
  const dispatch = useDispatch();

  // Add error handling for account query
  const [accountError, setAccountError] = useState<string | null>(null);

  useEffect(() => {
    if (accountQuery.error) {
      const errorString = JSON.stringify(accountQuery.error);
      if (errorString.includes('"message":"record not found"')) {
        setAccountError(
          "Your account is not registered on this Liquid domain. Note that accounts are not compatible between domains like us.liquidacquire and uk.liquidacquire.",
        );
      }
    } else {
      setAccountError(null);
    }
  }, [accountQuery.error]);

  useEffect(() => {
    console.log("isSignedIn", isSignedIn, session, account);
    if (!isSignedIn) {
      return;
    }

    if (!session || !session.user) {
      return;
    }

    if (!session.user.unsafeMetadata) {
      return;
    }

    if (session.user.unsafeMetadata.onboardingStatus !== "initial") {
      return;
    }

    if (createAccount.isPending) {
      return;
    }

    if (account) {
      return;
    }

    createAccount.mutate(
      {
        clerkUserId: session.user.id,
      },
      {
        onSuccess: (data) => {
          session.reload();
          dispatch(actions.refreshAccount({ account: data.createAccount }));
        },
      },
    );
  }, [
    isSignedIn,
    session,
    session?.user.unsafeMetadata,
    (client as any).options,
  ]);

  // Display error message if account query failed
  if (accountError) {
    return (
      <Card padding="m" margin="l 0 0 0">
        <p className="text-gray-800 font-semibold">Account Error</p>
        <p className="text-sm text-gray-500 mt-2">{accountError}</p>
        <div className="flex justify-end mt-6">
          <Button
            onClick={() => {
              window.location.href = "/login";
            }}
            text="Back to login"
            variant="positive"
          />
        </div>
      </Card>
    );
  }

  if (!isSignedIn) {
    return <Authenticate dealInvite={props.dealInvite} />;
  }

  if (
    !session ||
    !session.user ||
    session.user.unsafeMetadata.onboardingStatus === "initial" ||
    !account
  ) {
    return <Loading />;
  }

  return <AcceptInvite dealInvite={props.dealInvite} />;
}

function AcceptInvite(props: { dealInvite: DealInviteQuery["dealInvite"] }) {
  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);

  const dispatch = useDispatch();
  const history = useHistory();

  const acceptDealInvite = useAcceptDealInviteMutation(client);

  const { token } = useParams<{ token: string }>();

  function joinDeal() {
    acceptDealInvite.mutate(
      {
        token,
        ndaSigned: true,
      },
      {
        onSuccess: (data) => {
          accountQuery.refetch().then((accountData) => {
            if (accountData.data) {
              dispatch(
                actions.refreshAccount({ account: accountData.data!.account }),
              );

              dispatch(
                actions.setActiveDealId({ id: props.dealInvite.deal.id }),
              );

              history.push("/");
            }
          });
        },
      },
    );
  }

  if (
    props.dealInvite.dealGroup &&
    props.dealInvite.dealGroup.ndaDataRoomFile
  ) {
    return (
      <Card padding="m" margin="m 0 0 0">
        <Nda dealInvite={props.dealInvite} />
      </Card>
    );
  }

  return (
    <Card padding="m" margin="l 0 0 0">
      <p className="text-gray-800 font-semibold">Join Deal</p>
      {props.dealInvite.dealGroup ? (
        <p className="text-sm text-gray-500">
          You'll be joining{" "}
          <span className="font-medium">{props.dealInvite.dealGroup.name}</span>
          .
        </p>
      ) : null}

      <div className="flex justify-end mt-6">
        <Button
          onClick={() => {
            joinDeal();
          }}
          text="Join deal"
          variant="positive"
          loadingText="Joining..."
          isLoading={acceptDealInvite.isPending}
        />
      </div>
    </Card>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Authenticate(props: { dealInvite: DealInviteQuery["dealInvite"] }) {
  const queryParams = useQuery();
  const location = useLocation();

  const [mode, setMode] = useState<"login" | "signup">("signup");

  useEffect(() => {
    if (queryParams.get("mode") === "login") {
      setMode("login");
    }
  }, [queryParams.toString()]);

  if (mode === "login") {
    return (
      <div className="mt-3">
        <SignIn
          initialValues={{ emailAddress: props.dealInvite.email }}
          signUpUrl={`${location.pathname}?mode=signup`}
          redirectUrl={undefined}
          afterSignInUrl={location.pathname}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="mt-3">
        <SignUp
          appearance={{
            layout: {
              socialButtonsPlacement: "bottom",
              socialButtonsVariant: "iconButton",
            },
          }}
          initialValues={{ emailAddress: props.dealInvite.email }}
          signInUrl={`${location.pathname}?mode=login`}
          redirectUrl={undefined}
          afterSignUpUrl={location.pathname}
          unsafeMetadata={{
            onboardingStatus: "initial",
            signupSource: SignupSource.externalInvite,
          }}
        />
      </div>
    </div>
  );
}

function Nda(props: { dealInvite: DealInviteQuery["dealInvite"] }) {
  let content = null;

  const [ndaViewed, setNdaViewed] = useState(false);
  const [viewNda, setViewNda] = useState(false);
  const [ndaAccepted, setNdaAccepted] = useState(false);

  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);

  const dispatch = useDispatch();
  const history = useHistory();

  const acceptDealInvite = useAcceptDealInviteMutation(client);

  const { token } = useParams<{ token: string }>();

  function joinDeal() {
    acceptDealInvite.mutate(
      {
        token,
        ndaSigned: ndaAccepted,
      },
      {
        onSuccess: (data) => {
          accountQuery.refetch().then((accountData) => {
            if (accountData.data) {
              dispatch(
                actions.refreshAccount({ account: accountData.data!.account }),
              );

              dispatch(
                actions.setActiveDealId({ id: props.dealInvite.deal.id }),
              );

              history.push("/");
            }
          });
        },
      },
    );
  }

  if (
    props.dealInvite.dealGroup &&
    props.dealInvite.dealGroup.ndaDataRoomFile &&
    props.dealInvite.dealGroup.dealAccessStatus === DealAccessStatus.PendingNda
  ) {
    content = (
      <div>
        <p className="font-semibold text-gray-800">NDA</p>
        <p className="text-gray-500 text-sm">
          Sign the NDA below and join the deal.
        </p>
        <div className="mt-3">
          <div
            onClick={() => {
              setViewNda(true);
              setNdaViewed(true);
            }}
            className="bg-gray-100 rounded-md border border-gray-300 hover:border-gray-400 px-2 py-3 cursor-pointer group"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-1.5">
                <FileIcon
                  fileType={props.dealInvite.dealGroup.ndaDataRoomFile.fileType}
                />
                <p className=" select-none text-sm font-semibold mt-1 text-gray-700">
                  {props.dealInvite.dealGroup.ndaDataRoomFile.name}
                </p>
              </div>
              <button
                onClick={() => {
                  setViewNda(true);
                  setNdaViewed(true);
                }}
                className="font-semibold text-sm text-persian-500/90 group-hover:text-persian-600"
              >
                View
              </button>
            </div>
          </div>

          {!ndaViewed ? (
            <p className="text-xs text-gray-500 mt-2">
              View the NDA before signing
            </p>
          ) : (
            <p className="text-xs text-gray-500 mt-2">
              NDA viewed{" "}
              <CheckIcon className="w-3 h-3 inline-block text-green-500" />
            </p>
          )}

          <div
            className={classNames(
              "relative flex items-start mt-4",
              ndaViewed ? "" : "opacity-50",
            )}
          >
            <div className="flex h-6 items-center">
              <input
                disabled={!ndaViewed}
                id="ndaAccess"
                aria-describedby="ndaAccess-description"
                name="ndaAccess"
                type="checkbox"
                checked={ndaAccepted}
                onChange={(e) => {
                  setNdaAccepted(e.currentTarget.checked);
                }}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="ndaAccess" className="font-medium text-gray-900">
                I agree to the NDA.
              </label>
              <p id="ndaAccess-description" className="text-gray-500">
                Checking this box acknolwedges that you have read and agree to
                the NDA and are liable to its terms.
              </p>
            </div>
          </div>
          <div className={classNames("flex justify-end mt-4")}>
            <Button
              text="Join deal"
              variant="positive"
              isDisabled={!ndaViewed}
              isLoading={
                acceptDealInvite.isPending || accountQuery.isRefetching
              }
              loadingText="Joining..."
              onClick={() => {
                if (ndaViewed && ndaAccepted) {
                  joinDeal();
                  return;
                }

                toasts.error("Please view and accept the NDA to join the deal");
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  if (
    props.dealInvite.dealGroup &&
    props.dealInvite.dealGroup.ndaDataRoomFile &&
    props.dealInvite.dealGroup.dealAccessStatus ===
      DealAccessStatus.PendingManualApproval
  ) {
    content = (
      <div>
        <p className="font-semibold text-gray-800">NDA</p>
        <p className="text-gray-500 text-sm">
          Sign the NDA below to join this deal.
        </p>
        <div className="mt-3">
          <div
            onClick={() => {
              setViewNda(true);
            }}
            className="bg-gray-100 rounded-md border border-gray-300 hover:border-gray-400 px-2 py-3 cursor-pointer group"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-1.5">
                <FileIcon
                  fileType={props.dealInvite.dealGroup.ndaDataRoomFile.fileType}
                />
                <p className=" select-none font-semibold mt-1 text-gray-700">
                  {props.dealInvite.dealGroup.ndaDataRoomFile.name}
                </p>
              </div>
              <button
                onClick={() => {
                  setViewNda(true);
                  setNdaViewed(true);
                }}
                className="font-semibold text-blue-500/90 group-hover:text-blue-600"
              >
                View
              </button>
            </div>
          </div>

          {!ndaViewed ? (
            <p className="text-xs text-gray-500 mt-2">
              View the NDA before signing
            </p>
          ) : (
            <p className="text-xs text-gray-500 mt-2">
              NDA viewed{" "}
              <CheckIcon className="w-3 h-3 inline-block text-green-500" />
            </p>
          )}

          <div
            className={classNames(
              "relative flex items-start mt-4",
              ndaViewed ? "" : "opacity-50",
            )}
          >
            <div className="flex h-6 items-center">
              <input
                disabled={!ndaViewed}
                id="ndaAccess"
                aria-describedby="ndaAccess-description"
                name="ndaAccess"
                type="checkbox"
                checked={ndaAccepted}
                onChange={(e) => {
                  setNdaAccepted(e.currentTarget.checked);
                }}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="ndaAccess" className="font-medium text-gray-900">
                I agree to the NDA.
              </label>
              <p id="ndaAccess-description" className="text-gray-500">
                Checking this box acknolwedges that you have read and agree to
                the NDA and are liable to its terms.
              </p>
            </div>
          </div>
          <div
            className={classNames(
              "flex justify-end mt-4",
              ndaViewed && ndaAccepted ? "" : "opacity-50",
            )}
          >
            <Button
              text="Join deal"
              variant="positive"
              isDisabled={!ndaViewed}
              isLoading={
                acceptDealInvite.isPending || accountQuery.isRefetching
              }
              loadingText="Joining..."
              onClick={() => {
                joinDeal();
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <>{content}</>
      <AnimatedModal
        size="xl"
        open={viewNda}
        onClose={() => {
          setViewNda(false);
        }}
      >
        {props.dealInvite.dealGroup &&
        props.dealInvite.dealGroup.ndaDataRoomFile ? (
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-x-1.5">
              <FileIcon
                fileType={props.dealInvite.dealGroup.ndaDataRoomFile.fileType}
              />
              <p className="font-semibold mt-1">
                {props.dealInvite.dealGroup.ndaDataRoomFile.name}
              </p>
            </div>
            <CloseIcon
              onClose={() => {
                setViewNda(false);
              }}
            />
          </div>
        ) : null}

        <FileViewWrapper invite={props.dealInvite} />
      </AnimatedModal>
    </div>
  );
}

function FileViewWrapper(props: { invite: DealInviteQuery["dealInvite"] }) {
  if (
    props.invite.dealGroup &&
    props.invite.dealGroup.ndaDataRoomFile!.uploadStatus !==
      DataRoomFileUploadStatus.Ready &&
    props.invite.dealGroup.ndaDataRoomFile!.uploadStatus !==
      DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div style={{ height: 800 }} className="flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (
    (props.invite.dealGroup &&
      props.invite.dealGroup.ndaDataRoomFile!.fileType === FileType.Pdf) ||
    (props.invite.dealGroup &&
      props.invite.dealGroup.ndaDataRoomFile!.fileType === FileType.Docx) ||
    (props.invite.dealGroup &&
      props.invite.dealGroup.ndaDataRoomFile!.fileType === FileType.Pptx)
  ) {
    return <FileViewPspdfkit invite={props.invite} />;
  }

  return <FileViewContent invite={props.invite} />;
}

function FileViewPspdfkit(props: { invite: DealInviteQuery["dealInvite"] }) {
  const client = useGqlClient();

  const dataRoomFileVersion = useDataRoomFileVersionQuery(client, {
    id: props.invite.dealGroup!.ndaDataRoomFile!.latestVersion.id,
  });

  if (
    dataRoomFileVersion.error ||
    (dataRoomFileVersion.data &&
      !dataRoomFileVersion.data.dataRoomFileVersion.pspdfkitDocumentId)
  ) {
    return <FileViewContent invite={props.invite} />;
  }

  if (dataRoomFileVersion.isLoading || !dataRoomFileVersion.data) {
    return (
      <div style={{ height: 800 }}>
        <Loading />
      </div>
    );
  }

  const drfv = dataRoomFileVersion.data.dataRoomFileVersion;

  return (
    <div style={{ height: 800, width: "100%" }}>
      <PdfViewerDocumentId
        documentId={drfv.pspdfkitDocumentId}
        token={drfv.pspdfkitToken}
      />
    </div>
  );
}

function FileViewContent(props: { invite: DealInviteQuery["dealInvite"] }) {
  const client = useGqlClient();
  const [url, setUrl] = React.useState<string>("");
  const [fileDownloadError, setFileDownloadError] = React.useState<string>("");

  const fileDownloadUrl = useFileVersionDownloadUrlQuery(
    client,
    {
      id: props.invite.dealGroup!.ndaDataRoomFile!.latestVersion.id,
    },
    {
      refetchOnWindowFocus(query) {
        if (query.isStaleByTime(1000 * 60 * 5)) {
          return true;
        }

        return false;
      },
    },
  );

  useEffect(() => {
    if (
      fileDownloadUrl.data &&
      fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl !== url
    ) {
      setFileDownloadError("");
      setUrl(fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl);
      return;
    }

    if (fileDownloadUrl.error) {
      setFileDownloadError("Unable to load file");
      return;
    }
  }, [
    fileDownloadUrl.data,
    fileDownloadUrl.isRefetching,
    fileDownloadUrl.isPending,
    fileDownloadUrl.error,
    url,
  ]);

  if (
    props.invite.dealGroup &&
    props.invite.dealGroup.ndaDataRoomFile!.uploadStatus !==
      DataRoomFileUploadStatus.Ready &&
    props.invite.dealGroup.ndaDataRoomFile!.uploadStatus !==
      DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div style={{ height: 800 }} className="flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (url === "") {
    return (
      <div style={{ height: 800 }}>
        <Loading />
      </div>
    );
  }

  if (fileDownloadError) {
    return (
      <div className="flex items-center justify-center">
        <p>Failed to load file</p>
        <Button
          variant="neutral"
          text="Retry"
          isLoading={fileDownloadUrl.isPending || fileDownloadUrl.isRefetching}
          loadingText="Retrying..."
          onClick={() => {
            fileDownloadUrl.refetch();
          }}
        />
      </div>
    );
  }

  return (
    <DocViewer
      key={url}
      config={{
        pdfVerticalScrollByDefault: true,
        header: {
          disableFileName: true,
          disableHeader: true,
        },
      }}
      prefetchMethod="GET"
      style={{ height: 800, width: "100%", overflowY: "scroll" }}
      documents={[
        {
          uri: url,
        },
      ]}
      pluginRenderers={DocViewerRenderers}
    />
  );
}
