import { useEffect, useRef, useState } from "react";
import { AnimatedModal } from "../../components/AnimatedModal";
import { CloseIcon } from "../../components/CloseIcon";
import { H3 } from "../../components/Heading";
import useGqlClient from "../../hooks/useGqlClient";
import { Button } from "../../components/tailwind/Button";
import { Alert } from "../../components/Alert";
import {
  ArrowPathIcon,
  CheckCircleIcon,
  DocumentPlusIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { useQueryClient } from "@tanstack/react-query";
import {
  CreateDdqlDocument,
  CreateDdqlMutation,
  CreateDdqlMutationVariables,
  S3DdqlUploadCompleteDocument,
  S3DdqlUploadCompleteMutation,
  S3DdqlUploadCompleteMutationVariables,
} from "../../graphql/generated";
import { classNames } from "../../utils/cn";
import { LocalFileIcon } from "../../components/icons/LocalFileIcon";
import { formatFileSize } from "../../utils/formatFileSize";
import { Spinner } from "../../components/icons/Spinner";
import { Uploader, getNumberOfParts } from "../../utils/multipartUploader";
import { TextInput } from "../../components/tailwind/TextInput";
import { toasts } from "../../components/toasts/toasts";
import { useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";

export function NewDDQLModal(props: {
  dealFirmGroupId: string;
  parentDdqlID?: string;
  open: boolean;
  onClose: () => void;
}) {
  const client = useGqlClient();

  const [file, setFile] = useState<File | null>(null);

  function clearForm() {
    setFile(null);
  }

  return (
    <FileUploader
      open={props.open}
      onClose={props.onClose}
      dealFirmGroupId={props.dealFirmGroupId}
      parentDdqlID={props.parentDdqlID || ""}
    />
  );
}

interface FileUploaderProps {
  open: boolean;
  error?: unknown;
  onClose: () => void;
  dealFirmGroupId: string;
  parentDdqlID?: string;
}

interface FileWrapper {
  id: number;
  file: File;
  name: string;
  uploadProgress: number;
  status: "creating" | "uploading" | "complete" | "error" | "pending";
}

function FileUploader(props: FileUploaderProps) {
  const [dragActive, setDragActive] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");

  const [files, setFiles] = useState<FileWrapper[]>([]);
  const [fileError, setFileError] = useState("");

  const createButtonRef = useRef<HTMLButtonElement>(null);

  const queryClient = useQueryClient();
  const [ddqlName, setDdqlName] = useState("");
  const [ddqlNameError, setDdqlNameError] = useState("");

  const activeDealId = useSelector(authSelectors.activeDealId);

  const client = useGqlClient();

  const [uploadingFile, setUploadingFile] = useState(false);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey || event.ctrlKey) {
      if (event.key === "Enter") {
        event.preventDefault();
        if (createButtonRef.current) {
          createButtonRef.current.click();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleDrag = function (e: any) {
    setFileError("");
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      const newFiles: FileWrapper[] = Array.from<File>(e.dataTransfer.files).map((f, i) => ({
        id: i,
        file: f,
        name: f.name,
        uploadProgress: 0,
        status: "pending",
      }));
      setFiles(newFiles);
    }
  };

  function createDdql(fileWrap?: FileWrapper): Promise<void> {
    setUploadingFile(true);
    return new Promise((resolve, reject) => {
      try {
        setFiles((prev) => {
          return prev.map((f) => {
            if (fileWrap && f.id === fileWrap.id) {
              return {
                ...f,
                status: "creating",
              };
            }

            return f;
          });
        });

        client
          .request<CreateDdqlMutation, CreateDdqlMutationVariables>(
            CreateDdqlDocument,
            {
              input: {
                name: ddqlName,
                dealGroupID: props.dealFirmGroupId,
                ...(props.parentDdqlID && { parentDdqlID: props.parentDdqlID }),
                file: fileWrap
                  ? {
                      fileName: fileWrap.file.name,
                      fileType: fileWrap.file.type,
                      parts: getNumberOfParts(fileWrap.file),
                    }
                  : null,
              },
            }
          )
          .catch((e) => {
            setFiles((prev) => {
              return prev.map((f) => {
                if (fileWrap && f.id === fileWrap.id) {
                  return {
                    ...f,
                    status: "error",
                  };
                }

                return f;
              });
            });
          })
          .then((data) => {
            if (!data || !fileWrap) {
              if (!data) {
                setError("Failed to create DDQL");
                return;
              }
              setUploadingFile(false);
              clearForm();
              props.onClose();
              toasts.success("DDQL created successfully");
              return;
            }

            setFiles((prev) => {
              return prev.map((f) => {
                if (fileWrap && f.id === fileWrap.id) {
                  return {
                    ...f,
                    status: "uploading",
                  };
                }

                return f;
              });
            });

            const uploader = new Uploader({
              file: fileWrap.file,
              uploadId: data.createDdql.multiPartUploadID,
              parts: data.createDdql.parts.map((p) => {
                return {
                  PartNumber: p.partNumber,
                  signedUrl: p.presignedUrl,
                };
              }),
            });

            uploader.start();

            uploader.onError(() => {
              setFiles((prev) => {
                return prev.map((f) => {
                  if (f.id === fileWrap.id) {
                    return {
                      ...f,
                      status: "error",
                    };
                  }

                  return f;
                });
              });
            });

            uploader.onProgress((progress) => {
              setFiles((prev) => {
                return prev.map((f) => {
                  if (f.id === fileWrap.id) {
                    return {
                      ...f,
                      uploadProgress: progress.percentage,
                    };
                  }

                  return f;
                });
              });
            });

            uploader.onComplete((uploadedParts) => {
              setUploadingFile(false);
              client
                .request<
                  S3DdqlUploadCompleteMutation,
                  S3DdqlUploadCompleteMutationVariables
                >(S3DdqlUploadCompleteDocument, {
                  ddqlID: data.createDdql.ddql.id,
                  multiPartUploadID: data.createDdql.multiPartUploadID,
                  parts: uploadedParts.map((up) => {
                    return {
                      partNumber: up.PartNumber,
                      etag: up.ETag,
                    };
                  }),
                })
                .then((d) => {
                  if (!d) {
                    return;
                  }
                  setFiles((prev) => {
                    return prev.map((f) => {
                      if (f.id === fileWrap.id) {
                        return {
                          ...f,
                          status: "complete",
                        };
                      }

                      return f;
                    });
                  });
                });
            });
          });
      } finally {
        queryClient.invalidateQueries({
          queryKey: ["Questions", { dealId: activeDealId }],
        });
        resolve();
      }
    });
  }

  function clearForm() {
    setFiles([]);
    setError("");
    setFileError("");
    setDdqlName("");
  }

  return (
    <AnimatedModal
      padding="p-0"
      open={props.open}
      onClose={() => {
        clearForm();
        props.onClose();
      }}
      size="lg"
    >
      <div>
        <div className="p-4 flex justify-between items-center">
          <div>
            <H3>New question list</H3>
          </div>
          <CloseIcon
            onClose={() => {
              clearForm();
              props.onClose();
            }}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (ddqlName.trim() === "") {
              setDdqlNameError("Name is required");
              return;
            }

            const f = files.length > 0 ? files[0] : undefined;
            createDdql(f);
          }}
        >
          <div className="px-3 ">
            <TextInput
              label="Name"
              placeholder="Due Diligence Questionnaire"
              secondaryLabel="Required"
              value={ddqlName}
              error={ddqlNameError}
              onChange={(e) => {
                setDdqlNameError("");
                setDdqlName(e.currentTarget.value);
              }}
            />

            <div className="mt-5">
              <p className="block text-sm font-medium leading-3 text-gray-900">
                File
                <span className="text-gray-500/80 text-xs ml-1 font-normal">
                  Optional
                </span>
              </p>
              <p className="text-xs text-gray-500">
                If you have a file, attach it here to extract the questions
                automatically
              </p>
            </div>
            <div className="mt-1 overflow-y-scroll no-scrollbar">
              {files.length > 0 ? (
                <div className="">
                  <div className="">
                    {files
                      .sort((a, b) => {
                        if (
                          a.status === "complete" &&
                          b.status !== "complete"
                        ) {
                          return 1;
                        }

                        if (
                          a.status !== "complete" &&
                          b.status === "complete"
                        ) {
                          return -1;
                        }

                        return 0;
                      })
                      .map((file) => {
                        return (
                          <div
                            className={classNames(
                              "relative px-2 flex-wrap py-3 my-2 group gap-x-2 justify-between items-center flex border rounded-md border-gray-200",
                              file.status === "complete" ? "opacity-80" : ""
                            )}
                          >
                            <div className="flex items-start">
                              <LocalFileIcon fileType={file.file.type} />
                              <div className="-mt-1.5 ml-2">
                                <p className="font-semibold text-sm text-gray-700 truncate">
                                  {file.name}
                                </p>

                                {/* <PencilIcon className="h-4 w-4 ml-1 text-gray-500" /> */}
                                <p className="text-xs text-gray-500 mt-1.5 leading-none">
                                  {formatFileSize(file.file.size)}
                                </p>
                              </div>
                            </div>
                            {file.status === "pending" ? (
                              <div className="flex items-center overflow-visible gap-x-2">
                                <button
                                  type="button"
                                  className="text-gray-400 hover:text-gray-500"
                                  onClick={() => {
                                    setFiles((prev) => {
                                      return prev.filter(
                                        (f) => f.id !== file.id
                                      );
                                    });
                                  }}
                                >
                                  <TrashIcon className="h-5 w-5 " />
                                </button>
                              </div>
                            ) : (
                              <FileWrapStatus
                                fileWrap={file}
                                onRetry={() => {
                                  createDdql(file);
                                }}
                              />
                            )}
                          </div>
                        );
                      })}

                    <div>
                      <input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.files) {
                            const f: FileWrapper[] = Array.from(
                              e.target.files
                            ).map((f, i) => {
                              return {
                                id: i,
                                file: f,
                                name: f.name,
                                uploadProgress: 0,
                                status: "pending",
                              };
                            });

                            setFiles(f);
                          }
                        }}
                        ref={inputRef}
                        type="file"
                        multiple
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={classNames(
                      "p-2 h-full gap-x-2 bg-gray-50 border-2 transition-all duration-300 ease-in-out rounded-md border-dashed border-gray-300 flex  items-center ",
                      dragActive
                        ? "border-indigo-500 bg-indigo-100/80 shadow"
                        : "",
                      fileError ? "border-red-500" : ""
                    )}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  >
                    <p className="text-xs mb-1 text-gray-500 font-medium">
                      Drop the file here or
                    </p>
                    <div className="flex justify-center">
                      <button
                        type="button"
                        className="inline-flex items-center gap-x-1 bg-white text-blue-500 border px-2 py-1 rounded-full font-semibold hover:border-blue-400 hover:shadow text-xs"
                        onClick={() => {
                          setFileError("");
                          if (inputRef.current) {
                            inputRef.current.click();
                          }
                        }}
                      >
                        <DocumentPlusIcon className="w-4 h-4" />
                        <p>Upload</p>
                      </button>
                      <input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.files) {
                            const f: FileWrapper[] = Array.from(
                              e.target.files
                            ).map((f, i) => {
                              // remove extension from file name
                              return {
                                id: i,
                                file: f,
                                name: f.name,

                                uploadProgress: 0,
                                status: "pending",
                              };
                            });

                            setFiles(f);
                          }
                        }}
                        ref={inputRef}
                        type="file"
                        multiple
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                  {fileError ? (
                    <p className="text-red-500 font-medium mt-2 text-sm">
                      {fileError}
                    </p>
                  ) : null}
                </>
              )}
            </div>

            <div className=" bg-gray-200 mt-6 w-full shadow-inner h-px" />
            {error ? (
              <Alert margin="m 0 0 0" type="error" text={error} />
            ) : null}
            <div className="p-4 flex justify-end">
              {files.length > 0 &&
              files.every((f) => ["complete", "error"].includes(f.status)) ? (
                <FilesSummary
                  files={files}
                  onRetry={() => {
                    Promise.all(
                      files
                        .filter((f) => f.status === "error")
                        .map((f) => createDdql(f))
                    );
                  }}
                  onCompleted={() => {
                    clearForm();
                    props.onClose();
                  }}
                />
              ) : (
                <Button
                  ref={createButtonRef}
                  type="submit"
                  variant="positive"
                  text="Create new list"
                  isLoading={
                    uploadingFile ||
                    files.some((f) =>
                      ["creating", "uploading"].includes(f.status)
                    )
                  }
                  loadingText="Creating..."
                  onClick={() => {}}
                  isDisabled={ddqlName.trim() === ""}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}

function FileWrapStatus(props: { fileWrap: FileWrapper; onRetry: () => void }) {
  if (props.fileWrap.status === "creating") {
    return (
      <div className="flex items-center gap-x-1.5">
        <Spinner color="gray" size="s" />
        <p className="text-sm text-gray-500/80 font-semibold">
          Creating file...
        </p>
      </div>
    );
  }

  if (props.fileWrap.status === "uploading") {
    // retunr a progress bar

    return (
      <div className="w-32">
        <ProgressBar progress={props.fileWrap.uploadProgress} />
      </div>
    );
  }

  if (props.fileWrap.status === "error") {
    return (
      <div className="">
        <p className="text-xs text-orange-500">Error uploading file</p>
        <button
          className="flex items-center gap-x-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => {
            props.onRetry();
          }}
        >
          <ArrowPathIcon className="h-5 w-5" />
          <p className="text-sm font-semibold">Try again</p>
        </button>
      </div>
    );
  }

  if (props.fileWrap.status === "complete") {
    return (
      <div>
        <CheckCircleIcon className="h-5 w-5 text-green-500" />
      </div>
    );
  }

  return null;
}

function FilesSummary(props: {
  files: FileWrapper[];
  onRetry: () => void;
  onCompleted: () => void;
}) {
  const queryClient = useQueryClient();
  if (props.files.some((f) => f.status === "error")) {
    return (
      <div className="flex items-center gap-x-2">
        <p className="text-sm text-gray-500/80">
          Failed to upload{" "}
          {props.files.filter((f) => f.status === "error").length} files
        </p>
        <Button
          text="Retry"
          variant="neutral"
          onClick={() => {
            props.onRetry();
          }}
        />
        <Button
          text="Done"
          variant="positive"
          onClick={() => {
            props.onCompleted();
          }}
        />
      </div>
    );
  }

  return (
    <div className="flex items-center gap-x-2">
      <p className="text-sm text-gray-500/80">
        {props.files.length} files uploaded successfully
      </p>
      <Button
        text="Done"
        variant="positive"
        type="button"
        onClick={() => {
          props.onCompleted();
        }}
      />
    </div>
  );
}

function ProgressBar(props: { progress: number }) {
  return (
    <div className="h-1 w-full bg-blue-500/50 rounded-md">
      <div
        className="h-1 bg-blue-500 rounded-md transition-all duration-300 ease-in-out"
        style={{ width: `${props.progress}%` }}
      ></div>
    </div>
  );
}
