import { useEffect } from "react";
import Routes from "./routes";

import { useDispatch } from "react-redux";

import { actions } from "./store/auth/slice";
import useGqlClient from "./hooks/useGqlClient";

import { useAccountQuery } from "./graphql/generated";
import "./index.css";
import { WebSocketProvider } from "./contexts/websockets";

import { usePostHog } from "posthog-js/react";
import { Toaster } from "sonner";
import { useSession } from "@clerk/clerk-react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

function App() {
  const dispatch = useDispatch();
  const posthog = usePostHog();

  const { isSignedIn } = useSession();
  const client = useGqlClient();
  const accountQuery = useAccountQuery(
    client,
    {},
    {
      refetchOnWindowFocus(query) {
        return true;
      },
    }
  );

  useEffect(() => {
    if (!accountQuery.data || !isSignedIn) {
      return;
    }

    dispatch(actions.refreshAccount({ account: accountQuery.data.account }));

    posthog?.identify(accountQuery.data.account.id, {
      email: accountQuery.data.account.email,
      name: accountQuery.data.account.name,
      firmId: accountQuery.data.account.firm
        ? accountQuery.data.account.firm.id
        : null,
    });
    if (accountQuery.data.account.firm) {
      posthog?.group("firm", `id:${accountQuery.data.account.firm.id}`, {
        name: accountQuery.data.account.firm.name,
      });
    }
  }, [
    accountQuery.data,
    accountQuery.isLoading,
    isSignedIn,
    posthog,
    dispatch,
  ]);

  return (
    <WebSocketProvider>
      <ReactQueryDevtools buttonPosition="top-left" initialIsOpen={false} />

      <Toaster position="bottom-right" richColors expand />
      <Routes />
    </WebSocketProvider>
  );
}

export default App;
