import { useState } from "react";
import {
  DataRoomFile,
  useAllDataRoomFoldersQuery,
  AllDataRoomFoldersQuery,
  FileType,
} from "../graphql/generated";
import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";
import useGqlClient from "../hooks/useGqlClient";
import Loading from "./Loading";
import { FileIcon } from "./FileIcon";
import { AnimatedModal } from "./AnimatedModal";
import { CloseIcon } from "./CloseIcon";
import { Pills } from "./Pills";
import { Button } from "./tailwind/Button";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { StatusDot } from "./StatusDot";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { classNames } from "../utils/cn";

export function AddFileModal(props: {
  onFilesSelected: (files: SelectedFile[]) => void;
  open: boolean;
  onClose: () => void;
  mode?: "latest" | "versions";
}) {
  const [query, setQuery] = useState("");

  return (
    <>
      <AnimatedModal
        padding="p-0"
        onClose={props.onClose}
        open={props.open}
        size="xl"
      >
        <div className="min-h-80 flex flex-col">
          <div className="border-b flex justify-between items-center p-3">
            <p className=" font-semibold text-gray-700">Add documents</p>
            <CloseIcon onClose={props.onClose} />
          </div>
          <SearchAndSelect
            mode={props.mode ?? "latest"}
            query={query}
            setQuery={setQuery}
            onFilesSelected={(files) => {
              props.onFilesSelected(files);
              props.onClose();
            }}
          />
        </div>
      </AnimatedModal>
    </>
  );
}

export interface SelectedFile {
  file: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0]["files"][0];
  version: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0]["files"][0]["currentLiveVersion"];
}

// Inline or import this component to perform the actual file searching:
function SearchAndSelect({
  query,
  setQuery,
  onFilesSelected,
  mode = "latest",
}: {
  query: string;
  setQuery: (val: string) => void;
  onFilesSelected: (files: SelectedFile[]) => void;
  mode: "latest" | "versions";
}) {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const allFolders = useAllDataRoomFoldersQuery(client, {
    dealId: activeDealId ?? "",
  });

  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);

  if (allFolders.isError) return <div>Error loading folders</div>;
  if (allFolders.isPending || !allFolders.data) return <Loading />;

  return (
    <div className="flex flex-col  flex-1">
      <div className="w-full cursor-default overflow-hidden rounded-t-md bg-white text-left border-b border-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
        <input
          placeholder="Search files..."
          autoFocus
          className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
          onChange={(event) => setQuery(event.currentTarget.value)}
        />
      </div>
      <div className="flex-1 mt-2 max-h-80 overflow-y-auto">
        {allFolders.data.deal.dataRoom.allFolders.map((folder) => (
          <FolderContent
            key={folder.id}
            folder={folder}
            searchTerm={query}
            mode={mode}
            onFileSelected={(file) => {
              if (
                selectedFiles.some(
                  (f) =>
                    f.file.id === file.file.id &&
                    f.version.id === file.version.id
                )
              ) {
                setSelectedFiles(
                  selectedFiles.filter(
                    (f) =>
                      f.file.id !== file.file.id ||
                      f.version.id !== file.version.id
                  )
                );
              } else {
                setSelectedFiles([...selectedFiles, file]);
              }
            }}
            selectedFiles={selectedFiles}
          />
        ))}
      </div>
      <div className=" flex justify-between items-center gap-x-2 p-3 bg-gray-50 border-t border-gray-200 rounded-b-md">
        <div className="">
          <p className="text-sm font-semibold text-gray-700">Selected files</p>
          {selectedFiles.length === 0 ? (
            <p className="text-sm text-gray-500">No files selected</p>
          ) : (
            <Pills>
              {selectedFiles.map((file) => (
                <LocalFilePill
                  key={file.file.id}
                  name={file.file.name}
                  fileType={file.file.fileType}
                  version={
                    mode === "versions" ? file.version.versionNumber : undefined
                  }
                  isLiveVersion={
                    file.version.id === file.file.currentLiveVersion.id
                  }
                />
              ))}
            </Pills>
          )}
        </div>
        <div className="flex items-center justify-between flex-shrink-0">
          <Button
            text={`Add ${selectedFiles.length} documents`}
            variant="positive"
            isDisabled={selectedFiles.length === 0}
            onClick={() => {
              onFilesSelected(selectedFiles);
            }}
          />
        </div>
      </div>
    </div>
  );
}

function FolderContent(props: {
  folder: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0];
  searchTerm: string;
  onFileSelected: (file: SelectedFile) => void;
  selectedFiles: SelectedFile[];
  mode: "latest" | "versions";
}) {
  const { folder, searchTerm, onFileSelected, selectedFiles, mode } = props;
  const filteredFiles = folder.files.filter((file) =>
    searchTerm.length > 2
      ? file.name.toLowerCase().includes(searchTerm.toLowerCase())
      : true
  );

  if (filteredFiles.length === 0) return null;

  return (
    <div className="mt-2">
      <p className="text-xs px-3 py-1 text-ellipsis">
        {folder.parentFolders.length > 0
          ? folder.parentFolders
              .map((f) => (f.name ? f.name : "Home"))
              .reverse()
              .join(" / ")
          : "Home /"}
      </p>
      {filteredFiles.map((file) => (
        <FileRow
          key={file.id}
          file={file as DataRoomFile}
          onFileSelected={onFileSelected}
          selectedFiles={selectedFiles}
          mode={mode}
        />
      ))}
    </div>
  );
}

function FileRow(props: {
  file: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0]["files"][0];
  onFileSelected: (file: SelectedFile) => void;
  selectedFiles: SelectedFile[];
  mode: "latest" | "versions";
}) {
  const { file, onFileSelected, selectedFiles, mode } = props;
  const [showPreviousVersions, setShowPreviousVersions] = useState(false);

  const previousVersions = file.versions.filter(
    (v) => v.id !== file.currentLiveVersion.id
  );

  return (
    <div>
      <div
        className="cursor-pointer flex items-center justify-between px-3 py-2 hover:bg-gray-100"
        onClick={() =>
          onFileSelected({
            file: props.file,
            version: file.currentLiveVersion,
          })
        }
      >
        <div className="flex items-center gap-x-1.5">
          <input
            type="checkbox"
            className="h-4 w-4 mr-1.5 rounded border border-gray-300 bg-white text-persian-600 focus:ring-persian-500"
            checked={selectedFiles.some((f) => f.file.id === file.id)}
            ref={(input) => {
              if (input) {
                let isIndeterminate = false;
                const selectedFileCount = selectedFiles.filter(
                  (f) => f.file.id === file.id
                ).length;
                const selectedLiveVersionCount = selectedFiles.filter(
                  (f) =>
                    f.file.id === file.id &&
                    f.version.id === file.currentLiveVersion.id
                ).length;
                if (selectedFileCount >= 1 && selectedLiveVersionCount === 0) {
                  isIndeterminate = true;
                }

                input.indeterminate = isIndeterminate;
              }
            }}
          />
          <FileIcon fileType={file.fileType} />
          <div>
            <p className="text-sm font-semibold text-gray-800 leading-tight">
              {file.name}
            </p>
            {mode === "versions" ? (
              <div className="flex items-center gap-x-1">
                <StatusDot status="positive" />
                <p className="text-xs text-gray-500">
                  Version {file.currentLiveVersion.versionNumber}
                </p>
              </div>
            ) : null}
          </div>
        </div>
        {previousVersions.length > 0 && mode === "versions" ? (
          <button
            className="text-xs opacity-80 rounded-md px-2 py-1 border border-gray-200 flex items-center gap-x-1 text-gray-500/80 hover:text-gray-500 hover:shadow-md hover:border-gray-300 hover:bg-white hover:opacity-100"
            onClick={(e) => {
              e.stopPropagation();
              setShowPreviousVersions(!showPreviousVersions);
            }}
          >
            <p className="text-xs text-gray-500">
              {showPreviousVersions
                ? "Hide previous versions"
                : "Show previous versions"}
            </p>
            {showPreviousVersions ? (
              <ChevronUpIcon className="w-4 h-4" />
            ) : (
              <ChevronDownIcon className="w-4 h-4" />
            )}
          </button>
        ) : null}
      </div>
      {showPreviousVersions && (
        <div className="flex flex-col gap-y-1 ">
          {previousVersions.map((version) => (
            <div
              key={version.id}
              className="flex items-center px-11 py-1.5 gap-x-1 cursor-pointer hover:bg-gray-50"
              onClick={() => {
                onFileSelected({
                  file: props.file,
                  version: version,
                });
              }}
            >
              <input
                type="checkbox"
                className="h-4 w-4 mr-1.5 rounded border border-gray-300 bg-white text-persian-600 focus:ring-persian-500"
                checked={selectedFiles.some(
                  (f) => f.file.id === file.id && f.version.id === version.id
                )}
              />
              <div>
                <div className="flex items-center gap-x-1">
                  <StatusDot status="neutral" />
                  <p className="text-sm text-gray-500 font-semibold">
                    Version {version.versionNumber}
                  </p>
                </div>
                <p className="text-xs text-gray-500">
                  Created{" "}
                  {formatDistanceToNowStrict(fromUnixTime(version.createdAt), {
                    addSuffix: true,
                  })}{" "}
                  by {version.createdBy.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function LocalFilePill(props: {
  name: string;
  fileType: string;
  version?: number;
  isLiveVersion: boolean;
}) {
  return (
    <div className="cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
      <FileIcon size="s" fileType={props.fileType as FileType} />
      <div className="ml-2">
        <p className="flex items-center gap-x-1 text-xs leading-tight  text-gray-800 truncate">
          {props.name}
          {props.version ? (
            <>
              <span className="ml-1 text-xs items-center gap-x-1 text-gray-500 leading-tight">
                Version {props.version}
              </span>
              <span
                className={classNames(
                  "w-1 h-1 rounded-full",
                  props.isLiveVersion ? "bg-green-500" : "bg-gray-300"
                )}
              ></span>
            </>
          ) : null}
        </p>
      </div>
    </div>
  );
}
