import { DataRoomFileFragmentFragment } from "@/src/graphql/generated";
import { classNames } from "@/src/utils/cn";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

interface BreadcrumbProps {
  folders: DataRoomFileFragmentFragment["parentFolders"];
  root: string;
}

export function Breadcrumbs(props: BreadcrumbProps) {
  const [parentFolders, setParentFolders] = useState<
    DataRoomFileFragmentFragment["parentFolders"]
  >(props.folders);

  const location = useLocation();

  const crumbs: {
    name: string;
    link: string;
  }[] = [
    {
      name: "Home",
      link: `/${props.root}`,
    },
    ...parentFolders
      .slice(0, parentFolders.length - 1)
      .map((folder) => {
        return {
          name: folder.name,
          link: `/${props.root}/folder/${folder.id}`,
        };
      })
      .reverse(),
  ];

  return (
    <div className="flex items-center gap-x-2">
      {crumbs.map((folder, index) => {
        return (
          <NavLink to={folder.link} key={index}>
            <div className="flex gap-x-1" key={folder.link}>
              <p
                className={classNames(
                  "m-0 font-semibold text-sm ",
                  location.pathname === folder.link
                    ? "text-purple-500"
                    : "text-gray-500"
                )}
              >
                {folder.name}
              </p>
              {index !== props.folders.length - 1 ? (
                <p className="text-sm text-gray-400">/</p>
              ) : null}
            </div>
          </NavLink>
        );
      })}
    </div>
  );
}
