import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useGqlClient from "../../hooks/useGqlClient";
import {
  DataRoomFileQuery,
  useDataRoomFileQuery,
  useMoveDataRoomFileMutation,
} from "../../graphql/generated";
import { AnimatedModal } from "../../components/AnimatedModal";
import { useInvalidateQueryKeys } from "../../hooks/useInvalidateQueryKeys";
import { Loader } from "../../components/Loading";
import { ArrowRightCircleIcon, FolderIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../utils/cn";
import { FilePill } from "../../components/FilePill";
import { AddFolderPill } from "../../components/AddFolderPill";
import { CloseIcon } from "../../components/CloseIcon";
import { toasts } from "../../components/toasts/toasts";

interface MoveFileProps {
  onClose: () => void;
  onMoved?: () => void;
  open: boolean;
  dataRoomFileId: string;
  targetFolderId?: string;
}

export function MoveFileModal(props: MoveFileProps) {
  return (
    <AnimatedModal open={props.open} onClose={props.onClose} size="xl">
      <MoveFileContent
        id={props.dataRoomFileId}
        targetFolderId={props.targetFolderId}
        onCancel={() => props.onClose()}
        onMove={() => {
          if (props.onMoved) {
            props.onMoved();
          }

          props.onClose();
        }}
      />
    </AnimatedModal>
  );
}

interface MoveFileContentProps {
  id: string | null;
  targetFolderId?: string;
  onCancel: () => void;
  onMove: () => void;
}

interface SelectedFolder {
  id: string;
  name: string;
}

function MoveFileContent(props: MoveFileContentProps) {
  const client = useGqlClient();
  const fileQuery = useDataRoomFileQuery(client, {
    id: props.id || "",
  });

  const history = useHistory();

  const moveDataRoomFile = useMoveDataRoomFileMutation(client);
  const {
    invalidateDealActivities,
    invalidateDataRoomFolders,
    invalidateDataRooms,
  } = useInvalidateQueryKeys();

  const [data, setData] = useState<DataRoomFileQuery | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<SelectedFolder | null>(
    null
  );

  useEffect(() => {
    if (props.targetFolderId) {
      const fetchFolderName = async () => {
        try {
          interface FolderNameResponse {
            dataRoomFolder: {
              id: string;
              name: string;
            }
          }
          
          const response = await client.request<FolderNameResponse>(`
            query GetFolderName($id: String!) {
              dataRoomFolder(id: $id) {
                id
                name
              }
            }
          `, { id: props.targetFolderId });
          
          if (response.dataRoomFolder && props.targetFolderId) {
            setSelectedFolder({
              id: props.targetFolderId,
              name: response.dataRoomFolder.name
            });
          }
        } catch (error) {
          console.error("Error fetching folder name:", error);
        }
      };
      
      fetchFolderName();
    }
  }, [props.targetFolderId, client]);

  useEffect(() => {
    if (fileQuery.data) {
      setData(fileQuery.data);
    }
  }, [fileQuery.data]);

  if (fileQuery.isPending && !data) {
    return <Loader width={30} height={6} />;
  }

  if (fileQuery.error || !data) {
    return (
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <p>Something went wrong</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
          <ArrowRightCircleIcon
            className="h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
        </div>
        <div className="w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {`Move ${data.dataRoomFile.name} `}
          </Dialog.Title>

          <div className="mt-3 w-full">
            {fileQuery.data ? (
              <div className="flex items-center gap-x-1">
                <p className="text-sm font-semibold text-gray-500">Move</p>
                <FilePill
                  id={fileQuery.data.dataRoomFile.id}
                  name={fileQuery.data.dataRoomFile.name}
                  type={fileQuery.data.dataRoomFile.fileType}
                  showDetailsCard={false}
                />
                <p className="text-sm font-semibold text-gray-500">to</p>
                {selectedFolder ? (
                  <FolderPill
                    selectedFolder={selectedFolder}
                    onClose={() => {
                      setSelectedFolder(null);
                    }}
                  />
                ) : (
                  <AddFolderPill
                    onFolderSelected={(folder) => {
                      setSelectedFolder({
                        id: folder.id,
                        name: folder.name,
                      });
                    }}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mt-8 sm:mt-12 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          disabled={!selectedFolder}
          className={classNames(
            "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
            !selectedFolder ? "opacity-70" : ""
          )}
          onClick={() => {
            if (!selectedFolder || !props.id) {
              return;
            }

            moveDataRoomFile.mutate(
              {
                input: {
                  id: props.id,
                  folderID: selectedFolder.id,
                },
              },
              {
                onError: () => {},
                onSuccess: () => {
                  invalidateDataRoomFolders();
                  invalidateDealActivities();
                  invalidateDataRooms();
                  toasts.success("File moved successfully");
                  if(history.location.pathname.startsWith("/drive")){
                    history.push(`/drive/folder/${selectedFolder.id}`);
                  }else{
                    history.push(`/deal/documents/folder/${selectedFolder.id}`);
                  }
                  props.onMove();
                },
              }
            );
          }}
        >
          {moveDataRoomFile.isPending ? "Moving..." : "Move"}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            props.onCancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

function FolderPill(props: {
  selectedFolder: SelectedFolder | null;
  onClose: () => void;
}) {
  if (!props.selectedFolder) {
    return (
      <button className="flex flex-wrap group justify-center relative">
        <div className="cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
          <FolderIcon className="w-4 h-4 text-gray-400" />
          <p className="text-xs ml-1 mt-0.5 truncate">Select folder</p>
        </div>
      </button>
    );
  }

  return (
    <div className="flex flex-wrap group justify-center relative">
      <div className="gap-x-1 cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
        <FolderIcon className="w-4 h-4 text-blue-700/70" />
        <p className="text-xs  mt-0.5 truncate">{props.selectedFolder.name}</p>
        <CloseIcon
          onClose={() => {
            props.onClose();
          }}
        />
      </div>
    </div>
  );
}
