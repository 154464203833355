import React, { useEffect, useRef, useState } from "react";
import { useDataRoomFileVersionQuery } from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import {
  pspdfkitDocumentProxyEndpoint,
  pspdfkitLicenseKeyEnvVar,
} from "@/src/utils/endpoints";
import PSPDFKit from "@nutrient-sdk/viewer";

const PspdfkitComp: React.FC<{
  dataRoomFileVersionSourceID: string;
  dataRoomFileVersionTargetID: string;
}> = ({ dataRoomFileVersionSourceID, dataRoomFileVersionTargetID }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const client = useGqlClient();
  const [isLoading, setIsLoading] = useState(true);

  const { data: dataRoomFileVersionSource } = useDataRoomFileVersionQuery(
    client,
    {
      id: dataRoomFileVersionSourceID,
    },
  );

  const { data: dataRoomFileVersionTarget } = useDataRoomFileVersionQuery(
    client,
    {
      id: dataRoomFileVersionTargetID,
    },
  );

  useEffect(() => {
    const container = containerRef.current;

    if (
      !container ||
      !dataRoomFileVersionSource?.dataRoomFileVersion.pspdfkitDocumentId ||
      !dataRoomFileVersionTarget?.dataRoomFileVersion.pspdfkitDocumentId
    ) {
      return;
    }

    // Make sure container is empty before initializing PSPDFKit
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }

    setIsLoading(true);

    (async function () {
      // Ensure that there's only one PSPDFKit instance
      PSPDFKit.unload(container);

      console.log("Loading PSPDFKit Text Comparison viewer");

      await PSPDFKit.loadTextComparison({
        baseUrl: `${window.location.protocol}//${window.location.host}/${import.meta.env.BASE_URL}`,
        container,
        documentA: `${pspdfkitDocumentProxyEndpoint()}/${
          dataRoomFileVersionSource.dataRoomFileVersion.pspdfkitDocumentId
        }`,
        documentB: `${pspdfkitDocumentProxyEndpoint()}/${
          dataRoomFileVersionTarget.dataRoomFileVersion.pspdfkitDocumentId
        }`,
        licenseKey: pspdfkitLicenseKeyEnvVar(),
        theme: PSPDFKit.Theme.LIGHT,
        innerToolbarItems: [
          {
            type: "zoom-in",
          },
          {
            type: "zoom-out",
          },
        ],
        comparisonSidebarConfig: {},
      });

      setIsLoading(false);
      console.log("Text Comparison UI for Web successfully loaded!!");
    })();

    // Properly unload PSPDFKit when component unmounts
    return () => {
      if (PSPDFKit && container) {
        console.log("Unloading PSPDFKit instance");
        PSPDFKit.unload(container);
      }
    };
  }, [dataRoomFileVersionSource, dataRoomFileVersionTarget]);

  if (!dataRoomFileVersionSource || !dataRoomFileVersionTarget) {
    return <div>Loading document data...</div>;
  }

  return (
    <>
      <div
        id="pspdfkit-container"
        ref={containerRef}
        style={{ width: "100%", height: "100vh" }}
      />
    </>
  );
};

export default PspdfkitComp;
