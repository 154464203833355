import Loading from "@/src/components/Loading";
import { Button } from "@/src/components/tailwind/Button";
import { TextArea } from "@/src/components/tailwind/TextArea";
import { TextInput } from "@/src/components/tailwind/TextInput";
import { toasts } from "@/src/components/toasts/toasts";
import {
  AccountDocument,
  AccountQuery,
  AccountQueryVariables,
  useDealQuery,
  useUpdateDealMutation,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { authSelectors } from "@/src/store/auth/selector";
import { actions } from "@/src/store/auth/slice";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export function CompanyDetails() {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();

  const updateDeal = useUpdateDealMutation(client);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");

  const dealQuery = useDealQuery(client, {
    id: activeDealId ?? "",
  });

  useEffect(() => {
    if (dealQuery.data) {
      setName(dealQuery.data.deal.company.name);
      setWebsite(dealQuery.data.deal.company.website);
      setDescription(dealQuery.data.deal.company.description);
    }
  }, [dealQuery.data]);

  if (dealQuery.error) {
    return (
      <div className="w-full items-center justify-center flex">
        <p className="text-gray-500 text-sm font-semibold">
          Error loading company details
        </p>
      </div>
    );
  }

  if (dealQuery.isLoading || !dealQuery.data) {
    return (
      <div className="w-full justify-center items-center ">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full xl:w-1/2">
      <div>
        {/* <CompanyLogo
          logo={dealQuery.data.deal.company.logo}
          name={dealQuery.data.deal.company.name}
          bgColor={dealQuery.data.deal.company.logoColor}
        /> */}
        <p className="font-semibold text-gray-700 mt-3 text-md">
          Company details
        </p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateDeal.mutate(
              {
                input: {
                  id: activeDealId ?? "",
                  company: {
                    name,
                    website,
                    description,
                  },
                },
              },
              {
                onSuccess: () => {
                  toasts.success("Company details updated");
                  queryClient.invalidateQueries({
                    queryKey: ["Deal", { id: activeDealId ?? "" }],
                  });
                  client
                    .request<
                      AccountQuery,
                      AccountQueryVariables
                    >(AccountDocument)
                    .then((res) => {
                      if (res.account) {
                        dispatch(
                          actions.refreshAccount({ account: res.account }),
                        );
                      }
                    });
                },
                onError: () => {
                  toasts.error("Failed to update company details");
                },
              },
            );
          }}
          className="w-full max-w-[400px] mt-3"
        >
          <TextInput
            label="Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />

          <div className="mt-3 flex items-center leading-6 justify-between">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-900"
            >
              Description
            </label>
            <span className="text-xs text-gray-500">Optional</span>
          </div>
          <TextArea
            label="Description"
            value={description}
            name="description"
            placeholder="Enter a description for the company..."
            onChange={(e) => setDescription(e.currentTarget.value)}
            rows={3}
          />

          <TextInput
            label="Website"
            value={website}
            onChange={(e) => setWebsite(e.currentTarget.value)}
            placeholder="https://example.com"
            secondaryLabel="Optional"
            margin="m 0 0 0"
          />

          <div className="mt-6 flex items-center justify-end">
            <Button text="Save" variant="positive" />
          </div>
        </form>
      </div>
    </div>
  );
}
