import React, { useEffect, useState } from "react";
import { classNames } from "../../../utils/cn";
import {
  DataRoomFileFragmentFragment,
  Question as GqlQuestion,
  DealActivityType,
  DealRunner,
  FileVersionTextChange,
  useUpdateDataRoomFileMutation,
  useSimilarDataRoomFilesQuery,
  DataRoomFileOnlineStatus,
  DealRole,
  DataRoomFileSimilarityStatus,
  DataRoomFileVersion,
  FileType,
} from "../../../graphql/generated";
import {
  formatDistance,
  formatDistanceToNow,
  formatISO,
  fromUnixTime,
  secondsToMilliseconds,
} from "date-fns";
import { DeleteFile } from "../DeleteFileModal";
import { Link, useHistory } from "react-router-dom";
import { EditFile } from "../../../components/data_room/EditFile";
import { Card } from "../../../components/Card";
import { Avatar } from "../../../components/account/Avatar";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import {
  DealRoleRestricted,
  adminRoles,
  writeRoles,
} from "../../../components/DealRoleRestricted";
import Loading from "../../../components/Loading";
import { Activity } from "../../../components/activity/Activity";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors } from "../../../store/auth/selector";
import { DetailedFileHistoryModal } from "../../../components/data_room/DetailedFileHistoryModal";
import { TextDiffs } from "../../../components/tailwind/TextDiffs";
import { dataRoomFileSelectors } from "../../../store/dataRoomFile/selector";
import { AppState } from "../../../store";
import { actions } from "../../../store/dataRoomFile/slice";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Dropdown, { Option } from "../../../components/tailwind/Dropdown";
import { CommentInput } from "@/src/components/activity/CommentInput";
import { useQueryClient } from "@tanstack/react-query";
import { ActivityItems } from "@/src/components/activity/ActivityItems";
import { InfoIcon } from "lucide-react";
import { TripleDotMenu } from "@/src/components/TripleDotMenu";
import { Menu } from "@headlessui/react";
import { FilePill } from "@/src/components/FilePill";
import { DeleteVersionModal } from "./DeleteVersionModal";
import useGqlClient from "@/src/hooks/useGqlClient";
import { toasts } from "@/src/components/toasts/toasts";
import { Button } from "@/src/components/tailwind/Button";
import { RedlineExportModal } from "./RedlineExportModal";
import { FeatureFlag } from "@/src/components/posthog/FeatureFlag";

const adminTabs = [
  "Overview",
  "Comments",
  "Questions",
  "Versions",
  "Similar Documents",
  "Settings",
];
const nonAdminTabs = ["Questions"];
const adminViewerTabs = ["Overview", "Questions", "Versions"];

interface Props {
  dataRoomFile: DataRoomFileFragmentFragment;
  type: "seller" | "buyer";
}

export function Tabs(props: Props) {
  const activeDeal = useSelector(authSelectors.activeDeal);
  const activeDealAccount = useSelector(authSelectors.activeDealAccount);

  const client = useGqlClient();
  const { data, isError, isPending } = useSimilarDataRoomFilesQuery(client, {
    dataRoomFileID: props.dataRoomFile.id,
  });

  if (!activeDealAccount || !activeDeal) {
    return null;
  }

  let tabs: string[] = [];
  if (activeDeal.runner === DealRunner.Buyer) {
    tabs = adminTabs;
  } else if (activeDeal.runner === DealRunner.Seller) {
    if ([...adminRoles].includes(activeDealAccount.role)) {
      tabs = adminTabs;
    } else if (activeDealAccount.role === DealRole.DealAdminViewOnly) {
      tabs = adminViewerTabs;
    } else {
      tabs = nonAdminTabs;
    }
  } else {
    tabs = nonAdminTabs;
  }

  return (
    <TabContent
      dataRoomFile={props.dataRoomFile}
      tabs={tabs}
      similarDocumentsQuery={data}
    />
  );
}

function TabContent(props: {
  dataRoomFile: DataRoomFileFragmentFragment;
  tabs: string[];
  similarDocumentsQuery: any;
}) {
  const [selectedTab, setSelectedTab] = useState(props.tabs[0]);

  const tabs = props.dataRoomFile.deletedAt ? [] : props.tabs;

  // Calculate the number of detected similar documents with deduplication
  const similarDocumentsCount = props.similarDocumentsQuery?.similarDataRoomFiles
    ? props.similarDocumentsQuery.similarDataRoomFiles
        .filter((file: any) => file.status === "DETECTED")
        .reduce((uniqueFiles: Set<string>, file: any) => {
          // Add the ID of the other file to the Set
          if (file.dataRoomFile1?.id === props.dataRoomFile.id && file.dataRoomFile2?.id) {
            uniqueFiles.add(file.dataRoomFile2.id);
          } else if (file.dataRoomFile2?.id === props.dataRoomFile.id && file.dataRoomFile1?.id) {
            uniqueFiles.add(file.dataRoomFile1.id);
          }
          return uniqueFiles;
        }, new Set<string>())
        .size
    : 0;

  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          value={selectedTab}
          onChange={(e) => {
            setSelectedTab(e.currentTarget.value);
          }}
        >
          {tabs.map((tab) => (
            <option key={tab}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              onClick={() => {
                setSelectedTab(tab);
              }}
              key={tab}
              className={classNames(
                tab === selectedTab
                  ? "bg-gray-100 text-gray-700"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-md px-3 py-2 text-sm font-medium relative flex items-center gap-x-1.5",
              )}
              aria-current={tab === selectedTab ? "page" : undefined}
            >
              {tab}
              {tab === "Similar Documents" && similarDocumentsCount > 0 && (
                <span className="inline-flex items-center justify-center w-2 h-2 text-xs font-semibold text-white bg-orange-500 rounded-full"></span>
              )}
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-3">
        <TabRenderer
          selectedTab={selectedTab}
          file={props.dataRoomFile}
          setSelectedTab={setSelectedTab}
          similarDocumentsQuery={props.similarDocumentsQuery}
        />
      </div>
    </>
  );
}

function TabRenderer(props: {
  selectedTab: string;
  file: DataRoomFileFragmentFragment;
  setSelectedTab: (tab: string) => void;
  similarDocumentsQuery: any; // Adjust type as needed
}) {
  switch (props.selectedTab) {
    case "Overview":
      return (
        <Overview file={props.file} setSelectedTab={props.setSelectedTab} />
      );
    case "Comments":
      return <Comments file={props.file} />;
    case "Versions":
      return <Versions file={props.file} />;
    case "Settings":
      return <Settings file={props.file} />;
    case "Questions":
      return <Questions file={props.file} />;
    case "Similar Documents":
      return (
        <SimilarDocuments
          file={props.file}
          similarDocumentsQuery={props.similarDocumentsQuery}
        />
      );
    default:
      return (
        <Overview file={props.file} setSelectedTab={props.setSelectedTab} />
      );
  }
}

const stats = ["Views", "Downloads", "Versions"];

function Overview(props: {
  file: DataRoomFileFragmentFragment;
  setSelectedTab: (tab: string) => void;
}) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const c = props.file.onlineStatus === DataRoomFileOnlineStatus.Online;
    if (c !== checked) {
      setChecked(c);
    }
  }, [props.file.onlineStatus, checked]);

  return (
    <div>
      <DealRoleRestricted roles={adminRoles}>
        {/* {currentVersion ? (
            <Card padding="m" margin="m 0 0 0">
              <div className="flex items-center  gap-x-6">
                <div className="flex items-center gap-x-1">
                  {props.file.onlineStatus === DataRoomFileOnlineStatus.Online ? (
                    <div className="flex h-6 w-6  items-center justify-center bg-transparent animate-pulse">
                      <div
                        className={classNames(
                          "bg-green-400/10 text-green-400 ",
                          " rounded-full p-1"
                        )}
                      >
                        <div className="h-2 w-2 rounded-full bg-current" />
                      </div>
                    </div>
                  ) : (
                    <div className="flex h-6 w-6  items-center justify-center bg-transparent">
                      <div
                        className={classNames(
                          "h-1.5 w-1.5 rounded-full bg-gray-300 ring-1 ring-gray-300"
                        )}
                      />
                    </div>
                  )}
                  <p
                    className={`text-sm ${
                      props.file.onlineStatus === DataRoomFileOnlineStatus.Online
                        ? "text-green-500"
                        : "text-gray-500"
                    }`}
                  >
                    {props.file.onlineStatus === DataRoomFileOnlineStatus.Online
                      ? "Live"
                      : "Offline"}
                  </p>
                </div>
                <div className="flex items-center flex-1 justify-between">
                  <div className="flex items-center gap-x-1.5">
                    <p className="text-sm font-semibold text-gray-600">
                      Version {currentVersion.versionNumber}
                      <span className="ml-2 text-xs leading-2 text-gray-500 font-normal">
                        {currentVersion.summary
                          ? `${currentVersion.summary.slice(
                              0,
                              Math.min(currentVersion.summary.length, 20)
                            )}${currentVersion.summary.length > 20 ? "..." : ""}`
                          : "No summary"}
                      </span>
                    </p>
                  </div>

                  <div className="flex items-center gap-x-2">
                    <p
                      className={`text-sm ${
                        props.file.onlineStatus ===
                        DataRoomFileOnlineStatus.Online
                          ? "text-green-500"
                          : "text-gray-500"
                      }`}
                    >
                      {props.file.onlineStatus === DataRoomFileOnlineStatus.Online
                        ? "Available"
                        : "Unavailable"}{" "}
                      in the Data Room
                    </p>
                    <Switch
                      checked={checked}
                      onCheckedChange={(c) => {
                        setChecked(c);
                        if (onlineCheckTimeout) {
                          clearTimeout(onlineCheckTimeout);
                        }

                        onlineCheckTimeout = setTimeout(() => {
                          updateDataRoomFile.mutate(
                            {
                              input: {
                                id: props.file.id,
                                onlineStatus: c
                                  ? DataRoomFileOnlineStatus.Online
                                  : DataRoomFileOnlineStatus.Offline,
                              },
                            },
                            {
                              onSuccess: () => {
                                queryClient.invalidateQueries({
                                  queryKey: [
                                    "DataRoomFile",
                                    { id: props.file.id },
                                  ],
                                });
                                queryClient.invalidateQueries({
                                  queryKey: [
                                    "DataRoomFileView",
                                    { id: props.file.id },
                                  ],
                                });
                              },
                            }
                          );
                        }, 500);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Card>
          ) : null} */}
        <dl className="mt-2 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
          {stats.map((item) => (
            <div key={item} className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">{item}</dt>
              <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-gray-700">
                  {item === "Views"
                    ? props.file.stats.views
                    : item === "Downloads"
                      ? props.file.stats.downloads
                      : props.file.versions.length}
                </div>
              </dd>
            </div>
          ))}
        </dl>
      </DealRoleRestricted>

      <DealRoleRestricted roles={adminRoles}>
        <div className="mt-8">
          <p className="font-semibold text-gray-700">Activity</p>
          <Activity
            input={{
              dataRoomFileID: props.file.id,
              count: 30,
            }}
            mode="verbose"
          />
        </div>
      </DealRoleRestricted>
    </div>
  );
}

function Comments(props: { file: DataRoomFileFragmentFragment }) {
  const queryClient = useQueryClient();
  const comments = props.file.activity
    .filter((a) => a.type === DealActivityType.Comment)
    .sort((a, b) => a.createdAt - b.createdAt);
  return (
    <div>
      <div className="mt-3 mx-3 px-3 flex items-center gap-x-1 rounded-md bg-gray-100 py-2 border border-gray-300">
        <InfoIcon className="w-4 h-4 text-gray-500" />
        <p className="text-gray-500 font-semibold text-sm">
          Comments are only visible to people in your firm
        </p>
      </div>
      <div className="mt-4 px-3">
        <ActivityItems items={comments} />

        <CommentInput
          input={{
            dataRoomFileId: props.file.id,
            comment: "",
            files: [],
          }}
          onCommented={() => {
            queryClient.invalidateQueries({
              queryKey: ["DataRoomFile", { id: props.file.id }],
            });
          }}
        />
      </div>
    </div>
  );
}

function Versions(props: { file: DataRoomFileFragmentFragment }) {
  const [showFullHistory, setShowFullHistory] = useState(false);
  const [expandedVersion, setExpandedVersion] = useState<string>("");
  const [deleteVersion, setDeleteVersion] = useState<string>("");
  const [redlineExport, setRedlineExport] = useState<{
    sourceId: string;
    targetId: string;
  } | null>(null);

  return (
    <div className="mt-2">
      <ul role="list" className="mt-3 space-y-6">
        <Card>
          <TransitionGroup className="">
            {props.file.versions.map((version, i) => {
              return (
                <CSSTransition
                  key={version.id}
                  timeout={300} // This controls the duration of the animation
                  classNames="fade-slide-down"
                  onEnter={(node: any) => node.offsetHeight} // Trigger reflow to enable animation
                >
                  <li key={version.id}>
                    <Version
                      fileId={props.file.id}
                      version={version}
                      isFirst={i === 0}
                      isLast={i === props.file.versions.length - 1}
                      currentLiveVersionId={props.file.currentLiveVersion.id}
                      expandedVersionId={expandedVersion}
                      setExpandedVersion={setExpandedVersion}
                      file={props.file}
                      onDelete={() => {
                        setDeleteVersion(version.id);
                      }}
                      onRedlineExport={(sourceId, targetId) => {
                        setRedlineExport({ sourceId, targetId });
                      }}
                    />
                  </li>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </Card>
      </ul>
      <DetailedFileHistoryModal
        dataRoomFileId={props.file.id}
        open={showFullHistory}
        onClose={() => {
          setShowFullHistory(false);
        }}
      />
      <DeleteVersionModal
        open={deleteVersion !== ""}
        onClose={() => {
          setDeleteVersion("");
        }}
        dataRoomFile={props.file}
        versionId={deleteVersion}
      />
      <RedlineExportModal
        open={redlineExport !== null}
        onClose={() => {
          setRedlineExport(null);
        }}
        sourceVersionId={redlineExport?.sourceId || ""}
        targetVersionId={redlineExport?.targetId || ""}
        file={props.file}
      />
    </div>
  );
}

function Version(props: {
  fileId: string;
  version: DataRoomFileFragmentFragment["versions"][0];
  isLast: boolean;
  isFirst: boolean;
  currentLiveVersionId: string;
  expandedVersionId: string;
  setExpandedVersion: (versionId: string) => void;
  onDelete: () => void;
  onRedlineExport: (sourceVersionId: string, targetVersionId: string) => void;
  file: DataRoomFileFragmentFragment;
}) {
  const version = props.version;
  const dispatch = useDispatch();
  const selectedFileVersionId = useSelector((state: AppState) =>
    dataRoomFileSelectors.selectedFileVersionId(state, props.fileId),
  );

  const client = useGqlClient();
  const queryClient = useQueryClient();
  const updateDataRoomFile = useUpdateDataRoomFileMutation(client);

  const isSelectedVersion = selectedFileVersionId === version.id;

  return (
    <div
      className={classNames(
        " gap-x-4 transition-all duration-300 ease-in-out cursor-pointer hover:bg-gray-50 py-3",
        !props.isLast ? "border-b border-gray-100" : "",
        props.isLast ? "rounded-b-md" : "",
        props.isFirst ? "rounded-t-md" : "",
      )}
      onClick={() => {
        if (props.expandedVersionId === version.id) {
          props.setExpandedVersion("");
        } else {
          props.setExpandedVersion(version.id);
        }

        if (isSelectedVersion) {
          return;
        }

        dispatch(
          actions.setSelectedFileVersionId({
            fileId: props.fileId,
            versionId: version.id,
          }),
        );
      }}
    >
      <div>
        <div className="relative flex justify-between items-center space-y-2 px-2 ">
          <div className="flex  items-start gap-x-2 -py-0.5">
            {props.currentLiveVersionId === props.version.id ? (
              <div className="flex h-6 w-6  items-center justify-center bg-transparent">
                <div
                  className={classNames(
                    "bg-green-400/10 text-green-400 ",
                    " rounded-full p-1",
                  )}
                >
                  <div className="h-2 w-2 rounded-full bg-current" />
                </div>
              </div>
            ) : (
              <div className="flex h-6 w-6 items-center justify-center bg-transparent">
                <div
                  className={classNames(
                    "h-1.5 w-1.5 rounded-full bg-gray-300 ring-1 ring-gray-300",
                  )}
                />
              </div>
            )}

            <div className="">
              <p className="text-sm font-semibold text-gray-600">
                Version {version.versionNumber}
                <span className="ml-2 text-xs leading-2 text-gray-500 font-normal">
                  {version.summary ? version.summary : "No summary"}
                </span>
              </p>

              <div className="mt-1 flex items-center  gap-x-1.5">
                <Avatar account={version.createdBy} size="xs" />
                <p className="text-xs leading-5 text-gray-500 font-light">
                  <span className="font-semibold text-xs text-gray-500">
                    {version.createdBy.name}
                  </span>{" "}
                  uploaded{" "}
                  <time
                    title={formatISO(secondsToMilliseconds(version.createdAt))}
                    dateTime={formatISO(
                      secondsToMilliseconds(version.createdAt),
                    )}
                    className="flex-none"
                  >
                    {formatDistance(
                      secondsToMilliseconds(version.createdAt),
                      new Date(),
                      {
                        addSuffix: true,
                      },
                    )}
                  </time>
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <FilePill
              id={props.fileId}
              name={props.version.fileName}
              type={props.version.fileType}
              showDetailsCard={false}
            />
            <DealRoleRestricted roles={writeRoles}>
              <TripleDotMenu>
                {props.currentLiveVersionId !== version.id ? (
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          updateDataRoomFile.mutate(
                            {
                              input: {
                                id: props.fileId,
                                currentLiveVersionID: version.id,
                              },
                            },
                            {
                              onSuccess: () => {
                                queryClient.invalidateQueries({
                                  queryKey: [
                                    "DataRoomFile",
                                    { id: props.fileId },
                                  ],
                                });
                                queryClient.invalidateQueries({
                                  queryKey: [
                                    "DataRoomFile",
                                    { id: props.fileId },
                                  ],
                                });
                                toasts.success(
                                  `Version ${version.versionNumber} set as live version`,
                                );
                              },
                            },
                          );
                        }}
                        className={classNames(
                          active ? "bg-gray-50 text-indigo-700" : "",
                          "block px-3 py-1 text-sm leading-6 text-indigo-600 cursor-pointer",
                        )}
                      >
                        Set as live version
                      </div>
                    )}
                  </Menu.Item>
                ) : null}
                <FeatureFlag flag="redlining">
                  {props.file.fileType === FileType.Docx &&
                  props.file.versions.length > 1 &&
                  version.versionNumber > 1 ? (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            // Find the previous version to use as source
                            const previousVersionId = getPreviousVersionId(
                              props.file.versions as DataRoomFileVersion[],
                              version as DataRoomFileVersion,
                            );
                            if (previousVersionId) {
                              props.onRedlineExport(
                                previousVersionId,
                                version.id,
                              );
                            } else {
                              toasts.error(
                                "No previous version available for redline comparison",
                              );
                            }
                          }}
                          className={classNames(
                            active ? "bg-gray-50 text-indigo-700" : "",
                            "block px-3 py-1 text-sm leading-6 text-indigo-600 cursor-pointer",
                          )}
                        >
                          Download redline
                        </div>
                      )}
                    </Menu.Item>
                  ) : null}
                </FeatureFlag>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onDelete();
                      }}
                      className={classNames(
                        active ? "bg-gray-50 text-red-700" : "",
                        "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer",
                      )}
                    >
                      Delete version
                    </div>
                  )}
                </Menu.Item>
              </TripleDotMenu>
            </DealRoleRestricted>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          props.expandedVersionId === version.id
            ? "visible opacity-100 max-h-[1000px] transition-all duration-300 ease-in mt-3 overflow-y-scroll"
            : "invisible opacity-0 max-h-0 overflow-hidden transition-all duration-300 ease-out",
          "pr-3 pl-10 ",
        )}
      >
        <p className="text-sm font-semibold text-gray-600">Changes</p>
        <div className="my-3">
          {version.changes.textChanges.map((tc) => {
            return (
              <div key={tc.section} className="mt-2">
                <VersionTextChanges textChange={tc} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const versionTextChangeOptions: Option[] = [
  {
    label: "Compare",
    value: "compare",
  },
  {
    label: "New",
    value: "new",
  },
  {
    label: "Previous",
    value: "previous",
  },
];

function VersionTextChanges(props: { textChange: FileVersionTextChange }) {
  const [selectedOption, setSelectedOption] = useState<Option>(
    versionTextChangeOptions[0],
  );

  function decodeHTMLEntities(text: string): string {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  }

  function extractLines(html: string): string[] {
    return decodeHTMLEntities(html)
      .split(/<\/?p[^>]*>/gi)
      .map((str) => str.trim())
      .filter(Boolean);
  }

  const newLines = extractLines(props.textChange.new);
  const prevLines = extractLines(props.textChange.previous);

  return (
    <div>
      <div className="bg-white rounded-md p-2 border border-gray-200">
        <div className="flex items-center justify-between mb-1">
          <p className="font-semibold text-gray-600 text-xs">
            {props.textChange.section}
          </p>
          <Dropdown
            variant="text"
            size="xs"
            options={versionTextChangeOptions}
            selectedOption={selectedOption}
            onSelect={(o) => {
              setSelectedOption(o);
            }}
          />
        </div>
        {selectedOption.value === "new" ? (
          <p className="text-xs  text-gray-500">
            {newLines.map((line, i) => (
              <React.Fragment key={i}>
                {/* Insert a <br/> before all but the first line. */}
                {i > 0 && <br />}
                {line}
              </React.Fragment>
            ))}
          </p>
        ) : null}
        {selectedOption.value === "previous" ? (
          <p className="text-xs  text-gray-500">
            {prevLines.map((line, i) => (
              <React.Fragment key={i}>
                {/* Insert a <br/> before all but the first line. */}
                {i > 0 && <br />}
                {line}
              </React.Fragment>
            ))}
          </p>
        ) : null}
        {selectedOption.value === "compare" ? (
          <TextDiffs diffs={props.textChange.diffs} />
        ) : null}
      </div>
    </div>
  );
}

function Settings(props: { file: DataRoomFileFragmentFragment }) {
  const history = useHistory();
  const [showDeleteFile, setShowDeleteFile] = useState(false);
  const [showEditFile, setShowEditFile] = useState(false);

  return (
    <Card padding="m">
      <div className="grid grid-cols-1 gap-y-4 xl:gap-y-0 xl:grid-cols-2">
        <div>
          <p className="mt-2 text-sm font-semibold text-gray-600">
            Permission level
          </p>
          <div className="mt-1 ">
            <p className="text-sm font-semibold text-gray-700">
              {props.file.permission.name}
            </p>
            <p className="text-xs text-gray-500/80">
              {props.file.permission.description}
            </p>
          </div>
        </div>

        <div>
          <p className="mt-2 text-sm font-semibold text-gray-600">
            Description
          </p>
          <div className="mt-1 flex flex-wrap">
            <p className="text-sm text-gray-500/80">
              {props.file.description
                ? props.file.description
                : "No file description"}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-6 ">
        <div className="w-full h-px bg-gray-200 my-4"></div>
        <button
          onClick={() => {
            setShowEditFile(true);
          }}
          className="text-blue-700 text-sm px-2 py-1.5 rounded-md hover:bg-blue-50 font-semibold"
        >
          Edit file
        </button>
        <button
          onClick={() => {
            setShowDeleteFile(true);
          }}
          className="text-red-700 text-sm ml-4 px-2 py-1.5 rounded-md hover:bg-red-50 font-semibold"
        >
          Delete file
        </button>
      </div>
      <DeleteFile
        open={showDeleteFile}
        dataRoomFileId={props.file.id}
        onDeleted={() => {
          history.push(`/deal/documents/`);
        }}
        onClose={() => {
          setShowDeleteFile(false);
        }}
      />
      <EditFile
        dataRoomFileId={props.file.id}
        open={showEditFile}
        onClose={() => {
          setShowEditFile(false);
        }}
      />
    </Card>
  );
}

function Questions(props: { file: DataRoomFileFragmentFragment }) {
  return (
    <div>
      {props.file.questions.length === 0 ? (
        <p className="px-3 text-gray-500/80 text-sm">No questions</p>
      ) : (
        <Card margin="s 0 0 0">
          {props.file.questions.map((question, i) => {
            return (
              <>
                <Question
                  key={question.id}
                  question={question as GqlQuestion}
                  isFirst={i === 0}
                  isLast={i === props.file.questions.length - 1}
                />
                {i !== props.file.questions.length - 1 ? (
                  <div className="border-b border-gray-200" />
                ) : null}
              </>
            );
          })}
        </Card>
      )}
    </div>
  );
}

interface QuestionProps {
  question: GqlQuestion;
  isFirst: boolean;
  isLast: boolean;
}

function Question(props: QuestionProps) {
  return (
    <div
      key={props.question.id}
      className={`w-full 3xl:w-4/5 hover:bg-gray-200/70 cursor-pointer ${
        props.isFirst ? "rounded-t-md" : ""
      } ${props.isLast ? "rounded-b-md" : ""}`}
    >
      <Link to={`/deal/questions/${props.question.id}`}>
        <div className="flex px-3 py-2 items-center space-x-3">
          <Avatar account={props.question.createdBy} />
          <div className="flex-1">
            <p className="text-gray-700 font-bold">{props.question.title}</p>
            <p className="text-sm text-gray-500 leading-tight text-ellipsis">
              Created{" "}
              {formatDistanceToNow(fromUnixTime(props.question.createdAt), {
                addSuffix: true,
              })}
            </p>
          </div>
          <div className="flex gap-x-2">
            <ChatBubbleLeftIcon className="mt-0.5 w-5 h-5 text-gray-500" />
            <p className="text-gray-600">
              {
                props.question.activity.filter(
                  (a) => a.type === DealActivityType.Comment,
                ).length
              }
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}

function SimilarDocuments(props: {
  file: DataRoomFileFragmentFragment;
  similarDocumentsQuery: any;
}) {
  const history = useHistory();

  if (!props.similarDocumentsQuery) {
    return <Loading />;
  }

  const allSimilarFiles = props.similarDocumentsQuery.similarDataRoomFiles;

  // Process the similar files to get unique entries with the corresponding similar file
  const processedFiles = allSimilarFiles.reduce((acc: any[], file: any) => {
    let similarFile: DataRoomFileFragmentFragment;
    if (file.dataRoomFile1?.id === props.file.id) {
      similarFile = file.dataRoomFile2;
    } else if (file.dataRoomFile2?.id === props.file.id) {
      similarFile = file.dataRoomFile1;
    } else {
      return acc; // Skip this entry if neither matches
    }
    
    if (!similarFile) {
      return acc;
    }
    
    // Check if we already have this file in our processed list
    const existingIndex = acc.findIndex((item) => item.similarFile?.id === similarFile.id);
    
    if (existingIndex >= 0) {
      if (file.similarity > acc[existingIndex].similarity) {
        acc[existingIndex] = { ...file, similarFile };
      }
    } else {
      acc.push({ ...file, similarFile });
    }
    
    return acc;
  }, []);

  if (processedFiles.length === 0) {
    return (
      <div className="mt-3 mx-3 px-3 flex items-center gap-x-1 rounded-md bg-gray-100 py-2 border border-gray-300">
        <InfoIcon className="w-4 h-4 text-gray-500" />
        <p className="text-gray-500 font-semibold text-sm">
          No similar documents found
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className="mt-3 mx-3 px-3 flex items-center gap-x-1 bg-white rounded-md p-2 border border-gray-200 overflow-hidden">
        <table className="min-w-full rounded-lg border-separate border-spacing-0 rounded-b-lg text-sm">
          <thead className="font-semibold">
            <tr>
              <th scope="col" className="py-3 pl-3 pr-8 text-left">
                File Name
              </th>
              <th scope="col" className="px-3 py-3.5 text-left">
                Created On
              </th>
              <th scope="col" className="px-3 py-3.5 text-left">
                Similarity Score
              </th>
              <th scope="col" className="px-3 py-3.5 text-left">
                Status
              </th>
              <th scope="col" className="px-3 py-3.5 text-left"></th>
            </tr>
          </thead>
          <tbody className="rounded-b-lg text-sm text-gray-600">
            {processedFiles.map((file: any, index: number) => {
              const isLast = index === processedFiles.length - 1;
              const similarFile = file.similarFile;
              
              return (
                <tr
                  className={classNames(
                    "bg-white hover:cursor-pointer",
                    !isLast
                      ? "border-y border-gray-500"
                      : "border-t border-gray-500",
                  )}
                  key={similarFile.id}
                >
                  <td
                    className={classNames(
                      "pl-4 pr-3 py-3.5 whitespace-nowrap bg-white truncate max-w-[150px]",
                      isLast
                        ? "border-t border-gray-200"
                        : "border-y border-gray-200",
                    )}
                    onClick={() => {
                      if (!similarFile.folderId) {
                        return;
                      }
                      const link = `/deal/documents/folder/${similarFile.folderId}/file/${similarFile.id}`;
                      history.push(link);
                    }}
                  >
                    {similarFile.name || "Untitled"}
                  </td>
                  <td
                    className={classNames(
                      "px-3 py-3.5 whitespace-nowrap bg-white",
                      isLast
                        ? "border-t border-gray-200"
                        : "border-y border-gray-200",
                    )}
                  >
                    {similarFile.createdAt ? formatDistanceToNow(
                      fromUnixTime(similarFile.createdAt),
                      {
                        addSuffix: true,
                      },
                    ) : "Unknown date"}
                  </td>
                  <td
                    className={classNames(
                      "px-3 py-3.5 whitespace-nowrap bg-white",
                      isLast
                        ? "border-t border-gray-200"
                        : "border-y border-gray-200",
                    )}
                  >
                    {file.similarity ? (file.similarity * 100).toFixed(0) : 0}%
                  </td>
                  <td
                    className={classNames(
                      "px-3 py-3.5 whitespace-nowrap bg-white",
                      isLast
                        ? "border-t border-gray-200"
                        : "border-y border-gray-200",
                    )}
                  >
                    {file.status ? file.status.toLowerCase() : "Unknown"}
                  </td>
                  <td
                    className={classNames(
                      "px-3 py-3.5 whitespace-nowrap bg-white",
                      isLast
                        ? "border-t border-gray-200"
                        : "border-y border-gray-200",
                    )}
                  >
                    {file.status === DataRoomFileSimilarityStatus.Detected ? (
                      <Button
                        variant="neutral"
                        size="s"
                        onClick={() => {
                          history.push(
                            `/deal/toolkit/merge-documents/${props.file.id}/${similarFile.id}`,
                          );
                        }}
                        text="Merge"
                      />
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function getPreviousVersionId(
  versions: DataRoomFileVersion[],
  currentVersion: DataRoomFileVersion,
): string {
  // Sort versions by version number
  const sortedVersions = [...versions].sort(
    (a, b) => a.versionNumber - b.versionNumber,
  );

  // Find the index of the current version
  const currentIndex = sortedVersions.findIndex(
    (v) => v.id === currentVersion.id,
  );

  // If it's the first version or not found, there's no previous version
  if (currentIndex <= 0) {
    return ""; // Handle this case in your UI
  }

  // Return the ID of the previous version
  return sortedVersions[currentIndex - 1].id;
}
