import { useEffect, useState } from "react";
import { getUnixTime } from "date-fns";
import {
  DataRoomFolderQuery,
  useDataRoomFolderQuery,
  useUpdateDataRoomFolderMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { ExclamationCircleIcon, FolderIcon } from "@heroicons/react/24/solid";
import { Dialog } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import { AnimatedModal } from "../../components/AnimatedModal";
import { toasts } from "../../components/toasts/toasts";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  

interface DeleteFolderModalProps {
    onClose: () => void;
    open: boolean;
  }
  
  function DeleteFolderModal(props: DeleteFolderModalProps) {
    const id = useQuery().get("deleteFolder");
  
    return (
      <AnimatedModal onClose={props.onClose} open={props.open}>
        <DeleteFolderContent
          id={id}
          onCancel={() => props.onClose()}
          onDelete={() => props.onClose()}
        />
      </AnimatedModal>
    );
  }
  
  interface DeleteFolderContentProps {
    id: string | null;
    onCancel: () => void;
    onDelete: () => void;
  }
  
  function DeleteFolderContent(props: DeleteFolderContentProps) {
    const client = useGqlClient();
    const queryClient = useQueryClient();
    const folderQuery = useDataRoomFolderQuery(client, {
      id: props.id || "",
    });
  
    const updateDataRoomFolder = useUpdateDataRoomFolderMutation(client);
  
    const [data, setData] = useState<DataRoomFolderQuery | null>(null);
  
    useEffect(() => {
      if (folderQuery.data) {
        setData(folderQuery.data);
      }
    }, [folderQuery.data]);
  
    if (folderQuery.isPending && !data) {
      return (
        <div>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <FolderIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900"
              >
                Loading...
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">Please wait</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  
    if (folderQuery.error || !data) {
      return (
        <div>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationCircleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900"
              >
                Something went wrong
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  We couldn't load the folder. Please try again.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <FolderIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              {`Delete ${data.dataRoomFolder.name}`}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Are you sure you want to delete the {data.dataRoomFolder.name}{" "}
                folder? All files and folders inside will be deleted.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            onClick={() => {
              updateDataRoomFolder.mutate(
                {
                  input: {
                    id: data.dataRoomFolder.id,
                    deletedAt: getUnixTime(new Date()),
                  },
                },
                {
                  onSuccess: () => {
                    queryClient
                      .invalidateQueries({
                        queryKey: [
                          "DataRoomFolder",
                          {
                            id:
                              data.dataRoomFolder.parentFolders.length > 1
                                ? data.dataRoomFolder.parentFolders[1].id
                                : data.dataRoomFolder.id,
                          },
                        ],
                      })
                      .then(() => {
                        props.onDelete();
                      });
  
                    toasts.success(`${data.dataRoomFolder.name} deleted`);
                  },
                },
              );
            }}
          >
            {updateDataRoomFolder.isPending ? "Deleting..." : "Delete"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

export default DeleteFolderModal;