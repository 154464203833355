import {
  DealThreadMessageRole,
  DealThreadMessageStatus,
  useCreateDealThreadMutation,
  useCreateDealThreadMessageMutation,
  DealThreadDocument,
  DealThreadsDocument,
  DealThreadsQuery,
  DealThreadsQueryVariables,
  DealThreadQuery,
  DealThreadQueryVariables,
  DealThreadMessageContentQuoteStatus,
  DealThreadMessageQuery,
  DealThreadMessageQueryVariables,
  DealThreadMessageDocument,
  DealThreadMessage,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { authSelectors } from "@/src/store/auth/selector";
import { useDispatch, useSelector } from "react-redux";
import { SlideOver } from "../../SlideOver";
import { assistantSelectors } from "@/src/store/assistant/selector";
import {
  BotIcon,
  CheckCircleIcon,
  FileWarningIcon,
  PinIcon,
  PlusIcon,
  SquareArrowRightIcon,
} from "lucide-react";
import { Button } from "../../tailwind/Button";
import { toasts } from "../../toasts/toasts";
import { AppState } from "@/src/store";
import { actions } from "@/src/store/assistant/slice";
import { useEffect, useState, useRef, useCallback } from "react";
import {
  DealThreadMessageCreatedData,
  useWebSocket,
} from "@/src/contexts/websockets";
import { classNames } from "@/src/utils/cn";
import { animated, useSpring } from "react-spring";
import ReactMarkdown from "react-markdown";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { FilePill } from "../../FilePill";
import { Pills } from "../../Pills";
import { v4 as uuid } from "uuid";
import { Loader } from "../../Loading";
import { Spinner } from "../../icons/Spinner";
import { PreviousChats } from "./previous_chats";
import { getUnixTime } from "date-fns";
import { useManualRefresh } from "@/src/hooks/useManualRefresh";
import { gqlDealThreadMessageToWebsocketDealThreadMessage } from "@/src/utils/typeconv";

export function Assistant(props: { onClose: () => void; open: boolean }) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const createDealThread = useCreateDealThreadMutation(client);
  const dispatch = useDispatch();
  const activeDeal = useSelector(authSelectors.activeDeal);
  const assistantPanelState = useSelector(
    assistantSelectors.assistantPanelState,
  );

  useEffect(() => {
    if (!activeDealId) {
      return;
    }

    client
      .request<DealThreadsQuery, DealThreadsQueryVariables>(
        DealThreadsDocument,
        {
          dealId: activeDealId,
        },
      )
      .then((res) => {
        if (res.dealThreads.length === 0) {
          createDealThread.mutate(
            {
              input: {
                dealID: activeDealId,
              },
            },
            {
              onSuccess: (res) => {
                dispatch(
                  actions.setActiveDealThreadId(res.createDealThread.id),
                );
              },
            },
          );

          return;
        }

        dispatch(actions.setActiveDealThreadId(res.dealThreads[0].id));
      });
  }, [activeDealId, createDealThread, dispatch, client]);

  return (
    <SlideOver
      withOutsideClick={assistantPanelState === "unpinned"}
      withBackground={false}
      onClose={props.onClose}
      open={props.open}
    >
      <div className="flex h-full  flex-col overflow-y-scroll no-scrollbar bg-white border-b border-gray-500 z-50 pt-3 ">
        <div className="px-1 sm:px-3 shadow-xl pb-2 border-b border-gray-300">
          <div className="flex items-start  justify-between">
            <div className="flex items-start gap-x-2">
              <button
                type="button"
                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => props.onClose()}
              >
                <span className="sr-only">Close panel</span>
                <SquareArrowRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <div className="">
                <p className="leading-none font-semibold text-gray-700 text-md">
                  Deal Assistant
                </p>
                <p className="text-sm text-gray-500/80">
                  {activeDeal ? activeDeal.company.name : ""}
                </p>
              </div>
            </div>
            <div className="ml-3 flex h-7 gap-x-2 items-center">
              <Button
                variant={
                  assistantPanelState === "pinned"
                    ? "neutral/inverted"
                    : "neutral"
                }
                text=""
                icon={PinIcon}
                size="s"
                onClick={() => {
                  dispatch(
                    actions.setAssistantPanelState(
                      assistantPanelState === "pinned" ? "unpinned" : "pinned",
                    ),
                  );
                }}
              />
              <PreviousChats />
              <Button
                icon={PlusIcon}
                variant="neutral"
                text="New chat"
                size="s"
                onClick={() => {
                  if (!activeDealId) return;

                  createDealThread.mutate(
                    {
                      input: {
                        dealID: activeDealId,
                      },
                    },
                    {
                      onError: () => {
                        toasts.error("Failed to create chat");
                      },
                      onSuccess: (data) => {
                        dispatch(
                          actions.setActiveDealThreadId(
                            data.createDealThread.id,
                          ),
                        );

                        for (const message of data.createDealThread.messages) {
                          dispatch(
                            actions.addDealThreadMessage({
                              dealThreadId: data.createDealThread.id,
                              message: {
                                dealThreadID: data.createDealThread.id,
                                dealThreadMessageID: message.id,
                                dealThreadMessage: {
                                  id: message.id,
                                  status: message.status,
                                  role: message.role,
                                  content: {
                                    type: "text",
                                    text: {
                                      value: message.content.text.value,
                                    },
                                    quotes: [],
                                    files: [],
                                  },
                                  createdAt: message.createdAt,
                                },
                              },
                            }),
                          );
                        }
                      },
                    },
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="relative overflow-y-scroll  flex-1 ">
          <Chat />
        </div>
      </div>
    </SlideOver>
  );
}

let scrollTimeout: NodeJS.Timeout | null = null;

function Chat() {
  const [newMessage, setNewMessage] = useState("");

  const { subscribeToTopic, unsubscribeFromTopic } = useWebSocket();

  const client = useGqlClient();
  const activeDealThreadId = useSelector(assistantSelectors.activeDealThreadId);

  const createDealThreadMessage = useCreateDealThreadMessageMutation(client);

  const dealThreadMessages = useSelector((state: AppState) =>
    assistantSelectors.dealThreadMessages(state, activeDealThreadId ?? ""),
  );

  const lastMessage =
    dealThreadMessages && dealThreadMessages.length > 0
      ? dealThreadMessages[dealThreadMessages.length - 1]
      : undefined;
  const [chatDisabled, setChatDisabled] = useState(false);

  useEffect(() => {
    if (!lastMessage) {
      return;
    }

    if (
      lastMessage.dealThreadMessage.role === DealThreadMessageRole.User &&
      (lastMessage.dealThreadMessage.status ===
        DealThreadMessageStatus.Sending ||
        lastMessage.dealThreadMessage.status === DealThreadMessageStatus.Sent)
    ) {
      //   setChatDisabled(true);
      return;
    }

    setChatDisabled(false);
    textAreaRef?.current?.focus();
  }, [
    lastMessage,
    lastMessage?.dealThreadMessage.status,
    lastMessage?.dealThreadMessage.role,
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeDealThreadId) {
      return;
    }

    client
      .request<DealThreadQuery, DealThreadQueryVariables>(DealThreadDocument, {
        id: activeDealThreadId,
      })
      .then((res) => {
        res.dealThread.messages.forEach((message) => {
          dispatch(
            actions.addDealThreadMessage({
              dealThreadId: activeDealThreadId,
              message: gqlDealThreadMessageToWebsocketDealThreadMessage(
                message as DealThreadMessage,
                activeDealThreadId,
              ),
            }),
          );
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDealThreadId]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const createCommentRef = useRef<HTMLButtonElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    scrollTimeout = setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }, [dealThreadMessages]);

  useEffect(
    () => {
      if (!activeDealThreadId) {
        return;
      }

      subscribeToTopic(`deal_thread:${activeDealThreadId}`);

      return () => {
        unsubscribeFromTopic(`deal_thread:${activeDealThreadId}`);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeDealThreadId],
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (createCommentRef.current) {
        createCommentRef.current.click();
      }
    }
  };

  const [seenMessageIds, setSeenMessageIds] = useState(new Set());

  useEffect(() => {
    if (!dealThreadMessages) return;

    const newIds = dealThreadMessages
      .map((msg) => msg.dealThreadMessage.id)
      .filter((id) => !seenMessageIds.has(id));

    if (newIds.length > 0) {
      setTimeout(() => {
        setSeenMessageIds((prev) => {
          const updated = new Set(prev);
          newIds.forEach((id) => updated.add(id));
          return updated;
        });
      }, 500);
    }
  }, [dealThreadMessages, seenMessageIds]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex-1 overflow-y-scroll bg-gray-50 px-4">
        {dealThreadMessages &&
          dealThreadMessages.map((message) => {
            if (!message.dealThreadMessage.content) {
              return null;
            }

            const isNew = !seenMessageIds.has(message.dealThreadMessage.id);

            return (
              <AnimatedMessage
                key={message.dealThreadMessage.id}
                message={message}
                isNew={isNew}
              />
            );
          })}
        <div ref={scrollRef} />
      </div>

      <div className="p-4 border-t bg-gray-50">
        <div className="relative flex-auto">
          <div
            className={classNames(
              chatDisabled ? "opacity-50 focus-within:ring-gray-300" : "",
              "bg-white overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-indigo-600",
            )}
          >
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              ref={textAreaRef}
              rows={2}
              name="comment"
              value={newMessage}
              onKeyDown={(e) => {
                handleKeyDown(e);
              }}
              id="comment"
              onChange={(e) => {
                if (chatDisabled) {
                  return;
                }

                setNewMessage(e.currentTarget.value);
              }}
              className={classNames(
                chatDisabled ? "opacity-50" : "",
                "p-4 focus:outline-none block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
              )}
              placeholder="Send a message..."
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5"></div>
            <button
              disabled={chatDisabled}
              ref={createCommentRef}
              onClick={() => {
                if (!newMessage) {
                  return;
                }

                const newId = `deal_thread_msg_${uuid()}`;
                const messageToAdd: DealThreadMessageCreatedData = {
                  dealThreadID: activeDealThreadId ?? "",
                  dealThreadMessageID: newId,
                  dealThreadMessage: {
                    id: newId,
                    role: DealThreadMessageRole.User,
                    status: DealThreadMessageStatus.Sending,
                    content: {
                      text: {
                        value: newMessage,
                      },
                      quotes: [],
                      files: [],
                      type: "text",
                    },
                    createdAt: getUnixTime(new Date()),
                  },
                };

                dispatch(
                  actions.addDealThreadMessage({
                    dealThreadId: activeDealThreadId ?? "",
                    message: messageToAdd as DealThreadMessageCreatedData,
                  }),
                );

                setNewMessage("");

                createDealThreadMessage.mutate(
                  {
                    input: {
                      id: newId,
                      message: newMessage,
                      dealThreadID: activeDealThreadId ?? "",
                    },
                  },
                  {
                    onError: (e) => {
                      dispatch(
                        actions.updateDealThreadMessageStatus({
                          dealThreadId: activeDealThreadId ?? "",
                          messageId: newId,
                          status: DealThreadMessageStatus.Failed,
                        }),
                      );
                    },
                    onSuccess: (data) => {
                      dispatch(
                        actions.updateDealThreadMessageStatus({
                          dealThreadId: activeDealThreadId ?? "",
                          messageId: newId,
                          status: DealThreadMessageStatus.Sent,
                        }),
                      );

                      dispatch(
                        actions.addDealThreadMessage({
                          dealThreadId: activeDealThreadId ?? "",
                          message: {
                            dealThreadID: activeDealThreadId ?? "",
                            dealThreadMessageID:
                              data.createDealThreadMessage.responseMessage.id,
                            dealThreadMessage: {
                              id: data.createDealThreadMessage.responseMessage
                                .id,
                              status:
                                data.createDealThreadMessage.responseMessage
                                  .status,
                              role: data.createDealThreadMessage.responseMessage
                                .role,
                              content: {
                                type: "text",
                                text: {
                                  value: "",
                                },
                                quotes: [],
                                files: [],
                              },
                              createdAt:
                                data.createDealThreadMessage.responseMessage
                                  .createdAt,
                            },
                          },
                        }),
                      );
                    },
                  },
                );
              }}
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function AnimatedMessage(props: {
  message: DealThreadMessageCreatedData;
  isNew: boolean;
}) {
  const message = props.message;
  const style = useSpring({
    from: props.isNew
      ? { transform: "translate3d(0,40px,0)", opacity: 0 }
      : { transform: "translate3d(0,0px,0)", opacity: 1 },
    to: { transform: "translate3d(0,0px,0)", opacity: 1 },
    config: { tension: 125, friction: 20, precision: 0.1 },
    immediate: props.isNew === false,
  });

  if (!message.dealThreadMessage.content) {
    return null;
  }
  if (message.dealThreadMessage.role === DealThreadMessageRole.User) {
    return (
      <animated.div
        style={style}
        key={message.dealThreadMessage.id}
        className={classNames(
          "flex items-center justify-end space-x-2",
          message.dealThreadMessage.status ===
            DealThreadMessageStatus.Sending ||
            message.dealThreadMessage.status === DealThreadMessageStatus.Failed
            ? "opacity-50"
            : "opacity-1",
        )}
      >
        <div className="flex items-center gap-x-2">
          {message.dealThreadMessage.status ===
          DealThreadMessageStatus.Failed ? (
            <ExclamationTriangleIcon className="w-5 h-5 text-red-500" />
          ) : null}
          <div className="bg-persian-100/70 max-w-xs shadow rounded-md p-2 my-2">
            <p className="text-sm text-gray-600">
              <ReactMarkdown>
                {message.dealThreadMessage.content.text.value}
              </ReactMarkdown>
            </p>
          </div>
        </div>
      </animated.div>
    );
  }

  return <AnimatedAiMessage message={message} isNew={props.isNew} />;
}

function AnimatedAiMessage(props: {
  message: DealThreadMessageCreatedData;
  isNew: boolean;
}) {
  const message = props.message;
  const dispatch = useDispatch();
  const style = useSpring({
    from: props.isNew
      ? { transform: "translate3d(0,40px,0)", opacity: 0 }
      : { transform: "translate3d(0,0px,0)", opacity: 1 },
    to: { transform: "translate3d(0,0px,0)", opacity: 1 },
    config: { tension: 125, friction: 20, precision: 0.1 },
    immediate: props.isNew === false,
  });

  const client = useGqlClient();

  const refreshCallback = useCallback(
    () => {
      if (!props.message.dealThreadMessage.id) {
        return;
      }

      client
        .request<DealThreadMessageQuery, DealThreadMessageQueryVariables>(
          DealThreadMessageDocument,
          {
            id: props.message.dealThreadMessage.id,
          },
        )
        .then((res) => {
          dispatch(
            actions.updateDealThreadMessage({
              dealThreadId: props.message.dealThreadID,
              messageId: props.message.dealThreadMessageID,
              message: gqlDealThreadMessageToWebsocketDealThreadMessage(
                res.dealThreadMessage as DealThreadMessage,
                props.message.dealThreadID,
              ),
            }),
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      client,
      props.message.dealThreadMessage.id,
      props.message.dealThreadID,
      props.message.dealThreadMessageID,
    ],
  );

  useManualRefresh(
    {
      messageCreatedAt: props.message.dealThreadMessage.createdAt,
      messageLastStreamedAt: props.message.lastStreamedMessageAt,
      messageStatus: props.message.dealThreadMessage.status,
    },
    refreshCallback,
  );

  return (
    <animated.div
      style={style}
      key={message.dealThreadMessage.id}
      className="flex items-end justify-start space-x-2 my-2"
    >
      <BotIcon className="w-4 h-4 text-gray-500" />
      <div className="bg-gray-50 max-w-xs shadow rounded-md p-2">
        <AiMessageContent message={message} />
        <div className="mt-2">
          {message.dealThreadMessage.content.quotes
            ? message.dealThreadMessage.content.quotes.map((quote) => {
                return (
                  <div
                    className="p-3 bg-white border my-1 border-gray-200 rounded-md space-y-2"
                    key={quote.quote}
                  >
                    <QuoteStatus status={quote.status} />
                    <p className="text-sm text-gray-600">{quote.quote}</p>
                    {quote.dataRoomFileId ? (
                      <Pills>
                        <FilePill
                          id={quote.dataRoomFileId}
                          name={quote.dataRoomFileName}
                          type={quote.dataRoomFileType}
                          pageIndex={quote.pageIndex}
                          rectsOnPage={
                            quote.rectsOnPage ? quote.rectsOnPage : undefined
                          }
                        />
                      </Pills>
                    ) : null}
                  </div>
                );
              })
            : null}
        </div>
        {message.dealThreadMessage.content.files &&
        message.dealThreadMessage.content.files.length > 0 ? (
          <Pills>
            {message.dealThreadMessage.content.files.map((file) => {
              return (
                <FilePill
                  key={file.dataRoomFileId}
                  id={file.dataRoomFileId}
                  name={file.dataRoomFileName}
                  type={file.dataRoomFileType}
                  showDetailsCard={false}
                  pageIndex={file.pageIndex ? file.pageIndex : undefined}
                  rectsOnPage={file.rectsOnPage ? file.rectsOnPage : undefined}
                />
              );
            })}
          </Pills>
        ) : null}
        {message.dealThreadMessage.content.files &&
        message.dealThreadMessage.content.files.length > 0 ? (
          <Pills>
            {message.dealThreadMessage.content.files.map((file) => {
              return (
                <FilePill
                  key={file.dataRoomFileId}
                  id={file.dataRoomFileId}
                  name={file.dataRoomFileName}
                  type={file.dataRoomFileType}
                  showDetailsCard={false}
                />
              );
            })}
          </Pills>
        ) : null}
      </div>
    </animated.div>
  );
}

function AiMessageContent(props: { message: DealThreadMessageCreatedData }) {
  if (
    props.message.dealThreadMessage.status === DealThreadMessageStatus.Failed
  ) {
    return (
      <div>
        <p className="text-sm text-gray-600">
          Failed to generate response. Please try again.
        </p>
      </div>
    );
  }

  if (props.message.dealThreadMessage.content.text.value.length === 0) {
    return (
      <div className="flex items-end gap-x-2">
        <Loader fill="#6B7280" style={{ width: 24, height: 5 }} />
      </div>
    );
  }

  return (
    <>
      <p className="text-sm text-gray-600">
        <ReactMarkdown>
          {props.message.dealThreadMessage.content.text.value}
        </ReactMarkdown>
      </p>
    </>
  );
}

function QuoteStatus(props: { status: DealThreadMessageContentQuoteStatus }) {
  if (props.status === DealThreadMessageContentQuoteStatus.Unverified) {
    return (
      <div className="flex items-center gap-x-2">
        <FileWarningIcon className="w-3 h-3 text-gray-500" />
        <p className="text-sm font-semibold text-gray-600">
          Quote not verified yet
        </p>
      </div>
    );
  }

  if (props.status === DealThreadMessageContentQuoteStatus.Verifying) {
    return (
      <div className="flex items-center ">
        <Spinner color="gray" size="s" />
        <p className="text-sm text-gray-600 font-semibold">
          Verifying quote...
        </p>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-x-2">
      <CheckCircleIcon className="w-3 h-3 text-green-500" />
      <p className="text-sm text-gray-600 font-semibold">Quote verified</p>
    </div>
  );
}
