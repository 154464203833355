import { getUnixTime } from "date-fns";
import {
  useDeleteDataRoomFileMutation,
  useUpdateDataRoomFolderMutation,
  DeletionType,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { FolderIcon } from "@heroicons/react/24/solid";
import { Dialog } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import { AnimatedModal } from "../../components/AnimatedModal";
import { toasts } from "../../components/toasts/toasts";
import { useInvalidateQueryKeys } from "@/src/hooks/useInvalidateQueryKeys";

interface DeleteItemsProps {
  onClose: () => void;
  onDelete: () => void;
  open: boolean;
  dataRoomFileIds: string[];
  dataRoomFolderIds: string[];
}

export function DeleteItemsModal(props: DeleteItemsProps) {
  return (
    <AnimatedModal open={props.open} onClose={props.onClose}>
      <DeleteFolderContent
        onCancel={props.onClose}
        onDelete={props.onDelete}
        dataRoomFileIds={props.dataRoomFileIds}
        dataRoomFolderIds={props.dataRoomFolderIds}
      />
    </AnimatedModal>
  );
}

interface DeleteFolderContentProps {
  onCancel: () => void;
  onDelete: () => void;
  dataRoomFileIds: string[];
  dataRoomFolderIds: string[];
}

function DeleteFolderContent(props: DeleteFolderContentProps) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const { invalidateDealActivities } = useInvalidateQueryKeys();

  // Promise-based React Query mutations
  const deleteDataRoomFile = useDeleteDataRoomFileMutation(client);
  const updateDataRoomFolder = useUpdateDataRoomFolderMutation(client);

  // If you want a loading state, track it with your own or remove the button's disabled
  const isDeleting = deleteDataRoomFile.isPending || updateDataRoomFolder.isPending;

  // This handler does the actual delete logic
  const handleDelete = async () => {
    const validFileIds = props.dataRoomFileIds.filter(Boolean);
    const validFolderIds = props.dataRoomFolderIds.filter(Boolean);

    // If there's nothing to delete, just close the modal
    if (validFileIds.length === 0 && validFolderIds.length === 0) {
      props.onDelete();
      return;
    }

    try {
      // 1) Delete each file
      const filePromises = validFileIds.map(async (fileId) => {
        await deleteDataRoomFile.mutateAsync({
          input: {
            id: fileId,
            deletionType: DeletionType.Soft,
          },
        });
        // (Optional) invalidate or do any additional side effects
        // await queryClient.invalidateQueries({ queryKey: ["DataRoomFolder"] });
        invalidateDealActivities();
        toasts.success(`${fileId} deleted`);
      });

      // 2) Delete (soft-delete) each folder
      const folderPromises = validFolderIds.map(async (folderId) => {
        await updateDataRoomFolder.mutateAsync({
          input: {
            id: folderId,
            deletedAt: getUnixTime(new Date()),
          },
        });
        // (Optional) invalidate
        // await queryClient.invalidateQueries({ queryKey: ["DataRoomFolder"] });
        toasts.success(`${folderId} deleted`);
      });

      // 3) Wait for all deletions to settle
      const results = await Promise.allSettled([...filePromises, ...folderPromises]);
      console.log("Delete operations completed:", results);

      // Log any failures (status === 'rejected')
      const errors = results.filter((r) => r.status === "rejected");
      if (errors.length > 0) {
        console.error(`${errors.length} delete operations failed`, errors);
      }
    } catch (err) {
      // If mutateAsync throws (e.g. partial GraphQL error), handle it
      console.error("Unexpected error in delete operation:", err);
    } finally {
      // Always close the modal, even if some operations failed
      props.onDelete();
    }
  };

  return (
    <div>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center
                        rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <FolderIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            Delete {props.dataRoomFileIds.length + props.dataRoomFolderIds.length} items
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to delete{" "}
              {props.dataRoomFileIds.length + props.dataRoomFolderIds.length} items?
            </p>
          </div>
        </div>
      </div>

      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          disabled={isDeleting}
          onClick={handleDelete}
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2
                     text-sm font-semibold text-white shadow-sm hover:bg-red-500
                     sm:ml-3 sm:w-auto"
        >
          {isDeleting ? "Deleting..." : "Delete"}
        </button>
        <button
          type="button"
          onClick={props.onCancel}
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2
                     text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset
                     ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
