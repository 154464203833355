import { AnimatedModal } from "@/src/components/AnimatedModal";

import {
  Question,
  useAssignToDealGroupModalQuery,
  useUpdateQuestionMutation,
} from "@/src/graphql/generated";

import { CloseIcon } from "@/src/components/CloseIcon";
import { useEffect } from "react";
import { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import useGqlClient from "@/src/hooks/useGqlClient";
import { Button } from "@/src/components/tailwind/Button";
import { toasts } from "@/src/components/toasts/toasts";
import { useInvalidateQueryKeys } from "@/src/hooks/useInvalidateQueryKeys";
export function AssignToDealGroupModal(props: {
  open: boolean;
  onClose: () => void;
  dealId: string;
  question: Question;
}) {
  const { invalidateQuestions } = useInvalidateQueryKeys();
  const [selectedDealGroup, setSelectedDealGroup] = useState<string | null>(
    props.question.forDealGroup?.id ?? null,
  );

  useEffect(() => {
    setSelectedDealGroup(props.question.forDealGroup?.id ?? null);
  }, [props.question, props.open]);

  const client = useGqlClient();
  const assignToDealGroupModalQuery = useAssignToDealGroupModalQuery(client, {
    dealId: props.dealId,
  });
  const queryClient = useQueryClient();
  const updateQuestion = useUpdateQuestionMutation(client);

  function onClose() {
    setSelectedDealGroup(null);
    props.onClose();
  }

  return (
    <AnimatedModal open={props.open} onClose={onClose} size="lg">
      <div className="flex flex-col gap-y-4">
        <div className="">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">
              Assign to Deal Group
            </h3>
            <CloseIcon onClose={onClose} />
          </div>
          <div className="space-y-3">
            {assignToDealGroupModalQuery.data?.deal.guestGroups.map((group) => (
              <label
                key={group.id}
                className="flex items-center space-x-3 px-4 py-2 hover:bg-gray-50 cursor-pointer rounded-md transition-colors duration-150"
              >
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={selectedDealGroup === group.id}
                  onChange={() =>
                    setSelectedDealGroup(
                      selectedDealGroup === group.id ? null : group.id,
                    )
                  }
                />
                <span className="text-md font-semibold text-gray-700">
                  {group.name}
                </span>
                <span className="text-xs text-gray-500">
                  ({group.accounts.length}{" "}
                  {group.accounts.length === 1 ? "member" : "members"})
                </span>
              </label>
            ))}
          </div>
          <div className="mt-6 flex justify-end gap-x-3">
            <Button variant="neutral" text="Cancel" onClick={props.onClose} />
            <Button
              isLoading={updateQuestion.isPending}
              variant="positive"
              text="Assign"
              onClick={() => {
                if (!selectedDealGroup) {
                  return;
                }

                updateQuestion.mutate(
                  {
                    input: {
                      id: props.question.id,
                      questionForDealGroupID: selectedDealGroup,
                    },
                  },
                  {
                    onSuccess: () => {
                      toasts.success(`Question assigned`);
                      onClose();
                      invalidateQuestions();
                    },
                    onError: () => {
                      toasts.error(`Failed to assign DDQL`);
                    },
                  },
                );
              }}
            />
          </div>
        </div>
      </div>
    </AnimatedModal>
  );
}
