import { Avatar } from "@/src/components/account/Avatar";
import { FileIcon } from "@/src/components/FileIcon";
import Loading from "@/src/components/Loading";
import { StatusDot } from "@/src/components/StatusDot";
import { Button } from "@/src/components/tailwind/Button";
import {
  DataRoomFile,
  DataRoomFileFragmentFragment,
  DealActivity,
  DealActivityType,
  MergeDocumentsQuery,
  useResolveDataRoomFileSimilarityMutation,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { authSelectors } from "@/src/store/auth/selector";
import { classNames } from "@/src/utils/cn";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { UseQueryResult } from "@tanstack/react-query";
import {
  formatDistance,
  formatISO,
  getUnixTime,
  secondsToMilliseconds,
} from "date-fns";
import { ArrowLeftRightIcon, InfoIcon, MergeIcon } from "lucide-react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import React from "react";
import { ActivityItems } from "@/src/components/activity/ActivityItems";
import { FilePill } from "@/src/components/FilePill";
import { toasts } from "@/src/components/toasts/toasts";

export function Overview(props: {
  query: UseQueryResult<MergeDocumentsQuery, unknown>;
}) {
  const { query } = props;
  const history = useHistory();
  const client = useGqlClient();
  const resolveMergeMutation = useResolveDataRoomFileSimilarityMutation(client);

  if (query.error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="font-semibold text-gray-500">Something went wrong</p>
      </div>
    );
  }

  if (query.isLoading || !query.data) {
    return <Loading />;
  }

  return (
    <div className="relative flex-1 flex flex-col bg-white overflow-y-scroll h-full">
      <div className="flex-1">
        <div className="mx-2 px-2 flex items-center gap-x-1 mt-4 rounded-md bg-gray-50 py-2 border border-gray-300">
          <div className="flex items-start flex-col w-full">
            <div className="flex items-center gap-x-1 mb-0.5 ">
              <InfoIcon className="w-5 h-5 ml-1 text-amber-500 inline" />
              <h4 className="text-sm font-bold text-gray-700  inline">
                Caution
              </h4>
            </div>
            <p className="text-gray-500 text-sm mt-2">
              Merging documents is an irreversible action. Please ensure you
              have reviewed the documents and are ready to merge. The current
              live version of the right hand document will be appended as a new
              version of the left hand document. The right hand document and its
              other versions will be deleted.
            </p>
            <p className="text-gray-500 text-sm mt-2">
              Merging documents has implications for document permissions. The
              current live version of the right hand document will inherit the
              permissions of the left hand document.
            </p>
            <p className="text-gray-500 text-sm mt-2">
              <strong>Note on the comparison view:</strong> The left sidebar the
              comparison tab contains the full differences between the two
              documents. The document highlights are only indicative of the
              start of each of the changes.
            </p>
          </div>
        </div>

        <div className="px-2 mt-3 space-y-2 mb-24">
          <div className="flex justify-center">
            <Button
              variant="neutral"
              size="s"
              icon={ArrowLeftRightIcon}
              text="Swap documents"
              onClick={() => {
                history.push(
                  `/deal/toolkit/merge-documents/${query.data.dataRoomFile2.id}/${query.data.dataRoomFile1.id}`,
                );
              }}
            />
          </div>
          <FilesSideBarFile
            files={[query.data.dataRoomFile1, query.data.dataRoomFile2]}
          />
          <MergeIcon className="mx-auto w-6 h-6 text-gray-500 transform rotate-180" />
          <MergedDocuments
            sourceDocument={query.data.dataRoomFile1}
            targetDocument={query.data.dataRoomFile2}
          />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 left-0 shadow-2xl bg-white border-t border-gray-200">
        <div className="flex items-center justify-end gap-x-2 p-4">
          <Button
            variant="neutral"
            icon={XMarkIcon}
            text="Reject Merge"
            isLoading={
              resolveMergeMutation.isPending &&
              resolveMergeMutation.variables.acceptMerge === false
            }
            loadingText="Rejecting merge..."
            onClick={() => {
              if (resolveMergeMutation.isPending) {
                return;
              }

              resolveMergeMutation.mutate(
                {
                  acceptMerge: false,
                  dataRoomFileID1: query.data.dataRoomFile1.id,
                  dataRoomFileID2: query.data.dataRoomFile2.id,
                  reverseMerge: false,
                },
                {
                  onSuccess: () => {
                    toasts.success("Merge rejected");
                    history.push(
                      `/deal/documents/folder/${query.data.dataRoomFile2.folderId}/file/${query.data.dataRoomFile2.id}`,
                    );
                  },
                },
              );
            }}
          />
          <Button
            variant="positive"
            icon={MergeIcon}
            text="Confirm Merge"
            isLoading={
              resolveMergeMutation.isPending &&
              resolveMergeMutation.variables.acceptMerge === true
            }
            loadingText="Merging documents..."
            onClick={() => {
              if (resolveMergeMutation.isPending) {
                return;
              }

              resolveMergeMutation.mutate(
                {
                  acceptMerge: true,
                  dataRoomFileID1: query.data.dataRoomFile1.id,
                  dataRoomFileID2: query.data.dataRoomFile2.id,
                  reverseMerge: true,
                },
                {
                  onSuccess: () => {
                    toasts.success("Documents merged successfully");
                    history.push(
                      `/deal/documents/folder/${query.data.dataRoomFile1.folderId}/file/${query.data.dataRoomFile1.id}`,
                    );
                  },
                  onError: () => {
                    toasts.error("Failed to merge documents");
                  },
                },
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

function FilesSideBarFile(props: {
  files: MergeDocumentsQuery["dataRoomFile1"][];
}) {
  return (
    <div className="flex flex-1">
      <div className={classNames("mt-2 flex flex-1 items-start")}>
        {props.files.length > 0 ? (
          <div className="w-full flex flex-1">
            <FileSideBar files={props.files} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
function FileSideBar(props: { files: MergeDocumentsQuery["dataRoomFile1"][] }) {
  return (
    <div className="flex flex-1 gap-x-2">
      <SideBarFile file={props.files[0] as DataRoomFile} role={"source"} />
      <SideBarFile file={props.files[1] as DataRoomFile} role={"target"} />
    </div>
  );
}

function SideBarFile(props: { file: DataRoomFile; role: "source" | "target" }) {
  const [activeTab, setActiveTab] = React.useState<"versions" | "activity">(
    "versions",
  );

  return (
    <div className="flex-1" key={props.file.id}>
      <div
        className={classNames(
          "bg-gray-50 shadow-sm border border-gray-200 rounded-md flex flex-col items-start",
        )}
      >
        <div className="bg-white flex border-b w-full rounded-t-md border-gray-200 p-2 items-center justify-between gap-x-2">
          <FilePill
            id={props.file.id}
            name={props.file.name}
            type={props.file.fileType}
          />
          <span
            className={classNames(
              "text-xs font-medium bg-white border shadow-sm rounded-full px-2 py-0.5 mr-2",
              props.role === "source"
                ? "border-green-500 text-green-900"
                : "border-orange-500 text-orange-900",
            )}
          >
            {props.role === "source" ? "Original" : "Will be merged & deleted"}
          </span>
        </div>

        {/* Tab Navigation */}
        <div className="w-full border-b border-gray-200">
          <nav className="flex w-full" aria-label="Tabs">
            <button
              onClick={() => setActiveTab("versions")}
              className={classNames(
                "py-2 text-sm font-medium border-b-2 -mb-px w-1/2 text-gray-600",
                activeTab === "versions"
                  ? "border-gray-400 text-gray-700"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              )}
            >
              Versions
            </button>
            <button
              onClick={() => setActiveTab("activity")}
              className={classNames(
                "py-2 text-sm font-medium border-b-2 -mb-px w-1/2 text-gray-600",
                activeTab === "activity"
                  ? "border-gray-400 text-gray-700"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              )}
            >
              Activity
            </button>
          </nav>
        </div>

        {/* Tab Content */}
        {activeTab === "versions" ? (
          <div className="h-64 overflow-y-auto w-full">
            <Versions fileId={props.file.id} versions={props.file.versions} />
          </div>
        ) : (
          <div className="w-full p-4 text-center text-sm text-gray-500 h-64 overflow-y-auto">
            <Activity activity={props.file.activity} />
          </div>
        )}
      </div>
    </div>
  );
}

function Versions(props: {
  fileId: string;
  versions: DataRoomFileFragmentFragment["versions"];
}) {
  return (
    <div className="w-full px-1">
      <ul role="list" className="mt-2 space-y-2">
        <TransitionGroup className="space-y-2">
          {props.versions.map((version, i) => {
            return (
              <CSSTransition
                key={version.id}
                timeout={300}
                classNames="fade-slide-down"
                onEnter={(node: any) => node.offsetHeight}
              >
                <li key={version.id}>
                  <Version
                    fileId={props.fileId}
                    version={version}
                    isFirst={i === 0}
                    isLast={i === props.versions.length - 1}
                    currentLiveVersionId={props.versions[0].id}
                  />
                </li>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </ul>
    </div>
  );
}
function Version(props: {
  fileId: string;
  version: DataRoomFileFragmentFragment["versions"][0];
  isLast: boolean;
  isFirst: boolean;
  currentLiveVersionId: string;
}) {
  const version = props.version;

  return (
    <div
      className={classNames(
        "transition-all duration-300 ease-in-out cursor-pointer hover:bg-gray-50 py-2",
        !props.isLast ? "border-b border-gray-100" : "",
        props.isLast ? "rounded-b-md" : "",
        props.isFirst ? "rounded-t-md" : "",
      )}
    >
      <div>
        <div className="relative flex flex-col space-y-2 px-1">
          <div className="flex items-start gap-x-1 -py-0.5">
            {props.currentLiveVersionId === props.version.id ? (
              <div className="flex h-6 w-6 items-center justify-center bg-transparent">
                <div
                  className={classNames(
                    "bg-green-400/10 text-green-400",
                    "rounded-full p-1",
                  )}
                >
                  <div className="h-2 w-2 rounded-full bg-current" />
                </div>
              </div>
            ) : (
              <div className="flex h-6 w-6 items-center justify-center bg-transparent">
                <div
                  className={classNames(
                    "h-1.5 w-1.5 rounded-full bg-gray-300 ring-1 ring-gray-300",
                  )}
                />
              </div>
            )}

            <div className="w-1/2 min-w-0">
              <p className="text-sm font-semibold text-gray-600 truncate">
                Version {version.versionNumber}
              </p>
              <p className="text-xs leading-2 text-gray-500 font-normal truncate">
                {version.summary ? version.summary : "No summary"}
              </p>
              <div className="flex items-center mt-1.5">
                <Avatar account={version.createdBy} size="xs" />
                <p className="text-xs leading-5 ml-2 text-gray-500 font-light whitespace-nowrap">
                  <span className="font-semibold text-xs text-gray-500">
                    {version.createdBy.name}
                  </span>{" "}
                  uploaded{" "}
                  <time
                    title={formatISO(secondsToMilliseconds(version.createdAt))}
                    dateTime={formatISO(
                      secondsToMilliseconds(version.createdAt),
                    )}
                    className="flex-none"
                  >
                    {formatDistance(
                      secondsToMilliseconds(version.createdAt),
                      new Date(),
                      {
                        addSuffix: true,
                      },
                    )}
                  </time>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MergedDocuments(props: {
  sourceDocument: DataRoomFileFragmentFragment;
  targetDocument: DataRoomFileFragmentFragment;
}) {
  const [activeTab, setActiveTab] = React.useState<"versions" | "activity">(
    "versions",
  );
  const account = useSelector(authSelectors.account);
  const versions: DataRoomFileFragmentFragment["versions"] = [
    ...props.sourceDocument.versions,
    {
      ...props.targetDocument.versions[0],
      versionNumber: props.sourceDocument.versions.length + 1,
      createdAt: getUnixTime(new Date()),
      summary: "Merged from " + props.targetDocument.name,
      createdBy: account ?? props.sourceDocument.versions[0].createdBy,
    },
  ].sort((a, b) => b.createdAt - a.createdAt);

  const activity: DealActivity[] = [
    ...(props.sourceDocument.activity as DealActivity[]),
    ...(props.targetDocument.activity as DealActivity[]),
    {
      id: "merge-event",
      createdAt: getUnixTime(new Date()),
      account: account ?? props.sourceDocument.versions[0].createdBy,
      type: DealActivityType.DataRoomFileSimilarityAccepted,
      file: props.sourceDocument,
      mergedFile: true,
    } as DealActivity,
  ].sort((a, b) => b.createdAt - a.createdAt);

  return (
    <div
      className={classNames(
        "w-full xl:w-1/2 mx-auto bg-gray-50 shadow-sm border border-gray-200 rounded-md flex flex-col items-start",
      )}
    >
      <div className="bg-white flex border-b w-full rounded-t-md border-gray-200 p-2 items-center justify-between gap-x-2">
        <div className="flex items-center gap-x-2">
          <FileIcon fileType={props.sourceDocument.fileType} />
          <div>
            <p className="text-sm font-semibold text-gray-700 line-clamp-1 hover:line-clamp-none text-ellipsis">
              {props.sourceDocument.name}
            </p>
            <div className="flex items-center gap-x-2">
              <StatusDot status={"positive"} />
              <p className="text-xs text-gray-500">
                Version {versions[0].versionNumber}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Tab Navigation */}
      <div className="w-full border-b border-gray-200">
        <nav className="flex w-full" aria-label="Tabs">
          <button
            onClick={() => setActiveTab("versions")}
            className={classNames(
              "py-2 text-sm font-medium border-b-2 -mb-px w-1/2 text-gray-600",
              activeTab === "versions"
                ? "border-gray-400 text-gray-700"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
            )}
          >
            Versions
          </button>
          <button
            onClick={() => setActiveTab("activity")}
            className={classNames(
              "py-2 text-sm font-medium border-b-2 -mb-px w-1/2 text-gray-600",
              activeTab === "activity"
                ? "border-gray-400 text-gray-700"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
            )}
          >
            Activity
          </button>
        </nav>
      </div>

      {/* Tab Content */}
      {activeTab === "versions" ? (
        <div className="h-64 overflow-y-auto">
          <Versions fileId={props.sourceDocument.id} versions={versions} />
        </div>
      ) : (
        <div className="w-full p-4 text-center text-sm text-gray-500 h-64 overflow-y-auto">
          <Activity activity={activity} />
        </div>
      )}
    </div>
  );
}

function Activity(props: {
  activity: DataRoomFileFragmentFragment["activity"];
}) {
  return <ActivityItems items={props.activity} mode="compact" margin="mt-2" />;
}
