import { AnimatedModal } from "@/src/components/AnimatedModal";
import { CloseIcon } from "@/src/components/CloseIcon";
import { Button } from "@/src/components/tailwind/Button";
import { toasts } from "@/src/components/toasts/toasts";
import {
  useCreateRedlineExportMutation,
  useRedlineExportsQuery,
  RedlineExportStatus,
  DataRoomFileFragmentFragment,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { authSelectors } from "@/src/store/auth/selector";

export function RedlineExportModal(props: {
  open: boolean;
  onClose: () => void;
  sourceVersionId: string;
  targetVersionId: string;
  file: DataRoomFileFragmentFragment;
}) {
  const client = useGqlClient();
  const [exportFormat, setExportFormat] = useState<string>("docx");
  const [exportId, setExportId] = useState<string | null>(null);
  const [redlineType, setRedlineType] = useState<string>("traditional");

  const activeDealId = useSelector(authSelectors.activeDealId);
  const createRedlineExport = useCreateRedlineExportMutation(client);

  const redlineExportQuery = useRedlineExportsQuery(
    client,
    { id: exportId || "" },
    { enabled: !!exportId, refetchInterval: 2000 },
  );

  const redlineExport = redlineExportQuery.data?.redlineExport;
  const isPolling =
    !!exportId &&
    redlineExport?.status !== RedlineExportStatus.Complete &&
    redlineExport?.status !== RedlineExportStatus.Failed;
  const isComplete = redlineExport?.status === RedlineExportStatus.Complete;
  const isFailed = redlineExport?.status === RedlineExportStatus.Failed;

  // Reset state when modal closes
  useEffect(() => {
    if (!props.open) {
      setExportId(null);
    }
  }, [props.open]);

  const handleExport = () => {
    createRedlineExport.mutate(
      {
        input: {
          dealID: activeDealId ?? "",
          dataRoomFileVersionSourceID: props.sourceVersionId,
          dataRoomFileVersionTargetID: props.targetVersionId,
        },
      },
      {
        onSuccess: (data) => {
          if (data.createRedlineExport.id) {
            setExportId(data.createRedlineExport.id);
            toasts.success("Redline export started");
          }
        },
        onError: (error) => {
          toasts.error("Failed to start redline export");
          console.error(error);
        },
      },
    );
  };

  const handleDownload = async () => {
    try {
      if (!redlineExport) {
        return;
      }
      
      // Get the correct URL based on the selected export format
      const downloadUrl = exportFormat === "docx" 
      ? redlineType === "traditional" 
        ? redlineExport.s3PresignedDocXRedlineUrl
        : redlineExport.s3PresignedDocXTrackedChangesUrl
      : redlineExport.s3PresignedPdfRedlineUrl;

      if (!downloadUrl) {
        toasts.error(`No ${exportFormat.toUpperCase()} file available for download`);
        return;
      }

      const fileRes = await fetch(downloadUrl);
      const blob = await fileRes.blob();
      const fileName = `redline-${props.file.name}.${exportFormat}`;
      saveAs(blob, fileName);
      props.onClose();
    } catch (error) {
      toasts.error("Failed to download file");
      console.error(error);
    }
  };

  return (
    <AnimatedModal open={props.open} onClose={props.onClose} size="lg">
      <div>
        <div className="flex items-center justify-between">
          <p className="font-semibold text-gray-700">Export Redline</p>
          <CloseIcon onClose={props.onClose} />
        </div>

        <p className="mt-3 text-sm text-gray-500">
          Export a redline version of this document to see all changes.
        </p>

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            Export Format
          </label>
          <select
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={exportFormat}
            onChange={(e) => setExportFormat(e.target.value)}
            disabled={isPolling || isComplete}
          >
            <option value="docx">DOCX</option>
            <option value="pdf">PDF</option>
          </select>
        </div>

        {exportFormat === "docx" && (
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              Redline Type
            </label>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={redlineType}
              onChange={(e) => setRedlineType(e.target.value)}
              disabled={isPolling || isComplete}
            >
              <option value="traditional">Traditional Redline</option>
              <option value="tracked">Tracked Changes</option>
            </select>
          </div>
        )}

        <div className="mt-8 flex justify-between items-center">
          {exportId && (
            <div>
              {isPolling && (
                <p className="text-orange-600 text-xs font-semibold flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-orange-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </p>
              )}
              {isComplete && (
                <p className="text-green-700 text-xs font-semibold">Complete</p>
              )}
              {isFailed && (
                <p className="text-red-700 text-xs font-semibold">
                  Redline export failed. Please try again.
                </p>
              )}
            </div>
          )}

          <div className="flex gap-3">
            {!exportId && (
              <Button
                variant="positive"
                text="Export"
                isLoading={createRedlineExport.isPending}
                loadingText="Starting export..."
                onClick={handleExport}
              />
            )}

            {isComplete && (
              <Button
                variant="positive"
                text="Download"
                isDisabled={!redlineExport?.s3PresignedDocXRedlineUrl}
                onClick={handleDownload}
              />
            )}

            <Button variant="neutral" text="Cancel" onClick={props.onClose} />
          </div>
        </div>
      </div>
    </AnimatedModal>
  );
}
