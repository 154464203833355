import { useEffect, useRef, useState } from "react";
import { PencilIcon } from "lucide-react";
import { useOutsideClick } from "../hooks/useOutsideClick";

export function EditableText(props: {
  initialText: string;
  onSave: (text: string) => void;
  onCancel: () => void;
  placeholder?: string;
}) {
  const { initialText, onSave, onCancel, placeholder } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const [inputText, setInputText] = useState(initialText);
  const inputRef = useRef<HTMLInputElement>(null);
  useOutsideClick(inputRef, () => {
    handleCancel();
  });

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const handleSave = () => {
    setText(inputText);
    onSave(inputText);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInputText(text);
    setIsEditing(false);
  };

  return (
    <div className="flex items-center space-x-3">
      {isEditing === false ? (
        <button
          className="cursor-text group flex items-center space-x-1"
          onClick={() => {
            setIsEditing(true);
          }}
        >
          {!text ? (
            <p className="text-gray-500/80 text-sm font-semibold">
              {placeholder}
            </p>
          ) : (
            <p
              title={text}
              className="text-wrap text-left text-gray-700 text-sm font-semibold truncate"
            >
              {text}
            </p>
          )}
          <PencilIcon className="w-3 text-gray-500 h-3 opacity-0 group-hover:opacity-100" />
        </button>
      ) : (
        <div className="flex items-center space-x-2 py-2">
          <input
            ref={inputRef}
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            className="w-72 leading-none text-sm font-semibold py-1 px-0 m-0 focus:outline-none focus:ring-0 focus:border-0 outline-none border-0 ring-0"
            placeholder={placeholder}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSave();
              }
              if (e.key === "Escape") {
                handleCancel();
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
