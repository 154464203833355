import { AnimatedModal } from "@/src/components/AnimatedModal";
import { CloseIcon } from "@/src/components/CloseIcon";
import {
  AccessControlDealGroupFragment,
  useDeleteAccessControlMutation,
} from "@/src/graphql/generated";
import { Button } from "@/src/components/tailwind/Button";
import useGqlClient from "@/src/hooks/useGqlClient";
import { toasts } from "@/src/components/toasts/toasts";

export function RemoveAccessModal(props: {
  open: boolean;
  onClose: () => void;
  resourceId: string;
  accessControlDealGroup: AccessControlDealGroupFragment;
}) {
  const { open, resourceId, accessControlDealGroup } = props;

  const client = useGqlClient();
  const deleteAccessControl = useDeleteAccessControlMutation(client);

  function onClose() {
    props.onClose();
  }

  return (
    <AnimatedModal open={open} onClose={onClose} size="lg">
      <div className="">
        <div className="flex items-center justify-between">
          <p className="text-lg font-semibold text-gray-700">
            Remove access for {accessControlDealGroup.dealGroup.name}
          </p>
          <CloseIcon onClose={onClose} />
        </div>

        <div className="mt-4">
          <p className="text-sm text-gray-500">
            Are you sure you want to remove access for{" "}
            {accessControlDealGroup.dealGroup.name}?
          </p>
        </div>
        <div className="mt-8 flex justify-end">
          <Button
            variant="positive"
            text="Remove"
            isLoading={deleteAccessControl.isPending}
            isDisabled={deleteAccessControl.isPending}
            onClick={() => {
              if (deleteAccessControl.isPending) {
                return;
              }

              deleteAccessControl.mutate(
                {
                  id: accessControlDealGroup.accessControl.id,
                },
                {
                  onSuccess: () => {
                    toasts.success("Access removed");
                    onClose();
                  },
                  onError: () => {
                    toasts.error("Failed to remove access");
                  },
                },
              );
            }}
          />
        </div>
      </div>
    </AnimatedModal>
  );
}
