import { AnimatedModal } from "@/src/components/AnimatedModal";
import { CloseIcon } from "@/src/components/CloseIcon";
import { guestRoleOptions } from "@/src/components/DealRoleRestricted";
import { TextInput } from "@/src/components/tailwind/TextInput";
import {
  AccessQuery,
  DealAccessStatus,
  DealRole,
  useCreateDataFileVersionMutation,
  useCreateDealGroupMutation,
  useS3FileUploadCompleteMutation,
} from "@/src/graphql/generated";

import { DealGroupType } from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { classNames } from "@/src/utils/cn";
import { PlusIcon, CheckCircleIcon, TrashIcon } from "lucide-react";
import { useState, useRef } from "react";
import {
  GranularAccess,
  granularAccessToFileInput,
  granularAccessToFolderInput,
} from ".";
import { EditDataRoomPermissions } from "./EditDataRoomPermissions";
import Dropdown, { Option } from "@/src/components/tailwind/Dropdown";
import { Button } from "@/src/components/tailwind/Button";

export function CreateGroupModal(props: {
  dealId: string;
  type: DealGroupType;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  onCreate: (id: string) => void;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <AnimatedModal
      open={props.open}
      size="xxl"
      onClose={() => {
        props.onClose();
      }}
    >
      <CreateGroupModalContent
        dealId={props.dealId}
        type={props.type}
        onCreate={props.onCreate}
        open={props.open}
        onClose={props.onClose}
        permissions={props.permissions}
      />
    </AnimatedModal>
  );
}

const dealAccessOptions = [
  {
    name: "Manually grant access",
    description:
      "You control when the group gets access. Use when running your own NDA process.",
    value: DealAccessStatus.PendingManualApproval,
  },
  {
    name: "Automatically grant access*",
    description:
      "Upload an NDA and the group gets access immediately after accepting.",
    value: DealAccessStatus.PendingNda,
  },
  {
    name: "No NDA",
    description: "The group can access without signing an NDA.",
    value: DealAccessStatus.Granted,
  },
];

export interface GroupAccountInvite {
  email: string;
  role: Option;
}

function CreateGroupModalContent(props: {
  dealId: string;
  type: DealGroupType;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  onCreate: (id: string) => void;
  open: boolean;
  onClose: () => void;
}) {
  const client = useGqlClient();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [error, setError] = useState("");

  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [autoAdmit, setAutoAdmit] = useState(false);
  const [groupAccountInvites, setGroupAccountInvites] = useState<
    GroupAccountInvite[]
  >([
    {
      email: "",
      role: guestRoleOptions[0],
    },
    {
      email: "",
      role: guestRoleOptions[1],
    },
  ]);

  const createDealFirmGroup = useCreateDealGroupMutation(client);
  const createDataRoomFileVersion = useCreateDataFileVersionMutation(client);

  const fileUploadComplete = useS3FileUploadCompleteMutation(client);

  const [dealAccessOption, setDealAccessOption] = useState(
    dealAccessOptions[0],
  );

  const [selectedDataRoomPermission, setSelectedDataRoomPermission] = useState(
    props.permissions[0],
  );
  const [overrideDataRoomAccess, setOverrideDataRoomAccess] = useState(false);
  const [granularAccess, setGranularAccess] = useState<GranularAccess>({
    files: {},
    folders: {},
  });

  const handleDrag = function (e: any) {
    setFileError("");
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files.length > 1) {
        setError("You can only upload one file at a time");
        return;
      }

      setFile(e.dataTransfer.files[0]);
    }
  };

  function clearForm() {
    setName("");
    setFile(null);
    setOverrideDataRoomAccess(false);
    setDealAccessOption(dealAccessOptions[0]);
    setGroupAccountInvites([
      { email: "", role: guestRoleOptions[0] },
      { email: "", role: guestRoleOptions[1] },
    ]);
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-lg font-semibold">New group</p>
          <p className="text-sm text-gray-500 font-light">
            Groups allow you to set the same permissions for multiple people
          </p>
        </div>
        <CloseIcon
          onClose={() => {
            clearForm();
            props.onClose();
          }}
        />
      </div>
      <div className=" mt-4 gap-x-2">
        <div className="w-full xl:w-2/3">
          <TextInput
            label="Name"
            inputSize="s"
            placeholder="Enter the name of the group..."
            value={name}
            error={nameError}
            onChange={(e) => {
              setNameError("");
              setName(e.currentTarget.value);
            }}
          />
        </div>

        <div className="mt-6 ">
          <div className="flex items-center justify-between">
            <div>
              <p className="font-semibold text-gray-600 text-sm">
                People{" "}
                <span className="font-light ml-1 text-sm text-gray-500/80 leading-tight">
                  Optional, can be added later
                </span>
              </p>
              <p className="text-sm text-gray-500 leading-tight">
                Invite people to the group
              </p>
            </div>
          </div>
          <div className="mt-2 flex max-h-96  flex-col gap-y-1.5 ">
            {groupAccountInvites.map((invite, index) => {
              return (
                <div key={index} className="gap-x-2">
                  <GroupAccountInviteContent
                    invite={invite}
                    onChange={(invite) => {
                      const newInvites = [...groupAccountInvites];
                      newInvites[index] = invite;
                      setGroupAccountInvites(newInvites);
                    }}
                    index={index}
                    onRemove={(index) => {
                      const newInvites = [...groupAccountInvites];
                      newInvites.splice(index, 1);
                      setGroupAccountInvites(newInvites);
                    }}
                  />
                </div>
              );
            })}
          </div>
          {groupAccountInvites.length < 6 ? (
            <button
              className="flex items-center gap-x-2 mt-2 font-semibold text-gray-500 hover:text-gray-700"
              onClick={() => {
                setGroupAccountInvites([
                  ...groupAccountInvites,
                  { email: "", role: guestRoleOptions[0] },
                ]);
              }}
            >
              <PlusIcon className="w-4 h-4" />
              <p className="text-sm">Add more</p>
            </button>
          ) : null}
        </div>

        <div className="mt-6">
          <p className="font-semibold text-gray-600 text-sm">Permissions</p>
          <p className="text-sm text-gray-500 leading-tight">
            Select a permission group or specify the specific files you want to
            grant access to
          </p>
          <div className="mt-2 flex gap-x-3">
            {props.permissions.map((permission) => {
              return (
                <button
                  key={permission.id}
                  onClick={() => {
                    setSelectedDataRoomPermission(permission);
                  }}
                  className={classNames(
                    "p-2 rounded-md border-2 relative  group",
                    overrideDataRoomAccess ? "border-gray-300 opacity-60" : "",
                    !overrideDataRoomAccess &&
                      permission.id === selectedDataRoomPermission.id
                      ? "border-persian-500"
                      : "",
                    !overrideDataRoomAccess &&
                      permission.accessLevel <
                        selectedDataRoomPermission.accessLevel
                      ? "border-persian-500 opacity-60"
                      : "",
                    !overrideDataRoomAccess &&
                      permission.accessLevel >
                        selectedDataRoomPermission.accessLevel
                      ? "opacity-50 "
                      : "",
                  )}
                >
                  <div className="flex items-start gap-x-2 justify-between">
                    <div className="text-left">
                      <p className="text-sm font-semibold text-gray-800">
                        {permission.name}
                      </p>
                      <p className="leading-tight text-xs text-gray-500">
                        {permission.description}
                      </p>
                      <p className="mt-1 text-xs text-gray-500">
                        {permission.fileCount} files
                      </p>
                    </div>
                    {(!overrideDataRoomAccess &&
                      permission.id === selectedDataRoomPermission.id) ||
                    permission.accessLevel <
                      selectedDataRoomPermission.accessLevel ? (
                      <CheckCircleIcon
                        className={`w-4 h-4 ${
                          overrideDataRoomAccess
                            ? "text-gray-500"
                            : "text-persian-500"
                        }`}
                      />
                    ) : null}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
        <div className="mt-3 relative flex items-start">
          <div className="flex h-6 items-center mt-2">
            <input
              id="granularAccess"
              aria-describedby="autoAdmit-description"
              name="autoAdmit"
              type="checkbox"
              checked={autoAdmit}
              onChange={(e) => {
                setAutoAdmit(e.currentTarget.checked);
              }}
              className="h-4 w-4 rounded border-gray-300 text-persian-600 focus:ring-persian-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label
              htmlFor="autoAdmit"
              className="font-medium text-gray-900 leading-tight cursor-pointer"
            >
              Auto-admit the account
            </label>
            <p
              id="autoAdmit-description"
              className="text-gray-500 leading-tight"
            >
              Automatically admit guest accounts when they accept the invite
            </p>
          </div>
        </div>

        <EditDataRoomPermissions
          dealGroupId=""
          showPermissions={false}
          initialDataRoomPermission={props.permissions[0]}
          initialGranularAccess={{
            files: [],
            folders: [],
          }}
          dataRoomPermissions={props.permissions}
          overrideDataRoomAccess={overrideDataRoomAccess}
          onOverrideDataRoomAccessChange={(checked) => {
            setOverrideDataRoomAccess(checked);
          }}
          granularAccess={granularAccess}
          onGranularAccessChange={(granularAccess) => {
            setGranularAccess(granularAccess);
          }}
        />

        <div className="mt-8 flex justify-end">
          <Button
            text="Create"
            variant="positive"
            isLoading={
              createDealFirmGroup.isPending ||
              createDataRoomFileVersion.isPending ||
              uploadingFile
            }
            isDisabled={name === ""}
            onClick={() => {
              if (!name) {
                setNameError("Name is required");
                return;
              }

              if (
                dealAccessOption.value === DealAccessStatus.PendingNda &&
                !file
              ) {
                setFileError("NDA is required");
                return;
              }

              createDealFirmGroup.mutate(
                {
                  input: {
                    dealId: props.dealId,
                    name,
                    type: props.type,
                    overrideDataRoomAccess: overrideDataRoomAccess,
                    filePermissions: granularAccessToFileInput(granularAccess),
                    folderPermissions:
                      granularAccessToFolderInput(granularAccess),
                    dealAccessStatus: DealAccessStatus.Granted,
                    dataRoomPermissionId: selectedDataRoomPermission.id,
                    createNdaFile: false,
                    invites: groupAccountInvites
                      .filter(
                        (invite) =>
                          invite.email !== "" && invite.email.includes("@"),
                      )
                      .map((invite) => ({
                        email: invite.email,
                        role: invite.role.value as DealRole,
                        dataRoomPermissionID: "",
                        dealGroupID: "",
                        dealID: props.dealId,
                        filePermissions: [],
                        folderPermissions: [],
                        message: "",
                        overrideDataRoomAccess: false,
                        autoAdmit: autoAdmit,
                      })),
                  },
                },
                {
                  onSuccess: (data) => {
                    const createFirmGroupData = data;
                    if (data.createDealGroup.ndaDataRoomFile && file) {
                      createDataRoomFileVersion.mutate(
                        {
                          dataRoomFileID:
                            data.createDealGroup.ndaDataRoomFile.id,
                          fileName: file.name,
                          fileType: file.type,
                          summary: "Initial version",
                        },
                        {
                          onSuccess: (d) => {
                            setUploadingFile(true);
                            fetch(d.createDataRoomFileVersion.url, {
                              method: "PUT",
                              body: file,
                            })
                              .catch((e) => {
                                setUploadingFile(false);
                                setError("Error uploading file");
                              })
                              .then((res) => {
                                fileUploadComplete.mutate(
                                  {
                                    dataRoomFileID:
                                      data.createDealGroup.ndaDataRoomFile!.id,
                                    dataRoomFileVersionID:
                                      d.createDataRoomFileVersion
                                        .dataRoomFileVersionID,
                                  },
                                  {
                                    onSuccess: () => {
                                      setUploadingFile(false);

                                      clearForm();
                                      props.onCreate(
                                        createFirmGroupData.createDealGroup.id,
                                      );
                                    },
                                  },
                                );
                              });
                          },
                        },
                      );
                    }

                    props.onCreate(data.createDealGroup.id);
                    clearForm();
                  },
                  onError: () => {
                    setError("Failed to create group");
                  },
                },
              );

              setNameError("");
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function GroupAccountInviteContent(props: {
  invite: GroupAccountInvite;
  onChange: (invite: GroupAccountInvite) => void;
  onRemove: (index: number) => void;
  index: number;
  inviteeRole?: DealRole;
}) {
  const [emailError, setEmailError] = useState("");

  let options = guestRoleOptions;
  if (props.inviteeRole) {
    if (props.inviteeRole === DealRole.GuestAdmin) {
      options = guestRoleOptions.filter(
        (option) => option.value !== DealRole.GuestUploader,
      );
    }
  }

  return (
    <div className="flex items-center gap-x-2 group">
      <div className="flex-1 lg:max-w-md">
        <TextInput
          inputSize="s"
          placeholder="Enter the email of the person..."
          value={props.invite.email}
          onChange={(e) =>
            props.onChange({ ...props.invite, email: e.currentTarget.value })
          }
        />
      </div>
      <Dropdown
        margin={emailError ? "xs 0 xl 0" : "xs 0 0 0"}
        variant="light"
        size="s"
        options={options}
        selectedOption={props.invite.role}
        onSelect={(role) => props.onChange({ ...props.invite, role })}
        hideSelectedOptionDescription={true}
      />
      <button
        onClick={() => {
          props.onRemove(props.index);
        }}
      >
        <TrashIcon className=" mt-1 w-4 h-4 text-gray-500/80 hover:text-gray-700 cursor-pointer opacity-0 group-hover:opacity-100 transition-all duration-200 ease-in-out" />
      </button>
    </div>
  );
}
