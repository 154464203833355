import { Account, useFirmQuery } from "../graphql/generated";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import { Combobox, Popover, Transition } from "@headlessui/react";
import { useRef, useState } from "react";
import useGqlClient from "../hooks/useGqlClient";
import Loading from "./Loading";
import { classNames } from "../utils/cn";
import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";
import { Avatar } from "./account/Avatar";

export function SelectUserPill(props: {
  onUserSelected: (acc: Partial<Account>) => void;
  excludedAccountIds?: string[];
  icon?: React.ReactNode;
  title?: string;
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover className="relative">
      <Popover.Button
        ref={buttonRef}
        className="relative cursor-pointer  hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1"
      >
        {props.icon ? (
          props.icon
        ) : (
          <UserCircleIcon className="h-5 w-5 text-gray-600/80" />
        )}
        <p className="text-xs select-none text-gray-600 ml-1 truncate">
          {props.title ? props.title : "Select user"}
        </p>
        <Transition
          enter="ease-in-out duration-50"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Popover.Panel className="z-20 absolute mt-5 w-96 -translate-x-1/3 transform px-4">
            <PopOut
              excludedAccountIds={props.excludedAccountIds}
              onUserSelected={(acc) => {
                props.onUserSelected(acc);
                buttonRef.current?.click();
              }}
            />
          </Popover.Panel>
        </Transition>
      </Popover.Button>
    </Popover>
  );
}

const PopOut = ({
  onUserSelected,
  excludedAccountIds,
}: {
  onUserSelected: (acc: Partial<Account>) => void;
  excludedAccountIds?: string[];
}) => {
  const [query, setQuery] = useState("");

  return (
    <div className="border rounded-md ml-10">
      <Combobox
        onChange={(f: Partial<Account>) => {
          onUserSelected(f);
        }}
      >
        <div className="">
          <div className="relative w-full cursor-default overflow-hidden rounded-t-md bg-white text-left border-b border-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              placeholder="Search users..."
              autoFocus
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>

          <div className="bg-white rounded-b-md">
            <SearchResults
              searchTerm={query}
              excludedAccountIds={excludedAccountIds}
            />
          </div>
        </div>
      </Combobox>
    </div>
  );
};

interface SearchResultsProps {
  searchTerm: string;
  excludedAccountIds?: string[];
}

function SearchResults(props: SearchResultsProps) {
  const client = useGqlClient();

  const account = useSelector(authSelectors.account);

  const firmQuery = useFirmQuery(client, {
    id: account && account.firm ? account.firm.id : "",
  });

  if (firmQuery.isError) {
    return <div>Something went wrong</div>;
  }

  if (firmQuery.isPending || !firmQuery.data) {
    return (
      <div className="h-full bg-white justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Combobox.Options
        static
        className="max-h-80 scroll-py-2 divide-y  divide-gray-100 overflow-y-auto"
      >
        <li>
          <ul className="text-sm text-gray-700">
            {firmQuery.data.firm.accounts
              .filter((f) =>
                f.name.toLowerCase().includes(props.searchTerm.toLowerCase()),
              )
              .filter((f) => !props.excludedAccountIds?.includes(f.id))
              .map((acc) => {
                return (
                  <UserContent account={acc} searchTerm={props.searchTerm} />
                );
              })}
          </ul>
        </li>
      </Combobox.Options>
    </>
  );
}

function UserContent(props: { account: Partial<Account>; searchTerm: string }) {
  const account = props.account;
  return (
    <Combobox.Option
      key={account.id}
      value={account}
      className={({ active }) =>
        classNames(
          "cursor-pointer select-none last:rounded-b-md px-3 py-2",
          active ? "bg-gray-200/70" : "",
        )
      }
    >
      {({ active }) => (
        <div className="">
          <div className="flex items-center gap-x-2">
            <Avatar account={account} size="s" />
            <p className=" font-bold leading-tight  text-sm text-left">
              {account.name}
            </p>
          </div>
        </div>
      )}
    </Combobox.Option>
  );
}
