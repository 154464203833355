import { DocumentChartBarIcon } from "@heroicons/react/20/solid";
import { ArchiveBoxIcon, DocumentIcon } from "@heroicons/react/24/solid";

interface FileIconProps {
  fileType: string; // Changed from FileType to string
  size?: "s" | "m" | "l";
}

const sizes = {
  s: 15,
  m: 20,
  l: 30,
};

export function LocalFileIcon(props: FileIconProps) {
  const getFileTypeCategory = (fileType: string) => {
    if (fileType.match(/application\/pdf/)) {
      return "Pdf";
    } else if (
      fileType.match(
        /application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document/,
      ) ||
      fileType.match(/application\/msword/)
    ) {
      return "Docx";
    } else if (
      fileType.match(
        /application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet/,
      ) ||
      fileType.match(/application\/vnd\.ms-excel/)
    ) {
      return "Xlsx";
    } else if (
      fileType.match(/application\/vnd\.ms-powerpoint/) ||
      fileType.match(
        /application\/vnd\.openxmlformats-officedocument\.presentationml\.presentation/,
      )
    ) {
      return "Pptx";
    } else if (fileType.match(/application\/zip/)) {
      return "Zip";
    } else {
      return "Other";
    }
  };

  const fileTypeCategory = getFileTypeCategory(props.fileType);

  switch (fileTypeCategory) {
    case "Pdf":
      return (
        <DocumentIcon
          className="text-red-700/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    case "Docx":
      return (
        <DocumentIcon
          className="text-blue-700/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    case "Xlsx":
      return (
        <DocumentIcon
          className="text-green-700/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    case "Pptx":
      return (
        <DocumentChartBarIcon
          className="text-pink-600/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    case "Zip":
      return (
        <ArchiveBoxIcon
          className="text-gray-700/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    default:
      return (
        <DocumentIcon
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
          className="text-gray-700/70 flex-shrink-0"
        />
      );
  }
}
