import { useFeatureFlagEnabled } from "posthog-js/react";

export function FeatureFlag(props: {
  flag: string;
  children: React.ReactNode;
}) {
  const flagEnabled = useFeatureFlagEnabled(props.flag);

  if (flagEnabled) {
    return <>{props.children}</>;
  }

  return null;
}
