import { TransitionGroup, CSSTransition } from "react-transition-group";
import { TaskActivityType, TaskQuery } from "../../graphql/generated";
import { withMarginTw } from "../../styles/withMargin";
import { ActivityItemMode } from "../../components/activity/ActivityItem";
import { TaskActivityItem } from "./TaskActivityItem";

export const TASK_ACTIVITY_TYPES = [
  TaskActivityType.Comment,
  TaskActivityType.Created,
  TaskActivityType.StatusUpdated,
  TaskActivityType.PriorityUpdated,
];

export function TaskActivityItems(props: {
  items: TaskQuery["task"]["activity"];
  mode?: ActivityItemMode;
  margin?: string;
}) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <ul role="list" className={margin}>
      <TransitionGroup className="space-y-6">
        {props.items
          .filter((t) => TASK_ACTIVITY_TYPES.includes(t.type))
          .map((activity, i) => {
            return (
              <CSSTransition
                key={activity.id}
                timeout={300} // This controls the duration of the animation
                classNames="fade-slide-down"
                onEnter={(node: any) => {
                  if (node) {
                    return node.offsetHeight;
                  }
                }} // Trigger reflow to enable animation
              >
                <TaskActivityItem
                  mode={props.mode}
                  key={activity.id}
                  activity={activity}
                  isLast={i === props.items.length - 1}
                  isFirst={i === 0}
                />
              </CSSTransition>
            );
          })}
      </TransitionGroup>
    </ul>
  );
}
