import {
  AccessControlEntityType,
  AccessControlPermissionLevel,
  AccessControlResourceType,
  useAccessControlCheckQuery,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";

export function AccessControlCheck(props: {
  entityType: AccessControlEntityType;

  resourceType: AccessControlResourceType;
  requiredPermissionLevels: AccessControlPermissionLevel[];
  resourceId?: string;
  children: React.ReactNode;
}) {
  const client = useGqlClient();

  const accessControlCheckQuery = useAccessControlCheckQuery(client, {
    input: {
      entityType: props.entityType,
      resourceID: props.resourceId,
      resourceType: props.resourceType,
      requiredPermissionLevels: props.requiredPermissionLevels,
    },
  });

  if (
    accessControlCheckQuery.data &&
    accessControlCheckQuery.data.accessControlCheck.hasAccess
  ) {
    return <>{props.children}</>;
  }

  return null;
}
