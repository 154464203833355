import { Card } from "@/src/components/Card";
import Loading from "@/src/components/Loading";
import { Button } from "@/src/components/tailwind/Button";
import {
  AccessControlResourceType,
  DdqlAccessControlQuery,
  DdqlStatus,
  useDdqlAccessControlQuery,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { InfoIcon, UserPlusIcon } from "lucide-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";
import {
  AddMoreModal,
  ddqlPermissionOptions,
} from "@/src/components/access_control/AddMoreModal";
import { AccessControlGroupItem } from "@/src/components/access_control/AccessControlGroupItem";

export function AccessControl(props: { ddqlId: string }) {
  const client = useGqlClient();
  const ddqlAccessQuery = useDdqlAccessControlQuery(client, {
    id: props.ddqlId,
  });

  const [openAddMoreModal, setOpenAddMoreModal] = useState<"" | "add_more">("");

  const activeDealId = useSelector(authSelectors.activeDealId);
  const queryClient = useQueryClient();

  return (
    <div className="flex-1">
      <div className="flex justify-between items-center">
        <p className="text-md font-semibold text-gray-700">Access controls</p>

        {ddqlAccessQuery.data?.ddql.status === DdqlStatus.Published && (
          <Button
            icon={UserPlusIcon}
            size="s"
            variant="neutral"
            text="Add more people"
            onClick={() => setOpenAddMoreModal("add_more")}
          />
        )}
      </div>
      <Card margin="s 0 0 0">
        <AccessControlContent query={ddqlAccessQuery} ddqlId={props.ddqlId} />
      </Card>
      <AddMoreModal
        open={openAddMoreModal === "add_more"}
        onClose={() => {
          setOpenAddMoreModal("");
          queryClient.invalidateQueries({
            queryKey: ["DdqlAccessControl", { id: props.ddqlId }],
          });
        }}
        dealId={activeDealId ?? ""}
        access={ddqlAccessQuery.data?.ddql.accessControlDealGroups ?? []}
        resourceId={props.ddqlId}
        options={ddqlPermissionOptions}
        resourceType={AccessControlResourceType.Ddql}
      />
    </div>
  );
}

function AccessControlContent(props: {
  query: UseQueryResult<DdqlAccessControlQuery, unknown>;
  ddqlId: string;
}) {
  const { query, ddqlId } = props;
  const queryClient = useQueryClient();

  if (query.error) {
    return (
      <div>
        <p className="text-gray-500 text-sm">Error loading access controls</p>
      </div>
    );
  }

  if (query.isLoading || !query.data) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <PublishMessage query={query.data} />
      <div className="p-3">
        <p className="text-sm text-gray-500">
          This is everyone who currently has access to this question list
        </p>
      </div>
      {query.data.ddql.accessControlDealGroups.map((group) => {
        return (
          <AccessControlGroupItem
            key={group.dealGroup.id}
            group={group}
            resourceId={ddqlId}
            onRemove={() => {
              queryClient.invalidateQueries({
                queryKey: ["DdqlAccessControl", { id: ddqlId }],
              });
            }}
            onEdit={() => {
              queryClient.invalidateQueries({
                queryKey: ["DdqlAccessControl", { id: ddqlId }],
              });
            }}
          />
        );
      })}
    </div>
  );
}

function PublishMessage({ query }: { query: DdqlAccessControlQuery }) {
  if (query.ddql.status === DdqlStatus.Published) {
    return null;
  }

  return (
    <div className="px-3 pt-3">
      <div className="rounded-md bg-persian-100 border-persian-300 border px-3 py-1.5 flex items-center gap-x-2">
        <InfoIcon className="w-4 h-4 text-persian-500" />
        <p className="text-sm text-persian-900 font-semibold">
          Publish the question list to make it available to more people
        </p>
      </div>
    </div>
  );
}
