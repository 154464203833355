import { useEffect, useState } from "react";
import {
  DataRoomFolderQuery,
  DataRoomPermission,
  useDataRoomFolderQuery,
  useDataRoomQuery,
  useUpdateDataRoomFolderMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { AnimatedModal } from "../AnimatedModal";
import { CloseIcon } from "../CloseIcon";
import { H3 } from "../Heading";
import Loading from "../Loading";
import { TextInput } from "../tailwind/TextInput";
import { Button } from "../tailwind/Button";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";

interface Props {
  open: boolean;
  onClose: () => void;
  onUpdated?: () => void;
  dataRoomFolderId: string;
}

export function EditFolder(props: Props) {
  const client = useGqlClient();

  const activeDealId = useSelector(authSelectors.activeDealId);

  const [folderId, setFolderId] = useState(props.dataRoomFolderId);
  useEffect(() => {
    if (props.dataRoomFolderId) {
      setFolderId(props.dataRoomFolderId);
    }
  }, [props.dataRoomFolderId]);

  const { data, isLoading, error } = useDataRoomFolderQuery(client, {
    id: folderId,
  });

  const dataRoomQuery = useDataRoomQuery(client, {
    dealId: activeDealId ? activeDealId : "",
  });

  return (
    <AnimatedModal open={props.open} onClose={props.onClose}>
      <EditFolderHeader
        onClose={props.onClose}
        data={data}
        isLoading={isLoading || dataRoomQuery.isPending}
        error={error || dataRoomQuery.error}
      />
      <EditFolderContent
        data={data}
        isLoading={isLoading || dataRoomQuery.isPending}
        error={error || dataRoomQuery.error}
        onClose={props.onClose}
        onUpdated={props.onUpdated}
        permissions={
          dataRoomQuery.data ? dataRoomQuery.data.deal.dataRoom.permissions : []
        }
      />
    </AnimatedModal>
  );
}

interface EditFolderProps {
  isLoading: boolean;
  error: any;
  data: DataRoomFolderQuery | undefined;
  onClose: () => void;
}

function EditFolderHeader(props: EditFolderProps) {
  let title = "Edit Folder";

  if (props.error) {
    title = "Error";
  }

  if (props.data) {
    title = `Edit ${props.data.dataRoomFolder.name}`;
  }

  return (
    <div className="flex items-center justify-between">
      <H3>{title}</H3>
      <CloseIcon onClose={props.onClose} />
    </div>
  );
}

function EditFolderContent(
  props: EditFolderProps & {
    onUpdated?: () => void;
    permissions: Pick<DataRoomPermission, "id" | "name" | "description">[];
  }
) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const [folderName, setName] = useState("");

  const updateDataRoomFolder = useUpdateDataRoomFolderMutation(client);

  useEffect(() => {
    if (props.data) {
      setName(props.data.dataRoomFolder.name);
    }
  }, [props.data, props.isLoading, props.error]);

  if (props.error) {
    return (
      <div className="flex justify-center items-center p-4">
        <p className="text-gray-700 font-bold">Something went wrong</p>
      </div>
    );
  }

  if (props.isLoading || !props.data) {
    return <Loading />;
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (!props.data) {
            return;
          }

          updateDataRoomFolder.mutate(
            {
              input: {
                id: props.data.dataRoomFolder.id,
                name: folderName,
              },
            },
            {
              onSuccess: () => {
                if (props.onUpdated) {
                  props.onUpdated();
                }

                queryClient.invalidateQueries({
                  queryKey: [
                    "DataRoomFolder",
                    { id: props.data?.dataRoomFolder.id },
                  ],
                });

                if (
                  props.data?.dataRoomFolder.parentFolders &&
                  props.data.dataRoomFolder.parentFolders.length > 0
                ) {
                  props.data.dataRoomFolder.parentFolders.forEach((parent) => {
                    queryClient.invalidateQueries({
                      queryKey: ["DataRoomFolder", { id: parent.id }],
                    });
                  });
                }

                props.onClose();
              },
            }
          );
        }}
      >
        <TextInput
          margin="m 0 0 0"
          ignore1p
          label="Name"
          placeholder="Name of the file"
          value={folderName}
          onChange={(e) => setName(e.currentTarget.value)}
        />

        <div className="mt-12 flex justify-end">
          <Button
            type="submit"
            text="Save"
            variant="positive"
            isLoading={updateDataRoomFolder.isPending}
            isDisabled={folderName === ""}
            loadingText="Saving..."
          />
        </div>
      </form>
    </div>
  );
}
