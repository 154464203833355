import React, { Dispatch, SetStateAction, useState } from "react";
import { folderWrapper, fileWrapper } from "./NewFilesUploader";
import { classNames } from "@/src/utils/cn";
import {
  FolderIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { formatFileSize } from "@/src/utils/formatFileSize";
import { DataRoomPermission } from "@/src/graphql/generated";
import { adminRoles, DealRoleRestricted } from "../../DealRoleRestricted";
import Dropdown from "../../tailwind/Dropdown";
import { TextInput } from "../../tailwind/TextInput";
import { LocalFileIcon } from "../../icons/LocalFileIcon";

interface NestedFolderItemProps {
  folder: folderWrapper;
  permissions: DataRoomPermission[];
  onFoldersChange: Dispatch<SetStateAction<folderWrapper[]>>;
  onFilesChange: Dispatch<SetStateAction<fileWrapper[]>>;
}

export function NestedFolderItem({
  folder,
  onFoldersChange,
  onFilesChange,
  permissions,
}: NestedFolderItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div
      key={folder.id}
      className={classNames(
        "relative flex flex-col py-2 my-1 gap-x-2",
        folder.status === "complete" ? "opacity-80" : ""
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <button onClick={toggleExpand} className="mr-2">
            {isExpanded ? (
              <ChevronDownIcon className="w-5 h-5 text-gray-500" />
            ) : (
              <ChevronRightIcon className="w-5 h-5 text-gray-500" />
            )}
          </button>
          <FolderIcon className="w-6 h-6 text-blue-500" />
          <div className="ml-3">
            <p className="font-semibold text-xs text-gray-700 truncate">
              {folder.name}
            </p>
            <p className="text-xs text-gray-500 mt-0.5">
              {folder.files && folder.files.length > 0
                ? `${folder.files.length} file(s)`
                : "No files"}
              {folder.children && folder.children.length > 0
                ? `, ${folder.children.length} subfolder(s)`
                : ""}
            </p>
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="ml-10 mt-2 pl-4">
          {folder.files?.map((file: fileWrapper) => (
            <div
              key={file.id}
              className="flex items-center justify-between py-1"
            >
              <div className="flex items-center w-80">
                <div className="mr-2">
                  <LocalFileIcon fileType={file.file.type} />
                </div>
                <p className="text-xs text-gray-700">
                  <div className="flex items-center">
                    <TextInput
                      value={file.name}
                      inputSize="s"
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        const updateFolder = (
                          folders: folderWrapper[]
                        ): folderWrapper[] => {
                          return folders.map((f) => {
                            if (f.id === folder.id) {
                              return {
                                ...f,
                                files: f.files?.map((x) =>
                                  x.id === file.id ? { ...x, name: value } : x
                                ),
                              };
                            } else if (f.children) {
                              return {
                                ...f,
                                children: updateFolder(f.children),
                              };
                            }
                            return f;
                          });
                        };

                        onFoldersChange((prevFolders) =>
                          updateFolder(prevFolders)
                        );
                      }}
                    />
                    <span className="text-xs text-gray-500 ml-2">
                      ({formatFileSize(file.file.size)})
                    </span>
                  </div>
                </p>
              </div>
              {permissions.length > 0 ? (
                <DealRoleRestricted roles={adminRoles}>
                  <Dropdown
                    hideSelectedOptionDescription
                    variant="light"
                    selectedOption={file.selectedPermission}
                    onSelect={(option) => {
                      const updateFolder = (
                        folders: folderWrapper[]
                      ): folderWrapper[] => {
                        return folders.map((f) => {
                          if (f.id === folder.id) {
                            return {
                              ...f,
                              files: f.files?.map((x) =>
                                x.id === file.id
                                  ? { ...x, selectedPermission: option }
                                  : x
                              ),
                            };
                          } else if (f.children) {
                            return {
                              ...f,
                              children: updateFolder(f.children),
                            };
                          }
                          return f;
                        });
                      };

                      onFoldersChange((prevFolders) =>
                        updateFolder(prevFolders)
                      );
                    }}
                    options={permissions.map((permission) => {
                      return {
                        value: permission.id,
                        label: permission.name,
                        description: permission.description,
                      };
                    })}
                  />
                </DealRoleRestricted>
              ) : null}
            </div>
          ))}
          {folder.children &&
            folder.children.map((childFolder) => (
              <NestedFolderItem
                key={childFolder.id}
                folder={childFolder}
                onFoldersChange={onFoldersChange}
                onFilesChange={onFilesChange}
                permissions={permissions}
              />
            ))}
        </div>
      )}
    </div>
  );
}
