import { useSelector } from "react-redux";
import { H4 } from "../../components/Heading";
import {
  AllFilesQuery,
  DashboardQuery,
  DealAccessStatus,
  DealActivityType,
  NotificationType,
  useAllFilesQuery,
  useDashboardQuery,
  useSendNotificationMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { useState } from "react";
import {
  differenceInDays,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  fromUnixTime,
  getUnixTime,
  isAfter,
  sub,
} from "date-fns";
import Loading from "../../components/Loading";
import { Card } from "../../components/Card";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { Link, NavLink } from "react-router-dom";
import { Avatar } from "../../components/account/Avatar";
import { FilePill } from "../../components/FilePill";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { ActivityItem } from "../../components/activity/ActivityItem";
import { classNames } from "../../utils/cn";
import { UseQueryResult } from "@tanstack/react-query";
import { FileIcon } from "../../components/FileIcon";
import {
  DealRoleRestricted,
  adminRoles,
} from "../../components/DealRoleRestricted";
import { AppState } from "../../store";
import { DealProgress } from "../../components/DealProgress";
import { Pulse } from "../../components/Pulse";
import { presenceSelectors } from "../../store/presence/selector";
import { Button } from "../../components/tailwind/Button";
import {
  FolderIcon,
  LockKeyholeIcon,
  MessageCircleQuestionIcon,
} from "lucide-react";

export function Dashboard() {
  const activeDealId = useSelector(authSelectors.activeDealId);
  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-1 p-8 flex flex-col">
        <DashboardContent dealId={activeDealId ?? ""} />
      </div>
    </div>
  );
}

function DashboardContent(props: { dealId: string }) {
  const [startDate, setStartDate] = useState<number>(
    getUnixTime(sub(new Date(), { days: 6 })),
  );
  const [endDate, setEndDate] = useState<number>(getUnixTime(new Date()));
  const [showRemindAdmin, setShowRemindAdmin] = useState(true);

  const client = useGqlClient();
  const dashboardQuery = useDashboardQuery(client, {
    dealId: props.dealId,
    activityInput: {
      startDate: startDate,
      endDate: endDate,
    },
    input: {
      startDate: startDate,
      endDate: endDate,
    },
  });
  const sendNotification = useSendNotificationMutation(client);

  if (dashboardQuery.error) {
    return <div>Something went wrong</div>;
  }

  if (dashboardQuery.isPending || !dashboardQuery.data) {
    return (
      <div className="flex-1 ">
        <Loading />
      </div>
    );
  }

  if (
    dashboardQuery.data.deal.activeDealAccount?.dealAccessStatus ===
    DealAccessStatus.PendingManualApproval
  ) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="py-4 mx-auto text-center">
          <h1 className="text-gray-500 text-lg">
            Your access to this deal is pending manual approval.
          </h1>
          <div className="mt-4 flex justify-center">
            <Button
              onClick={() => {
                if (!showRemindAdmin) {
                  return;
                }
                sendNotification.mutate(
                  {
                    input: {
                      type: NotificationType.InviteAccepted,
                      inviteEmail:
                        dashboardQuery.data.deal.activeDealAccount?.account
                          .email,
                      dealAccountID:
                        dashboardQuery.data.deal.activeDealAccount?.id,
                      dealID: props.dealId,
                    },
                  },
                  {
                    onSuccess: () => {
                      setShowRemindAdmin(false);
                    },
                  },
                );
              }}
              isDisabled={!showRemindAdmin}
              text={
                showRemindAdmin
                  ? "Send Reminder to administrator"
                  : "Reminder Sent"
              }
              variant="positive"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <H4>Overview</H4>
      <div className="mt-1 flex flex-col xl:flex-row gap-4">
        <DataRoomCardContent />
        <div className="w-full xl:w-1/3">
          <Card>
            <div className="h-60 flex flex-col">
              <div className="flex px-3 pt-3 pb-2 border-b border-gray-200 items-center ">
                <MessageCircleQuestionIcon className="w-4 h-4 text-gray-600" />
                <div className="ml-2">
                  <p className="text-gray-600 text-sm font-semibold">
                    Questions
                  </p>
                </div>
              </div>
              <div className="overflow-y-scroll no-scrollbar flex flex-col flex-1">
                <div className="flex flex-col flex-1">
                  {dashboardQuery.data.questions.questions.length === 0 ? (
                    <div className="flex  justify-center flex-1  items-center">
                      <p className="text-gray-500 text-xs">No questions</p>
                    </div>
                  ) : null}
                  {dashboardQuery.data.questions.questions
                    .filter((question) => !question.answer)
                    .map((question) => {
                      return (
                        <Link
                          key={question.id}
                          to={`/deal/questions/${question.id}`}
                          className="cursor-pointer"
                        >
                          <div className="flex hover:bg-gray-100 px-3 py-2 items-center space-x-3">
                            <Avatar account={question.createdBy} />
                            <div className="flex-1">
                              <p className="text-gray-600 text-sm font-semibold leading-tight">
                                {question.title}
                              </p>
                              <p className="text-xs text-gray-500/80 leading-tight text-ellipsis">
                                Created{" "}
                                {formatDistanceToNow(
                                  fromUnixTime(question.createdAt),
                                  {
                                    addSuffix: true,
                                  },
                                )}
                              </p>
                            </div>
                            {question.dataRoomFile ? (
                              <FilePill
                                id={question.dataRoomFile.id}
                                name={question.dataRoomFile.name}
                                type={question.dataRoomFile.fileType}
                              />
                            ) : null}
                            <div className="flex gap-x-2">
                              <ChatBubbleLeftIcon className="mt-0.5 w-4 h-4 text-gray-500" />
                              <p className="text-gray-600 text-xs">
                                {
                                  question.activity.filter(
                                    (a) => a.type === DealActivityType.Comment,
                                  ).length
                                }
                              </p>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <DealRoleRestricted roles={adminRoles}>
          <div className="w-full xl:w-1/3">
            <Card>
              <div className="h-60 flex flex-col">
                <div className="flex px-3 pt-3 border-b border-gray-200 pb-2 items-center ">
                  <LockKeyholeIcon className="w-4 h-4 text-gray-600" />
                  <div className="ml-2">
                    <p className="text-gray-600 text-sm font-semibold">
                      Access
                    </p>
                  </div>
                </div>
                <div className="overflow-y-scroll no-scrollbar">
                  {dashboardQuery.data.deal.buyers.map((buyer, i) => {
                    return (
                      <NavLink
                        key={buyer.id}
                        className={(isActive) => {
                          return classNames(
                            "py-1.5 px-3 hover:bg-gray-100 font-semibold text-gray-700 flex justify-between items-center",
                            i === dashboardQuery.data.deal.buyers.length - 1
                              ? "rounded-b-md"
                              : "",
                          );
                        }}
                        activeClassName="bg-gray-200 text-gray-500"
                        to={`/deal/access/group/${buyer.id}`}
                      >
                        <div>
                          <p className="text-gray-600 text-sm font-semibold">
                            {buyer.name}
                          </p>
                          <p className="text-xs text-gray-500/80 leading-tight font-normal">
                            {buyer.dataRoomPermission.name}
                          </p>
                        </div>
                        {buyer.dealAccessStatus !== DealAccessStatus.Granted ? (
                          <LockClosedIcon className="w-4 h-4 text-gray-500" />
                        ) : null}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            </Card>
          </div>
        </DealRoleRestricted>
      </div>

      <DealRoleRestricted roles={adminRoles}>
        <div className="mt-8 w-full xl:w-1/2">
          <H4>Recent activity</H4>
          {dashboardQuery.data.dealActivity.activity.length === 0 ? (
            <p className="text-gray-500 text-xs">No recent activity</p>
          ) : null}
          <ul role="list" className="mt-3 space-y-6">
            {dashboardQuery.data.dealActivity.activity.map((activity, i) => {
              return (
                <ActivityItem
                  mode="verbose"
                  isFirst={i === 0}
                  isLast={
                    i === dashboardQuery.data.dealActivity.activity.length - 1
                  }
                  key={activity.id}
                  activity={activity}
                />
              );
            })}
          </ul>
        </div>
      </DealRoleRestricted>
    </div>
  );
}

function BuyerCard(props: {
  query: UseQueryResult<DashboardQuery, unknown>;
  buyer: DashboardQuery["deal"]["buyers"][0];
}) {
  const buyer = props.buyer;
  const dealFirmGroupPresence = useSelector((state: AppState) =>
    presenceSelectors.dealFirmGroupPresence(state, buyer.id),
  );

  if (!props.query.data) {
    return null;
  }

  return (
    <div key={buyer.id} className="min-w-72">
      <NavLink to={`/buyers/${buyer.id}`}>
        <Card padding="m">
          <div className="flex items-center justify-between">
            <p className="font-semibold text-gray-700 text-sm">{buyer.name}</p>
            <DealFirmGroupStatus
              dealFirmGroupId={buyer.id}
              dealFirmGroup={buyer}
            />
          </div>
          <div className="flex space-x-0.5 mt-2">
            <DealProgress
              dealStages={props.query.data.deal.dealStages}
              currentDealStage={buyer.currentDealStage}
              currentDealStageStatus={buyer.currentDealStageStatus}
              mode="compact"
            />
          </div>
          <div className="mt-4 gap-1 grid grid-cols-2">
            <p className="text-xs font-semibold text-gray-600">Questions</p>
            <p className="text-xs text-gray-500 text-right">
              {buyer.questions.length}
            </p>

            <p className="text-xs font-semibold text-gray-600">
              Data Room permission level
            </p>
            <p className="text-xs text-gray-500 text-right">
              {buyer.dataRoomPermission.name}
            </p>

            <p className="text-xs font-semibold text-gray-600">Last activity</p>
            <p className="text-xs text-gray-500 text-right">
              {buyer.activity.length === 0 ? (
                "No activity"
              ) : (
                <>
                  {formatDistanceToNowStrict(
                    fromUnixTime(buyer.activity[0].createdAt),
                    { addSuffix: true },
                  )}
                </>
              )}
            </p>

            <p className="text-xs font-semibold text-gray-600">
              Last file upload
            </p>
            <p className="text-xs text-gray-500 text-right">
              {buyer.files.length === 0 ? (
                <p className="text-xs text-gray-500">No files</p>
              ) : (
                <div className="flex justify-end">
                  <FilePill
                    id={
                      buyer.files.sort((a, b) => a.createdAt - b.createdAt)[0]
                        .id
                    }
                    name={
                      buyer.files.sort((a, b) => a.createdAt - b.createdAt)[0]
                        .name
                    }
                    type={
                      buyer.files.sort((a, b) => a.createdAt - b.createdAt)[0]
                        .fileType
                    }
                  />
                </div>
              )}
            </p>
          </div>
        </Card>
      </NavLink>
    </div>
  );
}

function DealFirmGroupStatus(props: {
  dealFirmGroupId: string;
  dealFirmGroup: DashboardQuery["deal"]["buyers"][0];
}) {
  const dealFirmGroupPresence = useSelector((state: AppState) =>
    presenceSelectors.dealFirmGroupPresence(state, props.dealFirmGroupId),
  );

  if (props.dealFirmGroup.dealAccessStatus != DealAccessStatus.Granted) {
    return (
      <div className="flex items-center">
        <LockClosedIcon className="w-4 h-4 text-gray-500" />
        <p className="text-xs text-gray-500">No deal access</p>
      </div>
    );
  }

  if (
    (!dealFirmGroupPresence ||
      dealFirmGroupPresence.activeUsers.length === 0) &&
    props.dealFirmGroup.lastSeen
  ) {
    const lastSeen = fromUnixTime(props.dealFirmGroup.lastSeen);

    if (differenceInDays(new Date(), lastSeen) > 7) {
      return (
        <div className="flex items-center gap-x-1.5">
          <p className="text-xs text-gray-500">Inactive</p>
          <Pulse color="gray" animate />
        </div>
      );
    }

    return (
      <div className="flex items-center gap-x-1.5">
        <p className="text-xs text-gray-500">
          Last seen{" "}
          {formatDistanceToNowStrict(
            fromUnixTime(props.dealFirmGroup.lastSeen),
            { addSuffix: true },
          )}
        </p>
        <Pulse color="gray" />
      </div>
    );
  }

  return (
    <div className="flex items-center gap-x-1.5">
      <p className="text-xs text-green-500">
        {dealFirmGroupPresence && dealFirmGroupPresence.activeUsers
          ? dealFirmGroupPresence.activeUsers.length
          : 0}{" "}
        online
      </p>
      <Pulse color="green" animate />
    </div>
  );
}

function DealAccessText(props: { dealAccessStatus: DealAccessStatus }) {
  switch (props.dealAccessStatus) {
    case DealAccessStatus.Granted:
      return (
        <p className="text-xs text-green-800 leading-tight text-right">
          Granted
        </p>
      );
    case DealAccessStatus.PendingManualApproval:
      return (
        <p className="text-xs text-orange-800 leading-tight text-right">
          Awaiting access
        </p>
      );
    case DealAccessStatus.PendingNda:
      return (
        <p className="text-xs text-orange-800 leading-tight text-right">
          NDA needs signed
        </p>
      );
    case DealAccessStatus.Blocked:
      return (
        <p className="text-xs text-red-800 leading-tight text-right">
          Access revoked
        </p>
      );
    default:
      return null;
  }
}

function DataRoomCardContent() {
  const activeDealId = useSelector(authSelectors.activeDealId);

  const client = useGqlClient();
  const allFiles = useAllFilesQuery(client, {
    dealId: activeDealId ?? "",
  });

  return (
    <div className="w-full xl:w-1/3">
      <Card>
        <div className="h-60 flex flex-col">
          <div className="flex pt-3 border-b border-gray-200 pb-2 px-3 items-center">
            <FolderIcon className="w-4 h-4 text-gray-600" />
            <div className="flex items-center justify-between flex-1">
              <p className="text-gray-600 text-sm ml-2 font-semibold">
                Documents
              </p>

              <p className="text-xs text-gray-500/80">Recent files</p>
            </div>
          </div>
          <div className="overflow-y-scroll no-scrollbar flex flex-col flex-1">
            <DataRoomCardContentInner query={allFiles} />
          </div>
        </div>
      </Card>
    </div>
  );
}

function DataRoomCardContentInner(props: {
  query: UseQueryResult<AllFilesQuery, unknown>;
}) {
  if (props.query.error) {
    return (
      <div className="flex justify-center  flex-1  items-center">
        <p>Something went wrong</p>
      </div>
    );
  }

  if (props.query.isPending || !props.query.data) {
    return (
      <div className="flex justify-center  flex-1  items-center">
        <Loading />
      </div>
    );
  }

  const recentFiles = props.query.data.deal.dataRoom.allFiles.filter((file) =>
    isAfter(fromUnixTime(file.createdAt), sub(new Date(), { hours: 300 })),
  );

  if (recentFiles.length === 0) {
    return (
      <div className="flex justify-center  flex-1  items-center">
        <p className="text-gray-500 text-xs">No recent files</p>
      </div>
    );
  }

  return (
    <div>
      {recentFiles.map((file) => {
        return (
          <NavLink
            key={file.id}
            to={`/deal/documents/folder/${file.folderId}/file/${file.id}`}
          >
            <div
              key={file.id}
              className="flex cursor-pointer hover:bg-gray-100 items-center justify-between px-2.5 py-2"
            >
              <div className="flex items-center">
                <FileIcon fileType={file.fileType} />
                <p className="text-sm ml-1 font-semibold text-gray-700 leading-tight">
                  {file.name}
                </p>
              </div>
              <p className="text-xs justify-self-end text-gray-500/80">
                {formatDistanceToNowStrict(fromUnixTime(file.createdAt), {
                  addSuffix: true,
                })}
              </p>
            </div>
          </NavLink>
        );
      })}
    </div>
  );
}
