import { NavLink, useParams } from "react-router-dom";
import {
  useDealGroupQuestionsQuery,
  Question as GqlQuestion,
  DdqlStatus,
  Ddql,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import Loading from "../../components/Loading";
import { H3, H4 } from "../../components/Heading";
import { Card } from "../../components/Card";
import { Question } from ".";
import { IndividualGuestQuestions } from "./index";
import { Spinner } from "../../components/icons/Spinner";
import { LocalFileIcon } from "../../components/icons/LocalFileIcon";
import { DdqlListItem } from "./ddql/DdqlListItem";

export function GroupWrapper() {
  const { id } = useParams<{ id: string }>();
  if (id.startsWith("acc_")) {
    return <IndividualGuestQuestions accountId={id} />;
  }
  return <Group />;
}

export function Group() {
  const { id } = useParams<{ id: string }>();
  const client = useGqlClient();
  const query = useDealGroupQuestionsQuery(client, { id });

  if (query.error) {
    return (
      <div className="flex-1 flex w-full h-full justify-center items-center">
        <p className="font-semibold text-sm text-gray-700">
          Something went wrong
        </p>
      </div>
    );
  }

  if (query.isPending || !query.data) {
    return <Loading />;
  }

  const publishedDdqls = query.data.dealGroup.ddqls.filter(
    (ddql) => ddql.status === DdqlStatus.Published,
  );

  return (
    <div className="flex-1 flex-col flex">
      <div className="bg-white px-8 py-3 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <NavLink to="/deal/questions">
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                Questions
              </h4>
            </NavLink>
            <p className="font-light text-gray-400">/</p>
            <H4>{query.data.dealGroup.name}</H4>
          </div>
          <div></div>
        </div>
      </div>

      <div className="p-8 pt-4">
        <div className="w-1/2">
          <div>
            <H3>Questions</H3>
            <p className="text-sm text-gray-500">
              Questions asked by this group and questions this group has been
              added to
            </p>
          </div>

          {query.data.dealGroup.questions.length === 0 ? (
            <Card margin="s 0 0 0">
              <div className="p-3 flex flex-col justify-center items-center flex-1">
                <p className="text-center font-semibold text-gray-800 ">
                  No questions yet
                </p>
                <p className="text-gray-500/80 text-sm text-center">
                  We'll notify you when a new question is asked
                </p>
              </div>
            </Card>
          ) : (
            <>
              <Card margin="s 0 0 0">
                {query.data.dealGroup.questions
                  .sort((a, b) => {
                    if (a.answer && !b.answer) {
                      return 1;
                    }

                    if (!a.answer && b.answer) {
                      return -1;
                    }

                    return 0;
                  })
                  .map((question, i) => {
                    return (
                      <>
                        <Question
                          key={question.id}
                          question={question as GqlQuestion}
                          isFirst={i === 0}
                          isLast={
                            i === query.data.dealGroup.questions.length - 1
                          }
                        />
                        {i !== query.data.dealGroup.questions.length - 1 ? (
                          <div className="border-b border-gray-200" />
                        ) : null}
                      </>
                    );
                  })}
              </Card>
            </>
          )}
        </div>
      </div>

      <div className="p-8 pt-4">
        <div className="w-1/2">
          <H3>Published Lists</H3>
          <p className="text-sm text-gray-500 mb-2">
            Lists published by this group and lists this group has been added to
          </p>

          <Card margin="s 0 0 0">
            {publishedDdqls.length === 0 ? (
              <div className="p-3 flex flex-col justify-center items-center flex-1">
                <p className="text-gray-500/80 text-sm font-semibold">
                  No published lists yet
                </p>
              </div>
            ) : (
              publishedDdqls.map((ddql, i) => {
                return (
                  <DdqlListItem
                    key={i}
                    ddql={ddql as Ddql}
                    isFirst={i === 0}
                    isLast={i === publishedDdqls.length - 1}
                  />
                );
              })
            )}
          </Card>
        </div>
      </div>
      {/* <NewDDQLModal
        open={openModal === "create_ddql"}
        onClose={() => {
          queryClient.invalidateQueries({
            queryKey: ["DealFirmGroupQuestions", { id: id }],
          });
          setOpenModal("");
        }}
        parentDdqlID=""
        dealFirmGroupId={id}
      />
      <NewQuestionModal
        open={openModal === "new_question"}
        onClose={() => setOpenModal("")}
        forDealFirmGroupId={id}
      /> */}
    </div>
  );
}

export function StatusPill(props: {
  ddql: Pick<Ddql, "status" | "sourceFileName" | "sourceFileType">;
  mode?: "compact" | "default";
}) {
  switch (props.ddql.status) {
    case DdqlStatus.Imported:
      return (
        <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1 bg-white">
          <div className="flex items-center">
            {props.mode === "compact" ? null : (
              <p className="font-semibold text-xs text-gray-700 mr-2">
                Imported from
              </p>
            )}
            <LocalFileIcon size="s" fileType={props.ddql.sourceFileType} />
            <p className="ml-1 text-xs text-gray-800 truncate">
              {props.ddql.sourceFileName}
            </p>
          </div>
        </div>
      );
    case DdqlStatus.Importing:
      return (
        <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1 bg-white">
          <Spinner size="xs" color="gray" withMargin={false} />
          <div className="flex items-center ml-1">
            <p className="font-semibold text-xs text-gray-700 mr-2">
              Importing from
            </p>
            <LocalFileIcon size="s" fileType={props.ddql.sourceFileType} />
            <p className="ml-1 text-xs text-gray-800 truncate">
              {props.ddql.sourceFileName}
            </p>
          </div>
        </div>
      );
    case DdqlStatus.ImportFailed:
      return (
        <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1 bg-red-50 border-red-200">
          <p className="text-xs text-red-700 font-semibold">Failed to import</p>
        </div>
      );
    default:
      return null;
  }
}

function ProgressBar(props: { progress: number }) {
  return (
    <div className="h-1 w-full bg-green-500/20 rounded-md">
      <div
        className="h-1 bg-green-500 rounded-md transition-all duration-300 ease-in-out"
        style={{ width: `${props.progress}%` }}
      ></div>
    </div>
  );
}
