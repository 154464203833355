import { XCircleIcon } from "@heroicons/react/20/solid";
import { Deal } from "../graphql/generated";
import CompanyLogo from "./CompanyLogo";
import { classNames } from "../utils/cn";

export function DealPill(props: {
  deal: Pick<Deal, "id"> & {
    company?: Pick<Deal["company"], "id" | "name" | "logo" | "logoColor">;
  };
  onRemove?: () => void;
  size?: "s" | "m";
}) {
  const deal = props.deal;
  return (
    <div className="flex flex-wrap group justify-center relative">
      <div
        className={classNames(
          "cursor-pointer  bg-white hover:shadow-sm relative flex items-center rounded-2xl border ",
          props.size === "s" ? "px-1.5 py-0.5 gap-x-1" : "px-2 py-1 gap-x-2",
        )}
      >
        <CompanyLogo
          logo={deal.company?.logo}
          withShadow={false}
          borderSize={"3"}
          size={props.size === "s" ? 18 : 24}
          name={deal.company ? deal.company.name : ""}
          bgColor={deal.company ? deal.company.logoColor : "#000"}
        />
        <p className="text-xs  mt-0.5 truncate">
          {props.deal.company ? props.deal.company.name : ""}
        </p>
      </div>
      {props.onRemove ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (props.onRemove) props.onRemove();
          }}
          className="hidden group-hover:block absolute -top-1.5 -right-2.5 group"
        >
          <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
        </button>
      ) : null}
    </div>
  );
}
