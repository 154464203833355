import { AnimatedModal } from "@/src/components/AnimatedModal";
import { CloseIcon } from "@/src/components/CloseIcon";
import Dropdown, { Option } from "@/src/components/tailwind/Dropdown";
import {
  AccessControlDealGroupFragment,
  AccessControlPermissionLevel,
  useUpdateAccessControlMutation,
} from "@/src/graphql/generated";
import { Button } from "@/src/components/tailwind/Button";
import { useEffect, useState } from "react";
import useGqlClient from "@/src/hooks/useGqlClient";
import { toasts } from "@/src/components/toasts/toasts";
import { ddqlPermissionOptions } from "./AddMoreModal";

export function EditAccessModal(props: {
  open: boolean;
  onClose: () => void;
  resourceId: string;
  accessControlDealGroup: AccessControlDealGroupFragment;
}) {
  const { open, resourceId, accessControlDealGroup } = props;

  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    undefined,
  );

  const client = useGqlClient();
  const updateAcessControl = useUpdateAccessControlMutation(client);

  useEffect(() => {
    if (props.open) {
      const permissionLevel =
        accessControlDealGroup.accessControl.permissionLevel;
      const option = ddqlPermissionOptions.find(
        (option) => option.value === permissionLevel,
      );
      if (option) {
        setSelectedOption(option);
      }
    }
  }, [props.open]);

  function onClose() {
    props.onClose();
  }

  return (
    <AnimatedModal open={open} onClose={onClose} size="lg">
      <div className="">
        <div className="flex items-center justify-between">
          <p className="text-lg font-semibold text-gray-700">
            Edit access for {accessControlDealGroup.dealGroup.name}
          </p>
          <CloseIcon onClose={onClose} />
        </div>

        <div className="mt-4">
          <Dropdown
            options={ddqlPermissionOptions}
            onSelect={(option) => {
              setSelectedOption(option);
            }}
            selectedOption={selectedOption}
          />
        </div>
        <div className="mt-8 flex justify-end">
          <Button
            variant="positive"
            text="Save"
            isLoading={updateAcessControl.isPending}
            isDisabled={!selectedOption || updateAcessControl.isPending}
            onClick={() => {
              if (updateAcessControl.isPending || !selectedOption) {
                return;
              }

              updateAcessControl.mutate(
                {
                  input: {
                    id: accessControlDealGroup.accessControl.id,
                    permissionLevel:
                      selectedOption.value as AccessControlPermissionLevel,
                  },
                },
                {
                  onSuccess: () => {
                    toasts.success("Access updated");
                    onClose();
                  },
                  onError: () => {
                    toasts.error("Failed to update access");
                  },
                },
              );
            }}
          />
        </div>
      </div>
    </AnimatedModal>
  );
}
