import { useDispatch, useSelector } from "react-redux";
import { H3 } from "../../components/Heading";
import { authSelectors } from "../../store/auth/selector";
import {
  AccountQuery,
  FirmAccountType,
  FirmQuery,
  FirmType,
  useAccountQuery,
  useCreateFirmMutation,
  useFirmQuery,
  useUpdateFirmMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import Loading from "../../components/Loading";
import { Card } from "../../components/Card";
import { Button } from "../../components/tailwind/Button";
import { AnimatedModal } from "../../components/AnimatedModal";
import { TextInput } from "../../components/tailwind/TextInput";
import { useState } from "react";
import { TextArea } from "../../components/tailwind/TextArea";
import { CloseIcon } from "../../components/CloseIcon";
import { useQueryClient } from "@tanstack/react-query";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";
import { actions } from "../../store/auth/slice";
import { formatFirmType } from "@/src/utils/enums";
import { NavLink, Route, Switch } from "react-router-dom";
import Members from "@/src/routes/firm/members";
import { TableProperties, UsersRound } from "lucide-react";
import { classNames } from "@/src/utils/cn";
import DealProperties from "./deal_properties";
import { DealProperty } from "./deal_properties/deal-property";

const navLinks = [
  {
    label: "Firm",
    icon: <UsersRound className="w-4 h-4" />,
    to: "/firm",
    exact: true,
  },
  {
    label: "Custom deal properties",
    icon: <TableProperties className="w-4 h-4" />,
    to: "/firm/deal-properties",
  },
];

export function Firm() {
  const account = useSelector(authSelectors.account);

  if (!account) {
    return null;
  }

  const links = account.firm ? navLinks : navLinks.slice(0, 1);

  return (
    <div className="flex-1">
      <div className="flex ">
        <div className="bg-white  h-screen min-w-72  shadow-sm p-2 border-r border-gray-200">
          {account.firm ? (
            <div className="px-3 py-2">
              <p className="font-semibold text-lg">{account.firm.name}</p>
            </div>
          ) : null}
          <div className="space-y-2">
            {links.map((link) => (
              <NavLink
                key={link.to}
                to={link.to}
                exact={link.exact}
                className={(isActive) => {
                  return classNames(
                    "flex text-gray-500 items-center gap-x-1.5 py-3 px-3 rounded-md hover:bg-gray-100 hover:text-gray-800",
                    isActive ? "bg-gray-100 text-gray-800" : "",
                  );
                }}
              >
                <>
                  {link.icon}
                  <span className="text-sm font-semibold">{link.label}</span>
                </>
              </NavLink>
            ))}
          </div>
        </div>
        <div className="flex-1 ">
          <Switch>
            <Route path="/firm" exact>
              {account.firm ? (
                <div className="p-8">
                  <p className="mb-2 text-md font-semibold text-gray-700">
                    Details
                  </p>
                  <FirmDetails firm={account.firm} account={account} />
                  <p className="mb-2 mt-4 text-md font-semibold text-gray-700">
                    Members
                  </p>
                  <Members />
                </div>
              ) : (
                <CreateFirm />
              )}
            </Route>
            <Route path="/firm/members" exact></Route>
            <Route path="/firm/deal-properties" exact>
              <DealProperties firmId={account.firm?.id ?? ""} />
            </Route>
            <Route path="/firm/deal-properties/:id" exact>
              <DealProperty />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

function FirmDetails(props: {
  firm: AccountQuery["account"]["firm"];
  account: AccountQuery["account"];
}) {
  const client = useGqlClient();
  const [showEditModal, setShowEditModal] = useState(false);

  const firmQuery = useFirmQuery(client, {
    id: props.firm ? props.firm.id : "",
  });

  if (firmQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (firmQuery.isPending || !firmQuery.data) {
    return <Loading />;
  }

  const firm = firmQuery.data.firm;

  return (
    <div>
      <div className="flex flex-col xl:flex-row  xl:gap-x-7">
        <div className="xl:w-1/2 w-full">
          <Card padding="m">
            <div className="flex justify-between items-start">
              <div>
                <p className="font-semibold text-lg">{firm.name}</p>
                <p className="text-sm text-gray-500 leading-tight">
                  {firm.description}
                </p>
              </div>
              {props.account.firmAccountType === FirmAccountType.Admin ? (
                <button
                  onClick={() => {
                    setShowEditModal(true);
                  }}
                  className="font-semibold text-sm text-indigo-500 hover:text-indigo-600"
                >
                  Edit
                </button>
              ) : null}
            </div>

            <div className="grid grid-cols-1 gap-x-8">
              <div className="">
                <p className="text-sm font-semibold text-gray-800 mt-3">
                  Website
                </p>
                {firm.website ? (
                  <a
                    target="_blank"
                    href={firm.website.startsWith('http') ? firm.website : `https://${firm.website}`}
                    className="text-sm text-blue-600 underline truncate"
                    rel="noreferrer"
                  >
                    {firm.website}
                  </a>
                ) : (
                  <p className="text-sm text-gray-500">No website</p>
                )}
              </div>

              <div>
                <p className="text-sm font-semibold text-gray-800 mt-3">
                  Address
                </p>
                {firm.address ? (
                  <p className="text-sm text-gray-600">{firm.address}</p>
                ) : (
                  <p className="text-sm text-gray-500">No address</p>
                )}
              </div>

              <div>
                <p className="text-sm font-semibold text-gray-800 mt-3">Type</p>
                <p className="text-sm text-gray-600">
                  {formatFirmType(firm.type)}
                </p>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <EditFirmModal
        firm={firm}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
      />
    </div>
  );
}

function EditFirmModal(props: {
  open: boolean;
  onClose: () => void;
  firm: FirmQuery["firm"];
}) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const updateFirm = useUpdateFirmMutation(client);

  const [name, setName] = useState(props.firm.name);
  const [nameError, setNameError] = useState("");

  const [description, setDescription] = useState(props.firm.description);
  const [descriptionError, setDescriptionError] = useState("");

  const [selectedType, setSelectedType] = useState<Option>({
    value: props.firm.type,
    label: formatFirmType(props.firm.type),
  });

  const [website, setWebsite] = useState(props.firm.website);
  const [address, setAddress] = useState(props.firm.address);

  return (
    <AnimatedModal open={props.open} onClose={props.onClose}>
      <div>
        <div className="flex items-center justify-between">
          <H3>Edit {props.firm.name}</H3>
          <CloseIcon onClose={props.onClose} />
        </div>
        <form
          className="mt-3"
          onSubmit={(e) => {
            e.preventDefault();

            if (!name || !description) {
              if (!name) {
                setNameError("Name is required");
              }
              if (!description) {
                setDescriptionError("Description is required");
              }
              return;
            }

            updateFirm.mutate(
              {
                input: {
                  id: props.firm.id,
                  name,
                  description,
                  website,
                  address,
                  type: selectedType.value as FirmType,
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["Account", {}],
                  });
                  queryClient.invalidateQueries({
                    queryKey: ["Firm", { id: props.firm.id }],
                  });
                  props.onClose();
                },
              },
            );
          }}
        >
          <TextInput
            ignore1p
            label="Name"
            placeholder="Enter firm name..."
            value={name}
            onChange={(e) => {
              setNameError("");
              setName(e.currentTarget.value);
            }}
          />
          {nameError && (
            <p className="mt-1 text-sm text-red-600">{nameError}</p>
          )}

          <label
            htmlFor="description"
            className="block text-sm mt-3 font-medium leading-6 text-gray-900"
          >
            Description
          </label>
          <TextArea
            label="Description"
            name="description"
            placeholder="A short description of your firm..."
            rows={4}
            value={description}
            onChange={(e) => [
              setDescriptionError(""),
              setDescription(e.currentTarget.value),
            ]}
          />
          {descriptionError && (
            <p className="mt-1 text-sm text-red-600">{descriptionError}</p>
          )}

          <label
            htmlFor="firm_type_dropdown"
            className="block text-sm mt-3 font-medium leading-6 text-gray-900"
          >
            Firm type
          </label>
          <Dropdown
            options={options}
            onSelect={(o) => {
              setSelectedType(o);
            }}
            selectedOption={selectedType}
          />

          <TextInput
            margin="m 0 0 0"
            secondaryLabel="Optional"
            label="Website"
            placeholder="Enter firm website..."
            value={website}
            onChange={(e) => {
              setWebsite(e.currentTarget.value);
            }}
          />

          <TextInput
            ignore1p
            margin="m 0 0 0"
            label="Address"
            secondaryLabel="Optional"
            placeholder="Enter firm address..."
            value={address}
            onChange={(e) => {
              setAddress(e.currentTarget.value);
            }}
          />

          <div className="mt-6 flex justify-end">
            <Button
              isLoading={updateFirm.isPending}
              loadingText="Saving..."
              type="submit"
              variant="positive"
              text="Save"
            />
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}

function CreateFirm() {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex p-4">
      <Card padding="m">
        <div className="h-full flex-1  flex flex-col">
          <p className="font-semibold text-lg text-gray-800">
            You don't have a firm
          </p>
          <p className="text-sm text-gray-500 leading-tight">
            Create one below or ask someone else at your Firm to add you
          </p>
        </div>
        <Button
          margin="xl 0 0 0"
          text="Create firm"
          variant="positive"
          onClick={() => {
            setOpen(true);
          }}
        />
      </Card>
      <CreateFirmModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}

const options: Option[] = [
  {
    value: FirmType.LawFirm,
    label: formatFirmType(FirmType.LawFirm),
  },
  {
    value: FirmType.Accountant,
    label: formatFirmType(FirmType.Accountant),
  },
  {
    value: FirmType.Broker,
    label: formatFirmType(FirmType.Broker),
  },
  {
    value: FirmType.Buyer,
    label: formatFirmType(FirmType.Buyer),
  },
  {
    value: FirmType.Seller,
    label: formatFirmType(FirmType.Seller),
  },
  {
    value: FirmType.Other,
    label: formatFirmType(FirmType.Other),
  },
];

function CreateFirmModal(props: { open: boolean; onClose: () => void }) {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [website, setWebsite] = useState("");

  const [address, setAddress] = useState("");

  const [type, setType] = useState<Option>(options[0]);

  const queryClient = useQueryClient();
  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);
  const createFirmMutation = useCreateFirmMutation(client);
  const dispatch = useDispatch();

  return (
    <AnimatedModal open={props.open} onClose={props.onClose} size="lg">
      <div>
        <div className="flex items-center justify-between">
          <div>
            <H3>Create Firm</H3>
            <p className="text-sm text-gray-500 leading-tight">
              Create a new firm
            </p>
          </div>
          <CloseIcon onClose={props.onClose} />
        </div>

        <form
          className="mt-3 "
          onSubmit={(e) => {
            e.preventDefault();
            if (!name || !description) {
              if (!name) {
                setNameError("Name is required");
              }
              if (!description) {
                setDescriptionError("Description is required");
              }
              return;
            }

            createFirmMutation.mutate(
              {
                input: {
                  name,
                  description,
                  website,
                  address,
                  type: type.value as FirmType,
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["Account", {}],
                  });

                  accountQuery.refetch().then((data) => {
                    if (data.data) {
                      dispatch(
                        actions.refreshAccount({ account: data.data.account }),
                      );
                      props.onClose();
                    }
                  });
                },
              },
            );
          }}
        >
          <TextInput
            ignore1p
            label="Name"
            placeholder="Enter firm name..."
            onChange={(e) => {
              setNameError("");
              setName(e.currentTarget.value);
            }}
          />
          {nameError && (
            <p className="mt-1 text-sm text-red-600">{nameError}</p>
          )}

          <label
            htmlFor="description"
            className="block text-sm mt-3 font-medium leading-6 text-gray-900"
          >
            Description
          </label>
          <TextArea
            label="Description"
            name="description"
            placeholder="A short description of your firm..."
            error={descriptionError}
            rows={2}
            value={description}
            onChange={(e) => {
              setDescriptionError("");
              setDescription(e.currentTarget.value);
            }}
          />
          {descriptionError && (
            <p className="mt-1 text-sm text-red-600">{descriptionError}</p>
          )}

          <label
            htmlFor="firm_type_dropdown"
            className="block text-sm mt-3 font-medium leading-6 text-gray-900"
          >
            Firm type
          </label>
          <Dropdown
            options={options}
            onSelect={(e) => {
              setType(e);
            }}
            selectedOption={type}
          />

          <TextInput
            margin="m 0 0 0"
            type="url"
            secondaryLabel="Optional"
            label="Website"
            placeholder="Enter firm website..."
            value={website}
            onChange={(e) => {
              setWebsite(e.currentTarget.value);
            }}
          />

          <TextInput
            ignore1p
            margin="m 0 0 0"
            label="Address"
            secondaryLabel="Optional"
            placeholder="Enter firm address..."
            value={address}
            onChange={(e) => {
              setAddress(e.currentTarget.value);
            }}
          />

          <div className="mt-6 flex justify-end">
            <Button
              variant="positive"
              text="Create firm"
              loadingText="Creating..."
              type="submit"
              isLoading={createFirmMutation.isPending}
            />
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}
