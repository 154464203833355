import { Draggable } from "@hello-pangea/dnd";
import { useState, useCallback } from "react";

import {
  DataRoomFile,
  DataRoomFileOnlineStatus,
  DataRoomFolderFragmentFragment,
  FileType,
} from "@/src/graphql/generated";
import { Droppable } from "@hello-pangea/dnd";
import {
  adminRoles,
  DealRoleRestricted,
} from "@/src/components/DealRoleRestricted";
import { TripleDotMenu } from "@/src/components/TripleDotMenu";
import { Menu } from "@headlessui/react";
import { classNames } from "@/src/utils/cn";
import { FileIcon } from "@/src/components/FileIcon";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { useHistory } from "react-router-dom";
import { ReactNode } from "react";

// TableCell component for consistent styling
interface TableCellProps {
  children: ReactNode;
  className?: string;
  visibilityClass?: string;
}

function TableCell({
  children,
  className = "",
  visibilityClass = "",
}: TableCellProps) {
  return (
    <td className={`py-2 px-3 ${visibilityClass} ${className}`}>{children}</td>
  );
}

// Text component for consistent text styling
function TableText({
  children,
  className = "",
}: {
  children: ReactNode;
  className?: string;
}) {
  return <p className={`text-sm text-gray-500 ${className}`}>{children}</p>;
}

export function NonDroppableFiles(props: {
  folder: DataRoomFolderFragmentFragment;
}) {
  const history = useHistory();
  return (
    <div className="contents">
      {props.folder?.files
        .sort((a, b) => {
          if (
            a.onlineStatus === DataRoomFileOnlineStatus.Online &&
            b.onlineStatus !== DataRoomFileOnlineStatus.Online
          ) {
            return -1;
          }

          if (
            b.onlineStatus === DataRoomFileOnlineStatus.Online &&
            a.onlineStatus !== DataRoomFileOnlineStatus.Online
          ) {
            return 1;
          }

          return 0;
        })
        .map((file, index) => (
          <tr
            className={` w-full table-row group hover:bg-gray-50 cursor-pointer `}
            onClick={(e) => {
              // Use our new handler with shift key detection
              const link = `/deal/documents/folder/${props.folder.id}/file/${file.id}`;
              history.push(link);
            }}
          >
            <TableCell>
              <div className="flex items-center gap-x-2">
                <FileIcon fileType={file.fileType} />
                <p className="text-gray-600 font-semibold text-sm">
                  {file.name}
                </p>
                {file.onlineStatus === DataRoomFileOnlineStatus.Offline ? (
                  <p className="text-xs text-gray-500">Offline</p>
                ) : null}
              </div>
            </TableCell>

            <DealRoleRestricted roles={adminRoles}>
              <TableCell visibilityClass="hidden xl:table-cell">
                <TableText>{file.createdBy.name}</TableText>
              </TableCell>
            </DealRoleRestricted>

            <TableCell visibilityClass="hidden md:table-cell">
              <TableText>
                {formatDistanceToNowStrict(fromUnixTime(file.updatedAt), {
                  addSuffix: true,
                })}
              </TableText>
            </TableCell>

            <DealRoleRestricted roles={adminRoles}>
              <TableCell visibilityClass="hidden lg:table-cell">
                <TableText>{file.permission.name}</TableText>
              </TableCell>
            </DealRoleRestricted>
          </tr>
        ))}
    </div>
  );
}

export function DroppableFiles(props: {
  folder: DataRoomFolderFragmentFragment;
  selectedFiles: string[];
  setSelectedFiles: (files: string[]) => void;
  selectedFolders: string[];
  setSelectedFolders: (folders: string[]) => void;
  handleMouseDown: (e: React.MouseEvent) => void;
  handleFileSelection: (
    fileId: string,
    shiftKey: boolean,
    e: React.MouseEvent,
  ) => void;
  setEditFileId: (fileId: string) => void;
  setMoveFileId: (fileId: string) => void;
  setDeleteFileId: (fileId: string) => void;
  // Add an optional prop to track global dragging state (can be set by either component)
  globalDragging?: boolean;
  setGlobalDragging?: (dragging: boolean) => void;
}) {
  const history = useHistory();
  const [isDraggingSelectedFile, setIsDraggingSelectedFile] = useState(false);
  const [fileBeingDragged, setFileBeingDragged] = useState<string | null>(null);
  
  // Clear local state when a drag operation completes
  const resetDragState = useCallback(() => {
    setFileBeingDragged(null);
    setIsDraggingSelectedFile(false);
    // Update global dragging state if prop is provided
    if (props.setGlobalDragging) {
      props.setGlobalDragging(false);
    }
  }, [props.setGlobalDragging]);

  return (
    <Droppable
      droppableId="files"
      type="FILE-FOLDER"
      renderClone={(provided, snapshot, rubric) => {
        // Find the file being dragged to display in the clone
        const fileBeingDragged = props.folder?.files[rubric.source.index];

        if (fileBeingDragged && snapshot.isDragging) {
          // Using setTimeout to avoid state updates during render
          setTimeout(() => {
            setFileBeingDragged(fileBeingDragged.id);
            if (props.selectedFiles.includes(fileBeingDragged.id)) {
              setIsDraggingSelectedFile(true);
            }
            // Update global dragging state
            if (props.setGlobalDragging) {
              props.setGlobalDragging(true);
            }
          }, 0);
        } else if (!snapshot.isDragging) {
          setTimeout(resetDragState, 0);
        }

        const totalSelectedItems =
          props.selectedFiles.length + props.selectedFolders.length;

        // Determine if we're dragging multiple items (either multiple files or files+folders)
        const isDraggingMultiple =
          totalSelectedItems > 1 &&
          (props.selectedFiles.includes(fileBeingDragged?.id) ||
            (totalSelectedItems > 0 && props.selectedFolders.length > 0));

        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div className="relative w-[160px]">
              <div className="absolute -top-1 -left-1 w-full h-full bg-gray-100 border border-gray-300 rounded-md"></div>
              <div className="absolute -top-0.5 -left-0.5 w-full h-full bg-gray-50 border border-gray-300 rounded-md"></div>
              <div className="relative bg-white border border-gray-300 rounded-md p-1 px-2">
                <div className="flex items-center gap-2">
                  <FileIcon
                    fileType={fileBeingDragged?.fileType || FileType.Doc}
                  />
                  <span className="text-sm font-medium">
                    {isDraggingMultiple
                      ? `${totalSelectedItems} item${totalSelectedItems !== 1 ? "s" : ""}`
                      : fileBeingDragged?.name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    >
      {(provided, snapshot) => {
        // When we exit dragging, reset the state
        if (!snapshot.isDraggingOver && !snapshot.draggingFromThisWith && isDraggingSelectedFile) {
          setTimeout(resetDragState, 100);
        }
        
        return (
          <>
            <tr className="hidden">
              <td></td>
            </tr>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="contents"
            >
              {props.folder?.files
                .sort((a, b) => {
                  if (
                    a.onlineStatus === DataRoomFileOnlineStatus.Online &&
                    b.onlineStatus !== DataRoomFileOnlineStatus.Online
                  ) {
                    return -1;
                  }

                  if (
                    b.onlineStatus === DataRoomFileOnlineStatus.Online &&
                    a.onlineStatus !== DataRoomFileOnlineStatus.Online
                  ) {
                    return 1;
                  }

                  return 0;
                })
                .map((file, index) => {
                  // If we're dragging selected files or folders and this file is selected, don't render it
                  // (unless it's the actual file being dragged)
                  const isGloballyDragging = props.globalDragging || isDraggingSelectedFile;
                  
                  if (
                    isGloballyDragging &&
                    props.selectedFiles.includes(file.id) &&
                    file.id !== fileBeingDragged
                  ) {
                    return null;
                  }

                  return (
                    <Draggable
                      key={file.id}
                      draggableId={
                        file.id.startsWith("dtrmfile")
                          ? file.id
                          : `dtrmfile-${file.id}`
                      }
                      index={index}
                      isDragDisabled={
                        file.onlineStatus !== DataRoomFileOnlineStatus.Online
                      }
                    >
                      {(provided, snapshot) => {
                        if (snapshot.isDragging) {
                          setTimeout(() => {
                            setFileBeingDragged(file.id);
                            if (props.selectedFiles.includes(file.id)) {
                              setIsDraggingSelectedFile(true);
                            }
                            // Update global dragging state
                            if (props.setGlobalDragging) {
                              props.setGlobalDragging(true);
                            }
                          }, 0);
                        }

                        return (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`cursor-pointer w-full table-row group ${
                              snapshot.isDragging ? "opacity-70" : ""
                            } ${
                              file.onlineStatus ===
                              DataRoomFileOnlineStatus.Online
                                ? ""
                                : "opacity-70"
                            } ${
                              props.selectedFiles.includes(file.id)
                                ? "bg-blue-100"
                                : "hover:bg-gray-50"
                            }`}
                            onMouseDown={props.handleMouseDown}
                            onDoubleClick={() => {
                              if (!props.folder) {
                                return;
                              }
                              props.setSelectedFiles([]);
                              props.setSelectedFolders([]);
                              const link = `/deal/documents/folder/${props.folder.id}/file/${file.id}`;
                              history.push(link);
                            }}
                            onClick={(e) => {
                              // Use our new handler with shift key detection
                              props.handleFileSelection(file.id, e.shiftKey, e);
                            }}
                          >
                            <TableCell>
                              <div className="flex items-center gap-x-2">
                                <FileIcon fileType={file.fileType} />
                                <p className="text-gray-600 font-semibold text-sm">
                                  {file.name}
                                </p>
                                {file.onlineStatus ===
                                DataRoomFileOnlineStatus.Offline ? (
                                  <p className="text-xs text-gray-500">
                                    Offline
                                  </p>
                                ) : null}
                              </div>
                            </TableCell>

                            <DealRoleRestricted roles={adminRoles}>
                              <TableCell visibilityClass="hidden xl:table-cell">
                                <TableText>{file.createdBy.name}</TableText>
                              </TableCell>
                            </DealRoleRestricted>

                            <TableCell visibilityClass="hidden md:table-cell">
                              <TableText>
                                {formatDistanceToNowStrict(
                                  fromUnixTime(file.updatedAt),
                                  {
                                    addSuffix: true,
                                  },
                                )}
                              </TableText>
                            </TableCell>

                            <DealRoleRestricted roles={adminRoles}>
                              <TableCell visibilityClass="hidden lg:table-cell">
                                <TableText>{file.permission.name}</TableText>
                              </TableCell>
                            </DealRoleRestricted>

                            <DealRoleRestricted roles={adminRoles}>
                              <TableCell visibilityClass="hidden sm:table-cell">
                                <IndividualFileActions
                                  file={file as DataRoomFile}
                                  setEditFileId={props.setEditFileId}
                                  setMoveFileId={props.setMoveFileId}
                                  setDeleteFileId={props.setDeleteFileId}
                                />
                              </TableCell>
                            </DealRoleRestricted>
                          </tr>
                        );
                      }}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          </>
        );
      }}
    </Droppable>
  );
}

function IndividualFileActions(props: {
  file: DataRoomFile;
  setEditFileId: (fileId: string) => void;
  setMoveFileId: (fileId: string) => void;
  setDeleteFileId: (fileId: string) => void;
}) {
  return (
    <TripleDotMenu mode="table">
      <Menu.Item>
        {({ active }) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              props.setEditFileId(props.file.id);
            }}
            className={classNames(
              active ? "bg-gray-50 text-blue-700" : "",
              "block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer",
            )}
          >
            Edit
          </div>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              props.setMoveFileId(props.file.id);
            }}
            className={classNames(
              active ? "bg-gray-50 text-blue-700" : "",
              "block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer",
            )}
          >
            Move
          </div>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              props.setDeleteFileId(props.file.id);
            }}
            className={classNames(
              active ? "bg-gray-50 text-red-700" : "",
              "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer",
            )}
          >
            Delete
          </div>
        )}
      </Menu.Item>
    </TripleDotMenu>
  );
}
