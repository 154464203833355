import { Dispatch, SetStateAction, useState } from "react";
import { fileWrapper, folderWrapper } from "./NewFilesUploader";
import { NestedFolderItem } from "./NestedFolderItem";
import {
  TrashIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  FolderIcon,
} from "@heroicons/react/24/solid";
import { TextInput } from "../../tailwind/TextInput";
import { formatFileSize } from "@/src/utils/formatFileSize";
import { DataRoomPermission } from "@/src/graphql/generated";
import { adminRoles } from "../../DealRoleRestricted";
import Dropdown from "../../tailwind/Dropdown";
import { DealRoleRestricted } from "../../DealRoleRestricted";
import { LocalFileIcon } from "../../icons/LocalFileIcon";

interface LocalFolderItemProps {
  folder: folderWrapper;
  permissions: DataRoomPermission[];
  onFoldersChange: Dispatch<SetStateAction<folderWrapper[]>>;
  onFilesChange: Dispatch<SetStateAction<fileWrapper[]>>;
}

export function LocalFolderItem(props: LocalFolderItemProps) {
  const { folder, onFoldersChange, onFilesChange, permissions } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className="relative flex flex-col py-3 my-2 gap-x-2 border-b border-gray-200">
      <div className="flex items-center justify-between group">
        <div className="flex items-center">
          <button onClick={toggleExpand} className="mr-2">
            {isExpanded ? (
              <ChevronDownIcon className="w-5 h-5 text-gray-500" />
            ) : (
              <ChevronRightIcon className="w-5 h-5 text-gray-500" />
            )}
          </button>
          <FolderIcon className="w-6 h-6 text-blue-500" />
          <div className="ml-3">
            {folder.status === "pending" ? (
              <div className="w-64">
                <TextInput
                  value={folder.name}
                  inputSize="s"
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    onFoldersChange((prev) =>
                      prev.map((f) =>
                        f.id === folder.id ? { ...f, name: value } : f
                      )
                    );
                  }}
                />
              </div>
            ) : (
              <p className="font-semibold text-sm text-gray-700 truncate">
                {folder.name}
              </p>
            )}
            <p className="font-semibold text-sm text-gray-700 truncate inline-block">
              <span className="text-xs text-gray-500 ml-2">
                {folder.files && folder.files.length > 0
                  ? `${folder.files.length} file(s)`
                  : "No files"}
                {folder.children && folder.children.length > 0
                  ? `, ${folder.children.length} subfolder(s)`
                  : ""}
              </span>
            </p>
          </div>
        </div>
        <button
          type="button"
          className="text-gray-400 hover:text-gray-500"
          onClick={() =>
            onFoldersChange((prev) => prev.filter((f) => f.id !== folder.id))
          }
        >
          {folder.status === "pending" && (
            <TrashIcon className="w-5 h-5 text-gray-500/80 hover:text-gray-700 cursor-pointer opacity-0 group-hover:opacity-100 transition-all duration-200 ease-in-out" />
          )}
        </button>
      </div>
      {isExpanded && (
        <div className="ml-10 mt-2 border-l border-gray-300 pl-4">
          {folder.files?.map((file) => (
            <div
              key={file.id}
              className="flex items-center justify-between py-1"
            >
              <div className="flex items-center">
                <div className="mr-2">
                  <LocalFileIcon fileType={file.file.type} />
                </div>
                <p className="text-xs text-gray-700">
                  <div className="flex items-center">
                    <TextInput
                      value={file.name}
                      inputSize="s"
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        const updateFolder = (
                          folders: folderWrapper[]
                        ): folderWrapper[] => {
                          return folders.map((f) =>
                            f.id === folder.id
                              ? {
                                  ...f,
                                  files: f.files?.map((x) =>
                                    x.id === file.id ? { ...x, name: value } : x
                                  ),
                                }
                              : f
                          );
                        };

                        onFoldersChange((prevFolders) =>
                          updateFolder(prevFolders)
                        );
                      }}
                    />
                    <span className="text-xs text-gray-500 ml-2">
                      ({formatFileSize(file.file.size)})
                    </span>
                  </div>
                </p>
              </div>
              {permissions.length > 0 ? (
                <DealRoleRestricted roles={adminRoles}>
                  <Dropdown
                    hideSelectedOptionDescription
                    variant="light"
                    selectedOption={file.selectedPermission}
                    onSelect={(option) => {
                      onFoldersChange((prevFolders) =>
                        prevFolders.map((f) =>
                          f.id === folder.id
                            ? {
                                ...f,
                                files: f.files?.map((x) =>
                                  x.id === file.id
                                    ? { ...x, selectedPermission: option }
                                    : x
                                ),
                              }
                            : f
                        )
                      );
                    }}
                    options={permissions.map((permission) => ({
                      value: permission.id,
                      label: permission.name,
                      description: permission.description,
                    }))}
                  />
                </DealRoleRestricted>
              ) : null}
            </div>
          ))}
          {folder.children &&
            folder.children.map((childFolder) => (
              <NestedFolderItem
                key={childFolder.id}
                folder={childFolder}
                permissions={permissions}
                onFoldersChange={onFoldersChange}
                onFilesChange={onFilesChange}
              />
            ))}
        </div>
      )}
    </div>
  );
}
