import { NavLink, useParams } from "react-router-dom";
import { H4 } from "../../../components/Heading";
import {
  Account,
  Deal,
  TaskPriority,
  TaskQuery,
  TaskStatus,
  useTaskQuery,
  useUpdateTaskMutation,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import Loading from "../../../components/Loading";
import { Button } from "../../../components/tailwind/Button";
import { TextInput } from "../../../components/tailwind/TextInput";
import { TextArea } from "../../../components/tailwind/TextArea";
import { UserPill } from "../../../components/UserPill";
import { SelectUserPill } from "../../../components/SelectUserPill";
import {
  CalendarDaysIcon,
  CheckCircleIcon,
  PlayCircleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { Combobox, Popover, Transition } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import { classNames } from "../../../utils/cn";
import { format, fromUnixTime, getUnixTime, isBefore } from "date-fns";
import { Pills } from "../../../components/Pills";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { toasts } from "../../../components/toasts/toasts";
import { DealPill } from "../../../components/DealPill";
import { SelectDealPill } from "../../../components/SelectDealPill";
import { SelectTaskPriority } from "../../../components/SelectTaskPriorityPill";
import { TaskActivityItems } from "../TaskActivityItems";
import { CommentInput } from "../CommentInput";
import { DatePicker } from "../../../components/DatePicker";

export function Task() {
  const { id } = useParams<{ id: string }>();

  const client = useGqlClient();
  const taskQuery = useTaskQuery(client, {
    id,
  });

  return (
    <div className="flex flex-col flex-1">
      <div className="bg-white sticky right-0 z-20 top-0 px-8 py-4 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <NavLink to="/tasks">
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                Tasks
              </h4>
            </NavLink>
            <p className="font-light text-gray-400">/</p>
            <H4>
              {taskQuery.isLoading || !taskQuery.data
                ? "..."
                : taskQuery.data.task.title}
            </H4>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col h-full w-full p-8">
        <TaskContent id={id} query={taskQuery} />
      </div>
    </div>
  );
}

function TaskContent(props: {
  id: string;
  query: UseQueryResult<TaskQuery, unknown>;
}) {
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [assignedTo, setAssignedTo] = useState<Partial<Account>[] | null>([]);
  const [status, setStatus] = useState<TaskStatus>(TaskStatus.Todo);
  const [deal, setDeal] = useState<Partial<Deal> | null>(null);
  const [priority, setPriority] = useState<TaskPriority | null>(null);

  const queryClient = useQueryClient();
  const client = useGqlClient();
  const updateTask = useUpdateTaskMutation(client);

  function refreshTask() {
    queryClient.invalidateQueries({
      queryKey: ["Task", { id: props.id }],
    });
  }

  useEffect(() => {
    if (props.query.isLoading || props.query.isRefetching) {
      return;
    }

    if (!props.query.data) {
      return;
    }

    const task = props.query.data.task;
    setTitle(task.title);
    setDescription(task.description);
    setAssignedTo(task.assignedTo ?? []);
    setStatus(task.status);
    setDeal(task.deal ? (task.deal as Deal) : null);
    setDueDate(task.dueDate ? fromUnixTime(task.dueDate) : null);
    setPriority(task.priority ? task.priority : null);
  }, [props.query.data, props.query.isRefetching, props.query.isLoading]);

  if (props.query.error) {
    return (
      <div className="flex-col flex-1 justify-center items-center flex">
        <p className="text-gray-700 font-semibold">Failed to load task</p>
        <Button
          margin="s 0 0 0"
          size="s"
          variant="neutral"
          isLoading={props.query.isFetching}
          text="Retry"
          onClick={() => props.query.refetch()}
        />
      </div>
    );
  }

  if (props.query.isLoading || !props.query.data) {
    return <Loading />;
  }

  const task = props.query.data.task;

  return (
    <div>
      <div className="flex flex-col xl:flex-row gap-x-8">
        <div className="flex-1 w-full xl:max-w-3xl">
          <TextInput
            onChange={(e) => {
              setTitle(e.currentTarget.value);
            }}
            label="Title"
            value={title}
            onBlur={() => {
              if (title === "") {
                toasts.error("Title has to be > 1 character");
                refreshTask();
                return;
              }

              updateTask.mutate(
                {
                  input: {
                    id: props.id,
                    title: title,
                  },
                },
                {
                  onSuccess: () => {
                    refreshTask();
                  },
                  onError: () => {
                    toasts.error("Failed to update task");
                    refreshTask();
                  },
                },
              );
            }}
          />

          <p className="mt-3 block text-sm font-medium leading-6 text-gray-900">
            Description
          </p>
          <TextArea
            rows={5}
            name="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.currentTarget.value);
            }}
            placeholder="Description of the task..."
            onBlur={() => {
              updateTask.mutate(
                {
                  input: {
                    id: props.id,
                    description: description,
                  },
                },
                {
                  onSuccess: () => {
                    refreshTask();
                  },
                  onError: () => {
                    toasts.error("Failed to update task");
                    refreshTask();
                  },
                },
              );
            }}
          />
          <H4 margin="xl 0 m 0">Activity</H4>
          <TaskActivityItems mode="compact" items={task.activity.reverse()} />
          <CommentInput
            input={{ taskId: props.id, comment: "" }}
            onCommented={() => {
              refreshTask();
            }}
          />
        </div>
        <div className="flex-shrink-0  min-w-[300px]">
          <p className="mt-8 xl:mt-0 text-sm font-semibold text-gray-700">
            Properties
          </p>
          <div className="flex mt-3 overflow-x-scroll xl:overflow-x-visible no-scrollbar flex-row xl:flex-col flex-wrap gap-4 w-full">
            <div>
              <p className="text-xs font-semibold text-gray-600 mb-1">Status</p>
              <Pills>
                <TaskStatusPill
                  status={status}
                  onStatusChanged={(status) => {
                    setStatus(status);

                    updateTask.mutate(
                      {
                        input: {
                          id: props.id,
                          status: status,
                        },
                      },
                      {
                        onSuccess: () => {
                          refreshTask();
                        },
                        onError: () => {
                          toasts.error("Failed to update task status");
                          refreshTask();
                        },
                      },
                    );
                  }}
                />
              </Pills>
            </div>
            <div>
              <p className="text-xs font-semibold text-gray-600 mb-1">
                Assigned to
              </p>
              <div
                className={`flex mt-2 flex-wrap items-center gap-2 xl:flex-col xl:items-start`}
              >
                {assignedTo?.map((a) => (
                  <UserPill
                    onRemove={() => {
                      setAssignedTo(assignedTo?.filter((at) => at.id !== a.id));
                      updateTask.mutate(
                        {
                          input: {
                            id: props.id,
                            assignedTo: assignedTo
                              ?.filter((at) => at.id !== a.id)
                              .map((at) => at.id!),
                          },
                        },
                        {
                          onSuccess: () => {
                            refreshTask();
                          },
                          onError: () => {
                            toasts.error("Failed to unassign task");
                            refreshTask();
                          },
                        },
                      );
                    }}
                    account={a as Account}
                  />
                ))}
                <SelectUserPill
                  excludedAccountIds={assignedTo?.map((a) => a.id ?? "")}
                  title="Add assignee"
                  icon={<UserCircleIcon className="w-5 h-5 text-gray-500" />}
                  onUserSelected={(acc) => {
                    setAssignedTo([...(assignedTo ?? []), acc as Account]);
                    const assignedToIds = assignedTo
                      ? assignedTo.map((a) => a.id!)
                      : [];

                    assignedToIds.push(acc.id!);

                    updateTask.mutate(
                      {
                        input: {
                          id: props.id,
                          assignedTo:
                            assignedToIds.length > 0
                              ? assignedToIds
                              : undefined,
                        },
                      },
                      {
                        onSuccess: () => {
                          refreshTask();
                        },
                        onError: () => {
                          toasts.error("Failed to assign task");
                          refreshTask();
                        },
                      },
                    );
                  }}
                />
              </div>
            </div>
            <div>
              <p className="text-xs font-semibold text-gray-600 mb-1">
                Due date
              </p>

              <>
                <Pills>
                  <DatePicker
                    onRemove={() => {
                      setDueDate(null);
                    }}
                    pretext="Due"
                    value={dueDate ? dueDate : undefined}
                    onChange={(e) => {
                      setDueDate(e);
                      updateTask.mutate(
                        {
                          input: {
                            id: props.id,
                            dueDate: getUnixTime(e),
                          },
                        },
                        {
                          onSuccess: () => {
                            refreshTask();
                          },
                          onError: () => {
                            toasts.error("Failed to update due date");
                            refreshTask();
                          },
                        },
                      );
                    }}
                  />
                </Pills>
              </>
            </div>
            <div>
              <p className="text-xs font-semibold text-gray-600 mb-1">Deal</p>
              <Pills>
                {deal ? (
                  <DealPill
                    deal={deal as Deal}
                    onRemove={() => {
                      setDeal(null);
                      updateTask.mutate(
                        {
                          input: {
                            id: props.id,
                            clearDeal: true,
                          },
                        },
                        {
                          onSuccess: () => {
                            refreshTask();
                          },
                          onError: () => {
                            toasts.error("Failed to remove deal");
                            refreshTask();
                          },
                        },
                      );
                    }}
                  />
                ) : (
                  <SelectDealPill
                    onDealSelected={(deal) => {
                      setDeal(deal as Deal);
                      updateTask.mutate(
                        {
                          input: {
                            id: props.id,
                            dealID: deal.id,
                          },
                        },
                        {
                          onSuccess: () => {
                            refreshTask();
                          },
                          onError: () => {
                            toasts.error("Failed to assign deal");
                            refreshTask();
                          },
                        },
                      );
                    }}
                  />
                )}
              </Pills>
            </div>
            <div>
              <p className="text-xs font-semibold text-gray-600 mb-1">
                Priority
              </p>
              <Pills>
                <SelectTaskPriority
                  priority={priority ?? undefined}
                  onPriorityChanged={(p) => {
                    setPriority(p);
                    updateTask.mutate(
                      {
                        input: {
                          id: props.id,
                          priority: p,
                        },
                      },
                      {
                        onSuccess: () => {
                          refreshTask();
                        },
                        onError: () => {
                          toasts.error("Failed to assign deal");
                          refreshTask();
                        },
                      },
                    );
                  }}
                />
              </Pills>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TaskStatusPill(props: {
  status: TaskStatus;
  onStatusChanged: (status: TaskStatus) => void;
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <Popover className="relative">
      <Popover.Button
        ref={buttonRef}
        className="relative cursor-pointer  hover:border-gray-400 bg-white hover:shadow-sm flex items-center rounded-2xl border px-2 py-1"
      >
        <TaskStatusIcon status={props.status} />
        <p className="text-sm select-none text-gray-600 ml-1 truncate">
          {getTaskStatusText(props.status)}
        </p>
      </Popover.Button>
      <Transition
        enter="ease-in-out duration-50"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <Popover.Panel className="z-20 absolute mt-5 w-96 -translate-x-1/3 transform px-4">
          <PopOut
            onTaskStatusChanged={(acc) => {
              props.onStatusChanged(acc);
              buttonRef.current?.click();
            }}
          />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

function getTaskStatusText(status: TaskStatus) {
  switch (status) {
    case TaskStatus.Todo:
      return "To do";
    case TaskStatus.InProgress:
      return "In progress";
    case TaskStatus.Completed:
      return "Completed";
  }
}

function TaskStatusIcon(props: { status: TaskStatus }) {
  switch (props.status) {
    case TaskStatus.Todo:
      return (
        <div className=" rounded-full border-2 border-gray-500 w-4 h-4 ml-0.5 text-gray-500" />
      );
    case TaskStatus.InProgress:
      return <PlayCircleIcon className="w-6 h-6 text-gray-500" />;
    case TaskStatus.Completed:
      return <CheckCircleIcon className="w-6 h-6 text-green-600" />;
    default:
      return (
        <div className=" rounded-full border-2 border-gray-500 w-4 h-4 ml-0.5 text-gray-500" />
      );
  }
}

const statuses = [TaskStatus.Todo, TaskStatus.InProgress, TaskStatus.Completed];

const PopOut = ({
  onTaskStatusChanged,
}: {
  onTaskStatusChanged: (status: TaskStatus) => void;
}) => {
  // Adjust the style below based on the exact positioning you need

  return (
    <div className="border rounded-md ml-10">
      <Combobox
        onChange={(f: TaskStatus) => {
          onTaskStatusChanged(f);
        }}
      >
        <div className="">
          <div className="bg-white rounded-b-md">
            <Combobox.Options
              static
              className="max-h-80 scroll-py-2 divide-y  divide-gray-100 overflow-y-auto"
            >
              {statuses.map((status) => {
                return (
                  <Combobox.Option
                    key={status}
                    value={status}
                    className={({ active }) =>
                      classNames(
                        "cursor-pointer select-none last:rounded-b-md px-3 py-2",
                        active ? "bg-gray-200/70" : "",
                      )
                    }
                  >
                    {({ active }) => (
                      <div className="">
                        <div className="flex items-center gap-x-2">
                          <TaskStatusIcon status={status} />
                          <p className=" font-bold leading-tight  text-sm text-left">
                            {getTaskStatusText(status)}
                          </p>
                        </div>
                      </div>
                    )}
                  </Combobox.Option>
                );
              })}
            </Combobox.Options>
          </div>
        </div>
      </Combobox>
    </div>
  );
};

function DueDatePill(props: {
  dueDate?: number;
  onClick?: () => void;
  onRemove?: () => void;
}) {
  if (!props.dueDate) {
    return (
      <div className="relative cursor-pointer border bg-white border-gray-200 flex items-center rounded-2xl px-2 py-1">
        <CalendarDaysIcon className="w-5 h-5 text-gray-400" />
        <p className="ml-1 text-sm text-gray-500">No due date</p>
      </div>
    );
  }

  const dd = fromUnixTime(props.dueDate);

  if (isBefore(dd, new Date())) {
    return (
      <div className="group relative cursor-pointer border bg-white border-orange-200 flex items-center rounded-2xl px-2 py-1">
        <CalendarDaysIcon className="w-5 h-5 text-orange-400" />
        <p className="ml-1 text-sm text-orange-600">
          Due {format(dd, "dd MMM")}
        </p>
        {props.onRemove ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (props.onRemove) props.onRemove();
            }}
            className="hidden group-hover:block absolute -top-1.5 -right-2.5 group"
          >
            <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
          </button>
        ) : null}
      </div>
    );
  }

  return (
    <div className="group relative cursor-pointer border bg-white border-gray-200 flex items-center rounded-2xl px-2 py-1">
      <CalendarDaysIcon className="w-5 h-5 text-gray-400" />
      <p className="ml-1 text-sm text-gray-500">Due {format(dd, "dd MMM")}</p>
      {props.onRemove ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (props.onRemove) props.onRemove();
          }}
          className="hidden group-hover:block absolute -top-1.5 -right-2.5 group"
        >
          <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
        </button>
      ) : null}
    </div>
  );
}
