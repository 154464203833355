import {
  fileWrapper,
  folderWrapper,
} from "../components/data_room/file_uploaders/NewFilesUploader";
import { BlobReader, BlobWriter, ZipReader } from "@zip.js/zip.js";
import { fileTypeFromBlob } from "file-type";

interface ZipEntry {
  filename: string;
  directory: boolean;
  getData: (writer: BlobWriter, options?: any) => Promise<Blob>;
}

// Reads a zip file (File object) and returns its entries using zip.js.
// Start of Selection
function getZipEntries(file: File): Promise<ZipEntry[]> {
  console.log("getZipEntries", file);
  const zipReader = new ZipReader(new BlobReader(file));
  return zipReader.getEntries().then((entries) =>
    entries.map((entry) => ({
      filename: entry.filename,
      directory: entry.directory,
      getData: entry.getData!,
    }))
  );
}

// Build a simple tree from the extracted ZipEntry objects.
// For example, if an entry has a filename "folderA/subfolder/file.txt",
// the tree will have a node for "folderA" (with a child node for "subfolder")
// and "file.txt" is stored in the files array for "subfolder".
interface TreeNode {
  name: string;
  folders: { [key: string]: TreeNode };
  files: ZipEntry[];
}

function buildZipTree(entries: ZipEntry[]): TreeNode {
  console.log("buildZipTree", entries);
  const root: TreeNode = { name: "", folders: {}, files: [] };
  entries.forEach((entry) => {
    // Split the entry filename into parts and remove any empty entries.
    const parts = entry.filename.split("/").filter((p) => p);
    let current = root;
    parts.forEach((part, index) => {
      const isLast = index === parts.length - 1;
      if (isLast) {
        if (entry.directory) {
          // Ensure the folder exists.
          if (!current.folders[part]) {
            current.folders[part] = { name: part, folders: {}, files: [] };
          }
        } else {
          current.files.push(entry);
        }
      } else {
        // For each intermediate part, create a new folder if needed.
        if (!current.folders[part]) {
          current.folders[part] = { name: part, folders: {}, files: [] };
        }
        current = current.folders[part];
      }
    });
  });
  return root;
}

// Converts a single ZipEntry (a file) to a fileWrapper by extracting its data.
async function convertZipEntryToFileWrapper(
  entry: ZipEntry,
  parentPermissionId?: string,
  parentPermission?: any
): Promise<fileWrapper> {
  const writer = new BlobWriter();
  const blob = await entry.getData(writer);
  const fileType = await fileTypeFromBlob(blob);
  const mimeType = fileType?.mime || "application/octet-stream";
  const fileName = entry.filename.split("/").pop() || "unknown";
  // Create a File object from the Blob.
  const extractedFile = new File([blob], fileName, { type: mimeType });
  const fileWrap: fileWrapper = {
    id: Math.random(),
    file: extractedFile,
    name: extractedFile.name,
    dataRoomPermissionId: parentPermissionId || "",
    uploadProgress: 0,
    status: "pending",
    selectedPermission: parentPermission,
  };
  return fileWrap;
}

// Recursively converts a TreeNode into a folderWrapper.
// Each folderWrapper contains an array of nested folders (children)
// and an array of fileWrapper objects.
async function convertTreeNodeToFolderWrapper(
  node: TreeNode,
  parentPermissionId?: string,
  parentPermission?: any
): Promise<folderWrapper> {
  const children: folderWrapper[] = [];
  for (const key in node.folders) {
    if (node.folders.hasOwnProperty(key)) {
      const childFolder = await convertTreeNodeToFolderWrapper(
        node.folders[key],
        parentPermissionId,
        parentPermission
      );
      children.push(childFolder);
    }
  }

  // Convert files directly under this node.
  const filesPromises = node.files.map((entry) =>
    convertZipEntryToFileWrapper(entry, parentPermissionId, parentPermission)
  );
  const files = await Promise.all(filesPromises);

  const folder: folderWrapper = {
    id: Math.random(),
    // Use node.name; for the root node, we later override the name at the top level.
    name: node.name || "Root",
    children: children,
    files: files,
    uploadProgress: 0,
    status: "pending",
    dataRoomPermissionId: parentPermissionId,
    selectedPermission: parentPermission,
  };
  return folder;
}

// Main function: converts a zip file (as fileWrapper) into a folderWrapper
// that represents the unzipped content.
export async function convertZipFileToFolderWrapper(
  zipFileWrap: fileWrapper
): Promise<[folderWrapper[], fileWrapper[]]> {
  // Use zip.js to get all entries from the zip file.
  const entries = await getZipEntries(zipFileWrap.file);
  const tree = buildZipTree(entries as ZipEntry[]);

  // Process any folders that exist at the root level of the zip.
  const rootFolders: folderWrapper[] = [];
  for (const key in tree.folders) {
    if (tree.folders.hasOwnProperty(key)) {
      const childFolder = await convertTreeNodeToFolderWrapper(
        tree.folders[key],
        zipFileWrap.dataRoomPermissionId,
        zipFileWrap.selectedPermission
      );
      rootFolders.push(childFolder);
    }
  }

  // Process any files in the root level of the zip.
  const rootFiles = await Promise.all(
    tree.files.map((entry) =>
      convertZipEntryToFileWrapper(
        entry,
        zipFileWrap.dataRoomPermissionId,
        zipFileWrap.selectedPermission
      )
    )
  );

  return Promise.resolve([rootFolders, rootFiles]);
}
