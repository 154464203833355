import { AnimatedModal } from "@/src/components/AnimatedModal";
import { Card } from "@/src/components/Card";
import { CloseIcon } from "@/src/components/CloseIcon";
import Loading from "@/src/components/Loading";
import { Button } from "@/src/components/tailwind/Button";
import { TextInput } from "@/src/components/tailwind/TextInput";
import { toasts } from "@/src/components/toasts/toasts";
import { TripleDotMenu } from "@/src/components/TripleDotMenu";
import {
  DealPropertyQuery,
  useDealPropertyQuery,
  useUpdateDealPropertyValueMutation,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { classNames } from "@/src/utils/cn";
import { Menu } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { ArrowLeftIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

export function DealProperty() {
  const { id } = useParams<{ id: string }>();
  return (
    <div>
      <div className="w-full flex items-center shadow-sm p-3 px-4 bg-gray-100 border-b border-gray-300">
        <div>
          <p className="font-semibold text-gray-800">Custom deal properties</p>
          <p className="text-sm text-gray-500">
            Custom deal properties are used to track deal information that is
            not already tracked by Liquid or is specific to your firm.
          </p>
        </div>
      </div>

      <div className="w-full xl:w-2/3 p-4">
        <DealPropertyContent id={id} />
      </div>
    </div>
  );
}

function DealPropertyContent(props: { id: string }) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const dealPropertyQuery = useDealPropertyQuery(client, {
    id: props.id,
  });

  const updateDealPropertyValue = useUpdateDealPropertyValueMutation(client);

  const [dealPropertyValueEditId, setDealPropertyValueEditId] = useState<
    string | null
  >(null);

  const history = useHistory();

  if (dealPropertyQuery.error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-gray-500">Error loading deal property</p>
      </div>
    );
  }

  if (dealPropertyQuery.isLoading || !dealPropertyQuery.data) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div>
        <button
          onClick={() => {
            history.goBack();
          }}
          className="flex text-sm font-semibold items-center gap-x-2 text-gray-500/80 hover:text-gray-700"
        >
          <ArrowLeftIcon className="w-4 h-4" />
          Back
        </button>
        <p className="font-semibold text-gray-800">
          {dealPropertyQuery.data.dealProperty.propertyKey}
        </p>
        <p className="text-sm text-gray-500">
          Created by {dealPropertyQuery.data.dealProperty.createdBy.name}{" "}
          {formatDistanceToNowStrict(
            fromUnixTime(dealPropertyQuery.data.dealProperty.createdAt)
          )}{" "}
          ago
        </p>
      </div>
      <div className="mt-4">
        <Card>
          <table className="min-w-full">
            <thead>
              <tr>
                <TableHead className="w-[50%]">Deal</TableHead>
                <TableHead className="w-[50%]">Value</TableHead>
                <th className="py-1 px-2 w-auto text-gray-500 hidden xl:table-cell"></th>
              </tr>
            </thead>
            <tbody>
              {dealPropertyQuery.data.dealProperty.dealPropertyValues.map(
                (value) => (
                  <tr key={value.id} className="group">
                    <TableCell className="font-semibold">
                      {value.deal.company.name}
                    </TableCell>
                    <TableCell>
                      {value.propertyValue ? value.propertyValue : "-"}
                    </TableCell>
                    <td className="py-2 px-3 hidden xl:table-cell">
                      <TripleDotMenu mode="table">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setDealPropertyValueEditId(value.id);
                              }}
                              className={classNames(
                                active ? "bg-gray-50 text-blue-700" : "",
                                "block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer w-full text-left"
                              )}
                            >
                              <p>Edit</p>
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                updateDealPropertyValue.mutate(
                                  {
                                    input: {
                                      id: value.id,
                                      delete: true,
                                      propertyValue: value.propertyValue,
                                    },
                                  },
                                  {
                                    onSuccess: () => {
                                      queryClient
                                        .invalidateQueries({
                                          queryKey: [
                                            "DealProperty",
                                            { id: props.id },
                                          ],
                                        })
                                        .then(() => {
                                          toasts.success(
                                            "Deal property value removed"
                                          );
                                        });
                                    },
                                  }
                                );
                              }}
                              className={classNames(
                                active ? "bg-gray-50 text-red-700" : "",
                                "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer w-full text-left"
                              )}
                            >
                              <p>Remove from deal</p>
                            </button>
                          )}
                        </Menu.Item>
                      </TripleDotMenu>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </Card>
      </div>
      <EditDealPropertyValueModal
        id={props.id}
        open={dealPropertyValueEditId !== null}
        onClose={() => {
          setDealPropertyValueEditId(null);
        }}
        value={dealPropertyQuery.data.dealProperty.dealPropertyValues.find(
          (value) => value.id === dealPropertyValueEditId
        )}
        property={dealPropertyQuery.data.dealProperty}
      />
    </div>
  );
}

function EditDealPropertyValueModal(props: {
  id: string;
  open: boolean;
  onClose: () => void;
  value?: DealPropertyQuery["dealProperty"]["dealPropertyValues"][0];
  property: DealPropertyQuery["dealProperty"];
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const updateDealPropertyValue = useUpdateDealPropertyValueMutation(client);

  const [propertyValue, setPropertyValue] = useState(
    props.value?.propertyValue ?? ""
  );

  useEffect(() => {
    setPropertyValue(props.value?.propertyValue ?? "");
  }, [props.value]);

  if (!props.value) {
    return null;
  }

  return (
    <AnimatedModal
      size="md"
      open={props.open}
      onClose={() => {
        setPropertyValue("");
        props.onClose();
      }}
    >
      <div>
        <div className="flex justify-between items-center">
          <p className="font-semibold text-gray-800">
            Edit{" "}
            <span className="text-gray-500/80">
              {props.property.propertyKey}
            </span>{" "}
            for{" "}
            <span className="text-gray-500/80 ">
              {props.value.deal.company.name}
            </span>
          </p>

          <CloseIcon onClose={props.onClose} />
        </div>

        <form
          className="mt-3"
          onSubmit={(e) => {
            e.preventDefault();
            if (!props.value) {
              return;
            }
            updateDealPropertyValue.mutate(
              {
                input: {
                  id: props.value.id,
                  propertyValue: propertyValue,
                },
              },
              {
                onSuccess: () => {
                  props.onClose();
                  queryClient
                    .invalidateQueries({
                      queryKey: ["DealProperty", { id: props.id }],
                    })
                    .then(() => {
                      toasts.success("Deal property value updated");
                    });
                  setPropertyValue("");
                },
              }
            );
          }}
        >
          <TextInput
            placeholder="Enter value..."
            value={propertyValue}
            onChange={(e) => {
              setPropertyValue(e.target.value);
            }}
          />
          <div className="mt-6 flex justify-end items-center gap-x-2">
            <Button
              text="Cancel"
              type="button"
              variant="neutral"
              onClick={props.onClose}
            />
            <Button
              text="Save"
              type="submit"
              variant="positive"
              loadingText="Saving..."
              isLoading={updateDealPropertyValue.isPending}
            />
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}

function TableHead(props: { children: React.ReactNode; className?: string }) {
  return (
    <th
      className={`text-gray-500 font-semibold px-3 pt-2 pb-1 text-left text-sm ${props.className}`}
    >
      {props.children}
    </th>
  );
}

function TableCell(props: { children: React.ReactNode; className?: string }) {
  return (
    <td
      className={`py-3 px-3 xl:table-cell truncate text-sm text-left ${props.className}`}
    >
      {props.children}
    </td>
  );
}
