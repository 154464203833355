import { Link } from "@/src/components/nav/index";
import { TabConfig } from "@/src/components/nav/types";
import { DealRoleRestricted } from "@/src/components/DealRoleRestricted";
import { useCallback } from "react";

interface TopTabNavbarProps {
  tabs: TabConfig[];
}

const TopTabNavbar = ({ tabs }: TopTabNavbarProps) => {
  const renderItem = useCallback(
    ({ roles, label, icon, to, ...config }: TabConfig) => {
      return (
        <Link margin="0 0 xs 0" to={to} {...config}>
          <div className="flex items-center space-x-2">
            {icon}
            <span className="text-sm font-semibold">{label}</span>
          </div>
        </Link>
      );
    },
    [],
  );

  return (
    <nav className="transition-colors duration-300 ease-in-out bg-gray-200 gap-x-2 overflow-x-scroll no-scrollbar">
      <ul className={"flex flex-row gap-x-2"}>
        {tabs.map((tabConfig) => (
          <li key={tabConfig.to}>
            {tabConfig.roles ? (
              <DealRoleRestricted roles={tabConfig.roles}>
                {renderItem(tabConfig)}
              </DealRoleRestricted>
            ) : (
              renderItem(tabConfig)
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
export default TopTabNavbar;
