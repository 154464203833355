// Used for viewing pdfs, docx and pptx files
import {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import PSPDFKit, { Instance, ToolbarItem, Rect } from "@nutrient-sdk/viewer";
import { pspdfkitEndpoint } from "../../utils/endpoints";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useDataRoomFileVersionQuery } from "@/src/graphql/generated";
import Loading from "../Loading";
import { Button } from "../tailwind/Button";
import { useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";
import { v4 as uuidv4 } from "uuid";
export interface PdfViewerHandle {
  setPageIndex: (pageIndex: number) => void;
  getCurrentPage: () => number;
  jumpToRect: (pageIndex: number, rect: Rect) => void;
  deleteAnnotation: (id: string) => void;
  addTemporaryAnnotation: (
    pageIndex: number,
    rects: Rect[],
    color?: "orange" | "yellow" | "green" | "blue" | "purple" | "red",
  ) => void;
}

const colors = {
  orange: PSPDFKit.Color.ORANGE,
  yellow: PSPDFKit.Color.LIGHT_YELLOW,
  green: PSPDFKit.Color.LIGHT_GREEN,
  blue: PSPDFKit.Color.LIGHT_BLUE,
  purple: PSPDFKit.Color.PURPLE,
  red: PSPDFKit.Color.RED,
};
type DirectRenderProps = {
  mode: "direct";
  documentId: string;
  token: string;
};

type DataRoomProps = {
  mode: "dataRoom";
  dataRoomFileVersionId: string;
};

type CommonProps = {
  highlights?: string[];
  pageIndex?: number;
  rectsOnPage?: number[][];
  rectsOnPages?: { pageIndex: number; rects: number[][]; id?: string }[];
  showToolbar?: boolean;
  search?: string;
  width?: number | string;
  height?: number | string;
  heightOffset?: number;
  onAnnotationCreated?: (annotation: {
    id: string;
    text: string;
    pageIndex: number;
    rects: number[][];
  }) => void;
};

type PspdfkitProps = CommonProps & (DirectRenderProps | DataRoomProps);

export const Pspdfkit = forwardRef<PdfViewerHandle, PspdfkitProps>(
  function Pspdfkit(props, ref) {
    if (props.mode === "dataRoom") {
      return <DataRoomFileRenderer {...props} ref={ref} />;
    }

    return <PspdfkitRenderer {...props} ref={ref} />;
  },
);

type PspdfkitRendererProps = CommonProps & DirectRenderProps;

const PspdfkitRenderer = forwardRef<PdfViewerHandle, PspdfkitRendererProps>(
  function PspdfkitRenderer(props, ref) {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [instance, setInstance] = useState<Instance | null>(null);
    const account = useSelector(authSelectors.account);

    useImperativeHandle(
      ref,
      () => ({
        setPageIndex: (pageIndex: number) => {
          if (instance && pageIndex < instance.totalPageCount) {
            instance.setViewState(
              instance.viewState.set("currentPageIndex", pageIndex),
            );
          }
        },

        getCurrentPage: () => {
          return instance ? instance.viewState.currentPageIndex : 0;
        },
        jumpToRect(pageIndex, rect) {
          if (instance) {
            instance.jumpToRect(pageIndex, rect);
          }
        },
        deleteAnnotation(id) {
          if (instance) {
            instance.delete([id]);
          }
        },
        addTemporaryAnnotation(pageIndex, rects, color) {
          if (instance) {
            const rcts = PSPDFKit.Immutable.List(rects);

            const a = new PSPDFKit.Annotations.HighlightAnnotation({
              pageIndex: pageIndex || 0,
              rects: rcts,
              boundingBox: PSPDFKit.Geometry.Rect.union(rcts),
              color: color ? colors[color] : PSPDFKit.Color.LIGHT_YELLOW,
              id: uuidv4(),
            });

            try {
              instance.create(a);
              setTimeout(() => {
                instance.delete([a.id]);
              }, 2000);
            } catch (e) {
              console.error("Error creating annotation", e);
            }
          }
        },
      }),
      [instance],
    );

    useEffect(() => {
      const container = containerRef.current; // This `useRef` instance will render the PDF.
      if (!container) {
        return;
      }

      const toolbarItems: ToolbarItem[] = [
        {
          type: "sidebar-thumbnails",
        },
        {
          type: "pager",
        },
        {
          type: "spacer",
        },
        {
          type: "zoom-in",
        },
        {
          type: "zoom-out",
        },
        {
          type: "search",
        },
      ];

      (async function () {
        PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

        const i = await PSPDFKit.load({
          // Container where PSPDFKit should be mounted.
          container,
          autoSaveMode: PSPDFKit.AutoSaveMode.DISABLED,
          // The document to open.
          documentId: props.documentId,
          authPayload: {
            jwt: props.token,
          },
          instant: false,
          serverUrl: pspdfkitEndpoint(),
          baseUrl: `${window.location.protocol}//${window.location.host}/${import.meta.env.BASE_URL}`,
          toolbarItems: toolbarItems,
          // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        });

        i.setAnnotationCreatorName(account ? account.name : "");
        i.setIsEditableAnnotation((annotation) => {
          return false;
        });

        i.setInlineTextSelectionToolbarItems(
          ({ defaultItems, hasDesktopLayout }, selection) => {
            return [
              {
                type: "custom",
                id: "add-as-reference",
                title: "Add as reference",
                onPress: (a) => {
                  const textSelection = i.getTextSelection();
                  if (!textSelection) {
                    return;
                  }

                  textSelection
                    .getSelectedRectsPerPage()
                    .then((rectsPerPage: any) => {
                      rectsPerPage.map(({ pageIndex, rects }: any) => {
                        // We need to create one annotation per page.
                        const id = `ref_${uuidv4()}`;
                        const annotation =
                          new PSPDFKit.Annotations.HighlightAnnotation({
                            pageIndex,
                            boundingBox: PSPDFKit.Geometry.Rect.union(rects),
                            rects,
                            color: PSPDFKit.Color.LIGHT_YELLOW,
                            id,
                            customData: {
                              type: "reference",
                              accountId: account?.id,
                            },
                          });

                        try {
                          i.create(annotation);

                          i.getTextFromRects(pageIndex, rects).then((text) => {
                            if (props.onAnnotationCreated) {
                              props.onAnnotationCreated({
                                id,
                                text: text,
                                pageIndex: pageIndex,
                                rects: rects.map((r: Rect) => {
                                  return [r.left, r.top, r.width, r.height];
                                }),
                              });
                            }
                          });
                        } catch (e) {
                          console.error("Error creating annotation", e);
                        }
                      });
                    });
                },
              },
            ];
          },
        );

        setInstance(i);

        if (props.rectsOnPage && props.rectsOnPage.length > 0 && i) {
          const rects = props.rectsOnPage.map((rect) => {
            return new PSPDFKit.Geometry.Rect({
              left: rect[0],
              top: rect[1],
              width: rect[2],
              height: rect[3],
            });
          });

          const rcts = PSPDFKit.Immutable.List(rects);

          const a = new PSPDFKit.Annotations.HighlightAnnotation({
            pageIndex: props.pageIndex || 0,
            rects: rcts,
            boundingBox: PSPDFKit.Geometry.Rect.union(rcts),
            color: PSPDFKit.Color.LIGHT_YELLOW,
          });

          try {
            i.create(a);
          } catch (e) {
            console.error("Error creating annotation", e);
          }
        }

        if (props.showToolbar === false) {
          i.setViewState(i.viewState.set("showToolbar", props.showToolbar));
        }

        if (props.pageIndex) {
          if (
            !Number.isNaN(props.pageIndex) &&
            props.pageIndex < i.totalPageCount
          ) {
            i.setViewState(
              i.viewState.set("currentPageIndex", props.pageIndex),
            );
          }
        }
      })();

      return () => {
        PSPDFKit.unload(container);
      };
    }, [props.documentId]);

    useEffect(() => {
      async function addRectsOnPages() {
        if (!instance || !props.rectsOnPages || !props.documentId) {
          return;
        }

        for (const {
          pageIndex,
          rects: rectsOnPage,
          id,
        } of props.rectsOnPages) {
          const existingAnnotations = await instance.getAnnotations(pageIndex);

          const hasExisting =
            existingAnnotations.filter((h) => h.id === id).size > 0;

          if (hasExisting) {
            console.log("Skipping existing annotation", id);
            continue;
          }

          const rects = rectsOnPage.map((r) => {
            return new PSPDFKit.Geometry.Rect({
              left: r[0],
              top: r[1],
              width: r[2],
              height: r[3],
            });
          });
          const rcts = PSPDFKit.Immutable.List(rects);
          const a = new PSPDFKit.Annotations.HighlightAnnotation({
            pageIndex,
            rects: rcts,
            id,
            boundingBox: PSPDFKit.Geometry.Rect.union(rcts),
            color: PSPDFKit.Color.LIGHT_YELLOW,
            isEditable: false,
            isDeletable: false,
          });

          try {
            instance.create(a);
          } catch (e) {}
        }
      }

      addRectsOnPages();
    }, [props.rectsOnPages, instance, props.documentId]);

    return (
      <div
        style={{
          width: props.width || 600,
          height:
            props.height || `calc(100vh - ${props.heightOffset || 200}px)`,
        }}
      >
        <div ref={containerRef} style={{ width: "100%", height: "100%" }} />
      </div>
    );
  },
);

type DataRoomFileRendererProps = CommonProps & DataRoomProps;

const DataRoomFileRenderer = forwardRef<
  PdfViewerHandle,
  DataRoomFileRendererProps
>(function DataRoomFileRenderer(props, ref) {
  const client = useGqlClient();

  const dataRoomFileVersionQuery = useDataRoomFileVersionQuery(client, {
    id: props.dataRoomFileVersionId,
  });

  if (dataRoomFileVersionQuery.error) {
    return (
      <div>
        <p className="text-sm font-semibold">
          Error loading file. Please try again.
        </p>
        <Button
          text="Try again"
          size="s"
          variant="neutral"
          onClick={() => {
            dataRoomFileVersionQuery.refetch();
          }}
        />
      </div>
    );
  }

  if (dataRoomFileVersionQuery.isLoading || !dataRoomFileVersionQuery.data) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const { dataRoomFileVersion } = dataRoomFileVersionQuery.data;

  return (
    <PspdfkitRenderer
      {...props}
      mode="direct"
      documentId={dataRoomFileVersion.pspdfkitDocumentId}
      token={dataRoomFileVersion.pspdfkitToken}
      ref={ref}
    />
  );
});
