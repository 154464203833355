import {
  AccessQuery,
  AccessType,
  DealAccessStatus,
  DealAccountFragment,
  DealGroup,
  GuestAccessTabQuery,
} from "@/src/graphql/generated";
import { classNames } from "@/src/utils/cn";
import { formatDealRole, formatEnum } from "@/src/utils/enums";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";

interface Group {
  id: string;
  name: string;
  accounts: DealAccountFragment[];
  invites: AccessQuery["deal"]["invites"];
  dealGroup?: AccessQuery["deal"]["guestGroups"][0];
}

export function AccessList(props: {
  dealId: string;
  group: GuestAccessTabQuery["deal"]["activeDealAccount"]["dealGroup"];
  accountSearch: string;
}) {
  const [groups, setGroups] = useState<Group[]>([]);
  useEffect(() => {
    const initialGroups: Group = {
      id: props.group?.id ?? "",
      name: props.group?.name ?? "",
      accounts: props.group?.accounts ?? [],
      dealGroup: props.group as DealGroup,
      invites: props.group?.invites ?? [],
    };

    setGroups([initialGroups]);
  }, [props.group]);

  const account = useSelector(authSelectors.account);

  return (
    <div>
      <div className="px-2 lg:px-0 rounded-md">
        <div className="mt-6 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block ring-1 ring-black/5 min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className=" shadow ring-1 ring-black/5 sm:rounded-lg">
                {groups.flatMap((g) => g.accounts).length === 0 ? (
                  <div className="bg-white text-center text-sm text-gray-500 p-4">
                    No guests have joined this deal yet.
                  </div>
                ) : (
                  <table className="min-w-full rounded-lg border-separate border-spacing-0">
                    <thead className=" rounded-lg">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Email
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Last seen
                        </th>
                      </tr>
                    </thead>
                    <tbody className="rounded-b-lg">
                      {groups
                        .filter((g) =>
                          g.id === "no-group" && g.accounts.length === 0
                            ? false
                            : true,
                        )
                        .filter((g) =>
                          props.accountSearch.length < 2
                            ? true
                            : g.accounts.some(
                                (a) =>
                                  a.account.email
                                    .toLowerCase()
                                    .includes(
                                      props.accountSearch.toLowerCase(),
                                    ) ||
                                  a.account.name
                                    .toLowerCase()
                                    .includes(
                                      props.accountSearch.toLowerCase(),
                                    ),
                              ),
                        )
                        .map((group) => (
                          <Fragment key={group.id}>
                            <tr className="border-y bg-white border-gray-500">
                              <th
                                scope="colgroup"
                                colSpan={5}
                                className="border-y border-gray-300 bg-gray-50 py-2 pl-4  pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                              >
                                <div className="flex items-center gap-x-2">
                                  <p>{group.name}</p>
                                </div>
                              </th>

                              <th
                                scope="col"
                                className="bg-gray-50 pr-4 sm:pr-3 border-y border-gray-300"
                              ></th>
                            </tr>
                            {group.accounts.length === 0 ? (
                              <tr className="[&_td]:border-transparent">
                                <td
                                  colSpan={6}
                                  className="bg-white py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-3"
                                >
                                  No guests have joined this group yet.
                                </td>
                              </tr>
                            ) : null}
                            {group.accounts
                              .filter((a) =>
                                props.accountSearch.length < 2
                                  ? true
                                  : a.account.email
                                      .toLowerCase()
                                      .includes(
                                        props.accountSearch.toLowerCase(),
                                      ) ||
                                    a.account.name
                                      .toLowerCase()
                                      .includes(
                                        props.accountSearch.toLowerCase(),
                                      ),
                              )
                              .map((person, personIdx) => (
                                <tr
                                  key={person.account.email}
                                  className={classNames(
                                    personIdx === 0
                                      ? "border-gray-300"
                                      : "border-gray-200",
                                    "border-t rounded-b-lg bg-white rounded-lg hover:bg-gray-50 ",
                                  )}
                                >
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                    {person.account.name}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {formatDealRole(person.role)}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {person.account.email}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {formatDistanceToNow(
                                      fromUnixTime(person.lastSeen),
                                      { addSuffix: true },
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </Fragment>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
              {props.group && props.group.invites.length > 0 && (
                <InviteTable groups={groups} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function InviteTable(props: { groups: Group[] }) {
  const groups = props.groups.filter((g) => g.invites.length > 0);

  return (
    <div className="mt-8">
      <p className="text-sm text-gray-700 font-semibold mt-4">Invites</p>

      <div className="mt-2 overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
        <table className="min-w-full rounded-lg">
          <thead className="bg-white">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Role
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Invite sent
              </th>

              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {groups
              .filter((g) => g.invites.length > 0)
              .map((group) => (
                <Fragment key={group.id}>
                  <tr className="border-t border-gray-200">
                    <th
                      scope="colgroup"
                      colSpan={5}
                      className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                    >
                      {group.name}
                    </th>
                  </tr>
                  {group.invites.map((invite, inviteIdx) => (
                    <tr
                      key={invite.email}
                      className={classNames(
                        inviteIdx === 0 ? "border-gray-300" : "border-gray-200",
                        "border-t",
                      )}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                        {invite.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {formatDealRole(invite.role)}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {formatEnum(invite.status)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {formatDistanceToNow(fromUnixTime(invite.createdAt), {
                          addSuffix: true,
                        })}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                        <button className="text-indigo-600 hover:text-indigo-900">
                          Edit
                          <span className="sr-only">, {invite.email}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function formatDataRoomAccess(
  overrideDataRoomAccess: boolean,
  granularAccess?:
    | AccessQuery["deal"]["otherGuests"][0]["granularAccess"]
    | null,
  permission?: AccessQuery["deal"]["invites"][0]["dataRoomPermission"] | null,
  group?: AccessQuery["deal"]["guestGroups"][0] | null,
  dealAccessStatus?: DealAccessStatus,
) {
  if (dealAccessStatus && dealAccessStatus === DealAccessStatus.Blocked) {
    return "Blocked";
  }

  if (
    dealAccessStatus &&
    dealAccessStatus === DealAccessStatus.PendingManualApproval
  ) {
    return "Pending manual approval";
  }

  if (overrideDataRoomAccess && granularAccess) {
    return `Restricted to ${
      granularAccess.files.filter((f) => f.type !== AccessType.NoAccess).length
    } ${
      granularAccess.files.filter((f) => f.type !== AccessType.NoAccess)
        .length === 1
        ? "file"
        : "files"
    }`;
  }

  if (group && group.dataRoomPermission) {
    return `${group.dataRoomPermission.name} (${group.dataRoomPermission.fileCount} files)`;
  }

  return permission
    ? `${permission.name} (${permission.fileCount} files)`
    : "No access";
}
