import { FileType } from "../graphql/generated";
import { ExternalLinkIcon } from "lucide-react";
import { FileIcon } from "./FileIcon";
import { PanelRightCloseIcon } from "lucide-react";
import { DataRoomFile } from "../graphql/generated";
import { Pspdfkit } from "./document_viewers/Pspdfkit";
import { SlideOver } from "./SlideOver";
import { Button } from "./tailwind/Button";
import useGqlClient from "../hooks/useGqlClient";
import { useDataRoomFileQuery } from "../graphql/generated";
import Loading from "./Loading";

export interface Reference {
  file: DataRoomFile;
  fileVersionId: string;
  pageIndex: number;
  quote: string;
  rectsOnPage: Array<Array<number>>;
}

export function ReferenceSlideOver(props: {
  reference?: Reference;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <SlideOver open={props.open} onClose={props.onClose} maxWidth="max-w-3xl">
      <div>
        <div className="border-b border-gray-200 shadow-sm p-4 flex justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <PanelRightCloseIcon
              onClick={() => props.onClose()}
              className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
            />
            <FileIcon
              fileType={props.reference?.file.fileType ?? FileType.Other}
            />
            <p className="text-sm text-gray-800 font-semibold">
              {props.reference?.file.name}
            </p>
          </div>
          <div>
            <Button
              variant="neutral"
              size="s"
              text="Go to document"
              icon={ExternalLinkIcon}
            />
          </div>
        </div>
        <div className="h-full flex flex-col ">
          {props.reference ? (
            <ReferenceFileView reference={props.reference} />
          ) : null}
        </div>
      </div>
    </SlideOver>
  );
}

function ReferenceFileView(props: { reference: Reference }) {
  const client = useGqlClient();
  const dataRoomFile = useDataRoomFileQuery(client, {
    id: props.reference.file.id,
  });

  if (dataRoomFile.isLoading || !dataRoomFile.data) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (dataRoomFile.error) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <p className="text-sm text-gray-500/80">
          Error loading file. Please try again.
        </p>
      </div>
    );
  }

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <Pspdfkit
        mode="dataRoom"
        dataRoomFileVersionId={props.reference.fileVersionId}
        height={"100%"}
        width={"100%"}
        pageIndex={props.reference.pageIndex}
        rectsOnPage={
          props.reference.rectsOnPage.length > 0
            ? props.reference.rectsOnPage
            : undefined
        }
      />
    </div>
  );
}
