import { Dialog } from "@headlessui/react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useGqlClient from "../../hooks/useGqlClient";
import {
  useMoveDataRoomFileMutation,
  useMoveDataRoomFolderMutation,
  useDataRoomFileQuery,
  useDataRoomFolderQuery,
  FileType
} from "../../graphql/generated";
import { AnimatedModal } from "../../components/AnimatedModal";
import { useInvalidateQueryKeys } from "../../hooks/useInvalidateQueryKeys";
import { ArrowRightCircleIcon, FolderIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../utils/cn";
import { AddFolderPill } from "../../components/AddFolderPill";
import { CloseIcon } from "../../components/CloseIcon";
import { toasts } from "../../components/toasts/toasts";
import { FilePill } from "../../components/FilePill";

interface MoveItemsProps {
  onClose: () => void;
  onMove?: () => void;
  open: boolean;
  dataRoomFileIds: string[];
  dataRoomFolderIds: string[];
  targetFolderId?: string;
  targetFolderName?: string;
  resetSelections?: () => void;
}

export function MoveItemsModal(props: MoveItemsProps) {
  return (
    <AnimatedModal open={props.open} onClose={props.onClose} size="xl">
      <MoveItemsContent
        dataRoomFileIds={props.dataRoomFileIds}
        dataRoomFolderIds={props.dataRoomFolderIds}
        targetFolderId={props.targetFolderId}
        targetFolderName={props.targetFolderName}
        onCancel={() => props.onClose()}
        onMove={() => {
          if (props.onMove) {
            props.onMove();
          }
          
          if (props.resetSelections) {
            props.resetSelections();
          }

          props.onClose();
        }}
      />
    </AnimatedModal>
  );
}

interface MoveItemsContentProps {
  dataRoomFileIds: string[];
  dataRoomFolderIds: string[];
  targetFolderId?: string;
  targetFolderName?: string;
  onCancel: () => void;
  onMove: () => void;
}

interface SelectedFolder {
  id: string;
  name: string;
}

function MoveItemsContent(props: MoveItemsContentProps) {
  const client = useGqlClient();

  const history = useHistory();

  const moveDataRoomFile = useMoveDataRoomFileMutation(client);
  const moveDataRoomFolder = useMoveDataRoomFolderMutation(client);
  const {
    invalidateDealActivities,
    invalidateDataRoomFolders,
    invalidateDataRooms,
  } = useInvalidateQueryKeys();

  const [selectedFolder, setSelectedFolder] = useState<SelectedFolder | null>(
    null,
  );

  useEffect(() => {
    if (props.targetFolderId && props.targetFolderName) {
      setSelectedFolder({
        id: props.targetFolderId,
        name: props.targetFolderName
      });
    } 
  }, [props.targetFolderId, props.targetFolderName]);

  const totalItems =
    props.dataRoomFileIds.length + props.dataRoomFolderIds.length;

  return (
    <div>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
          <ArrowRightCircleIcon
            className="h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
        </div>
        <div className="w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {`Move ${totalItems} items`}
          </Dialog.Title>

          <div className="mt-3 w-full">
            <div className="flex flex-wrap items-center gap-2">
              <span className="text-sm font-semibold text-gray-500">Moving:</span>
              
              {props.dataRoomFileIds.map(fileId => (
                <FileItemToMove key={fileId} fileId={fileId} />
              ))}
              {props.dataRoomFolderIds.map(folderId => (
                <FolderItemToMove key={folderId} folderId={folderId} />
              ))}
              
              <span className="text-sm font-semibold text-gray-500 ml-1">to</span>
              
              {selectedFolder ? (
                <FolderPill
                  selectedFolder={selectedFolder}
                  onClose={() => {
                    setSelectedFolder(null);
                  }}
                />
              ) : (
                <AddFolderPill
                  onFolderSelected={(folder) => {
                    setSelectedFolder({
                      id: folder.id,
                      name: folder.name,
                    });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 sm:mt-12 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          disabled={!selectedFolder || moveDataRoomFile.isPending || moveDataRoomFolder.isPending}
          className={classNames(
            "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
            !selectedFolder ? "opacity-70" : "",
          )}
          onClick={() => {
            if (!selectedFolder) {
              return;
            }

            props.dataRoomFileIds.forEach((id) => {
              moveDataRoomFile.mutate(
                {
                  input: {
                    id,
                    folderID: selectedFolder.id,
                  },
                },
                {
                  onError: () => {
                    toasts.error("Failed to move files"); 
                  },
                  onSuccess: () => {
                    invalidateDataRoomFolders();
                    invalidateDealActivities();
                    invalidateDataRooms();
                    toasts.success("File(s) moved successfully");
                    if (history.location.pathname.startsWith("/drive")) {
                      history.push(`/drive/folder/${selectedFolder.id}`);
                    } else {
                      history.push(
                        `/deal/documents/folder/${selectedFolder.id}`,
                      );
                    }
                    props.onMove();
                  },
                },
              );
            });
            props.dataRoomFolderIds.forEach((id) => {
              moveDataRoomFolder.mutate(
                {
                  input: {
                    folderID: id,
                    targetParentFolderID: selectedFolder.id,
                  },
                },
                {
                  onError: () => {},
                  onSuccess: () => {
                    invalidateDataRoomFolders();
                    invalidateDealActivities();
                    invalidateDataRooms();
                    toasts.success("Folder(s) moved successfully");
                    if (history.location.pathname.startsWith("/drive")) {
                        history.push(`/drive/folder/${selectedFolder.id}`);
                      } else {
                        history.push(
                          `/deal/documents/folder/${selectedFolder.id}`,
                        );
                      }
                    props.onMove();
                  },
                },
              );
            });
          }}
        >
          {moveDataRoomFile.isPending || moveDataRoomFolder.isPending ? "Moving..." : "Move"}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            props.onCancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

function FileItemToMove({ fileId }: { fileId: string }) {
  const client = useGqlClient();
  const { data, isLoading, error } = useDataRoomFileQuery(client, { id: fileId });
  
  if (isLoading) {
    return (
      <div className="animate-pulse bg-gray-200 rounded-2xl px-2 py-1 h-6 w-24"></div>
    );
  }
  
  if (error || !data) {
    return (
      <div className="text-xs text-red-500 rounded-2xl border border-red-200 bg-red-50 px-2 py-1">
        Error loading file
      </div>
    );
  }
  
  return (
    <FilePill
      id={data.dataRoomFile.id}
      name={data.dataRoomFile.name}
      type={data.dataRoomFile.fileType}
      withLink={false}
    />
  );
}

function FolderItemToMove({ folderId }: { folderId: string }) {
  const client = useGqlClient();
  const { data, isLoading, error } = useDataRoomFolderQuery(client, { id: folderId });
  
  if (isLoading) {
    return (
      <div className="animate-pulse bg-gray-200 rounded-2xl px-2 py-1 h-6 w-24"></div>
    );
  }
  
  if (error || !data) {
    return (
      <div className="text-xs text-red-500 rounded-2xl border border-red-200 bg-red-50 px-2 py-1">
        Error loading folder
      </div>
    );
  }
  
  return (
    <FolderPill
      selectedFolder={{id: data.dataRoomFolder.id, name: data.dataRoomFolder.name}}
      onClose={() => {}}
      isReadOnly={true}
    />
  );
}

function FolderPill(props: {
  selectedFolder: SelectedFolder | null;
  onClose: () => void;
  isReadOnly?: boolean;
}) {
  if (!props.selectedFolder) {
    return (
      <button className="flex flex-wrap group justify-center relative">
        <div className="cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
          <FolderIcon className="w-4 h-4 text-gray-400" />
          <p className="text-xs ml-1 mt-0.5 truncate">Select folder</p>
        </div>
      </button>
    );
  }

  return (
    <div className="flex flex-wrap group justify-center relative">
      <div className="gap-x-1 cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
        <FolderIcon className="w-4 h-4 text-blue-700/70" />
        <p className="text-xs mt-0.5 truncate">{props.selectedFolder.name}</p>
        {!props.isReadOnly && (
          <CloseIcon
            onClose={() => {
              props.onClose();
            }}
          />
        )}
      </div>
    </div>
  );
}
