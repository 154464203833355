import { useState } from "react";
import { Button } from "@/src/components/tailwind/Button";
import { H3 } from "@/src/components/Heading";
import useGqlClient from "@/src/hooks/useGqlClient";
import {
  DealGroup,
  DealRole,
  useCreateDealInviteMutation,
} from "@/src/graphql/generated";
import { toasts } from "@/src/components/toasts/toasts";
import { AnimatedModal } from "@/src/components/AnimatedModal";
import {
  GroupAccountInvite,
  GroupAccountInviteContent,
} from "./CreateGroupModal";
import { guestRoleOptions } from "@/src/components/DealRoleRestricted";
import { PlusIcon } from "@heroicons/react/24/outline";
import { CloseIcon } from "@/src/components/CloseIcon";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  dealGroup: DealGroup;
  dealId: string;
  inviteeRole?: DealRole;
}

export function InviteMoreToGroup({
  isOpen,
  onClose,
  dealGroup,
  dealId,
  inviteeRole,
}: Props) {
  const client = useGqlClient();
  const createInvite = useCreateDealInviteMutation(client);

  const [accountsToInvite, setAccountsToInvite] = useState<
    GroupAccountInvite[]
  >([
    {
      email: "",
      role: guestRoleOptions[1],
    },
  ]);

  function close() {
    setAccountsToInvite([
      {
        email: "",
        role: guestRoleOptions[1],
      },
    ]);
    onClose();
  }

  const handleSubmit = async () => {
    try {
      await Promise.all(
        accountsToInvite
          .filter((a) => a.email.includes("@"))
          .map(
            async (account) =>
              await createInvite.mutate({
                input: {
                  autoAdmit: false,
                  email: account.email,
                  dealGroupID: dealGroup.id,
                  dataRoomPermissionID: dealGroup.dataRoomPermission.id,
                  dealID: dealId,
                  filePermissions: [],
                  folderPermissions: [],
                  message: "",
                  overrideDataRoomAccess: false,
                  role: account.role.value as DealRole,
                },
              }),
          ),
      );

      toasts.success("Invites sent successfully");
      onClose();
      setAccountsToInvite([
        {
          email: "",
          role: guestRoleOptions[0],
        },
      ]);
    } catch (error) {
      console.log(error);
      toasts.error("Failed to send invites");
    }
  };

  if (!dealGroup) {
    return null;
  }

  return (
    <AnimatedModal open={isOpen} onClose={close} size="xl">
      <div>
        <div className="flex items-center justify-between">
          <H3>Invite more users to {dealGroup.name}</H3>
          <CloseIcon onClose={close} />
        </div>

        <div className="mt-4">
          {accountsToInvite.map((invite, index) => {
            return (
              <div key={index} className="gap-x-2">
                <GroupAccountInviteContent
                  inviteeRole={inviteeRole}
                  invite={invite}
                  onChange={(invite) => {
                    const newInvites = [...accountsToInvite];
                    newInvites[index] = invite;
                    setAccountsToInvite(newInvites);
                  }}
                  index={index}
                  onRemove={(index) => {
                    const newInvites = [...accountsToInvite];
                    newInvites.splice(index, 1);
                    setAccountsToInvite(newInvites);
                  }}
                />
              </div>
            );
          })}
          {accountsToInvite.length < 6 ? (
            <button
              className="flex items-center gap-x-2 mt-2 font-semibold text-gray-500 hover:text-gray-700"
              onClick={() => {
                setAccountsToInvite([
                  ...accountsToInvite,
                  { email: "", role: guestRoleOptions[1] },
                ]);
              }}
            >
              <PlusIcon className="w-4 h-4" />
              <p className="text-sm">Add more</p>
            </button>
          ) : null}
        </div>

        <div className="mt-4 flex justify-end gap-x-2">
          <Button text="Cancel" variant="neutral" onClick={close} />
          <Button
            text="Send Invites"
            variant="positive"
            onClick={handleSubmit}
            isLoading={createInvite.isPending}
          />
        </div>
      </div>
    </AnimatedModal>
  );
}
