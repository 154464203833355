import {
  DealThreadMessageCreatedData,
  DeepSearchThreadMessageUpdatedData,
} from "../contexts/websockets";
import {
  DealThreadMessage,
  DeepSearchThreadMessage,
} from "../graphql/generated";

export function gqlDealThreadMessageToWebsocketDealThreadMessage(
  message: DealThreadMessage,
  dealThreadId: string,
): DealThreadMessageCreatedData {
  return {
    dealThreadID: dealThreadId,
    dealThreadMessageID: message.id,
    dealThreadMessage: {
      role: message.role,
      status: message.status,
      id: message.id,
      content: {
        type: "text",
        text: {
          value: message.content.text.value,
        },
        quotes: message.content.quotes.map((quote) => ({
          quote: quote.quote,
          fileId: quote.file.id,
          dataRoomFileId: quote.file.id,
          dataRoomFileVersionId: quote.file.id,
          dataRoomFileName: quote.file.name,
          dataRoomFileType: quote.file.fileType,
          status: quote.status,
          rectsOnPage: quote.rectsOnPage ?? undefined,
          pageIndex: quote.pageIndex ?? undefined,
        })),
        files: message.content.files.map((f) => ({
          dataRoomFileId: f.file.id,
          dataRoomFileName: f.file.name,
          dataRoomFileType: f.file.fileType,
          dataRoomFileVersionId: f.file.id,
          pageIndex: f.pageIndex ?? undefined,
          rectsOnPage: f.rectsOnPage ?? undefined,
        })),
      },
      createdAt: message.createdAt,
    },
  };
}

export function gqlDeepSearchThreadMessageToWebsocketDeepSearchThreadMessage(
  message: DeepSearchThreadMessage,
  deepSearchId: string,
): DeepSearchThreadMessageUpdatedData {
  return {
    deepSearchThreadMessageID: message.id,
    deepSearchId: deepSearchId,
    deepSearchThreadMessageFileIds: message.files.map((f) => f.id),
    deepSearchThreadMessage: {
      id: message.id,
      role: message.role,
      status: message.status,
      createdAt: message.createdAt,
      content: {
        role: message.role,
        status: message.status,
        type: "text",
        text: {
          value: message.content.text,
        },
      },
    },
  };
}
