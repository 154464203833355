import { useState } from "react";
import { AnimatedModal } from "@/src/components/AnimatedModal";
import { H3 } from "@/src/components/Heading";
import { CloseIcon } from "@/src/components/CloseIcon";
import { TextInput } from "@/src/components/tailwind/TextInput";
import useGqlClient from "@/src/hooks/useGqlClient";
import {
  type CreateDealPropertyMutation,
  useCreateDealPropertyMutation,
  useFirmDealPropertiesQuery,
  useUpdateDealPropertyMutation,
} from "@/src/graphql/generated";
import { Button } from "@/src/components/tailwind/Button";
import { Card } from "@/src/components/Card";
import Loading from "@/src/components/Loading";
import { useQueryClient } from "@tanstack/react-query";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { Menu } from "@headlessui/react";
import { classNames } from "@/src/utils/cn";
import { toasts } from "@/src/components/toasts/toasts";
import { useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";
import { Redirect, useHistory } from "react-router-dom";
import { TripleDotMenu } from "@/src/components/TripleDotMenu";

const DealProperties = ({ firmId }: { firmId: string }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const account = useSelector(authSelectors.account);

  if (account && !account.firm) {
    return <Redirect to="/firm" />;
  }

  return (
    <div>
      <div className="w-full flex items-center shadow-sm p-3 px-4 bg-gray-100 border-b border-gray-300">
        <div>
          <p className="font-semibold text-gray-800">Custom deal properties</p>
          <p className="text-sm text-gray-500">
            Custom deal properties are used to track deal information that is
            not already tracked by Liquid or is specific to your firm.
          </p>
        </div>
      </div>
      <div className="w-full xl:w-2/3 p-4">
        <div className="flex mt-2 items-center justify-between">
          <p className="font-semibold text-gray-800">Properties</p>
          <Button
            variant="neutral"
            onClick={() => setShowModal(true)}
            text="Create property"
            size="s"
          />
        </div>
        <Card margin="m 0 0 0">
          <DealPropertiesContent firmId={firmId} />
        </Card>
      </div>
      <CreateDealPropertyModal
        open={showModal}
        firmID={firmId}
        onCreated={() => {}}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

function DealPropertiesContent({ firmId }: { firmId: string }) {
  const client = useGqlClient();
  const { data, isLoading, error } = useFirmDealPropertiesQuery(client, {
    firmId,
  });

  const history = useHistory();

  const [selectedProperty, setSelectedProperty] = useState<{
    id: string;
    propertyKey: string;
    mode: "edit" | "delete" | null;
  } | null>(null);

  if (error) {
    return (
      <div className="p-4">
        <p className="text-gray-500 text-center text-sm font-mediumsemibold">
          Failed to load deal properties
        </p>
      </div>
    );
  }

  if (isLoading || !data) {
    return (
      <div className="p-4">
        <Loading />
      </div>
    );
  }

  if (data && data.firm && data.firm.dealProperties.length === 0) {
    return (
      <div className="p-4">
        <p className="text-gray-500 text-center text-sm font-mediumsemibold">
          No deal properties yet!
        </p>
      </div>
    );
  }

  return (
    <div>
      <table className="table-fixed w-full box-border text-left border-collapse block">
        <thead>
          <tr>
            <TableHead className="w-[40%]">Property</TableHead>
            <TableHead className="w-[30%]">Created by</TableHead>
            <TableHead className="w-[30%]">Created</TableHead>
            <th className="py-1 px-2 w-auto text-gray-500 hidden xl:table-cell"></th>
          </tr>
        </thead>
        <tbody>
          {data.firm.dealProperties.map((dp) => (
            <tr
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/firm/deal-properties/${dp.id}`);
              }}
              key={dp.id}
              className="group hover:bg-gray-100 cursor-pointer"
            >
              <TableCell className="font-semibold">{dp.propertyKey}</TableCell>
              <TableCell className="text-gray-500">
                {dp.createdBy.name}
              </TableCell>
              <TableCell className="text-gray-500">
                {formatDistanceToNowStrict(fromUnixTime(dp.createdAt), {
                  addSuffix: true,
                })}
              </TableCell>
              <td className="py-2 px-3 hidden xl:table-cell ">
                <TripleDotMenu mode="table">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedProperty({
                            id: dp.id,
                            propertyKey: dp.propertyKey,
                            mode: "edit",
                          });
                        }}
                        className={classNames(
                          active ? "bg-gray-50 text-blue-700" : "",
                          "block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer"
                        )}
                      >
                        Edit
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedProperty({
                            id: dp.id,
                            propertyKey: dp.propertyKey,
                            mode: "delete",
                          });
                        }}
                        className={classNames(
                          active ? "bg-gray-50 text-red-700" : "",
                          "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer"
                        )}
                      >
                        Delete
                      </div>
                    )}
                  </Menu.Item>
                </TripleDotMenu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <DeletePropertyModal
        id={selectedProperty?.id ?? ""}
        propertyKey={selectedProperty?.propertyKey ?? ""}
        open={selectedProperty?.mode === "delete"}
        onClose={() => setSelectedProperty(null)}
        firmId={firmId}
      />
      <EditPropertyModal
        id={selectedProperty?.id ?? ""}
        propertyKey={selectedProperty?.propertyKey ?? ""}
        open={selectedProperty?.mode === "edit"}
        onClose={() => setSelectedProperty(null)}
        firmId={firmId}
      />
    </div>
  );
}

function TableHead(props: { children: React.ReactNode; className?: string }) {
  return (
    <th
      className={`text-gray-500 font-semibold px-3 pt-2 pb-1 text-left text-sm ${props.className}`}
    >
      {props.children}
    </th>
  );
}

function TableCell(props: { children: React.ReactNode; className?: string }) {
  return (
    <td
      className={`py-3 px-3 xl:table-cell truncate text-sm text-left ${props.className}`}
    >
      {props.children}
    </td>
  );
}

function DeletePropertyModal(props: {
  id: string;
  propertyKey: string;
  open: boolean;
  onClose: () => void;
  firmId: string;
}) {
  const client = useGqlClient();
  const { mutate, isPending } = useUpdateDealPropertyMutation(client);

  const queryClient = useQueryClient();

  function close() {
    props.onClose();
  }

  return (
    <AnimatedModal open={props.open} onClose={close} size="md">
      <div>
        <div className="flex items-center justify-between">
          <H3>Delete property "{props.propertyKey}"</H3>
          <CloseIcon onClose={close} />
        </div>
        <p className="text-gray-500 text-sm mt-2">
          Are you sure you want to delete this property? This action cannot be
          undone. The property will be removed from all deals.
        </p>
        <Button
          variant="danger"
          isLoading={isPending}
          loadingText="Deleting..."
          onClick={() => {
            mutate(
              {
                input: {
                  id: props.id,
                  delete: true,
                },
              },
              {
                onSuccess: () => {
                  queryClient
                    .invalidateQueries({
                      queryKey: [
                        "FirmDealProperties",
                        { firmId: props.firmId },
                      ],
                    })
                    .then(() => {
                      toasts.success("Property deleted");
                      close();
                    });
                },
              }
            );
          }}
          text="Delete property"
          margin="xxl 0 0 0"
        />
      </div>
    </AnimatedModal>
  );
}

function EditPropertyModal(props: {
  id: string;
  propertyKey: string;
  open: boolean;
  onClose: () => void;
  firmId: string;
}) {
  const client = useGqlClient();
  const { mutate, isPending } = useUpdateDealPropertyMutation(client);

  const queryClient = useQueryClient();
  const [propertyName, setPropertyName] = useState(props.propertyKey);

  function close() {
    props.onClose();
  }

  return (
    <AnimatedModal open={props.open} onClose={close} size="md">
      <div>
        <div className="flex items-center justify-between">
          <H3>Edit property "{props.propertyKey}"</H3>
          <CloseIcon onClose={close} />
        </div>

        <form
          className="mt-3"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <TextInput
            ignore1p
            label="Property"
            placeholder="Enter property name..."
            onChange={(e) => setPropertyName(e.currentTarget.value)}
            value={propertyName}
          />
        </form>
        <Button
          isDisabled={propertyName.trim().length === 0}
          variant="neutral"
          isLoading={isPending}
          loadingText="Updating..."
          onClick={() => {
            mutate(
              { input: { id: props.id, propertyKey: propertyName } },
              {
                onSuccess: () => {
                  queryClient
                    .invalidateQueries({
                      queryKey: [
                        "FirmDealProperties",
                        { firmId: props.firmId },
                      ],
                    })
                    .then(() => {
                      toasts.success("Property updated");
                      close();
                    });
                },
              }
            );
          }}
          type="submit"
          text="Update property"
          margin="xxl 0 0 0"
        />
      </div>
    </AnimatedModal>
  );
}

function CreateDealPropertyModal(props: {
  open: boolean;
  onClose: () => void;
  onCreated: (
    created: CreateDealPropertyMutation["createDealProperty"]
  ) => void;
  firmID: string;
}) {
  const client = useGqlClient();
  const { mutate } = useCreateDealPropertyMutation(client);

  const [propertyName, setPropertyName] = useState("");
  const queryClient = useQueryClient();

  function close() {
    setPropertyName("");
    props.onClose();
  }

  async function handleSaveClick() {
    const name = propertyName.trim();

    mutate(
      { input: { firmID: props.firmID, propertyKey: name } },
      {
        onSuccess: (e) => {
          queryClient.invalidateQueries({
            queryKey: ["FirmDealProperties", { firmId: props.firmID }],
          });
        },
        onError: (e) => console.log("Error", e),
      }
    );

    close();
  }

  return (
    <AnimatedModal open={props.open} onClose={close}>
      <div>
        <div className="flex items-center justify-between">
          <H3>New deal property</H3>
          <CloseIcon onClose={close} />
        </div>

        <form
          className="mt-3"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <TextInput
            ignore1p
            label="Property"
            placeholder="Enter property name..."
            onChange={(e) => setPropertyName(e.currentTarget.value)}
          />
        </form>
        <Button
          isDisabled={propertyName.trim().length === 0}
          variant="positive"
          onClick={handleSaveClick}
          type="submit"
          text="Create Property"
          margin="xxl 0 0 0"
        />
      </div>
    </AnimatedModal>
  );
}

export default DealProperties;
