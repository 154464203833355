import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";
import { folderWrapper } from "./NewFilesUploader";
import { fileWrapper } from "./NewFilesUploader";

type UploadContextType = {
    uploadFiles: fileWrapper[];
    setUploadFiles: Dispatch<SetStateAction<fileWrapper[]>>;
    uploadFolders: folderWrapper[];
    setUploadFolders: Dispatch<SetStateAction<folderWrapper[]>>;
};

const UploadContext = createContext<UploadContextType | undefined>(
    {
        uploadFiles: [],
        setUploadFiles: () => {},
        uploadFolders: [],
        setUploadFolders: () => {},
    }
);

export function UploadContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {

    const [uploadFiles, setUploadFiles] = useState<fileWrapper[]>([]);
    const [uploadFolders, setUploadFolders] = useState<folderWrapper[]>([]);

  return (
    <UploadContext.Provider value={{ uploadFiles: uploadFiles, setUploadFiles: setUploadFiles, uploadFolders: uploadFolders, setUploadFolders: setUploadFolders }}>
      {children}
    </UploadContext.Provider>
  );
}

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error(
      "useUploadContext must be used within a UploadContextProvider"
    );
  }
  return context;
};
