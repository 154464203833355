import { DealRoleRestricted } from "@/src/components/DealRoleRestricted";
import {
  DataRoomFolder,
  DataRoomFolderFragmentFragment,
} from "@/src/graphql/generated";
import { Menu } from "@headlessui/react";
import { Draggable } from "@hello-pangea/dnd";
import { Droppable } from "@hello-pangea/dnd";
import { adminRoles } from "@/src/components/DealRoleRestricted";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { classNames } from "@/src/utils/cn";
import { TripleDotMenu } from "@/src/components/TripleDotMenu";
import { FolderIcon } from "@heroicons/react/24/solid";
import { useHistory, useLocation } from "react-router-dom";
import { ReactNode, useState, useCallback } from "react";

// TableCell component for consistent styling
interface TableCellProps {
  children: ReactNode;
  className?: string;
  visibilityClass?: string;
}

function TableCell({
  children,
  className = "",
  visibilityClass = "",
}: TableCellProps) {
  return (
    <td className={`py-2 px-3 ${visibilityClass} ${className}`}>{children}</td>
  );
}

// Text component for consistent text styling
function TableText({
  children,
  className = "",
}: {
  children: ReactNode;
  className?: string;
}) {
  return <p className={`text-sm text-gray-500 ${className}`}>{children}</p>;
}

export function NonDroppableFolders(props: {
  folder: DataRoomFolderFragmentFragment;
}) {
  const history = useHistory();
  return (
    <div className="contents">
      {props.folder?.folders
        .sort((a, b) => {
          if (a.dealGroup && !b.dealGroup) {
            return -1;
          }

          if (!a.dealGroup && b.dealGroup) {
            return 1;
          }

          return 0;
        })
        .map((folder, index) => (
          <tr
            className={`cursor-pointer w-full table-row group hover:bg-gray-50`}
            onDoubleClick={() => {}}
            onClick={(e) => {
              history.push(`/deal/documents/folder/` + folder.id);
            }}
          >
            <TableCell className="w-full">
              <div
                className="flex items-center gap-x-2 relative"
                style={{
                  background: "transparent",
                }}
              >
                <FolderIcon
                  className={
                    folder.dealGroup ? "text-orange-800/70" : "text-blue-700/70"
                  }
                  width={20}
                  height={20}
                />
                <p
                  className="text-sm font-semibold text-gray-600 truncate whitespace-nowrap"
                  style={{
                    maxWidth: "calc(100% - 30px)",
                  }}
                >
                  {folder.name}
                </p>
              </div>
            </TableCell>
            <DealRoleRestricted roles={adminRoles}>
              <TableCell
                visibilityClass="hidden xl:table-cell"
                className="truncate"
              >
                <TableText>{folder.createdBy.name}</TableText>
              </TableCell>
            </DealRoleRestricted>
            <TableCell visibilityClass="hidden md:table-cell">
              <TableText>
                {formatDistanceToNowStrict(fromUnixTime(folder.createdAt), {
                  addSuffix: true,
                })}
              </TableText>
            </TableCell>
            <DealRoleRestricted roles={adminRoles}>
              <TableCell visibilityClass="hidden lg:table-cell">
                <TableText>
                  {folder.permission ? folder.permission.name : "-"}
                </TableText>
              </TableCell>
            </DealRoleRestricted>
          </tr>
        ))}
    </div>
  );
}

export function DroppableFolders(props: {
  folder: DataRoomFolderFragmentFragment;
  selectedFolders: string[];
  setSelectedFolders: (folders: string[]) => void;
  selectedFiles: string[];
  setSelectedFiles: (files: string[]) => void;
  handleMouseDown: (e: React.MouseEvent) => void;
  handleFolderSelection: (
    folderId: string,
    shiftKey: boolean,
    e: React.MouseEvent,
  ) => void;
  setEditFolderId: (folderId: string) => void;
  setMoveFolderId: (folderId: string) => void;
  folderDragStates: Record<string, boolean>;
  updateFolderDragState: (folderId: string, isDraggingOver: boolean) => void;
  folderBeingDraggedOver: string | null;
  globalDragging?: boolean;
  setGlobalDragging?: (dragging: boolean) => void;
}) {
  const history = useHistory();
  const [isDraggingSelectedFolder, setIsDraggingSelectedFolder] = useState(false);
  const [folderBeingDragged, setFolderBeingDragged] = useState<string | null>(null);
  
  // Clear local state when a drag operation completes
  const resetDragState = useCallback(() => {
    setFolderBeingDragged(null);
    setIsDraggingSelectedFolder(false);
    // Update global dragging state if prop is provided
    if (props.setGlobalDragging) {
      props.setGlobalDragging(false);
    }
  }, [props.setGlobalDragging]);

  return (
    <Droppable
      droppableId="folders"
      type="FILE-FOLDER"
      renderClone={(provided, snapshot, rubric) => {
        // Find the folder being dragged to display in the clone
        const folderBeingDragged = props.folder?.folders[rubric.source.index];

        if (folderBeingDragged && snapshot.isDragging) {
          // Using setTimeout to avoid state updates during render
          setTimeout(() => {
            setFolderBeingDragged(folderBeingDragged.id);
            if (props.selectedFolders.includes(folderBeingDragged.id)) {
              setIsDraggingSelectedFolder(true);
            }
            // Update global dragging state
            if (props.setGlobalDragging) {
              props.setGlobalDragging(true);
            }
          }, 0);
        } else if (!snapshot.isDragging) {
          setTimeout(resetDragState, 0);
        }

        const totalSelectedItems =
          props.selectedFiles.length + props.selectedFolders.length;

        const isDraggingMultiple =
          totalSelectedItems > 1 &&
          (props.selectedFolders.includes(folderBeingDragged?.id) ||
            (totalSelectedItems > 0 && props.selectedFiles.length > 0));

        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div className="relative w-[160px]">
              <div className="absolute -top-1 -left-1 w-full h-full bg-gray-100 border border-gray-300 rounded-md"></div>
              <div className="absolute -top-0.5 -left-0.5 w-full h-full bg-gray-50 border border-gray-300 rounded-md"></div>
              <div className="relative bg-white border border-gray-300 rounded-md p-1 px-2">
                <div className="flex items-center gap-2">
                  <FolderIcon
                    className="text-blue-700/70"
                    width={20}
                    height={20}
                  />
                  <span className="text-sm font-medium">
                    {isDraggingMultiple
                      ? `${totalSelectedItems} item${totalSelectedItems !== 1 ? "s" : ""}`
                      : folderBeingDragged?.name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    >
      {(provided, snapshot) => {
        // When we exit dragging, reset the state
        if (!snapshot.isDraggingOver && !snapshot.draggingFromThisWith && isDraggingSelectedFolder) {
          setTimeout(resetDragState, 100);
        }
        
        return (
          <>
            <tr className="hidden">
              <td></td>
            </tr>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="contents"
            >
              {props.folder?.folders
                .sort((a, b) => {
                  if (a.dealGroup && !b.dealGroup) {
                    return -1;
                  }

                  if (!a.dealGroup && b.dealGroup) {
                    return 1;
                  }

                  return 0;
                })
                .map((folder, index) => {
                  const isGloballyDragging = props.globalDragging || isDraggingSelectedFolder;
                  
                  if (
                    isGloballyDragging &&
                    props.selectedFolders.includes(folder.id) &&
                    folder.id !== folderBeingDragged
                  ) {
                    return null;
                  }

                  return (
                    <Draggable
                      key={folder.id}
                      draggableId={
                        folder.id.startsWith("dtrmfolder")
                          ? folder.id
                          : `dtrmfolder-${folder.id}`
                      }
                      index={index}
                      isDragDisabled={folder.dealGroup ? true : false}
                    >
                      {(provided, snapshot) => {
                        if (snapshot.isDragging) {
                          setTimeout(() => {
                            setFolderBeingDragged(folder.id);
                            if (props.selectedFolders.includes(folder.id)) {
                              setIsDraggingSelectedFolder(true);
                            }
                            if (props.setGlobalDragging) {
                              props.setGlobalDragging(true);
                            }
                          }, 0);
                        }

                        return (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`cursor-pointer w-full table-row group ${
                              snapshot.isDragging ? "opacity-70" : ""
                            } ${
                              props.selectedFolders.includes(folder.id)
                                ? "bg-blue-100"
                                : props.folderBeingDraggedOver === folder.id
                                  ? "bg-blue-100"
                                  : "hover:bg-gray-50"
                            }`}
                            onMouseDown={props.handleMouseDown}
                            onDoubleClick={() => {
                              props.setSelectedFiles([]);
                              props.setSelectedFolders([]);
                              history.push(
                                `/deal/documents/folder/` + folder.id,
                              );
                            }}
                            onClick={(e) => {
                              props.handleFolderSelection(
                                folder.id,
                                e.shiftKey,
                                e,
                              );
                            }}
                          >
                            <TableCell className="w-full">
                              <Droppable
                                droppableId={folder.id}
                                type="FILE-FOLDER"
                              >
                                {(providedDroppable, snapshotDroppable) => {
                                  const isDraggingOver =
                                    snapshotDroppable.isDraggingOver;
                                  if (
                                    props.folderDragStates[folder.id] !==
                                    isDraggingOver
                                  ) {
                                    setTimeout(() => {
                                      props.updateFolderDragState(
                                        folder.id,
                                        isDraggingOver,
                                      );
                                    }, 0);
                                  }

                                  return (
                                    <div
                                      ref={providedDroppable.innerRef}
                                      {...providedDroppable.droppableProps}
                                      className="flex items-center gap-x-2 relative"
                                      style={{
                                        background: "transparent",
                                      }}
                                    >
                                      <FolderIcon
                                        className={
                                          folder.dealGroup
                                            ? "text-orange-800/70"
                                            : "text-blue-700/70"
                                        }
                                        width={20}
                                        height={20}
                                      />
                                      <p
                                        className="text-sm font-semibold text-gray-600 truncate whitespace-nowrap"
                                        style={{
                                          maxWidth: "calc(100% - 30px)",
                                        }}
                                      >
                                        {folder.name}
                                      </p>
                                      <div className="absolute inset-0 pointer-events-none">
                                        {providedDroppable.placeholder}
                                      </div>
                                    </div>
                                  );
                                }}
                              </Droppable>
                            </TableCell>
                            <DealRoleRestricted roles={adminRoles}>
                              <TableCell
                                visibilityClass="hidden xl:table-cell"
                                className="truncate"
                              >
                                <TableText>{folder.createdBy.name}</TableText>
                              </TableCell>
                            </DealRoleRestricted>
                            <TableCell visibilityClass="hidden md:table-cell">
                              <TableText>
                                {formatDistanceToNowStrict(
                                  fromUnixTime(folder.createdAt),
                                  {
                                    addSuffix: true,
                                  },
                                )}
                              </TableText>
                            </TableCell>
                            <DealRoleRestricted roles={adminRoles}>
                              <TableCell visibilityClass="hidden lg:table-cell">
                                <TableText>
                                  {folder.permission
                                    ? folder.permission.name
                                    : "-"}
                                </TableText>
                              </TableCell>
                            </DealRoleRestricted>
                            <DealRoleRestricted roles={adminRoles}>
                              <TableCell visibilityClass="hidden sm:table-cell">
                                {folder.dealGroup ? null : (
                                  <IndividualFolderActions
                                    folder={folder as DataRoomFolder}
                                    setEditFolderId={props.setEditFolderId}
                                    setMoveFolderId={props.setMoveFolderId}
                                  />
                                )}
                              </TableCell>
                            </DealRoleRestricted>
                          </tr>
                        );
                      }}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          </>
        );
      }}
    </Droppable>
  );
}

function IndividualFolderActions(props: {
  folder: DataRoomFolder;
  setEditFolderId: (folderId: string) => void;
  setMoveFolderId: (folderId: string) => void;
}) {
  const history = useHistory();
  const location = useLocation();

  return (
    <TripleDotMenu mode="table">
      <Menu.Item>
        {({ active }) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              props.setMoveFolderId(props.folder.id);
            }}
            className={classNames(
              active ? "bg-gray-50 text-blue-700" : "",
              "block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer",
            )}
          >
            Move
          </div>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              props.setEditFolderId(props.folder.id);
            }}
            className={classNames(
              active ? "bg-gray-50 text-blue-700" : "",
              "block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer",
            )}
          >
            Edit
          </div>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              const params = new URLSearchParams({
                deleteFolder: props.folder.id,
              });
              history.replace({
                pathname: location.pathname,
                search: params.toString(),
              });
            }}
            className={classNames(
              active ? "bg-gray-50 text-red-700" : "",
              "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer",
            )}
          >
            Delete
          </div>
        )}
      </Menu.Item>
    </TripleDotMenu>
  );
}
