import { DdqlStatus } from "@/src/graphql/generated";

import { classNames } from "@/src/utils/cn";

import { Ddql } from "@/src/graphql/generated";
import { NavLink } from "react-router-dom";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { ProgressBar } from "@/src/components/ProgressBar";

export function DdqlListItem(props: {
  ddql: Ddql;
  isFirst: boolean;
  isLast: boolean;
}) {
  const { ddql } = props;
  return (
    <NavLink to={`/deal/questions/lists/${ddql.id}`}>
      <div
        className={classNames(
          "flex items-center justify-between hover:bg-gray-100 px-3 py-2",
          props.isFirst ? "rounded-t-md" : "",
          props.isLast ? "rounded-b-md" : "",
        )}
      >
        <div className="">
          <div className="flex items-center gap-x-2 mb-1">
            <p className="text-sm font-bold text-gray-700">{ddql.name}</p>
          </div>
          <p className="text-xs text-gray-500/80">
            Created{" "}
            {formatDistanceToNow(fromUnixTime(ddql.createdAt), {
              addSuffix: true,
            })}
          </p>
        </div>
        <div className="flex flex-col items-end">
          <DdqlStatusText ddql={ddql} />
          <div className="w-52 flex items-center gap-x-2">
            <ProgressBar
              height="h-1"
              backgroundColor="bg-green-500/20"
              progressColor="bg-green-500"
              progress={
                ddql.totalChildQuestions === 0
                  ? 0
                  : (ddql.answeredChildQuestions / ddql.totalChildQuestions) *
                    100
              }
            />
            <p className="text-xs text-gray-500/80">
              {ddql.answeredChildQuestions}/{ddql.totalChildQuestions}
            </p>
          </div>
        </div>
      </div>
    </NavLink>
  );
}

function DdqlStatusText(props: { ddql: Ddql }) {
  const status = props.ddql.status;
  if (!status) {
    return null;
  }

  return (
    <p
      className={classNames(
        "text-xs font-medium",
        status === DdqlStatus.Published ? "text-green-700" : "",
        status === DdqlStatus.Initial || status === DdqlStatus.Imported
          ? "text-orange-700"
          : "",
        status === DdqlStatus.Importing ? "text-gray-500" : "",
        status === DdqlStatus.ImportFailed ? "text-red-700" : "",
      )}
    >
      {ddqlStatusText(status)}
    </p>
  );
}

function ddqlStatusText(status: DdqlStatus) {
  switch (status) {
    case DdqlStatus.Published:
      return "Published";
    case DdqlStatus.Initial:
    case DdqlStatus.Imported:
      return "Draft";
    case DdqlStatus.Importing:
      return "Importing...";
    case DdqlStatus.ImportFailed:
      return "Import failed";
    default:
      return "Unknown";
  }
}
