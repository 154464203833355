import { Card } from "@/src/components/Card";
import Loading from "@/src/components/Loading";
import { Button } from "@/src/components/tailwind/Button";
import {
  AccessControlResourceType,
  QuestionAccessControlQuery,
  useQuestionAccessControlQuery,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { UserPlusIcon } from "lucide-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";
import {
  AddMoreModal,
  ddqlPermissionOptions,
} from "@/src/components/access_control/AddMoreModal";
import { AccessControlGroupItem } from "@/src/components/access_control/AccessControlGroupItem";

export function AccessControl(props: { questionId: string }) {
  const client = useGqlClient();
  const questionAccessQuery = useQuestionAccessControlQuery(client, {
    id: props.questionId,
  });

  const [openAddMoreModal, setOpenAddMoreModal] = useState<"" | "add_more">("");

  const activeDealId = useSelector(authSelectors.activeDealId);
  const queryClient = useQueryClient();

  return (
    <div className="flex-1">
      <div className="flex justify-between items-center">
        <p className="text-md font-semibold text-gray-700">Access controls</p>

        <Button
          icon={UserPlusIcon}
          size="s"
          variant="neutral"
          text="Add more people"
          onClick={() => setOpenAddMoreModal("add_more")}
        />
      </div>
      <Card margin="s 0 0 0">
        <AccessControlContent
          query={questionAccessQuery}
          questionId={props.questionId}
        />
      </Card>
      <AddMoreModal
        open={openAddMoreModal === "add_more"}
        onClose={() => {
          setOpenAddMoreModal("");
          queryClient.invalidateQueries({
            queryKey: ["QuestionAccessControl", { id: props.questionId }],
          });
        }}
        dealId={activeDealId ?? ""}
        access={
          questionAccessQuery.data?.question.accessControlDealGroups ?? []
        }
        resourceId={props.questionId}
        options={ddqlPermissionOptions}
        resourceType={AccessControlResourceType.Question}
      />
    </div>
  );
}

function AccessControlContent(props: {
  query: UseQueryResult<QuestionAccessControlQuery, unknown>;
  questionId: string;
}) {
  const { query, questionId } = props;
  const queryClient = useQueryClient();

  if (query.error) {
    return (
      <div>
        <p className="text-gray-500 text-sm">Error loading access controls</p>
      </div>
    );
  }

  if (query.isLoading || !query.data) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className="p-3">
        <p className="text-sm text-gray-500">
          This is everyone who currently has access to this question
        </p>
      </div>
      {query.data.question.accessControlDealGroups.map((group) => {
        return (
          <AccessControlGroupItem
            key={group.dealGroup.id}
            group={group}
            resourceId={query.data.question.id}
            onEdit={() => {
              queryClient.invalidateQueries({
                queryKey: ["QuestionAccessControl", { id: props.questionId }],
              });
            }}
            onRemove={() => {
              queryClient.invalidateQueries({
                queryKey: ["QuestionAccessControl", { id: props.questionId }],
              });
            }}
          />
        );
      })}
    </div>
  );
}
