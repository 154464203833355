import { NavLink, useHistory, useParams } from "react-router-dom";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import {
  useDdqlQuery,
  Question as GqlQuestion,
  DdqlStatus,
  Ddql as GqlDdql,
  usePublishDdqlMutation,
  useDeleteDdqlMutation,
  DdqlQuery,
  useUpdateDdqlMutation,
  AccessControlEntityType,
  AccessControlPermissionLevel,
  AccessControlResourceType,
} from "../../../graphql/generated";
import { classNames } from "../../../utils/cn";
import { Button } from "../../../components/tailwind/Button";
import Loading from "../../../components/Loading";
import { H3, H4 } from "../../../components/Heading";
import { Card } from "../../../components/Card";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { StatusPill } from "../Group";
import { NewQuestionModal, Question } from "..";
import { NewDDQLModal } from "./../NewDDQLModal";
import { toasts } from "../../../components/toasts/toasts";
import {
  PlusIcon,
  CheckCircleIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";
import { AnimatedModal } from "@/src/components/AnimatedModal";
import { CloseIcon } from "@/src/components/CloseIcon";
import { AccessControl } from "./access_control";
import { AccessControlCheck } from "@/src/components/access_control/AccessControlCheck";
import { TripleDotMenu } from "@/src/components/TripleDotMenu";
import { Menu } from "@headlessui/react";

export function Ddql() {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const [openModal, setOpenModal] = useState<
    "" | "import_ddql" | "new_question" | "create_ddql" | "assign_to_deal_group"
  >("");

  const client = useGqlClient();
  const queryClient = useQueryClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const ddqlQuery = useDdqlQuery(client, {
    id,
    dealId: activeDealId ?? "",
  });

  const updateDdql = useUpdateDdqlMutation(client);
  const publishDdql = usePublishDdqlMutation(client);
  const deleteDdql = useDeleteDdqlMutation(client);

  if (ddqlQuery.error) {
    return (
      <div className="flex flex-col flex-1 justify-center items-center h-full w-full">
        <p className="font-semibold text-gray-600 text-sm">
          Something went wrong
        </p>
        <p className="text-gray-500/80 text-xs">
          Sorry about that. Try again or contact support.
        </p>
        <Button
          margin="m 0 0 0"
          variant="neutral"
          text="Try again"
          onClick={() => {
            ddqlQuery.refetch();
          }}
        />
      </div>
    );
  }

  if (ddqlQuery.isLoading || !ddqlQuery.data) {
    return <Loading />;
  }

  const questions = ddqlQuery.data.ddql.questions;
  const children = ddqlQuery.data.ddql.children;

  return (
    <div className="flex-1 flex-col flex">
      <div className="bg-white sticky right-0 z-20 top-0 px-8 py-3 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <NavLink to="/deal/questions">
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                Questions
              </h4>
            </NavLink>
            <p className="text-gray-400">/</p>
            <div className="font-semibold">
              <H4>{ddqlQuery.data.ddql.name}</H4>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      <div className="flex p-8 gap-x-8">
        <div className="lg:w-2/3">
          <div className="flex justify-between items-start">
            <div>
              <div className="font-bold text-gray-800 mr-5">
                <H3>{ddqlQuery.data.ddql.name}</H3>
              </div>
            </div>

            <div className="flex items-center gap-x-3">
              <AccessControlCheck
                entityType={AccessControlEntityType.DealGroup}
                resourceType={AccessControlResourceType.Ddql}
                resourceId={id}
                requiredPermissionLevels={[AccessControlPermissionLevel.Admin]}
              >
                <div className="whitespace-nowrap flex justify-end">
                  <TripleDotMenu width="w-60">
                    <Menu.Item>
                      <button
                        className="w-full text-sm text-red-500 hover:text-red-600 text-left py-2 px-3 hover:bg-gray-100"
                        onClick={() => {
                          deleteDdql.mutate(
                            {
                              id: id,
                            },
                            {
                              onSuccess: () => {
                                history.push(`/deal/questions`);
                                toasts.success("DDQL deleted");
                              },
                              onError: (e) => {
                                toasts.error("Failed to delete DDQL");
                              },
                            },
                          );
                        }}
                      >
                        Delete list (this action cannot be undone)
                      </button>
                    </Menu.Item>
                  </TripleDotMenu>
                </div>
              </AccessControlCheck>

              {(ddqlQuery.data.ddql.status === DdqlStatus.Imported ||
                ddqlQuery.data.ddql.status === DdqlStatus.Initial) && (
                <div className="whitespace-nowrap min-w-[120px]">
                  <Button
                    size="s"
                    variant="positive"
                    icon={CheckCircleIcon}
                    text="Publish DDQL"
                    onClick={() => {
                      publishDdql.mutate(
                        {
                          id: id,
                        },
                        {
                          onSuccess: () => {
                            history.push(`/deal/questions`);
                            toasts.success("DDQL published");
                          },
                          onError: (e) => {
                            toasts.error("Failed to publish DDQL");
                          },
                        },
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between gap-x-3 mt-4">
            <p className="text-xs text-gray-500/80">
              Created by {ddqlQuery.data.ddql.createdBy.name}{" "}
              {formatDistanceToNow(
                fromUnixTime(ddqlQuery.data.ddql.createdAt),
                {
                  addSuffix: true,
                },
              )}
            </p>
            <StatusPill ddql={ddqlQuery.data.ddql} />
            <AccessControlCheck
              entityType={AccessControlEntityType.DealGroup}
              resourceType={AccessControlResourceType.Ddql}
              resourceId={id}
              requiredPermissionLevels={[
                AccessControlPermissionLevel.Admin,
                AccessControlPermissionLevel.Edit,
              ]}
            >
              <div className="flex gap-x-2">
                <button
                  onClick={() => setOpenModal("new_question")}
                  className="text-indigo-500 text-sm font-semibold flex items-center gap-x-1 hover:text-indigo-600"
                >
                  <PlusIcon className="w-4 h-4" />
                  Add question
                </button>
              </div>
            </AccessControlCheck>
          </div>

          <div className="mt-5">
            <Card margin="s 0 0 0">
              {children.length === 0 && questions.length === 0 ? (
                <div className="p-6 flex flex-col justify-center items-center flex-1">
                  <p className="text-gray-600 text-sm font-medium">
                    No content yet
                  </p>
                  {/* <p className="text-xs text-gray-400 mt-1">
                  Add a question or create a new list
                </p> */}
                </div>
              ) : (
                <div className="flex flex-col py-2">
                  {/* Show questions for this DDQL */}
                  {questions?.length > 0 && (
                    <div className="pl-6">
                      {questions
                        .sort((a, b) => {
                          if (a.answer && !b.answer) return 1;
                          if (!a.answer && b.answer) return -1;
                          return 0;
                        })
                        .map((q, qIndex) => (
                          <Question
                            key={q.id}
                            isFirst={qIndex === 0}
                            isLast={qIndex === questions.length - 1}
                            question={q as GqlQuestion}
                            showAnswered={false}
                            showAvatar={false}
                            showAssignToDealGroup={false}
                          />
                        ))}
                    </div>
                  )}

                  {/* Render child DDQLs */}
                  {children?.map((childDdql, index) => (
                    <DDQLNode
                      key={childDdql.id}
                      ddql={childDdql as GqlDdql}
                      depth={0}
                      isLast={index === children.length - 1}
                      rootDdqlStatus={
                        ddqlQuery.data.ddql.status || DdqlStatus.Initial
                      }
                    />
                  ))}
                </div>
              )}
            </Card>
          </div>
        </div>
        <AccessControlCheck
          entityType={AccessControlEntityType.DealGroup}
          resourceType={AccessControlResourceType.Ddql}
          resourceId={id}
          requiredPermissionLevels={[AccessControlPermissionLevel.Admin]}
        >
          <div className="flex-1">
            <AccessControl ddqlId={id} />
          </div>
        </AccessControlCheck>
      </div>
      <NewQuestionModal
        open={openModal === "new_question"}
        onClose={() => setOpenModal("")}
        ddqlId={id}
      />
      <NewDDQLModal
        open={openModal === "create_ddql"}
        onClose={() => {
          setOpenModal("");
          queryClient.invalidateQueries({
            queryKey: ["Ddql"],
          });
        }}
        parentDdqlID={ddqlQuery.data.ddql.id}
        dealFirmGroupId={ddqlQuery.data.ddql.dealGroup?.id ?? ""}
      />
      <AssignToDealGroupModal
        open={openModal === "assign_to_deal_group"}
        onClose={() => setOpenModal("")}
        ddqlQuery={ddqlQuery}
        dealId={activeDealId ?? ""}
      />
    </div>
  );
}

function AssignToDealGroupModal(props: {
  open: boolean;
  onClose: () => void;
  ddqlQuery: UseQueryResult<DdqlQuery, unknown>;
  dealId: string;
}) {
  const [selectedDealGroup, setSelectedDealGroup] = useState<string | null>(
    props.ddqlQuery.data?.ddql.forDealGroup?.id ?? null,
  );

  useEffect(() => {
    setSelectedDealGroup(props.ddqlQuery.data?.ddql.forDealGroup?.id ?? null);
  }, [props.ddqlQuery.data, props.open]);

  const client = useGqlClient();
  const queryClient = useQueryClient();
  const updateDdql = useUpdateDdqlMutation(client);

  function onClose() {
    setSelectedDealGroup(null);
    props.onClose();
  }

  return (
    <AnimatedModal open={props.open} onClose={onClose} size="lg">
      <div className="flex flex-col gap-y-4">
        <div className="">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">
              Assign to Deal Group
            </h3>
            <CloseIcon onClose={onClose} />
          </div>
          <div className="space-y-3">
            {props.ddqlQuery.data?.deal.guestGroups.map((group) => (
              <label
                key={group.id}
                className="flex items-center space-x-3 px-4 py-2 hover:bg-gray-50 cursor-pointer rounded-md transition-colors duration-150"
              >
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={selectedDealGroup === group.id}
                  onChange={() =>
                    setSelectedDealGroup(
                      selectedDealGroup === group.id ? null : group.id,
                    )
                  }
                />
                <span className="text-md font-semibold text-gray-700">
                  {group.name}
                </span>
                <span className="text-xs text-gray-500">
                  ({group.accounts.length}{" "}
                  {group.accounts.length === 1 ? "member" : "members"})
                </span>
              </label>
            ))}
          </div>
          <div className="mt-6 flex justify-end gap-x-3">
            <Button variant="neutral" text="Cancel" onClick={props.onClose} />
            <Button
              isLoading={updateDdql.isPending}
              variant="positive"
              text="Assign"
              onClick={() => {
                if (!props.ddqlQuery.data || !selectedDealGroup) {
                  return;
                }

                updateDdql.mutate(
                  {
                    input: {
                      id: props.ddqlQuery.data.ddql.id,
                      forDealGroupID: selectedDealGroup,
                    },
                  },
                  {
                    onSuccess: () => {
                      toasts.success(`DDQL assigned`);
                      onClose();
                      if (props.ddqlQuery.data) {
                        queryClient.invalidateQueries({
                          queryKey: [
                            "Ddql",
                            {
                              id: props.ddqlQuery.data.ddql.id,
                              dealId: props.dealId,
                            },
                          ],
                        });
                      }
                    },
                    onError: () => {
                      toasts.error(`Failed to assign DDQL`);
                    },
                  },
                );
              }}
            />
          </div>
        </div>
      </div>
    </AnimatedModal>
  );
}

interface DDQLNodeProps {
  ddql: GqlDdql;
  depth?: number;
  isLast?: boolean;
  rootDdqlStatus: DdqlStatus;
}

function DDQLNode({
  ddql,
  depth = 0,
  isLast = false,
  rootDdqlStatus,
}: DDQLNodeProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const marginClasses = ["ml-0", "ml-4", "ml-8", "ml-12", "ml-16", "ml-20"];

  // If the DDQL has no name and has questions, we should display the questions directly
  const hasEmptyName = !ddql.name || ddql.name.trim() === "";
  const hasQuestions = ddql.questions?.length > 0;
  const hasChildren = ddql.children?.length > 0;

  // For empty-named DDQLs with questions, we'll skip the header and display questions directly
  if (hasEmptyName && hasQuestions) {
    return (
      <div
        className={classNames("flex flex-col", depth === 0 ? "mt-4" : "mt-2")}
      >
        <div
          className={classNames(
            "border-l-2 border-gray-100",
            depth > 0 ? marginClasses[depth] : "ml-6",
          )}
        >
          <div className="pl-6 py-2">
            {ddql.questions
              ?.sort((a, b) => (a.index || "").localeCompare(b.index || ""))
              .map((q, qIndex) => (
                <Question
                  key={q.id}
                  isFirst={qIndex === 0}
                  isLast={qIndex === ddql.questions?.length - 1}
                  question={q as GqlQuestion}
                  showComments={rootDdqlStatus === DdqlStatus.Published}
                  showAnswered={rootDdqlStatus === DdqlStatus.Published}
                  showAvatar={rootDdqlStatus === DdqlStatus.Published}
                  showIndex={true}
                  showAssignToDealGroup={false}
                />
              ))}
          </div>

          {/* Render children if any */}
          {hasChildren && (
            <div
              className={classNames("space-y-1", hasQuestions ? "mt-2" : "")}
            >
              {ddql.children?.map((childDdql, index) => (
                <DDQLNode
                  key={childDdql.id}
                  ddql={childDdql}
                  depth={depth + 1}
                  isLast={index === ddql.children?.length - 1}
                  rootDdqlStatus={rootDdqlStatus}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  // Original rendering for DDQLs with names
  return (
    <div className={classNames("flex flex-col", depth === 0 ? "mt-4" : "mt-2")}>
      <NavLink to={`/deal/questions/lists/${ddql.id}`}>
        <div
          className={classNames(
            "flex items-center justify-between rounded-md transition-colors duration-150",
            "hover:bg-indigo-50/70 group",
            depth === 0 ? "px-5 py-3" : "px-4 py-2.5",
            depth > 0 ? marginClasses[depth] : "",
            "border-l-2",
          )}
          onClick={(e) => {
            if (depth > 0) {
              e.preventDefault();
              setIsCollapsed(!isCollapsed);
            }
          }}
        >
          <div className="flex items-center gap-x-2">
            {depth > 0 && (hasQuestions || hasChildren) && (
              <ChevronRightIcon
                className={classNames(
                  "w-4 h-4 text-gray-400 transition-transform",
                  isCollapsed ? "" : "rotate-90",
                )}
              />
            )}
            <div
              className={classNames(
                "rounded-full",
                depth === 0 ? "w-2 h-2" : "w-1.5 h-1.5",
              )}
            />
            <p
              className={classNames(
                "group-hover:text-indigo-600 transition-colors duration-150",
                depth === 0
                  ? "text-base font-semibold text-gray-700"
                  : hasQuestions || hasChildren
                    ? "text-sm font-semibold text-gray-600"
                    : "text-sm font-normal text-gray-500",
              )}
            >
              {ddql.name}
            </p>
          </div>

          <div
            className={classNames(
              "flex items-center gap-x-3 text-xs",
              hasQuestions || hasChildren
                ? "text-gray-500 font-medium"
                : "text-gray-300",
            )}
          >
            {hasQuestions && <span>{ddql.questions.length} questions</span>}
            {hasChildren && <span>{ddql.children.length} sublists</span>}
          </div>
        </div>
      </NavLink>

      {!isCollapsed && (
        <div
          className={classNames(
            "border-l-2 border-gray-100",
            depth > 0 ? marginClasses[depth] : "ml-6",
          )}
        >
          {hasQuestions && (
            <div className="pl-6 py-2">
              {ddql.questions
                ?.sort((a, b) => (a.index || "").localeCompare(b.index || ""))
                .map((q, qIndex) => (
                  <Question
                    key={q.id}
                    isFirst={qIndex === 0}
                    isLast={qIndex === ddql.questions?.length - 1}
                    question={q as GqlQuestion}
                    showComments={rootDdqlStatus === DdqlStatus.Published}
                    showAnswered={rootDdqlStatus === DdqlStatus.Published}
                    showAvatar={rootDdqlStatus === DdqlStatus.Published}
                    showIndex={true}
                    showAssignToDealGroup={false}
                  />
                ))}
            </div>
          )}

          <div className={classNames("space-y-1", hasQuestions ? "mt-2" : "")}>
            {ddql?.children?.map((childDdql, index) => (
              <DDQLNode
                key={childDdql.id}
                ddql={childDdql}
                depth={depth + 1}
                isLast={index === ddql.children?.length - 1}
                rootDdqlStatus={rootDdqlStatus}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
