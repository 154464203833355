import { Card } from "@/src/components/Card";
import { FilePill } from "@/src/components/FilePill";
import Loading from "@/src/components/Loading";
import { PageContent } from "@/src/components/PageContent";
import { Pills } from "@/src/components/Pills";
import {
  DataRoomFileSimilarityStatus,
  SimilarDataRoomFilesQuery,
  useSimilarDataRoomFilesQuery,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { authSelectors } from "@/src/store/auth/selector";
import { classNames } from "@/src/utils/cn";
import { UseQueryResult } from "@tanstack/react-query";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export function MergeDocuments() {
  const client = useGqlClient();
  const activeDeal = useSelector(authSelectors.activeDeal);
  const similarDocumentsQuery = useSimilarDataRoomFilesQuery(client, {
    dataRoomFileID: null,
    dataRoomID: activeDeal?.dataRoom?.id,
  });

  return (
    <PageContent>
      <div className="flex flex-col h-full">
        <SimilarDocumentsContent query={similarDocumentsQuery} />
      </div>
    </PageContent>
  );
}

function SimilarDocumentsContent(props: {
  query: UseQueryResult<SimilarDataRoomFilesQuery, unknown>;
}) {
  const [tab, setTab] = useState<"open" | "resolved">("open");

  if (props.query.error) {
    return (
      <div className="flex flex-col h-full">
        <p className="text-gray-500 text-sm font-semibold">
          Error loading similar documents
        </p>
      </div>
    );
  }

  if (props.query.isPending || !props.query.data) {
    return <Loading />;
  }

  if (props.query.data.similarDataRoomFiles.length === 0) {
    return (
      <div className="flex flex-col h-full">
        <p className="text-gray-500 text-sm font-semibold">
          No similar documents found
        </p>
      </div>
    );
  }

  const selectedStatuses =
    tab === "open"
      ? [DataRoomFileSimilarityStatus.Detected]
      : [
          DataRoomFileSimilarityStatus.Rejected,
          DataRoomFileSimilarityStatus.Accepted,
        ];

  return (
    <div className="flex flex-col xl:w-1/2">
      <div className="flex items-end justify-between">
        <p className="text-gray-700 font-semibold">Similar documents</p>
        <div className="flex space-x-4">
          <button
            onClick={() => setTab("open")}
            className={classNames(
              "px-4 py-1 text-sm font-medium rounded-md",
              tab === "open"
                ? "bg-gray-200 text-gray-900"
                : "text-gray-500 hover:text-gray-700",
            )}
          >
            Open
          </button>
          <button
            onClick={() => setTab("resolved")}
            className={classNames(
              "px-4 py-1 text-sm font-medium rounded-md",
              tab === "resolved"
                ? "bg-gray-200 text-gray-900"
                : "text-gray-500 hover:text-gray-700",
            )}
          >
            Resolved
          </button>
        </div>
      </div>
      <Card margin="m 0 0 0">
        <div className="max-h-[500px] overflow-y-auto scrollbar-thin">
          {props.query.data.similarDataRoomFiles
            .filter((similarDocument) =>
              selectedStatuses.includes(similarDocument.status),
            )
            .map((similarDocument, i) => (
              <NavLink
                key={similarDocument.id}
                to={`/deal/toolkit/merge-documents/${similarDocument.dataRoomFile1.id}/${similarDocument.dataRoomFile2.id}`}
              >
                <div
                  key={similarDocument.id}
                  className={classNames(
                    "bg-white py-2.5 px-3 border-b border-gray-200 hover:bg-gray-50",
                    i ===
                      props.query.data!.similarDataRoomFiles.filter(
                        (similarDocument) =>
                          selectedStatuses.includes(similarDocument.status),
                      ).length -
                        1
                      ? "rounded-b-md border-none"
                      : "",
                    i === 0 ? "rounded-t-md" : "",
                  )}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-gray-700 text-md font-semibold">
                        {similarDocument.similarity * 100}% similarity
                      </p>
                      <p className="text-gray-500 text-xs">
                        Detected{" "}
                        {formatDistanceToNowStrict(
                          fromUnixTime(similarDocument.createdAt),
                          { addSuffix: true },
                        )}
                      </p>
                      <Pills margin="m 0 0 0">
                        <FilePill
                          id={similarDocument.dataRoomFile1.id}
                          name={similarDocument.dataRoomFile1.name}
                          type={similarDocument.dataRoomFile1.fileType}
                        />
                        <FilePill
                          id={similarDocument.dataRoomFile2.id}
                          name={similarDocument.dataRoomFile2.name}
                          type={similarDocument.dataRoomFile2.fileType}
                        />
                      </Pills>
                    </div>
                    <div className="flex items-start space-x-1">
                      <SimilarityStatus status={similarDocument.status} />
                    </div>
                  </div>
                </div>
              </NavLink>
            ))}
        </div>
      </Card>
    </div>
  );
}

function SimilarityStatus(props: { status: DataRoomFileSimilarityStatus }) {
  return (
    <div className="flex items-center space-x-1">
      <p
        className={classNames(
          "font-semibold text-sm",
          props.status === DataRoomFileSimilarityStatus.Rejected
            ? "text-red-700/80"
            : props.status === DataRoomFileSimilarityStatus.Accepted
              ? "text-green-700/80"
              : "text-orange-700/80",
        )}
      >
        {props.status === DataRoomFileSimilarityStatus.Rejected
          ? "Rejected"
          : props.status === DataRoomFileSimilarityStatus.Accepted
            ? "Merged"
            : "Needs resolved"}
      </p>
    </div>
  );
}
