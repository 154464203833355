import { DeepSearchThreadMessageUpdatedData } from "@/src/contexts/websockets";
import { AppState } from "..";

function showSidebar(state: AppState, deepSearchId: string): boolean {
  if (state.deepSearch.showSidebar[deepSearchId] === undefined) {
    return true;
  }

  return state.deepSearch.showSidebar[deepSearchId];
}

function deepThreadMessage(
  state: AppState,
  deepSearchId: string,
  messageId: string
): DeepSearchThreadMessageUpdatedData | undefined {
  return state.deepSearch.deepSearchThreads[deepSearchId].messages.find(
    (message) => message.deepSearchThreadMessageID === messageId
  );
}

function deepSearchThreadMessages(
  state: AppState,
  deepSearchId: string
): DeepSearchThreadMessageUpdatedData[] {
  if (!state.deepSearch.deepSearchThreads[deepSearchId]) {
    return [];
  }

  return state.deepSearch.deepSearchThreads[deepSearchId].messages;
}

export const deepSearchSelectors = {
  showSidebar,
  deepSearchThreadMessages,
  deepThreadMessage,
};
