import { Switch, Route } from "react-router-dom";
import { Tasks } from "../index";
import { Task } from "../task";
import { useState } from "react";

import { Option } from "../../../components/tailwind/Dropdown";
import { DealStatus, TasksFilters } from "../../../graphql/generated";

function TasksParent() {
  const [selectedDeal, setSelectedDeal] = useState<Option | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<Option | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<Option | null>({ label: "Active", value: DealStatus.Active });
  const [showActivity, setShowActivity] = useState(false);
  const [filters, setFilters] = useState<TasksFilters>({dealStatus: DealStatus.Active});
  return (
    <Switch>
      <Route path="/tasks" exact>
        <Tasks
          selectedDeal={selectedDeal}
          selectedAccount={selectedAccount}
          selectedStatus={selectedStatus}
          setSelectedDeal={setSelectedDeal}
          setSelectedAccount={setSelectedAccount}
          setSelectedStatus={setSelectedStatus}
          showActivity={showActivity}
          setShowActivity={setShowActivity}
          filters={filters}
          setFilters={setFilters}
        />
      </Route>

      <Route path="/tasks/:id">
        <Task />
      </Route>
    </Switch>
  );
}

export default TasksParent;