export function apiEndpoint() {
  let endpoint = "http://localhost:8080/graphql";

  if (import.meta.env.PROD) {
    if (window.location.hostname === "uk.liquidacquire.com") {
      endpoint = "https://uk.api.liquidacquire.com/graphql";
    }

    if (window.location.hostname === "us.liquidacquire.com") {
      endpoint = "https://us.api.liquidacquire.com/graphql";
    }
  }

  return endpoint;
}

export function wsEndpoint() {
  let endpoint = "ws://localhost:8080/ws";

  if (import.meta.env.PROD) {
    if (window.location.hostname === "uk.liquidacquire.com") {
      endpoint = "wss://uk.api.liquidacquire.com/ws";
    }

    if (window.location.hostname === "us.liquidacquire.com") {
      endpoint = "wss://us.api.liquidacquire.com/ws";
    }
  }

  return endpoint;
}

export function pspdfkitEndpoint() {
  let endpoint = "http://localhost:5001/";

  if (import.meta.env.PROD) {
    if (window.location.hostname === "uk.liquidacquire.com") {
      endpoint = "https://eu.documentengine.liquidacquire.com/";
    }

    if (window.location.hostname === "us.liquidacquire.com") {
      endpoint = "https://us.documentengine.liquidacquire.com/";
    }
  }

  return endpoint;
}

export function pspdfkitDocumentProxyEndpoint() {
  let endpoint = "http://localhost:8080/pspdfkit/proxy";

  if (import.meta.env.PROD) {
    if (window.location.hostname === "uk.liquidacquire.com") {
      endpoint = "https://u.api.liquidacquire.com/pspdfkit/proxy";
    }

    if (window.location.hostname === "us.liquidacquire.com") {
      endpoint = "https://us.api.liquidacquire.com/pspdfkit/proxy";
    }
  }

  return endpoint;
}

export function pspdfkitLicenseKeyEnvVar() {
  let licenseKey = undefined;

  if (import.meta.env.PROD) {
    if (window.location.hostname === "uk.liquidacquire.com") {
      return import.meta.env.VITE_PSPDFKIT_LICENSE_KEY_UK;
    }

    if (window.location.hostname === "us.liquidacquire.com") {
      return import.meta.env.VITE_PSPDFKIT_LICENSE_KEY_US;
    }
  }

  return licenseKey;
}
