import React, { useEffect, useState } from "react";
import { Card } from "../../components/Card";
import { H3 } from "../../components/Heading";
import Loading from "../../components/Loading";
import {
  DataRoomFolderFragmentFragment,
  DataRoomPermission,
  useDataRoomFolderQuery,
  useDataRoomQuery,
  useDealActivityQuery,
  FileType,
  DealRole,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { FolderIcon } from "@heroicons/react/24/solid";
import { FileIcon } from "../../components/FileIcon";
import { DeleteFile } from "./DeleteFileModal";
import { DataRoomHeader } from "../../components/data_room/DataRoomHeader";
import { EditFile } from "../../components/data_room/EditFile";
import { EditFolder } from "../../components/data_room/EditFolder";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { FolderOpenIcon } from "@heroicons/react/20/solid";
import {
  DealRoleRestricted,
  adminRoles,
  guestRoles,
} from "../../components/DealRoleRestricted";
import { ActivityItems } from "../../components/activity/ActivityItems";
import { MoveFileModal } from "./MoveFileModal";
import { Pills } from "../../components/Pills";
import { Tooltip } from "../../components/Tooltip";
import { UploadContextProvider } from "@/src/components/data_room/file_uploaders/useUploadContext";
import { MoveFolderModal } from "./MoveFolderModal";
import { MoveItemsModal } from "./MoveItemsModal";
import { DeleteItemsModal } from "./DeleteItemsModal";
import { DragDropContext } from "@hello-pangea/dnd";
import DeleteFolderModal from "./DeleteFolderModal";
import { DroppableFiles, NonDroppableFiles } from "./DroppableFiles";
import { DroppableFolders, NonDroppableFolders } from "./DroppableFolders";
import { useShiftSelection } from "../../hooks/useShiftSelection";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface Key {
  icon: React.ReactNode;
  name: string;
  tooltip?: string;
}

export function DataRoom() {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const dataRoomQuery = useDataRoomQuery(client, {
    dealId: activeDealId ? activeDealId : "",
  });

  const { folderId } = useParams<{ folderId: string }>();

  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  const [triggerMoveItems, setTriggerMoveItems] = useState<boolean>(false);
  const [triggerDeleteItems, setTriggerDeleteItems] = useState<boolean>(false);
  if (!dataRoomQuery.data || dataRoomQuery.isPending) {
    return <Loading />;
  }

  if (dataRoomQuery.error) {
    return <div>Something went wrong</div>;
  }

  const keys: Key[] = [
    {
      icon: <FolderIcon className="h-5 w-5 text-blue-700/70" />,
      name: "Folder",
    },
    {
      icon: <FileIcon fileType={FileType.Pdf} />,
      name: "PDF",
    },
    {
      icon: <FileIcon fileType={FileType.Xlsx} />,
      name: "Excel",
    },
    {
      icon: <FileIcon fileType={FileType.Docx} />,
      name: "Word",
    },
    {
      icon: <FileIcon fileType={FileType.Pptx} />,
      name: "Powerpoint",
    },
    {
      icon: <FileIcon fileType={FileType.Zip} />,
      name: "Zip",
    },
    {
      icon: <FileIcon fileType={FileType.Other} />,
      name: "Unknown",
    },
  ];

  return (
    <div className="flex-1 select-none">
      <UploadContextProvider>
        <DataRoomHeader
          folderId={
            folderId ? folderId : dataRoomQuery.data.deal.dataRoom.folder.id
          }
          permissions={dataRoomQuery.data.deal.dataRoom.permissions}
          recentSearchesKey={dataRoomQuery.data.deal.id}
          dataRoomId={dataRoomQuery.data.deal.dataRoom.id}
          root="deal/documents"
          title="Documents"
          selectedFolders={selectedFolders}
          selectedFiles={selectedFiles}
          setSelectedFolders={setSelectedFolders}
          setSelectedFiles={setSelectedFiles}
          setTriggerMoveItems={setTriggerMoveItems}
          setTriggerDeleteItems={setTriggerDeleteItems}
        />
      </UploadContextProvider>
      <div className="p-8 pt-4">
        <Folder
          id={folderId ? folderId : dataRoomQuery.data.deal.dataRoom.folder.id}
          permissions={dataRoomQuery.data.deal.dataRoom.permissions}
          selectedFolders={selectedFolders}
          selectedFiles={selectedFiles}
          setSelectedFolders={setSelectedFolders}
          setSelectedFiles={setSelectedFiles}
          triggerMoveItems={triggerMoveItems}
          setTriggerMoveItems={setTriggerMoveItems}
          triggerDeleteItems={triggerDeleteItems}
          setTriggerDeleteItems={setTriggerDeleteItems}
        />
        <Pills>
          {keys.map((key) => {
            return (
              <div
                key={key.name}
                className="rounded-md border border-gray-200 gap-x-2 bg-white  px-2 py-1 flex items-center"
              >
                {key.icon}
                <p className="text-sm text-gray-700 mt-0.5 font-medium">
                  {key.name}
                </p>
                {key.tooltip && (
                  <Tooltip text={key.tooltip} width="w-[18rem]" />
                )}
              </div>
            );
          })}
        </Pills>
        <DealRoleRestricted
          roles={adminRoles.concat([DealRole.DealAdminViewOnly])}
        >
          <div className="w-full mt-8 xl:w-1/2">
            <div>
              <div className="flex justify-between items-center">
                <H3 margin="0">Recent activity</H3>
                <Link to="/deal/documents/activity">
                  <p className="text-xs font-semibold text-blue-500">
                    View all
                  </p>
                </Link>
              </div>
              <Activity
                dataRoomId={dataRoomQuery.data.deal.dataRoom.id}
                dataRoomFolderId={folderId}
              />
            </div>
            <div>
              <FolderMeta folderId={folderId} />
            </div>
          </div>
        </DealRoleRestricted>
      </div>
    </div>
  );
}

function FolderMeta(props: { folderId: string }) {
  const client = useGqlClient();
  const folderQuery = useDataRoomFolderQuery(client, {
    id: props.folderId,
  });

  if (folderQuery.isPending) {
    return null;
  }

  if (folderQuery.error || !folderQuery.data) {
    return null;
  }

  if (!folderQuery.data.dataRoomFolder.dealGroup) {
    return null;
  }

  return (
    <Card padding="m">
      <div className="flex items-center gap-x-2">
        <FolderIcon className="h-6 w-6 text-orange-700/70" />
        <p className="font-semibold text-gray-700 mt-1">Buyer folder</p>
      </div>
      <p className="text-sm text-gray-500 mt-2">
        This is a buyer folder for{" "}
        <span className="font-semibold">
          {folderQuery.data.dataRoomFolder.dealGroup.name}
        </span>
        .<br />
        They can manage files and folders in this folder.
      </p>
    </Card>
  );
}

interface FolderProps {
  id: string;
  permissions: DataRoomPermission[];
  selectedFolders: string[];
  selectedFiles: string[];
  setSelectedFolders: (folders: string[]) => void;
  setSelectedFiles: (files: string[]) => void;
  triggerMoveItems: boolean;
  setTriggerMoveItems: (trigger: boolean) => void;
  triggerDeleteItems: boolean;
  setTriggerDeleteItems: (trigger: boolean) => void;
}

function Folder(props: FolderProps) {
  const client = useGqlClient();
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const folderQuery = useDataRoomFolderQuery(client, {
    id: props.id,
  });

  return (
    <>
      <Card>
        <div className="h-96 overflow-y-scroll no-scrollbar">
          <Files
            isLoading={folderQuery.isPending}
            error={folderQuery.error}
            folder={
              folderQuery.data ? folderQuery.data.dataRoomFolder : undefined
            }
            selectedFolders={props.selectedFolders}
            selectedFiles={props.selectedFiles}
            setSelectedFolders={props.setSelectedFolders}
            setSelectedFiles={props.setSelectedFiles}
            triggerMoveItems={props.triggerMoveItems}
            setTriggerMoveItems={props.setTriggerMoveItems}
            triggerDeleteItems={props.triggerDeleteItems}
            setTriggerDeleteItems={props.setTriggerDeleteItems}
          />
        </div>
      </Card>

      <DeleteFolderModal
        open={
          query.get("deleteFolder") !== null && query.get("deleteFolder") !== ""
        }
        onClose={() => {
          history.replace({ pathname: location.pathname, search: "" });
        }}
      />
    </>
  );
}

interface FilesProps {
  folder?: DataRoomFolderFragmentFragment;
  isLoading: boolean;
  error?: unknown;
  selectedFolders: string[];
  selectedFiles: string[];
  setSelectedFolders: (folders: string[]) => void;
  setSelectedFiles: (files: string[]) => void;
  triggerMoveItems: boolean;
  setTriggerMoveItems: (trigger: boolean) => void;
  triggerDeleteItems: boolean;
  setTriggerDeleteItems: (trigger: boolean) => void;
}

function Files(props: FilesProps) {
  const history = useHistory();
  const location = useLocation();

  const [deleteFileId, setDeleteFileId] = useState<string>("");
  const [editFileId, setEditFileId] = useState<string>("");
  const [moveFileId, setMoveFileId] = useState<string>("");
  const [editFolderId, setEditFolderId] = useState<string>("");
  const [moveFolderId, setMoveFolderId] = useState<string>("");
  const [globalDragging, setGlobalDragging] = useState<boolean>(false);

  const [fileToFolderMove, setFileToFolderMove] = useState<{
    fileId: string;
    targetFolderId: string;
  } | null>(null);

  const [folderToFolderMove, setFolderToFolderMove] = useState<{
    folderId: string;
    targetFolderId: string;
    targetFolderName: string;
  } | null>(null);

  const [folderBeingDraggedOver, setFolderBeingDraggedOver] = useState<
    string | null
  >(null);

  const [folderDragStates, setFolderDragStates] = useState<
    Record<string, boolean>
  >({});

  const [multiItemMove, setMultiItemMove] = useState<{
    fileIds: string[];
    folderIds: string[];
    targetFolderId: string;
    targetFolderName: string;
  } | null>(null);

  // Use the shared shift selection hook for folders
  const {
    handleItemSelection: handleFolderSelectionInternal,
    handleMouseDown,
  } = useShiftSelection(
    props.folder?.folders || [],
    props.selectedFolders,
    props.setSelectedFolders,
  );

  // Use the shared shift selection hook for files
  const { handleItemSelection: handleFileSelectionInternal } =
    useShiftSelection(
      props.folder?.files || [],
      props.selectedFiles,
      props.setSelectedFiles,
    );

  // Wrapper functions to maintain the same API for component props
  const handleFolderSelection = (
    folderId: string,
    shiftKey: boolean,
    e: React.MouseEvent,
  ) => {
    handleFolderSelectionInternal(folderId, shiftKey, e);
  };

  const handleFileSelection = (
    fileId: string,
    shiftKey: boolean,
    e: React.MouseEvent,
  ) => {
    handleFileSelectionInternal(fileId, shiftKey, e);
  };

  // Use effect to handle drag state changes outside of render
  useEffect(() => {
    const draggedOverFolder =
      Object.entries(folderDragStates).find(
        ([_, isDraggingOver]) => isDraggingOver,
      )?.[0] || null;

    if (draggedOverFolder !== folderBeingDraggedOver) {
      setFolderBeingDraggedOver(draggedOverFolder);
    }
  }, [folderDragStates, folderBeingDraggedOver]);

  const updateFolderDragState = (folderId: string, isDraggingOver: boolean) => {
    setFolderDragStates((prev) => ({
      ...prev,
      [folderId]: isDraggingOver,
    }));
  };

  if (props.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (props.isLoading || !props.folder) {
    return <Loading />;
  }

  if (props.folder.files.length === 0 && props.folder.folders.length === 0) {
    return (
      <div className="h-96 flex justify-center items-center flex-col">
        <FolderOpenIcon className="h-12 w-12 text-gray-500/70" />
        <p className="m-0 font-semibold text-gray-700">
          You haven't uploaded any files yet
        </p>
        <p className="text-sm text-gray-500">
          Files and folders you create will appear here
        </p>
      </div>
    );
  }

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId, type } = result;

    console.log(result);

    // If dropped outside the list, no changes needed
    if (!destination) {
      return;
    }

    // Check if we're dragging a file or folder over a folder
    if (
      type === "FILE-FOLDER" &&
      destination.droppableId !== "files" &&
      destination.droppableId !== "folders" &&
      props.folder
    ) {
      const targetFolderId = destination.droppableId.startsWith("folder-")
        ? destination.droppableId.replace("folder-", "")
        : destination.droppableId;

      const targetFolder = props.folder.folders.find(
        (folder) => folder.id === targetFolderId,
      );

      if (!targetFolder) {
        return;
      }

      const extractFileId = (id: string) =>
        id.startsWith("dtrmfile-") ? id.replace("dtrmfile-", "") : id;
      const extractFolderId = (id: string) =>
        id.startsWith("dtrmfolder-") ? id.replace("dtrmfolder-", "") : id;

      // Check if the dragged item is a file
      if (draggableId.startsWith("dtrmfile")) {
        const fileId = extractFileId(draggableId);

        if (props.selectedFolders.includes(targetFolderId)) {
          return;
        }

        if (
          props.selectedFiles.includes(fileId) ||
          props.selectedFiles.includes(draggableId)
        ) {
          if (
            props.selectedFiles.length > 1 ||
            props.selectedFolders.length > 0
          ) {
            const cleanFileIds = props.selectedFiles.map(extractFileId);
            const cleanFolderIds = props.selectedFolders.map(extractFolderId);

            setMultiItemMove({
              fileIds: cleanFileIds,
              folderIds: cleanFolderIds,
              targetFolderId: targetFolderId,
              targetFolderName: targetFolder.name,
            });
            return;
          } else {
            setFileToFolderMove({
              fileId: fileId,
              targetFolderId: targetFolderId,
            });
            return;
          }
        } else {
          setFileToFolderMove({
            fileId: fileId,
            targetFolderId: targetFolderId,
          });
          return;
        }
      }

      // Check if the dragged item is a folder
      if (draggableId.startsWith("dtrmfolder")) {
        const folderId = extractFolderId(draggableId);

        if (props.selectedFolders.includes(targetFolderId)) {
          return;
        }

        if (
          props.selectedFolders.includes(folderId) ||
          props.selectedFolders.includes(draggableId)
        ) {
          if (
            props.selectedFolders.length > 1 ||
            props.selectedFiles.length > 0
          ) {
            const cleanFileIds = props.selectedFiles.map(extractFileId);
            const cleanFolderIds = props.selectedFolders.map(extractFolderId);

            setMultiItemMove({
              fileIds: cleanFileIds,
              folderIds: cleanFolderIds,
              targetFolderId: targetFolderId,
              targetFolderName: targetFolder.name,
            });
            return;
          } else {
            setFolderToFolderMove({
              folderId: folderId,
              targetFolderId: targetFolderId,
              targetFolderName: targetFolder.name,
            });
            return;
          }
        } else {
          setFolderToFolderMove({
            folderId: folderId,
            targetFolderId: targetFolderId,
            targetFolderName: targetFolder.name,
          });
          return;
        }
      }
    }

    // If dropped at the same position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <table
          className="table-fixed w-full box-border text-left border-collapse block select-none"
          onMouseDown={handleMouseDown}
        >
          <tbody className="w-full table">
            <tr>
              <th
                className="text-sm py-2 px-3 text-gray-500/80 truncate"
                style={{ width: "100%" }}
              >
                Name
              </th>
              <DealRoleRestricted roles={adminRoles}>
                <th
                  className="text-sm py-2 px-3 text-gray-500/80 hidden xl:table-cell truncate"
                  style={{ minWidth: 140 }}
                >
                  Created by
                </th>
              </DealRoleRestricted>
              <th
                className="text-sm py-2 px-3 text-gray-500/80 hidden md:table-cell truncate"
                style={{ minWidth: 130 }}
              >
                Last updated
              </th>
              <DealRoleRestricted roles={adminRoles}>
                <th
                  className="text-sm py-2 px-3 text-gray-500/80 hidden lg:table-cell truncate"
                  style={{ minWidth: 130 }}
                >
                  Access level
                </th>
                <th className="py-1 px-2 w-55 text-gray-500 hidden xl:table-cell"></th>
              </DealRoleRestricted>
            </tr>

            <DealRoleRestricted roles={adminRoles}>
              <DroppableFolders
                folder={props.folder}
                selectedFolders={props.selectedFolders}
                setSelectedFolders={props.setSelectedFolders}
                selectedFiles={props.selectedFiles}
                setSelectedFiles={props.setSelectedFiles}
                handleMouseDown={handleMouseDown}
                handleFolderSelection={handleFolderSelection}
                setEditFolderId={setEditFolderId}
                setMoveFolderId={setMoveFolderId}
                folderDragStates={folderDragStates}
                updateFolderDragState={updateFolderDragState}
                folderBeingDraggedOver={folderBeingDraggedOver}
                globalDragging={globalDragging}
                setGlobalDragging={setGlobalDragging}
              />
              <DroppableFiles
                folder={props.folder}
                selectedFiles={props.selectedFiles}
                setSelectedFiles={props.setSelectedFiles}
                selectedFolders={props.selectedFolders}
                setSelectedFolders={props.setSelectedFolders}
                handleMouseDown={handleMouseDown}
                handleFileSelection={handleFileSelection}
                setEditFileId={setEditFileId}
                setMoveFileId={setMoveFileId}
                setDeleteFileId={setDeleteFileId}
                globalDragging={globalDragging}
                setGlobalDragging={setGlobalDragging}
              />
            </DealRoleRestricted>
            <DealRoleRestricted roles={guestRoles}>
              <NonDroppableFolders folder={props.folder} />
              <NonDroppableFiles folder={props.folder} />
            </DealRoleRestricted>
          </tbody>
        </table>
      </DragDropContext>
      <DeleteFile
        open={deleteFileId !== ""}
        dataRoomFileId={deleteFileId}
        onClose={() => {
          setDeleteFileId("");
        }}
      />
      <EditFile
        dataRoomFileId={editFileId}
        open={editFileId !== ""}
        onClose={() => {
          setEditFileId("");
        }}
      />
      <MoveFileModal
        dataRoomFileId={fileToFolderMove ? fileToFolderMove.fileId : moveFileId}
        open={moveFileId !== "" || fileToFolderMove !== null}
        targetFolderId={
          fileToFolderMove ? fileToFolderMove.targetFolderId : undefined
        }
        onClose={() => {
          setMoveFileId("");
          setFileToFolderMove(null);
        }}
        onMoved={() => {
          props.setSelectedFiles([]);
        }}
      />
      <EditFolder
        dataRoomFolderId={editFolderId}
        open={editFolderId !== ""}
        onClose={() => {
          setEditFolderId("");
        }}
      />
      <MoveFolderModal
        dataRoomFolderId={
          folderToFolderMove ? folderToFolderMove.folderId : moveFolderId
        }
        open={moveFolderId !== "" || folderToFolderMove !== null}
        targetFolderId={
          folderToFolderMove ? folderToFolderMove.targetFolderId : undefined
        }
        targetFolderName={
          folderToFolderMove ? folderToFolderMove.targetFolderName : undefined
        }
        onClose={() => {
          setMoveFolderId("");
          setFolderToFolderMove(null);
        }}
        onMoved={() => {
          props.setSelectedFolders([]);
        }}
      />
      <MoveItemsModal
        dataRoomFileIds={
          multiItemMove ? multiItemMove.fileIds : props.selectedFiles
        }
        dataRoomFolderIds={
          multiItemMove ? multiItemMove.folderIds : props.selectedFolders
        }
        open={props.triggerMoveItems || multiItemMove !== null}
        targetFolderId={
          multiItemMove ? multiItemMove.targetFolderId : undefined
        }
        targetFolderName={
          multiItemMove ? multiItemMove.targetFolderName : undefined
        }
        onClose={() => {
          if (multiItemMove) {
            setMultiItemMove(null);
          } else {
            props.setTriggerMoveItems(false);
          }
          props.setSelectedFiles([]);
          props.setSelectedFolders([]);
        }}
      />
      <DeleteItemsModal
        dataRoomFileIds={props.selectedFiles}
        dataRoomFolderIds={props.selectedFolders}
        open={props.triggerDeleteItems}
        onClose={() => {
          props.setTriggerDeleteItems(false);
          props.setSelectedFiles([]);
          props.setSelectedFolders([]);
        }}
        onDelete={() => {
          props.setTriggerDeleteItems(false);
          props.setSelectedFiles([]);
          props.setSelectedFolders([]);
        }}
      />
    </>
  );
}

interface ActivityProps {
  dataRoomId: string;
  dataRoomFolderId?: string;
}

function Activity(props: ActivityProps) {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const { data, isLoading, error, refetch } = useDealActivityQuery(client, {
    dealId: activeDealId ?? "",
    input: {
      dataRoomID: props.dataRoomId,
      dataRoomFolderID: props.dataRoomFolderId,
      count: 30,
      cursor: "",
    },
  });

  if (error) {
    return <div>Error</div>;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  if (data.dealActivity.activity.length === 0) {
    return (
      <div>
        <p className="text-sm text-gray-500">No recent activity</p>
      </div>
    );
  }

  return (
    <ActivityItems
      margin="l 0 0 0"
      items={data.dealActivity.activity}
      mode="verbose"
    />
  );
}
