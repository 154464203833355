import { OnboardingBody } from ".";
import { TextInput } from "@/src/components/tailwind/TextInput";
import { useDispatch } from "react-redux";
import { FirmType, useAccountQuery } from "@/src/graphql/generated";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import useGqlClient from "@/src/hooks/useGqlClient";
import Dropdown, { Option } from "@/src/components/tailwind/Dropdown";
import { useCreateFirmMutation } from "@/src/graphql/generated";
import { formatFirmType } from "@/src/utils/enums";
import { Button } from "@/src/components/tailwind/Button";
import { actions } from "@/src/store/auth/slice";
import { useHistory } from "react-router-dom";
import { H3 } from "@/src/components/Heading";
import { toasts } from "@/src/components/toasts/toasts";

const options: Option[] = [
  {
    value: FirmType.LawFirm,
    label: formatFirmType(FirmType.LawFirm),
  },
  {
    value: FirmType.Accountant,
    label: formatFirmType(FirmType.Accountant),
  },
  {
    value: FirmType.Broker,
    label: formatFirmType(FirmType.Broker),
  },
  {
    value: FirmType.Buyer,
    label: formatFirmType(FirmType.Buyer),
  },
  {
    value: FirmType.Seller,
    label: formatFirmType(FirmType.Seller),
  },
  {
    value: FirmType.Other,
    label: formatFirmType(FirmType.Other),
  },
];

export function CreateFirm() {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [website, setWebsite] = useState("");

  const [address, setAddress] = useState("");

  const [type, setType] = useState<Option>(options[0]);

  const queryClient = useQueryClient();
  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);
  const createFirmMutation = useCreateFirmMutation(client);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="flex flex-1 flex-col">
      <OnboardingBody>
        <div className="flex flex-col  text-left">
          <H3 margin="0">Create your firm</H3>
          <p className="text-sm text-gray-500/80">
            Welcome to Liquid! The first thing you need to do is setup a firm so
            you can invite your colleagues and start setting up deals.
          </p>
        </div>

        <div className="w-full  mt-3 border border-concrete-200 shadow-lg p-4 rounded-md bg-white">
          <div className=" flex flex-col">
            <form
              className="mt-3 flex flex-col flex-1"
              onSubmit={(e) => {
                e.preventDefault();
                if (!name) {
                  if (!name) {
                    setNameError("Name is required");
                  }

                  return;
                }

                createFirmMutation.mutate(
                  {
                    input: {
                      name,
                      description,
                      website,
                      address,
                      type: type.value as FirmType,
                    },
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: ["Account", {}],
                      });

                      accountQuery.refetch().then((data) => {
                        if (data.data) {
                          dispatch(
                            actions.refreshAccount({
                              account: data.data.account,
                            })
                          );
                          history.push(`/onboarding/invite-firm-members`);
                          toasts.success("Firm created successfully");
                        }
                      });
                    },
                  }
                );
              }}
            >
              <div className="flex flex-1 flex-col">
                <TextInput
                  ignore1p
                  label="Name"
                  placeholder="Enter firm name..."
                  onChange={(e) => {
                    setNameError("");
                    setName(e.currentTarget.value);
                  }}
                />

                <div className="mt-6 flex items-center gap-x-4">
                  <div>
                    <label
                      htmlFor="firm_type_dropdown"
                      className="block text-sm mb-1  font-medium leading-6 text-gray-900"
                    >
                      Firm type
                    </label>
                    <Dropdown
                      size="s"
                      options={options}
                      onSelect={(e) => {
                        setType(e);
                      }}
                      selectedOption={type}
                    />
                  </div>
                  <div className="min-w-72">
                    <TextInput
                      margin="0 0 0 0"
                      inputSize="s"
                      secondaryLabel="Optional"
                      label="Website"
                      placeholder="Enter firm website..."
                      value={website}
                      onChange={(e) => {
                        setWebsite(e.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className=" mt-6 flex  justify-end">
                <Button
                  variant="positive"
                  text="Create firm"
                  size="s"
                  loadingText="Creating..."
                  type="submit"
                  isLoading={createFirmMutation.isPending}
                />
              </div>
            </form>
          </div>
        </div>
      </OnboardingBody>
    </div>
  );
}
