import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react/jsx-runtime";
import { classNames } from "../utils/cn";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { useRef } from "react";

interface SlideOverProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  maxWidth?: string;
  withBackground?: boolean;
  withOutsideClick?: boolean;
}
export function SlideOver(props: SlideOverProps) {
  if (props.withBackground === false) {
    return <SlideOverNoBackground {...props} />;
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        role="dialog"
        as="div"
        className="relative z-50"
        onClose={props.onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={classNames(
                    "pointer-events-auto w-screen",
                    props.maxWidth ? props.maxWidth : "max-w-md",
                  )}
                >
                  <div className="flex h-full flex-col overflow-y-scroll no-scrollbar bg-white border-b border-gray z-50 shadow-xl">
                    <div className="relative overflow-y-scroll  flex-1 ">
                      {props.children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function SlideOverNoBackground(props: SlideOverProps) {
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    if (props.withOutsideClick) {
      props.onClose();
    }
  });
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <div
        ref={ref}
        className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
      >
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-300 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-300 sm:duration-500"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div
            className={classNames(
              "pointer-events-auto w-screen",
              props.maxWidth ? props.maxWidth : "max-w-md",
            )}
          >
            <div className="flex h-full flex-col overflow-y-scroll no-scrollbar bg-white border-b border-gray z-50 shadow-xl">
              <div className="relative overflow-y-scroll  flex-1 ">
                {props.children}
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
}
