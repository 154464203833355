import { AccessControlDealGroupFragment } from "@/src/graphql/generated";
import { authSelectors } from "@/src/store/auth/selector";
import { classNames } from "@/src/utils/cn";
import { formatEnum } from "@/src/utils/enums";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useSelector } from "react-redux";
import { TripleDotMenu } from "../TripleDotMenu";
import { Menu } from "@headlessui/react";
import { EditAccessModal } from "./EditAccessModal";
import { RemoveAccessModal } from "./RemoveAccessModal";
import { Avatar } from "../account/Avatar";

export function AccessControlGroupItem(props: {
  group: AccessControlDealGroupFragment;
  resourceId: string;
  onRemove: () => void;
  onEdit: () => void;
}) {
  const activeDealAccount = useSelector(authSelectors.activeDealAccount);
  const [openModal, setOpenModal] = useState<
    "edit_access" | "remove_access" | ""
  >("");
  const group = props.group;
  const queryClient = useQueryClient();
  return (
    <div key={group.dealGroup.id}>
      <div
        className={classNames(
          "w-full pl-3 py-1 border-y border-gray-200 bg-gray-100 flex justify-between items-center",
          activeDealAccount &&
            activeDealAccount.dealGroup &&
            activeDealAccount.dealGroup.id === group.dealGroup.id
            ? "pr-3"
            : "",
        )}
      >
        <p className="text-sm font-semibold text-gray-600">
          {group.dealGroup.name}
        </p>
        <div className="flex items-center gap-x-1">
          <p className="text-sm text-gray-500">
            {formatEnum(group.accessControl.permissionLevel)}
          </p>
          {activeDealAccount &&
          activeDealAccount.dealGroup &&
          activeDealAccount.dealGroup.id !== group.dealGroup.id ? (
            <TripleDotMenu>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-200" : "",
                      "w-full text-left px-3 py-2 text-sm text-gray-500 hover:text-gray-600 font-semibold",
                    )}
                    onClick={() => setOpenModal("edit_access")}
                  >
                    Edit access
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-200" : "",
                      "w-full text-left px-3 py-2 text-sm text-red-500 hover:text-red-600 font-semibold",
                    )}
                    onClick={() => setOpenModal("remove_access")}
                  >
                    Remove access
                  </button>
                )}
              </Menu.Item>
            </TripleDotMenu>
          ) : null}
        </div>
      </div>
      <div className="p-3 space-y-3">
        {group.accessControlDealAccounts.map((account) => {
          return (
            <AccessControlItem key={account.dealAccount.id} account={account} />
          );
        })}
      </div>
      <EditAccessModal
        open={openModal === "edit_access"}
        onClose={() => {
          props.onEdit();
          setOpenModal("");
        }}
        resourceId={props.resourceId}
        accessControlDealGroup={group}
      />
      <RemoveAccessModal
        open={openModal === "remove_access"}
        onClose={() => {
          setOpenModal("");
          props.onRemove();
        }}
        resourceId={props.resourceId}
        accessControlDealGroup={group}
      />
    </div>
  );
}

export function AccessControlItem(props: {
  account: AccessControlDealGroupFragment["accessControlDealAccounts"][0];
}) {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-x-2">
        <Avatar account={props.account.dealAccount.account} size="s" />
        <p className="text-sm font-semibold text-gray-600">
          {props.account.dealAccount.account.name}
        </p>
      </div>
      <p className="text-sm text-gray-500">
        {formatEnum(props.account.accessControl.permissionLevel)}
      </p>
    </div>
  );
}
