import { useDealThreadsQuery } from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { authSelectors } from "@/src/store/auth/selector";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Loading";
import { actions } from "@/src/store/assistant/slice";
import { Popover } from "@headlessui/react";
import { HistoryIcon } from "lucide-react";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";

export function PreviousChatsContent() {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const dealThreadsQuery = useDealThreadsQuery(client, {
    dealId: activeDealId ?? "",
  });
  const dispatch = useDispatch();

  if (dealThreadsQuery.error) {
    return (
      <div className="p-3">
        <p className="text-sm text-gray-500">Error loading previous chats</p>
      </div>
    );
  }

  if (dealThreadsQuery.isLoading || !dealThreadsQuery.data) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    );
  }

  if (dealThreadsQuery.data.dealThreads.length === 0) {
    return (
      <div className="p-3">
        <p className="text-sm text-gray-500">No previous chats</p>
      </div>
    );
  }

  return (
    <div className="">
      <p className="px-3 text-sm pt-3 text-gray-500">Previous chats</p>

      <div className="max-h-[300px] overflow-y-auto">
        {dealThreadsQuery.data.dealThreads.map((dealThread) => {
          return (
            <button
              key={dealThread.id}
              className="px-3 text-left w-full py-2 hover:bg-gray-100 hover:text-gray-700 "
              onClick={() => {
                dispatch(actions.setActiveDealThreadId(dealThread.id));
              }}
            >
              <p className="text-sm text-gray-500 font-semibold">
                {dealThread.summary
                  ? dealThread.summary.length > 30
                    ? dealThread.summary.slice(0, 30) + "..."
                    : dealThread.summary
                  : "No summary for thread"}
              </p>
              <p className="text-xs text-gray-500/80">
                {formatDistanceToNowStrict(fromUnixTime(dealThread.createdAt), {
                  addSuffix: true,
                })}
              </p>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export function PreviousChats() {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="px-2.5 py-1.5 bg-white hover:shadow-sm border border-gray-300 text-gray-500 hover:text-gray-700 focus:outline-none rounded-md">
            <HistoryIcon className="w-5 h-5" />
          </Popover.Button>

          <Popover.Panel className="absolute z-10 mt-2 w-72 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none right-0">
            <PreviousChatsContent />
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
