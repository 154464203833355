import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { authSelectors } from "../../store/auth/selector";
import { useDealQuery } from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import {
  adminRoles,
  DealRoleRestricted,
  guestAdminRoles,
} from "../DealRoleRestricted";
import {
  BookOpenCheckIcon,
  BotIcon,
  Building2Icon,
  ChartBarIcon,
  CircleAlertIcon,
  CogIcon,
  EyeIcon,
  FileSearch2Icon,
  FilesIcon,
  FolderIcon,
  LayoutDashboardIcon,
  LockKeyholeIcon,
  MergeIcon,
  MessageCircleQuestionIcon,
  PocketKnifeIcon,
  TableProperties,
  UsersIcon,
} from "lucide-react";

import CompanyLogo from "../CompanyLogo";
import { actions as authActions } from "../../store/auth/slice";
import { Avatar } from "../account/Avatar";
import { Link, TopTabNavbar } from "../nav";
import { FeatureFlag } from "../posthog/FeatureFlag";
import { Assistant } from "./assistant";

export function DealNavigation() {
  const [showAssistant, setShowAssistant] = useState(false);
  const location = useLocation();

  const client = useGqlClient();
  const queryClient = useQueryClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const dealQuery = useDealQuery(client, {
    id: activeDealId ? activeDealId : "",
  });

  const activeDeal = useSelector(authSelectors.activeDeal);
  const actor = useSelector(authSelectors.actor);
  const dispatch = useDispatch();
  const history = useHistory();

  const previousActorRef = useRef(actor);

  useEffect(() => {
    if (previousActorRef.current && !actor) {
      const queries = queryClient.getQueryCache().findAll({
        queryKey: ["ActiveDealAccount"],
      });
      for (const query of queries) {
        queryClient.invalidateQueries({ queryKey: query.queryKey });
      }
    }
    previousActorRef.current = actor;
  }, [actor, queryClient]);

  if (!location.pathname.startsWith("/deal")) {
    return null;
  }

  return (
    <div className="sticky top-0 z-20">
      {actor ? (
        <div className="w-full h-12 bg-persian-950/80 px-4 flex items-center justify-center gap-x-2">
          <div className="flex items-center gap-x-3">
            <EyeIcon className="w-4 h-4 text-white" />
            <p className="text-sm flex gap-x-2  items-center text-white font-semibold">
              Viewing deal as
              <span>
                <Avatar account={actor.account} size="xs" />
              </span>
              {actor.account.name}
            </p>
          </div>
          <button
            className="px-2 py-1 rounded-md shadow-sm bg-persian-500/90 hover:bg-persian-600 hover:border-persian-500 border border-persian-700 text-sm font-semibold text-white"
            onClick={() => {
              dispatch(authActions.setActor({ actor: undefined }));
              history.push(`/deal/access/guest/account/${actor.dealAccountId}`);
            }}
          >
            Stop viewing as {actor.account.name}
          </button>
        </div>
      ) : null}
      <div className="transition-colors duration-300 ease-in-out bg-gray-200 p-4 pb-0 ">
        <div className="flex items-center justify-between">
          <div className="flex  items-center gap-3 mb-2 px-2">
            <div style={{ position: "relative" }}>
              <CompanyLogo
                logo={activeDeal ? activeDeal.company.logo : undefined}
                withShadow={false}
                borderSize={"2"}
                size={32}
                name={activeDeal ? activeDeal.company.name : ""}
                bgColor={activeDeal ? activeDeal.company.logoColor : "#000"}
              />
            </div>
            <div className="flex flex-col">
              <p className="font-bold text-gray-800 truncate">
                {activeDeal ? activeDeal.company.name : ""}
              </p>
            </div>
          </div>
          <DealRoleRestricted roles={[...adminRoles]}>
            <button
              onClick={() => {
                setShowAssistant(true);
              }}
              className="rounded-full flex items-center gap-x-2 font-semibold ring-1 shadow-sm ring-persian-900 bg-white text-sm text-persian-800 px-2 py-1"
            >
              <BotIcon className="w-4 h-4" />
              Assistant
            </button>
          </DealRoleRestricted>
        </div>

        <TopTabNavbar
          tabs={[
            {
              label: "Dashboard",
              icon: <LayoutDashboardIcon className="w-4 h-4" />,
              to: "/deal/dashboard",
            },
            {
              label: "Questions",
              icon: <MessageCircleQuestionIcon className="w-4 h-4 " />,
              to: "/deal/questions",
            },
            {
              label: "ToolKit",
              icon: <PocketKnifeIcon className="w-4 h-4 " />,
              to: "/deal/toolkit",
              roles: adminRoles,
            },
            {
              label: "Documents",
              icon: <FolderIcon className="h-4 w-4 " />,
              to: "/deal/documents",
            },
            {
              label: "Access",
              icon: <LockKeyholeIcon className="w-4 h-4 " />,
              to: "/deal/access",
              roles: [...adminRoles],
            },
            {
              label: "Access",
              icon: <LockKeyholeIcon className="w-4 h-4 " />,
              to: "/deal/access/guests",
              roles: [...guestAdminRoles],
            },
            {
              label: "Settings",
              icon: <CogIcon className="w-4 h-4 " />,
              to: "/deal/settings",
              roles: adminRoles,
            },
          ]}
        />
      </div>
      {location.pathname.startsWith("/deal/documents") ? (
        <DealRoleRestricted roles={adminRoles}>
          <SecondaryNavBar>
            <Link
              margin="0 0 0 0"
              to="/deal/documents"
              mode="secondary"
              isActive={(match) => {
                if (!match) {
                  return false;
                }

                if (location.pathname.endsWith("/activity")) {
                  return false;
                }

                return match.url.startsWith("/deal/documents");
              }}
            >
              <FilesIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Documents</span>
            </Link>
            <Link
              margin="0 0 0 0"
              to="/deal/documents/activity"
              mode="secondary"
            >
              <ChartBarIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Activity</span>
            </Link>
          </SecondaryNavBar>
        </DealRoleRestricted>
      ) : null}

      {location.pathname.startsWith("/deal/toolkit") ? (
        <DealRoleRestricted roles={adminRoles}>
          <SecondaryNavBar>
            <FeatureFlag flag="error-checking">
              <Link
                margin="0 0 0 0"
                to="/deal/toolkit/error-checking"
                mode="secondary"
              >
                <CircleAlertIcon className="w-4 h-4 " />
                <span className="text-sm font-semibold">Error checking</span>
              </Link>
            </FeatureFlag>
            <Link
              margin="0 0 0 0"
              to="/deal/toolkit/document-history"
              mode="secondary"
            >
              <BookOpenCheckIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Document history</span>
            </Link>
            <Link
              margin="0 0 0 0"
              to="/deal/toolkit/document-referencing"
              mode="secondary"
            >
              <FileSearch2Icon className="w-4 h-4 " />
              <span className="text-sm font-semibold">
                Document referencing
              </span>
            </Link>
            <Link
              margin="0 0 0 0"
              to="/deal/toolkit/merge-documents"
              mode="secondary"
            >
              <MergeIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Merge documents</span>
            </Link>
          </SecondaryNavBar>
        </DealRoleRestricted>
      ) : null}

      {location.pathname.startsWith("/deal/access") ? (
        <DealRoleRestricted roles={adminRoles}>
          <SecondaryNavBar>
            <Link margin="0 0 0 0" to="/deal/access/internal" mode="secondary">
              <LockKeyholeIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Internal access</span>
            </Link>
            <Link
              margin="0 0 0 0"
              to="/deal/access/guest"
              mode="secondary"
              isActive={(match) => {
                if (location.pathname.startsWith("/deal/access/guest/")) {
                  return true;
                }

                if (!match) {
                  return false;
                }

                return match.url.startsWith("/deal/access/guest");
              }}
            >
              <UsersIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Guest access</span>
            </Link>
          </SecondaryNavBar>
        </DealRoleRestricted>
      ) : null}

      {location.pathname.startsWith("/deal/settings") ? (
        <DealRoleRestricted roles={adminRoles}>
          <SecondaryNavBar>
            <Link margin="0 0 0 0" to="/deal/settings/company" mode="secondary">
              <Building2Icon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Company details</span>
            </Link>
            <Link margin="0 0 0 0" to="/deal/settings/exports" mode="secondary">
              <CogIcon className="w-4 h-4 " />
              <span className="text-sm font-semibold">Exports</span>
            </Link>
            <Link
              margin="0 0 0 0"
              to="/deal/settings/custom-properties"
              mode="secondary"
            >
              <TableProperties className="w-4 h-4" />
              <span className="text-sm font-semibold">Custom properties</span>
            </Link>
          </SecondaryNavBar>
        </DealRoleRestricted>
      ) : null}

      <Assistant open={showAssistant} onClose={() => setShowAssistant(false)} />
    </div>
  );
}

function SecondaryNavBar(props: { children: React.ReactNode }) {
  return (
    <div className="bg-gray-100 border-y border-gray-300 gap-x-8 py-0  flex items-center px-7">
      {props.children}
    </div>
  );
}
