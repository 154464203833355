import { useState } from "react";
import {
  AccessQuery,
  DealGroupQuery,
  DealRole,
  useUpdateDealAccountMutation,
  DealAccountFragment,
  DataRoomPermission,
} from "../../graphql/generated";
import { useQueryClient } from "@tanstack/react-query";
import useGqlClient from "../../hooks/useGqlClient";
import {
  GranularAccess,
  granularAccessToFileInput,
  granularAccessToFolderInput,
} from ".";
import { AnimatedModal } from "../../components/AnimatedModal";
import { H4 } from "../../components/Heading";
import { CloseIcon } from "../../components/CloseIcon";
import { Button } from "../../components/tailwind/Button";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";
import { EditDataRoomPermissions } from "./EditDataRoomPermissions";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import {
  allRoleOptions,
  guestRoleOptions,
} from "../../components/DealRoleRestricted";

export function EditDealAccount(props: {
  dealGroup?: DealGroupQuery["dealGroup"];
  dealAccount: DealAccountFragment | null;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  open: boolean;
  onClose: () => void;
  dealId: string;
}) {
  if (!props.dealAccount) {
    return null;
  }

  return (
    <EditDealAccountContent
      dealGroup={props.dealGroup}
      dealAccount={props.dealAccount}
      permissions={props.permissions}
      open={props.open}
      onClose={props.onClose}
      dealId={props.dealId}
    />
  );
}

function EditDealAccountContent(props: {
  dealGroup?: DealGroupQuery["dealGroup"];
  dealAccount: DealAccountFragment;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  open: boolean;
  onClose: () => void;
  dealId: string;
}) {
  const initialRole = allRoleOptions.find(
    (r) => r.value === props.dealAccount.role,
  );

  const [role, setRole] = useState<Option>(
    initialRole ? initialRole : guestRoleOptions[0],
  );

  const queryClient = useQueryClient();

  const client = useGqlClient();
  const updateDealAccount = useUpdateDealAccountMutation(client);

  const [overrideDataRoomAccess, setOverrideDataRoomAccess] = useState(
    props.dealAccount.overrideDataRoomAccess,
  );
  const [granularAccess, setGranularAccess] = useState<GranularAccess>({
    files: {},
    folders: {},
  });
  return (
    <AnimatedModal
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      size="xxl"
    >
      <div>
        <div className="flex items-center justify-between">
          <H4 margin="0">Edit {props.dealAccount.account.name}</H4>
          <CloseIcon onClose={props.onClose} />
        </div>
        <p className="text-sm text-gray-500/80 leading-tight">
          Joined{" "}
          {formatDistanceToNow(fromUnixTime(props.dealAccount.createdAt), {
            addSuffix: true,
          })}
        </p>
        <div>
          <div className="flex flex-col">
            <p className="mt-3 block text-sm font-semibold leading-6 text-gray-900">
              Deal role
            </p>
            <Dropdown
              options={
                props.dealGroup
                  ? guestRoleOptions
                  : guestRoleOptions.filter(
                      (o) => o.value !== DealRole.GuestAdmin,
                    )
              }
              onSelect={(o) => {
                setRole(o);
              }}
              selectedOption={role}
            />
          </div>
        </div>
        {role.value === DealRole.DealAdmin ? (
          <div className="mt-3">
            <p>Deal Admins have full access to everything in the Data Room. </p>
          </div>
        ) : (
          <EditDataRoomPermissions
            dealGroupId={props.dealGroup ? props.dealGroup.id : undefined}
            showPermissions={true}
            key={props.dealAccount.id}
            initialDataRoomPermission={
              props.dealGroup
                ? props.dealGroup.dataRoomPermission
                : (props.dealAccount.dataRoomPermission as DataRoomPermission)
            }
            initialGranularAccess={props.dealAccount.granularAccess}
            dataRoomPermissions={props.permissions}
            overrideDataRoomAccess={overrideDataRoomAccess}
            onOverrideDataRoomAccessChange={(checked) => {
              setOverrideDataRoomAccess(checked);
            }}
            granularAccess={granularAccess}
            onGranularAccessChange={(granularAccess) => {
              setGranularAccess(granularAccess);
            }}
          />
        )}

        <div className="w-full h-px bg-gray-200 my-4"></div>
        <div className="flex justify-end mt-5">
          <Button
            text="Save"
            variant="positive"
            isLoading={updateDealAccount.isPending}
            loadingText="Saving..."
            onClick={() => {
              updateDealAccount.mutate(
                {
                  input: {
                    id: props.dealAccount.id,
                    filePermissions: granularAccessToFileInput(granularAccess),
                    folderPermissions:
                      granularAccessToFolderInput(granularAccess),
                    overrideDataRoomAccess,
                    role: role.value as DealRole,
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: ["Access", { dealId: props.dealId }],
                    });
                    props.onClose();
                  },
                },
              );
            }}
          />
        </div>
      </div>
    </AnimatedModal>
  );
}
