import { useQueryClient } from "@tanstack/react-query";
import { AnimatedModal } from "../../components/AnimatedModal";
import { CloseIcon } from "../../components/CloseIcon";
import {
  DealRoleRestricted,
  adminRoles,
  allRoleOptions,
  dealRoleOptions,
} from "../../components/DealRoleRestricted";
import { H4 } from "../../components/Heading";
import {
  AddPeopleFromFirmQuery,
  DealRole,
  FirmQuery,
  useAddPeopleFromFirmQuery,
  useCreateDealAccountsMutation,
  useFirmQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import Loading from "../../components/Loading";
import { useState } from "react";
import { Button } from "../../components/tailwind/Button";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";
import { useFeatureFlagEnabled } from "posthog-js/react";

export function AddPeopleFromFirmModal(props: {
  open: boolean;
  onClose: () => void;
  dealId: string;
}) {
  const client = useGqlClient();
  const account = useSelector(authSelectors.account);
  const addPeopleFromFirm = useAddPeopleFromFirmQuery(client, {
    dealId: props.dealId,
    firmId: account?.firm?.id ?? "",
  });

  if (addPeopleFromFirm.error) {
    return null;
  }

  if (addPeopleFromFirm.isPending || !addPeopleFromFirm.data) {
    return null;
  }

  return (
    <AnimatedModal size="lg" open={props.open} onClose={props.onClose}>
      <DealRoleRestricted dealId={props.dealId} roles={adminRoles}>
        <div>
          <div className="flex items-center justify-between">
            <H4>
              Add people from your firm to{" "}
              {addPeopleFromFirm.data.deal.company.name}
            </H4>
            <CloseIcon onClose={props.onClose} />
          </div>
          <AddPeopleFromFirmContent
            onClose={props.onClose}
            query={addPeopleFromFirm.data}
            dealId={props.dealId}
          />
        </div>
      </DealRoleRestricted>
    </AnimatedModal>
  );
}

interface AccountToAdd {
  id: string;
  role: DealRole;
}

function AddPeopleFromFirmContent(props: {
  query: AddPeopleFromFirmQuery;
  onClose: () => void;
  dealId: string;
}) {
  const account = useSelector(authSelectors.account);
  const client = useGqlClient();
  const queryClient = useQueryClient();

  const [accountsToAdd, setAccountsToAdd] = useState<AccountToAdd[]>([]);

  const createDealAccounts = useCreateDealAccountsMutation(client);

  const firmQuery = useFirmQuery(client, {
    id: account && account.firm ? account.firm.id : "",
  });

  const roleOptions = useFeatureFlagEnabled("viewer-role")
    ? dealRoleOptions
    : dealRoleOptions.filter((role) => role.value !== DealRole.DealAdminViewOnly);

  if (firmQuery.error) {
    return <div>Error</div>;
  }

  if (firmQuery.isPending || !firmQuery.data) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const availableAccounts = firmQuery.data.firm.accounts.filter((acc) => {
    return !props.query.deal.dealAccounts.some(
      (dealAcc) => dealAcc.account.id === acc.id,
    );
  });

  return (
    <div>
      <div className="mt-3">
        {availableAccounts.length === 0 ? (
          <p className="text-gray-500">No accounts available to add</p>
        ) : null}
        {availableAccounts.map((acc) => {
          return (
            <div key={acc.id} className="py-2">
              <Account
                roleOptions={roleOptions}
                account={acc}
                accountsToAdd={accountsToAdd}
                setAccountsToAdd={setAccountsToAdd}
              />
            </div>
          );
        })}
      </div>
      <div className="mt-8 flex justify-end">
        <Button
          variant="positive"
          text="Add"
          isDisabled={accountsToAdd.length === 0}
          isLoading={createDealAccounts.isPending}
          loadingText="Adding..."
          onClick={() => {
            createDealAccounts.mutate(
              {
                input: {
                  dealGroupId:
                    props.query.deal.activeDealAccount.dealGroup?.id ?? "",
                  dealId: props.dealId,
                  accounts: accountsToAdd.map((a) => {
                    return {
                      accountId: a.id,
                      role: a.role,
                      ndaSigned: true,
                    };
                  }),
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: [
                      "DealGroup",
                      {
                        id:
                          props.query.deal.activeDealAccount.dealGroup?.id ??
                          "",
                      },
                    ],
                  });
                  
                  queryClient.invalidateQueries({
                    queryKey: [
                      "Firm",
                      {
                        id: account && account.firm ? account.firm.id : "",
                      },
                    ],
                  });
                  
                  queryClient.invalidateQueries({
                    queryKey: [
                      "AddPeopleFromFirm",
                      {
                        dealId: props.dealId,
                        firmId: account?.firm?.id ?? "",
                      },
                    ],
                  });
                  
                  props.onClose();
                },
              },
            );
          }}
        />
      </div>
    </div>
  );
}

function Account(props: {
  roleOptions: Option[];
  account: FirmQuery["firm"]["accounts"][0];
  accountsToAdd: AccountToAdd[];
  setAccountsToAdd: React.Dispatch<React.SetStateAction<AccountToAdd[]>>;
}) {
  const [selectedRole, setSelectedRole] = useState<Option>(
    props.roleOptions[0],
  );
  
  // Check if this account is in the accountsToAdd array
  const isChecked = props.accountsToAdd.some(a => a.id === props.account.id);
  
  return (
    <div className="flex justify-between">
      <div className="relative flex items-start">
        <div className="flex h-6 items-start">
          <input
            id={`account_to_add_checkbox_${props.account.id}`}
            aria-describedby="comments-description"
            name="account_to_add_checkbox"
            type="checkbox"
            checked={isChecked}
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            onChange={(e) => {
              const checked = e.currentTarget.checked;
              if (checked) {
                props.setAccountsToAdd(prev => [
                  ...prev, 
                  { id: props.account.id, role: selectedRole.value as DealRole }
                ]);
              } else {
                props.setAccountsToAdd(prev => 
                  prev.filter(a => a.id !== props.account.id)
                );
              }
            }}
          />
        </div>
        <div className="ml-3 text-sm leading-tight">
          <label
            htmlFor={`account_to_add_checkbox_${props.account.id}`}
            className="font-medium text-gray-900"
          >
            {props.account.name}
          </label>
          <p id="account_to_add_email" className="text-gray-500">
            {props.account.email}
          </p>
        </div>
      </div>
      <Dropdown
        hideSelectedOptionDescription
        selectedOption={selectedRole}
        options={props.roleOptions}
        onSelect={(e) => {
          setSelectedRole(e);
          // If this account is checked, update its role in accountsToAdd
          if (isChecked) {
            props.setAccountsToAdd(prev => 
              prev.map(a => 
                a.id === props.account.id 
                  ? { ...a, role: e.value as DealRole } 
                  : a
              )
            );
          }
        }}
      />
    </div>
  );
}
