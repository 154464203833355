import { TransitionGroup, CSSTransition } from "react-transition-group";
import { DealActivityFragmentFragment } from "../../graphql/generated";
import { ActivityItem, ActivityItemMode } from "./ActivityItem";
import { withMarginTw } from "../../styles/withMargin";

export function ActivityItems(props: {
  items: DealActivityFragmentFragment[];
  mode?: ActivityItemMode;
  margin?: string;
}) {

  if (props.items.length === 0) {
    return null;
  }

  const margin = withMarginTw({ margin: props.margin });
  return (
    <ul role="list" className={margin}>
      <TransitionGroup className="space-y-6">
        {props.items.map((activity, i) => {
          return (
            <CSSTransition
              key={activity.id}
              timeout={300} // This controls the duration of the animation
              classNames="fade-slide-down"
              onEnter={(node: any) => {
                if (node) {
                  return node.offsetHeight;
                }
              }}
            >
              <ActivityItem
                mode={props.mode}
                key={activity.id}
                activity={activity}
                isLast={i === props.items.length - 1}
                isFirst={i === 0}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </ul>
  );
}
