import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { withMarginTw } from "../styles/withMargin";
import { classNames } from "../utils/cn";
import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";

export function Tooltip(props: {
  text: string;
  margin?: string;
  width?: string;
}) {
  const margin = withMarginTw({ margin: props.margin });
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showTooltip && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top - 10, // Position above the icon with some spacing
        left: rect.left + rect.width / 2, // Center horizontally
      });
    }
  }, [showTooltip]);

  return (
    <div
      aria-label={`Tooltip that says: ${props.text}`}
      className={`${margin} inline-block relative`}
    >
      <div
        ref={triggerRef}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <InformationCircleIcon className="w-4 h-4 text-gray-500 hover:text-gray-600" />
      </div>

      {showTooltip &&
        createPortal(
          <div
            className="fixed z-[9999] pointer-events-none"
            style={{
              top: `${tooltipPosition.top}px`,
              left: `${tooltipPosition.left}px`,
              transform: "translate(-50%, -100%)",
            }}
          >
            <div
              className={classNames(
                `py-2 px-3 rounded-md border border-gray-900 bg-gray-900 relative
              before:absolute before:left-1/2 before:bottom-0 before:-mb-[8px] 
              before:border-transparent before:border-t-gray-900 before:border-t-[8px] 
              before:border-x-[8px] before:border-b-0 before:content-[''] before:-translate-x-1/2`,
                props.width ? props.width : "w-[12rem]",
              )}
            >
              <p className="text-sm font-semibold text-white whitespace-normal break-normal">
                {props.text}
              </p>
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
}
