// hook for manually refetching assistant messages when webhook streaming fails

import { useEffect } from "react";
import { DealThreadMessageStatus } from "../graphql/generated";
import {
  differenceInMinutes,
  differenceInSeconds,
  fromUnixTime,
} from "date-fns";

export interface ManualRefreshInput {
  messageCreatedAt: number;
  messageLastStreamedAt?: number;
  messageStatus: DealThreadMessageStatus;
}

export function useManualRefresh(
  input: ManualRefreshInput,
  callback: () => void,
) {
  useEffect(
    () => {
      let manualRefreshTimeout: NodeJS.Timeout | null = null;

      function checkMessage() {
        const cancelledStatuses = [
          DealThreadMessageStatus.Cancelled,
          DealThreadMessageStatus.Sent,
          DealThreadMessageStatus.Failed,
        ];

        if (
          cancelledStatuses.includes(input.messageStatus) ||
          differenceInMinutes(
            new Date(),
            fromUnixTime(input.messageCreatedAt),
          ) > 5
        ) {
          if (manualRefreshTimeout) {
            clearTimeout(manualRefreshTimeout);
          }
          return;
        }

        // give the llm 5 seconds, could just be thinking / in a queue
        // Get the most recent timestamp between message createdAt and lastStreamedMessageAt
        const mostRecentTimestamp =
          input.messageLastStreamedAt || input.messageCreatedAt;

        if (manualRefreshTimeout) {
          clearTimeout(manualRefreshTimeout);
        }

        if (
          differenceInSeconds(new Date(), fromUnixTime(mostRecentTimestamp)) > 5
        ) {
          callback();
          manualRefreshTimeout = setTimeout(() => {
            checkMessage();
          }, 250);
          return;
        }

        manualRefreshTimeout = setTimeout(checkMessage, 1000);
        return;
      }

      checkMessage();

      return () => {
        if (manualRefreshTimeout) {
          clearTimeout(manualRefreshTimeout);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [input.messageCreatedAt, input.messageLastStreamedAt, input.messageStatus],
  );
}
