import { useParams, useHistory } from "react-router-dom";
import { classNames } from "@/src/utils/cn";
import Loading from "@/src/components/Loading";
import {
  DataRoomFileUploadStatus,
  FileType,
  useFileVersionDownloadUrlQuery,
  DataRoomFile,
  useResolveDataRoomFileSimilarityMutation,
  DataRoomFileFragmentFragment,
  MergeDocumentsQuery,
} from "@/src/graphql/generated";
import { formatDistance, formatISO, secondsToMilliseconds } from "date-fns";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Button } from "@/src/components/tailwind/Button";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useEffect, useState } from "react";
import { Spinner } from "@/src/components/icons/Spinner";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import React from "react";
import { FileIcon } from "@/src/components/FileIcon";
import { StatusDot } from "@/src/components/StatusDot";
import { Pspdfkit } from "@/src/components/document_viewers/Pspdfkit";
import PspdfkitComp from "@/src/components/document_viewers/PspdfkitComp";
import { InfoIcon } from "lucide-react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Avatar } from "@/src/components/account/Avatar";
import { UseQueryResult } from "@tanstack/react-query";

export function Comparison(props: {
  query: UseQueryResult<MergeDocumentsQuery, unknown>;
}) {
  const client = useGqlClient();
  const { fileId1, fileId2 } = useParams<{
    fileId1: string;
    fileId2: string;
  }>();

  const [showSidebar, setShowSidebar] = useState(false);
  const [swapped, setSwapped] = useState(false);

  if (props.query.error || !props.query.data) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <p className="text-gray-500 text-sm">
          Error loading documents. Please try again.
        </p>
      </div>
    );
  }

  if (props.query.isPending) {
    return <Loading />;
  }

  const files = swapped
    ? [props.query.data.dataRoomFile2, props.query.data.dataRoomFile1]
    : [props.query.data.dataRoomFile1, props.query.data.dataRoomFile2];

  return (
    <div className="flex-1 flex flex-col min-w-0">
      <div className="flex-1 flex flex-col h-full w-full min-w-0">
        <div className="lg:hidden flex  items-center justify-center flex-1">
          <p className="font-semibold text-gray-600">Not supported on mobile</p>
        </div>
        <div className="hidden lg:flex flex-1 min-w-0">
          <div className="flex flex-1 relative min-w-0">
            <PspdfkitComp
              key={`${files[0].id}-${files[1].id}`}
              dataRoomFileVersionSourceID={files[0].currentLiveVersion.id}
              dataRoomFileVersionTargetID={files[1].currentLiveVersion.id}
            />
            {showSidebar ? (
              <FilesSidebar
                files={files as DataRoomFile[]}
                onSwap={() => setSwapped(!swapped)}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

function MergeDocumentsContent(props: { files: DataRoomFile[] }) {
  if (!props.files) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <p className="text-xs text-gray-500">No search selected</p>
      </div>
    );
  }

  return (
    <div className="flex-1  min-w-0">
      <div className="flex w-full">
        {props.files.map((file, i) => (
          <div key={`${file.id}-${i}`} id={`doc-${file.id}`} className="w-1/2">
            <DeepSearchFileHeader
              file={file}
              role={i === 0 ? "source" : "target"}
            />
            <FileViewWrapper file={file} />
          </div>
        ))}
      </div>
    </div>
  );
}

function FilesSidebar(props: { files: DataRoomFile[]; onSwap: () => void }) {
  const history = useHistory();
  const client = useGqlClient();
  const resolveMergeMutation = useResolveDataRoomFileSimilarityMutation(client);

  return (
    <div className="overflow-y-auto pb-2 shadow-sm h-full rounded-tr-md rounded-br-md">
      <div className="px-2 py-4 flex items-center justify-center gap-4">
        <Button
          variant="neutral"
          icon={XMarkIcon}
          text="Reject Merge"
          onClick={() => {
            resolveMergeMutation.mutate(
              {
                acceptMerge: false,
                dataRoomFileID1: props.files[0].id,
                dataRoomFileID2: props.files[1].id,
                reverseMerge: false,
              },
              {
                onSuccess: () => {
                  history.push(
                    `/deal/documents/folder/${props.files[1].folderId}/file/${props.files[1].id}`,
                  );
                },
              },
            );
          }}
        />
        <Button
          variant="positive"
          icon={CheckIcon}
          text="Accept Merge"
          onClick={() => {
            resolveMergeMutation.mutate(
              {
                acceptMerge: true,
                dataRoomFileID1: props.files[0].id,
                dataRoomFileID2: props.files[1].id,
                reverseMerge: false,
              },
              {
                onSuccess: () => {
                  history.push(
                    `/deal/documents/folder/${props.files[1].folderId}/file/${props.files[1].id}`,
                  );
                },
              },
            );
          }}
        />
      </div>
      <div className="mx-3 px-2 flex items-center gap-x-1 rounded-md bg-gray-50 py-2 border border-gray-300">
        <div className="flex items-center w-full">
          <div className="flex-shrink-0 mr-2"></div>
          <div className="items-center">
            <h4 className="text-sm font-bold text-gray-700 mb-0.5 inline">
              Caution
            </h4>
            <InfoIcon className="w-5 h-5 ml-1 text-amber-500 inline" />
            <p className="text-gray-500 text-sm mt-2">
              Merging documents is an irreversible action. Please ensure you
              have reviewed the documents and are ready to merge. The current
              live version of the source document will be appended as a new
              version of the target document. The source document and its other
              versions will be deleted.
            </p>
            <p className="text-gray-500 text-sm mt-2">
              Merging documents has implications for document permissions. The
              current live version of the source document will inherit the
              permissions of the target document.
            </p>
            <p className="text-gray-500 text-sm mt-2">
              The left sidebar of this merge view contains the full differences
              between the two documents. The document highlights are only
              indicative of the start of each of the changes.
            </p>
          </div>
        </div>
      </div>

      <div className="px-2 space-y-2">
        <FilesSideBarFile files={props.files} />
      </div>
    </div>
  );
}

function FilesSideBarFile(props: { files: DataRoomFile[] }) {
  return (
    <div>
      <div
        className={classNames(
          "mt-4 p-2 bg-gray-50 shadow-sm border border-gray-200 rounded-md flex flex-col items-start",
        )}
      >
        {props.files.length > 0 ? (
          <div className="space-y-2 w-full">
            <FileSideBar files={props.files} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

function InjectedFileHeader(props: { file: DataRoomFile }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        paddingLeft: "16px",
      }}
    >
      <FileIcon mode="innerHtml" fileType={props.file.fileType} />
      <p style={{ fontWeight: 600, fontSize: 14, color: "#464646" }}>
        {props.file.name}
      </p>
    </div>
  );
}

function FileSideBar(props: { files: DataRoomFile[] }) {
  return (
    <div className="">
      <div className="space-y-2">
        {props.files.map((file, i) => (
          <SideBarFile
            key={i}
            file={file}
            role={i === 0 ? "source" : "target"}
          />
        ))}
      </div>
    </div>
  );
}

function SideBarFile(props: { file: DataRoomFile; role: "source" | "target" }) {
  return (
    <div className="" key={props.file.id}>
      <div
        className={classNames(
          "bg-gray-50 shadow-sm border border-gray-200 rounded-md flex flex-col items-start",
        )}
      >
        <div className="bg-white flex border-b w-full rounded-t-md border-gray-200 p-2 items-center justify-between gap-x-2">
          <div className="flex items-center gap-x-2">
            <FileIcon fileType={props.file.fileType} />
            <div>
              <p className="font-semibold text-sm text-gray-700 line-clamp-1 hover:line-clamp-none text-ellipsis">
                <span className="text-xs text-white bg-blue-500 rounded-full px-2 py-0.5 mr-2">
                  {props.role.toUpperCase()}
                </span>
                {props.file.name}
              </p>
              <div className="flex items-center gap-x-1">
                <StatusDot
                  status={
                    props.file.currentLiveVersion.id ===
                    props.file.currentLiveVersion.id
                      ? "positive"
                      : "neutral"
                  }
                />
                <p className="text-xs text-gray-600">
                  Version {props.file.currentLiveVersion.versionNumber}
                </p>
              </div>
            </div>
          </div>
        </div>

        <Versions file={props.file} />
      </div>
    </div>
  );
}

function DeepSearchFileHeader(props: {
  file: DataRoomFile;
  role: "source" | "target";
}) {
  return (
    <div className="flex items-center gap-x-2 p-2 border-b border-gray-200">
      <FileIcon fileType={props.file.fileType} />
      <p className="font-semibold text-sm text-gray-700">
        <span className="text-xs text-white bg-blue-500 rounded-full px-2 py-0.5 mr-2">
          {props.role.toUpperCase()}
        </span>
        {props.file.name}
      </p>
    </div>
  );
}

export function FileViewWrapper(props: { file: DataRoomFile }) {
  if (
    props.file.fileType === FileType.Pdf ||
    props.file.fileType === FileType.Docx ||
    props.file.fileType === FileType.Pptx
  ) {
    return (
      <Pspdfkit
        mode="dataRoom"
        heightOffset={300}
        width="100%"
        dataRoomFileVersionId={props.file.currentLiveVersion.id}
      />
    );
  }

  return <FileViewContent file={props.file} />;
}

function FileViewContent(props: { file: DataRoomFile }) {
  const client = useGqlClient();
  const [url, setUrl] = React.useState<string>("");
  const [fileDownloadError, setFileDownloadError] = React.useState<string>("");

  const fileDownloadUrl = useFileVersionDownloadUrlQuery(
    client,
    {
      id: props.file.currentLiveVersion.id,
    },
    {
      refetchOnWindowFocus(query) {
        if (query.isStaleByTime(1000 * 60 * 5)) {
          return true;
        }

        return false;
      },
    },
  );

  useEffect(() => {
    if (
      fileDownloadUrl.data &&
      fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl !== url
    ) {
      setFileDownloadError("");
      setUrl(fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl);
      return;
    }

    if (fileDownloadUrl.error) {
      setFileDownloadError("Unable to load file");
      return;
    }
  }, [
    fileDownloadUrl.data,
    fileDownloadUrl.isRefetching,
    fileDownloadUrl.isPending,
    fileDownloadUrl.error,
    url,
  ]);

  if (
    props.file.uploadStatus !== DataRoomFileUploadStatus.Ready &&
    props.file.uploadStatus !== DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div style={{ height: 800 }} className="flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (url === "") {
    return (
      <div style={{ height: 800 }}>
        <Loading />
      </div>
    );
  }

  if (fileDownloadError) {
    return (
      <div className="flex items-center justify-center">
        <p>Failed to load file</p>
        <Button
          variant="neutral"
          text="Retry"
          isLoading={fileDownloadUrl.isPending || fileDownloadUrl.isRefetching}
          loadingText="Retrying..."
          onClick={() => {
            fileDownloadUrl.refetch();
          }}
        />
      </div>
    );
  }

  return (
    <DocViewer
      key={url}
      config={{
        pdfVerticalScrollByDefault: true,
        header: {
          disableFileName: true,
          disableHeader: true,
        },
      }}
      prefetchMethod="GET"
      style={{ height: 800, width: "100%", overflowY: "scroll" }}
      documents={[
        {
          uri: url,
        },
      ]}
      pluginRenderers={DocViewerRenderers}
    />
  );
}

function Versions(props: { file: DataRoomFileFragmentFragment }) {
  return (
    <div className="w-full px-1">
      <ul role="list" className="mt-2 space-y-2">
        <TransitionGroup className="space-y-2">
          {props.file.versions.map((version, i) => {
            return (
              <CSSTransition
                key={version.id}
                timeout={300}
                classNames="fade-slide-down"
                onEnter={(node: any) => node.offsetHeight}
              >
                <li key={version.id}>
                  <Version
                    fileId={props.file.id}
                    version={version}
                    isFirst={i === 0}
                    isLast={i === props.file.versions.length - 1}
                    currentLiveVersionId={props.file.currentLiveVersion.id}
                  />
                </li>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </ul>
    </div>
  );
}
function Version(props: {
  fileId: string;
  version: DataRoomFileFragmentFragment["versions"][0];
  isLast: boolean;
  isFirst: boolean;
  currentLiveVersionId: string;
}) {
  const version = props.version;

  return (
    <div
      className={classNames(
        "transition-all duration-300 ease-in-out cursor-pointer hover:bg-gray-50 py-2",
        !props.isLast ? "border-b border-gray-100" : "",
        props.isLast ? "rounded-b-md" : "",
        props.isFirst ? "rounded-t-md" : "",
      )}
    >
      <div>
        <div className="relative flex flex-col space-y-2 px-1">
          <div className="flex items-start gap-x-1 -py-0.5">
            {props.currentLiveVersionId === props.version.id ? (
              <div className="flex h-6 w-6 items-center justify-center bg-transparent">
                <div
                  className={classNames(
                    "bg-green-400/10 text-green-400",
                    "rounded-full p-1",
                  )}
                >
                  <div className="h-2 w-2 rounded-full bg-current" />
                </div>
              </div>
            ) : (
              <div className="flex h-6 w-6 items-center justify-center bg-transparent">
                <div
                  className={classNames(
                    "h-1.5 w-1.5 rounded-full bg-gray-300 ring-1 ring-gray-300",
                  )}
                />
              </div>
            )}

            <div className="w-1/2 min-w-0">
              <p className="text-sm font-semibold text-gray-600 truncate">
                Version {version.versionNumber}
              </p>
              <p className="text-xs leading-2 text-gray-500 font-normal truncate">
                {version.summary ? version.summary : "No summary"}
              </p>
            </div>
          </div>

          <div className="flex items-center ml-1">
            <Avatar account={version.createdBy} size="xs" />
            <p className="text-xs leading-5 ml-2 text-gray-500 font-light whitespace-nowrap">
              <span className="font-semibold text-xs text-gray-500">
                {version.createdBy.name}
              </span>{" "}
              uploaded{" "}
              <time
                title={formatISO(secondsToMilliseconds(version.createdAt))}
                dateTime={formatISO(secondsToMilliseconds(version.createdAt))}
                className="flex-none"
              >
                {formatDistance(
                  secondsToMilliseconds(version.createdAt),
                  new Date(),
                  {
                    addSuffix: true,
                  },
                )}
              </time>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
