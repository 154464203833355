import { Dialog } from "@headlessui/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import useGqlClient from "../../hooks/useGqlClient";
import {
  useDataRoomFolderQuery,
  useMoveDataRoomFolderMutation,
} from "../../graphql/generated";
import { AnimatedModal } from "../../components/AnimatedModal";
import { useInvalidateQueryKeys } from "../../hooks/useInvalidateQueryKeys";
import { Loader } from "../../components/Loading";
import { ArrowRightCircleIcon, FolderIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../utils/cn";
import { AddFolderPill } from "../../components/AddFolderPill";
import { CloseIcon } from "../../components/CloseIcon";
import { toasts } from "../../components/toasts/toasts";

interface MoveFolderProps {
  onClose: () => void;
  onMoved?: () => void;
  open: boolean;
  dataRoomFolderId: string;
  targetFolderId?: string;
  targetFolderName?: string;
}

export function MoveFolderModal(props: MoveFolderProps) {
  return (
    <AnimatedModal open={props.open} onClose={props.onClose} size="xl">
      <MoveFolderContent
        id={props.dataRoomFolderId}
        targetFolderId={props.targetFolderId}
        targetFolderName={props.targetFolderName}
        onCancel={() => props.onClose()}
        onMove={() => {
          if (props.onMoved) {
            props.onMoved();
          }

          props.onClose();
        }}
      />
    </AnimatedModal>
  );
}

interface MoveFolderContentProps {
  id: string | null;
  targetFolderId?: string;
  targetFolderName?: string;
  onCancel: () => void;
  onMove: () => void;
}

interface SelectedFolder {
  id: string;
  name: string;
}

function MoveFolderContent(props: MoveFolderContentProps) {
  const client = useGqlClient();
  const folderQuery = useDataRoomFolderQuery(client, {
    id: props.id || "",
  });

  const history = useHistory();

  const moveDataRoomFolder = useMoveDataRoomFolderMutation(client);
  const {
    invalidateDealActivities,
    invalidateDataRoomFolders,
    invalidateDataRooms,
  } = useInvalidateQueryKeys();

  const [selectedFolder, setSelectedFolder] = useState<SelectedFolder | null>(
    props.targetFolderId
      ? { id: props.targetFolderId, name: props.targetFolderName ?? "" }
      : null,
  );

  if (folderQuery.isPending) {
    return <Loader width={30} height={6} />;
  }

  if (folderQuery.error) {
    return (
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <p>Something went wrong</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
          <ArrowRightCircleIcon
            className="h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
        </div>
        <div className="w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {`Move ${folderQuery.data?.dataRoomFolder.name} `}
          </Dialog.Title>

          <div className="mt-3 w-full">
            {folderQuery.data ? (
              <div className="flex items-center gap-x-1">
                <p className="text-sm font-semibold text-gray-500">Move</p>
                <FolderPill
                  selectedFolder={{
                    id: folderQuery.data?.dataRoomFolder.id ?? "",
                    name: folderQuery.data?.dataRoomFolder.name ?? "",
                  }}
                  onClose={() => {}}
                />
                <p className="text-sm font-semibold text-gray-500">to</p>
                {selectedFolder ? (
                  <FolderPill
                    selectedFolder={selectedFolder}
                    onClose={() => {
                      setSelectedFolder(null);
                    }}
                  />
                ) : (
                  <AddFolderPill
                    onFolderSelected={(folder) => {
                      setSelectedFolder({
                        id: folder.id,
                        name: folder.name,
                      });
                    }}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mt-8 sm:mt-12 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          disabled={!selectedFolder}
          className={classNames(
            "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
            !selectedFolder ? "opacity-70" : "",
          )}
          onClick={() => {
            if (!selectedFolder || !props.id) {
              return;
            }

            moveDataRoomFolder.mutate(
              {
                input: {
                  folderID: props.id,
                  targetParentFolderID: selectedFolder.id,
                },
              },
              {
                onError: (error: any) => {
                  if (
                    error.message.includes(
                      "folderID and targetParentFolderID cannot be the same",
                    )
                  ) {
                    toasts.error("Cannot move folder into itself");
                  } else {
                    toasts.error("Failed to move folder");
                  }
                },
                onSuccess: () => {
                  invalidateDataRoomFolders();
                  invalidateDealActivities();
                  invalidateDataRooms();
                  toasts.success("Folder moved successfully");
                  if (history.location.pathname.startsWith("/drive")) {
                    history.push(`/drive/folder/${selectedFolder.id}`);
                  } else {
                    history.push(`/deal/documents/folder/${selectedFolder.id}`);
                  }
                  props.onMove();
                },
              },
            );
          }}
        >
          {moveDataRoomFolder.isPending ? "Moving..." : "Move"}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            props.onCancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

function FolderPill(props: {
  selectedFolder: SelectedFolder | null;
  onClose: () => void;
}) {
  if (!props.selectedFolder) {
    return (
      <button className="flex flex-wrap group justify-center relative">
        <div className="cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
          <FolderIcon className="w-4 h-4 text-gray-400" />
          <p className="text-xs ml-1 mt-0.5 truncate">Select folder</p>
        </div>
      </button>
    );
  }

  return (
    <div className="flex flex-wrap group justify-center relative">
      <div className="gap-x-1 cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
        <FolderIcon className="w-4 h-4 text-blue-700/70" />
        <p className="text-xs  mt-0.5 truncate">{props.selectedFolder.name}</p>
        <CloseIcon
          onClose={() => {
            props.onClose();
          }}
        />
      </div>
    </div>
  );
}
