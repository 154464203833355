import { useSelector } from "react-redux";
import { H3 } from "../../components/Heading";
import {
  Account,
  Deal,
  DealStatus,
  Task,
  TaskActivityType,
  TaskPriority,
  TasksFilters,
  TasksQuery,
  TaskStatus,
  UpdateTaskInput,
  useTaskActivityQuery,
  useTasksQuery,
  useUpdateTasksMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import Loading from "../../components/Loading";
import { PlusIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Button } from "../../components/tailwind/Button";
import { NewTaskModal } from "./NewTaskModal";
import {
  useEffect,
  useState,
  useMemo,
  MouseEvent,
  createContext,
  useContext,
} from "react";
import { Card } from "../../components/Card";
import { NavLink } from "react-router-dom";
import {
  format,
  formatDistanceToNowStrict,
  fromUnixTime,
  isBefore,
} from "date-fns";
import { Avatar } from "../../components/account/Avatar";
import {
  CalendarDaysIcon,
  CheckCircleIcon,
  ChatBubbleLeftIcon,
} from "@heroicons/react/24/outline";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import CompanyLogo from "../../components/CompanyLogo";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { TaskPriorityIcon } from "../../components/TaskPriorityPill";
import { classNames } from "@/src/utils/cn";
import {
  ActivityIcon,
  AlertCircleIcon,
  ChevronDownIcon,
  CircleDashedIcon,
  CircleIcon,
  PinIcon,
} from "lucide-react";
import { formatEnum } from "@/src/utils/enums";
import { ProgressBar } from "@/src/components/ProgressBar";
import { move, reorder } from "@/src/utils/dnd";
import { TASK_ACTIVITY_TYPES, TaskActivityItems } from "./TaskActivityItems";
import { TripleDotMenu } from "@/src/components/TripleDotMenu";
import { Menu } from "@headlessui/react";
import { Tooltip } from "@/src/components/Tooltip";

type GroupedTasks = {
  [dealId: string]: {
    [status in TaskStatus]: Task[];
  };
};

interface PinnedDealsContextType {
  pinnedDeals: string[];
  togglePinDeal: (dealId: string) => void;
  isPinned: (dealId: string) => boolean;
}

const PinnedDealsContext = createContext<PinnedDealsContextType>({
  pinnedDeals: [],
  togglePinDeal: () => {},
  isPinned: () => false,
});

export function PinnedDealsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [pinnedDeals, setPinnedDeals] = useState<string[]>(() => {
    const storedPinnedDeals = localStorage.getItem("pinnedDeals");
    if (storedPinnedDeals) {
      try {
        return JSON.parse(storedPinnedDeals);
      } catch (e) {
        console.error("Error parsing pinned deals from localStorage:", e);
        return [];
      }
    }
    return [];
  });

  // Update localStorage whenever pinnedDeals changes
  useEffect(() => {
    localStorage.setItem("pinnedDeals", JSON.stringify(pinnedDeals));
  }, [pinnedDeals]);

  const togglePinDeal = (dealId: string) => {
    setPinnedDeals((prevPinnedDeals) => {
      const isPinned = prevPinnedDeals.includes(dealId);
      const updatedPinnedDeals = isPinned
        ? prevPinnedDeals.filter((id) => id !== dealId) // remove if already pinned
        : [...prevPinnedDeals, dealId]; // add if not pinned

      return updatedPinnedDeals;
    });
  };

  const isPinned = (dealId: string) => {
    return pinnedDeals.includes(dealId);
  };

  const value = useMemo(
    () => ({
      pinnedDeals,
      togglePinDeal,
      isPinned,
    }),
    [pinnedDeals],
  );

  return (
    <PinnedDealsContext.Provider value={value}>
      {children}
    </PinnedDealsContext.Provider>
  );
}

function usePinnedDeals() {
  return useContext(PinnedDealsContext);
}

// High Priority Tasks Component
function HighPriorityTasks({ tasks }: { tasks: TasksQuery["tasks"] }) {
  const sortedTasks = [...tasks].sort((a, b) => {
    // Sort URGENT before HIGH
    if (
      a.priority === TaskPriority.Urgent &&
      b.priority !== TaskPriority.Urgent
    )
      return -1;
    if (
      a.priority !== TaskPriority.Urgent &&
      b.priority === TaskPriority.Urgent
    )
      return 1;

    // Then sort by due date (if available)
    if (a.dueDate && b.dueDate) {
      return a.dueDate - b.dueDate;
    }

    // Tasks with due dates come before tasks without
    if (a.dueDate && !b.dueDate) return -1;
    if (!a.dueDate && b.dueDate) return 1;

    return 0;
  });

  return (
    <>
      <Card className="border-2 border-orange-200 shadow-md">
        <div className="flex items-center p-3 border-b border-orange-200 bg-gradient-to-r from-orange-50 to-orange-100">
          <span>
            <h3 className="text-lg font-bold text-orange-950">Urgent Tasks</h3>
          </span>
          <span className="inline-flex items-center ml-2">
            <Tooltip
              text="Tasks marked as high or urgent priority, or due in the next 3 days"
              width="w-[16rem]"
            />
          </span>
        </div>

        <div className="max-h-[300px] overflow-y-auto bg-orange-50/30">
          {sortedTasks.map((task) => (
            <div
              key={task.id}
              className={classNames("hover:bg-concrete-50 px-6 py-2 border-b")}
            >
              <TaskListItem task={task as unknown as Task} />
            </div>
          ))}
        </div>
      </Card>
    </>
  );
}

export function Tasks(props: {
  selectedDeal: Option | null;
  selectedAccount: Option | null;
  selectedStatus: Option | null;
  setSelectedDeal: (deal: Option | null) => void;
  setSelectedAccount: (account: Option | null) => void;
  setSelectedStatus: (status: Option | null) => void;
  showActivity: boolean;
  setShowActivity: (show: boolean) => void;
  filters: TasksFilters;
  setFilters: (filters: TasksFilters) => void;
}) {
  const account = useSelector(authSelectors.account);
  const [openModal, setOpenModal] = useState<"new_task" | "">("");
  const [taskModal, setTaskModal] = useState<{
    dealId?: string;
    accountId?: string;
  } | null>(null);
  const client = useGqlClient();

  const tasksQuery = useTasksQuery(client, {
    firmID: account && account.firm ? account.firm.id : "",
    filters: props.filters,
  });

  const [dealOptions, setDealOptions] = useState<Option[]>([]);
  const [accountOptions, setAccountOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!tasksQuery.data || tasksQuery.isLoading) {
      return;
    }

    const accountOptions: Option[] = tasksQuery.data.firm.accounts.map(
      (acc) => {
        return {
          label: acc.name,
          value: acc.id,
          icon: <Avatar account={acc} size="xs" />,
        };
      },
    );

    setAccountOptions([
      {
        label: "All accounts",
        value: null,
      },
      ...accountOptions,
    ]);

    if (account && account.deals.length > 0) {
      const dealOptions: Option[] = account.deals.map((deal) => {
        return {
          label: deal.company?.name ?? "",
          value: deal.id,
          icon: (
            <CompanyLogo
              logo={deal.company ? deal.company.logo : ""}
              withShadow={false}
              borderSize={"3"}
              size={20}
              name={deal.company ? deal.company.name : ""}
              bgColor={deal.company ? deal.company.logoColor : "#000"}
            />
          ),
        };
      });

      setDealOptions([
        {
          label: "All deals",
          value: null,
        },
        ...dealOptions,
      ]);
    }
  }, [tasksQuery.data, account, tasksQuery.isLoading]);

  return (
    <PinnedDealsProvider>
      <div className="flex flex-col flex-1">
        <div className="bg-white sticky top-0 z-20 px-8 py-3 w-full shadow-sm border-b border-gray-300/80">
          <div className="flex items-center justify-between">
            <div>
              <H3>Tasks</H3>
              <p className="text-sm text-gray-500 leading-none"></p>
            </div>
            <div className="flex items-center gap-x-3">
              {account && account.deals.length > 0 ? (
                <Dropdown
                  size="s"
                  options={[
                    { label: "All deal status", value: null },
                    { label: "Active", value: DealStatus.Active },
                    { label: "Archived", value: DealStatus.Archived },
                  ]}
                  selectedOption={props.selectedStatus ?? undefined}
                  onSelect={(o) => {
                    props.setSelectedStatus(o);
                    props.setFilters({
                      ...props.filters,
                      dealStatus: o.value as DealStatus | null,
                    });
                  }}
                />
              ) : null}
              {account && account.deals.length > 0 ? (
                <Dropdown
                  size="s"
                  options={dealOptions}
                  selectedOption={props.selectedDeal ?? undefined}
                  defaultOption={{
                    label: "All deals",
                    value: null,
                  }}
                  onSelect={(o) => {
                    props.setSelectedDeal(o);
                    props.setFilters({ ...props.filters, dealID: o.value });
                  }}
                />
              ) : null}
              {accountOptions.length > 0 ? (
                <Dropdown
                  size="s"
                  options={accountOptions}
                  selectedOption={props.selectedAccount ?? undefined}
                  defaultOption={{
                    label: "All accounts",
                    value: null,
                  }}
                  onSelect={(o) => {
                    props.setSelectedAccount(o);
                    props.setFilters({ ...props.filters, assignedTo: o.value });
                  }}
                />
              ) : null}
              <Button
                text="New task"
                icon={PlusIcon}
                variant="positive"
                onClick={() => {
                  setTaskModal({});
                }}
                size="s"
              />
            </div>
          </div>
        </div>
        <div className="p-8 flex-1 flex flex-col">
          {tasksQuery.data && account && account.firm ? (
            <div className="flex gap-x-4">
              {/* <div className="w-1/5 p-2 rounded-md bg-concrete-100 max-h-[calc(100vh-10rem)]">
                <TasksNavigation firm={tasksQuery.data.firm} />
              </div> */}
              <TasksContent
                showActivity={props.showActivity}
                setShowActivity={props.setShowActivity}
                onNewTask={(dealId, accountId) => {
                  setTaskModal({ dealId, accountId });
                }}
                query={tasksQuery}
                filters={props.filters}
              />
            </div>
          ) : null}
        </div>
        {account && account.firm ? (
          <NewTaskModal
            open={taskModal !== null}
            onClose={() => {
              setTaskModal(null);
            }}
            firmID={account.firm.id}
            deal={
              tasksQuery.data && taskModal?.dealId
                ? (tasksQuery.data.firm.deals.find(
                    (d) => d.id === taskModal.dealId,
                  ) as Deal)
                : undefined
            }
            account={
              tasksQuery.data && taskModal?.accountId
                ? (tasksQuery.data.firm.accounts.find(
                    (a) => a.id === taskModal.accountId,
                  ) as Account)
                : undefined
            }
          />
        ) : null}
      </div>
    </PinnedDealsProvider>
  );
}

type DroppableKey = {
  dealId: string;
  status: TaskStatus;
};

function getDroppableKey(droppableId: string): DroppableKey {
  const [dealId, status] = droppableId.split("-");
  return { dealId, status: status as TaskStatus };
}

function TasksContent(props: {
  showActivity: boolean;
  setShowActivity: (show: boolean) => void;
  onNewTask: (dealId?: string, accountId?: string) => void;
  query: UseQueryResult<TasksQuery, unknown>;
  filters: TasksFilters;
}): JSX.Element {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const updateTasks = useUpdateTasksMutation(client);
  const { pinnedDeals } = usePinnedDeals();

  // Force re-render when pinnedDeals changes
  const [, setForceUpdate] = useState({});
  useEffect(() => {
    // Force a re-render when pinnedDeals changes
    console.log("TasksContent: pinnedDeals changed, forcing re-render");
    setForceUpdate({});
  }, [pinnedDeals]);

  const tasksQuery = props.query;

  const [groupedTasks, setGroupedTasks] = useState<GroupedTasks>({});

  // Get high priority tasks
  const highPriorityTasks = useMemo(() => {
    if (!tasksQuery.data) return [];

    const now = new Date();
    const threeDaysFromNow = new Date();
    threeDaysFromNow.setDate(now.getDate() + 3);
    const threeDaysFromNowUnix = Math.floor(threeDaysFromNow.getTime() / 1000);

    return tasksQuery.data.tasks.filter((task) => {
      // Include priority High or Urgent tasks that aren't completed
      const isHighPriority =
        (task.priority === TaskPriority.High ||
          task.priority === TaskPriority.Urgent) &&
        task.status !== TaskStatus.Completed;

      // Include tasks due in the next 3 days that aren't completed
      const isDueSoon =
        task.dueDate &&
        task.dueDate <= threeDaysFromNowUnix &&
        task.status !== TaskStatus.Completed;

      return isHighPriority || isDueSoon;
    });
  }, [tasksQuery.data]);

  // Apply pinned deals sorting to deal IDs
  const sortedDealIds = useMemo(() => {
    return Object.keys(groupedTasks)
      .filter((dealId) => dealId !== "nodeal")
      // filter out deals with no tasks
      // .filter((dealId) =>
      //   Object.keys(groupedTasks[dealId]).some(
      //     (status) => groupedTasks[dealId][status as TaskStatus].length > 0,
      //   ),
      // )
      .sort((a, b) => {
        const aIsPinned = pinnedDeals.includes(a);
        const bIsPinned = pinnedDeals.includes(b);

        if (aIsPinned && !bIsPinned) return -1;
        if (!aIsPinned && bIsPinned) return 1;

        // Use the original sort logic for non-pinned deals
        const aHasOnlyCompletedTasks =
          groupedTasks[a][TaskStatus.Completed].length ===
          groupedTasks[a][TaskStatus.InProgress].length +
            groupedTasks[a][TaskStatus.Todo].length;

        const bHasOnlyCompletedTasks =
          groupedTasks[b][TaskStatus.Completed].length ===
          groupedTasks[b][TaskStatus.InProgress].length +
            groupedTasks[b][TaskStatus.Todo].length;

        if (aHasOnlyCompletedTasks && !bHasOnlyCompletedTasks) return 1;
        if (!aHasOnlyCompletedTasks && bHasOnlyCompletedTasks) return -1;

        return 0;
      });
  }, [groupedTasks, pinnedDeals]);

  // Create a mapping of dealId to its index in the sorted list
  // This will be passed to DealTasks to determine initial collapsed state
  const dealIndexMap = useMemo(() => {
    return sortedDealIds.reduce((map, dealId, index) => {
      map[dealId] = index;
      return map;
    }, {} as Record<string, number>);
  }, [sortedDealIds]);

  useEffect(() => {
    if (!tasksQuery.data) {
      return;
    }

    // If filtering by deal status, don't include "nodeal" tasks
    const includeDealLessTasks = !props.filters.dealStatus;

    const deals = tasksQuery.data.firm.deals.filter((d) => {
      return (
        (props.filters.dealID ? d.id === props.filters.dealID : true) &&
        (props.filters.dealStatus
          ? d.status === props.filters.dealStatus
          : true)
      );
    });

    const accounts = tasksQuery.data.firm.accounts.filter((a) => {
      return props.filters.assignedTo
        ? a.id === props.filters.assignedTo
        : true;
    });

    const groupedTasks = groupTasks(
      tasksQuery.data.tasks as Task[],
      deals as Deal[],
      accounts as Account[],
      includeDealLessTasks,
      pinnedDeals,
    );
    setGroupedTasks(groupedTasks);
  }, [tasksQuery.data, props.filters, pinnedDeals]);

  function handleUpdateTasks(tasks: Task[]) {
    const inputs: UpdateTaskInput[] = tasks.map((task) => {
      return {
        id: task.id,
        sortOrder: task.sortOrder,
        status: task.status,
        assignedTo: task.assignedTo ? task.assignedTo.map((a) => a.id) : [],
        dealID: task.deal ? task.deal.id : "",
      };
    });

    updateTasks.mutate(
      {
        input: {
          taskInputs: inputs,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["Tasks"] });
          queryClient.invalidateQueries({
            queryKey: ["TaskActivity", { filters: { limit: 100 } }],
          });
        },
      },
    );
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.destination.droppableId === result.source.droppableId) {
      const { dealId, status } = getDroppableKey(result.source.droppableId);

      const newTasks = reorder(
        groupedTasks[dealId][status],
        result.source.index,
        result.destination.index,
      ).map((t, i) => ({
        ...t,
        sortOrder: i,
      }));

      setGroupedTasks({
        ...groupedTasks,
        [dealId]: {
          ...groupedTasks[dealId],
          [status]: newTasks,
        },
      });

      handleUpdateTasks(newTasks);
    }

    if (result.destination.droppableId !== result.source.droppableId) {
      const source = getDroppableKey(result.source.droppableId);
      const destination = getDroppableKey(result.destination.droppableId);

      const movedTasks = move<Task>(
        groupedTasks[source.dealId][source.status],
        groupedTasks[destination.dealId][destination.status],
        result.source,
        result.destination,
      );

      let newGroupedTasks = { ...groupedTasks };

      const newSourceTasks: Task[] = movedTasks[result.source.droppableId].map(
        (t, i) => ({
          ...t,
          sortOrder: i,
          status: source.status,
          deal:
            source.dealId === "nodeal"
              ? null
              : (tasksQuery.data?.firm.deals.find(
                  (d) => d.id === source.dealId,
                ) as Deal),
        }),
      ) as Task[];

      const newDestinationTasks = movedTasks[
        result.destination.droppableId
      ].map((t, i) => ({
        ...t,
        sortOrder: i,
        status: destination.status,
        deal:
          destination.dealId === "nodeal"
            ? null
            : (tasksQuery.data?.firm.deals.find(
                (d) => d.id === destination.dealId,
              ) as Deal),
      }));

      newGroupedTasks[source.dealId][source.status] = newSourceTasks;
      newGroupedTasks[destination.dealId][destination.status] =
        newDestinationTasks;

      setGroupedTasks(newGroupedTasks);

      handleUpdateTasks(newSourceTasks);
      handleUpdateTasks(newDestinationTasks);
    }
  }

  if (tasksQuery.isLoading || !tasksQuery.data) {
    return <Loading />;
  }

  if (tasksQuery.error) {
    return <div>Error</div>;
  }

  return (
    <div className="w-full">
      <DragDropContext
        onDragEnd={(result: DropResult) => {
          onDragEnd(result);
        }}
      >
        {Object.keys(groupedTasks).length > 0 ? (
          <>
            <div className="flex gap-x-4">
              <div
                className={classNames(
                  "flex-1 flex flex-col",
                  props.showActivity ? "w-2/3" : "w-full",
                )}
              >
                {/* High Priority Tasks Section */}
                {highPriorityTasks.length > 0 && (
                  <div className="mb-6">
                    <HighPriorityTasks tasks={highPriorityTasks} />
                  </div>
                )}

                <div className="flex items-center justify-between p-3">
                  <H3>Deal tasks</H3>
                  <button
                    onClick={() => props.setShowActivity(!props.showActivity)}
                    className="flex items-center gap-x-2 font-semibold bg-persian-100 hover:bg-persian-200 rounded-full px-2 py-1"
                  >
                    <ActivityIcon className="w-4 h-4 text-persian-800" />
                    <p className="text-persian-800 text-sm">
                      {props.showActivity ? "Hide activity" : "Show activity"}
                    </p>
                  </button>
                </div>

                <div
                  className={classNames(
                    "grid gap-x-4 content-start",
                    props.showActivity ? "grid-cols-1" : "lg:grid-cols-2",
                  )}
                >
                  {sortedDealIds.map((dealId) => (
                    <div key={dealId}>
                      {dealId ? (
                        <DealTasks
                          firm={tasksQuery.data.firm}
                          dealId={dealId}
                          groupedTasks={groupedTasks}
                          filters={props.filters}
                          tasks={tasksQuery.data.tasks}
                          onNewTask={props.onNewTask}
                          dealIndex={dealIndexMap[dealId]}
                          allDealIds={sortedDealIds}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
              {props.showActivity ? (
                <div className="w-1/3">
                  <TaskActivity />
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <Card margin="s 0 0 0">
            <div className="p-3 text-center">
              <p className="text-gray-500 text-sm">No tasks yet</p>
            </div>
          </Card>
        )}
      </DragDropContext>
    </div>
  );
}

const TASK_ACTIVITY_PAGE_SIZE = 15;

function TaskActivity() {
  const client = useGqlClient();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const taskActivities = useTaskActivityQuery(client, {
    filters: {
      limit: TASK_ACTIVITY_PAGE_SIZE,
      cursor: page > 1 ? (page * TASK_ACTIVITY_PAGE_SIZE).toString() : "",
      activityTypes: TASK_ACTIVITY_TYPES,
    },
  });

  useEffect(() => {
    if (taskActivities.data) {
      setTotal(taskActivities.data.taskActivity.total);
    }
  }, [taskActivities.data]);

  const totalPages = Math.floor(total / TASK_ACTIVITY_PAGE_SIZE);

  const activities = taskActivities.data?.taskActivity?.activities || [];
  const hasItems = activities.filter(a => 
    TASK_ACTIVITY_TYPES.includes(a.type)
  ).length > 0;

  return (
    <div className="w-full">
      <Card className={classNames(
        "flex flex-col",
        // Only use h-full if there are enough items to fill the space
        hasItems ? "max-h-full" : "h-auto"
      )}>
        <div className="flex items-center justify-between p-3 border-b border-concrete-100">
          <h3 className="text-lg font-bold text-concrete-950/95">Activity</h3>
          <div className="flex items-center gap-x-2">
            <button
              className="p-1 rounded-md hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
              disabled={page === 1}
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}
            >
              <ChevronDownIcon className="w-4 h-4 text-gray-500 rotate-90" />
            </button>

            <span className="text-sm text-gray-500">
              {page} / {totalPages}
            </span>

            <button
              className="p-1 rounded-md hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
              disabled={page === totalPages}
              onClick={() => {
                if (page < totalPages) {
                  setPage(page + 1);
                }
              }}
            >
              <ChevronDownIcon className="w-4 h-4 text-gray-500 -rotate-90" />
            </button>
          </div>
        </div>
        <div className={classNames(
          "overflow-y-auto p-3",
          hasItems ? "flex-1" : ""
        )}>
          <TaskActivityItems
            mode="verbose"
            items={
              taskActivities.data?.taskActivity.activities.filter((a) =>
                TASK_ACTIVITY_TYPES.includes(a.type),
              ) ?? []
            }
          />
        </div>
      </Card>
    </div>
  );
}

function DealTasks(props: {
  firm: TasksQuery["firm"];
  tasks: TasksQuery["tasks"];
  groupedTasks: GroupedTasks;
  dealId: string;
  filters: TasksFilters;
  onNewTask: (dealId?: string, accountId?: string) => void;
  dealIndex?: number;
  allDealIds: string[];
}) {
  const dealId = props.dealId;
  const deal = props.firm.deals.find((d) => d.id === dealId);
  const tasks = props.tasks.filter((t) =>
    dealId === "nodeal" ? !t.deal : t.deal && t.deal.id === dealId,
  );

  const { isPinned, togglePinDeal } = usePinnedDeals();
  const isPinnedDeal = isPinned(dealId);

  // Determine initial state based on the pair this deal belongs to
  // Deals at positions 0-1 will have the same state, 2-3 will have the same state, etc.
  const determineInitialShowState = () => {
    // This is the same logic as before to determine if a deal has non-completed tasks
    const hasNonCompletedTasks = tasks.filter((t) => t.status !== TaskStatus.Completed).length > 0;
    
    // If we have an index, use it to determine the pair this belongs to
    if (props.dealIndex !== undefined) {
      // Get the pair number (0 for deals 0-1, 1 for deals 2-3, etc.)
      const pairIndex = Math.floor(props.dealIndex / 2);
      
      // Get all deals in this same pair
      const dealsInSamePair = props.allDealIds.filter((_, idx: number) => 
        Math.floor(idx / 2) === pairIndex
      );
      
      // If any deal in this pair has non-completed tasks, show all deals in the pair
      const anyDealInPairHasNonCompletedTasks = dealsInSamePair.some((id: string) => {
        const dealTasks = props.tasks.filter((t) => 
          id === "nodeal" ? !t.deal : t.deal && t.deal.id === id
        );
        return dealTasks.some((t) => t.status !== TaskStatus.Completed);
      });
      
      return anyDealInPairHasNonCompletedTasks;
    }
    
    // Default: expand if there are non-completed tasks
    return hasNonCompletedTasks;
  };

  const [showTasks, setShowTasks] = useState(determineInitialShowState());

  const progress =
    (tasks.filter((t) => t.status === TaskStatus.Completed).length /
      tasks.length) *
    100;

  // Function to handle pinning/unpinning a deal
  const handleTogglePin = (e: MouseEvent) => {
    e.stopPropagation();
    togglePinDeal(dealId);
  };

  if (props.filters.dealID && dealId !== props.filters.dealID) {
    return null;
  }

  if (props.filters.assignedTo) {
    const assignedTasks = Object.values(props.groupedTasks[dealId])
      .map((tasks) => {
        return tasks.filter(
          (t: Task) =>
            t.assignedTo?.filter(
              (a: Account) => a.id === props.filters.assignedTo,
            ).length > 0,
        );
      })
      .flatMap((t) => t);

    if (assignedTasks.length === 0) {
      return null;
    }
  }

  const statuses = [
    TaskStatus.InProgress,
    TaskStatus.Todo,
    TaskStatus.Completed,
  ];

  return (
    <div className="m-2 mb-3">
      <Card>
        <div className="flex justify-between items-center p-3 border-b border-concrete-100">
          <div className="flex items-center gap-x-2">
            <button
              onClick={() => setShowTasks(!showTasks)}
              className="transition-all duration-200 ease-in-out"
            >
              <ChevronDownIcon
                className={`w-4 h-4 text-gray-500 ${
                  showTasks ? "" : "-rotate-90"
                }`}
              />
            </button>
            <CompanyLogo
              logo={deal?.company?.logo}
              withShadow={false}
              borderSize={"2"}
              size={28}
              name={deal?.company?.name ?? ""}
              bgColor={deal?.company?.logoColor ?? "#000"}
            />
            <div className="flex items-center gap-x-2">
              <p className="text-gray-700 font-semibold">
                {deal?.company?.name ?? "No deal"}
              </p>
              {isPinnedDeal && (
                <div className="bg-blue-100 rounded-full p-1">
                  <PinIcon className="w-3 h-3 text-blue-600" />
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="ml-3 w-[100px]">
              <ProgressBar
                progressColor="bg-green-400/80"
                backgroundColor="bg-green-100"
                progress={progress}
                height="h-1.5"
              />
            </div>
            <TripleDotMenu>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onNewTask(dealId);
                    }}
                    className={classNames(
                      active ? "bg-gray-50 text-blue-700" : "",
                      "block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer",
                    )}
                  >
                    New task
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={handleTogglePin}
                    className={classNames(
                      active ? "bg-gray-50 text-blue-700" : "",
                      "block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer",
                    )}
                  >
                    {isPinnedDeal ? "Unpin deal" : "Pin deal"}
                  </div>
                )}
              </Menu.Item>
            </TripleDotMenu>
          </div>
        </div>
        {showTasks ? (
          <div className="overflow-y-auto h-96">
            {statuses.map((status) => (
              <div key={status}>
                <StatusTasks
                  key={status}
                  tasks={
                    props.groupedTasks[props.dealId ?? "nodeal"][status] ?? []
                  }
                  status={status as TaskStatus}
                  dealId={props.dealId}
                />
              </div>
            ))}
          </div>
        ) : null}
      </Card>
    </div>
  );
}

function StatusIcon(props: { status: TaskStatus }) {
  switch (props.status) {
    case TaskStatus.Todo:
      return <CircleDashedIcon className="w-4 h-4 text-gray-400" />;
    case TaskStatus.InProgress:
      return <CircleIcon className="w-4 h-4 text-gray-400" />;
    case TaskStatus.Completed:
      return <CheckCircleIcon className="w-4 h-4 text-green-400" />;
    default:
      return null;
  }
}

function StatusHeader(props: { status: TaskStatus }) {
  return (
    <div className="w-full border-y border-concrete-100 bg-concrete-50 px-6 py-2 flex items-center gap-x-2">
      <StatusIcon status={props.status} />
      <p className="text-gray-700 text-sm font-semibold">
        {formatEnum(props.status)}
      </p>
    </div>
  );
}

function StatusTasks(props: {
  tasks: Task[];
  status: TaskStatus;
  dealId: string;
}) {
  const tasks = props.tasks;
  return (
    <div>
      <StatusHeader status={props.status} />
      <div className="">
        <Droppable droppableId={`${props.dealId}-${props.status}`}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {tasks.length === 0 ? (
                <div className="flex items-center flex-col py-3 justify-center">
                  <p className="text-gray-500 text-sm font-normal">
                    No tasks in {formatEnum(props.status)}
                  </p>
                </div>
              ) : null}
              {tasks
                .sort((a, b) => {
                  return a.sortOrder - b.sortOrder;
                })
                .map((task, i) => (
                  <Draggable key={task.id} draggableId={task.id} index={i}>
                    {(provided, snapshot) => (
                      <div
                        key={task.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classNames(
                          "hover:bg-concrete-50 px-6 py-2",
                          snapshot.isDragging
                            ? "bg-concrete-50 shadow-sm border border-concrete-100"
                            : "",
                        )}
                      >
                        <TaskListItem task={task} />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
}

function TaskListItem(props: { task: Task }) {
  const task = props.task;
  return (
    <NavLink key={task.id} to={`/tasks/${task.id}`}>
      <div key={task.id}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <TaskPriorityIcon priority={task.priority ?? undefined} />
            <div>
              <p className="font-semibold text-sm text-gray-700">
                {task.title}
              </p>
              <p className="text-xs text-gray-500/80">
                Created by {task.createdBy.firstName}{" "}
                {formatDistanceToNowStrict(fromUnixTime(task.createdAt), {
                  addSuffix: true,
                })}
              </p>
            </div>
          </div>
          <div className="flex gap-x-2">
            <>
              {task.dueDate ? (
                <DueDatePill dueDate={task.dueDate ?? undefined} />
              ) : null}
            </>

            <div className="border border-concrete-100 rounded-2xl px-2 flex items-center gap-x-1.5">
              <ChatBubbleLeftIcon className=" w-4 h-4 text-gray-400" />
              <p className="text-gray-500 text-sm">
                {
                  task.activity.filter(
                    (a) => a.type === TaskActivityType.Comment,
                  ).length
                }
              </p>
            </div>
            <div className="flex -space-x-2">
              {task.assignedTo.map((a) => (
                <div className="relative inline-block">
                  <UserPillInfo account={a} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
}

function groupTasks(
  tasks: Task[],
  deals: Deal[],
  accounts: Account[],
  includeDealLessTasks: boolean = true,
  pinnedDeals: string[] = [],
): GroupedTasks {
  const grouped: GroupedTasks = {};

  // Only include "nodeal" if not filtering by deal status
  if (includeDealLessTasks) {
    grouped["nodeal"] = {
      [TaskStatus.InProgress]: [],
      [TaskStatus.Todo]: [],
      [TaskStatus.Completed]: [],
      [TaskStatus.Cancelled]: [],
    };
  }

  for (const d of deals) {
    grouped[d.id] = {
      [TaskStatus.InProgress]: [],
      [TaskStatus.Todo]: [],
      [TaskStatus.Completed]: [],
      [TaskStatus.Cancelled]: [],
    };

    const filteredTasks = tasks.filter((t) => t.deal?.id === d.id);
    for (const status of Object.values(TaskStatus)) {
      grouped[d.id][status] = filteredTasks.filter((t) => t.status === status);
    }
  }

  // deals which only have completed tasks should be last
  const dealsWithOnlyCompletedTasks = Object.keys(grouped).filter((dealId) => {
    return (
      grouped[dealId][TaskStatus.Completed].length > 0 &&
      grouped[dealId][TaskStatus.Completed].length ===
        grouped[dealId][TaskStatus.InProgress].length +
          grouped[dealId][TaskStatus.Todo].length +
          grouped[dealId][TaskStatus.Completed].length
    );
  });

  const sortedDeals = Object.keys(grouped).sort((a, b) => {
    // Pinned deals first (this is the highest priority)
    const aIsPinned = pinnedDeals.includes(a);
    const bIsPinned = pinnedDeals.includes(b);

    if (aIsPinned && !bIsPinned) return -1;
    if (!aIsPinned && bIsPinned) return 1;

    if (
      dealsWithOnlyCompletedTasks.includes(a) &&
      !dealsWithOnlyCompletedTasks.includes(b)
    ) {
      return 1;
    }
    if (
      !dealsWithOnlyCompletedTasks.includes(a) &&
      dealsWithOnlyCompletedTasks.includes(b)
    ) {
      return -1;
    }

    return 0;
  });

  return sortedDeals.reduce((acc, dealId) => {
    acc[dealId] = grouped[dealId];
    return acc;
  }, {} as GroupedTasks);
}

function DueDatePill(props: { dueDate?: number }) {
  if (!props.dueDate) {
    return (
      <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
        <CalendarDaysIcon className="w-4 h-4 text-gray-400" />
        <p className="ml-1 text-xs text-gray-500">No due date</p>
      </div>
    );
  }

  const dd = fromUnixTime(props.dueDate);

  if (isBefore(dd, new Date())) {
    return (
      <div className="relative cursor-pointer border border-orange-200 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
        <CalendarDaysIcon className="w-4 h-4 text-orange-400" />
        <p className="ml-1 text-xs text-orange-600">
          Due {format(dd, "dd MMM")}
        </p>
      </div>
    );
  }

  return (
    <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
      <CalendarDaysIcon className="w-4 h-4 text-gray-400" />
      <p className="ml-1 text-xs text-gray-500">Due {format(dd, "dd MMM")}</p>
    </div>
  );
}

function UserPillInfo(props: {
  account: Pick<Account, "id" | "name" | "avatar" | "avatarColor">;
  onRemove?: () => void;
}) {
  return (
    <div className="flex flex-wrap group justify-center relative">
      <div className="cursor-pointer relative flex items-center rounded-2xl py-1">
        <Avatar account={props.account} size="s" />
      </div>
      {props.onRemove ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (props.onRemove) props.onRemove();
          }}
          className="hidden group-hover:block absolute -top-1.5 -right-2.5 group"
        >
          <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
        </button>
      ) : null}
    </div>
  );
}
