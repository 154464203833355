import { GraphQLClient } from "graphql-request";

import {
  useMutation,
  useQuery,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit["headers"],
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders,
    });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type Access = {
  __typename?: "Access";
  id: Scalars["String"]["output"];
  type: AccessType;
};

export type AccessActivity = {
  __typename?: "AccessActivity";
  account?: Maybe<Account>;
  dealGroup?: Maybe<DealGroup>;
  fileDifferences?: Maybe<GranularAccessChanges>;
  folderDifferences?: Maybe<GranularAccessChanges>;
  permission?: Maybe<DataRoomPermission>;
};

export type AccessControl = {
  __typename?: "AccessControl";
  entityID: Scalars["String"]["output"];
  entityType: AccessControlEntityType;
  id: Scalars["ID"]["output"];
  permissionLevel: AccessControlPermissionLevel;
  resourceID: Scalars["String"]["output"];
  resourceType: AccessControlResourceType;
};

export type AccessControlCheckInput = {
  entityType: AccessControlEntityType;
  requiredPermissionLevels: Array<AccessControlPermissionLevel>;
  resourceID?: InputMaybe<Scalars["String"]["input"]>;
  resourceType: AccessControlResourceType;
};

export type AccessControlCheckResponse = {
  __typename?: "AccessControlCheckResponse";
  hasAccess: Scalars["Boolean"]["output"];
};

export type AccessControlDealAccount = {
  __typename?: "AccessControlDealAccount";
  accessControl: AccessControl;
  dealAccount: DealAccount;
};

export type AccessControlDealGroup = {
  __typename?: "AccessControlDealGroup";
  accessControl: AccessControl;
  accessControlDealAccounts: Array<AccessControlDealAccount>;
  dealGroup: DealGroup;
};

export enum AccessControlEntityType {
  Account = "ACCOUNT",
  DealAccount = "DEAL_ACCOUNT",
  DealGroup = "DEAL_GROUP",
}

export enum AccessControlPermissionLevel {
  Admin = "ADMIN",
  Answer = "ANSWER",
  DraftAnswer = "DRAFT_ANSWER",
  Edit = "EDIT",
  NoAccess = "NO_ACCESS",
  View = "VIEW",
}

export enum AccessControlResourceType {
  Ddql = "DDQL",
  Question = "QUESTION",
}

export type AccessMatrix = {
  __typename?: "AccessMatrix";
  accountID: Scalars["String"]["output"];
  entityKey: Scalars["String"]["output"];
  hasAccess: Scalars["Boolean"]["output"];
};

export enum AccessType {
  Download = "DOWNLOAD",
  NoAccess = "NO_ACCESS",
  View = "VIEW",
  Write = "WRITE",
}

export type Account = {
  __typename?: "Account";
  accountType: AccountType;
  avatar: Scalars["String"]["output"];
  avatarColor: Scalars["String"]["output"];
  createdAt: Scalars["Int"]["output"];
  dealAccounts: Array<DealAccount>;
  deals: Array<Deal>;
  email: Scalars["String"]["output"];
  firm?: Maybe<Firm>;
  firmAccountType?: Maybe<FirmAccountType>;
  firstName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  notifications: Array<Notification>;
  status: AccountStatus;
  unreadNotificationCount: Scalars["Int"]["output"];
};

export enum AccountStatus {
  Active = "ACTIVE",
  Pending = "PENDING",
}

export enum AccountType {
  Bot = "BOT",
  User = "USER",
}

export type ActivateAccountResponse = {
  __typename?: "ActivateAccountResponse";
  account: Account;
};

export type AddDeepSearchFileReferenceInput = {
  annotationID: Scalars["String"]["input"];
  deepSearchFileID: Scalars["String"]["input"];
  pageIndex: Scalars["Int"]["input"];
  quote: Scalars["String"]["input"];
  rectsOnPage: Array<Array<Scalars["Float"]["input"]>>;
};

export type Answer = {
  __typename?: "Answer";
  answer: Scalars["String"]["output"];
  reason: Scalars["String"]["output"];
  references: Array<AnswerReference>;
};

export type AnswerReference = {
  __typename?: "AnswerReference";
  file: DataRoomFile;
  pageIndex: Scalars["Int"]["output"];
  quote: Scalars["String"]["output"];
  rectsOnPage: Array<Array<Scalars["Float"]["output"]>>;
};

export type AuthTokens = {
  __typename?: "AuthTokens";
  /**
   * An access token to use to make authenticated requests to this API. This token is
   * generally quite short lived but a new one can be obtained by using the refreshToken
   */
  accessToken: Scalars["String"]["output"];
  /**
   * A token that can be used to get a new access token. This token is long-lived and
   * so should be stored securely by the client
   */
  refreshToken: Scalars["String"]["output"];
};

export type AuthenticateResponse = {
  __typename?: "AuthenticateResponse";
  account: Account;
  tokens: AuthTokens;
};

export enum ChangeSummaryStatus {
  Failed = "FAILED",
  Pending = "PENDING",
  Success = "SUCCESS",
}

export type Column = {
  __typename?: "Column";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type ColumnInput = {
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type Comment = {
  __typename?: "Comment";
  comment: Scalars["String"]["output"];
  files: Array<DataRoomFile>;
};

export type Company = {
  __typename?: "Company";
  createdAt: Scalars["Int"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  logo: Scalars["String"]["output"];
  logoColor: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  website: Scalars["String"]["output"];
};

export enum CompanyAccountAccessType {
  Admin = "ADMIN",
  Buyer = "BUYER",
  Guest = "GUEST",
  Seller = "SELLER",
  Staff = "STAFF",
}

export type CompanyInput = {
  description: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  website: Scalars["String"]["input"];
};

export type CopyDataRoomFileInput = {
  dataRoomPermissionID: Scalars["String"]["input"];
  fromDataRoomFileID: Scalars["String"]["input"];
  fromDataRoomID: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  toDataRoomFolderID: Scalars["String"]["input"];
  toDataRoomID: Scalars["String"]["input"];
};

export type CreateCommentInput = {
  comment: Scalars["String"]["input"];
  dataRoomFileErrorId?: InputMaybe<Scalars["String"]["input"]>;
  dataRoomFileId?: InputMaybe<Scalars["String"]["input"]>;
  dataRoomFolderId?: InputMaybe<Scalars["String"]["input"]>;
  dataRoomId?: InputMaybe<Scalars["String"]["input"]>;
  files: Array<Scalars["String"]["input"]>;
  questionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateDataRoomFileResponse = {
  __typename?: "CreateDataRoomFileResponse";
  dataRoomFile: DataRoomFile;
  initialVersion: DataRoomFileVersion;
  multiPartUploadID: Scalars["String"]["output"];
  parts: Array<MultipartUrl>;
};

export type CreateDdqlFileInput = {
  fileName: Scalars["String"]["input"];
  fileType: Scalars["String"]["input"];
  parts: Scalars["Int"]["input"];
};

export type CreateDdqlImportResponse = {
  __typename?: "CreateDdqlImportResponse";
  ddql: Ddql;
  multiPartUploadID: Scalars["String"]["output"];
  parts: Array<MultipartUrl>;
};

export type CreateDdqlInput = {
  dealGroupID: Scalars["String"]["input"];
  file?: InputMaybe<CreateDdqlFileInput>;
  forDealGroupID?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  parentDdqlID?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateDdqlResponse = {
  __typename?: "CreateDdqlResponse";
  ddql: Ddql;
  multiPartUploadID: Scalars["String"]["output"];
  parts: Array<MultipartUrl>;
};

export type CreateDealAccountInput = {
  accountId: Scalars["String"]["input"];
  ndaSigned: Scalars["Boolean"]["input"];
  role: DealRole;
};

export type CreateDealAccountsInput = {
  accounts: Array<CreateDealAccountInput>;
  dealGroupId: Scalars["String"]["input"];
  dealId: Scalars["String"]["input"];
};

export type CreateDealExportInput = {
  dataRoomFileIDs: Array<Scalars["String"]["input"]>;
  dealID: Scalars["String"]["input"];
  keepFolderStructure: Scalars["Boolean"]["input"];
  name: Scalars["String"]["input"];
};

export type CreateDealFirmInput = {
  createNdaFile: Scalars["Boolean"]["input"];
  dataRoomAccessPreNda: Scalars["Boolean"]["input"];
  dealGroupId: Scalars["String"]["input"];
  filePermissions: Array<FileAccessInput>;
  folderPermissions: Array<FolderAccessInput>;
  name: Scalars["String"]["input"];
  overrideDataRoomAccess: Scalars["Boolean"]["input"];
};

export type CreateDealGroupInput = {
  createNdaFile: Scalars["Boolean"]["input"];
  dataRoomPermissionId: Scalars["String"]["input"];
  dealAccessStatus: DealAccessStatus;
  dealId: Scalars["String"]["input"];
  filePermissions: Array<FileAccessInput>;
  folderPermissions: Array<FolderAccessInput>;
  invites: Array<CreateDealInviteInput>;
  name: Scalars["String"]["input"];
  overrideDataRoomAccess: Scalars["Boolean"]["input"];
  type: DealGroupType;
};

export type CreateDealInput = {
  company: CompanyInput;
  runner: DealRunner;
};

export type CreateDealInviteInput = {
  autoAdmit: Scalars["Boolean"]["input"];
  dataRoomPermissionID: Scalars["String"]["input"];
  dealGroupID: Scalars["String"]["input"];
  dealID: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  filePermissions: Array<FileAccessInput>;
  folderPermissions: Array<FolderAccessInput>;
  message: Scalars["String"]["input"];
  overrideDataRoomAccess: Scalars["Boolean"]["input"];
  role: DealRole;
};

export type CreateDealPropertyInput = {
  firmID: Scalars["String"]["input"];
  propertyKey: Scalars["String"]["input"];
};

export type CreateDealThreadInput = {
  dealID: Scalars["String"]["input"];
};

export type CreateDealThreadMessageInput = {
  dealThreadID: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
};

export type CreateDealThreadMessageResponse = {
  __typename?: "CreateDealThreadMessageResponse";
  dealThreadMessage: DealThreadMessage;
  responseMessage: DealThreadMessage;
};

export type CreateDeepSearchFileInput = {
  dataRoomFileID: Scalars["String"]["input"];
  dataRoomFileVersionID: Scalars["String"]["input"];
  deepSearchID: Scalars["String"]["input"];
  references: Array<DeepSearchFileReferenceInput>;
};

export type CreateDeepSearchInput = {
  dataRoomFileIDs: Array<Scalars["String"]["input"]>;
  dealID: Scalars["String"]["input"];
};

export type CreateDeepSearchThreadMessageInput = {
  deepSearchID: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
};

export type CreateDueDiligenceChecklistItemInput = {
  assignedToID?: InputMaybe<Scalars["String"]["input"]>;
  dealGroupID: Scalars["String"]["input"];
  dueDate?: InputMaybe<Scalars["Int"]["input"]>;
  title: Scalars["String"]["input"];
  type: DueDiligenceChecklistItemType;
};

export type CreateErrorCheckInput = {
  dataRoomFileIDs: Array<Scalars["String"]["input"]>;
  dealID: Scalars["String"]["input"];
};

export type CreateFirmInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  description: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  type: FirmType;
  website?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateFirmInviteInput = {
  email: Scalars["String"]["input"];
  firmID: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
  role: FirmAccountType;
};

export type CreateFirmInvitesInput = {
  firmID: Scalars["String"]["input"];
  invites: Array<CreateFirmInviteInput>;
};

export type CreateIgnoreErrorRuleInput = {
  dataRoomFileID?: InputMaybe<Scalars["String"]["input"]>;
  dealID: Scalars["String"]["input"];
  key?: InputMaybe<Scalars["String"]["input"]>;
  keyFiles: Array<Scalars["String"]["input"]>;
};

export type CreateOrUpdateAccessControlInput = {
  dealID: Scalars["String"]["input"];
  entityID: Scalars["String"]["input"];
  entityType: AccessControlEntityType;
  permissionLevel: AccessControlPermissionLevel;
  resourceID: Scalars["String"]["input"];
  resourceType: AccessControlResourceType;
};

export type CreateOrUpdateAccessControlsInput = {
  accessControls: Array<CreateOrUpdateAccessControlInput>;
};

export type CreateRedlineExportInput = {
  dataRoomFileVersionSourceID: Scalars["String"]["input"];
  dataRoomFileVersionTargetID: Scalars["String"]["input"];
  dealID: Scalars["String"]["input"];
};

export type CreateTaskCommentInput = {
  comment: Scalars["String"]["input"];
  taskId: Scalars["String"]["input"];
};

export type CreateTaskInput = {
  assignedTo?: InputMaybe<Array<Scalars["String"]["input"]>>;
  dealID?: InputMaybe<Scalars["String"]["input"]>;
  description: Scalars["String"]["input"];
  dueDate?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<TaskPriority>;
  title: Scalars["String"]["input"];
};

export type CurrentDealStageInput = {
  dealStageId: Scalars["String"]["input"];
  status: DealStageStatus;
};

export type DataRoom = {
  __typename?: "DataRoom";
  activity: Array<DealActivity>;
  allFiles: Array<DataRoomFile>;
  allFolders: Array<DataRoomFolder>;
  context: DataRoomContext;
  createdAt: Scalars["Int"]["output"];
  deal: Deal;
  folder: DataRoomFolder;
  id: Scalars["ID"]["output"];
  permissions: Array<DataRoomPermission>;
  updatedAt: Scalars["Int"]["output"];
};

export type DataRoomActivityMetrics = {
  __typename?: "DataRoomActivityMetrics";
  downloads: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  views: Scalars["Int"]["output"];
};

export type DataRoomActivityMetricsInput = {
  accountID?: InputMaybe<Scalars["String"]["input"]>;
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  dataRoomFileID?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Int"]["input"]>;
  startDate?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DataRoomActivityMetricsResponse = {
  __typename?: "DataRoomActivityMetricsResponse";
  activity: Array<DealActivity>;
  cursor: Scalars["String"]["output"];
  metrics: Array<DataRoomActivityMetrics>;
};

export enum DataRoomContext {
  Deal = "DEAL",
  Firm = "FIRM",
}

export type DataRoomFile = {
  __typename?: "DataRoomFile";
  activity: Array<DealActivity>;
  context: DataRoomContext;
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  currentLiveVersion: DataRoomFileVersion;
  deletedAt?: Maybe<Scalars["Int"]["output"]>;
  deletionType?: Maybe<DeletionType>;
  description: Scalars["String"]["output"];
  documentSummary: DocumentSummary;
  documentSummaryStatus: DocumentSummaryStatus;
  errorCheckingStatus: ErrorCheckingStatus;
  fileType: FileType;
  folderId: Scalars["String"]["output"];
  hasAccess: Scalars["Boolean"]["output"];
  hasDownloadAccess: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  latestVersion: DataRoomFileVersion;
  name: Scalars["String"]["output"];
  onlineStatus: DataRoomFileOnlineStatus;
  parentFolders: Array<DataRoomFolder>;
  permission: DataRoomPermission;
  questions: Array<Question>;
  stats: DataRoomFileStats;
  updatedAt: Scalars["Int"]["output"];
  uploadStatus: DataRoomFileUploadStatus;
  url: Scalars["String"]["output"];
  versions: Array<DataRoomFileVersion>;
};

export type DataRoomFileError = {
  __typename?: "DataRoomFileError";
  baseKey: Scalars["String"]["output"];
  createdAt: Scalars["Int"]["output"];
  dismissalReason: Scalars["String"]["output"];
  dismissedBy?: Maybe<Account>;
  files: Array<DataRoomFileErrorFile>;
  id: Scalars["ID"]["output"];
  key: Scalars["String"]["output"];
  resolvedBy?: Maybe<Account>;
  resolvedMessage: Scalars["String"]["output"];
  status: DataRoomFileErrorStatus;
  title: Scalars["String"]["output"];
};

export type DataRoomFileErrorFile = {
  __typename?: "DataRoomFileErrorFile";
  file: DataRoomFile;
  key: Scalars["String"]["output"];
  status: ErrorCheckingStatus;
  value: Scalars["Float"]["output"];
};

export enum DataRoomFileErrorStatus {
  Checking = "CHECKING",
  Dismissed = "DISMISSED",
  Pending = "PENDING",
  Resolved = "RESOLVED",
}

export enum DataRoomFileOnlineStatus {
  Offline = "OFFLINE",
  Online = "ONLINE",
}

export enum DataRoomFileSimilarityStatus {
  Accepted = "ACCEPTED",
  Detected = "DETECTED",
  Rejected = "REJECTED",
}

export type DataRoomFileStats = {
  __typename?: "DataRoomFileStats";
  activities: Scalars["Int"]["output"];
  comments: Scalars["Int"]["output"];
  downloads: Scalars["Int"]["output"];
  versions: Scalars["Int"]["output"];
  views: Scalars["Int"]["output"];
};

export enum DataRoomFileUploadStatus {
  Pending = "PENDING",
  Preparing = "PREPARING",
  Ready = "READY",
  Unzipping = "UNZIPPING",
  Uploaded = "UPLOADED",
}

export type DataRoomFileVersion = {
  __typename?: "DataRoomFileVersion";
  activity: Array<DealActivity>;
  changes: FileVersionChanges;
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  fileName: Scalars["String"]["output"];
  fileType: FileType;
  hash: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  pspdfkitDocumentId: Scalars["String"]["output"];
  pspdfkitToken: Scalars["String"]["output"];
  shortHash: Scalars["String"]["output"];
  summary: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  versionNumber: Scalars["Int"]["output"];
};

export type DataRoomFolder = {
  __typename?: "DataRoomFolder";
  context: DataRoomContext;
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  dataRoom: DataRoom;
  deal?: Maybe<Deal>;
  dealGroup?: Maybe<DealGroup>;
  files: Array<DataRoomFile>;
  folders: Array<DataRoomFolder>;
  hasFolderWriteAccess: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  parentFolders: Array<DataRoomFolder>;
  permission?: Maybe<DataRoomPermission>;
};

export type DataRoomPermission = {
  __typename?: "DataRoomPermission";
  accessLevel: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["String"]["output"];
  fileCount: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type DataRoomPermissionInput = {
  accessLevel: Scalars["Int"]["input"];
  description: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type Ddql = {
  __typename?: "Ddql";
  accessControlDealAccounts: Array<AccessControlDealAccount>;
  accessControlDealGroups: Array<AccessControlDealGroup>;
  answeredChildQuestions: Scalars["Int"]["output"];
  children: Array<Ddql>;
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  dealGroup: DealGroup;
  forDealGroup?: Maybe<DealGroup>;
  id: Scalars["ID"]["output"];
  index: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  questions: Array<Question>;
  sourceFileName: Scalars["String"]["output"];
  sourceFileType: Scalars["String"]["output"];
  status?: Maybe<DdqlStatus>;
  totalChildQuestions: Scalars["Int"]["output"];
};

export type DdqlImport = {
  __typename?: "DdqlImport";
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  dealGroup: DealGroup;
  fileName: Scalars["String"]["output"];
  fileType: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  status: DdqlImportStatus;
};

export enum DdqlImportStatus {
  Complete = "COMPLETE",
  Failed = "FAILED",
  Importing = "IMPORTING",
}

export enum DdqlStatus {
  Imported = "IMPORTED",
  Importing = "IMPORTING",
  ImportFailed = "IMPORT_FAILED",
  Initial = "INITIAL",
  Published = "PUBLISHED",
}

export type Deal = {
  __typename?: "Deal";
  activeDealAccount: DealAccount;
  allGroups: Array<DealGroup>;
  buyers: Array<DealGroup>;
  company: Company;
  createdAt: Scalars["Int"]["output"];
  currentDealStage?: Maybe<DealStage>;
  currentDealStageStatus?: Maybe<DealStageStatus>;
  dataRoom: DataRoom;
  dataRoomFileErrors: Array<DataRoomFileError>;
  ddqls: Array<Ddql>;
  dealAccounts: Array<DealAccount>;
  dealExports: Array<DealExport>;
  dealPropertyValues: Array<DealPropertyValue>;
  dealStages: Array<DealStage>;
  financialStatements: FinancialStatements;
  guestGroups: Array<DealGroup>;
  id: Scalars["ID"]["output"];
  invites: Array<DealInvite>;
  otherGuests: Array<DealAccount>;
  ownerGroups: Array<DealGroup>;
  questions: Array<Question>;
  runner: DealRunner;
  sellers: Array<DealGroup>;
  status: DealStatus;
  transactionFolder?: Maybe<DataRoomFolder>;
};

export enum DealAccessStatus {
  Blocked = "BLOCKED",
  Granted = "GRANTED",
  PendingManualApproval = "PENDING_MANUAL_APPROVAL",
  PendingNda = "PENDING_NDA",
}

export type DealAccount = {
  __typename?: "DealAccount";
  account: Account;
  allFolders: Array<DataRoomFolder>;
  createdAt: Scalars["Int"]["output"];
  dataRoomPermission?: Maybe<DataRoomPermission>;
  dealAccessStatus: DealAccessStatus;
  dealFirm?: Maybe<DealFirm>;
  dealGroup?: Maybe<DealGroup>;
  dealThread?: Maybe<DealThread>;
  granularAccess: GranularAccess;
  id: Scalars["ID"]["output"];
  lastSeen: Scalars["Int"]["output"];
  lastViewed: Scalars["Int"]["output"];
  ndaSignedAt?: Maybe<Scalars["Int"]["output"]>;
  overrideDataRoomAccess: Scalars["Boolean"]["output"];
  role: DealRole;
};

export type DealActivity = {
  __typename?: "DealActivity";
  accessActivity?: Maybe<AccessActivity>;
  account: Account;
  accountImpersonation?: Maybe<Account>;
  comment?: Maybe<Comment>;
  createdAt: Scalars["Int"]["output"];
  dataRoomFileError?: Maybe<DataRoomFileError>;
  file?: Maybe<DataRoomFile>;
  folder?: Maybe<DataRoomFolder>;
  id: Scalars["ID"]["output"];
  invite?: Maybe<DealInvite>;
  mergedFile?: Maybe<Scalars["Boolean"]["output"]>;
  question?: Maybe<Question>;
  type: DealActivityType;
  version?: Maybe<DataRoomFileVersion>;
};

export type DealActivityInput = {
  accountID?: InputMaybe<Scalars["String"]["input"]>;
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  dataRoomFileErrorID?: InputMaybe<Scalars["String"]["input"]>;
  dataRoomFileID?: InputMaybe<Scalars["String"]["input"]>;
  dataRoomFolderID?: InputMaybe<Scalars["String"]["input"]>;
  dataRoomID?: InputMaybe<Scalars["String"]["input"]>;
  dealGroupID?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Int"]["input"]>;
  fileActivitiesInput?: InputMaybe<FileActivitiesInput>;
  startDate?: InputMaybe<Scalars["Int"]["input"]>;
  type?: InputMaybe<DealActivityType>;
};

export type DealActivityResponse = {
  __typename?: "DealActivityResponse";
  activity: Array<DealActivity>;
  cursor: Scalars["String"]["output"];
  hasMore: Scalars["Boolean"]["output"];
};

export enum DealActivityType {
  AccountBlocked = "ACCOUNT_BLOCKED",
  AccountImpersonation = "ACCOUNT_IMPERSONATION",
  AccountRoleChanged = "ACCOUNT_ROLE_CHANGED",
  AccountUnblocked = "ACCOUNT_UNBLOCKED",
  AccountUpdatedFileAccess = "ACCOUNT_UPDATED_FILE_ACCESS",
  AccountUpdatedFolderAccess = "ACCOUNT_UPDATED_FOLDER_ACCESS",
  Comment = "COMMENT",
  DataRoomFileErrorCreated = "DATA_ROOM_FILE_ERROR_CREATED",
  DataRoomFileErrorDismissed = "DATA_ROOM_FILE_ERROR_DISMISSED",
  DataRoomFileErrorResolved = "DATA_ROOM_FILE_ERROR_RESOLVED",
  DataRoomFileSimilarityAccepted = "DATA_ROOM_FILE_SIMILARITY_ACCEPTED",
  FileCreated = "FILE_CREATED",
  FileDeleted = "FILE_DELETED",
  FileDownloaded = "FILE_DOWNLOADED",
  FileUpdated = "FILE_UPDATED",
  FileVersionCreated = "FILE_VERSION_CREATED",
  FileVersionDeleted = "FILE_VERSION_DELETED",
  FileViewed = "FILE_VIEWED",
  FolderCreated = "FOLDER_CREATED",
  GroupBlocked = "GROUP_BLOCKED",
  GroupCreated = "GROUP_CREATED",
  GroupUnblocked = "GROUP_UNBLOCKED",
  GroupUpdatedFileAccess = "GROUP_UPDATED_FILE_ACCESS",
  GroupUpdatedFolderAccess = "GROUP_UPDATED_FOLDER_ACCESS",
  InviteCreated = "INVITE_CREATED",
  QuestionAnswered = "QUESTION_ANSWERED",
  QuestionAsked = "QUESTION_ASKED",
  QuestionViewed = "QUESTION_VIEWED",
}

export type DealExport = {
  __typename?: "DealExport";
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  s3PresignedUrl?: Maybe<Scalars["String"]["output"]>;
  status: DealExportStatus;
};

export enum DealExportStatus {
  Complete = "COMPLETE",
  Exporting = "EXPORTING",
  Failed = "FAILED",
}

export type DealFirm = {
  __typename?: "DealFirm";
  createdAt: Scalars["Int"]["output"];
  dataRoomAccessPreNda: Scalars["Boolean"]["output"];
  dealAccounts: Array<DealAccount>;
  dealGroup: DealGroup;
  granularAccess: GranularAccess;
  id: Scalars["ID"]["output"];
  invites: Array<DealInvite>;
  name: Scalars["String"]["output"];
  ndaDataRoomFile?: Maybe<DataRoomFile>;
  overrideDataRoomAccess: Scalars["Boolean"]["output"];
  type: DealFirmType;
};

export enum DealFirmType {
  Extra = "EXTRA",
  Original = "ORIGINAL",
}

export type DealGroup = {
  __typename?: "DealGroup";
  accounts: Array<DealAccount>;
  activity: Array<DealActivity>;
  createdAt: Scalars["Int"]["output"];
  currentDealStage?: Maybe<DealStage>;
  currentDealStageStatus?: Maybe<DealStageStatus>;
  dataRoomPermission: DataRoomPermission;
  ddChecklistItems: Array<DueDiligenceChecklistItem>;
  ddqlImports: Array<DdqlImport>;
  ddqls: Array<Ddql>;
  ddqlsForGroup: Array<Ddql>;
  deal: Deal;
  dealAccessStatus: DealAccessStatus;
  dealFirms: Array<DealFirm>;
  files: Array<DataRoomFile>;
  granularAccess: GranularAccess;
  id: Scalars["ID"]["output"];
  invites: Array<DealInvite>;
  lastSeen?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  ndaDataRoomFile?: Maybe<DataRoomFile>;
  ndaSignedAt?: Maybe<Scalars["Int"]["output"]>;
  ndaSignedBy?: Maybe<Account>;
  overrideDataRoomAccess: Scalars["Boolean"]["output"];
  questions: Array<Question>;
  questionsForGroup: Array<Question>;
  type: DealGroupType;
};

export enum DealGroupType {
  Buyer = "BUYER",
  Guest = "GUEST",
  Owner = "OWNER",
  Seller = "SELLER",
}

export type DealInvite = {
  __typename?: "DealInvite";
  createdAt: Scalars["Int"]["output"];
  dataRoomPermission?: Maybe<DataRoomPermission>;
  deal: Deal;
  dealGroup?: Maybe<DealGroup>;
  email: Scalars["String"]["output"];
  granularAccess?: Maybe<GranularAccess>;
  id: Scalars["ID"]["output"];
  invitedBy: Account;
  message: Scalars["String"]["output"];
  overrideDataRoomAccess: Scalars["Boolean"]["output"];
  role: DealRole;
  status: DealInviteStatus;
};

export enum DealInviteStatus {
  Approved = "APPROVED",
  Requested = "REQUESTED",
}

export type DealProperty = {
  __typename?: "DealProperty";
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  dealPropertyValues: Array<DealPropertyValue>;
  firmID: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  propertyKey: Scalars["String"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type DealPropertyValue = {
  __typename?: "DealPropertyValue";
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  deal: Deal;
  dealProperty: DealProperty;
  dealPropertyID: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastUpdatedBy: Account;
  propertyKey: Scalars["String"]["output"];
  propertyValue: Scalars["String"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export enum DealRole {
  BuyerAdmin = "BUYER_ADMIN",
  BuyerStaff = "BUYER_STAFF",
  DealAdmin = "DEAL_ADMIN",
  DealAdminViewOnly = "DEAL_ADMIN_VIEW_ONLY",
  DealOwner = "DEAL_OWNER",
  Guest = "GUEST",
  GuestAdmin = "GUEST_ADMIN",
  GuestUploader = "GUEST_UPLOADER",
  SellerAdmin = "SELLER_ADMIN",
  SellerStaff = "SELLER_STAFF",
}

export enum DealRunner {
  Buyer = "BUYER",
  Seller = "SELLER",
}

export type DealStage = {
  __typename?: "DealStage";
  createdAt: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  index: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export enum DealStageStatus {
  Completed = "COMPLETED",
  Current = "CURRENT",
  Overdue = "OVERDUE",
  Upcoming = "UPCOMING",
}

export enum DealStatus {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
}

export type DealThread = {
  __typename?: "DealThread";
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  deal: Deal;
  dealAccount: DealAccount;
  id: Scalars["ID"]["output"];
  messages: Array<DealThreadMessage>;
  summary: Scalars["String"]["output"];
};

export type DealThreadAnnotation = {
  __typename?: "DealThreadAnnotation";
  citation: DealThreadAnnotationCitation;
  text: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type DealThreadAnnotationCitation = {
  __typename?: "DealThreadAnnotationCitation";
  file: DataRoomFile;
  quote: Scalars["String"]["output"];
};

export type DealThreadMessage = {
  __typename?: "DealThreadMessage";
  content: DealThreadMessageContent;
  createdAt: Scalars["Int"]["output"];
  files: Array<DataRoomFile>;
  id: Scalars["ID"]["output"];
  role: DealThreadMessageRole;
  status: DealThreadMessageStatus;
  thread: DealThread;
};

export type DealThreadMessageContent = {
  __typename?: "DealThreadMessageContent";
  files: Array<DealThreadMessageContentFile>;
  quotes: Array<DealThreadMessageContentQuote>;
  text: DealThreadMessageText;
  type: Scalars["String"]["output"];
};

export type DealThreadMessageContentFile = {
  __typename?: "DealThreadMessageContentFile";
  file: DataRoomFile;
  pageIndex?: Maybe<Scalars["Int"]["output"]>;
  rectsOnPage: Array<Array<Scalars["Float"]["output"]>>;
};

export type DealThreadMessageContentFileInput = {
  dataRoomFileID: Scalars["String"]["input"];
  pageIndex?: InputMaybe<Scalars["Int"]["input"]>;
  rectsOnPage: Array<Array<Scalars["Float"]["input"]>>;
};

export type DealThreadMessageContentQuote = {
  __typename?: "DealThreadMessageContentQuote";
  dataRoomFileName: Scalars["String"]["output"];
  dataRoomFileType: FileType;
  file: DataRoomFile;
  pageIndex?: Maybe<Scalars["Int"]["output"]>;
  quote: Scalars["String"]["output"];
  rectsOnPage?: Maybe<Array<Array<Scalars["Float"]["output"]>>>;
  status: DealThreadMessageContentQuoteStatus;
};

export enum DealThreadMessageContentQuoteStatus {
  Unverified = "UNVERIFIED",
  Verified = "VERIFIED",
  Verifying = "VERIFYING",
}

export enum DealThreadMessageRole {
  Ai = "ai",
  User = "user",
}

export enum DealThreadMessageStatus {
  Cancelled = "CANCELLED",
  Failed = "FAILED",
  Sending = "SENDING",
  Sent = "SENT",
  Thinking = "THINKING",
  Verifying = "VERIFYING",
}

export type DealThreadMessageText = {
  __typename?: "DealThreadMessageText";
  annotations: Array<DealThreadAnnotation>;
  value: Scalars["String"]["output"];
};

export type DeepSearch = {
  __typename?: "DeepSearch";
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  files: Array<DeepSearchFile>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  status: DeepSearchStatus;
  threadMessages: Array<DeepSearchThreadMessage>;
  vectorScope: DeepSearchVectorScope;
};

export type DeepSearchFile = {
  __typename?: "DeepSearchFile";
  currentVersion: DataRoomFileVersion;
  file: DataRoomFile;
  hidden: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  references: Array<DeepSearchFileReference>;
  sortIndex: Scalars["Int"]["output"];
  status: DeepSearchFileStatus;
};

export type DeepSearchFileReference = {
  __typename?: "DeepSearchFileReference";
  annotationID: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  pageIndex: Scalars["Int"]["output"];
  quote: Scalars["String"]["output"];
  rectsOnPage: Array<Array<Scalars["Float"]["output"]>>;
};

export type DeepSearchFileReferenceInput = {
  pageIndex: Scalars["Int"]["input"];
  quote: Scalars["String"]["input"];
  rects: Array<Array<Scalars["Float"]["input"]>>;
};

export enum DeepSearchFileStatus {
  Complete = "COMPLETE",
  Initial = "INITIAL",
  Searching = "SEARCHING",
}

export enum DeepSearchStatus {
  Complete = "COMPLETE",
  Initial = "INITIAL",
  Searching = "SEARCHING",
  SearchingComplete = "SEARCHING_COMPLETE",
  SearchingFailed = "SEARCHING_FAILED",
  Started = "STARTED",
}

export type DeepSearchThreadMessage = {
  __typename?: "DeepSearchThreadMessage";
  content: DeepSearchThreadMessageContent;
  createdAt: Scalars["Int"]["output"];
  deepSearch: DeepSearch;
  files: Array<DeepSearchThreadMessageContentFile>;
  id: Scalars["ID"]["output"];
  role: DealThreadMessageRole;
  status: DealThreadMessageStatus;
};

export type DeepSearchThreadMessageContent = {
  __typename?: "DeepSearchThreadMessageContent";
  text: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type DeepSearchThreadMessageContentFile = {
  __typename?: "DeepSearchThreadMessageContentFile";
  file: DataRoomFile;
  fileVersion: DataRoomFileVersion;
  id: Scalars["ID"]["output"];
  references: Array<DeepSearchThreadMessageContentFileReference>;
  status: DeepSearchFileStatus;
};

export type DeepSearchThreadMessageContentFileReference = {
  __typename?: "DeepSearchThreadMessageContentFileReference";
  id: Scalars["ID"]["output"];
  pageIndex: Scalars["Int"]["output"];
  quote: Scalars["String"]["output"];
  rects: Array<Array<Scalars["Float"]["output"]>>;
};

export enum DeepSearchVectorScope {
  Deal = "DEAL",
  Selected = "SELECTED",
}

export type DeleteAccountResponse = {
  __typename?: "DeleteAccountResponse";
  success: Scalars["Boolean"]["output"];
};

export type DeleteDataRoomFileInput = {
  deletionType: DeletionType;
  id: Scalars["String"]["input"];
};

export type DeleteDataRoomFileVersionInput = {
  deletionType: DeletionType;
  id: Scalars["String"]["input"];
};

export type DeleteQuestionInput = {
  id: Scalars["String"]["input"];
};

export type DeleteResponse = {
  __typename?: "DeleteResponse";
  success: Scalars["Boolean"]["output"];
};

export enum DeletionType {
  Hard = "HARD",
  Soft = "SOFT",
}

export type DismissDataRoomFileErrorInput = {
  dismissalReason: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  ignoreErrorKeys: Array<Scalars["String"]["input"]>;
  ignoreFiles: Array<Scalars["String"]["input"]>;
};

export type DocumentSummary = {
  __typename?: "DocumentSummary";
  keyPoints: Array<KeyPoint>;
  summary: Scalars["String"]["output"];
};

export enum DocumentSummaryStatus {
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
  Success = "SUCCESS",
}

export type DueDiligenceChecklistItem = {
  __typename?: "DueDiligenceChecklistItem";
  assignedTo?: Maybe<Account>;
  completedAt?: Maybe<Scalars["Int"]["output"]>;
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  dataRoomFile?: Maybe<DataRoomFile>;
  dueDate?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
  type: DueDiligenceChecklistItemType;
  updatedAt: Scalars["Int"]["output"];
};

export enum DueDiligenceChecklistItemType {
  ConditionPrecedent = "CONDITION_PRECEDENT",
  ConditionSubsequent = "CONDITION_SUBSEQUENT",
}

export type ErrorCheck = {
  __typename?: "ErrorCheck";
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  files: Array<ErrorCheckFile>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  rows: Array<ErrorCheckRow>;
};

export type ErrorCheckFile = {
  __typename?: "ErrorCheckFile";
  currentVersion: DataRoomFileVersion;
  file: DataRoomFile;
};

export type ErrorCheckFileInput = {
  dataRoomFileID: Scalars["String"]["input"];
  dataRoomFileVersionID: Scalars["String"]["input"];
};

export type ErrorCheckRow = {
  __typename?: "ErrorCheckRow";
  hasDiscrepancy: Scalars["Boolean"]["output"];
  key: Scalars["String"]["output"];
  values: Array<ErrorCheckValue>;
};

export type ErrorCheckValue = {
  __typename?: "ErrorCheckValue";
  dataRoomFileId: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  value: Scalars["Float"]["output"];
};

export enum ErrorCheckingStatus {
  Checking = "CHECKING",
  Failed = "FAILED",
  Ignored = "IGNORED",
  Success = "SUCCESS",
}

export type FileAccessInput = {
  fileId: Scalars["String"]["input"];
  type: AccessType;
};

export type FileActivitiesInput = {
  activityTypes: Array<DealActivityType>;
  dataRoomFileIDs: Array<Scalars["String"]["input"]>;
};

export enum FileType {
  Compressed = "COMPRESSED",
  Doc = "DOC",
  Docx = "DOCX",
  Eml = "EML",
  Jpeg = "JPEG",
  Mp3 = "MP3",
  Mp4 = "MP4",
  Msg = "MSG",
  Other = "OTHER",
  Pdf = "PDF",
  Png = "PNG",
  Ppt = "PPT",
  Pptx = "PPTX",
  RawText = "RAW_TEXT",
  Rtf = "RTF",
  Tiff = "TIFF",
  Xls = "XLS",
  Xlsx = "XLSX",
  Zip = "ZIP",
}

export type FileVersionChanges = {
  __typename?: "FileVersionChanges";
  summary: Scalars["String"]["output"];
  textChanges: Array<FileVersionTextChange>;
};

export type FileVersionTextChange = {
  __typename?: "FileVersionTextChange";
  diffs: Array<TextDiff>;
  new: Scalars["String"]["output"];
  previous: Scalars["String"]["output"];
  section: Scalars["String"]["output"];
};

export type FinancialStatements = {
  __typename?: "FinancialStatements";
  pnl?: Maybe<Pnl>;
};

export type Firm = {
  __typename?: "Firm";
  accounts: Array<Account>;
  address: Scalars["String"]["output"];
  allFolders: Array<DataRoomFolder>;
  createdAt: Scalars["Int"]["output"];
  dataRoom: DataRoom;
  dealProperties: Array<DealProperty>;
  deals: Array<Deal>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  invites: Array<FirmInvite>;
  logo: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  type: FirmType;
  website: Scalars["String"]["output"];
};

export enum FirmAccountType {
  Admin = "ADMIN",
  Staff = "STAFF",
}

export type FirmFile = {
  __typename?: "FirmFile";
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  deletedAt?: Maybe<Scalars["Int"]["output"]>;
  fileType: FileType;
  folder: FirmFolder;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["Int"]["output"];
  uploadStatus: DataRoomFileUploadStatus;
  versions: Array<FirmFileVersion>;
};

export type FirmFileVersion = {
  __typename?: "FirmFileVersion";
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  fileName: Scalars["String"]["output"];
  fileType: FileType;
  id: Scalars["ID"]["output"];
  pspdfkitDocumentId: Scalars["String"]["output"];
  pspdfkitToken: Scalars["String"]["output"];
  versionNumber: Scalars["Int"]["output"];
};

export type FirmFolder = {
  __typename?: "FirmFolder";
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  deal?: Maybe<Deal>;
  files: Array<FirmFile>;
  folders: Array<FirmFolder>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type FirmInvite = {
  __typename?: "FirmInvite";
  createdAt: Scalars["Int"]["output"];
  email: Scalars["String"]["output"];
  firm: Firm;
  id: Scalars["ID"]["output"];
  invitedBy: Account;
  message: Scalars["String"]["output"];
  role: FirmAccountType;
};

export enum FirmType {
  Accountant = "ACCOUNTANT",
  Broker = "BROKER",
  Buyer = "BUYER",
  LawFirm = "LAW_FIRM",
  Other = "OTHER",
  Seller = "SELLER",
}

export type FolderAccessInput = {
  folderId: Scalars["String"]["input"];
  type: AccessType;
};

export type GranularAccess = {
  __typename?: "GranularAccess";
  files: Array<Access>;
  folders: Array<Access>;
};

export type GranularAccessChanges = {
  __typename?: "GranularAccessChanges";
  updated: Array<UpdatedGranularAccess>;
};

export type GranularAccessInput = {
  __typename?: "GranularAccessInput";
  id: Scalars["String"]["output"];
  type: AccessType;
};

export type IgnoreErrorRule = {
  __typename?: "IgnoreErrorRule";
  createdAt: Scalars["Int"]["output"];
  dataRoomFile?: Maybe<DataRoomFile>;
  id: Scalars["ID"]["output"];
  key: Scalars["String"]["output"];
  keyFiles: Array<DataRoomFile>;
};

export type KeyPoint = {
  __typename?: "KeyPoint";
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type MoveDataRoomFileInput = {
  folderID: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
};

export type MoveDataRoomFolderInput = {
  folderID: Scalars["String"]["input"];
  targetParentFolderID: Scalars["String"]["input"];
};

export type MultipartPart = {
  etag: Scalars["String"]["input"];
  partNumber: Scalars["Int"]["input"];
};

export type MultipartUrl = {
  __typename?: "MultipartUrl";
  partNumber: Scalars["Int"]["output"];
  presignedUrl: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  acceptDealInvite: Deal;
  acceptFirmInvite: Firm;
  addDeepSearchFileReference: DeepSearchFile;
  approveDealInvite: DealInvite;
  copyDataRoomFile: DataRoomFile;
  createAccount: Account;
  createComment: DealActivity;
  createDataRoomFile: CreateDataRoomFileResponse;
  createDataRoomFileVersion: PresignedUrlResponse;
  createDataRoomFolder: DataRoomFolder;
  createDataRoomPermission: DataRoomPermission;
  createDdql: CreateDdqlResponse;
  createDdqlImport: CreateDdqlImportResponse;
  createDeal: Deal;
  createDealAccounts: Array<DealAccount>;
  createDealExport: DealExport;
  createDealFirm: DealFirm;
  createDealGroup: DealGroup;
  createDealInvite: DealInvite;
  createDealProperty: DealProperty;
  createDealThread: DealThread;
  createDealThreadMessage: CreateDealThreadMessageResponse;
  createDeepSearch: DeepSearch;
  createDeepSearchFile: DeepSearchFile;
  createDeepSearchThreadMessage: DeepSearchThreadMessage;
  createDueDiligenceChecklistItem: DueDiligenceChecklistItem;
  createErrorCheck: ErrorCheck;
  createFirm: Firm;
  createFirmInvite: FirmInvite;
  createFirmInvites: Array<FirmInvite>;
  createIgnoreErrorRule: IgnoreErrorRule;
  createOrUpdateAccessControl: AccessControl;
  createOrUpdateAccessControls: Array<AccessControl>;
  createPnlVersion: Pnl;
  createQuestion: Question;
  createQuestionAnswer: Question;
  createRedlineExport: RedlineExport;
  createTask: Task;
  createTaskComment: TaskActivity;
  deleteAccessControl: DeleteResponse;
  deleteAccount: DeleteAccountResponse;
  deleteDataRoomFile: DeleteResponse;
  deleteDataRoomFileVersion: DeleteResponse;
  deleteDataRoomPermission: DataRoomPermission;
  deleteDdql: Ddql;
  deleteDealInvite: DeleteResponse;
  deleteDeepSearch: DeleteResponse;
  deleteDeepSearchFileReference: DeepSearchFile;
  deleteFirmInvite: DeleteResponse;
  deleteIgnoreErrorRule: DeleteResponse;
  deleteQuestion: DeleteResponse;
  ignoreFileForError: DataRoomFileError;
  login: AuthenticateResponse;
  markNotificationAsRead: Notification;
  moveDataRoomFile: DataRoomFile;
  moveDataRoomFolder: DataRoomFolder;
  publishDdql: Ddql;
  removeDealAccount: DeleteResponse;
  removeDealFirm: DeleteResponse;
  removeDealGroup: DeleteResponse;
  removeDeepSearchFile: DeepSearch;
  resetPassword: Account;
  resolveDataRoomFileError: DataRoomFileError;
  resolveDataRoomFileSimilarity: ResolveDataRoomFileSimilarityResponse;
  s3DdqlUploadComplete: Ddql;
  s3FileUploadComplete: DataRoomFile;
  s3MultipartUploadComplete: DataRoomFile;
  sendNotification: SendNotificationResponse;
  sendResetPassword: Scalars["String"]["output"];
  startDeepSearch: DeepSearch;
  trackActivity: DealActivity;
  updateAccessControl: AccessControl;
  updateAccount: Account;
  updateDataRoom: DataRoom;
  updateDataRoomFile: DataRoomFile;
  updateDataRoomFolder: DataRoomFolder;
  updateDataRoomPermission: DataRoomPermission;
  updateDdql: Ddql;
  updateDeal?: Maybe<Deal>;
  updateDealAccount: DealAccount;
  updateDealFirm: DealFirm;
  updateDealGroup: DealGroup;
  updateDealProperty: DealProperty;
  updateDealPropertyValue: DealPropertyValue;
  updateDealPropertyValues: UpdateDealPropertyValuesResponse;
  updateDeepSearch: DeepSearch;
  updateDeepSearchFile: DeepSearchFile;
  updateDeepSearchFileReference: DeepSearchFile;
  updateDeepSearchFiles: Array<DeepSearchFile>;
  updateDeepSearchThreadMessage: DeepSearchThreadMessage;
  updateDueDiligenceChecklistItem: DueDiligenceChecklistItem;
  updateErrorCheck: ErrorCheck;
  updateFirm: Firm;
  updateIgnoreErrorRule: IgnoreErrorRule;
  updateQuestion: Question;
  updateTask: Task;
  updateTasks: Array<Task>;
};

export type MutationAcceptDealInviteArgs = {
  ndaSigned: Scalars["Boolean"]["input"];
  token: Scalars["String"]["input"];
};

export type MutationAcceptFirmInviteArgs = {
  token: Scalars["String"]["input"];
};

export type MutationAddDeepSearchFileReferenceArgs = {
  input: AddDeepSearchFileReferenceInput;
};

export type MutationApproveDealInviteArgs = {
  id: Scalars["String"]["input"];
};

export type MutationCopyDataRoomFileArgs = {
  input: CopyDataRoomFileInput;
};

export type MutationCreateAccountArgs = {
  clerkUserId: Scalars["String"]["input"];
};

export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};

export type MutationCreateDataRoomFileArgs = {
  dataRoomFolderID: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  fileName: Scalars["String"]["input"];
  fileType: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  parts: Scalars["Int"]["input"];
  permissionID: Scalars["String"]["input"];
};

export type MutationCreateDataRoomFileVersionArgs = {
  dataRoomFileID: Scalars["String"]["input"];
  fileName: Scalars["String"]["input"];
  fileType: Scalars["String"]["input"];
  summary: Scalars["String"]["input"];
};

export type MutationCreateDataRoomFolderArgs = {
  dataRoomFolderID: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationCreateDataRoomPermissionArgs = {
  accessLevel: Scalars["Int"]["input"];
  dataRoomID: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationCreateDdqlArgs = {
  input: CreateDdqlInput;
};

export type MutationCreateDdqlImportArgs = {
  ddqlID: Scalars["String"]["input"];
  fileName: Scalars["String"]["input"];
  fileType: Scalars["String"]["input"];
  parts: Scalars["Int"]["input"];
};

export type MutationCreateDealArgs = {
  input: CreateDealInput;
};

export type MutationCreateDealAccountsArgs = {
  input: CreateDealAccountsInput;
};

export type MutationCreateDealExportArgs = {
  input: CreateDealExportInput;
};

export type MutationCreateDealFirmArgs = {
  input: CreateDealFirmInput;
};

export type MutationCreateDealGroupArgs = {
  input: CreateDealGroupInput;
};

export type MutationCreateDealInviteArgs = {
  input: CreateDealInviteInput;
};

export type MutationCreateDealPropertyArgs = {
  input: CreateDealPropertyInput;
};

export type MutationCreateDealThreadArgs = {
  input: CreateDealThreadInput;
};

export type MutationCreateDealThreadMessageArgs = {
  input: CreateDealThreadMessageInput;
};

export type MutationCreateDeepSearchArgs = {
  input: CreateDeepSearchInput;
};

export type MutationCreateDeepSearchFileArgs = {
  input: CreateDeepSearchFileInput;
};

export type MutationCreateDeepSearchThreadMessageArgs = {
  input: CreateDeepSearchThreadMessageInput;
};

export type MutationCreateDueDiligenceChecklistItemArgs = {
  input: CreateDueDiligenceChecklistItemInput;
};

export type MutationCreateErrorCheckArgs = {
  input: CreateErrorCheckInput;
};

export type MutationCreateFirmArgs = {
  input: CreateFirmInput;
};

export type MutationCreateFirmInviteArgs = {
  input: CreateFirmInviteInput;
};

export type MutationCreateFirmInvitesArgs = {
  input: CreateFirmInvitesInput;
};

export type MutationCreateIgnoreErrorRuleArgs = {
  input: CreateIgnoreErrorRuleInput;
};

export type MutationCreateOrUpdateAccessControlArgs = {
  input: CreateOrUpdateAccessControlInput;
};

export type MutationCreateOrUpdateAccessControlsArgs = {
  input: CreateOrUpdateAccessControlsInput;
};

export type MutationCreatePnlVersionArgs = {
  input: PnlInput;
};

export type MutationCreateQuestionArgs = {
  body: Scalars["String"]["input"];
  ddqlID?: InputMaybe<Scalars["String"]["input"]>;
  nodeId: Scalars["String"]["input"];
  nodeType: QuestionNodeType;
  questionForDealGroupID?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
};

export type MutationCreateQuestionAnswerArgs = {
  answer: Scalars["String"]["input"];
  answerFiles: Array<DealThreadMessageContentFileInput>;
  dataRoomFileIds: Array<Scalars["String"]["input"]>;
  questionId: Scalars["String"]["input"];
};

export type MutationCreateRedlineExportArgs = {
  input: CreateRedlineExportInput;
};

export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationCreateTaskCommentArgs = {
  input: CreateTaskCommentInput;
};

export type MutationDeleteAccessControlArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteDataRoomFileArgs = {
  input: DeleteDataRoomFileInput;
};

export type MutationDeleteDataRoomFileVersionArgs = {
  input: DeleteDataRoomFileVersionInput;
};

export type MutationDeleteDataRoomPermissionArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteDdqlArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteDealInviteArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteDeepSearchArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteDeepSearchFileReferenceArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteFirmInviteArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteIgnoreErrorRuleArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteQuestionArgs = {
  input: DeleteQuestionInput;
};

export type MutationIgnoreFileForErrorArgs = {
  dataRoomFileErrorID: Scalars["String"]["input"];
  dataRoomFileID: Scalars["String"]["input"];
};

export type MutationLoginArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type MutationMarkNotificationAsReadArgs = {
  id: Scalars["String"]["input"];
};

export type MutationMoveDataRoomFileArgs = {
  input: MoveDataRoomFileInput;
};

export type MutationMoveDataRoomFolderArgs = {
  input: MoveDataRoomFolderInput;
};

export type MutationPublishDdqlArgs = {
  id: Scalars["String"]["input"];
};

export type MutationRemoveDealAccountArgs = {
  id: Scalars["String"]["input"];
};

export type MutationRemoveDealFirmArgs = {
  id: Scalars["String"]["input"];
};

export type MutationRemoveDealGroupArgs = {
  id: Scalars["String"]["input"];
};

export type MutationRemoveDeepSearchFileArgs = {
  id: Scalars["String"]["input"];
};

export type MutationResetPasswordArgs = {
  password: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type MutationResolveDataRoomFileErrorArgs = {
  input: ResolveDataRoomFileErrorInput;
};

export type MutationResolveDataRoomFileSimilarityArgs = {
  acceptMerge: Scalars["Boolean"]["input"];
  dataRoomFileID1: Scalars["String"]["input"];
  dataRoomFileID2: Scalars["String"]["input"];
  reverseMerge: Scalars["Boolean"]["input"];
};

export type MutationS3DdqlUploadCompleteArgs = {
  ddqlID: Scalars["String"]["input"];
  multiPartUploadID: Scalars["String"]["input"];
  parts: Array<MultipartPart>;
};

export type MutationS3FileUploadCompleteArgs = {
  dataRoomFileID: Scalars["String"]["input"];
  dataRoomFileVersionID: Scalars["String"]["input"];
};

export type MutationS3MultipartUploadCompleteArgs = {
  dataRoomFileID: Scalars["String"]["input"];
  dataRoomFileVersionID: Scalars["String"]["input"];
  multiPartUploadID: Scalars["String"]["input"];
  parts: Array<MultipartPart>;
};

export type MutationSendNotificationArgs = {
  input: SendNotificationInput;
};

export type MutationSendResetPasswordArgs = {
  email: Scalars["String"]["input"];
};

export type MutationStartDeepSearchArgs = {
  input: StartDeepSearchInput;
};

export type MutationTrackActivityArgs = {
  dataRoomId?: InputMaybe<Scalars["String"]["input"]>;
  fileId?: InputMaybe<Scalars["String"]["input"]>;
  folderId?: InputMaybe<Scalars["String"]["input"]>;
  targetAccountID?: InputMaybe<Scalars["String"]["input"]>;
  type: DealActivityType;
};

export type MutationUpdateAccessControlArgs = {
  input: UpdateAccessControlInput;
};

export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};

export type MutationUpdateDataRoomArgs = {
  input: UpdateDataRoomInput;
};

export type MutationUpdateDataRoomFileArgs = {
  input: UpdateDataRoomFileInput;
};

export type MutationUpdateDataRoomFolderArgs = {
  input: UpdateDataRoomFolderInput;
};

export type MutationUpdateDataRoomPermissionArgs = {
  accessLevel?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateDdqlArgs = {
  input: UpdateDdqlInput;
};

export type MutationUpdateDealArgs = {
  input: UpdateDealInput;
};

export type MutationUpdateDealAccountArgs = {
  input: UpdateDealAccountInput;
};

export type MutationUpdateDealFirmArgs = {
  input: UpdateDealFirmInput;
};

export type MutationUpdateDealGroupArgs = {
  input: UpdateDealGroupInput;
};

export type MutationUpdateDealPropertyArgs = {
  input: UpdateDealPropertyInput;
};

export type MutationUpdateDealPropertyValueArgs = {
  input: UpdateDealPropertyValueInput;
};

export type MutationUpdateDealPropertyValuesArgs = {
  input: Array<UpdateDealPropertyValueInput>;
};

export type MutationUpdateDeepSearchArgs = {
  input: UpdateDeepSearchInput;
};

export type MutationUpdateDeepSearchFileArgs = {
  input: UpdateDeepSearchFileInput;
};

export type MutationUpdateDeepSearchFileReferenceArgs = {
  input: UpdateDeepSearchFileReferenceInput;
};

export type MutationUpdateDeepSearchFilesArgs = {
  input: UpdateDeepSearchFilesInput;
};

export type MutationUpdateDeepSearchThreadMessageArgs = {
  input: UpdateDeepSearchThreadMessageInput;
};

export type MutationUpdateDueDiligenceChecklistItemArgs = {
  input: UpdateDueDiligenceChecklistItemInput;
};

export type MutationUpdateErrorCheckArgs = {
  input: UpdateErrorCheckInput;
};

export type MutationUpdateFirmArgs = {
  input: UpdateFirmInput;
};

export type MutationUpdateIgnoreErrorRuleArgs = {
  input: UpdateIgnoreErrorRuleInput;
};

export type MutationUpdateQuestionArgs = {
  input: UpdateQuestionInput;
};

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};

export type MutationUpdateTasksArgs = {
  input: UpdateTasksInput;
};

export type Notification = {
  __typename?: "Notification";
  createdAt: Scalars["Int"]["output"];
  dataRoomFile?: Maybe<DataRoomFile>;
  deal?: Maybe<Deal>;
  id: Scalars["ID"]["output"];
  initiator?: Maybe<Account>;
  initiatorDealAccount?: Maybe<DealAccount>;
  message: Scalars["String"]["output"];
  readAt?: Maybe<Scalars["Int"]["output"]>;
  similarDataRoomFile?: Maybe<SimilarDataRoomFile>;
  task?: Maybe<Task>;
  title: Scalars["String"]["output"];
  type: NotificationType;
};

export enum NotificationType {
  Comment = "COMMENT",
  DataRoomFileSimilarity = "DATA_ROOM_FILE_SIMILARITY",
  InviteAccepted = "INVITE_ACCEPTED",
  TaskAssigned = "TASK_ASSIGNED",
  TaskUrgent = "TASK_URGENT",
}

export type Pnl = {
  __typename?: "Pnl";
  createdAt: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  latestVersion: PnlVersion;
  questions: Array<Question>;
  versions: Array<PnlVersion>;
};

export type PnlInput = {
  amortizations: Array<RowInput>;
  cogs: Array<RowInput>;
  columns: Array<ColumnInput>;
  depreciations: Array<RowInput>;
  firmID: Scalars["String"]["input"];
  interests: Array<RowInput>;
  operatingExpenses: Array<RowInput>;
  revenues: Array<RowInput>;
  taxes: Array<RowInput>;
};

export type PnlVersion = {
  __typename?: "PnlVersion";
  amortizations: Array<Row>;
  changeSummary: Scalars["String"]["output"];
  changeSummaryStatus: ChangeSummaryStatus;
  cogs: Array<Row>;
  columns: Array<Column>;
  createdAt: Scalars["Int"]["output"];
  depreciations: Array<Row>;
  id: Scalars["ID"]["output"];
  interests: Array<Row>;
  operatingExpenses: Array<Row>;
  revenues: Array<Row>;
  taxes: Array<Row>;
};

export type PresignedUrlResponse = {
  __typename?: "PresignedUrlResponse";
  dataRoomFileVersionID: Scalars["String"]["output"];
  downloadUrl: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  viewUrl: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  accessControlCheck: AccessControlCheckResponse;
  accessMatrix: Array<AccessMatrix>;
  account: Account;
  allFolders: Array<DataRoomFolder>;
  checkFileAccess: Scalars["Boolean"]["output"];
  company: Company;
  dataRoom: DataRoom;
  dataRoomActivityMetrics: DataRoomActivityMetricsResponse;
  dataRoomFile: DataRoomFile;
  dataRoomFileError: DataRoomFileError;
  dataRoomFileErrors: Array<DataRoomFileError>;
  dataRoomFileVersion: DataRoomFileVersion;
  dataRoomFolder: DataRoomFolder;
  ddql: Ddql;
  ddqls: Array<Ddql>;
  deal: Deal;
  dealAccount: DealAccount;
  dealActivity: DealActivityResponse;
  dealFirm: DealFirm;
  dealGroup: DealGroup;
  dealInvite: DealInvite;
  dealProperty: DealProperty;
  dealThread: DealThread;
  dealThreadMessage: DealThreadMessage;
  dealThreads: Array<DealThread>;
  deepSearch: DeepSearch;
  deepSearchFile: DeepSearchFile;
  deepSearchThreadMessage: DeepSearchThreadMessage;
  deepSearchThreadMessageFile: DeepSearchThreadMessageContentFile;
  deepSearches: Array<DeepSearch>;
  errorCheck: ErrorCheck;
  errorChecks: Array<ErrorCheck>;
  fileDownloadUrl: PresignedUrlResponse;
  fileVersionDownloadUrl: PresignedUrlResponse;
  firm: Firm;
  firmInvite: FirmInvite;
  ignoreErrorRule: IgnoreErrorRule;
  ignoreErrorRules: Array<IgnoreErrorRule>;
  individualGuestQuestions: QuestionsRespose;
  notifications: Array<Notification>;
  pnlVersion: PnlVersion;
  question: Question;
  questions: QuestionsRespose;
  redlineExport: RedlineExport;
  redlineExportByFileVersions: RedlineExport;
  searchDeepSearchFiles: SearchDeepSearchFilesResponse;
  searchFiles: Array<DataRoomFile>;
  similarDataRoomFiles: Array<SimilarDataRoomFile>;
  task: Task;
  taskActivity: TaskActivitiesResponse;
  tasks: Array<Task>;
};

export type QueryAccessControlCheckArgs = {
  input: AccessControlCheckInput;
};

export type QueryAccessMatrixArgs = {
  dataRoomID: Scalars["String"]["input"];
};

export type QueryAllFoldersArgs = {
  dataRoomFolderID: Scalars["String"]["input"];
  targetAccountID?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCheckFileAccessArgs = {
  accountId: Scalars["String"]["input"];
  fileId: Scalars["String"]["input"];
};

export type QueryCompanyArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDataRoomArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDataRoomActivityMetricsArgs = {
  input: DataRoomActivityMetricsInput;
};

export type QueryDataRoomFileArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDataRoomFileErrorArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDataRoomFileErrorsArgs = {
  dealId: Scalars["String"]["input"];
};

export type QueryDataRoomFileVersionArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDataRoomFolderArgs = {
  id: Scalars["String"]["input"];
  targetAccountID?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryDdqlArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDdqlsArgs = {
  dealId: Scalars["String"]["input"];
};

export type QueryDealArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDealAccountArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDealActivityArgs = {
  dealId: Scalars["String"]["input"];
  input: DealActivityInput;
};

export type QueryDealFirmArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDealGroupArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDealInviteArgs = {
  token: Scalars["String"]["input"];
};

export type QueryDealPropertyArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDealThreadArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDealThreadMessageArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDealThreadsArgs = {
  dealID: Scalars["String"]["input"];
};

export type QueryDeepSearchArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDeepSearchFileArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDeepSearchThreadMessageArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDeepSearchThreadMessageFileArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDeepSearchesArgs = {
  dealId: Scalars["String"]["input"];
};

export type QueryErrorCheckArgs = {
  id: Scalars["String"]["input"];
};

export type QueryErrorChecksArgs = {
  dealId: Scalars["String"]["input"];
};

export type QueryFileDownloadUrlArgs = {
  id: Scalars["String"]["input"];
};

export type QueryFileVersionDownloadUrlArgs = {
  id: Scalars["String"]["input"];
};

export type QueryFirmArgs = {
  id: Scalars["String"]["input"];
};

export type QueryFirmInviteArgs = {
  token: Scalars["String"]["input"];
};

export type QueryIgnoreErrorRuleArgs = {
  id: Scalars["String"]["input"];
};

export type QueryIgnoreErrorRulesArgs = {
  dealId: Scalars["String"]["input"];
};

export type QueryIndividualGuestQuestionsArgs = {
  dealId: Scalars["String"]["input"];
  input: QuestionsInput;
};

export type QueryPnlVersionArgs = {
  id: Scalars["String"]["input"];
};

export type QueryQuestionArgs = {
  id: Scalars["String"]["input"];
};

export type QueryQuestionsArgs = {
  dealId: Scalars["String"]["input"];
  input: QuestionsInput;
};

export type QueryRedlineExportArgs = {
  id: Scalars["String"]["input"];
};

export type QueryRedlineExportByFileVersionsArgs = {
  sourceFileVersionID: Scalars["String"]["input"];
  targetFileVersionID: Scalars["String"]["input"];
};

export type QuerySearchDeepSearchFilesArgs = {
  input: SearchDeepSearchFilesInput;
};

export type QuerySearchFilesArgs = {
  dataRoomID: Scalars["String"]["input"];
  query: Scalars["String"]["input"];
};

export type QuerySimilarDataRoomFilesArgs = {
  dataRoomFileID?: InputMaybe<Scalars["String"]["input"]>;
  dataRoomID?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTaskArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTaskActivityArgs = {
  filters: TaskActivityFilters;
};

export type QueryTasksArgs = {
  filters?: InputMaybe<TasksFilters>;
  firmID: Scalars["String"]["input"];
};

export type Question = {
  __typename?: "Question";
  accessControlDealGroups: Array<AccessControlDealGroup>;
  activity: Array<DealActivity>;
  answer?: Maybe<QuestionAnswer>;
  answeredAt?: Maybe<Scalars["Int"]["output"]>;
  body: Scalars["String"]["output"];
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  dataRoomFile?: Maybe<DataRoomFile>;
  ddql?: Maybe<Ddql>;
  dealGroup?: Maybe<DealGroup>;
  forDealGroup?: Maybe<DealGroup>;
  id: Scalars["ID"]["output"];
  index: Scalars["String"]["output"];
  nodeId: Scalars["String"]["output"];
  similarQuestions: Array<Question>;
  suggestedAnswer?: Maybe<SuggestedAnswer>;
  title: Scalars["String"]["output"];
};

export type QuestionAnswer = {
  __typename?: "QuestionAnswer";
  answer: Scalars["String"]["output"];
  answerFiles: Array<DealThreadMessageContentFile>;
  answeredBy: Account;
  createdAt: Scalars["Int"]["output"];
  dataRoomFiles: Array<DataRoomFile>;
  id: Scalars["ID"]["output"];
  question: Question;
};

export enum QuestionNodeType {
  DataRoomFile = "DATA_ROOM_FILE",
  DataRoomFolder = "DATA_ROOM_FOLDER",
  Pnl = "PNL",
}

export type QuestionsInput = {
  dealGroupID?: InputMaybe<Scalars["String"]["input"]>;
  nodeId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuestionsRespose = {
  __typename?: "QuestionsRespose";
  questions: Array<Question>;
};

export type RedlineExport = {
  __typename?: "RedlineExport";
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  id: Scalars["ID"]["output"];
  s3PresignedDocXRedlineUrl?: Maybe<Scalars["String"]["output"]>;
  s3PresignedDocXTrackedChangesUrl?: Maybe<Scalars["String"]["output"]>;
  s3PresignedPdfRedlineUrl?: Maybe<Scalars["String"]["output"]>;
  sourceFileVersion: DataRoomFileVersion;
  status: RedlineExportStatus;
  targetFileVersion: DataRoomFileVersion;
};

export enum RedlineExportStatus {
  Complete = "COMPLETE",
  Exporting = "EXPORTING",
  Failed = "FAILED",
}

export type ResolveDataRoomFileErrorInput = {
  id: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
};

export type ResolveDataRoomFileSimilarityResponse = {
  __typename?: "ResolveDataRoomFileSimilarityResponse";
  success: Scalars["Boolean"]["output"];
};

export type Row = {
  __typename?: "Row";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  type: RowType;
  values: Array<RowValue>;
};

export type RowInput = {
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  type: RowType;
  values: Array<RowValueInput>;
};

export enum RowType {
  Expense = "EXPENSE",
  Income = "INCOME",
}

export type RowValue = {
  __typename?: "RowValue";
  column: Scalars["String"]["output"];
  value: Scalars["Float"]["output"];
};

export type RowValueInput = {
  column: Scalars["String"]["input"];
  value: Scalars["Float"]["input"];
};

export type SearchDeepSearchFileMatch = {
  __typename?: "SearchDeepSearchFileMatch";
  deepSearchFile: DeepSearchFile;
  results: Array<DeepSearchFileReference>;
};

export type SearchDeepSearchFilesInput = {
  deepSearchID: Scalars["String"]["input"];
  query: Scalars["String"]["input"];
};

export type SearchDeepSearchFilesResponse = {
  __typename?: "SearchDeepSearchFilesResponse";
  matches: Array<SearchDeepSearchFileMatch>;
};

export type SendNotificationInput = {
  dealAccountID: Scalars["String"]["input"];
  dealID: Scalars["String"]["input"];
  inviteEmail: Scalars["String"]["input"];
  type: NotificationType;
};

export type SendNotificationResponse = {
  __typename?: "SendNotificationResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type SimilarDataRoomFile = {
  __typename?: "SimilarDataRoomFile";
  createdAt: Scalars["Int"]["output"];
  dataRoomFile1: DataRoomFile;
  dataRoomFile2: DataRoomFile;
  id: Scalars["ID"]["output"];
  similarity: Scalars["Float"]["output"];
  status: DataRoomFileSimilarityStatus;
};

export type StartDeepSearchInput = {
  files: Array<CreateDeepSearchFileInput>;
  id: Scalars["String"]["input"];
};

export type SuggestedAnswer = {
  __typename?: "SuggestedAnswer";
  createdAt: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  question: Question;
  references: Array<AnswerReference>;
  relevantFiles: Array<DataRoomFile>;
  status: SuggestedAnswerStatus;
  summary: Scalars["String"]["output"];
};

export enum SuggestedAnswerStatus {
  CheckingAnswer = "CHECKING_ANSWER",
  Complete = "COMPLETE",
  FindingRelevantFiles = "FINDING_RELEVANT_FILES",
  Initial = "INITIAL",
  SynthesizingAnswer = "SYNTHESIZING_ANSWER",
}

export type Task = {
  __typename?: "Task";
  activity: Array<TaskActivity>;
  assignedTo: Array<Account>;
  createdAt: Scalars["Int"]["output"];
  createdBy: Account;
  deal?: Maybe<Deal>;
  description: Scalars["String"]["output"];
  dueDate?: Maybe<Scalars["Int"]["output"]>;
  firm: Firm;
  id: Scalars["ID"]["output"];
  priority?: Maybe<TaskPriority>;
  sortOrder: Scalars["Int"]["output"];
  status: TaskStatus;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type TaskActivitiesResponse = {
  __typename?: "TaskActivitiesResponse";
  activities: Array<TaskActivity>;
  hasMore: Scalars["Boolean"]["output"];
  nextCursor: Scalars["String"]["output"];
  total: Scalars["Int"]["output"];
};

export type TaskActivity = {
  __typename?: "TaskActivity";
  account: Account;
  assignedTo: Array<Account>;
  comment?: Maybe<Comment>;
  createdAt: Scalars["Int"]["output"];
  deal?: Maybe<Deal>;
  /**
   * these fields come from the json data
   * so are representative of the state
   * when the activity was created
   */
  dueDate?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  priority?: Maybe<TaskPriority>;
  status?: Maybe<TaskStatus>;
  task: Task;
  type: TaskActivityType;
};

export type TaskActivityFilters = {
  accountID?: InputMaybe<Scalars["String"]["input"]>;
  activityTypes?: InputMaybe<Array<TaskActivityType>>;
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  dealID?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  startDate?: InputMaybe<Scalars["Int"]["input"]>;
  taskID?: InputMaybe<Scalars["String"]["input"]>;
};

export enum TaskActivityType {
  Assigned = "ASSIGNED",
  Comment = "COMMENT",
  Completed = "COMPLETED",
  Created = "CREATED",
  DueDate = "DUE_DATE",
  PriorityUpdated = "PRIORITY_UPDATED",
  StatusUpdated = "STATUS_UPDATED",
  Updated = "UPDATED",
}

export enum TaskPriority {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
  None = "NONE",
  Urgent = "URGENT",
}

export enum TaskStatus {
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  Todo = "TODO",
}

export type TasksFilters = {
  assignedTo?: InputMaybe<Scalars["String"]["input"]>;
  dealID?: InputMaybe<Scalars["String"]["input"]>;
  dealStatus?: InputMaybe<DealStatus>;
};

export type TextDiff = {
  __typename?: "TextDiff";
  text: Scalars["String"]["output"];
  type: TextDiffType;
};

export enum TextDiffType {
  Delete = "DELETE",
  Equal = "EQUAL",
  Insert = "INSERT",
}

export type UpdateAccessControlInput = {
  id: Scalars["String"]["input"];
  permissionLevel: AccessControlPermissionLevel;
};

export type UpdateAccountInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateDataRoomFileInput = {
  currentLiveVersionID?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  onlineStatus?: InputMaybe<DataRoomFileOnlineStatus>;
  permissionID?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateDataRoomFolderInput = {
  deletedAt?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  permissionID?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateDataRoomInput = {
  id: Scalars["String"]["input"];
  permissions: Array<DataRoomPermissionInput>;
};

export type UpdateDdqlInput = {
  forDealGroupID?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  removeForDealGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateDealAccountInput = {
  dealAccessStatus?: InputMaybe<DealAccessStatus>;
  filePermissions?: InputMaybe<Array<FileAccessInput>>;
  folderPermissions?: InputMaybe<Array<FolderAccessInput>>;
  id: Scalars["String"]["input"];
  lastViewed?: InputMaybe<Scalars["Int"]["input"]>;
  overrideDataRoomAccess?: InputMaybe<Scalars["Boolean"]["input"]>;
  role?: InputMaybe<DealRole>;
};

export type UpdateDealFirmInput = {
  filePermissions: Array<FileAccessInput>;
  folderPermissions: Array<FolderAccessInput>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  overrideDataRoomAccess: Scalars["Boolean"]["input"];
};

export type UpdateDealGroupInput = {
  currentDealStage?: InputMaybe<CurrentDealStageInput>;
  dataRoomPermissionId?: InputMaybe<Scalars["String"]["input"]>;
  dealAccessStatus?: InputMaybe<DealAccessStatus>;
  filePermissions?: InputMaybe<Array<FileAccessInput>>;
  folderPermissions?: InputMaybe<Array<FolderAccessInput>>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  overrideDataRoomAccess?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateDealInput = {
  company?: InputMaybe<CompanyInput>;
  id: Scalars["String"]["input"];
  status?: InputMaybe<DealStatus>;
};

export type UpdateDealPropertyInput = {
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["String"]["input"];
  propertyKey?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateDealPropertyValueInput = {
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["String"]["input"];
  propertyValue: Scalars["String"]["input"];
};

export type UpdateDealPropertyValuesResponse = {
  __typename?: "UpdateDealPropertyValuesResponse";
  success: Scalars["Boolean"]["output"];
};

export type UpdateDeepSearchFileInput = {
  dataRoomFileVersionID?: InputMaybe<Scalars["String"]["input"]>;
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["String"]["input"];
  sortIndex?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateDeepSearchFileReferenceInput = {
  id: Scalars["String"]["input"];
  quote: Scalars["String"]["input"];
};

export type UpdateDeepSearchFilesInput = {
  files: Array<UpdateDeepSearchFileInput>;
};

export type UpdateDeepSearchInput = {
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  vectorScope?: InputMaybe<DeepSearchVectorScope>;
};

export type UpdateDeepSearchThreadMessageInput = {
  id: Scalars["String"]["input"];
  status: DealThreadMessageStatus;
};

export type UpdateDueDiligenceChecklistItemInput = {
  assignedTo?: InputMaybe<Scalars["String"]["input"]>;
  completedAt?: InputMaybe<Scalars["Int"]["input"]>;
  dataRoomFileID?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateErrorCheckInput = {
  files: Array<ErrorCheckFileInput>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateFirmInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<FirmType>;
  website?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateIgnoreErrorRuleInput = {
  id: Scalars["String"]["input"];
  keyFiles: Array<Scalars["String"]["input"]>;
};

export type UpdateQuestionInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  questionForDealGroupID?: InputMaybe<Scalars["String"]["input"]>;
  removeQuestionForDealGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateTaskInput = {
  assignedTo?: InputMaybe<Array<Scalars["String"]["input"]>>;
  clearDeal?: InputMaybe<Scalars["Boolean"]["input"]>;
  clearDueDate?: InputMaybe<Scalars["Boolean"]["input"]>;
  dealID?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["String"]["input"];
  priority?: InputMaybe<TaskPriority>;
  sortOrder?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<TaskStatus>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  unnassign?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateTasksInput = {
  taskInputs: Array<UpdateTaskInput>;
};

export type UpdatedGranularAccess = {
  __typename?: "UpdatedGranularAccess";
  id: Scalars["String"]["output"];
  newType: AccessType;
  oldType: AccessType;
};

export type AcceptDealInviteMutationVariables = Exact<{
  token: Scalars["String"]["input"];
  ndaSigned: Scalars["Boolean"]["input"];
}>;

export type AcceptDealInviteMutation = {
  __typename?: "Mutation";
  acceptDealInvite: {
    __typename?: "Deal";
    id: string;
    company: { __typename?: "Company"; id: string; name: string };
  };
};

export type AcceptFirmInviteMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type AcceptFirmInviteMutation = {
  __typename?: "Mutation";
  acceptFirmInvite: { __typename?: "Firm"; id: string; name: string };
};

export type AccessQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type AccessQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    runner: DealRunner;
    company: {
      __typename?: "Company";
      id: string;
      name: string;
      logo: string;
      logoColor: string;
    };
    dataRoom: {
      __typename?: "DataRoom";
      id: string;
      permissions: Array<{
        __typename?: "DataRoomPermission";
        id: string;
        createdAt: number;
        name: string;
        description: string;
        accessLevel: number;
        fileCount: number;
      }>;
    };
    invites: Array<{
      __typename?: "DealInvite";
      id: string;
      email: string;
      createdAt: number;
      role: DealRole;
      status: DealInviteStatus;
      overrideDataRoomAccess: boolean;
      dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
      dataRoomPermission?: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
        description: string;
        accessLevel: number;
        fileCount: number;
      } | null;
      granularAccess?: {
        __typename?: "GranularAccess";
        files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
        folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
      } | null;
    }>;
    otherGuests: Array<{
      __typename?: "DealAccount";
      id: string;
      createdAt: number;
      dealAccessStatus: DealAccessStatus;
      overrideDataRoomAccess: boolean;
      lastSeen: number;
      role: DealRole;
      dataRoomPermission?: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
        createdAt: number;
        accessLevel: number;
        fileCount: number;
        description: string;
      } | null;
      granularAccess: {
        __typename?: "GranularAccess";
        files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
        folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
      };
      account: {
        __typename?: "Account";
        id: string;
        name: string;
        email: string;
      };
    }>;
    guestGroups: Array<{
      __typename?: "DealGroup";
      id: string;
      name: string;
      dealAccessStatus: DealAccessStatus;
      overrideDataRoomAccess: boolean;
      type: DealGroupType;
      granularAccess: {
        __typename?: "GranularAccess";
        files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
        folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
      };
      dataRoomPermission: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
        description: string;
        accessLevel: number;
        fileCount: number;
      };
      accounts: Array<{
        __typename?: "DealAccount";
        id: string;
        createdAt: number;
        dealAccessStatus: DealAccessStatus;
        overrideDataRoomAccess: boolean;
        lastSeen: number;
        role: DealRole;
        dataRoomPermission?: {
          __typename?: "DataRoomPermission";
          id: string;
          name: string;
          createdAt: number;
          accessLevel: number;
          fileCount: number;
          description: string;
        } | null;
        granularAccess: {
          __typename?: "GranularAccess";
          files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
          folders: Array<{
            __typename?: "Access";
            id: string;
            type: AccessType;
          }>;
        };
        account: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
        };
      }>;
    }>;
    activeDealAccount: {
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      dealGroup?: { __typename?: "DealGroup"; id: string } | null;
    };
  };
};

export type AccessControlAddMoreQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type AccessControlAddMoreQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    guestGroups: Array<{
      __typename?: "DealGroup";
      id: string;
      name: string;
      accounts: Array<{
        __typename?: "DealAccount";
        id: string;
        account: {
          __typename?: "Account";
          id: string;
          name: string;
          avatar: string;
        };
      }>;
    }>;
  };
};

export type AccessControlCheckQueryVariables = Exact<{
  input: AccessControlCheckInput;
}>;

export type AccessControlCheckQuery = {
  __typename?: "Query";
  accessControlCheck: {
    __typename?: "AccessControlCheckResponse";
    hasAccess: boolean;
  };
};

export type AccessMatrixQueryVariables = Exact<{
  dataRoomID: Scalars["String"]["input"];
  dealID: Scalars["String"]["input"];
}>;

export type AccessMatrixQuery = {
  __typename?: "Query";
  dataRoom: {
    __typename?: "DataRoom";
    allFolders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
      parentFolders: Array<{
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
      }>;
      files: Array<{
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        latestVersion: { __typename?: "DataRoomFileVersion"; id: string };
        permission: {
          __typename?: "DataRoomPermission";
          id: string;
          createdAt: number;
          name: string;
          accessLevel: number;
          description: string;
        };
      }>;
    }>;
  };
  deal: {
    __typename?: "Deal";
    id: string;
    dealAccounts: Array<{
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      account: {
        __typename?: "Account";
        id: string;
        name: string;
        email: string;
        avatar: string;
      };
    }>;
  };
  accessMatrix: Array<{
    __typename?: "AccessMatrix";
    entityKey: string;
    accountID: string;
    hasAccess: boolean;
  }>;
};

export type AccountQueryVariables = Exact<{ [key: string]: never }>;

export type AccountQuery = {
  __typename?: "Query";
  account: {
    __typename?: "Account";
    id: string;
    name: string;
    email: string;
    status: AccountStatus;
    createdAt: number;
    avatar: string;
    avatarColor: string;
    unreadNotificationCount: number;
    firmAccountType?: FirmAccountType | null;
    notifications: Array<{
      __typename?: "Notification";
      id: string;
      createdAt: number;
      title: string;
      message: string;
      type: NotificationType;
      readAt?: number | null;
      task?: { __typename?: "Task"; id: string } | null;
    }>;
    dealAccounts: Array<{ __typename?: "DealAccount"; role: DealRole }>;
    deals: Array<{
      __typename?: "Deal";
      id: string;
      runner: DealRunner;
      createdAt: number;
      status: DealStatus;
      dataRoom: { __typename?: "DataRoom"; id: string };
      activeDealAccount: {
        __typename?: "DealAccount";
        id: string;
        createdAt: number;
        dealAccessStatus: DealAccessStatus;
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      };
      company: {
        __typename?: "Company";
        name: string;
        logoColor: string;
        logo: string;
      };
      dealAccounts: Array<{
        __typename?: "DealAccount";
        id: string;
        role: DealRole;
        dealAccessStatus: DealAccessStatus;
        account: { __typename?: "Account"; id: string };
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      }>;
    }>;
    firm?: {
      __typename?: "Firm";
      id: string;
      name: string;
      website: string;
      logo: string;
      address: string;
      type: FirmType;
      accounts: Array<{
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        email: string;
      }>;
      dealProperties: Array<{
        __typename?: "DealProperty";
        id: string;
        propertyKey: string;
        createdAt: number;
        createdBy: {
          __typename?: "Account";
          id: string;
          name: string;
          avatar: string;
        };
      }>;
    } | null;
  };
};

export type AccountFragmentFragment = {
  __typename?: "Account";
  id: string;
  name: string;
  email: string;
  status: AccountStatus;
  createdAt: number;
  avatar: string;
  avatarColor: string;
  unreadNotificationCount: number;
  firmAccountType?: FirmAccountType | null;
  notifications: Array<{
    __typename?: "Notification";
    id: string;
    createdAt: number;
    title: string;
    message: string;
    type: NotificationType;
    readAt?: number | null;
    task?: { __typename?: "Task"; id: string } | null;
  }>;
  dealAccounts: Array<{ __typename?: "DealAccount"; role: DealRole }>;
  deals: Array<{
    __typename?: "Deal";
    id: string;
    runner: DealRunner;
    createdAt: number;
    status: DealStatus;
    dataRoom: { __typename?: "DataRoom"; id: string };
    activeDealAccount: {
      __typename?: "DealAccount";
      id: string;
      createdAt: number;
      dealAccessStatus: DealAccessStatus;
      dealGroup?: { __typename?: "DealGroup"; id: string } | null;
    };
    company: {
      __typename?: "Company";
      name: string;
      logoColor: string;
      logo: string;
    };
    dealAccounts: Array<{
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      dealAccessStatus: DealAccessStatus;
      account: { __typename?: "Account"; id: string };
      dealGroup?: { __typename?: "DealGroup"; id: string } | null;
    }>;
  }>;
  firm?: {
    __typename?: "Firm";
    id: string;
    name: string;
    website: string;
    logo: string;
    address: string;
    type: FirmType;
    accounts: Array<{
      __typename?: "Account";
      id: string;
      name: string;
      avatar: string;
      avatarColor: string;
      email: string;
    }>;
    dealProperties: Array<{
      __typename?: "DealProperty";
      id: string;
      propertyKey: string;
      createdAt: number;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
      };
    }>;
  } | null;
};

export type ActiveDealAccountQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type ActiveDealAccountQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    activeDealAccount: {
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      dealAccessStatus: DealAccessStatus;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        dealAccessStatus: DealAccessStatus;
      } | null;
      dealThread?: {
        __typename?: "DealThread";
        id: string;
        messages: Array<{
          __typename?: "DealThreadMessage";
          id: string;
          createdAt: number;
          status: DealThreadMessageStatus;
          role: DealThreadMessageRole;
          thread: { __typename?: "DealThread"; id: string };
          files: Array<{
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          }>;
          content: {
            __typename?: "DealThreadMessageContent";
            text: {
              __typename?: "DealThreadMessageText";
              value: string;
              annotations: Array<{
                __typename?: "DealThreadAnnotation";
                text: string;
                citation: {
                  __typename?: "DealThreadAnnotationCitation";
                  quote: string;
                };
              }>;
            };
            quotes: Array<{
              __typename?: "DealThreadMessageContentQuote";
              quote: string;
              file: { __typename?: "DataRoomFile"; id: string };
            }>;
            files: Array<{
              __typename?: "DealThreadMessageContentFile";
              pageIndex?: number | null;
              rectsOnPage: Array<Array<number>>;
              file: {
                __typename?: "DataRoomFile";
                id: string;
                name: string;
                fileType: FileType;
              };
            }>;
          };
        }>;
      } | null;
    };
  };
};

export type AddDeepSearchFileReferenceMutationVariables = Exact<{
  input: AddDeepSearchFileReferenceInput;
}>;

export type AddDeepSearchFileReferenceMutation = {
  __typename?: "Mutation";
  addDeepSearchFileReference: { __typename?: "DeepSearchFile"; id: string };
};

export type AddPeopleFromFirmQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
  firmId: Scalars["String"]["input"];
}>;

export type AddPeopleFromFirmQuery = {
  __typename?: "Query";
  firm: {
    __typename?: "Firm";
    id: string;
    accounts: Array<{
      __typename?: "Account";
      id: string;
      name: string;
      email: string;
      avatar: string;
      avatarColor: string;
      firmAccountType?: FirmAccountType | null;
    }>;
  };
  deal: {
    __typename?: "Deal";
    company: { __typename?: "Company"; id: string; name: string };
    dealAccounts: Array<{
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      account: {
        __typename?: "Account";
        id: string;
        firm?: { __typename?: "Firm"; id: string } | null;
      };
    }>;
    activeDealAccount: {
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        accounts: Array<{
          __typename?: "DealAccount";
          id: string;
          account: { __typename?: "Account"; id: string };
        }>;
      } | null;
    };
  };
};

export type AllDataRoomFoldersQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type AllDataRoomFoldersQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    dataRoom: {
      __typename?: "DataRoom";
      allFolders: Array<{
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
        parentFolders: Array<{
          __typename?: "DataRoomFolder";
          id: string;
          name: string;
        }>;
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
          latestVersion: { __typename?: "DataRoomFileVersion"; id: string };
          currentLiveVersion: {
            __typename?: "DataRoomFileVersion";
            id: string;
            versionNumber: number;
            createdAt: number;
            createdBy: {
              __typename?: "Account";
              id: string;
              name: string;
              avatar: string;
            };
          };
          versions: Array<{
            __typename?: "DataRoomFileVersion";
            id: string;
            versionNumber: number;
            createdAt: number;
            createdBy: {
              __typename?: "Account";
              id: string;
              name: string;
              avatar: string;
            };
          }>;
          permission: {
            __typename?: "DataRoomPermission";
            id: string;
            createdAt: number;
            name: string;
            accessLevel: number;
            description: string;
          };
        }>;
      }>;
    };
  };
};

export type AllFilesQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type AllFilesQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    dataRoom: {
      __typename?: "DataRoom";
      updatedAt: number;
      allFiles: Array<{
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        folderId: string;
        fileType: FileType;
        createdAt: number;
        createdBy: { __typename?: "Account"; id: string; name: string };
      }>;
    };
  };
};

export type AllFoldersQueryVariables = Exact<{
  folderId: Scalars["String"]["input"];
}>;

export type AllFoldersQuery = {
  __typename?: "Query";
  allFolders: Array<{
    __typename?: "DataRoomFolder";
    id: string;
    name: string;
    parentFolders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
    }>;
    files: Array<{
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
      latestVersion: { __typename?: "DataRoomFileVersion"; id: string };
      permission: {
        __typename?: "DataRoomPermission";
        id: string;
        createdAt: number;
        name: string;
        accessLevel: number;
        description: string;
      };
    }>;
  }>;
};

export type PublishDdqlMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type PublishDdqlMutation = {
  __typename?: "Mutation";
  publishDdql: { __typename?: "Ddql"; id: string };
};

export type ApproveDealInviteMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type ApproveDealInviteMutation = {
  __typename?: "Mutation";
  approveDealInvite: { __typename?: "DealInvite"; id: string };
};

export type AssignToDealGroupModalQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type AssignToDealGroupModalQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    guestGroups: Array<{
      __typename?: "DealGroup";
      id: string;
      name: string;
      accounts: Array<{ __typename?: "DealAccount"; id: string }>;
    }>;
  };
};

export type DeleteDdqlMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteDdqlMutation = {
  __typename?: "Mutation";
  deleteDdql: { __typename?: "Ddql"; id: string };
};

export type CheckFileAccessQueryVariables = Exact<{
  accountId: Scalars["String"]["input"];
  fileId: Scalars["String"]["input"];
}>;

export type CheckFileAccessQuery = {
  __typename?: "Query";
  checkFileAccess: boolean;
};

export type CompanyQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type CompanyQuery = {
  __typename?: "Query";
  company: { __typename?: "Company"; id: string; name: string; logo: string };
};

export type CopyDataRoomFileMutationVariables = Exact<{
  input: CopyDataRoomFileInput;
}>;

export type CopyDataRoomFileMutation = {
  __typename?: "Mutation";
  copyDataRoomFile: { __typename?: "DataRoomFile"; id: string };
};

export type CreateAccountMutationVariables = Exact<{
  clerkUserId: Scalars["String"]["input"];
}>;

export type CreateAccountMutation = {
  __typename?: "Mutation";
  createAccount: {
    __typename?: "Account";
    id: string;
    name: string;
    email: string;
    status: AccountStatus;
    createdAt: number;
    avatar: string;
    avatarColor: string;
    unreadNotificationCount: number;
    firmAccountType?: FirmAccountType | null;
    notifications: Array<{
      __typename?: "Notification";
      id: string;
      createdAt: number;
      title: string;
      message: string;
      type: NotificationType;
      readAt?: number | null;
      task?: { __typename?: "Task"; id: string } | null;
    }>;
    dealAccounts: Array<{ __typename?: "DealAccount"; role: DealRole }>;
    deals: Array<{
      __typename?: "Deal";
      id: string;
      runner: DealRunner;
      createdAt: number;
      status: DealStatus;
      dataRoom: { __typename?: "DataRoom"; id: string };
      activeDealAccount: {
        __typename?: "DealAccount";
        id: string;
        createdAt: number;
        dealAccessStatus: DealAccessStatus;
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      };
      company: {
        __typename?: "Company";
        name: string;
        logoColor: string;
        logo: string;
      };
      dealAccounts: Array<{
        __typename?: "DealAccount";
        id: string;
        role: DealRole;
        dealAccessStatus: DealAccessStatus;
        account: { __typename?: "Account"; id: string };
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      }>;
    }>;
    firm?: {
      __typename?: "Firm";
      id: string;
      name: string;
      website: string;
      logo: string;
      address: string;
      type: FirmType;
      accounts: Array<{
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        email: string;
      }>;
      dealProperties: Array<{
        __typename?: "DealProperty";
        id: string;
        propertyKey: string;
        createdAt: number;
        createdBy: {
          __typename?: "Account";
          id: string;
          name: string;
          avatar: string;
        };
      }>;
    } | null;
  };
};

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput;
}>;

export type CreateCommentMutation = {
  __typename?: "Mutation";
  createComment: { __typename?: "DealActivity"; id: string };
};

export type CreateDataRoomFileMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  dataRoomFolderID: Scalars["String"]["input"];
  dataRoomPermissionID: Scalars["String"]["input"];
  fileName: Scalars["String"]["input"];
  fileType: Scalars["String"]["input"];
  parts: Scalars["Int"]["input"];
}>;

export type CreateDataRoomFileMutation = {
  __typename?: "Mutation";
  createDataRoomFile: {
    __typename?: "CreateDataRoomFileResponse";
    multiPartUploadID: string;
    dataRoomFile: { __typename?: "DataRoomFile"; id: string };
    initialVersion: { __typename?: "DataRoomFileVersion"; id: string };
    parts: Array<{
      __typename?: "MultipartUrl";
      presignedUrl: string;
      partNumber: number;
    }>;
  };
};

export type CreateDataFileVersionMutationVariables = Exact<{
  dataRoomFileID: Scalars["String"]["input"];
  fileName: Scalars["String"]["input"];
  fileType: Scalars["String"]["input"];
  summary: Scalars["String"]["input"];
}>;

export type CreateDataFileVersionMutation = {
  __typename?: "Mutation";
  createDataRoomFileVersion: {
    __typename?: "PresignedUrlResponse";
    dataRoomFileVersionID: string;
    url: string;
  };
};

export type CreateDataRoomFolderMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  dataRoomFolderID: Scalars["String"]["input"];
}>;

export type CreateDataRoomFolderMutation = {
  __typename?: "Mutation";
  createDataRoomFolder: {
    __typename?: "DataRoomFolder";
    id: string;
    name: string;
    createdAt: number;
    hasFolderWriteAccess: boolean;
    context: DataRoomContext;
    createdBy: { __typename?: "Account"; email: string; name: string };
    dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
    files: Array<{
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      updatedAt: number;
      fileType: FileType;
      errorCheckingStatus: ErrorCheckingStatus;
      onlineStatus: DataRoomFileOnlineStatus;
      createdBy: { __typename?: "Account"; id: string; name: string };
      permission: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
      };
    }>;
    parentFolders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
    }>;
    folders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
      createdAt: number;
      dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
      permission?: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
      } | null;
      createdBy: { __typename?: "Account"; email: string; name: string };
    }>;
  };
};

export type CreateDdqlMutationVariables = Exact<{
  input: CreateDdqlInput;
}>;

export type CreateDdqlMutation = {
  __typename?: "Mutation";
  createDdql: {
    __typename?: "CreateDdqlResponse";
    multiPartUploadID: string;
    ddql: {
      __typename?: "Ddql";
      id: string;
      sourceFileName: string;
      sourceFileType: string;
      status?: DdqlStatus | null;
      createdAt: number;
      dealGroup: { __typename?: "DealGroup"; id: string; name: string };
    };
    parts: Array<{
      __typename?: "MultipartUrl";
      presignedUrl: string;
      partNumber: number;
    }>;
  };
};

export type CreateDdqlImportMutationVariables = Exact<{
  ddqlID: Scalars["String"]["input"];
  fileName: Scalars["String"]["input"];
  fileType: Scalars["String"]["input"];
  parts: Scalars["Int"]["input"];
}>;

export type CreateDdqlImportMutation = {
  __typename?: "Mutation";
  createDdqlImport: {
    __typename?: "CreateDdqlImportResponse";
    multiPartUploadID: string;
    ddql: {
      __typename?: "Ddql";
      id: string;
      sourceFileName: string;
      sourceFileType: string;
      status?: DdqlStatus | null;
      createdAt: number;
      dealGroup: { __typename?: "DealGroup"; id: string; name: string };
    };
    parts: Array<{
      __typename?: "MultipartUrl";
      presignedUrl: string;
      partNumber: number;
    }>;
  };
};

export type CreateDealMutationVariables = Exact<{
  input: CreateDealInput;
}>;

export type CreateDealMutation = {
  __typename?: "Mutation";
  createDeal: {
    __typename?: "Deal";
    id: string;
    company: { __typename?: "Company"; id: string; name: string };
  };
};

export type CreateDealAccountsMutationVariables = Exact<{
  input: CreateDealAccountsInput;
}>;

export type CreateDealAccountsMutation = {
  __typename?: "Mutation";
  createDealAccounts: Array<{
    __typename?: "DealAccount";
    account: { __typename?: "Account"; id: string };
  }>;
};

export type CreateDealExportMutationVariables = Exact<{
  input: CreateDealExportInput;
}>;

export type CreateDealExportMutation = {
  __typename?: "Mutation";
  createDealExport: {
    __typename?: "DealExport";
    id: string;
    status: DealExportStatus;
    createdAt: number;
  };
};

export type CreateDealFirmMutationVariables = Exact<{
  input: CreateDealFirmInput;
}>;

export type CreateDealFirmMutation = {
  __typename?: "Mutation";
  createDealFirm: {
    __typename?: "DealFirm";
    id: string;
    name: string;
    ndaDataRoomFile?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
    } | null;
  };
};

export type CreateDealGroupMutationVariables = Exact<{
  input: CreateDealGroupInput;
}>;

export type CreateDealGroupMutation = {
  __typename?: "Mutation";
  createDealGroup: {
    __typename?: "DealGroup";
    id: string;
    name: string;
    ndaDataRoomFile?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
    } | null;
  };
};

export type CreateDealInviteMutationVariables = Exact<{
  input: CreateDealInviteInput;
}>;

export type CreateDealInviteMutation = {
  __typename?: "Mutation";
  createDealInvite: {
    __typename?: "DealInvite";
    id: string;
    email: string;
    role: DealRole;
    createdAt: number;
    deal: { __typename?: "Deal"; id: string };
  };
};

export type CreateDealPropertyMutationVariables = Exact<{
  input: CreateDealPropertyInput;
}>;

export type CreateDealPropertyMutation = {
  __typename?: "Mutation";
  createDealProperty: { __typename?: "DealProperty"; id: string };
};

export type CreateDealThreadMutationVariables = Exact<{
  input: CreateDealThreadInput;
}>;

export type CreateDealThreadMutation = {
  __typename?: "Mutation";
  createDealThread: {
    __typename?: "DealThread";
    id: string;
    messages: Array<{
      __typename?: "DealThreadMessage";
      id: string;
      createdAt: number;
      status: DealThreadMessageStatus;
      role: DealThreadMessageRole;
      thread: { __typename?: "DealThread"; id: string };
      files: Array<{
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      }>;
      content: {
        __typename?: "DealThreadMessageContent";
        text: {
          __typename?: "DealThreadMessageText";
          value: string;
          annotations: Array<{
            __typename?: "DealThreadAnnotation";
            text: string;
            citation: {
              __typename?: "DealThreadAnnotationCitation";
              quote: string;
            };
          }>;
        };
      };
    }>;
  };
};

export type CreateDealThreadMessageMutationVariables = Exact<{
  input: CreateDealThreadMessageInput;
}>;

export type CreateDealThreadMessageMutation = {
  __typename?: "Mutation";
  createDealThreadMessage: {
    __typename?: "CreateDealThreadMessageResponse";
    dealThreadMessage: {
      __typename?: "DealThreadMessage";
      id: string;
      createdAt: number;
    };
    responseMessage: {
      __typename?: "DealThreadMessage";
      id: string;
      status: DealThreadMessageStatus;
      role: DealThreadMessageRole;
      createdAt: number;
    };
  };
};

export type CreateDeepSearchMutationVariables = Exact<{
  input: CreateDeepSearchInput;
}>;

export type CreateDeepSearchMutation = {
  __typename?: "Mutation";
  createDeepSearch: { __typename?: "DeepSearch"; id: string };
};

export type CreateDeepSearchFileMutationVariables = Exact<{
  input: CreateDeepSearchFileInput;
}>;

export type CreateDeepSearchFileMutation = {
  __typename?: "Mutation";
  createDeepSearchFile: { __typename?: "DeepSearchFile"; id: string };
};

export type CreateDeepSearchThreadMessageMutationVariables = Exact<{
  input: CreateDeepSearchThreadMessageInput;
}>;

export type CreateDeepSearchThreadMessageMutation = {
  __typename?: "Mutation";
  createDeepSearchThreadMessage: {
    __typename?: "DeepSearchThreadMessage";
    id: string;
    createdAt: number;
  };
};

export type CreateDueDiligenceChecklistItemMutationVariables = Exact<{
  input: CreateDueDiligenceChecklistItemInput;
}>;

export type CreateDueDiligenceChecklistItemMutation = {
  __typename?: "Mutation";
  createDueDiligenceChecklistItem: {
    __typename?: "DueDiligenceChecklistItem";
    id: string;
    title: string;
    createdAt: number;
    completedAt?: number | null;
    type: DueDiligenceChecklistItemType;
    dueDate?: number | null;
    dataRoomFile?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
    } | null;
    assignedTo?: {
      __typename?: "Account";
      id: string;
      name: string;
      avatar: string;
      avatarColor: string;
      firm?: { __typename?: "Firm"; id: string; name: string } | null;
    } | null;
    createdBy: {
      __typename?: "Account";
      id: string;
      name: string;
      avatar: string;
      avatarColor: string;
      firm?: { __typename?: "Firm"; id: string; name: string } | null;
    };
  };
};

export type DueDiligenceChecklistItemFragment = {
  __typename?: "DueDiligenceChecklistItem";
  id: string;
  title: string;
  createdAt: number;
  completedAt?: number | null;
  type: DueDiligenceChecklistItemType;
  dueDate?: number | null;
  dataRoomFile?: {
    __typename?: "DataRoomFile";
    id: string;
    name: string;
    fileType: FileType;
  } | null;
  assignedTo?: {
    __typename?: "Account";
    id: string;
    name: string;
    avatar: string;
    avatarColor: string;
    firm?: { __typename?: "Firm"; id: string; name: string } | null;
  } | null;
  createdBy: {
    __typename?: "Account";
    id: string;
    name: string;
    avatar: string;
    avatarColor: string;
    firm?: { __typename?: "Firm"; id: string; name: string } | null;
  };
};

export type CreateErrorCheckMutationVariables = Exact<{
  input: CreateErrorCheckInput;
}>;

export type CreateErrorCheckMutation = {
  __typename?: "Mutation";
  createErrorCheck: { __typename?: "ErrorCheck"; id: string };
};

export type CreateFirmMutationVariables = Exact<{
  input: CreateFirmInput;
}>;

export type CreateFirmMutation = {
  __typename?: "Mutation";
  createFirm: { __typename?: "Firm"; id: string; name: string };
};

export type CreateFirmInviteMutationVariables = Exact<{
  input: CreateFirmInviteInput;
}>;

export type CreateFirmInviteMutation = {
  __typename?: "Mutation";
  createFirmInvite: { __typename?: "FirmInvite"; id: string; email: string };
};

export type CreateFirmInvitesMutationVariables = Exact<{
  input: CreateFirmInvitesInput;
}>;

export type CreateFirmInvitesMutation = {
  __typename?: "Mutation";
  createFirmInvites: Array<{ __typename?: "FirmInvite"; id: string }>;
};

export type CreateIgnoreErrorRuleMutationVariables = Exact<{
  input: CreateIgnoreErrorRuleInput;
}>;

export type CreateIgnoreErrorRuleMutation = {
  __typename?: "Mutation";
  createIgnoreErrorRule: { __typename?: "IgnoreErrorRule"; id: string };
};

export type CreateOrUpdateAccessControlsMutationVariables = Exact<{
  input: CreateOrUpdateAccessControlsInput;
}>;

export type CreateOrUpdateAccessControlsMutation = {
  __typename?: "Mutation";
  createOrUpdateAccessControls: Array<{
    __typename?: "AccessControl";
    id: string;
  }>;
};

export type CreatePnlVersionMutationVariables = Exact<{
  input: PnlInput;
}>;

export type CreatePnlVersionMutation = {
  __typename?: "Mutation";
  createPnlVersion: { __typename?: "Pnl"; id: string };
};

export type CreateQuestionMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  body: Scalars["String"]["input"];
  nodeId: Scalars["String"]["input"];
  nodeType: QuestionNodeType;
  ddqlID?: InputMaybe<Scalars["String"]["input"]>;
  questionForDealGroupID?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateQuestionMutation = {
  __typename?: "Mutation";
  createQuestion: { __typename?: "Question"; id: string };
};

export type CreateQuestionAnswerMutationVariables = Exact<{
  questionId: Scalars["String"]["input"];
  answer: Scalars["String"]["input"];
  dataRoomFileIds:
    | Array<Scalars["String"]["input"]>
    | Scalars["String"]["input"];
  answerFiles:
    | Array<DealThreadMessageContentFileInput>
    | DealThreadMessageContentFileInput;
}>;

export type CreateQuestionAnswerMutation = {
  __typename?: "Mutation";
  createQuestionAnswer: {
    __typename?: "Question";
    id: string;
    answer?: {
      __typename?: "QuestionAnswer";
      id: string;
      answer: string;
      answeredBy: { __typename?: "Account"; id: string; name: string };
    } | null;
  };
};

export type CreateRedlineExportMutationVariables = Exact<{
  input: CreateRedlineExportInput;
}>;

export type CreateRedlineExportMutation = {
  __typename?: "Mutation";
  createRedlineExport: {
    __typename?: "RedlineExport";
    id: string;
    status: RedlineExportStatus;
  };
};

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskInput;
}>;

export type CreateTaskMutation = {
  __typename?: "Mutation";
  createTask: {
    __typename?: "Task";
    id: string;
    title: string;
    description: string;
  };
};

export type CreateTaskCommentMutationVariables = Exact<{
  input: CreateTaskCommentInput;
}>;

export type CreateTaskCommentMutation = {
  __typename?: "Mutation";
  createTaskComment: {
    __typename?: "TaskActivity";
    id: string;
    createdAt: number;
    comment?: { __typename?: "Comment"; comment: string } | null;
    account: {
      __typename?: "Account";
      id: string;
      name: string;
      firstName: string;
      avatar: string;
    };
  };
};

export type DashboardQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
  input: DataRoomActivityMetricsInput;
  activityInput: DealActivityInput;
}>;

export type DashboardQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    currentDealStageStatus?: DealStageStatus | null;
    dataRoom: {
      __typename?: "DataRoom";
      id: string;
      createdAt: number;
      updatedAt: number;
      folder: {
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
        createdAt: number;
        hasFolderWriteAccess: boolean;
        context: DataRoomContext;
        createdBy: { __typename?: "Account"; email: string; name: string };
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          updatedAt: number;
          fileType: FileType;
          errorCheckingStatus: ErrorCheckingStatus;
          onlineStatus: DataRoomFileOnlineStatus;
          createdBy: { __typename?: "Account"; id: string; name: string };
          permission: {
            __typename?: "DataRoomPermission";
            id: string;
            name: string;
          };
        }>;
        parentFolders: Array<{
          __typename?: "DataRoomFolder";
          id: string;
          name: string;
        }>;
        folders: Array<{
          __typename?: "DataRoomFolder";
          id: string;
          name: string;
          createdAt: number;
          dealGroup?: {
            __typename?: "DealGroup";
            id: string;
            name: string;
          } | null;
          permission?: {
            __typename?: "DataRoomPermission";
            id: string;
            name: string;
          } | null;
          createdBy: { __typename?: "Account"; email: string; name: string };
        }>;
      };
      permissions: Array<{
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
        description: string;
        accessLevel: number;
        createdAt: number;
        fileCount: number;
      }>;
    };
    activeDealAccount: {
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      dealAccessStatus: DealAccessStatus;
      account: { __typename?: "Account"; id: string; email: string };
    };
    dealStages: Array<{
      __typename?: "DealStage";
      id: string;
      name: string;
      index: number;
    }>;
    currentDealStage?: {
      __typename?: "DealStage";
      id: string;
      index: number;
      name: string;
    } | null;
    buyers: Array<{
      __typename?: "DealGroup";
      id: string;
      name: string;
      createdAt: number;
      dealAccessStatus: DealAccessStatus;
      lastSeen?: number | null;
      currentDealStageStatus?: DealStageStatus | null;
      files: Array<{
        __typename?: "DataRoomFile";
        id: string;
        fileType: FileType;
        name: string;
        createdAt: number;
      }>;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        createdAt: number;
      }>;
      currentDealStage?: {
        __typename?: "DealStage";
        id: string;
        index: number;
      } | null;
      questions: Array<{
        __typename?: "Question";
        id: string;
        answeredAt?: number | null;
      }>;
      dataRoomPermission: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
        description: string;
        accessLevel: number;
      };
      dealFirms: Array<{
        __typename?: "DealFirm";
        id: string;
        name: string;
        dealAccounts: Array<{
          __typename?: "DealAccount";
          role: DealRole;
          account: {
            __typename?: "Account";
            id: string;
            name: string;
            email: string;
          };
        }>;
      }>;
    }>;
    invites: Array<{
      __typename?: "DealInvite";
      id: string;
      email: string;
      role: DealRole;
    }>;
    dealAccounts: Array<{
      __typename?: "DealAccount";
      role: DealRole;
      account: {
        __typename?: "Account";
        id: string;
        name: string;
        email: string;
      };
    }>;
  };
  dealActivity: {
    __typename?: "DealActivityResponse";
    activity: Array<{
      __typename?: "DealActivity";
      id: string;
      createdAt: number;
      type: DealActivityType;
      account: {
        __typename?: "Account";
        id: string;
        name: string;
        email: string;
        avatar: string;
        avatarColor: string;
      };
      folder?: {
        __typename?: "DataRoomFolder";
        id: string;
        createdAt: number;
        name: string;
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      } | null;
      file?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        folderId: string;
      } | null;
      version?: {
        __typename?: "DataRoomFileVersion";
        id: string;
        summary: string;
      } | null;
      question?: { __typename?: "Question"; id: string; title: string } | null;
      comment?: {
        __typename?: "Comment";
        comment: string;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        }>;
      } | null;
      invite?: { __typename?: "DealInvite"; id: string; email: string } | null;
      accountImpersonation?: {
        __typename?: "Account";
        id: string;
        name: string;
        email: string;
      } | null;
      accessActivity?: {
        __typename?: "AccessActivity";
        account?: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
        } | null;
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
        fileDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        folderDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        permission?: {
          __typename?: "DataRoomPermission";
          id: string;
          name: string;
        } | null;
      } | null;
    }>;
  };
  questions: {
    __typename?: "QuestionsRespose";
    questions: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      body: string;
      createdAt: number;
      answeredAt?: number | null;
      dataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      } | null;
      answer?: { __typename?: "QuestionAnswer"; id: string } | null;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        type: DealActivityType;
      }>;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
        type: DealGroupType;
      } | null;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      };
      forDealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
      } | null;
    }>;
  };
  dataRoomActivityMetrics: {
    __typename?: "DataRoomActivityMetricsResponse";
    cursor: string;
    metrics: Array<{
      __typename?: "DataRoomActivityMetrics";
      key: string;
      views: number;
      downloads: number;
    }>;
  };
};

export type DataRoomQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type DataRoomQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    runner: DealRunner;
    dataRoom: {
      __typename?: "DataRoom";
      id: string;
      createdAt: number;
      updatedAt: number;
      folder: {
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
        createdAt: number;
        hasFolderWriteAccess: boolean;
        context: DataRoomContext;
        createdBy: { __typename?: "Account"; email: string; name: string };
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          updatedAt: number;
          fileType: FileType;
          errorCheckingStatus: ErrorCheckingStatus;
          onlineStatus: DataRoomFileOnlineStatus;
          createdBy: { __typename?: "Account"; id: string; name: string };
          permission: {
            __typename?: "DataRoomPermission";
            id: string;
            name: string;
          };
        }>;
        parentFolders: Array<{
          __typename?: "DataRoomFolder";
          id: string;
          name: string;
        }>;
        folders: Array<{
          __typename?: "DataRoomFolder";
          id: string;
          name: string;
          createdAt: number;
          dealGroup?: {
            __typename?: "DealGroup";
            id: string;
            name: string;
          } | null;
          permission?: {
            __typename?: "DataRoomPermission";
            id: string;
            name: string;
          } | null;
          createdBy: { __typename?: "Account"; email: string; name: string };
        }>;
      };
      permissions: Array<{
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
        description: string;
        accessLevel: number;
        createdAt: number;
        fileCount: number;
      }>;
    };
    activeDealAccount: { __typename?: "DealAccount"; role: DealRole };
  };
};

export type DataRoomFragmentFragment = {
  __typename?: "DataRoom";
  id: string;
  createdAt: number;
  updatedAt: number;
  folder: {
    __typename?: "DataRoomFolder";
    id: string;
    name: string;
    createdAt: number;
    hasFolderWriteAccess: boolean;
    context: DataRoomContext;
    createdBy: { __typename?: "Account"; email: string; name: string };
    dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
    files: Array<{
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      updatedAt: number;
      fileType: FileType;
      errorCheckingStatus: ErrorCheckingStatus;
      onlineStatus: DataRoomFileOnlineStatus;
      createdBy: { __typename?: "Account"; id: string; name: string };
      permission: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
      };
    }>;
    parentFolders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
    }>;
    folders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
      createdAt: number;
      dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
      permission?: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
      } | null;
      createdBy: { __typename?: "Account"; email: string; name: string };
    }>;
  };
  permissions: Array<{
    __typename?: "DataRoomPermission";
    id: string;
    name: string;
    description: string;
    accessLevel: number;
    createdAt: number;
    fileCount: number;
  }>;
};

export type DataRoomActivityMetricsQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
  input: DataRoomActivityMetricsInput;
}>;

export type DataRoomActivityMetricsQuery = {
  __typename?: "Query";
  dataRoomActivityMetrics: {
    __typename?: "DataRoomActivityMetricsResponse";
    cursor: string;
    activity: Array<{
      __typename?: "DealActivity";
      id: string;
      createdAt: number;
      type: DealActivityType;
      mergedFile?: boolean | null;
      account: { __typename?: "Account"; id: string; name: string };
      folder?: {
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      } | null;
      file?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        folderId: string;
      } | null;
      question?: { __typename?: "Question"; id: string; title: string } | null;
      dataRoomFileError?: {
        __typename?: "DataRoomFileError";
        id: string;
        key: string;
        dismissalReason: string;
        resolvedMessage: string;
        files: Array<{
          __typename?: "DataRoomFileErrorFile";
          file: {
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          };
        }>;
      } | null;
      comment?: {
        __typename?: "Comment";
        comment: string;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        }>;
      } | null;
      invite?: { __typename?: "DealInvite"; id: string; email: string } | null;
      accountImpersonation?: { __typename?: "Account"; name: string } | null;
      accessActivity?: {
        __typename?: "AccessActivity";
        account?: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
        } | null;
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
        fileDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        folderDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        permission?: {
          __typename?: "DataRoomPermission";
          id: string;
          name: string;
        } | null;
      } | null;
    }>;
    metrics: Array<{
      __typename?: "DataRoomActivityMetrics";
      key: string;
      views: number;
      downloads: number;
    }>;
  };
  deal: {
    __typename?: "Deal";
    activeDealAccount: { __typename?: "DealAccount"; role: DealRole };
    dealAccounts: Array<{
      __typename?: "DealAccount";
      role: DealRole;
      account: {
        __typename?: "Account";
        id: string;
        name: string;
        email: string;
      };
    }>;
    dataRoom: {
      __typename?: "DataRoom";
      allFiles: Array<{
        __typename?: "DataRoomFile";
        id: string;
        name: string;
      }>;
    };
  };
};

export type DataRoomFileQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DataRoomFileQuery = {
  __typename?: "Query";
  dataRoomFile: {
    __typename?: "DataRoomFile";
    id: string;
    name: string;
    description: string;
    url: string;
    createdAt: number;
    deletedAt?: number | null;
    deletionType?: DeletionType | null;
    context: DataRoomContext;
    onlineStatus: DataRoomFileOnlineStatus;
    hasAccess: boolean;
    hasDownloadAccess: boolean;
    folderId: string;
    updatedAt: number;
    fileType: FileType;
    uploadStatus: DataRoomFileUploadStatus;
    documentSummaryStatus: DocumentSummaryStatus;
    currentLiveVersion: {
      __typename?: "DataRoomFileVersion";
      id: string;
      pspdfkitDocumentId: string;
      pspdfkitToken: string;
      versionNumber: number;
    };
    createdBy: { __typename?: "Account"; id: string; name: string };
    permission: {
      __typename?: "DataRoomPermission";
      id: string;
      name: string;
      description: string;
    };
    parentFolders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
    }>;
    versions: Array<{
      __typename?: "DataRoomFileVersion";
      id: string;
      summary: string;
      hash: string;
      shortHash: string;
      versionNumber: number;
      fileName: string;
      fileType: FileType;
      createdAt: number;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        createdAt: number;
        type: DealActivityType;
        mergedFile?: boolean | null;
        account: { __typename?: "Account"; id: string; name: string };
        folder?: {
          __typename?: "DataRoomFolder";
          id: string;
          name: string;
          dealGroup?: { __typename?: "DealGroup"; id: string } | null;
        } | null;
        file?: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
          folderId: string;
        } | null;
        question?: {
          __typename?: "Question";
          id: string;
          title: string;
        } | null;
        dataRoomFileError?: {
          __typename?: "DataRoomFileError";
          id: string;
          key: string;
          dismissalReason: string;
          resolvedMessage: string;
          files: Array<{
            __typename?: "DataRoomFileErrorFile";
            file: {
              __typename?: "DataRoomFile";
              id: string;
              name: string;
              fileType: FileType;
            };
          }>;
        } | null;
        comment?: {
          __typename?: "Comment";
          comment: string;
          files: Array<{
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          }>;
        } | null;
        invite?: {
          __typename?: "DealInvite";
          id: string;
          email: string;
        } | null;
        accountImpersonation?: { __typename?: "Account"; name: string } | null;
        accessActivity?: {
          __typename?: "AccessActivity";
          account?: {
            __typename?: "Account";
            id: string;
            name: string;
            email: string;
          } | null;
          dealGroup?: {
            __typename?: "DealGroup";
            id: string;
            name: string;
          } | null;
          fileDifferences?: {
            __typename?: "GranularAccessChanges";
            updated: Array<{
              __typename?: "UpdatedGranularAccess";
              id: string;
              oldType: AccessType;
              newType: AccessType;
            }>;
          } | null;
          folderDifferences?: {
            __typename?: "GranularAccessChanges";
            updated: Array<{
              __typename?: "UpdatedGranularAccess";
              id: string;
              oldType: AccessType;
              newType: AccessType;
            }>;
          } | null;
          permission?: {
            __typename?: "DataRoomPermission";
            id: string;
            name: string;
          } | null;
        } | null;
      }>;
      changes: {
        __typename?: "FileVersionChanges";
        summary: string;
        textChanges: Array<{
          __typename?: "FileVersionTextChange";
          section: string;
          previous: string;
          new: string;
          diffs: Array<{
            __typename?: "TextDiff";
            type: TextDiffType;
            text: string;
          }>;
        }>;
      };
      createdBy: {
        __typename?: "Account";
        id: string;
        email: string;
        name: string;
        avatar: string;
      };
    }>;
    latestVersion: {
      __typename?: "DataRoomFileVersion";
      id: string;
      summary: string;
      createdAt: number;
      createdBy: {
        __typename?: "Account";
        id: string;
        email: string;
        name: string;
        avatar: string;
        avatarColor: string;
      };
    };
    questions: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      body: string;
      createdAt: number;
      answeredAt?: number | null;
      dataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      } | null;
      answer?: { __typename?: "QuestionAnswer"; id: string } | null;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        type: DealActivityType;
      }>;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
        type: DealGroupType;
      } | null;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      };
      forDealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
      } | null;
    }>;
    stats: {
      __typename?: "DataRoomFileStats";
      views: number;
      downloads: number;
    };
    activity: Array<{
      __typename?: "DealActivity";
      id: string;
      createdAt: number;
      type: DealActivityType;
      mergedFile?: boolean | null;
      account: { __typename?: "Account"; id: string; name: string };
      folder?: {
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      } | null;
      file?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        folderId: string;
      } | null;
      question?: { __typename?: "Question"; id: string; title: string } | null;
      dataRoomFileError?: {
        __typename?: "DataRoomFileError";
        id: string;
        key: string;
        dismissalReason: string;
        resolvedMessage: string;
        files: Array<{
          __typename?: "DataRoomFileErrorFile";
          file: {
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          };
        }>;
      } | null;
      comment?: {
        __typename?: "Comment";
        comment: string;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        }>;
      } | null;
      invite?: { __typename?: "DealInvite"; id: string; email: string } | null;
      accountImpersonation?: { __typename?: "Account"; name: string } | null;
      accessActivity?: {
        __typename?: "AccessActivity";
        account?: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
        } | null;
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
        fileDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        folderDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        permission?: {
          __typename?: "DataRoomPermission";
          id: string;
          name: string;
        } | null;
      } | null;
    }>;
    documentSummary: {
      __typename?: "DocumentSummary";
      summary: string;
      keyPoints: Array<{
        __typename?: "KeyPoint";
        title: string;
        description: string;
      }>;
    };
  };
};

export type DataRoomFileFragmentFragment = {
  __typename?: "DataRoomFile";
  id: string;
  name: string;
  description: string;
  url: string;
  createdAt: number;
  deletedAt?: number | null;
  deletionType?: DeletionType | null;
  context: DataRoomContext;
  onlineStatus: DataRoomFileOnlineStatus;
  hasAccess: boolean;
  hasDownloadAccess: boolean;
  folderId: string;
  updatedAt: number;
  fileType: FileType;
  uploadStatus: DataRoomFileUploadStatus;
  documentSummaryStatus: DocumentSummaryStatus;
  currentLiveVersion: {
    __typename?: "DataRoomFileVersion";
    id: string;
    pspdfkitDocumentId: string;
    pspdfkitToken: string;
    versionNumber: number;
  };
  createdBy: { __typename?: "Account"; id: string; name: string };
  permission: {
    __typename?: "DataRoomPermission";
    id: string;
    name: string;
    description: string;
  };
  parentFolders: Array<{
    __typename?: "DataRoomFolder";
    id: string;
    name: string;
  }>;
  versions: Array<{
    __typename?: "DataRoomFileVersion";
    id: string;
    summary: string;
    hash: string;
    shortHash: string;
    versionNumber: number;
    fileName: string;
    fileType: FileType;
    createdAt: number;
    activity: Array<{
      __typename?: "DealActivity";
      id: string;
      createdAt: number;
      type: DealActivityType;
      mergedFile?: boolean | null;
      account: { __typename?: "Account"; id: string; name: string };
      folder?: {
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      } | null;
      file?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        folderId: string;
      } | null;
      question?: { __typename?: "Question"; id: string; title: string } | null;
      dataRoomFileError?: {
        __typename?: "DataRoomFileError";
        id: string;
        key: string;
        dismissalReason: string;
        resolvedMessage: string;
        files: Array<{
          __typename?: "DataRoomFileErrorFile";
          file: {
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          };
        }>;
      } | null;
      comment?: {
        __typename?: "Comment";
        comment: string;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        }>;
      } | null;
      invite?: { __typename?: "DealInvite"; id: string; email: string } | null;
      accountImpersonation?: { __typename?: "Account"; name: string } | null;
      accessActivity?: {
        __typename?: "AccessActivity";
        account?: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
        } | null;
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
        fileDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        folderDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        permission?: {
          __typename?: "DataRoomPermission";
          id: string;
          name: string;
        } | null;
      } | null;
    }>;
    changes: {
      __typename?: "FileVersionChanges";
      summary: string;
      textChanges: Array<{
        __typename?: "FileVersionTextChange";
        section: string;
        previous: string;
        new: string;
        diffs: Array<{
          __typename?: "TextDiff";
          type: TextDiffType;
          text: string;
        }>;
      }>;
    };
    createdBy: {
      __typename?: "Account";
      id: string;
      email: string;
      name: string;
      avatar: string;
    };
  }>;
  latestVersion: {
    __typename?: "DataRoomFileVersion";
    id: string;
    summary: string;
    createdAt: number;
    createdBy: {
      __typename?: "Account";
      id: string;
      email: string;
      name: string;
      avatar: string;
      avatarColor: string;
    };
  };
  questions: Array<{
    __typename?: "Question";
    id: string;
    title: string;
    body: string;
    createdAt: number;
    answeredAt?: number | null;
    dataRoomFile?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
    } | null;
    answer?: { __typename?: "QuestionAnswer"; id: string } | null;
    activity: Array<{
      __typename?: "DealActivity";
      id: string;
      type: DealActivityType;
    }>;
    dealGroup?: {
      __typename?: "DealGroup";
      id: string;
      name: string;
      type: DealGroupType;
    } | null;
    createdBy: {
      __typename?: "Account";
      id: string;
      name: string;
      avatar: string;
      avatarColor: string;
      firm?: { __typename?: "Firm"; id: string; name: string } | null;
    };
    forDealGroup?: {
      __typename?: "DealGroup";
      id: string;
      name: string;
    } | null;
  }>;
  stats: { __typename?: "DataRoomFileStats"; views: number; downloads: number };
  activity: Array<{
    __typename?: "DealActivity";
    id: string;
    createdAt: number;
    type: DealActivityType;
    mergedFile?: boolean | null;
    account: { __typename?: "Account"; id: string; name: string };
    folder?: {
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
      dealGroup?: { __typename?: "DealGroup"; id: string } | null;
    } | null;
    file?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
      folderId: string;
    } | null;
    question?: { __typename?: "Question"; id: string; title: string } | null;
    dataRoomFileError?: {
      __typename?: "DataRoomFileError";
      id: string;
      key: string;
      dismissalReason: string;
      resolvedMessage: string;
      files: Array<{
        __typename?: "DataRoomFileErrorFile";
        file: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        };
      }>;
    } | null;
    comment?: {
      __typename?: "Comment";
      comment: string;
      files: Array<{
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      }>;
    } | null;
    invite?: { __typename?: "DealInvite"; id: string; email: string } | null;
    accountImpersonation?: { __typename?: "Account"; name: string } | null;
    accessActivity?: {
      __typename?: "AccessActivity";
      account?: {
        __typename?: "Account";
        id: string;
        name: string;
        email: string;
      } | null;
      dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
      fileDifferences?: {
        __typename?: "GranularAccessChanges";
        updated: Array<{
          __typename?: "UpdatedGranularAccess";
          id: string;
          oldType: AccessType;
          newType: AccessType;
        }>;
      } | null;
      folderDifferences?: {
        __typename?: "GranularAccessChanges";
        updated: Array<{
          __typename?: "UpdatedGranularAccess";
          id: string;
          oldType: AccessType;
          newType: AccessType;
        }>;
      } | null;
      permission?: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
      } | null;
    } | null;
  }>;
  documentSummary: {
    __typename?: "DocumentSummary";
    summary: string;
    keyPoints: Array<{
      __typename?: "KeyPoint";
      title: string;
      description: string;
    }>;
  };
};

export type DataRoomFileErrorQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DataRoomFileErrorQuery = {
  __typename?: "Query";
  dataRoomFileError: {
    __typename?: "DataRoomFileError";
    id: string;
    createdAt: number;
    title: string;
    status: DataRoomFileErrorStatus;
    key: string;
    baseKey: string;
    dismissalReason: string;
    files: Array<{
      __typename?: "DataRoomFileErrorFile";
      key: string;
      value: number;
      status: ErrorCheckingStatus;
      file: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        versions: Array<{
          __typename?: "DataRoomFileVersion";
          id: string;
          createdAt: number;
          summary: string;
          createdBy: {
            __typename?: "Account";
            id: string;
            email: string;
            name: string;
          };
        }>;
      };
    }>;
  };
};

export type DataRoomFileErrorsQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type DataRoomFileErrorsQuery = {
  __typename?: "Query";
  dataRoomFileErrors: Array<{
    __typename?: "DataRoomFileError";
    id: string;
    createdAt: number;
    key: string;
    title: string;
    status: DataRoomFileErrorStatus;
    files: Array<{
      __typename?: "DataRoomFileErrorFile";
      value: number;
      file: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      };
    }>;
  }>;
};

export type DataRoomFileVersionQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DataRoomFileVersionQuery = {
  __typename?: "Query";
  dataRoomFileVersion: {
    __typename?: "DataRoomFileVersion";
    id: string;
    pspdfkitDocumentId: string;
    pspdfkitToken: string;
  };
};

export type DataRoomFolderQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DataRoomFolderQuery = {
  __typename?: "Query";
  dataRoomFolder: {
    __typename?: "DataRoomFolder";
    id: string;
    name: string;
    createdAt: number;
    hasFolderWriteAccess: boolean;
    context: DataRoomContext;
    createdBy: { __typename?: "Account"; email: string; name: string };
    dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
    files: Array<{
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      updatedAt: number;
      fileType: FileType;
      errorCheckingStatus: ErrorCheckingStatus;
      onlineStatus: DataRoomFileOnlineStatus;
      createdBy: { __typename?: "Account"; id: string; name: string };
      permission: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
      };
    }>;
    parentFolders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
    }>;
    folders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
      createdAt: number;
      dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
      permission?: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
      } | null;
      createdBy: { __typename?: "Account"; email: string; name: string };
    }>;
  };
};

export type DataRoomFolderFragmentFragment = {
  __typename?: "DataRoomFolder";
  id: string;
  name: string;
  createdAt: number;
  hasFolderWriteAccess: boolean;
  context: DataRoomContext;
  createdBy: { __typename?: "Account"; email: string; name: string };
  dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
  files: Array<{
    __typename?: "DataRoomFile";
    id: string;
    name: string;
    updatedAt: number;
    fileType: FileType;
    errorCheckingStatus: ErrorCheckingStatus;
    onlineStatus: DataRoomFileOnlineStatus;
    createdBy: { __typename?: "Account"; id: string; name: string };
    permission: { __typename?: "DataRoomPermission"; id: string; name: string };
  }>;
  parentFolders: Array<{
    __typename?: "DataRoomFolder";
    id: string;
    name: string;
  }>;
  folders: Array<{
    __typename?: "DataRoomFolder";
    id: string;
    name: string;
    createdAt: number;
    dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
    permission?: {
      __typename?: "DataRoomPermission";
      id: string;
      name: string;
    } | null;
    createdBy: { __typename?: "Account"; email: string; name: string };
  }>;
};

export type DdChecklistQueryVariables = Exact<{
  dealGroupId: Scalars["String"]["input"];
}>;

export type DdChecklistQuery = {
  __typename?: "Query";
  dealGroup: {
    __typename?: "DealGroup";
    id: string;
    name: string;
    ddChecklistItems: Array<{
      __typename?: "DueDiligenceChecklistItem";
      id: string;
      title: string;
      createdAt: number;
      completedAt?: number | null;
      type: DueDiligenceChecklistItemType;
      dueDate?: number | null;
      dataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      } | null;
      assignedTo?: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      } | null;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      };
    }>;
  };
};

export type DdChecklistsQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type DdChecklistsQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    allGroups: Array<{
      __typename?: "DealGroup";
      id: string;
      name: string;
      ddChecklistItems: Array<{
        __typename?: "DueDiligenceChecklistItem";
        id: string;
        title: string;
        type: DueDiligenceChecklistItemType;
        completedAt?: number | null;
      }>;
    }>;
  };
};

export type DdqlQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  dealId: Scalars["String"]["input"];
}>;

export type DdqlQuery = {
  __typename?: "Query";
  ddql: {
    __typename?: "Ddql";
    id: string;
    name: string;
    index: string;
    createdAt: number;
    sourceFileName: string;
    sourceFileType: string;
    status?: DdqlStatus | null;
    totalChildQuestions: number;
    answeredChildQuestions: number;
    createdBy: { __typename?: "Account"; id: string; name: string };
    questions: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      index: string;
      createdAt: number;
      createdBy: { __typename?: "Account"; id: string; name: string };
      answer?: {
        __typename?: "QuestionAnswer";
        id: string;
        answer: string;
      } | null;
    }>;
    children: Array<{
      __typename?: "Ddql";
      id: string;
      name: string;
      index: string;
      createdAt: number;
      createdBy: { __typename?: "Account"; id: string; name: string };
      questions: Array<{
        __typename?: "Question";
        id: string;
        title: string;
        createdAt: number;
        answer?: { __typename?: "QuestionAnswer"; id: string } | null;
        activity: Array<{
          __typename?: "DealActivity";
          id: string;
          type: DealActivityType;
        }>;
        createdBy: { __typename?: "Account"; id: string; name: string };
      }>;
      children: Array<{
        __typename?: "Ddql";
        id: string;
        name: string;
        index: string;
        createdAt: number;
        createdBy: { __typename?: "Account"; id: string; name: string };
        questions: Array<{
          __typename?: "Question";
          id: string;
          title: string;
          index: string;
          createdAt: number;
          answer?: { __typename?: "QuestionAnswer"; id: string } | null;
          activity: Array<{
            __typename?: "DealActivity";
            id: string;
            type: DealActivityType;
          }>;
          createdBy: { __typename?: "Account"; id: string; name: string };
        }>;
        children: Array<{
          __typename?: "Ddql";
          id: string;
          name: string;
          index: string;
          createdAt: number;
          createdBy: { __typename?: "Account"; id: string; name: string };
        }>;
      }>;
    }>;
    dealGroup: { __typename?: "DealGroup"; id: string; name: string };
    forDealGroup?: {
      __typename?: "DealGroup";
      id: string;
      name: string;
    } | null;
  };
  deal: {
    __typename?: "Deal";
    id: string;
    guestGroups: Array<{
      __typename?: "DealGroup";
      id: string;
      name: string;
      accounts: Array<{
        __typename?: "DealAccount";
        id: string;
        account: {
          __typename?: "Account";
          id: string;
          email: string;
          name: string;
          avatar: string;
        };
      }>;
    }>;
  };
};

export type DdqlFieldsFragment = {
  __typename?: "Ddql";
  id: string;
  name: string;
  index: string;
  createdAt: number;
  sourceFileName: string;
  sourceFileType: string;
  status?: DdqlStatus | null;
  totalChildQuestions: number;
  answeredChildQuestions: number;
  createdBy: { __typename?: "Account"; id: string; name: string };
  questions: Array<{
    __typename?: "Question";
    id: string;
    title: string;
    index: string;
    createdAt: number;
    createdBy: { __typename?: "Account"; id: string; name: string };
    answer?: {
      __typename?: "QuestionAnswer";
      id: string;
      answer: string;
    } | null;
  }>;
  children: Array<{
    __typename?: "Ddql";
    id: string;
    name: string;
    index: string;
    createdAt: number;
    createdBy: { __typename?: "Account"; id: string; name: string };
    questions: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      createdAt: number;
      answer?: { __typename?: "QuestionAnswer"; id: string } | null;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        type: DealActivityType;
      }>;
      createdBy: { __typename?: "Account"; id: string; name: string };
    }>;
    children: Array<{
      __typename?: "Ddql";
      id: string;
      name: string;
      index: string;
      createdAt: number;
      createdBy: { __typename?: "Account"; id: string; name: string };
      questions: Array<{
        __typename?: "Question";
        id: string;
        title: string;
        index: string;
        createdAt: number;
        answer?: { __typename?: "QuestionAnswer"; id: string } | null;
        activity: Array<{
          __typename?: "DealActivity";
          id: string;
          type: DealActivityType;
        }>;
        createdBy: { __typename?: "Account"; id: string; name: string };
      }>;
      children: Array<{
        __typename?: "Ddql";
        id: string;
        name: string;
        index: string;
        createdAt: number;
        createdBy: { __typename?: "Account"; id: string; name: string };
      }>;
    }>;
  }>;
  dealGroup: { __typename?: "DealGroup"; id: string; name: string };
  forDealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
};

export type DdqlAccessControlQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DdqlAccessControlQuery = {
  __typename?: "Query";
  ddql: {
    __typename?: "Ddql";
    id: string;
    status?: DdqlStatus | null;
    accessControlDealGroups: Array<{
      __typename?: "AccessControlDealGroup";
      dealGroup: { __typename?: "DealGroup"; id: string; name: string };
      accessControl: {
        __typename?: "AccessControl";
        id: string;
        permissionLevel: AccessControlPermissionLevel;
      };
      accessControlDealAccounts: Array<{
        __typename?: "AccessControlDealAccount";
        dealAccount: {
          __typename?: "DealAccount";
          id: string;
          account: {
            __typename?: "Account";
            id: string;
            name: string;
            avatar: string;
          };
        };
        accessControl: {
          __typename?: "AccessControl";
          id: string;
          permissionLevel: AccessControlPermissionLevel;
        };
      }>;
    }>;
  };
};

export type AccessControlDealGroupFragment = {
  __typename?: "AccessControlDealGroup";
  dealGroup: { __typename?: "DealGroup"; id: string; name: string };
  accessControl: {
    __typename?: "AccessControl";
    id: string;
    permissionLevel: AccessControlPermissionLevel;
  };
  accessControlDealAccounts: Array<{
    __typename?: "AccessControlDealAccount";
    dealAccount: {
      __typename?: "DealAccount";
      id: string;
      account: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
      };
    };
    accessControl: {
      __typename?: "AccessControl";
      id: string;
      permissionLevel: AccessControlPermissionLevel;
    };
  }>;
};

export type DealQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DealQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    status: DealStatus;
    runner: DealRunner;
    company: {
      __typename?: "Company";
      id: string;
      name: string;
      logo: string;
      logoColor: string;
      website: string;
      description: string;
    };
    dealStages: Array<{
      __typename?: "DealStage";
      id: string;
      name: string;
      index: number;
    }>;
    activeDealAccount: {
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        type: DealGroupType;
      } | null;
    };
    dataRoom: {
      __typename?: "DataRoom";
      id: string;
      permissions: Array<{
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
        description: string;
        fileCount: number;
      }>;
    };
    dealPropertyValues: Array<{
      __typename?: "DealPropertyValue";
      id: string;
      propertyKey: string;
      propertyValue: string;
      createdAt: number;
      updatedAt: number;
      dealProperty: { __typename?: "DealProperty"; id: string };
    }>;
    invites: Array<{
      __typename?: "DealInvite";
      id: string;
      email: string;
      role: DealRole;
      status: DealInviteStatus;
      invitedBy: {
        __typename?: "Account";
        id: string;
        email: string;
        name: string;
        avatar: string;
        avatarColor: string;
      };
      dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
    }>;
    guestGroups: Array<{
      __typename?: "DealGroup";
      id: string;
      name: string;
      type: DealGroupType;
      currentDealStageStatus?: DealStageStatus | null;
      currentDealStage?: {
        __typename?: "DealStage";
        id: string;
        index: number;
      } | null;
      accounts: Array<{
        __typename?: "DealAccount";
        id: string;
        createdAt: number;
        dealAccessStatus: DealAccessStatus;
        overrideDataRoomAccess: boolean;
        lastSeen: number;
        role: DealRole;
        dataRoomPermission?: {
          __typename?: "DataRoomPermission";
          id: string;
          name: string;
          createdAt: number;
          accessLevel: number;
          fileCount: number;
          description: string;
        } | null;
        granularAccess: {
          __typename?: "GranularAccess";
          files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
          folders: Array<{
            __typename?: "Access";
            id: string;
            type: AccessType;
          }>;
        };
        account: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
        };
      }>;
    }>;
    buyers: Array<{
      __typename?: "DealGroup";
      id: string;
      name: string;
      type: DealGroupType;
      currentDealStageStatus?: DealStageStatus | null;
      currentDealStage?: {
        __typename?: "DealStage";
        id: string;
        index: number;
      } | null;
      accounts: Array<{
        __typename?: "DealAccount";
        role: DealRole;
        account: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
          avatarColor: string;
          avatar: string;
        };
      }>;
      dealFirms: Array<{
        __typename?: "DealFirm";
        id: string;
        name: string;
        createdAt: number;
        type: DealFirmType;
        dealAccounts: Array<{
          __typename?: "DealAccount";
          role: DealRole;
          account: {
            __typename?: "Account";
            id: string;
            name: string;
            email: string;
            avatarColor: string;
            avatar: string;
          };
        }>;
      }>;
    }>;
    sellers: Array<{
      __typename?: "DealGroup";
      id: string;
      name: string;
      type: DealGroupType;
      accounts: Array<{
        __typename?: "DealAccount";
        role: DealRole;
        account: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
          avatarColor: string;
          avatar: string;
        };
      }>;
      dealFirms: Array<{
        __typename?: "DealFirm";
        id: string;
        name: string;
        createdAt: number;
        type: DealFirmType;
        dealAccounts: Array<{
          __typename?: "DealAccount";
          role: DealRole;
          account: {
            __typename?: "Account";
            id: string;
            name: string;
            email: string;
            avatarColor: string;
            avatar: string;
          };
        }>;
      }>;
    }>;
  };
};

export type DealAccountQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DealAccountQuery = {
  __typename?: "Query";
  dealAccount: {
    __typename?: "DealAccount";
    id: string;
    lastSeen: number;
    createdAt: number;
    dealAccessStatus: DealAccessStatus;
    overrideDataRoomAccess: boolean;
    role: DealRole;
    account: {
      __typename?: "Account";
      id: string;
      name: string;
      email: string;
      avatar: string;
      avatarColor: string;
    };
    granularAccess: {
      __typename?: "GranularAccess";
      files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
      folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
    };
    dataRoomPermission?: {
      __typename?: "DataRoomPermission";
      id: string;
      createdAt: number;
      name: string;
      accessLevel: number;
      description: string;
      fileCount: number;
    } | null;
    allFolders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
      parentFolders: Array<{
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
      }>;
      files: Array<{
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        latestVersion: { __typename?: "DataRoomFileVersion"; id: string };
        permission: {
          __typename?: "DataRoomPermission";
          id: string;
          createdAt: number;
          name: string;
          accessLevel: number;
          description: string;
        };
      }>;
    }>;
    dealGroup?: {
      __typename?: "DealGroup";
      id: string;
      name: string;
      overrideDataRoomAccess: boolean;
      dealAccessStatus: DealAccessStatus;
      dataRoomPermission: {
        __typename?: "DataRoomPermission";
        id: string;
        createdAt: number;
        name: string;
        accessLevel: number;
        description: string;
        fileCount: number;
      };
      granularAccess: {
        __typename?: "GranularAccess";
        files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
        folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
      };
    } | null;
  };
};

export type DealAccountFragment = {
  __typename?: "DealAccount";
  id: string;
  createdAt: number;
  dealAccessStatus: DealAccessStatus;
  overrideDataRoomAccess: boolean;
  lastSeen: number;
  role: DealRole;
  dataRoomPermission?: {
    __typename?: "DataRoomPermission";
    id: string;
    name: string;
    createdAt: number;
    accessLevel: number;
    fileCount: number;
    description: string;
  } | null;
  granularAccess: {
    __typename?: "GranularAccess";
    files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
    folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
  };
  account: { __typename?: "Account"; id: string; name: string; email: string };
};

export type DealActivityQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
  input: DealActivityInput;
}>;

export type DealActivityQuery = {
  __typename?: "Query";
  dealActivity: {
    __typename?: "DealActivityResponse";
    cursor: string;
    activity: Array<{
      __typename?: "DealActivity";
      id: string;
      createdAt: number;
      type: DealActivityType;
      mergedFile?: boolean | null;
      account: { __typename?: "Account"; id: string; name: string };
      folder?: {
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      } | null;
      file?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        folderId: string;
      } | null;
      question?: { __typename?: "Question"; id: string; title: string } | null;
      dataRoomFileError?: {
        __typename?: "DataRoomFileError";
        id: string;
        key: string;
        dismissalReason: string;
        resolvedMessage: string;
        files: Array<{
          __typename?: "DataRoomFileErrorFile";
          file: {
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          };
        }>;
      } | null;
      comment?: {
        __typename?: "Comment";
        comment: string;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        }>;
      } | null;
      invite?: { __typename?: "DealInvite"; id: string; email: string } | null;
      accountImpersonation?: { __typename?: "Account"; name: string } | null;
      accessActivity?: {
        __typename?: "AccessActivity";
        account?: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
        } | null;
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
        fileDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        folderDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        permission?: {
          __typename?: "DataRoomPermission";
          id: string;
          name: string;
        } | null;
      } | null;
    }>;
  };
};

export type DealActivityFragmentFragment = {
  __typename?: "DealActivity";
  id: string;
  createdAt: number;
  type: DealActivityType;
  mergedFile?: boolean | null;
  account: { __typename?: "Account"; id: string; name: string };
  folder?: {
    __typename?: "DataRoomFolder";
    id: string;
    name: string;
    dealGroup?: { __typename?: "DealGroup"; id: string } | null;
  } | null;
  file?: {
    __typename?: "DataRoomFile";
    id: string;
    name: string;
    fileType: FileType;
    folderId: string;
  } | null;
  question?: { __typename?: "Question"; id: string; title: string } | null;
  dataRoomFileError?: {
    __typename?: "DataRoomFileError";
    id: string;
    key: string;
    dismissalReason: string;
    resolvedMessage: string;
    files: Array<{
      __typename?: "DataRoomFileErrorFile";
      file: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      };
    }>;
  } | null;
  comment?: {
    __typename?: "Comment";
    comment: string;
    files: Array<{
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
    }>;
  } | null;
  invite?: { __typename?: "DealInvite"; id: string; email: string } | null;
  accountImpersonation?: { __typename?: "Account"; name: string } | null;
  accessActivity?: {
    __typename?: "AccessActivity";
    account?: {
      __typename?: "Account";
      id: string;
      name: string;
      email: string;
    } | null;
    dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
    fileDifferences?: {
      __typename?: "GranularAccessChanges";
      updated: Array<{
        __typename?: "UpdatedGranularAccess";
        id: string;
        oldType: AccessType;
        newType: AccessType;
      }>;
    } | null;
    folderDifferences?: {
      __typename?: "GranularAccessChanges";
      updated: Array<{
        __typename?: "UpdatedGranularAccess";
        id: string;
        oldType: AccessType;
        newType: AccessType;
      }>;
    } | null;
    permission?: {
      __typename?: "DataRoomPermission";
      id: string;
      name: string;
    } | null;
  } | null;
};

export type DealDashboardDetailsQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type DealDashboardDetailsQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    status: DealStatus;
    createdAt: number;
    dataRoom: {
      __typename?: "DataRoom";
      id: string;
      allFiles: Array<{
        __typename?: "DataRoomFile";
        id: string;
        createdAt: number;
      }>;
    };
    dataRoomFileErrors: Array<{
      __typename?: "DataRoomFileError";
      id: string;
      createdAt: number;
      status: DataRoomFileErrorStatus;
    }>;
    questions: Array<{
      __typename?: "Question";
      id: string;
      createdAt: number;
      answer?: { __typename?: "QuestionAnswer"; id: string } | null;
    }>;
    activeDealAccount: {
      __typename?: "DealAccount";
      dealGroup?: { __typename?: "DealGroup"; id: string } | null;
    };
    dealPropertyValues: Array<{
      __typename?: "DealPropertyValue";
      id: string;
      propertyKey: string;
      propertyValue: string;
    }>;
    dealAccounts: Array<{
      __typename?: "DealAccount";
      account: {
        __typename?: "Account";
        id: string;
        name: string;
        email: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string } | null;
      };
    }>;
  };
};

export type DealExportsQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type DealExportsQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    dealExports: Array<{
      __typename?: "DealExport";
      id: string;
      status: DealExportStatus;
      createdAt: number;
      name: string;
      s3PresignedUrl?: string | null;
      createdBy: { __typename?: "Account"; id: string; email: string };
    }>;
  };
};

export type DealFilesQueryVariables = Exact<{
  dealID: Scalars["String"]["input"];
}>;

export type DealFilesQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    dataRoom: { __typename?: "DataRoom"; id: string };
  };
};

export type DealFirmQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  dealId: Scalars["String"]["input"];
}>;

export type DealFirmQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    activeDealAccount: {
      __typename?: "DealAccount";
      role: DealRole;
      account: { __typename?: "Account"; id: string };
    };
    dataRoom: {
      __typename?: "DataRoom";
      id: string;
      folder: { __typename?: "DataRoomFolder"; id: string };
    };
  };
  dealFirm: {
    __typename?: "DealFirm";
    id: string;
    name: string;
    createdAt: number;
    overrideDataRoomAccess: boolean;
    ndaDataRoomFile?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
    } | null;
    granularAccess: {
      __typename?: "GranularAccess";
      files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
      folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
    };
    dealAccounts: Array<{
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      createdAt: number;
      overrideDataRoomAccess: boolean;
      account: {
        __typename?: "Account";
        id: string;
        name: string;
        email: string;
        avatarColor: string;
        avatar: string;
      };
      granularAccess: {
        __typename?: "GranularAccess";
        files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
        folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
      };
    }>;
    invites: Array<{
      __typename?: "DealInvite";
      id: string;
      email: string;
      role: DealRole;
      status: DealInviteStatus;
    }>;
  };
};

export type DealGroupQuestionsQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DealGroupQuestionsQuery = {
  __typename?: "Query";
  dealGroup: {
    __typename?: "DealGroup";
    id: string;
    name: string;
    questions: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      body: string;
      createdAt: number;
      answeredAt?: number | null;
      dataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      } | null;
      answer?: { __typename?: "QuestionAnswer"; id: string } | null;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        type: DealActivityType;
      }>;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
        type: DealGroupType;
      } | null;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      };
      forDealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
      } | null;
    }>;
    questionsForGroup: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      body: string;
      createdAt: number;
      answeredAt?: number | null;
      dataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      } | null;
      answer?: { __typename?: "QuestionAnswer"; id: string } | null;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        type: DealActivityType;
      }>;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
        type: DealGroupType;
      } | null;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      };
      forDealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
      } | null;
    }>;
    ddqlsForGroup: Array<{
      __typename?: "Ddql";
      id: string;
      name: string;
      createdAt: number;
      status?: DdqlStatus | null;
      sourceFileName: string;
      answeredChildQuestions: number;
      totalChildQuestions: number;
      sourceFileType: string;
      questions: Array<{
        __typename?: "Question";
        id: string;
        title: string;
        body: string;
        createdAt: number;
        answeredAt?: number | null;
        dataRoomFile?: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        } | null;
        answer?: { __typename?: "QuestionAnswer"; id: string } | null;
        activity: Array<{
          __typename?: "DealActivity";
          id: string;
          type: DealActivityType;
        }>;
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
          type: DealGroupType;
        } | null;
        createdBy: {
          __typename?: "Account";
          id: string;
          name: string;
          avatar: string;
          avatarColor: string;
          firm?: { __typename?: "Firm"; id: string; name: string } | null;
        };
        forDealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
      }>;
    }>;
    ddqls: Array<{
      __typename?: "Ddql";
      id: string;
      name: string;
      createdAt: number;
      status?: DdqlStatus | null;
      sourceFileName: string;
      answeredChildQuestions: number;
      totalChildQuestions: number;
      sourceFileType: string;
      questions: Array<{
        __typename?: "Question";
        id: string;
        title: string;
        body: string;
        createdAt: number;
        answeredAt?: number | null;
        dataRoomFile?: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        } | null;
        answer?: { __typename?: "QuestionAnswer"; id: string } | null;
        activity: Array<{
          __typename?: "DealActivity";
          id: string;
          type: DealActivityType;
        }>;
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
          type: DealGroupType;
        } | null;
        createdBy: {
          __typename?: "Account";
          id: string;
          name: string;
          avatar: string;
          avatarColor: string;
          firm?: { __typename?: "Firm"; id: string; name: string } | null;
        };
        forDealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
      }>;
    }>;
    ddqlImports: Array<{
      __typename?: "DdqlImport";
      id: string;
      fileName: string;
      fileType: string;
      status: DdqlImportStatus;
    }>;
  };
};

export type DealGroupQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DealGroupQuery = {
  __typename?: "Query";
  dealGroup: {
    __typename?: "DealGroup";
    id: string;
    name: string;
    type: DealGroupType;
    createdAt: number;
    overrideDataRoomAccess: boolean;
    dealAccessStatus: DealAccessStatus;
    currentDealStageStatus?: DealStageStatus | null;
    ndaSignedAt?: number | null;
    files: Array<{
      __typename?: "DataRoomFile";
      id: string;
      createdAt: number;
      name: string;
      fileType: FileType;
    }>;
    questions: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      body: string;
      createdAt: number;
      answeredAt?: number | null;
      dataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      } | null;
      answer?: { __typename?: "QuestionAnswer"; id: string } | null;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        type: DealActivityType;
      }>;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
        type: DealGroupType;
      } | null;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      };
      forDealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
      } | null;
    }>;
    ddChecklistItems: Array<{
      __typename?: "DueDiligenceChecklistItem";
      id: string;
      title: string;
      createdAt: number;
      completedAt?: number | null;
      type: DueDiligenceChecklistItemType;
      dueDate?: number | null;
      dataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      } | null;
      assignedTo?: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      } | null;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      };
    }>;
    currentDealStage?: {
      __typename?: "DealStage";
      id: string;
      index: number;
    } | null;
    deal: {
      __typename?: "Deal";
      id: string;
      dealStages: Array<{
        __typename?: "DealStage";
        id: string;
        name: string;
        index: number;
      }>;
    };
    invites: Array<{
      __typename?: "DealInvite";
      id: string;
      email: string;
      role: DealRole;
      status: DealInviteStatus;
    }>;
    ndaDataRoomFile?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
    } | null;
    ndaSignedBy?: { __typename?: "Account"; id: string; name: string } | null;
    granularAccess: {
      __typename?: "GranularAccess";
      files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
      folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
    };
    dataRoomPermission: {
      __typename?: "DataRoomPermission";
      id: string;
      name: string;
      description: string;
      accessLevel: number;
      createdAt: number;
      fileCount: number;
    };
    accounts: Array<{
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      createdAt: number;
      overrideDataRoomAccess: boolean;
      account: {
        __typename?: "Account";
        id: string;
        name: string;
        email: string;
        avatarColor: string;
        avatar: string;
      };
      granularAccess: {
        __typename?: "GranularAccess";
        files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
        folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
      };
    }>;
    dealFirms: Array<{
      __typename?: "DealFirm";
      id: string;
      name: string;
      type: DealFirmType;
      createdAt: number;
      overrideDataRoomAccess: boolean;
      invites: Array<{
        __typename?: "DealInvite";
        id: string;
        email: string;
        role: DealRole;
        status: DealInviteStatus;
      }>;
      granularAccess: {
        __typename?: "GranularAccess";
        files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
        folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
      };
      dealAccounts: Array<{
        __typename?: "DealAccount";
        id: string;
        role: DealRole;
        createdAt: number;
        overrideDataRoomAccess: boolean;
        account: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
          avatarColor: string;
          avatar: string;
        };
        granularAccess: {
          __typename?: "GranularAccess";
          files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
          folders: Array<{
            __typename?: "Access";
            id: string;
            type: AccessType;
          }>;
        };
      }>;
    }>;
  };
};

export type DealInviteQueryVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type DealInviteQuery = {
  __typename?: "Query";
  dealInvite: {
    __typename?: "DealInvite";
    id: string;
    email: string;
    role: DealRole;
    deal: {
      __typename?: "Deal";
      id: string;
      company: { __typename?: "Company"; id: string; name: string };
    };
    dealGroup?: {
      __typename?: "DealGroup";
      id: string;
      name: string;
      dealAccessStatus: DealAccessStatus;
      ndaDataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        uploadStatus: DataRoomFileUploadStatus;
        latestVersion: { __typename?: "DataRoomFileVersion"; id: string };
      } | null;
    } | null;
    invitedBy: {
      __typename?: "Account";
      id: string;
      name: string;
      email: string;
      firm?: { __typename?: "Firm"; id: string; name: string } | null;
    };
  };
};

export type DealPropertyQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DealPropertyQuery = {
  __typename?: "Query";
  dealProperty: {
    __typename?: "DealProperty";
    id: string;
    propertyKey: string;
    createdAt: number;
    createdBy: {
      __typename?: "Account";
      id: string;
      name: string;
      avatar: string;
    };
    dealPropertyValues: Array<{
      __typename?: "DealPropertyValue";
      id: string;
      propertyValue: string;
      deal: {
        __typename?: "Deal";
        id: string;
        company: {
          __typename?: "Company";
          id: string;
          name: string;
          logo: string;
          logoColor: string;
        };
      };
    }>;
  };
};

export type DealThreadQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DealThreadQuery = {
  __typename?: "Query";
  dealThread: {
    __typename?: "DealThread";
    id: string;
    createdBy: { __typename?: "Account"; id: string };
    messages: Array<{
      __typename?: "DealThreadMessage";
      id: string;
      role: DealThreadMessageRole;
      status: DealThreadMessageStatus;
      createdAt: number;
      content: {
        __typename?: "DealThreadMessageContent";
        text: { __typename?: "DealThreadMessageText"; value: string };
        quotes: Array<{
          __typename?: "DealThreadMessageContentQuote";
          quote: string;
          status: DealThreadMessageContentQuoteStatus;
          rectsOnPage?: Array<Array<number>> | null;
          pageIndex?: number | null;
          file: {
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          };
        }>;
        files: Array<{
          __typename?: "DealThreadMessageContentFile";
          pageIndex?: number | null;
          rectsOnPage: Array<Array<number>>;
          file: {
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          };
        }>;
      };
    }>;
  };
};

export type DealThreadMessageQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DealThreadMessageQuery = {
  __typename?: "Query";
  dealThreadMessage: {
    __typename?: "DealThreadMessage";
    id: string;
    role: DealThreadMessageRole;
    status: DealThreadMessageStatus;
    createdAt: number;
    content: {
      __typename?: "DealThreadMessageContent";
      text: { __typename?: "DealThreadMessageText"; value: string };
      quotes: Array<{
        __typename?: "DealThreadMessageContentQuote";
        quote: string;
        status: DealThreadMessageContentQuoteStatus;
        rectsOnPage?: Array<Array<number>> | null;
        pageIndex?: number | null;
        file: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        };
      }>;
      files: Array<{
        __typename?: "DealThreadMessageContentFile";
        pageIndex?: number | null;
        rectsOnPage: Array<Array<number>>;
        file: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        };
      }>;
    };
  };
};

export type DealThreadsQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type DealThreadsQuery = {
  __typename?: "Query";
  dealThreads: Array<{
    __typename?: "DealThread";
    id: string;
    summary: string;
    createdAt: number;
  }>;
};

export type DeepSearchQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeepSearchQuery = {
  __typename?: "Query";
  deepSearch: {
    __typename?: "DeepSearch";
    id: string;
    name: string;
    createdAt: number;
    status: DeepSearchStatus;
    vectorScope: DeepSearchVectorScope;
    createdBy: { __typename?: "Account"; id: string; email: string };
    threadMessages: Array<{
      __typename?: "DeepSearchThreadMessage";
      id: string;
      role: DealThreadMessageRole;
      status: DealThreadMessageStatus;
      files: Array<{
        __typename?: "DeepSearchThreadMessageContentFile";
        id: string;
        status: DeepSearchFileStatus;
        file: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        };
        fileVersion: {
          __typename?: "DataRoomFileVersion";
          id: string;
          versionNumber: number;
        };
      }>;
      content: {
        __typename?: "DeepSearchThreadMessageContent";
        type: string;
        text: string;
      };
    }>;
    files: Array<{
      __typename?: "DeepSearchFile";
      id: string;
      status: DeepSearchFileStatus;
      hidden: boolean;
      sortIndex: number;
      file: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        createdAt: number;
        uploadStatus: DataRoomFileUploadStatus;
        currentLiveVersion: { __typename?: "DataRoomFileVersion"; id: string };
      };
      currentVersion: {
        __typename?: "DataRoomFileVersion";
        id: string;
        versionNumber: number;
      };
      references: Array<{
        __typename?: "DeepSearchFileReference";
        id: string;
        quote: string;
        pageIndex: number;
        rectsOnPage: Array<Array<number>>;
        annotationID: string;
      }>;
    }>;
  };
};

export type DeepSearchFileQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeepSearchFileQuery = {
  __typename?: "Query";
  deepSearchFile: {
    __typename?: "DeepSearchFile";
    id: string;
    status: DeepSearchFileStatus;
    file: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
      createdAt: number;
      uploadStatus: DataRoomFileUploadStatus;
      versions: Array<{
        __typename?: "DataRoomFileVersion";
        id: string;
        createdAt: number;
        summary: string;
      }>;
    };
    references: Array<{
      __typename?: "DeepSearchFileReference";
      quote: string;
      pageIndex: number;
      rectsOnPage: Array<Array<number>>;
    }>;
  };
};

export type DeepSearchThreadMessagesQueryVariables = Exact<{
  deepSearchId: Scalars["String"]["input"];
}>;

export type DeepSearchThreadMessagesQuery = {
  __typename?: "Query";
  deepSearch: {
    __typename?: "DeepSearch";
    id: string;
    threadMessages: Array<{
      __typename?: "DeepSearchThreadMessage";
      id: string;
      createdAt: number;
      role: DealThreadMessageRole;
      status: DealThreadMessageStatus;
      files: Array<{
        __typename?: "DeepSearchThreadMessageContentFile";
        id: string;
        status: DeepSearchFileStatus;
        file: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        };
        fileVersion: {
          __typename?: "DataRoomFileVersion";
          id: string;
          versionNumber: number;
        };
      }>;
      content: {
        __typename?: "DeepSearchThreadMessageContent";
        type: string;
        text: string;
      };
    }>;
  };
};

export type DeepSearchThreadMessageQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeepSearchThreadMessageQuery = {
  __typename?: "Query";
  deepSearchThreadMessage: {
    __typename?: "DeepSearchThreadMessage";
    id: string;
    role: DealThreadMessageRole;
    status: DealThreadMessageStatus;
    files: Array<{
      __typename?: "DeepSearchThreadMessageContentFile";
      id: string;
      status: DeepSearchFileStatus;
      file: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      };
      fileVersion: {
        __typename?: "DataRoomFileVersion";
        id: string;
        versionNumber: number;
      };
    }>;
    content: {
      __typename?: "DeepSearchThreadMessageContent";
      type: string;
      text: string;
    };
  };
};

export type DeepSearchThreadMessageFileQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeepSearchThreadMessageFileQuery = {
  __typename?: "Query";
  deepSearchThreadMessageFile: {
    __typename?: "DeepSearchThreadMessageContentFile";
    id: string;
    status: DeepSearchFileStatus;
    file: {
      __typename?: "DataRoomFile";
      id: string;
      fileType: FileType;
      name: string;
      currentLiveVersion: {
        __typename?: "DataRoomFileVersion";
        id: string;
        versionNumber: number;
      };
    };
    fileVersion: { __typename?: "DataRoomFileVersion"; id: string };
    references: Array<{
      __typename?: "DeepSearchThreadMessageContentFileReference";
      id: string;
      quote: string;
      pageIndex: number;
      rects: Array<Array<number>>;
    }>;
  };
};

export type DeepSearchesQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type DeepSearchesQuery = {
  __typename?: "Query";
  deepSearches: Array<{
    __typename?: "DeepSearch";
    id: string;
    name: string;
    createdAt: number;
    files: Array<{
      __typename?: "DeepSearchFile";
      file: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      };
    }>;
    createdBy: { __typename?: "Account"; id: string; email: string };
  }>;
};

export type DeleteAccessControlMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteAccessControlMutation = {
  __typename?: "Mutation";
  deleteAccessControl: { __typename?: "DeleteResponse"; success: boolean };
};

export type DeleteDataRoomFileMutationVariables = Exact<{
  input: DeleteDataRoomFileInput;
}>;

export type DeleteDataRoomFileMutation = {
  __typename?: "Mutation";
  deleteDataRoomFile: { __typename?: "DeleteResponse"; success: boolean };
};

export type DeleteDataRoomFileVersionMutationVariables = Exact<{
  input: DeleteDataRoomFileVersionInput;
}>;

export type DeleteDataRoomFileVersionMutation = {
  __typename?: "Mutation";
  deleteDataRoomFileVersion: {
    __typename?: "DeleteResponse";
    success: boolean;
  };
};

export type DeleteDataRoomPermissionMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteDataRoomPermissionMutation = {
  __typename?: "Mutation";
  deleteDataRoomPermission: { __typename?: "DataRoomPermission"; id: string };
};

export type DeleteDealInviteMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteDealInviteMutation = {
  __typename?: "Mutation";
  deleteDealInvite: { __typename?: "DeleteResponse"; success: boolean };
};

export type DeleteDeepSearchMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteDeepSearchMutation = {
  __typename?: "Mutation";
  deleteDeepSearch: { __typename?: "DeleteResponse"; success: boolean };
};

export type DeleteDeepSearchFileReferenceMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteDeepSearchFileReferenceMutation = {
  __typename?: "Mutation";
  deleteDeepSearchFileReference: { __typename?: "DeepSearchFile"; id: string };
};

export type DeleteFirmInviteMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteFirmInviteMutation = {
  __typename?: "Mutation";
  deleteFirmInvite: { __typename?: "DeleteResponse"; success: boolean };
};

export type DeleteIgnoreErrorRuleMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteIgnoreErrorRuleMutation = {
  __typename?: "Mutation";
  deleteIgnoreErrorRule: { __typename?: "DeleteResponse"; success: boolean };
};

export type DeleteQuestionMutationVariables = Exact<{
  input: DeleteQuestionInput;
}>;

export type DeleteQuestionMutation = {
  __typename?: "Mutation";
  deleteQuestion: { __typename?: "DeleteResponse"; success: boolean };
};

export type FileDownloadUrlQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type FileDownloadUrlQuery = {
  __typename?: "Query";
  fileDownloadUrl: {
    __typename?: "PresignedUrlResponse";
    url: string;
    downloadUrl: string;
    viewUrl: string;
  };
};

export type ErrorCheckQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type ErrorCheckQuery = {
  __typename?: "Query";
  errorCheck: {
    __typename?: "ErrorCheck";
    id: string;
    name: string;
    createdAt: number;
    createdBy: { __typename?: "Account"; id: string; email: string };
    files: Array<{
      __typename?: "ErrorCheckFile";
      file: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        createdAt: number;
        errorCheckingStatus: ErrorCheckingStatus;
        versions: Array<{
          __typename?: "DataRoomFileVersion";
          id: string;
          createdAt: number;
          summary: string;
        }>;
      };
      currentVersion: { __typename?: "DataRoomFileVersion"; id: string };
    }>;
    rows: Array<{
      __typename?: "ErrorCheckRow";
      key: string;
      hasDiscrepancy: boolean;
      values: Array<{
        __typename?: "ErrorCheckValue";
        dataRoomFileId: string;
        value: number;
        key: string;
      }>;
    }>;
  };
};

export type ErrorChecksQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type ErrorChecksQuery = {
  __typename?: "Query";
  errorChecks: Array<{
    __typename?: "ErrorCheck";
    id: string;
    name: string;
    createdAt: number;
    files: Array<{
      __typename?: "ErrorCheckFile";
      file: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      };
    }>;
    createdBy: { __typename?: "Account"; id: string; email: string };
  }>;
};

export type FileVersionDownloadUrlQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type FileVersionDownloadUrlQuery = {
  __typename?: "Query";
  fileVersionDownloadUrl: {
    __typename?: "PresignedUrlResponse";
    url: string;
    downloadUrl: string;
    viewUrl: string;
  };
};

export type FileViewerQueryVariables = Exact<{
  dataRoomFileVersionId: Scalars["String"]["input"];
  dataRoomFileId: Scalars["String"]["input"];
}>;

export type FileViewerQuery = {
  __typename?: "Query";
  dataRoomFile: {
    __typename?: "DataRoomFile";
    id: string;
    uploadStatus: DataRoomFileUploadStatus;
  };
  dataRoomFileVersion: {
    __typename?: "DataRoomFileVersion";
    id: string;
    pspdfkitDocumentId: string;
    pspdfkitToken: string;
  };
  fileVersionDownloadUrl: {
    __typename?: "PresignedUrlResponse";
    url: string;
    downloadUrl: string;
    viewUrl: string;
  };
};

export type FinancialsQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type FinancialsQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    financialStatements: {
      __typename?: "FinancialStatements";
      pnl?: {
        __typename?: "Pnl";
        id: string;
        latestVersion: {
          __typename?: "PnlVersion";
          id: string;
          createdAt: number;
          changeSummary: string;
          changeSummaryStatus: ChangeSummaryStatus;
          columns: Array<{ __typename?: "Column"; id: string; name: string }>;
          revenues: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          cogs: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          operatingExpenses: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          depreciations: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          amortizations: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          interests: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          taxes: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
        };
        versions: Array<{
          __typename?: "PnlVersion";
          id: string;
          createdAt: number;
          changeSummary: string;
          changeSummaryStatus: ChangeSummaryStatus;
          columns: Array<{ __typename?: "Column"; id: string; name: string }>;
          revenues: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          cogs: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          operatingExpenses: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          depreciations: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          amortizations: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          interests: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
          taxes: Array<{
            __typename?: "Row";
            id: string;
            name: string;
            type: RowType;
            values: Array<{
              __typename?: "RowValue";
              column: string;
              value: number;
            }>;
          }>;
        }>;
        questions: Array<{
          __typename?: "Question";
          id: string;
          title: string;
          body: string;
          nodeId: string;
        }>;
      } | null;
    };
  };
};

export type PnlVersionFragmentFragment = {
  __typename?: "PnlVersion";
  id: string;
  createdAt: number;
  changeSummary: string;
  changeSummaryStatus: ChangeSummaryStatus;
  columns: Array<{ __typename?: "Column"; id: string; name: string }>;
  revenues: Array<{
    __typename?: "Row";
    id: string;
    name: string;
    type: RowType;
    values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
  }>;
  cogs: Array<{
    __typename?: "Row";
    id: string;
    name: string;
    type: RowType;
    values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
  }>;
  operatingExpenses: Array<{
    __typename?: "Row";
    id: string;
    name: string;
    type: RowType;
    values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
  }>;
  depreciations: Array<{
    __typename?: "Row";
    id: string;
    name: string;
    type: RowType;
    values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
  }>;
  amortizations: Array<{
    __typename?: "Row";
    id: string;
    name: string;
    type: RowType;
    values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
  }>;
  interests: Array<{
    __typename?: "Row";
    id: string;
    name: string;
    type: RowType;
    values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
  }>;
  taxes: Array<{
    __typename?: "Row";
    id: string;
    name: string;
    type: RowType;
    values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
  }>;
};

export type RowFragmentFragment = {
  __typename?: "Row";
  id: string;
  name: string;
  type: RowType;
  values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
};

export type FirmQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type FirmQuery = {
  __typename?: "Query";
  firm: {
    __typename?: "Firm";
    id: string;
    name: string;
    description: string;
    website: string;
    logo: string;
    address: string;
    type: FirmType;
    accounts: Array<{
      __typename?: "Account";
      id: string;
      name: string;
      email: string;
      avatar: string;
      avatarColor: string;
      firmAccountType?: FirmAccountType | null;
    }>;
    invites: Array<{
      __typename?: "FirmInvite";
      id: string;
      email: string;
      role: FirmAccountType;
    }>;
  };
};

export type FirmDealPropertiesQueryVariables = Exact<{
  firmId: Scalars["String"]["input"];
}>;

export type FirmDealPropertiesQuery = {
  __typename?: "Query";
  firm: {
    __typename?: "Firm";
    dealProperties: Array<{
      __typename?: "DealProperty";
      id: string;
      propertyKey: string;
      createdAt: number;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
      };
    }>;
  };
};

export type FirmFilesQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type FirmFilesQuery = {
  __typename?: "Query";
  firm: {
    __typename?: "Firm";
    id: string;
    name: string;
    dataRoom: {
      __typename?: "DataRoom";
      id: string;
      createdAt: number;
      updatedAt: number;
      folder: {
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
        createdAt: number;
        hasFolderWriteAccess: boolean;
        context: DataRoomContext;
        createdBy: { __typename?: "Account"; email: string; name: string };
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          updatedAt: number;
          fileType: FileType;
          errorCheckingStatus: ErrorCheckingStatus;
          onlineStatus: DataRoomFileOnlineStatus;
          createdBy: { __typename?: "Account"; id: string; name: string };
          permission: {
            __typename?: "DataRoomPermission";
            id: string;
            name: string;
          };
        }>;
        parentFolders: Array<{
          __typename?: "DataRoomFolder";
          id: string;
          name: string;
        }>;
        folders: Array<{
          __typename?: "DataRoomFolder";
          id: string;
          name: string;
          createdAt: number;
          dealGroup?: {
            __typename?: "DealGroup";
            id: string;
            name: string;
          } | null;
          permission?: {
            __typename?: "DataRoomPermission";
            id: string;
            name: string;
          } | null;
          createdBy: { __typename?: "Account"; email: string; name: string };
        }>;
      };
      permissions: Array<{
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
        description: string;
        accessLevel: number;
        createdAt: number;
        fileCount: number;
      }>;
    };
    allFolders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
    }>;
  };
};

export type FirmInviteQueryVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type FirmInviteQuery = {
  __typename?: "Query";
  firmInvite: {
    __typename?: "FirmInvite";
    id: string;
    email: string;
    role: FirmAccountType;
    firm: { __typename?: "Firm"; id: string; name: string };
    invitedBy: {
      __typename?: "Account";
      id: string;
      name: string;
      email: string;
    };
  };
};

export type GroupQuestionsQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type GroupQuestionsQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    activeDealAccount: {
      __typename?: "DealAccount";
      dealGroup?: { __typename?: "DealGroup"; id: string } | null;
    };
    allGroups: Array<{
      __typename?: "DealGroup";
      id: string;
      name: string;
      questions: Array<{
        __typename?: "Question";
        id: string;
        title: string;
        body: string;
        createdAt: number;
        answeredAt?: number | null;
        dataRoomFile?: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        } | null;
        answer?: { __typename?: "QuestionAnswer"; id: string } | null;
        activity: Array<{
          __typename?: "DealActivity";
          id: string;
          type: DealActivityType;
        }>;
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
          type: DealGroupType;
        } | null;
        createdBy: {
          __typename?: "Account";
          id: string;
          name: string;
          avatar: string;
          avatarColor: string;
          firm?: { __typename?: "Firm"; id: string; name: string } | null;
        };
        forDealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
      }>;
    }>;
  };
};

export type GuestAccessTabQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type GuestAccessTabQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    activeDealAccount: {
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
        accounts: Array<{
          __typename?: "DealAccount";
          id: string;
          createdAt: number;
          dealAccessStatus: DealAccessStatus;
          overrideDataRoomAccess: boolean;
          lastSeen: number;
          role: DealRole;
          dataRoomPermission?: {
            __typename?: "DataRoomPermission";
            id: string;
            name: string;
            createdAt: number;
            accessLevel: number;
            fileCount: number;
            description: string;
          } | null;
          granularAccess: {
            __typename?: "GranularAccess";
            files: Array<{
              __typename?: "Access";
              id: string;
              type: AccessType;
            }>;
            folders: Array<{
              __typename?: "Access";
              id: string;
              type: AccessType;
            }>;
          };
          account: {
            __typename?: "Account";
            id: string;
            name: string;
            email: string;
          };
        }>;
        dataRoomPermission: { __typename?: "DataRoomPermission"; id: string };
        invites: Array<{
          __typename?: "DealInvite";
          id: string;
          email: string;
          createdAt: number;
          role: DealRole;
          status: DealInviteStatus;
          overrideDataRoomAccess: boolean;
          dealGroup?: {
            __typename?: "DealGroup";
            id: string;
            name: string;
          } | null;
          dataRoomPermission?: {
            __typename?: "DataRoomPermission";
            id: string;
            name: string;
            description: string;
            accessLevel: number;
            fileCount: number;
          } | null;
          granularAccess?: {
            __typename?: "GranularAccess";
            files: Array<{
              __typename?: "Access";
              id: string;
              type: AccessType;
            }>;
            folders: Array<{
              __typename?: "Access";
              id: string;
              type: AccessType;
            }>;
          } | null;
        }>;
      } | null;
    };
  };
};

export type IgnoreErrorRulesQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type IgnoreErrorRulesQuery = {
  __typename?: "Query";
  ignoreErrorRules: Array<{
    __typename?: "IgnoreErrorRule";
    id: string;
    key: string;
    createdAt: number;
    keyFiles: Array<{
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
    }>;
    dataRoomFile?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
    } | null;
  }>;
};

export type IgnoreFileForErrorMutationVariables = Exact<{
  dataRoomFileID: Scalars["String"]["input"];
  dataRoomFileErrorID: Scalars["String"]["input"];
}>;

export type IgnoreFileForErrorMutation = {
  __typename?: "Mutation";
  ignoreFileForError: { __typename?: "DataRoomFileError"; id: string };
};

export type InternalAccessQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
}>;

export type InternalAccessQuery = {
  __typename?: "Query";
  deal: {
    __typename?: "Deal";
    id: string;
    runner: DealRunner;
    company: {
      __typename?: "Company";
      id: string;
      name: string;
      logo: string;
      logoColor: string;
    };
    dataRoom: {
      __typename?: "DataRoom";
      id: string;
      permissions: Array<{
        __typename?: "DataRoomPermission";
        id: string;
        createdAt: number;
        name: string;
        description: string;
        accessLevel: number;
        fileCount: number;
      }>;
    };
    ownerGroups: Array<{
      __typename?: "DealGroup";
      id: string;
      name: string;
      type: DealGroupType;
      dealAccessStatus: DealAccessStatus;
      overrideDataRoomAccess: boolean;
      granularAccess: {
        __typename?: "GranularAccess";
        files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
        folders: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
      };
      dataRoomPermission: {
        __typename?: "DataRoomPermission";
        id: string;
        name: string;
        description: string;
        accessLevel: number;
        fileCount: number;
      };
      accounts: Array<{
        __typename?: "DealAccount";
        id: string;
        createdAt: number;
        dealAccessStatus: DealAccessStatus;
        overrideDataRoomAccess: boolean;
        lastSeen: number;
        role: DealRole;
        dataRoomPermission?: {
          __typename?: "DataRoomPermission";
          id: string;
          name: string;
          createdAt: number;
          accessLevel: number;
          fileCount: number;
          description: string;
        } | null;
        granularAccess: {
          __typename?: "GranularAccess";
          files: Array<{ __typename?: "Access"; id: string; type: AccessType }>;
          folders: Array<{
            __typename?: "Access";
            id: string;
            type: AccessType;
          }>;
        };
        account: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
        };
      }>;
    }>;
    activeDealAccount: {
      __typename?: "DealAccount";
      id: string;
      role: DealRole;
      dealGroup?: { __typename?: "DealGroup"; id: string } | null;
    };
  };
};

export type LoginMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login: {
    __typename?: "AuthenticateResponse";
    tokens: {
      __typename?: "AuthTokens";
      accessToken: string;
      refreshToken: string;
    };
  };
};

export type MarkNotificationAsReadMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type MarkNotificationAsReadMutation = {
  __typename?: "Mutation";
  markNotificationAsRead: {
    __typename?: "Notification";
    id: string;
    readAt?: number | null;
  };
};

export type MergeDocumentsQueryVariables = Exact<{
  dataRoomFileID1: Scalars["String"]["input"];
  dataRoomFileID2: Scalars["String"]["input"];
}>;

export type MergeDocumentsQuery = {
  __typename?: "Query";
  dataRoomFile1: {
    __typename?: "DataRoomFile";
    id: string;
    name: string;
    description: string;
    url: string;
    createdAt: number;
    deletedAt?: number | null;
    deletionType?: DeletionType | null;
    context: DataRoomContext;
    onlineStatus: DataRoomFileOnlineStatus;
    hasAccess: boolean;
    hasDownloadAccess: boolean;
    folderId: string;
    updatedAt: number;
    fileType: FileType;
    uploadStatus: DataRoomFileUploadStatus;
    documentSummaryStatus: DocumentSummaryStatus;
    currentLiveVersion: {
      __typename?: "DataRoomFileVersion";
      id: string;
      pspdfkitDocumentId: string;
      pspdfkitToken: string;
      versionNumber: number;
    };
    createdBy: { __typename?: "Account"; id: string; name: string };
    permission: {
      __typename?: "DataRoomPermission";
      id: string;
      name: string;
      description: string;
    };
    parentFolders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
    }>;
    versions: Array<{
      __typename?: "DataRoomFileVersion";
      id: string;
      summary: string;
      hash: string;
      shortHash: string;
      versionNumber: number;
      fileName: string;
      fileType: FileType;
      createdAt: number;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        createdAt: number;
        type: DealActivityType;
        mergedFile?: boolean | null;
        account: { __typename?: "Account"; id: string; name: string };
        folder?: {
          __typename?: "DataRoomFolder";
          id: string;
          name: string;
          dealGroup?: { __typename?: "DealGroup"; id: string } | null;
        } | null;
        file?: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
          folderId: string;
        } | null;
        question?: {
          __typename?: "Question";
          id: string;
          title: string;
        } | null;
        dataRoomFileError?: {
          __typename?: "DataRoomFileError";
          id: string;
          key: string;
          dismissalReason: string;
          resolvedMessage: string;
          files: Array<{
            __typename?: "DataRoomFileErrorFile";
            file: {
              __typename?: "DataRoomFile";
              id: string;
              name: string;
              fileType: FileType;
            };
          }>;
        } | null;
        comment?: {
          __typename?: "Comment";
          comment: string;
          files: Array<{
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          }>;
        } | null;
        invite?: {
          __typename?: "DealInvite";
          id: string;
          email: string;
        } | null;
        accountImpersonation?: { __typename?: "Account"; name: string } | null;
        accessActivity?: {
          __typename?: "AccessActivity";
          account?: {
            __typename?: "Account";
            id: string;
            name: string;
            email: string;
          } | null;
          dealGroup?: {
            __typename?: "DealGroup";
            id: string;
            name: string;
          } | null;
          fileDifferences?: {
            __typename?: "GranularAccessChanges";
            updated: Array<{
              __typename?: "UpdatedGranularAccess";
              id: string;
              oldType: AccessType;
              newType: AccessType;
            }>;
          } | null;
          folderDifferences?: {
            __typename?: "GranularAccessChanges";
            updated: Array<{
              __typename?: "UpdatedGranularAccess";
              id: string;
              oldType: AccessType;
              newType: AccessType;
            }>;
          } | null;
          permission?: {
            __typename?: "DataRoomPermission";
            id: string;
            name: string;
          } | null;
        } | null;
      }>;
      changes: {
        __typename?: "FileVersionChanges";
        summary: string;
        textChanges: Array<{
          __typename?: "FileVersionTextChange";
          section: string;
          previous: string;
          new: string;
          diffs: Array<{
            __typename?: "TextDiff";
            type: TextDiffType;
            text: string;
          }>;
        }>;
      };
      createdBy: {
        __typename?: "Account";
        id: string;
        email: string;
        name: string;
        avatar: string;
      };
    }>;
    latestVersion: {
      __typename?: "DataRoomFileVersion";
      id: string;
      summary: string;
      createdAt: number;
      createdBy: {
        __typename?: "Account";
        id: string;
        email: string;
        name: string;
        avatar: string;
        avatarColor: string;
      };
    };
    questions: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      body: string;
      createdAt: number;
      answeredAt?: number | null;
      dataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      } | null;
      answer?: { __typename?: "QuestionAnswer"; id: string } | null;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        type: DealActivityType;
      }>;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
        type: DealGroupType;
      } | null;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      };
      forDealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
      } | null;
    }>;
    stats: {
      __typename?: "DataRoomFileStats";
      views: number;
      downloads: number;
    };
    activity: Array<{
      __typename?: "DealActivity";
      id: string;
      createdAt: number;
      type: DealActivityType;
      mergedFile?: boolean | null;
      account: { __typename?: "Account"; id: string; name: string };
      folder?: {
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      } | null;
      file?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        folderId: string;
      } | null;
      question?: { __typename?: "Question"; id: string; title: string } | null;
      dataRoomFileError?: {
        __typename?: "DataRoomFileError";
        id: string;
        key: string;
        dismissalReason: string;
        resolvedMessage: string;
        files: Array<{
          __typename?: "DataRoomFileErrorFile";
          file: {
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          };
        }>;
      } | null;
      comment?: {
        __typename?: "Comment";
        comment: string;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        }>;
      } | null;
      invite?: { __typename?: "DealInvite"; id: string; email: string } | null;
      accountImpersonation?: { __typename?: "Account"; name: string } | null;
      accessActivity?: {
        __typename?: "AccessActivity";
        account?: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
        } | null;
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
        fileDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        folderDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        permission?: {
          __typename?: "DataRoomPermission";
          id: string;
          name: string;
        } | null;
      } | null;
    }>;
    documentSummary: {
      __typename?: "DocumentSummary";
      summary: string;
      keyPoints: Array<{
        __typename?: "KeyPoint";
        title: string;
        description: string;
      }>;
    };
  };
  dataRoomFile2: {
    __typename?: "DataRoomFile";
    id: string;
    name: string;
    description: string;
    url: string;
    createdAt: number;
    deletedAt?: number | null;
    deletionType?: DeletionType | null;
    context: DataRoomContext;
    onlineStatus: DataRoomFileOnlineStatus;
    hasAccess: boolean;
    hasDownloadAccess: boolean;
    folderId: string;
    updatedAt: number;
    fileType: FileType;
    uploadStatus: DataRoomFileUploadStatus;
    documentSummaryStatus: DocumentSummaryStatus;
    currentLiveVersion: {
      __typename?: "DataRoomFileVersion";
      id: string;
      pspdfkitDocumentId: string;
      pspdfkitToken: string;
      versionNumber: number;
    };
    createdBy: { __typename?: "Account"; id: string; name: string };
    permission: {
      __typename?: "DataRoomPermission";
      id: string;
      name: string;
      description: string;
    };
    parentFolders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
    }>;
    versions: Array<{
      __typename?: "DataRoomFileVersion";
      id: string;
      summary: string;
      hash: string;
      shortHash: string;
      versionNumber: number;
      fileName: string;
      fileType: FileType;
      createdAt: number;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        createdAt: number;
        type: DealActivityType;
        mergedFile?: boolean | null;
        account: { __typename?: "Account"; id: string; name: string };
        folder?: {
          __typename?: "DataRoomFolder";
          id: string;
          name: string;
          dealGroup?: { __typename?: "DealGroup"; id: string } | null;
        } | null;
        file?: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
          folderId: string;
        } | null;
        question?: {
          __typename?: "Question";
          id: string;
          title: string;
        } | null;
        dataRoomFileError?: {
          __typename?: "DataRoomFileError";
          id: string;
          key: string;
          dismissalReason: string;
          resolvedMessage: string;
          files: Array<{
            __typename?: "DataRoomFileErrorFile";
            file: {
              __typename?: "DataRoomFile";
              id: string;
              name: string;
              fileType: FileType;
            };
          }>;
        } | null;
        comment?: {
          __typename?: "Comment";
          comment: string;
          files: Array<{
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          }>;
        } | null;
        invite?: {
          __typename?: "DealInvite";
          id: string;
          email: string;
        } | null;
        accountImpersonation?: { __typename?: "Account"; name: string } | null;
        accessActivity?: {
          __typename?: "AccessActivity";
          account?: {
            __typename?: "Account";
            id: string;
            name: string;
            email: string;
          } | null;
          dealGroup?: {
            __typename?: "DealGroup";
            id: string;
            name: string;
          } | null;
          fileDifferences?: {
            __typename?: "GranularAccessChanges";
            updated: Array<{
              __typename?: "UpdatedGranularAccess";
              id: string;
              oldType: AccessType;
              newType: AccessType;
            }>;
          } | null;
          folderDifferences?: {
            __typename?: "GranularAccessChanges";
            updated: Array<{
              __typename?: "UpdatedGranularAccess";
              id: string;
              oldType: AccessType;
              newType: AccessType;
            }>;
          } | null;
          permission?: {
            __typename?: "DataRoomPermission";
            id: string;
            name: string;
          } | null;
        } | null;
      }>;
      changes: {
        __typename?: "FileVersionChanges";
        summary: string;
        textChanges: Array<{
          __typename?: "FileVersionTextChange";
          section: string;
          previous: string;
          new: string;
          diffs: Array<{
            __typename?: "TextDiff";
            type: TextDiffType;
            text: string;
          }>;
        }>;
      };
      createdBy: {
        __typename?: "Account";
        id: string;
        email: string;
        name: string;
        avatar: string;
      };
    }>;
    latestVersion: {
      __typename?: "DataRoomFileVersion";
      id: string;
      summary: string;
      createdAt: number;
      createdBy: {
        __typename?: "Account";
        id: string;
        email: string;
        name: string;
        avatar: string;
        avatarColor: string;
      };
    };
    questions: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      body: string;
      createdAt: number;
      answeredAt?: number | null;
      dataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      } | null;
      answer?: { __typename?: "QuestionAnswer"; id: string } | null;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        type: DealActivityType;
      }>;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
        type: DealGroupType;
      } | null;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      };
      forDealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
      } | null;
    }>;
    stats: {
      __typename?: "DataRoomFileStats";
      views: number;
      downloads: number;
    };
    activity: Array<{
      __typename?: "DealActivity";
      id: string;
      createdAt: number;
      type: DealActivityType;
      mergedFile?: boolean | null;
      account: { __typename?: "Account"; id: string; name: string };
      folder?: {
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      } | null;
      file?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        folderId: string;
      } | null;
      question?: { __typename?: "Question"; id: string; title: string } | null;
      dataRoomFileError?: {
        __typename?: "DataRoomFileError";
        id: string;
        key: string;
        dismissalReason: string;
        resolvedMessage: string;
        files: Array<{
          __typename?: "DataRoomFileErrorFile";
          file: {
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          };
        }>;
      } | null;
      comment?: {
        __typename?: "Comment";
        comment: string;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        }>;
      } | null;
      invite?: { __typename?: "DealInvite"; id: string; email: string } | null;
      accountImpersonation?: { __typename?: "Account"; name: string } | null;
      accessActivity?: {
        __typename?: "AccessActivity";
        account?: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
        } | null;
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
        fileDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        folderDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        permission?: {
          __typename?: "DataRoomPermission";
          id: string;
          name: string;
        } | null;
      } | null;
    }>;
    documentSummary: {
      __typename?: "DocumentSummary";
      summary: string;
      keyPoints: Array<{
        __typename?: "KeyPoint";
        title: string;
        description: string;
      }>;
    };
  };
};

export type MoveDataRoomFileMutationVariables = Exact<{
  input: MoveDataRoomFileInput;
}>;

export type MoveDataRoomFileMutation = {
  __typename?: "Mutation";
  moveDataRoomFile: { __typename?: "DataRoomFile"; id: string; name: string };
};

export type MoveDataRoomFolderMutationVariables = Exact<{
  input: MoveDataRoomFolderInput;
}>;

export type MoveDataRoomFolderMutation = {
  __typename?: "Mutation";
  moveDataRoomFolder: {
    __typename?: "DataRoomFolder";
    id: string;
    name: string;
  };
};

export type NotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationsQuery = {
  __typename?: "Query";
  notifications: Array<{
    __typename?: "Notification";
    id: string;
    title: string;
    type: NotificationType;
    message: string;
    createdAt: number;
    readAt?: number | null;
    deal?: {
      __typename?: "Deal";
      id: string;
      company: {
        __typename?: "Company";
        name: string;
        logoColor: string;
        logo: string;
      };
    } | null;
    similarDataRoomFile?: {
      __typename?: "SimilarDataRoomFile";
      id: string;
      dataRoomFile1: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      };
      dataRoomFile2: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      };
    } | null;
    initiator?: {
      __typename?: "Account";
      id: string;
      firstName: string;
      avatar: string;
    } | null;
    initiatorDealAccount?: { __typename?: "DealAccount"; id: string } | null;
    task?: {
      __typename?: "Task";
      id: string;
      title: string;
      deal?: {
        __typename?: "Deal";
        id: string;
        company: { __typename?: "Company"; name: string };
      } | null;
    } | null;
    dataRoomFile?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      folderId: string;
    } | null;
  }>;
};

export type PnlVersionQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type PnlVersionQuery = {
  __typename?: "Query";
  pnlVersion: {
    __typename?: "PnlVersion";
    id: string;
    createdAt: number;
    changeSummary: string;
    changeSummaryStatus: ChangeSummaryStatus;
    columns: Array<{ __typename?: "Column"; id: string; name: string }>;
    revenues: Array<{
      __typename?: "Row";
      id: string;
      name: string;
      type: RowType;
      values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
    }>;
    cogs: Array<{
      __typename?: "Row";
      id: string;
      name: string;
      type: RowType;
      values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
    }>;
    operatingExpenses: Array<{
      __typename?: "Row";
      id: string;
      name: string;
      type: RowType;
      values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
    }>;
    depreciations: Array<{
      __typename?: "Row";
      id: string;
      name: string;
      type: RowType;
      values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
    }>;
    amortizations: Array<{
      __typename?: "Row";
      id: string;
      name: string;
      type: RowType;
      values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
    }>;
    interests: Array<{
      __typename?: "Row";
      id: string;
      name: string;
      type: RowType;
      values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
    }>;
    taxes: Array<{
      __typename?: "Row";
      id: string;
      name: string;
      type: RowType;
      values: Array<{ __typename?: "RowValue"; column: string; value: number }>;
    }>;
  };
};

export type QuestionQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type QuestionQuery = {
  __typename?: "Query";
  question: {
    __typename?: "Question";
    id: string;
    title: string;
    body: string;
    createdAt: number;
    answeredAt?: number | null;
    ddql?: { __typename?: "Ddql"; id: string; name: string } | null;
    dealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
    forDealGroup?: {
      __typename?: "DealGroup";
      id: string;
      name: string;
    } | null;
    similarQuestions: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      body: string;
      createdAt: number;
      answeredAt?: number | null;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
      };
      answer?: {
        __typename?: "QuestionAnswer";
        id: string;
        answer: string;
        answeredBy: {
          __typename?: "Account";
          id: string;
          name: string;
          avatar: string;
          avatarColor: string;
        };
        answerFiles: Array<{
          __typename?: "DealThreadMessageContentFile";
          pageIndex?: number | null;
          rectsOnPage: Array<Array<number>>;
          file: {
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          };
        }>;
      } | null;
    }>;
    activity: Array<{
      __typename?: "DealActivity";
      id: string;
      createdAt: number;
      type: DealActivityType;
      mergedFile?: boolean | null;
      account: { __typename?: "Account"; id: string; name: string };
      folder?: {
        __typename?: "DataRoomFolder";
        id: string;
        name: string;
        dealGroup?: { __typename?: "DealGroup"; id: string } | null;
      } | null;
      file?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
        folderId: string;
      } | null;
      question?: { __typename?: "Question"; id: string; title: string } | null;
      dataRoomFileError?: {
        __typename?: "DataRoomFileError";
        id: string;
        key: string;
        dismissalReason: string;
        resolvedMessage: string;
        files: Array<{
          __typename?: "DataRoomFileErrorFile";
          file: {
            __typename?: "DataRoomFile";
            id: string;
            name: string;
            fileType: FileType;
          };
        }>;
      } | null;
      comment?: {
        __typename?: "Comment";
        comment: string;
        files: Array<{
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        }>;
      } | null;
      invite?: { __typename?: "DealInvite"; id: string; email: string } | null;
      accountImpersonation?: { __typename?: "Account"; name: string } | null;
      accessActivity?: {
        __typename?: "AccessActivity";
        account?: {
          __typename?: "Account";
          id: string;
          name: string;
          email: string;
        } | null;
        dealGroup?: {
          __typename?: "DealGroup";
          id: string;
          name: string;
        } | null;
        fileDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        folderDifferences?: {
          __typename?: "GranularAccessChanges";
          updated: Array<{
            __typename?: "UpdatedGranularAccess";
            id: string;
            oldType: AccessType;
            newType: AccessType;
          }>;
        } | null;
        permission?: {
          __typename?: "DataRoomPermission";
          id: string;
          name: string;
        } | null;
      } | null;
    }>;
    dataRoomFile?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
    } | null;
    suggestedAnswer?: {
      __typename?: "SuggestedAnswer";
      id: string;
      status: SuggestedAnswerStatus;
      summary: string;
      relevantFiles: Array<{
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      }>;
      references: Array<{
        __typename?: "AnswerReference";
        pageIndex: number;
        rectsOnPage: Array<Array<number>>;
        quote: string;
        file: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
          currentLiveVersion: {
            __typename?: "DataRoomFileVersion";
            id: string;
          };
        };
      }>;
    } | null;
    answer?: {
      __typename?: "QuestionAnswer";
      id: string;
      answer: string;
      createdAt: number;
      answeredBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
      };
      answerFiles: Array<{
        __typename?: "DealThreadMessageContentFile";
        pageIndex?: number | null;
        rectsOnPage: Array<Array<number>>;
        file: {
          __typename?: "DataRoomFile";
          id: string;
          name: string;
          fileType: FileType;
        };
      }>;
      dataRoomFiles: Array<{
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      }>;
    } | null;
    createdBy: {
      __typename?: "Account";
      id: string;
      name: string;
      avatar: string;
      avatarColor: string;
    };
  };
};

export type QuestionAccessControlQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type QuestionAccessControlQuery = {
  __typename?: "Query";
  question: {
    __typename?: "Question";
    id: string;
    accessControlDealGroups: Array<{
      __typename?: "AccessControlDealGroup";
      dealGroup: { __typename?: "DealGroup"; id: string; name: string };
      accessControl: {
        __typename?: "AccessControl";
        id: string;
        permissionLevel: AccessControlPermissionLevel;
      };
      accessControlDealAccounts: Array<{
        __typename?: "AccessControlDealAccount";
        dealAccount: {
          __typename?: "DealAccount";
          id: string;
          account: {
            __typename?: "Account";
            id: string;
            name: string;
            avatar: string;
          };
        };
        accessControl: {
          __typename?: "AccessControl";
          id: string;
          permissionLevel: AccessControlPermissionLevel;
        };
      }>;
    }>;
  };
};

export type QuestionsQueryVariables = Exact<{
  dealId: Scalars["String"]["input"];
  input: QuestionsInput;
}>;

export type QuestionsQuery = {
  __typename?: "Query";
  questions: {
    __typename?: "QuestionsRespose";
    questions: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      body: string;
      createdAt: number;
      answeredAt?: number | null;
      dataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      } | null;
      answer?: { __typename?: "QuestionAnswer"; id: string } | null;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        type: DealActivityType;
      }>;
      dealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
        type: DealGroupType;
      } | null;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      };
      forDealGroup?: {
        __typename?: "DealGroup";
        id: string;
        name: string;
      } | null;
    }>;
  };
  ddqls: Array<{
    __typename?: "Ddql";
    id: string;
    name: string;
    index: string;
    createdAt: number;
    status?: DdqlStatus | null;
    totalChildQuestions: number;
    answeredChildQuestions: number;
    sourceFileName: string;
    sourceFileType: string;
    dealGroup: {
      __typename?: "DealGroup";
      id: string;
      name: string;
      type: DealGroupType;
    };
    createdBy: { __typename?: "Account"; id: string; name: string };
    questions: Array<{
      __typename?: "Question";
      id: string;
      title: string;
      body: string;
      index: string;
      createdAt: number;
      answeredAt?: number | null;
      dataRoomFile?: {
        __typename?: "DataRoomFile";
        id: string;
        name: string;
        fileType: FileType;
      } | null;
      answer?: { __typename?: "QuestionAnswer"; id: string } | null;
      activity: Array<{
        __typename?: "DealActivity";
        id: string;
        type: DealActivityType;
      }>;
      createdBy: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
        firm?: { __typename?: "Firm"; id: string; name: string } | null;
      };
    }>;
  }>;
  deal: {
    __typename?: "Deal";
    activeDealAccount: {
      __typename?: "DealAccount";
      dealGroup?: { __typename?: "DealGroup"; id: string } | null;
    };
  };
};

export type DdqlFragment = {
  __typename?: "Ddql";
  id: string;
  name: string;
  index: string;
  createdAt: number;
  status?: DdqlStatus | null;
  totalChildQuestions: number;
  answeredChildQuestions: number;
  sourceFileName: string;
  sourceFileType: string;
  dealGroup: {
    __typename?: "DealGroup";
    id: string;
    name: string;
    type: DealGroupType;
  };
  createdBy: { __typename?: "Account"; id: string; name: string };
  questions: Array<{
    __typename?: "Question";
    id: string;
    title: string;
    body: string;
    index: string;
    createdAt: number;
    answeredAt?: number | null;
    dataRoomFile?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
    } | null;
    answer?: { __typename?: "QuestionAnswer"; id: string } | null;
    activity: Array<{
      __typename?: "DealActivity";
      id: string;
      type: DealActivityType;
    }>;
    createdBy: {
      __typename?: "Account";
      id: string;
      name: string;
      avatar: string;
      avatarColor: string;
      firm?: { __typename?: "Firm"; id: string; name: string } | null;
    };
  }>;
};

export type QuestionFragment = {
  __typename?: "Question";
  id: string;
  title: string;
  body: string;
  createdAt: number;
  answeredAt?: number | null;
  dataRoomFile?: {
    __typename?: "DataRoomFile";
    id: string;
    name: string;
    fileType: FileType;
  } | null;
  answer?: { __typename?: "QuestionAnswer"; id: string } | null;
  activity: Array<{
    __typename?: "DealActivity";
    id: string;
    type: DealActivityType;
  }>;
  dealGroup?: {
    __typename?: "DealGroup";
    id: string;
    name: string;
    type: DealGroupType;
  } | null;
  createdBy: {
    __typename?: "Account";
    id: string;
    name: string;
    avatar: string;
    avatarColor: string;
    firm?: { __typename?: "Firm"; id: string; name: string } | null;
  };
  forDealGroup?: { __typename?: "DealGroup"; id: string; name: string } | null;
};

export type RedlineExportsQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type RedlineExportsQuery = {
  __typename?: "Query";
  redlineExport: {
    __typename?: "RedlineExport";
    id: string;
    status: RedlineExportStatus;
    s3PresignedDocXRedlineUrl?: string | null;
    s3PresignedDocXTrackedChangesUrl?: string | null;
    s3PresignedPdfRedlineUrl?: string | null;
  };
};

export type RemoveDealAccountMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type RemoveDealAccountMutation = {
  __typename?: "Mutation";
  removeDealAccount: { __typename?: "DeleteResponse"; success: boolean };
};

export type RemoveDealFirmMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type RemoveDealFirmMutation = {
  __typename?: "Mutation";
  removeDealFirm: { __typename?: "DeleteResponse"; success: boolean };
};

export type RemoveDealGroupMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type RemoveDealGroupMutation = {
  __typename?: "Mutation";
  removeDealGroup: { __typename?: "DeleteResponse"; success: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword: { __typename?: "Account"; id: string; email: string };
};

export type ResolveDataRoomFileErrorMutationVariables = Exact<{
  input: ResolveDataRoomFileErrorInput;
}>;

export type ResolveDataRoomFileErrorMutation = {
  __typename?: "Mutation";
  resolveDataRoomFileError: { __typename?: "DataRoomFileError"; id: string };
};

export type ResolveDataRoomFileSimilarityMutationVariables = Exact<{
  acceptMerge: Scalars["Boolean"]["input"];
  dataRoomFileID1: Scalars["String"]["input"];
  dataRoomFileID2: Scalars["String"]["input"];
  reverseMerge: Scalars["Boolean"]["input"];
}>;

export type ResolveDataRoomFileSimilarityMutation = {
  __typename?: "Mutation";
  resolveDataRoomFileSimilarity: {
    __typename?: "ResolveDataRoomFileSimilarityResponse";
    success: boolean;
  };
};

export type S3DdqlUploadCompleteMutationVariables = Exact<{
  ddqlID: Scalars["String"]["input"];
  multiPartUploadID: Scalars["String"]["input"];
  parts: Array<MultipartPart> | MultipartPart;
}>;

export type S3DdqlUploadCompleteMutation = {
  __typename?: "Mutation";
  s3DdqlUploadComplete: { __typename?: "Ddql"; id: string };
};

export type S3FileUploadCompleteMutationVariables = Exact<{
  dataRoomFileID: Scalars["String"]["input"];
  dataRoomFileVersionID: Scalars["String"]["input"];
}>;

export type S3FileUploadCompleteMutation = {
  __typename?: "Mutation";
  s3FileUploadComplete: { __typename?: "DataRoomFile"; id: string };
};

export type S3MultipartUploadCompleteMutationVariables = Exact<{
  dataRoomFileID: Scalars["String"]["input"];
  dataRoomFileVersionID: Scalars["String"]["input"];
  multiPartUploadID: Scalars["String"]["input"];
  parts: Array<MultipartPart> | MultipartPart;
}>;

export type S3MultipartUploadCompleteMutation = {
  __typename?: "Mutation";
  s3MultipartUploadComplete: { __typename?: "DataRoomFile"; id: string };
};

export type SearchDeepSearchFilesQueryVariables = Exact<{
  input: SearchDeepSearchFilesInput;
}>;

export type SearchDeepSearchFilesQuery = {
  __typename?: "Query";
  searchDeepSearchFiles: {
    __typename?: "SearchDeepSearchFilesResponse";
    matches: Array<{
      __typename?: "SearchDeepSearchFileMatch";
      deepSearchFile: {
        __typename?: "DeepSearchFile";
        id: string;
        file: {
          __typename?: "DataRoomFile";
          id: string;
          fileType: FileType;
          name: string;
        };
      };
      results: Array<{
        __typename?: "DeepSearchFileReference";
        quote: string;
        rectsOnPage: Array<Array<number>>;
        pageIndex: number;
      }>;
    }>;
  };
};

export type SearchFilesQueryVariables = Exact<{
  query: Scalars["String"]["input"];
  dataRoomID: Scalars["String"]["input"];
}>;

export type SearchFilesQuery = {
  __typename?: "Query";
  searchFiles: Array<{
    __typename?: "DataRoomFile";
    id: string;
    name: string;
    fileType: FileType;
    parentFolders: Array<{
      __typename?: "DataRoomFolder";
      id: string;
      name: string;
    }>;
  }>;
};

export type SendNotificationMutationVariables = Exact<{
  input: SendNotificationInput;
}>;

export type SendNotificationMutation = {
  __typename?: "Mutation";
  sendNotification: {
    __typename?: "SendNotificationResponse";
    success: boolean;
    message: string;
  };
};

export type SendResetPasswordMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type SendResetPasswordMutation = {
  __typename?: "Mutation";
  sendResetPassword: string;
};

export type SimilarDataRoomFilesQueryVariables = Exact<{
  dataRoomFileID?: InputMaybe<Scalars["String"]["input"]>;
  dataRoomID?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SimilarDataRoomFilesQuery = {
  __typename?: "Query";
  similarDataRoomFiles: Array<{
    __typename?: "SimilarDataRoomFile";
    id: string;
    createdAt: number;
    similarity: number;
    status: DataRoomFileSimilarityStatus;
    dataRoomFile1: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      folderId: string;
      createdAt: number;
      fileType: FileType;
      createdBy: { __typename?: "Account"; id: string; name: string };
    };
    dataRoomFile2: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      folderId: string;
      createdAt: number;
      fileType: FileType;
      createdBy: { __typename?: "Account"; id: string; name: string };
    };
  }>;
};

export type StartDeepSearchMutationVariables = Exact<{
  input: StartDeepSearchInput;
}>;

export type StartDeepSearchMutation = {
  __typename?: "Mutation";
  startDeepSearch: { __typename?: "DeepSearch"; id: string };
};

export type TaskQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type TaskQuery = {
  __typename?: "Query";
  task: {
    __typename?: "Task";
    id: string;
    title: string;
    description: string;
    createdAt: number;
    status: TaskStatus;
    priority?: TaskPriority | null;
    dueDate?: number | null;
    createdBy: {
      __typename?: "Account";
      id: string;
      name: string;
      firstName: string;
      avatar: string;
    };
    activity: Array<{
      __typename?: "TaskActivity";
      id: string;
      type: TaskActivityType;
      createdAt: number;
      status?: TaskStatus | null;
      priority?: TaskPriority | null;
      task: {
        __typename?: "Task";
        id: string;
        title: string;
        priority?: TaskPriority | null;
        deal?: {
          __typename?: "Deal";
          id: string;
          company: {
            __typename?: "Company";
            id: string;
            name: string;
            logo: string;
            logoColor: string;
          };
        } | null;
        assignedTo: Array<{
          __typename?: "Account";
          id: string;
          name: string;
          avatar: string;
          avatarColor: string;
        }>;
      };
      account: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        email: string;
      };
      comment?: { __typename?: "Comment"; comment: string } | null;
    }>;
    assignedTo: Array<{
      __typename?: "Account";
      id: string;
      name: string;
      email: string;
      avatar: string;
      avatarColor: string;
    }>;
    deal?: {
      __typename?: "Deal";
      id: string;
      company: {
        __typename?: "Company";
        id: string;
        name: string;
        logo: string;
        logoColor: string;
      };
    } | null;
  };
};

export type TaskActivityQueryVariables = Exact<{
  filters: TaskActivityFilters;
}>;

export type TaskActivityQuery = {
  __typename?: "Query";
  taskActivity: {
    __typename?: "TaskActivitiesResponse";
    nextCursor: string;
    total: number;
    activities: Array<{
      __typename?: "TaskActivity";
      id: string;
      type: TaskActivityType;
      createdAt: number;
      dueDate?: number | null;
      status?: TaskStatus | null;
      priority?: TaskPriority | null;
      deal?: {
        __typename?: "Deal";
        id: string;
        company: {
          __typename?: "Company";
          id: string;
          name: string;
          logo: string;
          logoColor: string;
        };
      } | null;
      assignedTo: Array<{
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        avatarColor: string;
      }>;
      task: {
        __typename?: "Task";
        id: string;
        title: string;
        priority?: TaskPriority | null;
        assignedTo: Array<{
          __typename?: "Account";
          id: string;
          name: string;
          avatar: string;
          avatarColor: string;
        }>;
        deal?: {
          __typename?: "Deal";
          id: string;
          company: {
            __typename?: "Company";
            id: string;
            name: string;
            logo: string;
            logoColor: string;
          };
        } | null;
      };
      account: {
        __typename?: "Account";
        id: string;
        name: string;
        avatar: string;
        email: string;
      };
      comment?: { __typename?: "Comment"; comment: string } | null;
    }>;
  };
};

export type TasksQueryVariables = Exact<{
  firmID: Scalars["String"]["input"];
  filters?: InputMaybe<TasksFilters>;
}>;

export type TasksQuery = {
  __typename?: "Query";
  firm: {
    __typename?: "Firm";
    id: string;
    accounts: Array<{
      __typename?: "Account";
      id: string;
      name: string;
      email: string;
      avatar: string;
    }>;
    deals: Array<{
      __typename?: "Deal";
      id: string;
      status: DealStatus;
      company: {
        __typename?: "Company";
        id: string;
        name: string;
        logo: string;
        logoColor: string;
      };
    }>;
  };
  tasks: Array<{
    __typename?: "Task";
    id: string;
    title: string;
    createdAt: number;
    status: TaskStatus;
    priority?: TaskPriority | null;
    sortOrder: number;
    description: string;
    dueDate?: number | null;
    activity: Array<{
      __typename?: "TaskActivity";
      id: string;
      type: TaskActivityType;
    }>;
    createdBy: {
      __typename?: "Account";
      id: string;
      name: string;
      firstName: string;
      avatar: string;
    };
    assignedTo: Array<{
      __typename?: "Account";
      id: string;
      name: string;
      email: string;
      avatar: string;
    }>;
    deal?: {
      __typename?: "Deal";
      id: string;
      status: DealStatus;
      company: {
        __typename?: "Company";
        id: string;
        name: string;
        logo: string;
        logoColor: string;
      };
    } | null;
  }>;
};

export type TrackActivityMutationVariables = Exact<{
  type: DealActivityType;
  fileId?: InputMaybe<Scalars["String"]["input"]>;
  dataRoomId?: InputMaybe<Scalars["String"]["input"]>;
  folderId?: InputMaybe<Scalars["String"]["input"]>;
  targetAccountID?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TrackActivityMutation = {
  __typename?: "Mutation";
  trackActivity: { __typename?: "DealActivity"; id: string };
};

export type UnreadNotificationCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UnreadNotificationCountQuery = {
  __typename?: "Query";
  account: { __typename?: "Account"; unreadNotificationCount: number };
};

export type UpdateAccessControlMutationVariables = Exact<{
  input: UpdateAccessControlInput;
}>;

export type UpdateAccessControlMutation = {
  __typename?: "Mutation";
  updateAccessControl: {
    __typename?: "AccessControl";
    id: string;
    permissionLevel: AccessControlPermissionLevel;
  };
};

export type UpdateAccountMutationVariables = Exact<{
  input: UpdateAccountInput;
}>;

export type UpdateAccountMutation = {
  __typename?: "Mutation";
  updateAccount: { __typename?: "Account"; id: string; name: string };
};

export type UpdateDataRoomMutationVariables = Exact<{
  input: UpdateDataRoomInput;
}>;

export type UpdateDataRoomMutation = {
  __typename?: "Mutation";
  updateDataRoom: {
    __typename?: "DataRoom";
    id: string;
    permissions: Array<{
      __typename?: "DataRoomPermission";
      id: string;
      name: string;
      description: string;
      accessLevel: number;
    }>;
  };
};

export type UpdateDataRoomFileMutationVariables = Exact<{
  input: UpdateDataRoomFileInput;
}>;

export type UpdateDataRoomFileMutation = {
  __typename?: "Mutation";
  updateDataRoomFile: { __typename?: "DataRoomFile"; id: string; name: string };
};

export type UpdateDataRoomFolderMutationVariables = Exact<{
  input: UpdateDataRoomFolderInput;
}>;

export type UpdateDataRoomFolderMutation = {
  __typename?: "Mutation";
  updateDataRoomFolder: {
    __typename?: "DataRoomFolder";
    id: string;
    name: string;
  };
};

export type UpdateDdqlMutationVariables = Exact<{
  input: UpdateDdqlInput;
}>;

export type UpdateDdqlMutation = {
  __typename?: "Mutation";
  updateDdql: { __typename?: "Ddql"; id: string };
};

export type UpdateDealMutationVariables = Exact<{
  input: UpdateDealInput;
}>;

export type UpdateDealMutation = {
  __typename?: "Mutation";
  updateDeal?: { __typename?: "Deal"; id: string; status: DealStatus } | null;
};

export type UpdateDealAccountMutationVariables = Exact<{
  input: UpdateDealAccountInput;
}>;

export type UpdateDealAccountMutation = {
  __typename?: "Mutation";
  updateDealAccount: {
    __typename?: "DealAccount";
    role: DealRole;
    account: { __typename?: "Account"; id: string };
  };
};

export type UpdateDealFirmMutationVariables = Exact<{
  input: UpdateDealFirmInput;
}>;

export type UpdateDealFirmMutation = {
  __typename?: "Mutation";
  updateDealFirm: { __typename?: "DealFirm"; id: string; name: string };
};

export type UpdateDealGroupMutationVariables = Exact<{
  input: UpdateDealGroupInput;
}>;

export type UpdateDealGroupMutation = {
  __typename?: "Mutation";
  updateDealGroup: { __typename?: "DealGroup"; id: string; name: string };
};

export type UpdateDealPropertyMutationVariables = Exact<{
  input: UpdateDealPropertyInput;
}>;

export type UpdateDealPropertyMutation = {
  __typename?: "Mutation";
  updateDealProperty: {
    __typename?: "DealProperty";
    id: string;
    propertyKey: string;
    updatedAt: number;
    createdAt: number;
  };
};

export type UpdateDealPropertyValueMutationVariables = Exact<{
  input: UpdateDealPropertyValueInput;
}>;

export type UpdateDealPropertyValueMutation = {
  __typename?: "Mutation";
  updateDealPropertyValue: { __typename?: "DealPropertyValue"; id: string };
};

export type UpdateDealPropertyValuesMutationVariables = Exact<{
  input: Array<UpdateDealPropertyValueInput> | UpdateDealPropertyValueInput;
}>;

export type UpdateDealPropertyValuesMutation = {
  __typename?: "Mutation";
  updateDealPropertyValues: {
    __typename?: "UpdateDealPropertyValuesResponse";
    success: boolean;
  };
};

export type UpdateDeepSearchMutationVariables = Exact<{
  input: UpdateDeepSearchInput;
}>;

export type UpdateDeepSearchMutation = {
  __typename?: "Mutation";
  updateDeepSearch: { __typename?: "DeepSearch"; id: string };
};

export type UpdateDeepSearchFileMutationVariables = Exact<{
  input: UpdateDeepSearchFileInput;
}>;

export type UpdateDeepSearchFileMutation = {
  __typename?: "Mutation";
  updateDeepSearchFile: { __typename?: "DeepSearchFile"; id: string };
};

export type UpdateDeepSearchFileReferenceMutationVariables = Exact<{
  input: UpdateDeepSearchFileReferenceInput;
}>;

export type UpdateDeepSearchFileReferenceMutation = {
  __typename?: "Mutation";
  updateDeepSearchFileReference: { __typename?: "DeepSearchFile"; id: string };
};

export type UpdateDeepSearchFilesMutationVariables = Exact<{
  input: UpdateDeepSearchFilesInput;
}>;

export type UpdateDeepSearchFilesMutation = {
  __typename?: "Mutation";
  updateDeepSearchFiles: Array<{ __typename?: "DeepSearchFile"; id: string }>;
};

export type UpdateDeepSearchThreadMessageMutationVariables = Exact<{
  input: UpdateDeepSearchThreadMessageInput;
}>;

export type UpdateDeepSearchThreadMessageMutation = {
  __typename?: "Mutation";
  updateDeepSearchThreadMessage: {
    __typename?: "DeepSearchThreadMessage";
    id: string;
  };
};

export type UpdateDueDiligenceChecklistItemMutationVariables = Exact<{
  input: UpdateDueDiligenceChecklistItemInput;
}>;

export type UpdateDueDiligenceChecklistItemMutation = {
  __typename?: "Mutation";
  updateDueDiligenceChecklistItem: {
    __typename?: "DueDiligenceChecklistItem";
    id: string;
    title: string;
    createdAt: number;
    completedAt?: number | null;
    type: DueDiligenceChecklistItemType;
    dueDate?: number | null;
    dataRoomFile?: {
      __typename?: "DataRoomFile";
      id: string;
      name: string;
      fileType: FileType;
    } | null;
    assignedTo?: {
      __typename?: "Account";
      id: string;
      name: string;
      avatar: string;
      avatarColor: string;
      firm?: { __typename?: "Firm"; id: string; name: string } | null;
    } | null;
    createdBy: {
      __typename?: "Account";
      id: string;
      name: string;
      avatar: string;
      avatarColor: string;
      firm?: { __typename?: "Firm"; id: string; name: string } | null;
    };
  };
};

export type UpdateErrorCheckMutationVariables = Exact<{
  input: UpdateErrorCheckInput;
}>;

export type UpdateErrorCheckMutation = {
  __typename?: "Mutation";
  updateErrorCheck: { __typename?: "ErrorCheck"; id: string };
};

export type UpdateFirmMutationVariables = Exact<{
  input: UpdateFirmInput;
}>;

export type UpdateFirmMutation = {
  __typename?: "Mutation";
  updateFirm: { __typename?: "Firm"; id: string; name: string };
};

export type UpdateQuestionMutationVariables = Exact<{
  input: UpdateQuestionInput;
}>;

export type UpdateQuestionMutation = {
  __typename?: "Mutation";
  updateQuestion: { __typename?: "Question"; id: string };
};

export type UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;

export type UpdateTaskMutation = {
  __typename?: "Mutation";
  updateTask: {
    __typename?: "Task";
    id: string;
    title: string;
    description: string;
    createdAt: number;
    status: TaskStatus;
    dueDate?: number | null;
    createdBy: {
      __typename?: "Account";
      id: string;
      name: string;
      firstName: string;
      avatar: string;
    };
    assignedTo: Array<{
      __typename?: "Account";
      id: string;
      name: string;
      email: string;
      avatar: string;
      avatarColor: string;
    }>;
    deal?: {
      __typename?: "Deal";
      id: string;
      company: {
        __typename?: "Company";
        id: string;
        name: string;
        logo: string;
        logoColor: string;
      };
    } | null;
  };
};

export type UpdateTasksMutationVariables = Exact<{
  input: UpdateTasksInput;
}>;

export type UpdateTasksMutation = {
  __typename?: "Mutation";
  updateTasks: Array<{
    __typename?: "Task";
    id: string;
    title: string;
    createdAt: number;
    status: TaskStatus;
    priority?: TaskPriority | null;
    sortOrder: number;
    description: string;
    dueDate?: number | null;
    activity: Array<{
      __typename?: "TaskActivity";
      id: string;
      type: TaskActivityType;
    }>;
    createdBy: {
      __typename?: "Account";
      id: string;
      name: string;
      firstName: string;
      avatar: string;
    };
    assignedTo: Array<{
      __typename?: "Account";
      id: string;
      name: string;
      email: string;
      avatar: string;
    }>;
    deal?: {
      __typename?: "Deal";
      id: string;
      company: {
        __typename?: "Company";
        id: string;
        name: string;
        logo: string;
        logoColor: string;
      };
    } | null;
  }>;
};

export const AccountFragmentFragmentDoc = `
    fragment AccountFragment on Account {
  id
  name
  email
  status
  createdAt
  avatar
  avatarColor
  unreadNotificationCount
  notifications {
    id
    createdAt
    title
    message
    type
    readAt
    task {
      id
    }
  }
  dealAccounts {
    role
  }
  deals {
    id
    runner
    createdAt
    status
    dataRoom {
      id
    }
    activeDealAccount {
      id
      createdAt
      dealGroup {
        id
      }
      dealAccessStatus
    }
    company {
      name
      logoColor
      logo
    }
    dealAccounts {
      id
      account {
        id
      }
      role
      dealAccessStatus
      dealGroup {
        id
      }
    }
  }
  firmAccountType
  firm {
    id
    name
    website
    logo
    address
    type
    accounts {
      id
      name
      avatar
      avatarColor
      email
    }
    dealProperties {
      id
      propertyKey
      createdAt
      createdBy {
        id
        name
        avatar
      }
    }
  }
}
    `;
export const DueDiligenceChecklistItemFragmentDoc = `
    fragment DueDiligenceChecklistItem on DueDiligenceChecklistItem {
  id
  title
  createdAt
  completedAt
  type
  dueDate
  dataRoomFile {
    id
    name
    fileType
  }
  assignedTo {
    id
    name
    firm {
      id
      name
    }
    avatar
    avatarColor
  }
  createdBy {
    id
    name
    firm {
      id
      name
    }
    avatar
    avatarColor
  }
}
    `;
export const DataRoomFolderFragmentFragmentDoc = `
    fragment DataRoomFolderFragment on DataRoomFolder {
  id
  name
  createdAt
  createdBy {
    email
    name
  }
  hasFolderWriteAccess
  context
  dealGroup {
    id
    name
  }
  files {
    id
    name
    updatedAt
    fileType
    errorCheckingStatus
    onlineStatus
    createdBy {
      id
      name
    }
    permission {
      id
      name
    }
  }
  parentFolders {
    id
    name
  }
  folders {
    id
    name
    createdAt
    dealGroup {
      id
      name
    }
    permission {
      id
      name
    }
    createdBy {
      email
      name
    }
  }
}
    `;
export const DataRoomFragmentFragmentDoc = `
    fragment DataRoomFragment on DataRoom {
  id
  createdAt
  updatedAt
  folder {
    ...DataRoomFolderFragment
  }
  permissions {
    id
    name
    description
    accessLevel
    createdAt
    fileCount
  }
}
    ${DataRoomFolderFragmentFragmentDoc}`;
export const DealActivityFragmentFragmentDoc = `
    fragment DealActivityFragment on DealActivity {
  id
  createdAt
  type
  account {
    id
    name
  }
  folder {
    id
    name
    dealGroup {
      id
    }
  }
  file {
    id
    name
    fileType
    folderId
  }
  question {
    id
    title
  }
  dataRoomFileError {
    id
    key
    dismissalReason
    resolvedMessage
    files {
      file {
        id
        name
        fileType
      }
    }
  }
  comment {
    comment
    files {
      id
      name
      fileType
    }
  }
  invite {
    id
    email
  }
  accountImpersonation {
    name
  }
  accessActivity {
    account {
      id
      name
      email
    }
    dealGroup {
      id
      name
    }
    fileDifferences {
      updated {
        id
        oldType
        newType
      }
    }
    folderDifferences {
      updated {
        id
        oldType
        newType
      }
    }
    permission {
      id
      name
    }
  }
  mergedFile
}
    `;
export const QuestionFragmentDoc = `
    fragment Question on Question {
  id
  title
  body
  createdAt
  answeredAt
  dataRoomFile {
    id
    name
    fileType
  }
  answer {
    id
  }
  activity {
    id
    type
  }
  dealGroup {
    id
    name
    type
  }
  createdBy {
    id
    name
    firm {
      id
      name
    }
    avatar
    avatarColor
  }
  forDealGroup {
    id
    name
  }
}
    `;
export const DataRoomFileFragmentFragmentDoc = `
    fragment DataRoomFileFragment on DataRoomFile {
  id
  name
  description
  url
  createdAt
  deletedAt
  deletionType
  context
  currentLiveVersion {
    id
    pspdfkitDocumentId
    pspdfkitToken
    versionNumber
  }
  onlineStatus
  createdBy {
    id
    name
  }
  permission {
    id
    name
    description
  }
  parentFolders {
    id
    name
  }
  hasAccess
  hasDownloadAccess
  folderId
  updatedAt
  fileType
  uploadStatus
  versions {
    id
    summary
    hash
    shortHash
    versionNumber
    activity {
      ...DealActivityFragment
    }
    changes {
      summary
      textChanges {
        section
        previous
        new
        diffs {
          type
          text
        }
      }
    }
    fileName
    fileType
    createdAt
    createdBy {
      id
      email
      name
      avatar
    }
  }
  latestVersion {
    id
    summary
    createdAt
    createdBy {
      id
      email
      name
      avatar
      avatarColor
    }
  }
  questions {
    ...Question
  }
  stats {
    views
    downloads
  }
  activity {
    ...DealActivityFragment
  }
  documentSummary {
    summary
    keyPoints {
      title
      description
    }
  }
  documentSummaryStatus
}
    ${DealActivityFragmentFragmentDoc}
${QuestionFragmentDoc}`;
export const DdqlFieldsFragmentDoc = `
    fragment DdqlFields on Ddql {
  id
  name
  index
  createdAt
  sourceFileName
  sourceFileType
  status
  totalChildQuestions
  answeredChildQuestions
  createdBy {
    id
    name
  }
  questions {
    id
    title
    index
    createdAt
    createdBy {
      id
      name
    }
    answer {
      id
      answer
    }
  }
  children {
    id
    name
    index
    createdAt
    createdBy {
      id
      name
    }
    questions {
      id
      title
      createdAt
      answer {
        id
      }
      activity {
        id
        type
      }
      createdBy {
        id
        name
      }
    }
    children {
      id
      name
      index
      createdAt
      createdBy {
        id
        name
      }
      questions {
        id
        title
        index
        createdAt
        answer {
          id
        }
        activity {
          id
          type
        }
        createdBy {
          id
          name
        }
      }
      children {
        id
        name
        index
        createdAt
        createdBy {
          id
          name
        }
      }
    }
  }
  dealGroup {
    id
    name
  }
  forDealGroup {
    id
    name
  }
}
    `;
export const AccessControlDealGroupFragmentDoc = `
    fragment AccessControlDealGroup on AccessControlDealGroup {
  dealGroup {
    id
    name
  }
  accessControl {
    id
    permissionLevel
  }
  accessControlDealAccounts {
    dealAccount {
      id
      account {
        id
        name
        avatar
      }
    }
    accessControl {
      id
      permissionLevel
    }
  }
}
    `;
export const DealAccountFragmentDoc = `
    fragment DealAccount on DealAccount {
  id
  createdAt
  dataRoomPermission {
    id
    name
    createdAt
    accessLevel
    fileCount
    description
  }
  dealAccessStatus
  overrideDataRoomAccess
  lastSeen
  granularAccess {
    files {
      id
      type
    }
    folders {
      id
      type
    }
  }
  account {
    id
    name
    email
  }
  role
}
    `;
export const RowFragmentFragmentDoc = `
    fragment RowFragment on Row {
  id
  name
  type
  values {
    column
    value
  }
}
    `;
export const PnlVersionFragmentFragmentDoc = `
    fragment PnlVersionFragment on PnlVersion {
  id
  createdAt
  columns {
    id
    name
  }
  revenues {
    ...RowFragment
  }
  cogs {
    ...RowFragment
  }
  operatingExpenses {
    ...RowFragment
  }
  depreciations {
    ...RowFragment
  }
  amortizations {
    ...RowFragment
  }
  interests {
    ...RowFragment
  }
  taxes {
    ...RowFragment
  }
  changeSummary
  changeSummaryStatus
}
    ${RowFragmentFragmentDoc}`;
export const DdqlFragmentDoc = `
    fragment Ddql on Ddql {
  id
  name
  index
  createdAt
  status
  totalChildQuestions
  answeredChildQuestions
  dealGroup {
    id
    name
    type
  }
  createdBy {
    id
    name
  }
  questions {
    id
    title
    body
    index
    createdAt
    answeredAt
    dataRoomFile {
      id
      name
      fileType
    }
    answer {
      id
    }
    activity {
      id
      type
    }
    createdBy {
      id
      name
      firm {
        id
        name
      }
      avatar
      avatarColor
    }
  }
  sourceFileName
  sourceFileType
}
    `;
export const AcceptDealInviteDocument = `
    mutation AcceptDealInvite($token: String!, $ndaSigned: Boolean!) {
  acceptDealInvite(token: $token, ndaSigned: $ndaSigned) {
    id
    company {
      id
      name
    }
  }
}
    `;

export const useAcceptDealInviteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AcceptDealInviteMutation,
    TError,
    AcceptDealInviteMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    AcceptDealInviteMutation,
    TError,
    AcceptDealInviteMutationVariables,
    TContext
  >({
    mutationKey: ["AcceptDealInvite"],
    mutationFn: (variables?: AcceptDealInviteMutationVariables) =>
      fetcher<AcceptDealInviteMutation, AcceptDealInviteMutationVariables>(
        client,
        AcceptDealInviteDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const AcceptFirmInviteDocument = `
    mutation AcceptFirmInvite($token: String!) {
  acceptFirmInvite(token: $token) {
    id
    name
  }
}
    `;

export const useAcceptFirmInviteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AcceptFirmInviteMutation,
    TError,
    AcceptFirmInviteMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    AcceptFirmInviteMutation,
    TError,
    AcceptFirmInviteMutationVariables,
    TContext
  >({
    mutationKey: ["AcceptFirmInvite"],
    mutationFn: (variables?: AcceptFirmInviteMutationVariables) =>
      fetcher<AcceptFirmInviteMutation, AcceptFirmInviteMutationVariables>(
        client,
        AcceptFirmInviteDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const AccessDocument = `
    query Access($dealId: String!) {
  deal(id: $dealId) {
    id
    runner
    company {
      id
      name
      logo
      logoColor
    }
    dataRoom {
      id
      permissions {
        id
        createdAt
        name
        description
        accessLevel
        fileCount
      }
    }
    invites {
      id
      email
      createdAt
      role
      dealGroup {
        id
        name
      }
      status
      dataRoomPermission {
        id
        name
        description
        accessLevel
        fileCount
      }
      overrideDataRoomAccess
      granularAccess {
        files {
          id
          type
        }
        folders {
          id
          type
        }
      }
    }
    otherGuests {
      ...DealAccount
    }
    guestGroups {
      id
      name
      dealAccessStatus
      overrideDataRoomAccess
      type
      granularAccess {
        files {
          id
          type
        }
        folders {
          id
          type
        }
      }
      dataRoomPermission {
        id
        name
        description
        accessLevel
        fileCount
      }
      accounts {
        ...DealAccount
      }
    }
    activeDealAccount {
      id
      role
      dealGroup {
        id
      }
    }
  }
}
    ${DealAccountFragmentDoc}`;

export const useAccessQuery = <TData = AccessQuery, TError = unknown>(
  client: GraphQLClient,
  variables: AccessQueryVariables,
  options?: Omit<UseQueryOptions<AccessQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<AccessQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<AccessQuery, TError, TData>({
    queryKey: ["Access", variables],
    queryFn: fetcher<AccessQuery, AccessQueryVariables>(
      client,
      AccessDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const AccessControlAddMoreDocument = `
    query AccessControlAddMore($dealId: String!) {
  deal(id: $dealId) {
    id
    guestGroups {
      id
      name
      accounts {
        id
        account {
          id
          name
          avatar
        }
      }
    }
  }
}
    `;

export const useAccessControlAddMoreQuery = <
  TData = AccessControlAddMoreQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: AccessControlAddMoreQueryVariables,
  options?: Omit<
    UseQueryOptions<AccessControlAddMoreQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      AccessControlAddMoreQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<AccessControlAddMoreQuery, TError, TData>({
    queryKey: ["AccessControlAddMore", variables],
    queryFn: fetcher<
      AccessControlAddMoreQuery,
      AccessControlAddMoreQueryVariables
    >(client, AccessControlAddMoreDocument, variables, headers),
    ...options,
  });
};

export const AccessControlCheckDocument = `
    query AccessControlCheck($input: AccessControlCheckInput!) {
  accessControlCheck(input: $input) {
    hasAccess
  }
}
    `;

export const useAccessControlCheckQuery = <
  TData = AccessControlCheckQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: AccessControlCheckQueryVariables,
  options?: Omit<
    UseQueryOptions<AccessControlCheckQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      AccessControlCheckQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<AccessControlCheckQuery, TError, TData>({
    queryKey: ["AccessControlCheck", variables],
    queryFn: fetcher<AccessControlCheckQuery, AccessControlCheckQueryVariables>(
      client,
      AccessControlCheckDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const AccessMatrixDocument = `
    query AccessMatrix($dataRoomID: String!, $dealID: String!) {
  dataRoom(id: $dataRoomID) {
    allFolders {
      id
      name
      parentFolders {
        id
        name
      }
      files {
        id
        name
        fileType
        latestVersion {
          id
        }
        permission {
          id
          createdAt
          name
          accessLevel
          description
        }
      }
    }
  }
  deal(id: $dealID) {
    id
    dealAccounts {
      id
      role
      account {
        id
        name
        email
        avatar
      }
    }
  }
  accessMatrix(dataRoomID: $dataRoomID) {
    entityKey
    accountID
    hasAccess
  }
}
    `;

export const useAccessMatrixQuery = <
  TData = AccessMatrixQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: AccessMatrixQueryVariables,
  options?: Omit<
    UseQueryOptions<AccessMatrixQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<AccessMatrixQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<AccessMatrixQuery, TError, TData>({
    queryKey: ["AccessMatrix", variables],
    queryFn: fetcher<AccessMatrixQuery, AccessMatrixQueryVariables>(
      client,
      AccessMatrixDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const AccountDocument = `
    query Account {
  account {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;

export const useAccountQuery = <TData = AccountQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: AccountQueryVariables,
  options?: Omit<UseQueryOptions<AccountQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<AccountQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<AccountQuery, TError, TData>({
    queryKey: variables === undefined ? ["Account"] : ["Account", variables],
    queryFn: fetcher<AccountQuery, AccountQueryVariables>(
      client,
      AccountDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const ActiveDealAccountDocument = `
    query ActiveDealAccount($id: String!) {
  deal(id: $id) {
    activeDealAccount {
      id
      role
      dealAccessStatus
      dealGroup {
        id
        dealAccessStatus
      }
      dealThread {
        id
        messages {
          id
          createdAt
          thread {
            id
          }
          files {
            id
          }
          status
          role
          files {
            id
            name
            fileType
          }
          content {
            text {
              value
              annotations {
                text
                citation {
                  quote
                }
              }
            }
            quotes {
              quote
              file {
                id
              }
            }
            files {
              file {
                id
                name
                fileType
              }
              pageIndex
              rectsOnPage
            }
          }
        }
      }
    }
  }
}
    `;

export const useActiveDealAccountQuery = <
  TData = ActiveDealAccountQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: ActiveDealAccountQueryVariables,
  options?: Omit<
    UseQueryOptions<ActiveDealAccountQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      ActiveDealAccountQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<ActiveDealAccountQuery, TError, TData>({
    queryKey: ["ActiveDealAccount", variables],
    queryFn: fetcher<ActiveDealAccountQuery, ActiveDealAccountQueryVariables>(
      client,
      ActiveDealAccountDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const AddDeepSearchFileReferenceDocument = `
    mutation AddDeepSearchFileReference($input: AddDeepSearchFileReferenceInput!) {
  addDeepSearchFileReference(input: $input) {
    id
  }
}
    `;

export const useAddDeepSearchFileReferenceMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    AddDeepSearchFileReferenceMutation,
    TError,
    AddDeepSearchFileReferenceMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    AddDeepSearchFileReferenceMutation,
    TError,
    AddDeepSearchFileReferenceMutationVariables,
    TContext
  >({
    mutationKey: ["AddDeepSearchFileReference"],
    mutationFn: (variables?: AddDeepSearchFileReferenceMutationVariables) =>
      fetcher<
        AddDeepSearchFileReferenceMutation,
        AddDeepSearchFileReferenceMutationVariables
      >(client, AddDeepSearchFileReferenceDocument, variables, headers)(),
    ...options,
  });
};

export const AddPeopleFromFirmDocument = `
    query AddPeopleFromFirm($dealId: String!, $firmId: String!) {
  firm(id: $firmId) {
    id
    accounts {
      id
      name
      email
      avatar
      avatarColor
      firmAccountType
    }
  }
  deal(id: $dealId) {
    company {
      id
      name
    }
    dealAccounts {
      id
      role
      account {
        id
        firm {
          id
        }
      }
    }
    activeDealAccount {
      id
      role
      dealGroup {
        id
        accounts {
          id
          account {
            id
          }
        }
      }
    }
  }
}
    `;

export const useAddPeopleFromFirmQuery = <
  TData = AddPeopleFromFirmQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: AddPeopleFromFirmQueryVariables,
  options?: Omit<
    UseQueryOptions<AddPeopleFromFirmQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      AddPeopleFromFirmQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<AddPeopleFromFirmQuery, TError, TData>({
    queryKey: ["AddPeopleFromFirm", variables],
    queryFn: fetcher<AddPeopleFromFirmQuery, AddPeopleFromFirmQueryVariables>(
      client,
      AddPeopleFromFirmDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const AllDataRoomFoldersDocument = `
    query AllDataRoomFolders($dealId: String!) {
  deal(id: $dealId) {
    dataRoom {
      allFolders {
        id
        name
        parentFolders {
          id
          name
        }
        dealGroup {
          id
        }
        files {
          id
          name
          fileType
          latestVersion {
            id
          }
          currentLiveVersion {
            id
            versionNumber
            createdBy {
              id
              name
              avatar
            }
            createdAt
          }
          versions {
            id
            versionNumber
            createdAt
            createdBy {
              id
              name
              avatar
            }
          }
          permission {
            id
            createdAt
            name
            accessLevel
            description
          }
        }
      }
    }
  }
}
    `;

export const useAllDataRoomFoldersQuery = <
  TData = AllDataRoomFoldersQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: AllDataRoomFoldersQueryVariables,
  options?: Omit<
    UseQueryOptions<AllDataRoomFoldersQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      AllDataRoomFoldersQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<AllDataRoomFoldersQuery, TError, TData>({
    queryKey: ["AllDataRoomFolders", variables],
    queryFn: fetcher<AllDataRoomFoldersQuery, AllDataRoomFoldersQueryVariables>(
      client,
      AllDataRoomFoldersDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const AllFilesDocument = `
    query AllFiles($dealId: String!) {
  deal(id: $dealId) {
    dataRoom {
      updatedAt
      allFiles {
        id
        name
        folderId
        fileType
        createdAt
        createdBy {
          id
          name
        }
      }
    }
  }
}
    `;

export const useAllFilesQuery = <TData = AllFilesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: AllFilesQueryVariables,
  options?: Omit<UseQueryOptions<AllFilesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<AllFilesQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<AllFilesQuery, TError, TData>({
    queryKey: ["AllFiles", variables],
    queryFn: fetcher<AllFilesQuery, AllFilesQueryVariables>(
      client,
      AllFilesDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const AllFoldersDocument = `
    query AllFolders($folderId: String!) {
  allFolders(dataRoomFolderID: $folderId) {
    id
    name
    parentFolders {
      id
      name
    }
    files {
      id
      name
      fileType
      latestVersion {
        id
      }
      permission {
        id
        createdAt
        name
        accessLevel
        description
      }
    }
  }
}
    `;

export const useAllFoldersQuery = <TData = AllFoldersQuery, TError = unknown>(
  client: GraphQLClient,
  variables: AllFoldersQueryVariables,
  options?: Omit<
    UseQueryOptions<AllFoldersQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<AllFoldersQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<AllFoldersQuery, TError, TData>({
    queryKey: ["AllFolders", variables],
    queryFn: fetcher<AllFoldersQuery, AllFoldersQueryVariables>(
      client,
      AllFoldersDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const PublishDdqlDocument = `
    mutation publishDdql($id: String!) {
  publishDdql(id: $id) {
    id
  }
}
    `;

export const usePublishDdqlMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    PublishDdqlMutation,
    TError,
    PublishDdqlMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    PublishDdqlMutation,
    TError,
    PublishDdqlMutationVariables,
    TContext
  >({
    mutationKey: ["publishDdql"],
    mutationFn: (variables?: PublishDdqlMutationVariables) =>
      fetcher<PublishDdqlMutation, PublishDdqlMutationVariables>(
        client,
        PublishDdqlDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const ApproveDealInviteDocument = `
    mutation ApproveDealInvite($id: String!) {
  approveDealInvite(id: $id) {
    id
  }
}
    `;

export const useApproveDealInviteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    ApproveDealInviteMutation,
    TError,
    ApproveDealInviteMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    ApproveDealInviteMutation,
    TError,
    ApproveDealInviteMutationVariables,
    TContext
  >({
    mutationKey: ["ApproveDealInvite"],
    mutationFn: (variables?: ApproveDealInviteMutationVariables) =>
      fetcher<ApproveDealInviteMutation, ApproveDealInviteMutationVariables>(
        client,
        ApproveDealInviteDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const AssignToDealGroupModalDocument = `
    query AssignToDealGroupModal($dealId: String!) {
  deal(id: $dealId) {
    id
    guestGroups {
      id
      name
      accounts {
        id
      }
    }
  }
}
    `;

export const useAssignToDealGroupModalQuery = <
  TData = AssignToDealGroupModalQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: AssignToDealGroupModalQueryVariables,
  options?: Omit<
    UseQueryOptions<AssignToDealGroupModalQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      AssignToDealGroupModalQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<AssignToDealGroupModalQuery, TError, TData>({
    queryKey: ["AssignToDealGroupModal", variables],
    queryFn: fetcher<
      AssignToDealGroupModalQuery,
      AssignToDealGroupModalQueryVariables
    >(client, AssignToDealGroupModalDocument, variables, headers),
    ...options,
  });
};

export const DeleteDdqlDocument = `
    mutation deleteDdql($id: String!) {
  deleteDdql(id: $id) {
    id
  }
}
    `;

export const useDeleteDdqlMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteDdqlMutation,
    TError,
    DeleteDdqlMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteDdqlMutation,
    TError,
    DeleteDdqlMutationVariables,
    TContext
  >({
    mutationKey: ["deleteDdql"],
    mutationFn: (variables?: DeleteDdqlMutationVariables) =>
      fetcher<DeleteDdqlMutation, DeleteDdqlMutationVariables>(
        client,
        DeleteDdqlDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CheckFileAccessDocument = `
    query CheckFileAccess($accountId: String!, $fileId: String!) {
  checkFileAccess(accountId: $accountId, fileId: $fileId)
}
    `;

export const useCheckFileAccessQuery = <
  TData = CheckFileAccessQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: CheckFileAccessQueryVariables,
  options?: Omit<
    UseQueryOptions<CheckFileAccessQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<CheckFileAccessQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<CheckFileAccessQuery, TError, TData>({
    queryKey: ["CheckFileAccess", variables],
    queryFn: fetcher<CheckFileAccessQuery, CheckFileAccessQueryVariables>(
      client,
      CheckFileAccessDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const CompanyDocument = `
    query Company($id: String!) {
  company(id: $id) {
    id
    name
    logo
  }
}
    `;

export const useCompanyQuery = <TData = CompanyQuery, TError = unknown>(
  client: GraphQLClient,
  variables: CompanyQueryVariables,
  options?: Omit<UseQueryOptions<CompanyQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<CompanyQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<CompanyQuery, TError, TData>({
    queryKey: ["Company", variables],
    queryFn: fetcher<CompanyQuery, CompanyQueryVariables>(
      client,
      CompanyDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const CopyDataRoomFileDocument = `
    mutation CopyDataRoomFile($input: CopyDataRoomFileInput!) {
  copyDataRoomFile(input: $input) {
    id
  }
}
    `;

export const useCopyDataRoomFileMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CopyDataRoomFileMutation,
    TError,
    CopyDataRoomFileMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CopyDataRoomFileMutation,
    TError,
    CopyDataRoomFileMutationVariables,
    TContext
  >({
    mutationKey: ["CopyDataRoomFile"],
    mutationFn: (variables?: CopyDataRoomFileMutationVariables) =>
      fetcher<CopyDataRoomFileMutation, CopyDataRoomFileMutationVariables>(
        client,
        CopyDataRoomFileDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateAccountDocument = `
    mutation CreateAccount($clerkUserId: String!) {
  createAccount(clerkUserId: $clerkUserId) {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;

export const useCreateAccountMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateAccountMutation,
    TError,
    CreateAccountMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateAccountMutation,
    TError,
    CreateAccountMutationVariables,
    TContext
  >({
    mutationKey: ["CreateAccount"],
    mutationFn: (variables?: CreateAccountMutationVariables) =>
      fetcher<CreateAccountMutation, CreateAccountMutationVariables>(
        client,
        CreateAccountDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateCommentDocument = `
    mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    id
  }
}
    `;

export const useCreateCommentMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateCommentMutation,
    TError,
    CreateCommentMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateCommentMutation,
    TError,
    CreateCommentMutationVariables,
    TContext
  >({
    mutationKey: ["CreateComment"],
    mutationFn: (variables?: CreateCommentMutationVariables) =>
      fetcher<CreateCommentMutation, CreateCommentMutationVariables>(
        client,
        CreateCommentDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDataRoomFileDocument = `
    mutation CreateDataRoomFile($name: String!, $description: String!, $dataRoomFolderID: String!, $dataRoomPermissionID: String!, $fileName: String!, $fileType: String!, $parts: Int!) {
  createDataRoomFile(
    name: $name
    dataRoomFolderID: $dataRoomFolderID
    permissionID: $dataRoomPermissionID
    description: $description
    fileName: $fileName
    fileType: $fileType
    parts: $parts
  ) {
    dataRoomFile {
      id
    }
    initialVersion {
      id
    }
    multiPartUploadID
    parts {
      presignedUrl
      partNumber
    }
  }
}
    `;

export const useCreateDataRoomFileMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDataRoomFileMutation,
    TError,
    CreateDataRoomFileMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDataRoomFileMutation,
    TError,
    CreateDataRoomFileMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDataRoomFile"],
    mutationFn: (variables?: CreateDataRoomFileMutationVariables) =>
      fetcher<CreateDataRoomFileMutation, CreateDataRoomFileMutationVariables>(
        client,
        CreateDataRoomFileDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDataFileVersionDocument = `
    mutation CreateDataFileVersion($dataRoomFileID: String!, $fileName: String!, $fileType: String!, $summary: String!) {
  createDataRoomFileVersion(
    dataRoomFileID: $dataRoomFileID
    fileName: $fileName
    fileType: $fileType
    summary: $summary
  ) {
    dataRoomFileVersionID
    url
  }
}
    `;

export const useCreateDataFileVersionMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDataFileVersionMutation,
    TError,
    CreateDataFileVersionMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDataFileVersionMutation,
    TError,
    CreateDataFileVersionMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDataFileVersion"],
    mutationFn: (variables?: CreateDataFileVersionMutationVariables) =>
      fetcher<
        CreateDataFileVersionMutation,
        CreateDataFileVersionMutationVariables
      >(client, CreateDataFileVersionDocument, variables, headers)(),
    ...options,
  });
};

export const CreateDataRoomFolderDocument = `
    mutation CreateDataRoomFolder($name: String!, $dataRoomFolderID: String!) {
  createDataRoomFolder(name: $name, dataRoomFolderID: $dataRoomFolderID) {
    ...DataRoomFolderFragment
  }
}
    ${DataRoomFolderFragmentFragmentDoc}`;

export const useCreateDataRoomFolderMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDataRoomFolderMutation,
    TError,
    CreateDataRoomFolderMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDataRoomFolderMutation,
    TError,
    CreateDataRoomFolderMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDataRoomFolder"],
    mutationFn: (variables?: CreateDataRoomFolderMutationVariables) =>
      fetcher<
        CreateDataRoomFolderMutation,
        CreateDataRoomFolderMutationVariables
      >(client, CreateDataRoomFolderDocument, variables, headers)(),
    ...options,
  });
};

export const CreateDdqlDocument = `
    mutation CreateDdql($input: CreateDdqlInput!) {
  createDdql(input: $input) {
    ddql {
      id
      dealGroup {
        id
        name
      }
      sourceFileName
      sourceFileType
      status
      createdAt
    }
    multiPartUploadID
    parts {
      presignedUrl
      partNumber
    }
  }
}
    `;

export const useCreateDdqlMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDdqlMutation,
    TError,
    CreateDdqlMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDdqlMutation,
    TError,
    CreateDdqlMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDdql"],
    mutationFn: (variables?: CreateDdqlMutationVariables) =>
      fetcher<CreateDdqlMutation, CreateDdqlMutationVariables>(
        client,
        CreateDdqlDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDdqlImportDocument = `
    mutation CreateDdqlImport($ddqlID: String!, $fileName: String!, $fileType: String!, $parts: Int!) {
  createDdqlImport(
    ddqlID: $ddqlID
    fileName: $fileName
    fileType: $fileType
    parts: $parts
  ) {
    ddql {
      id
      dealGroup {
        id
        name
      }
      sourceFileName
      sourceFileType
      status
      createdAt
    }
    multiPartUploadID
    parts {
      presignedUrl
      partNumber
    }
  }
}
    `;

export const useCreateDdqlImportMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDdqlImportMutation,
    TError,
    CreateDdqlImportMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDdqlImportMutation,
    TError,
    CreateDdqlImportMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDdqlImport"],
    mutationFn: (variables?: CreateDdqlImportMutationVariables) =>
      fetcher<CreateDdqlImportMutation, CreateDdqlImportMutationVariables>(
        client,
        CreateDdqlImportDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDealDocument = `
    mutation CreateDeal($input: CreateDealInput!) {
  createDeal(input: $input) {
    id
    company {
      id
      name
    }
  }
}
    `;

export const useCreateDealMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDealMutation,
    TError,
    CreateDealMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDealMutation,
    TError,
    CreateDealMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDeal"],
    mutationFn: (variables?: CreateDealMutationVariables) =>
      fetcher<CreateDealMutation, CreateDealMutationVariables>(
        client,
        CreateDealDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDealAccountsDocument = `
    mutation CreateDealAccounts($input: CreateDealAccountsInput!) {
  createDealAccounts(input: $input) {
    account {
      id
    }
  }
}
    `;

export const useCreateDealAccountsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDealAccountsMutation,
    TError,
    CreateDealAccountsMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDealAccountsMutation,
    TError,
    CreateDealAccountsMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDealAccounts"],
    mutationFn: (variables?: CreateDealAccountsMutationVariables) =>
      fetcher<CreateDealAccountsMutation, CreateDealAccountsMutationVariables>(
        client,
        CreateDealAccountsDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDealExportDocument = `
    mutation CreateDealExport($input: CreateDealExportInput!) {
  createDealExport(input: $input) {
    id
    status
    createdAt
  }
}
    `;

export const useCreateDealExportMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDealExportMutation,
    TError,
    CreateDealExportMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDealExportMutation,
    TError,
    CreateDealExportMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDealExport"],
    mutationFn: (variables?: CreateDealExportMutationVariables) =>
      fetcher<CreateDealExportMutation, CreateDealExportMutationVariables>(
        client,
        CreateDealExportDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDealFirmDocument = `
    mutation CreateDealFirm($input: CreateDealFirmInput!) {
  createDealFirm(input: $input) {
    id
    name
    ndaDataRoomFile {
      id
      name
    }
  }
}
    `;

export const useCreateDealFirmMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDealFirmMutation,
    TError,
    CreateDealFirmMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDealFirmMutation,
    TError,
    CreateDealFirmMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDealFirm"],
    mutationFn: (variables?: CreateDealFirmMutationVariables) =>
      fetcher<CreateDealFirmMutation, CreateDealFirmMutationVariables>(
        client,
        CreateDealFirmDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDealGroupDocument = `
    mutation CreateDealGroup($input: CreateDealGroupInput!) {
  createDealGroup(input: $input) {
    id
    name
    ndaDataRoomFile {
      id
      name
    }
  }
}
    `;

export const useCreateDealGroupMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDealGroupMutation,
    TError,
    CreateDealGroupMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDealGroupMutation,
    TError,
    CreateDealGroupMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDealGroup"],
    mutationFn: (variables?: CreateDealGroupMutationVariables) =>
      fetcher<CreateDealGroupMutation, CreateDealGroupMutationVariables>(
        client,
        CreateDealGroupDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDealInviteDocument = `
    mutation CreateDealInvite($input: CreateDealInviteInput!) {
  createDealInvite(input: $input) {
    id
    email
    role
    deal {
      id
    }
    createdAt
  }
}
    `;

export const useCreateDealInviteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDealInviteMutation,
    TError,
    CreateDealInviteMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDealInviteMutation,
    TError,
    CreateDealInviteMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDealInvite"],
    mutationFn: (variables?: CreateDealInviteMutationVariables) =>
      fetcher<CreateDealInviteMutation, CreateDealInviteMutationVariables>(
        client,
        CreateDealInviteDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDealPropertyDocument = `
    mutation CreateDealProperty($input: CreateDealPropertyInput!) {
  createDealProperty(input: $input) {
    id
  }
}
    `;

export const useCreateDealPropertyMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDealPropertyMutation,
    TError,
    CreateDealPropertyMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDealPropertyMutation,
    TError,
    CreateDealPropertyMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDealProperty"],
    mutationFn: (variables?: CreateDealPropertyMutationVariables) =>
      fetcher<CreateDealPropertyMutation, CreateDealPropertyMutationVariables>(
        client,
        CreateDealPropertyDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDealThreadDocument = `
    mutation CreateDealThread($input: CreateDealThreadInput!) {
  createDealThread(input: $input) {
    id
    messages {
      id
      createdAt
      thread {
        id
      }
      files {
        id
      }
      status
      role
      files {
        id
        name
        fileType
      }
      content {
        text {
          value
          annotations {
            text
            citation {
              quote
            }
          }
        }
      }
    }
  }
}
    `;

export const useCreateDealThreadMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDealThreadMutation,
    TError,
    CreateDealThreadMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDealThreadMutation,
    TError,
    CreateDealThreadMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDealThread"],
    mutationFn: (variables?: CreateDealThreadMutationVariables) =>
      fetcher<CreateDealThreadMutation, CreateDealThreadMutationVariables>(
        client,
        CreateDealThreadDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDealThreadMessageDocument = `
    mutation CreateDealThreadMessage($input: CreateDealThreadMessageInput!) {
  createDealThreadMessage(input: $input) {
    dealThreadMessage {
      id
      createdAt
    }
    responseMessage {
      id
      status
      role
      createdAt
    }
  }
}
    `;

export const useCreateDealThreadMessageMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDealThreadMessageMutation,
    TError,
    CreateDealThreadMessageMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDealThreadMessageMutation,
    TError,
    CreateDealThreadMessageMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDealThreadMessage"],
    mutationFn: (variables?: CreateDealThreadMessageMutationVariables) =>
      fetcher<
        CreateDealThreadMessageMutation,
        CreateDealThreadMessageMutationVariables
      >(client, CreateDealThreadMessageDocument, variables, headers)(),
    ...options,
  });
};

export const CreateDeepSearchDocument = `
    mutation CreateDeepSearch($input: CreateDeepSearchInput!) {
  createDeepSearch(input: $input) {
    id
  }
}
    `;

export const useCreateDeepSearchMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDeepSearchMutation,
    TError,
    CreateDeepSearchMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDeepSearchMutation,
    TError,
    CreateDeepSearchMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDeepSearch"],
    mutationFn: (variables?: CreateDeepSearchMutationVariables) =>
      fetcher<CreateDeepSearchMutation, CreateDeepSearchMutationVariables>(
        client,
        CreateDeepSearchDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateDeepSearchFileDocument = `
    mutation CreateDeepSearchFile($input: CreateDeepSearchFileInput!) {
  createDeepSearchFile(input: $input) {
    id
  }
}
    `;

export const useCreateDeepSearchFileMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDeepSearchFileMutation,
    TError,
    CreateDeepSearchFileMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDeepSearchFileMutation,
    TError,
    CreateDeepSearchFileMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDeepSearchFile"],
    mutationFn: (variables?: CreateDeepSearchFileMutationVariables) =>
      fetcher<
        CreateDeepSearchFileMutation,
        CreateDeepSearchFileMutationVariables
      >(client, CreateDeepSearchFileDocument, variables, headers)(),
    ...options,
  });
};

export const CreateDeepSearchThreadMessageDocument = `
    mutation CreateDeepSearchThreadMessage($input: CreateDeepSearchThreadMessageInput!) {
  createDeepSearchThreadMessage(input: $input) {
    id
    createdAt
  }
}
    `;

export const useCreateDeepSearchThreadMessageMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDeepSearchThreadMessageMutation,
    TError,
    CreateDeepSearchThreadMessageMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDeepSearchThreadMessageMutation,
    TError,
    CreateDeepSearchThreadMessageMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDeepSearchThreadMessage"],
    mutationFn: (variables?: CreateDeepSearchThreadMessageMutationVariables) =>
      fetcher<
        CreateDeepSearchThreadMessageMutation,
        CreateDeepSearchThreadMessageMutationVariables
      >(client, CreateDeepSearchThreadMessageDocument, variables, headers)(),
    ...options,
  });
};

export const CreateDueDiligenceChecklistItemDocument = `
    mutation CreateDueDiligenceChecklistItem($input: CreateDueDiligenceChecklistItemInput!) {
  createDueDiligenceChecklistItem(input: $input) {
    ...DueDiligenceChecklistItem
  }
}
    ${DueDiligenceChecklistItemFragmentDoc}`;

export const useCreateDueDiligenceChecklistItemMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateDueDiligenceChecklistItemMutation,
    TError,
    CreateDueDiligenceChecklistItemMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateDueDiligenceChecklistItemMutation,
    TError,
    CreateDueDiligenceChecklistItemMutationVariables,
    TContext
  >({
    mutationKey: ["CreateDueDiligenceChecklistItem"],
    mutationFn: (
      variables?: CreateDueDiligenceChecklistItemMutationVariables,
    ) =>
      fetcher<
        CreateDueDiligenceChecklistItemMutation,
        CreateDueDiligenceChecklistItemMutationVariables
      >(client, CreateDueDiligenceChecklistItemDocument, variables, headers)(),
    ...options,
  });
};

export const CreateErrorCheckDocument = `
    mutation CreateErrorCheck($input: CreateErrorCheckInput!) {
  createErrorCheck(input: $input) {
    id
  }
}
    `;

export const useCreateErrorCheckMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateErrorCheckMutation,
    TError,
    CreateErrorCheckMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateErrorCheckMutation,
    TError,
    CreateErrorCheckMutationVariables,
    TContext
  >({
    mutationKey: ["CreateErrorCheck"],
    mutationFn: (variables?: CreateErrorCheckMutationVariables) =>
      fetcher<CreateErrorCheckMutation, CreateErrorCheckMutationVariables>(
        client,
        CreateErrorCheckDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateFirmDocument = `
    mutation CreateFirm($input: CreateFirmInput!) {
  createFirm(input: $input) {
    id
    name
  }
}
    `;

export const useCreateFirmMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateFirmMutation,
    TError,
    CreateFirmMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateFirmMutation,
    TError,
    CreateFirmMutationVariables,
    TContext
  >({
    mutationKey: ["CreateFirm"],
    mutationFn: (variables?: CreateFirmMutationVariables) =>
      fetcher<CreateFirmMutation, CreateFirmMutationVariables>(
        client,
        CreateFirmDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateFirmInviteDocument = `
    mutation CreateFirmInvite($input: CreateFirmInviteInput!) {
  createFirmInvite(input: $input) {
    id
    email
  }
}
    `;

export const useCreateFirmInviteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateFirmInviteMutation,
    TError,
    CreateFirmInviteMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateFirmInviteMutation,
    TError,
    CreateFirmInviteMutationVariables,
    TContext
  >({
    mutationKey: ["CreateFirmInvite"],
    mutationFn: (variables?: CreateFirmInviteMutationVariables) =>
      fetcher<CreateFirmInviteMutation, CreateFirmInviteMutationVariables>(
        client,
        CreateFirmInviteDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateFirmInvitesDocument = `
    mutation CreateFirmInvites($input: CreateFirmInvitesInput!) {
  createFirmInvites(input: $input) {
    id
  }
}
    `;

export const useCreateFirmInvitesMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateFirmInvitesMutation,
    TError,
    CreateFirmInvitesMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateFirmInvitesMutation,
    TError,
    CreateFirmInvitesMutationVariables,
    TContext
  >({
    mutationKey: ["CreateFirmInvites"],
    mutationFn: (variables?: CreateFirmInvitesMutationVariables) =>
      fetcher<CreateFirmInvitesMutation, CreateFirmInvitesMutationVariables>(
        client,
        CreateFirmInvitesDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateIgnoreErrorRuleDocument = `
    mutation CreateIgnoreErrorRule($input: CreateIgnoreErrorRuleInput!) {
  createIgnoreErrorRule(input: $input) {
    id
  }
}
    `;

export const useCreateIgnoreErrorRuleMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateIgnoreErrorRuleMutation,
    TError,
    CreateIgnoreErrorRuleMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateIgnoreErrorRuleMutation,
    TError,
    CreateIgnoreErrorRuleMutationVariables,
    TContext
  >({
    mutationKey: ["CreateIgnoreErrorRule"],
    mutationFn: (variables?: CreateIgnoreErrorRuleMutationVariables) =>
      fetcher<
        CreateIgnoreErrorRuleMutation,
        CreateIgnoreErrorRuleMutationVariables
      >(client, CreateIgnoreErrorRuleDocument, variables, headers)(),
    ...options,
  });
};

export const CreateOrUpdateAccessControlsDocument = `
    mutation CreateOrUpdateAccessControls($input: CreateOrUpdateAccessControlsInput!) {
  createOrUpdateAccessControls(input: $input) {
    id
  }
}
    `;

export const useCreateOrUpdateAccessControlsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateOrUpdateAccessControlsMutation,
    TError,
    CreateOrUpdateAccessControlsMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateOrUpdateAccessControlsMutation,
    TError,
    CreateOrUpdateAccessControlsMutationVariables,
    TContext
  >({
    mutationKey: ["CreateOrUpdateAccessControls"],
    mutationFn: (variables?: CreateOrUpdateAccessControlsMutationVariables) =>
      fetcher<
        CreateOrUpdateAccessControlsMutation,
        CreateOrUpdateAccessControlsMutationVariables
      >(client, CreateOrUpdateAccessControlsDocument, variables, headers)(),
    ...options,
  });
};

export const CreatePnlVersionDocument = `
    mutation CreatePnlVersion($input: PnlInput!) {
  createPnlVersion(input: $input) {
    id
  }
}
    `;

export const useCreatePnlVersionMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreatePnlVersionMutation,
    TError,
    CreatePnlVersionMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreatePnlVersionMutation,
    TError,
    CreatePnlVersionMutationVariables,
    TContext
  >({
    mutationKey: ["CreatePnlVersion"],
    mutationFn: (variables?: CreatePnlVersionMutationVariables) =>
      fetcher<CreatePnlVersionMutation, CreatePnlVersionMutationVariables>(
        client,
        CreatePnlVersionDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateQuestionDocument = `
    mutation CreateQuestion($title: String!, $body: String!, $nodeId: String!, $nodeType: QuestionNodeType!, $ddqlID: String, $questionForDealGroupID: String) {
  createQuestion(
    title: $title
    body: $body
    nodeId: $nodeId
    nodeType: $nodeType
    ddqlID: $ddqlID
    questionForDealGroupID: $questionForDealGroupID
  ) {
    id
  }
}
    `;

export const useCreateQuestionMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateQuestionMutation,
    TError,
    CreateQuestionMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateQuestionMutation,
    TError,
    CreateQuestionMutationVariables,
    TContext
  >({
    mutationKey: ["CreateQuestion"],
    mutationFn: (variables?: CreateQuestionMutationVariables) =>
      fetcher<CreateQuestionMutation, CreateQuestionMutationVariables>(
        client,
        CreateQuestionDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateQuestionAnswerDocument = `
    mutation CreateQuestionAnswer($questionId: String!, $answer: String!, $dataRoomFileIds: [String!]!, $answerFiles: [DealThreadMessageContentFileInput!]!) {
  createQuestionAnswer(
    questionId: $questionId
    answer: $answer
    dataRoomFileIds: $dataRoomFileIds
    answerFiles: $answerFiles
  ) {
    id
    answer {
      id
      answer
      answeredBy {
        id
        name
      }
    }
  }
}
    `;

export const useCreateQuestionAnswerMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateQuestionAnswerMutation,
    TError,
    CreateQuestionAnswerMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateQuestionAnswerMutation,
    TError,
    CreateQuestionAnswerMutationVariables,
    TContext
  >({
    mutationKey: ["CreateQuestionAnswer"],
    mutationFn: (variables?: CreateQuestionAnswerMutationVariables) =>
      fetcher<
        CreateQuestionAnswerMutation,
        CreateQuestionAnswerMutationVariables
      >(client, CreateQuestionAnswerDocument, variables, headers)(),
    ...options,
  });
};

export const CreateRedlineExportDocument = `
    mutation CreateRedlineExport($input: CreateRedlineExportInput!) {
  createRedlineExport(input: $input) {
    id
    status
  }
}
    `;

export const useCreateRedlineExportMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateRedlineExportMutation,
    TError,
    CreateRedlineExportMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateRedlineExportMutation,
    TError,
    CreateRedlineExportMutationVariables,
    TContext
  >({
    mutationKey: ["CreateRedlineExport"],
    mutationFn: (variables?: CreateRedlineExportMutationVariables) =>
      fetcher<
        CreateRedlineExportMutation,
        CreateRedlineExportMutationVariables
      >(client, CreateRedlineExportDocument, variables, headers)(),
    ...options,
  });
};

export const CreateTaskDocument = `
    mutation CreateTask($input: CreateTaskInput!) {
  createTask(input: $input) {
    id
    title
    description
  }
}
    `;

export const useCreateTaskMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateTaskMutation,
    TError,
    CreateTaskMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateTaskMutation,
    TError,
    CreateTaskMutationVariables,
    TContext
  >({
    mutationKey: ["CreateTask"],
    mutationFn: (variables?: CreateTaskMutationVariables) =>
      fetcher<CreateTaskMutation, CreateTaskMutationVariables>(
        client,
        CreateTaskDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const CreateTaskCommentDocument = `
    mutation CreateTaskComment($input: CreateTaskCommentInput!) {
  createTaskComment(input: $input) {
    id
    comment {
      comment
    }
    createdAt
    account {
      id
      name
      firstName
      avatar
    }
  }
}
    `;

export const useCreateTaskCommentMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateTaskCommentMutation,
    TError,
    CreateTaskCommentMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    CreateTaskCommentMutation,
    TError,
    CreateTaskCommentMutationVariables,
    TContext
  >({
    mutationKey: ["CreateTaskComment"],
    mutationFn: (variables?: CreateTaskCommentMutationVariables) =>
      fetcher<CreateTaskCommentMutation, CreateTaskCommentMutationVariables>(
        client,
        CreateTaskCommentDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const DashboardDocument = `
    query Dashboard($dealId: String!, $input: DataRoomActivityMetricsInput!, $activityInput: DealActivityInput!) {
  deal(id: $dealId) {
    id
    dataRoom {
      ...DataRoomFragment
    }
    activeDealAccount {
      id
      role
      dealAccessStatus
      account {
        id
        email
      }
    }
    dealStages {
      id
      name
      index
    }
    currentDealStage {
      id
      index
      name
    }
    currentDealStageStatus
    buyers {
      id
      name
      createdAt
      dealAccessStatus
      lastSeen
      files {
        id
        fileType
        name
        createdAt
      }
      activity {
        id
        createdAt
      }
      currentDealStage {
        id
        index
      }
      currentDealStageStatus
      questions {
        id
        answeredAt
      }
      dataRoomPermission {
        id
        name
        description
        accessLevel
      }
      dealFirms {
        id
        name
        dealAccounts {
          account {
            id
            name
            email
          }
          role
        }
      }
    }
    invites {
      id
      email
      role
    }
    dealAccounts {
      account {
        id
        name
        email
      }
      role
    }
  }
  dealActivity(dealId: $dealId, input: $activityInput) {
    activity {
      id
      createdAt
      type
      account {
        id
        name
        email
        avatar
        avatarColor
      }
      folder {
        id
        createdAt
        name
        dealGroup {
          id
        }
      }
      file {
        id
        name
        fileType
        folderId
      }
      version {
        id
        summary
      }
      question {
        id
        title
      }
      comment {
        comment
        files {
          id
          name
          fileType
        }
      }
      invite {
        id
        email
      }
      accountImpersonation {
        id
        name
        email
      }
      accessActivity {
        account {
          id
          name
          email
        }
        dealGroup {
          id
          name
        }
        fileDifferences {
          updated {
            id
            oldType
            newType
          }
        }
        folderDifferences {
          updated {
            id
            oldType
            newType
          }
        }
        permission {
          id
          name
        }
      }
    }
  }
  questions(dealId: $dealId, input: {}) {
    questions {
      ...Question
    }
  }
  dataRoomActivityMetrics(input: $input) {
    metrics {
      key
      views
      downloads
    }
    cursor
  }
}
    ${DataRoomFragmentFragmentDoc}
${QuestionFragmentDoc}`;

export const useDashboardQuery = <TData = DashboardQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DashboardQueryVariables,
  options?: Omit<UseQueryOptions<DashboardQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<DashboardQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DashboardQuery, TError, TData>({
    queryKey: ["Dashboard", variables],
    queryFn: fetcher<DashboardQuery, DashboardQueryVariables>(
      client,
      DashboardDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DataRoomDocument = `
    query DataRoom($dealId: String!) {
  deal(id: $dealId) {
    id
    dataRoom {
      ...DataRoomFragment
    }
    runner
    activeDealAccount {
      role
    }
  }
}
    ${DataRoomFragmentFragmentDoc}`;

export const useDataRoomQuery = <TData = DataRoomQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DataRoomQueryVariables,
  options?: Omit<UseQueryOptions<DataRoomQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<DataRoomQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DataRoomQuery, TError, TData>({
    queryKey: ["DataRoom", variables],
    queryFn: fetcher<DataRoomQuery, DataRoomQueryVariables>(
      client,
      DataRoomDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DataRoomActivityMetricsDocument = `
    query DataRoomActivityMetrics($dealId: String!, $input: DataRoomActivityMetricsInput!) {
  dataRoomActivityMetrics(input: $input) {
    activity {
      ...DealActivityFragment
    }
    metrics {
      key
      views
      downloads
    }
    cursor
  }
  deal(id: $dealId) {
    activeDealAccount {
      role
    }
    dealAccounts {
      account {
        id
        name
        email
      }
      role
    }
    dataRoom {
      allFiles {
        id
        name
      }
    }
  }
}
    ${DealActivityFragmentFragmentDoc}`;

export const useDataRoomActivityMetricsQuery = <
  TData = DataRoomActivityMetricsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DataRoomActivityMetricsQueryVariables,
  options?: Omit<
    UseQueryOptions<DataRoomActivityMetricsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      DataRoomActivityMetricsQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DataRoomActivityMetricsQuery, TError, TData>({
    queryKey: ["DataRoomActivityMetrics", variables],
    queryFn: fetcher<
      DataRoomActivityMetricsQuery,
      DataRoomActivityMetricsQueryVariables
    >(client, DataRoomActivityMetricsDocument, variables, headers),
    ...options,
  });
};

export const DataRoomFileDocument = `
    query DataRoomFile($id: String!) {
  dataRoomFile(id: $id) {
    ...DataRoomFileFragment
  }
}
    ${DataRoomFileFragmentFragmentDoc}`;

export const useDataRoomFileQuery = <
  TData = DataRoomFileQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DataRoomFileQueryVariables,
  options?: Omit<
    UseQueryOptions<DataRoomFileQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DataRoomFileQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DataRoomFileQuery, TError, TData>({
    queryKey: ["DataRoomFile", variables],
    queryFn: fetcher<DataRoomFileQuery, DataRoomFileQueryVariables>(
      client,
      DataRoomFileDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DataRoomFileErrorDocument = `
    query DataRoomFileError($id: String!) {
  dataRoomFileError(id: $id) {
    id
    createdAt
    title
    status
    key
    baseKey
    dismissalReason
    files {
      file {
        id
        name
        fileType
        versions {
          id
          createdAt
          summary
          createdBy {
            id
            email
            name
          }
        }
      }
      key
      value
      status
    }
  }
}
    `;

export const useDataRoomFileErrorQuery = <
  TData = DataRoomFileErrorQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DataRoomFileErrorQueryVariables,
  options?: Omit<
    UseQueryOptions<DataRoomFileErrorQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      DataRoomFileErrorQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DataRoomFileErrorQuery, TError, TData>({
    queryKey: ["DataRoomFileError", variables],
    queryFn: fetcher<DataRoomFileErrorQuery, DataRoomFileErrorQueryVariables>(
      client,
      DataRoomFileErrorDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DataRoomFileErrorsDocument = `
    query DataRoomFileErrors($dealId: String!) {
  dataRoomFileErrors(dealId: $dealId) {
    id
    createdAt
    key
    title
    status
    files {
      file {
        id
        name
        fileType
      }
      value
    }
  }
}
    `;

export const useDataRoomFileErrorsQuery = <
  TData = DataRoomFileErrorsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DataRoomFileErrorsQueryVariables,
  options?: Omit<
    UseQueryOptions<DataRoomFileErrorsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      DataRoomFileErrorsQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DataRoomFileErrorsQuery, TError, TData>({
    queryKey: ["DataRoomFileErrors", variables],
    queryFn: fetcher<DataRoomFileErrorsQuery, DataRoomFileErrorsQueryVariables>(
      client,
      DataRoomFileErrorsDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DataRoomFileVersionDocument = `
    query DataRoomFileVersion($id: String!) {
  dataRoomFileVersion(id: $id) {
    id
    pspdfkitDocumentId
    pspdfkitToken
  }
}
    `;

export const useDataRoomFileVersionQuery = <
  TData = DataRoomFileVersionQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DataRoomFileVersionQueryVariables,
  options?: Omit<
    UseQueryOptions<DataRoomFileVersionQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      DataRoomFileVersionQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DataRoomFileVersionQuery, TError, TData>({
    queryKey: ["DataRoomFileVersion", variables],
    queryFn: fetcher<
      DataRoomFileVersionQuery,
      DataRoomFileVersionQueryVariables
    >(client, DataRoomFileVersionDocument, variables, headers),
    ...options,
  });
};

export const DataRoomFolderDocument = `
    query DataRoomFolder($id: String!) {
  dataRoomFolder(id: $id) {
    ...DataRoomFolderFragment
  }
}
    ${DataRoomFolderFragmentFragmentDoc}`;

export const useDataRoomFolderQuery = <
  TData = DataRoomFolderQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DataRoomFolderQueryVariables,
  options?: Omit<
    UseQueryOptions<DataRoomFolderQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DataRoomFolderQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DataRoomFolderQuery, TError, TData>({
    queryKey: ["DataRoomFolder", variables],
    queryFn: fetcher<DataRoomFolderQuery, DataRoomFolderQueryVariables>(
      client,
      DataRoomFolderDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DdChecklistDocument = `
    query DdChecklist($dealGroupId: String!) {
  dealGroup(id: $dealGroupId) {
    id
    name
    ddChecklistItems {
      ...DueDiligenceChecklistItem
    }
  }
}
    ${DueDiligenceChecklistItemFragmentDoc}`;

export const useDdChecklistQuery = <TData = DdChecklistQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DdChecklistQueryVariables,
  options?: Omit<
    UseQueryOptions<DdChecklistQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DdChecklistQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DdChecklistQuery, TError, TData>({
    queryKey: ["DdChecklist", variables],
    queryFn: fetcher<DdChecklistQuery, DdChecklistQueryVariables>(
      client,
      DdChecklistDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DdChecklistsDocument = `
    query DdChecklists($dealId: String!) {
  deal(id: $dealId) {
    id
    allGroups {
      id
      name
      ddChecklistItems {
        id
        title
        type
        completedAt
      }
    }
  }
}
    `;

export const useDdChecklistsQuery = <
  TData = DdChecklistsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DdChecklistsQueryVariables,
  options?: Omit<
    UseQueryOptions<DdChecklistsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DdChecklistsQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DdChecklistsQuery, TError, TData>({
    queryKey: ["DdChecklists", variables],
    queryFn: fetcher<DdChecklistsQuery, DdChecklistsQueryVariables>(
      client,
      DdChecklistsDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DdqlDocument = `
    query Ddql($id: String!, $dealId: String!) {
  ddql(id: $id) {
    ...DdqlFields
  }
  deal(id: $dealId) {
    id
    guestGroups {
      id
      name
      accounts {
        id
        account {
          id
          email
          name
          avatar
        }
      }
    }
  }
}
    ${DdqlFieldsFragmentDoc}`;

export const useDdqlQuery = <TData = DdqlQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DdqlQueryVariables,
  options?: Omit<UseQueryOptions<DdqlQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<DdqlQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DdqlQuery, TError, TData>({
    queryKey: ["Ddql", variables],
    queryFn: fetcher<DdqlQuery, DdqlQueryVariables>(
      client,
      DdqlDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DdqlAccessControlDocument = `
    query DdqlAccessControl($id: String!) {
  ddql(id: $id) {
    id
    status
    accessControlDealGroups {
      ...AccessControlDealGroup
    }
  }
}
    ${AccessControlDealGroupFragmentDoc}`;

export const useDdqlAccessControlQuery = <
  TData = DdqlAccessControlQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DdqlAccessControlQueryVariables,
  options?: Omit<
    UseQueryOptions<DdqlAccessControlQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      DdqlAccessControlQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DdqlAccessControlQuery, TError, TData>({
    queryKey: ["DdqlAccessControl", variables],
    queryFn: fetcher<DdqlAccessControlQuery, DdqlAccessControlQueryVariables>(
      client,
      DdqlAccessControlDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealDocument = `
    query Deal($id: String!) {
  deal(id: $id) {
    id
    status
    company {
      id
      name
      logo
      logoColor
      website
      description
    }
    dealStages {
      id
      name
      index
    }
    runner
    activeDealAccount {
      id
      role
      dealGroup {
        id
        type
      }
    }
    dataRoom {
      id
      permissions {
        id
        name
        description
        fileCount
      }
    }
    dealPropertyValues {
      id
      propertyKey
      propertyValue
      createdAt
      updatedAt
      dealProperty {
        id
      }
    }
    invites {
      id
      email
      role
      invitedBy {
        id
        email
        name
        avatar
        avatarColor
      }
      dealGroup {
        id
        name
      }
      status
    }
    guestGroups {
      id
      name
      type
      currentDealStage {
        id
        index
      }
      currentDealStageStatus
      accounts {
        ...DealAccount
      }
    }
    buyers {
      id
      name
      type
      currentDealStage {
        id
        index
      }
      currentDealStageStatus
      accounts {
        role
        account {
          id
          name
          email
          avatarColor
          avatar
        }
      }
      dealFirms {
        id
        name
        createdAt
        type
        dealAccounts {
          role
          account {
            id
            name
            email
            avatarColor
            avatar
          }
        }
      }
    }
    sellers {
      id
      name
      type
      accounts {
        role
        account {
          id
          name
          email
          avatarColor
          avatar
        }
      }
      dealFirms {
        id
        name
        createdAt
        type
        dealAccounts {
          role
          account {
            id
            name
            email
            avatarColor
            avatar
          }
        }
      }
    }
  }
}
    ${DealAccountFragmentDoc}`;

export const useDealQuery = <TData = DealQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DealQueryVariables,
  options?: Omit<UseQueryOptions<DealQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<DealQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealQuery, TError, TData>({
    queryKey: ["Deal", variables],
    queryFn: fetcher<DealQuery, DealQueryVariables>(
      client,
      DealDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealAccountDocument = `
    query DealAccount($id: String!) {
  dealAccount(id: $id) {
    id
    lastSeen
    createdAt
    dealAccessStatus
    account {
      id
      name
      email
      avatar
      avatarColor
    }
    overrideDataRoomAccess
    granularAccess {
      files {
        id
        type
      }
      folders {
        id
        type
      }
    }
    dataRoomPermission {
      id
      createdAt
      name
      accessLevel
      description
      fileCount
    }
    role
    allFolders {
      id
      name
      parentFolders {
        id
        name
      }
      files {
        id
        name
        fileType
        latestVersion {
          id
        }
        permission {
          id
          createdAt
          name
          accessLevel
          description
        }
      }
    }
    dealGroup {
      id
      name
      overrideDataRoomAccess
      dealAccessStatus
      dataRoomPermission {
        id
        createdAt
        name
        accessLevel
        description
        fileCount
      }
      granularAccess {
        files {
          id
          type
        }
        folders {
          id
          type
        }
      }
    }
  }
}
    `;

export const useDealAccountQuery = <TData = DealAccountQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DealAccountQueryVariables,
  options?: Omit<
    UseQueryOptions<DealAccountQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DealAccountQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealAccountQuery, TError, TData>({
    queryKey: ["DealAccount", variables],
    queryFn: fetcher<DealAccountQuery, DealAccountQueryVariables>(
      client,
      DealAccountDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealActivityDocument = `
    query DealActivity($dealId: String!, $input: DealActivityInput!) {
  dealActivity(dealId: $dealId, input: $input) {
    activity {
      ...DealActivityFragment
    }
    cursor
  }
}
    ${DealActivityFragmentFragmentDoc}`;

export const useDealActivityQuery = <
  TData = DealActivityQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DealActivityQueryVariables,
  options?: Omit<
    UseQueryOptions<DealActivityQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DealActivityQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealActivityQuery, TError, TData>({
    queryKey: ["DealActivity", variables],
    queryFn: fetcher<DealActivityQuery, DealActivityQueryVariables>(
      client,
      DealActivityDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealDashboardDetailsDocument = `
    query DealDashboardDetails($dealId: String!) {
  deal(id: $dealId) {
    id
    status
    createdAt
    dataRoom {
      id
      allFiles {
        id
        createdAt
      }
    }
    dataRoomFileErrors {
      id
      createdAt
      status
    }
    questions {
      id
      createdAt
      answer {
        id
      }
    }
    activeDealAccount {
      dealGroup {
        id
      }
    }
    dealPropertyValues {
      id
      propertyKey
      propertyValue
    }
    dealAccounts {
      account {
        id
        name
        email
        avatar
        avatarColor
        firm {
          id
        }
      }
    }
  }
}
    `;

export const useDealDashboardDetailsQuery = <
  TData = DealDashboardDetailsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DealDashboardDetailsQueryVariables,
  options?: Omit<
    UseQueryOptions<DealDashboardDetailsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      DealDashboardDetailsQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealDashboardDetailsQuery, TError, TData>({
    queryKey: ["DealDashboardDetails", variables],
    queryFn: fetcher<
      DealDashboardDetailsQuery,
      DealDashboardDetailsQueryVariables
    >(client, DealDashboardDetailsDocument, variables, headers),
    ...options,
  });
};

export const DealExportsDocument = `
    query DealExports($dealId: String!) {
  deal(id: $dealId) {
    id
    dealExports {
      id
      status
      createdAt
      name
      s3PresignedUrl
      createdBy {
        id
        email
      }
    }
  }
}
    `;

export const useDealExportsQuery = <TData = DealExportsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DealExportsQueryVariables,
  options?: Omit<
    UseQueryOptions<DealExportsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DealExportsQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealExportsQuery, TError, TData>({
    queryKey: ["DealExports", variables],
    queryFn: fetcher<DealExportsQuery, DealExportsQueryVariables>(
      client,
      DealExportsDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealFilesDocument = `
    query DealFiles($dealID: String!) {
  deal(id: $dealID) {
    id
    dataRoom {
      id
    }
  }
}
    `;

export const useDealFilesQuery = <TData = DealFilesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DealFilesQueryVariables,
  options?: Omit<UseQueryOptions<DealFilesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<DealFilesQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealFilesQuery, TError, TData>({
    queryKey: ["DealFiles", variables],
    queryFn: fetcher<DealFilesQuery, DealFilesQueryVariables>(
      client,
      DealFilesDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealFirmDocument = `
    query DealFirm($id: String!, $dealId: String!) {
  deal(id: $dealId) {
    activeDealAccount {
      role
      account {
        id
      }
    }
    dataRoom {
      id
      folder {
        id
      }
    }
  }
  dealFirm(id: $id) {
    id
    name
    createdAt
    ndaDataRoomFile {
      id
      name
      fileType
    }
    overrideDataRoomAccess
    granularAccess {
      files {
        id
        type
      }
      folders {
        id
        type
      }
    }
    dealAccounts {
      id
      role
      createdAt
      account {
        id
        name
        email
        avatarColor
        avatar
      }
      overrideDataRoomAccess
      granularAccess {
        files {
          id
          type
        }
        folders {
          id
          type
        }
      }
    }
    invites {
      id
      email
      role
      status
    }
  }
}
    `;

export const useDealFirmQuery = <TData = DealFirmQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DealFirmQueryVariables,
  options?: Omit<UseQueryOptions<DealFirmQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<DealFirmQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealFirmQuery, TError, TData>({
    queryKey: ["DealFirm", variables],
    queryFn: fetcher<DealFirmQuery, DealFirmQueryVariables>(
      client,
      DealFirmDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealGroupQuestionsDocument = `
    query DealGroupQuestions($id: String!) {
  dealGroup(id: $id) {
    id
    name
    questions {
      ...Question
    }
    questionsForGroup {
      ...Question
    }
    ddqlsForGroup {
      id
      name
      createdAt
      status
      sourceFileName
      answeredChildQuestions
      totalChildQuestions
      sourceFileType
      questions {
        ...Question
      }
    }
    ddqls {
      id
      name
      createdAt
      status
      sourceFileName
      answeredChildQuestions
      totalChildQuestions
      sourceFileType
      questions {
        ...Question
      }
    }
    ddqlImports {
      id
      fileName
      fileType
      status
    }
  }
}
    ${QuestionFragmentDoc}`;

export const useDealGroupQuestionsQuery = <
  TData = DealGroupQuestionsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DealGroupQuestionsQueryVariables,
  options?: Omit<
    UseQueryOptions<DealGroupQuestionsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      DealGroupQuestionsQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealGroupQuestionsQuery, TError, TData>({
    queryKey: ["DealGroupQuestions", variables],
    queryFn: fetcher<DealGroupQuestionsQuery, DealGroupQuestionsQueryVariables>(
      client,
      DealGroupQuestionsDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealGroupDocument = `
    query DealGroup($id: String!) {
  dealGroup(id: $id) {
    id
    name
    type
    createdAt
    overrideDataRoomAccess
    dealAccessStatus
    files {
      id
      createdAt
      name
      fileType
    }
    questions {
      ...Question
    }
    ddChecklistItems {
      ...DueDiligenceChecklistItem
    }
    currentDealStage {
      id
      index
    }
    currentDealStageStatus
    deal {
      id
      dealStages {
        id
        name
        index
      }
    }
    invites {
      id
      email
      role
      status
    }
    ndaDataRoomFile {
      id
      name
      fileType
    }
    ndaSignedAt
    ndaSignedBy {
      id
      name
    }
    granularAccess {
      files {
        id
        type
      }
      folders {
        id
        type
      }
    }
    dataRoomPermission {
      id
      name
      description
      accessLevel
      createdAt
      fileCount
    }
    accounts {
      id
      role
      createdAt
      account {
        id
        name
        email
        avatarColor
        avatar
      }
      overrideDataRoomAccess
      granularAccess {
        files {
          id
          type
        }
        folders {
          id
          type
        }
      }
    }
    dealFirms {
      id
      name
      type
      createdAt
      invites {
        id
        email
        role
        status
      }
      overrideDataRoomAccess
      granularAccess {
        files {
          id
          type
        }
        folders {
          id
          type
        }
      }
      dealAccounts {
        id
        role
        createdAt
        account {
          id
          name
          email
          avatarColor
          avatar
        }
        overrideDataRoomAccess
        granularAccess {
          files {
            id
            type
          }
          folders {
            id
            type
          }
        }
      }
    }
  }
}
    ${QuestionFragmentDoc}
${DueDiligenceChecklistItemFragmentDoc}`;

export const useDealGroupQuery = <TData = DealGroupQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DealGroupQueryVariables,
  options?: Omit<UseQueryOptions<DealGroupQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<DealGroupQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealGroupQuery, TError, TData>({
    queryKey: ["DealGroup", variables],
    queryFn: fetcher<DealGroupQuery, DealGroupQueryVariables>(
      client,
      DealGroupDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealInviteDocument = `
    query DealInvite($token: String!) {
  dealInvite(token: $token) {
    id
    email
    deal {
      id
      company {
        id
        name
      }
    }
    dealGroup {
      id
      name
      ndaDataRoomFile {
        id
        name
        fileType
        uploadStatus
        latestVersion {
          id
        }
      }
      dealAccessStatus
    }
    role
    invitedBy {
      id
      name
      email
      firm {
        id
        name
      }
    }
  }
}
    `;

export const useDealInviteQuery = <TData = DealInviteQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DealInviteQueryVariables,
  options?: Omit<
    UseQueryOptions<DealInviteQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DealInviteQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealInviteQuery, TError, TData>({
    queryKey: ["DealInvite", variables],
    queryFn: fetcher<DealInviteQuery, DealInviteQueryVariables>(
      client,
      DealInviteDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealPropertyDocument = `
    query DealProperty($id: String!) {
  dealProperty(id: $id) {
    id
    propertyKey
    createdBy {
      id
      name
      avatar
    }
    createdAt
    dealPropertyValues {
      id
      propertyValue
      deal {
        id
        company {
          id
          name
          logo
          logoColor
        }
      }
    }
  }
}
    `;

export const useDealPropertyQuery = <
  TData = DealPropertyQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DealPropertyQueryVariables,
  options?: Omit<
    UseQueryOptions<DealPropertyQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DealPropertyQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealPropertyQuery, TError, TData>({
    queryKey: ["DealProperty", variables],
    queryFn: fetcher<DealPropertyQuery, DealPropertyQueryVariables>(
      client,
      DealPropertyDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealThreadDocument = `
    query DealThread($id: String!) {
  dealThread(id: $id) {
    id
    createdBy {
      id
    }
    messages {
      id
      role
      status
      createdAt
      content {
        text {
          value
        }
        quotes {
          quote
          file {
            id
            name
            fileType
          }
          status
          rectsOnPage
          pageIndex
        }
        files {
          file {
            id
            name
            fileType
          }
          pageIndex
          rectsOnPage
        }
      }
    }
  }
}
    `;

export const useDealThreadQuery = <TData = DealThreadQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DealThreadQueryVariables,
  options?: Omit<
    UseQueryOptions<DealThreadQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DealThreadQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealThreadQuery, TError, TData>({
    queryKey: ["DealThread", variables],
    queryFn: fetcher<DealThreadQuery, DealThreadQueryVariables>(
      client,
      DealThreadDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealThreadMessageDocument = `
    query DealThreadMessage($id: String!) {
  dealThreadMessage(id: $id) {
    id
    role
    status
    createdAt
    content {
      text {
        value
      }
      quotes {
        quote
        file {
          id
          name
          fileType
        }
        status
        rectsOnPage
        pageIndex
      }
      files {
        file {
          id
          name
          fileType
        }
        pageIndex
        rectsOnPage
      }
    }
  }
}
    `;

export const useDealThreadMessageQuery = <
  TData = DealThreadMessageQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DealThreadMessageQueryVariables,
  options?: Omit<
    UseQueryOptions<DealThreadMessageQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      DealThreadMessageQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealThreadMessageQuery, TError, TData>({
    queryKey: ["DealThreadMessage", variables],
    queryFn: fetcher<DealThreadMessageQuery, DealThreadMessageQueryVariables>(
      client,
      DealThreadMessageDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DealThreadsDocument = `
    query DealThreads($dealId: String!) {
  dealThreads(dealID: $dealId) {
    id
    summary
    createdAt
  }
}
    `;

export const useDealThreadsQuery = <TData = DealThreadsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DealThreadsQueryVariables,
  options?: Omit<
    UseQueryOptions<DealThreadsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DealThreadsQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DealThreadsQuery, TError, TData>({
    queryKey: ["DealThreads", variables],
    queryFn: fetcher<DealThreadsQuery, DealThreadsQueryVariables>(
      client,
      DealThreadsDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DeepSearchDocument = `
    query DeepSearch($id: String!) {
  deepSearch(id: $id) {
    id
    name
    createdAt
    status
    vectorScope
    createdBy {
      id
      email
    }
    threadMessages {
      id
      role
      status
      files {
        id
        status
        file {
          id
          name
          fileType
        }
        fileVersion {
          id
          versionNumber
        }
      }
      content {
        type
        text
      }
    }
    files {
      id
      status
      hidden
      file {
        id
        name
        fileType
        createdAt
        uploadStatus
        currentLiveVersion {
          id
        }
      }
      sortIndex
      currentVersion {
        id
        versionNumber
      }
      references {
        id
        quote
        pageIndex
        rectsOnPage
        annotationID
      }
      currentVersion {
        id
      }
    }
  }
}
    `;

export const useDeepSearchQuery = <TData = DeepSearchQuery, TError = unknown>(
  client: GraphQLClient,
  variables: DeepSearchQueryVariables,
  options?: Omit<
    UseQueryOptions<DeepSearchQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DeepSearchQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DeepSearchQuery, TError, TData>({
    queryKey: ["DeepSearch", variables],
    queryFn: fetcher<DeepSearchQuery, DeepSearchQueryVariables>(
      client,
      DeepSearchDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DeepSearchFileDocument = `
    query DeepSearchFile($id: String!) {
  deepSearchFile(id: $id) {
    id
    status
    file {
      id
      name
      fileType
      createdAt
      uploadStatus
      versions {
        id
        createdAt
        summary
      }
    }
    references {
      quote
      pageIndex
      rectsOnPage
    }
  }
}
    `;

export const useDeepSearchFileQuery = <
  TData = DeepSearchFileQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DeepSearchFileQueryVariables,
  options?: Omit<
    UseQueryOptions<DeepSearchFileQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DeepSearchFileQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DeepSearchFileQuery, TError, TData>({
    queryKey: ["DeepSearchFile", variables],
    queryFn: fetcher<DeepSearchFileQuery, DeepSearchFileQueryVariables>(
      client,
      DeepSearchFileDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DeepSearchThreadMessagesDocument = `
    query DeepSearchThreadMessages($deepSearchId: String!) {
  deepSearch(id: $deepSearchId) {
    id
    threadMessages {
      id
      createdAt
      role
      status
      files {
        id
        status
        file {
          id
          name
          fileType
        }
        fileVersion {
          id
          versionNumber
        }
      }
      content {
        type
        text
      }
    }
  }
}
    `;

export const useDeepSearchThreadMessagesQuery = <
  TData = DeepSearchThreadMessagesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DeepSearchThreadMessagesQueryVariables,
  options?: Omit<
    UseQueryOptions<DeepSearchThreadMessagesQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      DeepSearchThreadMessagesQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DeepSearchThreadMessagesQuery, TError, TData>({
    queryKey: ["DeepSearchThreadMessages", variables],
    queryFn: fetcher<
      DeepSearchThreadMessagesQuery,
      DeepSearchThreadMessagesQueryVariables
    >(client, DeepSearchThreadMessagesDocument, variables, headers),
    ...options,
  });
};

export const DeepSearchThreadMessageDocument = `
    query DeepSearchThreadMessage($id: String!) {
  deepSearchThreadMessage(id: $id) {
    id
    role
    status
    files {
      id
      status
      file {
        id
        name
        fileType
      }
      fileVersion {
        id
        versionNumber
      }
    }
    content {
      type
      text
    }
  }
}
    `;

export const useDeepSearchThreadMessageQuery = <
  TData = DeepSearchThreadMessageQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DeepSearchThreadMessageQueryVariables,
  options?: Omit<
    UseQueryOptions<DeepSearchThreadMessageQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      DeepSearchThreadMessageQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DeepSearchThreadMessageQuery, TError, TData>({
    queryKey: ["DeepSearchThreadMessage", variables],
    queryFn: fetcher<
      DeepSearchThreadMessageQuery,
      DeepSearchThreadMessageQueryVariables
    >(client, DeepSearchThreadMessageDocument, variables, headers),
    ...options,
  });
};

export const DeepSearchThreadMessageFileDocument = `
    query DeepSearchThreadMessageFile($id: String!) {
  deepSearchThreadMessageFile(id: $id) {
    id
    status
    file {
      id
      fileType
      name
      currentLiveVersion {
        id
        versionNumber
      }
    }
    fileVersion {
      id
    }
    references {
      id
      quote
      pageIndex
      rects
    }
  }
}
    `;

export const useDeepSearchThreadMessageFileQuery = <
  TData = DeepSearchThreadMessageFileQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DeepSearchThreadMessageFileQueryVariables,
  options?: Omit<
    UseQueryOptions<DeepSearchThreadMessageFileQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      DeepSearchThreadMessageFileQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DeepSearchThreadMessageFileQuery, TError, TData>({
    queryKey: ["DeepSearchThreadMessageFile", variables],
    queryFn: fetcher<
      DeepSearchThreadMessageFileQuery,
      DeepSearchThreadMessageFileQueryVariables
    >(client, DeepSearchThreadMessageFileDocument, variables, headers),
    ...options,
  });
};

export const DeepSearchesDocument = `
    query DeepSearches($dealId: String!) {
  deepSearches(dealId: $dealId) {
    id
    name
    createdAt
    files {
      file {
        id
        name
        fileType
      }
    }
    createdBy {
      id
      email
    }
  }
}
    `;

export const useDeepSearchesQuery = <
  TData = DeepSearchesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: DeepSearchesQueryVariables,
  options?: Omit<
    UseQueryOptions<DeepSearchesQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<DeepSearchesQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<DeepSearchesQuery, TError, TData>({
    queryKey: ["DeepSearches", variables],
    queryFn: fetcher<DeepSearchesQuery, DeepSearchesQueryVariables>(
      client,
      DeepSearchesDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const DeleteAccessControlDocument = `
    mutation DeleteAccessControl($id: String!) {
  deleteAccessControl(id: $id) {
    success
  }
}
    `;

export const useDeleteAccessControlMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteAccessControlMutation,
    TError,
    DeleteAccessControlMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteAccessControlMutation,
    TError,
    DeleteAccessControlMutationVariables,
    TContext
  >({
    mutationKey: ["DeleteAccessControl"],
    mutationFn: (variables?: DeleteAccessControlMutationVariables) =>
      fetcher<
        DeleteAccessControlMutation,
        DeleteAccessControlMutationVariables
      >(client, DeleteAccessControlDocument, variables, headers)(),
    ...options,
  });
};

export const DeleteDataRoomFileDocument = `
    mutation DeleteDataRoomFile($input: DeleteDataRoomFileInput!) {
  deleteDataRoomFile(input: $input) {
    success
  }
}
    `;

export const useDeleteDataRoomFileMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteDataRoomFileMutation,
    TError,
    DeleteDataRoomFileMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteDataRoomFileMutation,
    TError,
    DeleteDataRoomFileMutationVariables,
    TContext
  >({
    mutationKey: ["DeleteDataRoomFile"],
    mutationFn: (variables?: DeleteDataRoomFileMutationVariables) =>
      fetcher<DeleteDataRoomFileMutation, DeleteDataRoomFileMutationVariables>(
        client,
        DeleteDataRoomFileDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const DeleteDataRoomFileVersionDocument = `
    mutation DeleteDataRoomFileVersion($input: DeleteDataRoomFileVersionInput!) {
  deleteDataRoomFileVersion(input: $input) {
    success
  }
}
    `;

export const useDeleteDataRoomFileVersionMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteDataRoomFileVersionMutation,
    TError,
    DeleteDataRoomFileVersionMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteDataRoomFileVersionMutation,
    TError,
    DeleteDataRoomFileVersionMutationVariables,
    TContext
  >({
    mutationKey: ["DeleteDataRoomFileVersion"],
    mutationFn: (variables?: DeleteDataRoomFileVersionMutationVariables) =>
      fetcher<
        DeleteDataRoomFileVersionMutation,
        DeleteDataRoomFileVersionMutationVariables
      >(client, DeleteDataRoomFileVersionDocument, variables, headers)(),
    ...options,
  });
};

export const DeleteDataRoomPermissionDocument = `
    mutation DeleteDataRoomPermission($id: String!) {
  deleteDataRoomPermission(id: $id) {
    id
  }
}
    `;

export const useDeleteDataRoomPermissionMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteDataRoomPermissionMutation,
    TError,
    DeleteDataRoomPermissionMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteDataRoomPermissionMutation,
    TError,
    DeleteDataRoomPermissionMutationVariables,
    TContext
  >({
    mutationKey: ["DeleteDataRoomPermission"],
    mutationFn: (variables?: DeleteDataRoomPermissionMutationVariables) =>
      fetcher<
        DeleteDataRoomPermissionMutation,
        DeleteDataRoomPermissionMutationVariables
      >(client, DeleteDataRoomPermissionDocument, variables, headers)(),
    ...options,
  });
};

export const DeleteDealInviteDocument = `
    mutation DeleteDealInvite($id: String!) {
  deleteDealInvite(id: $id) {
    success
  }
}
    `;

export const useDeleteDealInviteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteDealInviteMutation,
    TError,
    DeleteDealInviteMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteDealInviteMutation,
    TError,
    DeleteDealInviteMutationVariables,
    TContext
  >({
    mutationKey: ["DeleteDealInvite"],
    mutationFn: (variables?: DeleteDealInviteMutationVariables) =>
      fetcher<DeleteDealInviteMutation, DeleteDealInviteMutationVariables>(
        client,
        DeleteDealInviteDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const DeleteDeepSearchDocument = `
    mutation DeleteDeepSearch($id: String!) {
  deleteDeepSearch(id: $id) {
    success
  }
}
    `;

export const useDeleteDeepSearchMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteDeepSearchMutation,
    TError,
    DeleteDeepSearchMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteDeepSearchMutation,
    TError,
    DeleteDeepSearchMutationVariables,
    TContext
  >({
    mutationKey: ["DeleteDeepSearch"],
    mutationFn: (variables?: DeleteDeepSearchMutationVariables) =>
      fetcher<DeleteDeepSearchMutation, DeleteDeepSearchMutationVariables>(
        client,
        DeleteDeepSearchDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const DeleteDeepSearchFileReferenceDocument = `
    mutation DeleteDeepSearchFileReference($id: String!) {
  deleteDeepSearchFileReference(id: $id) {
    id
  }
}
    `;

export const useDeleteDeepSearchFileReferenceMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteDeepSearchFileReferenceMutation,
    TError,
    DeleteDeepSearchFileReferenceMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteDeepSearchFileReferenceMutation,
    TError,
    DeleteDeepSearchFileReferenceMutationVariables,
    TContext
  >({
    mutationKey: ["DeleteDeepSearchFileReference"],
    mutationFn: (variables?: DeleteDeepSearchFileReferenceMutationVariables) =>
      fetcher<
        DeleteDeepSearchFileReferenceMutation,
        DeleteDeepSearchFileReferenceMutationVariables
      >(client, DeleteDeepSearchFileReferenceDocument, variables, headers)(),
    ...options,
  });
};

export const DeleteFirmInviteDocument = `
    mutation DeleteFirmInvite($id: String!) {
  deleteFirmInvite(id: $id) {
    success
  }
}
    `;

export const useDeleteFirmInviteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteFirmInviteMutation,
    TError,
    DeleteFirmInviteMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteFirmInviteMutation,
    TError,
    DeleteFirmInviteMutationVariables,
    TContext
  >({
    mutationKey: ["DeleteFirmInvite"],
    mutationFn: (variables?: DeleteFirmInviteMutationVariables) =>
      fetcher<DeleteFirmInviteMutation, DeleteFirmInviteMutationVariables>(
        client,
        DeleteFirmInviteDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const DeleteIgnoreErrorRuleDocument = `
    mutation DeleteIgnoreErrorRule($id: String!) {
  deleteIgnoreErrorRule(id: $id) {
    success
  }
}
    `;

export const useDeleteIgnoreErrorRuleMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteIgnoreErrorRuleMutation,
    TError,
    DeleteIgnoreErrorRuleMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteIgnoreErrorRuleMutation,
    TError,
    DeleteIgnoreErrorRuleMutationVariables,
    TContext
  >({
    mutationKey: ["DeleteIgnoreErrorRule"],
    mutationFn: (variables?: DeleteIgnoreErrorRuleMutationVariables) =>
      fetcher<
        DeleteIgnoreErrorRuleMutation,
        DeleteIgnoreErrorRuleMutationVariables
      >(client, DeleteIgnoreErrorRuleDocument, variables, headers)(),
    ...options,
  });
};

export const DeleteQuestionDocument = `
    mutation DeleteQuestion($input: DeleteQuestionInput!) {
  deleteQuestion(input: $input) {
    success
  }
}
    `;

export const useDeleteQuestionMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteQuestionMutation,
    TError,
    DeleteQuestionMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    DeleteQuestionMutation,
    TError,
    DeleteQuestionMutationVariables,
    TContext
  >({
    mutationKey: ["DeleteQuestion"],
    mutationFn: (variables?: DeleteQuestionMutationVariables) =>
      fetcher<DeleteQuestionMutation, DeleteQuestionMutationVariables>(
        client,
        DeleteQuestionDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const FileDownloadUrlDocument = `
    query FileDownloadUrl($id: String!) {
  fileDownloadUrl(id: $id) {
    url
    downloadUrl
    viewUrl
  }
}
    `;

export const useFileDownloadUrlQuery = <
  TData = FileDownloadUrlQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: FileDownloadUrlQueryVariables,
  options?: Omit<
    UseQueryOptions<FileDownloadUrlQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<FileDownloadUrlQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<FileDownloadUrlQuery, TError, TData>({
    queryKey: ["FileDownloadUrl", variables],
    queryFn: fetcher<FileDownloadUrlQuery, FileDownloadUrlQueryVariables>(
      client,
      FileDownloadUrlDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const ErrorCheckDocument = `
    query ErrorCheck($id: String!) {
  errorCheck(id: $id) {
    id
    name
    createdAt
    createdBy {
      id
      email
    }
    files {
      file {
        id
        name
        fileType
        createdAt
        versions {
          id
          createdAt
          summary
        }
        errorCheckingStatus
      }
      currentVersion {
        id
      }
    }
    rows {
      key
      hasDiscrepancy
      values {
        dataRoomFileId
        value
        key
      }
    }
  }
}
    `;

export const useErrorCheckQuery = <TData = ErrorCheckQuery, TError = unknown>(
  client: GraphQLClient,
  variables: ErrorCheckQueryVariables,
  options?: Omit<
    UseQueryOptions<ErrorCheckQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<ErrorCheckQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<ErrorCheckQuery, TError, TData>({
    queryKey: ["ErrorCheck", variables],
    queryFn: fetcher<ErrorCheckQuery, ErrorCheckQueryVariables>(
      client,
      ErrorCheckDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const ErrorChecksDocument = `
    query ErrorChecks($dealId: String!) {
  errorChecks(dealId: $dealId) {
    id
    name
    createdAt
    files {
      file {
        id
        name
        fileType
      }
    }
    createdBy {
      id
      email
    }
  }
}
    `;

export const useErrorChecksQuery = <TData = ErrorChecksQuery, TError = unknown>(
  client: GraphQLClient,
  variables: ErrorChecksQueryVariables,
  options?: Omit<
    UseQueryOptions<ErrorChecksQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<ErrorChecksQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<ErrorChecksQuery, TError, TData>({
    queryKey: ["ErrorChecks", variables],
    queryFn: fetcher<ErrorChecksQuery, ErrorChecksQueryVariables>(
      client,
      ErrorChecksDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const FileVersionDownloadUrlDocument = `
    query FileVersionDownloadUrl($id: String!) {
  fileVersionDownloadUrl(id: $id) {
    url
    downloadUrl
    viewUrl
  }
}
    `;

export const useFileVersionDownloadUrlQuery = <
  TData = FileVersionDownloadUrlQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: FileVersionDownloadUrlQueryVariables,
  options?: Omit<
    UseQueryOptions<FileVersionDownloadUrlQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      FileVersionDownloadUrlQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<FileVersionDownloadUrlQuery, TError, TData>({
    queryKey: ["FileVersionDownloadUrl", variables],
    queryFn: fetcher<
      FileVersionDownloadUrlQuery,
      FileVersionDownloadUrlQueryVariables
    >(client, FileVersionDownloadUrlDocument, variables, headers),
    ...options,
  });
};

export const FileViewerDocument = `
    query FileViewer($dataRoomFileVersionId: String!, $dataRoomFileId: String!) {
  dataRoomFile(id: $dataRoomFileId) {
    id
    uploadStatus
  }
  dataRoomFileVersion(id: $dataRoomFileVersionId) {
    id
    pspdfkitDocumentId
    pspdfkitToken
  }
  fileVersionDownloadUrl(id: $dataRoomFileVersionId) {
    url
    downloadUrl
    viewUrl
  }
}
    `;

export const useFileViewerQuery = <TData = FileViewerQuery, TError = unknown>(
  client: GraphQLClient,
  variables: FileViewerQueryVariables,
  options?: Omit<
    UseQueryOptions<FileViewerQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<FileViewerQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<FileViewerQuery, TError, TData>({
    queryKey: ["FileViewer", variables],
    queryFn: fetcher<FileViewerQuery, FileViewerQueryVariables>(
      client,
      FileViewerDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const FinancialsDocument = `
    query Financials($dealId: String!) {
  deal(id: $dealId) {
    financialStatements {
      pnl {
        id
        latestVersion {
          ...PnlVersionFragment
        }
        versions {
          ...PnlVersionFragment
        }
        questions {
          id
          title
          body
          nodeId
        }
      }
    }
  }
}
    ${PnlVersionFragmentFragmentDoc}`;

export const useFinancialsQuery = <TData = FinancialsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: FinancialsQueryVariables,
  options?: Omit<
    UseQueryOptions<FinancialsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<FinancialsQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<FinancialsQuery, TError, TData>({
    queryKey: ["Financials", variables],
    queryFn: fetcher<FinancialsQuery, FinancialsQueryVariables>(
      client,
      FinancialsDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const FirmDocument = `
    query Firm($id: String!) {
  firm(id: $id) {
    id
    name
    description
    website
    logo
    address
    type
    accounts {
      id
      name
      email
      avatar
      avatarColor
      firmAccountType
    }
    invites {
      id
      email
      role
    }
  }
}
    `;

export const useFirmQuery = <TData = FirmQuery, TError = unknown>(
  client: GraphQLClient,
  variables: FirmQueryVariables,
  options?: Omit<UseQueryOptions<FirmQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FirmQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<FirmQuery, TError, TData>({
    queryKey: ["Firm", variables],
    queryFn: fetcher<FirmQuery, FirmQueryVariables>(
      client,
      FirmDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const FirmDealPropertiesDocument = `
    query FirmDealProperties($firmId: String!) {
  firm(id: $firmId) {
    dealProperties {
      id
      propertyKey
      createdAt
      createdBy {
        id
        name
        avatar
      }
    }
  }
}
    `;

export const useFirmDealPropertiesQuery = <
  TData = FirmDealPropertiesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: FirmDealPropertiesQueryVariables,
  options?: Omit<
    UseQueryOptions<FirmDealPropertiesQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      FirmDealPropertiesQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<FirmDealPropertiesQuery, TError, TData>({
    queryKey: ["FirmDealProperties", variables],
    queryFn: fetcher<FirmDealPropertiesQuery, FirmDealPropertiesQueryVariables>(
      client,
      FirmDealPropertiesDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const FirmFilesDocument = `
    query FirmFiles($id: String!) {
  firm(id: $id) {
    id
    name
    dataRoom {
      ...DataRoomFragment
    }
    allFolders {
      id
      name
    }
  }
}
    ${DataRoomFragmentFragmentDoc}`;

export const useFirmFilesQuery = <TData = FirmFilesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: FirmFilesQueryVariables,
  options?: Omit<UseQueryOptions<FirmFilesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FirmFilesQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<FirmFilesQuery, TError, TData>({
    queryKey: ["FirmFiles", variables],
    queryFn: fetcher<FirmFilesQuery, FirmFilesQueryVariables>(
      client,
      FirmFilesDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const FirmInviteDocument = `
    query FirmInvite($token: String!) {
  firmInvite(token: $token) {
    id
    email
    firm {
      id
      name
    }
    role
    invitedBy {
      id
      name
      email
    }
  }
}
    `;

export const useFirmInviteQuery = <TData = FirmInviteQuery, TError = unknown>(
  client: GraphQLClient,
  variables: FirmInviteQueryVariables,
  options?: Omit<
    UseQueryOptions<FirmInviteQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<FirmInviteQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<FirmInviteQuery, TError, TData>({
    queryKey: ["FirmInvite", variables],
    queryFn: fetcher<FirmInviteQuery, FirmInviteQueryVariables>(
      client,
      FirmInviteDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const GroupQuestionsDocument = `
    query GroupQuestions($dealId: String!) {
  deal(id: $dealId) {
    id
    activeDealAccount {
      dealGroup {
        id
      }
    }
    allGroups {
      id
      name
      questions {
        ...Question
      }
    }
  }
}
    ${QuestionFragmentDoc}`;

export const useGroupQuestionsQuery = <
  TData = GroupQuestionsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GroupQuestionsQueryVariables,
  options?: Omit<
    UseQueryOptions<GroupQuestionsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<GroupQuestionsQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<GroupQuestionsQuery, TError, TData>({
    queryKey: ["GroupQuestions", variables],
    queryFn: fetcher<GroupQuestionsQuery, GroupQuestionsQueryVariables>(
      client,
      GroupQuestionsDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const GuestAccessTabDocument = `
    query GuestAccessTab($dealId: String!) {
  deal(id: $dealId) {
    id
    activeDealAccount {
      id
      role
      dealGroup {
        id
        name
        accounts {
          ...DealAccount
        }
        dataRoomPermission {
          id
        }
        invites {
          id
          email
          createdAt
          role
          dealGroup {
            id
            name
          }
          status
          dataRoomPermission {
            id
            name
            description
            accessLevel
            fileCount
          }
          overrideDataRoomAccess
          granularAccess {
            files {
              id
              type
            }
            folders {
              id
              type
            }
          }
        }
      }
    }
  }
}
    ${DealAccountFragmentDoc}`;

export const useGuestAccessTabQuery = <
  TData = GuestAccessTabQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GuestAccessTabQueryVariables,
  options?: Omit<
    UseQueryOptions<GuestAccessTabQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<GuestAccessTabQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<GuestAccessTabQuery, TError, TData>({
    queryKey: ["GuestAccessTab", variables],
    queryFn: fetcher<GuestAccessTabQuery, GuestAccessTabQueryVariables>(
      client,
      GuestAccessTabDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const IgnoreErrorRulesDocument = `
    query IgnoreErrorRules($dealId: String!) {
  ignoreErrorRules(dealId: $dealId) {
    id
    key
    keyFiles {
      id
      name
      fileType
    }
    dataRoomFile {
      id
      name
      fileType
    }
    createdAt
  }
}
    `;

export const useIgnoreErrorRulesQuery = <
  TData = IgnoreErrorRulesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: IgnoreErrorRulesQueryVariables,
  options?: Omit<
    UseQueryOptions<IgnoreErrorRulesQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      IgnoreErrorRulesQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<IgnoreErrorRulesQuery, TError, TData>({
    queryKey: ["IgnoreErrorRules", variables],
    queryFn: fetcher<IgnoreErrorRulesQuery, IgnoreErrorRulesQueryVariables>(
      client,
      IgnoreErrorRulesDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const IgnoreFileForErrorDocument = `
    mutation IgnoreFileForError($dataRoomFileID: String!, $dataRoomFileErrorID: String!) {
  ignoreFileForError(
    dataRoomFileID: $dataRoomFileID
    dataRoomFileErrorID: $dataRoomFileErrorID
  ) {
    id
  }
}
    `;

export const useIgnoreFileForErrorMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    IgnoreFileForErrorMutation,
    TError,
    IgnoreFileForErrorMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    IgnoreFileForErrorMutation,
    TError,
    IgnoreFileForErrorMutationVariables,
    TContext
  >({
    mutationKey: ["IgnoreFileForError"],
    mutationFn: (variables?: IgnoreFileForErrorMutationVariables) =>
      fetcher<IgnoreFileForErrorMutation, IgnoreFileForErrorMutationVariables>(
        client,
        IgnoreFileForErrorDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const InternalAccessDocument = `
    query InternalAccess($dealId: String!) {
  deal(id: $dealId) {
    id
    runner
    company {
      id
      name
      logo
      logoColor
    }
    dataRoom {
      id
      permissions {
        id
        createdAt
        name
        description
        accessLevel
        fileCount
      }
    }
    ownerGroups {
      id
      name
      id
      name
      type
      dealAccessStatus
      overrideDataRoomAccess
      granularAccess {
        files {
          id
          type
        }
        folders {
          id
          type
        }
      }
      dataRoomPermission {
        id
        name
        description
        accessLevel
        fileCount
      }
      accounts {
        ...DealAccount
      }
    }
    activeDealAccount {
      id
      role
      dealGroup {
        id
      }
    }
  }
}
    ${DealAccountFragmentDoc}`;

export const useInternalAccessQuery = <
  TData = InternalAccessQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: InternalAccessQueryVariables,
  options?: Omit<
    UseQueryOptions<InternalAccessQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<InternalAccessQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<InternalAccessQuery, TError, TData>({
    queryKey: ["InternalAccess", variables],
    queryFn: fetcher<InternalAccessQuery, InternalAccessQueryVariables>(
      client,
      InternalAccessDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const LoginDocument = `
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    tokens {
      accessToken
      refreshToken
    }
  }
}
    `;

export const useLoginMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    LoginMutation,
    TError,
    LoginMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<LoginMutation, TError, LoginMutationVariables, TContext>({
    mutationKey: ["Login"],
    mutationFn: (variables?: LoginMutationVariables) =>
      fetcher<LoginMutation, LoginMutationVariables>(
        client,
        LoginDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const MarkNotificationAsReadDocument = `
    mutation MarkNotificationAsRead($id: String!) {
  markNotificationAsRead(id: $id) {
    id
    readAt
  }
}
    `;

export const useMarkNotificationAsReadMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    MarkNotificationAsReadMutation,
    TError,
    MarkNotificationAsReadMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    MarkNotificationAsReadMutation,
    TError,
    MarkNotificationAsReadMutationVariables,
    TContext
  >({
    mutationKey: ["MarkNotificationAsRead"],
    mutationFn: (variables?: MarkNotificationAsReadMutationVariables) =>
      fetcher<
        MarkNotificationAsReadMutation,
        MarkNotificationAsReadMutationVariables
      >(client, MarkNotificationAsReadDocument, variables, headers)(),
    ...options,
  });
};

export const MergeDocumentsDocument = `
    query MergeDocuments($dataRoomFileID1: String!, $dataRoomFileID2: String!) {
  dataRoomFile1: dataRoomFile(id: $dataRoomFileID1) {
    ...DataRoomFileFragment
  }
  dataRoomFile2: dataRoomFile(id: $dataRoomFileID2) {
    ...DataRoomFileFragment
  }
}
    ${DataRoomFileFragmentFragmentDoc}`;

export const useMergeDocumentsQuery = <
  TData = MergeDocumentsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: MergeDocumentsQueryVariables,
  options?: Omit<
    UseQueryOptions<MergeDocumentsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<MergeDocumentsQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<MergeDocumentsQuery, TError, TData>({
    queryKey: ["MergeDocuments", variables],
    queryFn: fetcher<MergeDocumentsQuery, MergeDocumentsQueryVariables>(
      client,
      MergeDocumentsDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const MoveDataRoomFileDocument = `
    mutation MoveDataRoomFile($input: MoveDataRoomFileInput!) {
  moveDataRoomFile(input: $input) {
    id
    name
  }
}
    `;

export const useMoveDataRoomFileMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    MoveDataRoomFileMutation,
    TError,
    MoveDataRoomFileMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    MoveDataRoomFileMutation,
    TError,
    MoveDataRoomFileMutationVariables,
    TContext
  >({
    mutationKey: ["MoveDataRoomFile"],
    mutationFn: (variables?: MoveDataRoomFileMutationVariables) =>
      fetcher<MoveDataRoomFileMutation, MoveDataRoomFileMutationVariables>(
        client,
        MoveDataRoomFileDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const MoveDataRoomFolderDocument = `
    mutation MoveDataRoomFolder($input: MoveDataRoomFolderInput!) {
  moveDataRoomFolder(input: $input) {
    id
    name
  }
}
    `;

export const useMoveDataRoomFolderMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    MoveDataRoomFolderMutation,
    TError,
    MoveDataRoomFolderMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    MoveDataRoomFolderMutation,
    TError,
    MoveDataRoomFolderMutationVariables,
    TContext
  >({
    mutationKey: ["MoveDataRoomFolder"],
    mutationFn: (variables?: MoveDataRoomFolderMutationVariables) =>
      fetcher<MoveDataRoomFolderMutation, MoveDataRoomFolderMutationVariables>(
        client,
        MoveDataRoomFolderDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const NotificationsDocument = `
    query Notifications {
  notifications {
    id
    title
    type
    message
    createdAt
    readAt
    deal {
      id
      company {
        name
        logoColor
        logo
      }
    }
    similarDataRoomFile {
      id
      dataRoomFile1 {
        id
        name
        fileType
      }
      dataRoomFile2 {
        id
        name
        fileType
      }
    }
    initiator {
      id
      firstName
      avatar
    }
    initiatorDealAccount {
      id
    }
    task {
      id
      title
      deal {
        id
        company {
          name
        }
      }
    }
    dataRoomFile {
      id
      name
      folderId
    }
  }
}
    `;

export const useNotificationsQuery = <
  TData = NotificationsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: NotificationsQueryVariables,
  options?: Omit<
    UseQueryOptions<NotificationsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<NotificationsQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<NotificationsQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ["Notifications"]
        : ["Notifications", variables],
    queryFn: fetcher<NotificationsQuery, NotificationsQueryVariables>(
      client,
      NotificationsDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const PnlVersionDocument = `
    query PnlVersion($id: String!) {
  pnlVersion(id: $id) {
    ...PnlVersionFragment
  }
}
    ${PnlVersionFragmentFragmentDoc}`;

export const usePnlVersionQuery = <TData = PnlVersionQuery, TError = unknown>(
  client: GraphQLClient,
  variables: PnlVersionQueryVariables,
  options?: Omit<
    UseQueryOptions<PnlVersionQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<PnlVersionQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<PnlVersionQuery, TError, TData>({
    queryKey: ["PnlVersion", variables],
    queryFn: fetcher<PnlVersionQuery, PnlVersionQueryVariables>(
      client,
      PnlVersionDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const QuestionDocument = `
    query Question($id: String!) {
  question(id: $id) {
    id
    title
    body
    createdAt
    answeredAt
    ddql {
      id
      name
    }
    dealGroup {
      id
      name
    }
    forDealGroup {
      id
      name
    }
    similarQuestions {
      id
      title
      body
      createdAt
      answeredAt
      createdBy {
        id
        name
        avatar
        avatarColor
      }
      answer {
        id
        answer
        answeredBy {
          id
          name
          avatar
          avatarColor
        }
        answerFiles {
          file {
            id
            name
            fileType
          }
          pageIndex
          rectsOnPage
        }
      }
    }
    forDealGroup {
      id
      name
    }
    activity {
      ...DealActivityFragment
    }
    dataRoomFile {
      id
      name
      fileType
    }
    suggestedAnswer {
      id
      status
      summary
      relevantFiles {
        id
        name
        fileType
      }
      references {
        file {
          id
          name
          fileType
          currentLiveVersion {
            id
          }
        }
        pageIndex
        rectsOnPage
        quote
      }
    }
    answer {
      id
      answer
      createdAt
      answeredBy {
        id
        name
        avatar
        avatarColor
      }
      answerFiles {
        file {
          id
          name
          fileType
        }
        pageIndex
        rectsOnPage
      }
      dataRoomFiles {
        id
        name
        fileType
      }
    }
    createdBy {
      id
      name
      avatar
      avatarColor
    }
  }
}
    ${DealActivityFragmentFragmentDoc}`;

export const useQuestionQuery = <TData = QuestionQuery, TError = unknown>(
  client: GraphQLClient,
  variables: QuestionQueryVariables,
  options?: Omit<UseQueryOptions<QuestionQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<QuestionQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<QuestionQuery, TError, TData>({
    queryKey: ["Question", variables],
    queryFn: fetcher<QuestionQuery, QuestionQueryVariables>(
      client,
      QuestionDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const QuestionAccessControlDocument = `
    query QuestionAccessControl($id: String!) {
  question(id: $id) {
    id
    accessControlDealGroups {
      ...AccessControlDealGroup
    }
  }
}
    ${AccessControlDealGroupFragmentDoc}`;

export const useQuestionAccessControlQuery = <
  TData = QuestionAccessControlQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: QuestionAccessControlQueryVariables,
  options?: Omit<
    UseQueryOptions<QuestionAccessControlQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      QuestionAccessControlQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<QuestionAccessControlQuery, TError, TData>({
    queryKey: ["QuestionAccessControl", variables],
    queryFn: fetcher<
      QuestionAccessControlQuery,
      QuestionAccessControlQueryVariables
    >(client, QuestionAccessControlDocument, variables, headers),
    ...options,
  });
};

export const QuestionsDocument = `
    query Questions($dealId: String!, $input: QuestionsInput!) {
  questions(dealId: $dealId, input: $input) {
    questions {
      ...Question
    }
  }
  ddqls(dealId: $dealId) {
    ...Ddql
  }
  deal(id: $dealId) {
    activeDealAccount {
      dealGroup {
        id
      }
    }
  }
}
    ${QuestionFragmentDoc}
${DdqlFragmentDoc}`;

export const useQuestionsQuery = <TData = QuestionsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: QuestionsQueryVariables,
  options?: Omit<UseQueryOptions<QuestionsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<QuestionsQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<QuestionsQuery, TError, TData>({
    queryKey: ["Questions", variables],
    queryFn: fetcher<QuestionsQuery, QuestionsQueryVariables>(
      client,
      QuestionsDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const RedlineExportsDocument = `
    query RedlineExports($id: String!) {
  redlineExport(id: $id) {
    id
    status
    s3PresignedDocXRedlineUrl
    s3PresignedDocXTrackedChangesUrl
    s3PresignedPdfRedlineUrl
  }
}
    `;

export const useRedlineExportsQuery = <
  TData = RedlineExportsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: RedlineExportsQueryVariables,
  options?: Omit<
    UseQueryOptions<RedlineExportsQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<RedlineExportsQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<RedlineExportsQuery, TError, TData>({
    queryKey: ["RedlineExports", variables],
    queryFn: fetcher<RedlineExportsQuery, RedlineExportsQueryVariables>(
      client,
      RedlineExportsDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const RemoveDealAccountDocument = `
    mutation RemoveDealAccount($id: String!) {
  removeDealAccount(id: $id) {
    success
  }
}
    `;

export const useRemoveDealAccountMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    RemoveDealAccountMutation,
    TError,
    RemoveDealAccountMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    RemoveDealAccountMutation,
    TError,
    RemoveDealAccountMutationVariables,
    TContext
  >({
    mutationKey: ["RemoveDealAccount"],
    mutationFn: (variables?: RemoveDealAccountMutationVariables) =>
      fetcher<RemoveDealAccountMutation, RemoveDealAccountMutationVariables>(
        client,
        RemoveDealAccountDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const RemoveDealFirmDocument = `
    mutation RemoveDealFirm($id: String!) {
  removeDealFirm(id: $id) {
    success
  }
}
    `;

export const useRemoveDealFirmMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    RemoveDealFirmMutation,
    TError,
    RemoveDealFirmMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    RemoveDealFirmMutation,
    TError,
    RemoveDealFirmMutationVariables,
    TContext
  >({
    mutationKey: ["RemoveDealFirm"],
    mutationFn: (variables?: RemoveDealFirmMutationVariables) =>
      fetcher<RemoveDealFirmMutation, RemoveDealFirmMutationVariables>(
        client,
        RemoveDealFirmDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const RemoveDealGroupDocument = `
    mutation RemoveDealGroup($id: String!) {
  removeDealGroup(id: $id) {
    success
  }
}
    `;

export const useRemoveDealGroupMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    RemoveDealGroupMutation,
    TError,
    RemoveDealGroupMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    RemoveDealGroupMutation,
    TError,
    RemoveDealGroupMutationVariables,
    TContext
  >({
    mutationKey: ["RemoveDealGroup"],
    mutationFn: (variables?: RemoveDealGroupMutationVariables) =>
      fetcher<RemoveDealGroupMutation, RemoveDealGroupMutationVariables>(
        client,
        RemoveDealGroupDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const ResetPasswordDocument = `
    mutation ResetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password) {
    id
    email
  }
}
    `;

export const useResetPasswordMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    ResetPasswordMutation,
    TError,
    ResetPasswordMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    ResetPasswordMutation,
    TError,
    ResetPasswordMutationVariables,
    TContext
  >({
    mutationKey: ["ResetPassword"],
    mutationFn: (variables?: ResetPasswordMutationVariables) =>
      fetcher<ResetPasswordMutation, ResetPasswordMutationVariables>(
        client,
        ResetPasswordDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const ResolveDataRoomFileErrorDocument = `
    mutation ResolveDataRoomFileError($input: ResolveDataRoomFileErrorInput!) {
  resolveDataRoomFileError(input: $input) {
    id
  }
}
    `;

export const useResolveDataRoomFileErrorMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    ResolveDataRoomFileErrorMutation,
    TError,
    ResolveDataRoomFileErrorMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    ResolveDataRoomFileErrorMutation,
    TError,
    ResolveDataRoomFileErrorMutationVariables,
    TContext
  >({
    mutationKey: ["ResolveDataRoomFileError"],
    mutationFn: (variables?: ResolveDataRoomFileErrorMutationVariables) =>
      fetcher<
        ResolveDataRoomFileErrorMutation,
        ResolveDataRoomFileErrorMutationVariables
      >(client, ResolveDataRoomFileErrorDocument, variables, headers)(),
    ...options,
  });
};

export const ResolveDataRoomFileSimilarityDocument = `
    mutation ResolveDataRoomFileSimilarity($acceptMerge: Boolean!, $dataRoomFileID1: String!, $dataRoomFileID2: String!, $reverseMerge: Boolean!) {
  resolveDataRoomFileSimilarity(
    acceptMerge: $acceptMerge
    dataRoomFileID1: $dataRoomFileID1
    dataRoomFileID2: $dataRoomFileID2
    reverseMerge: $reverseMerge
  ) {
    success
  }
}
    `;

export const useResolveDataRoomFileSimilarityMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    ResolveDataRoomFileSimilarityMutation,
    TError,
    ResolveDataRoomFileSimilarityMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    ResolveDataRoomFileSimilarityMutation,
    TError,
    ResolveDataRoomFileSimilarityMutationVariables,
    TContext
  >({
    mutationKey: ["ResolveDataRoomFileSimilarity"],
    mutationFn: (variables?: ResolveDataRoomFileSimilarityMutationVariables) =>
      fetcher<
        ResolveDataRoomFileSimilarityMutation,
        ResolveDataRoomFileSimilarityMutationVariables
      >(client, ResolveDataRoomFileSimilarityDocument, variables, headers)(),
    ...options,
  });
};

export const S3DdqlUploadCompleteDocument = `
    mutation S3DdqlUploadComplete($ddqlID: String!, $multiPartUploadID: String!, $parts: [MultipartPart!]!) {
  s3DdqlUploadComplete(
    ddqlID: $ddqlID
    multiPartUploadID: $multiPartUploadID
    parts: $parts
  ) {
    id
  }
}
    `;

export const useS3DdqlUploadCompleteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    S3DdqlUploadCompleteMutation,
    TError,
    S3DdqlUploadCompleteMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    S3DdqlUploadCompleteMutation,
    TError,
    S3DdqlUploadCompleteMutationVariables,
    TContext
  >({
    mutationKey: ["S3DdqlUploadComplete"],
    mutationFn: (variables?: S3DdqlUploadCompleteMutationVariables) =>
      fetcher<
        S3DdqlUploadCompleteMutation,
        S3DdqlUploadCompleteMutationVariables
      >(client, S3DdqlUploadCompleteDocument, variables, headers)(),
    ...options,
  });
};

export const S3FileUploadCompleteDocument = `
    mutation S3FileUploadComplete($dataRoomFileID: String!, $dataRoomFileVersionID: String!) {
  s3FileUploadComplete(
    dataRoomFileID: $dataRoomFileID
    dataRoomFileVersionID: $dataRoomFileVersionID
  ) {
    id
  }
}
    `;

export const useS3FileUploadCompleteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    S3FileUploadCompleteMutation,
    TError,
    S3FileUploadCompleteMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    S3FileUploadCompleteMutation,
    TError,
    S3FileUploadCompleteMutationVariables,
    TContext
  >({
    mutationKey: ["S3FileUploadComplete"],
    mutationFn: (variables?: S3FileUploadCompleteMutationVariables) =>
      fetcher<
        S3FileUploadCompleteMutation,
        S3FileUploadCompleteMutationVariables
      >(client, S3FileUploadCompleteDocument, variables, headers)(),
    ...options,
  });
};

export const S3MultipartUploadCompleteDocument = `
    mutation S3MultipartUploadComplete($dataRoomFileID: String!, $dataRoomFileVersionID: String!, $multiPartUploadID: String!, $parts: [MultipartPart!]!) {
  s3MultipartUploadComplete(
    dataRoomFileID: $dataRoomFileID
    dataRoomFileVersionID: $dataRoomFileVersionID
    multiPartUploadID: $multiPartUploadID
    parts: $parts
  ) {
    id
  }
}
    `;

export const useS3MultipartUploadCompleteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    S3MultipartUploadCompleteMutation,
    TError,
    S3MultipartUploadCompleteMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    S3MultipartUploadCompleteMutation,
    TError,
    S3MultipartUploadCompleteMutationVariables,
    TContext
  >({
    mutationKey: ["S3MultipartUploadComplete"],
    mutationFn: (variables?: S3MultipartUploadCompleteMutationVariables) =>
      fetcher<
        S3MultipartUploadCompleteMutation,
        S3MultipartUploadCompleteMutationVariables
      >(client, S3MultipartUploadCompleteDocument, variables, headers)(),
    ...options,
  });
};

export const SearchDeepSearchFilesDocument = `
    query SearchDeepSearchFiles($input: SearchDeepSearchFilesInput!) {
  searchDeepSearchFiles(input: $input) {
    matches {
      deepSearchFile {
        id
        file {
          id
          fileType
          name
        }
      }
      results {
        quote
        rectsOnPage
        pageIndex
      }
    }
  }
}
    `;

export const useSearchDeepSearchFilesQuery = <
  TData = SearchDeepSearchFilesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: SearchDeepSearchFilesQueryVariables,
  options?: Omit<
    UseQueryOptions<SearchDeepSearchFilesQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      SearchDeepSearchFilesQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<SearchDeepSearchFilesQuery, TError, TData>({
    queryKey: ["SearchDeepSearchFiles", variables],
    queryFn: fetcher<
      SearchDeepSearchFilesQuery,
      SearchDeepSearchFilesQueryVariables
    >(client, SearchDeepSearchFilesDocument, variables, headers),
    ...options,
  });
};

export const SearchFilesDocument = `
    query SearchFiles($query: String!, $dataRoomID: String!) {
  searchFiles(query: $query, dataRoomID: $dataRoomID) {
    id
    name
    fileType
    parentFolders {
      id
      name
    }
  }
}
    `;

export const useSearchFilesQuery = <TData = SearchFilesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: SearchFilesQueryVariables,
  options?: Omit<
    UseQueryOptions<SearchFilesQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<SearchFilesQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<SearchFilesQuery, TError, TData>({
    queryKey: ["SearchFiles", variables],
    queryFn: fetcher<SearchFilesQuery, SearchFilesQueryVariables>(
      client,
      SearchFilesDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const SendNotificationDocument = `
    mutation SendNotification($input: SendNotificationInput!) {
  sendNotification(input: $input) {
    success
    message
  }
}
    `;

export const useSendNotificationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    SendNotificationMutation,
    TError,
    SendNotificationMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    SendNotificationMutation,
    TError,
    SendNotificationMutationVariables,
    TContext
  >({
    mutationKey: ["SendNotification"],
    mutationFn: (variables?: SendNotificationMutationVariables) =>
      fetcher<SendNotificationMutation, SendNotificationMutationVariables>(
        client,
        SendNotificationDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const SendResetPasswordDocument = `
    mutation SendResetPassword($email: String!) {
  sendResetPassword(email: $email)
}
    `;

export const useSendResetPasswordMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    SendResetPasswordMutation,
    TError,
    SendResetPasswordMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    SendResetPasswordMutation,
    TError,
    SendResetPasswordMutationVariables,
    TContext
  >({
    mutationKey: ["SendResetPassword"],
    mutationFn: (variables?: SendResetPasswordMutationVariables) =>
      fetcher<SendResetPasswordMutation, SendResetPasswordMutationVariables>(
        client,
        SendResetPasswordDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const SimilarDataRoomFilesDocument = `
    query SimilarDataRoomFiles($dataRoomFileID: String, $dataRoomID: String) {
  similarDataRoomFiles(dataRoomFileID: $dataRoomFileID, dataRoomID: $dataRoomID) {
    id
    createdAt
    similarity
    status
    dataRoomFile1 {
      id
      name
      folderId
      createdAt
      fileType
      createdBy {
        id
        name
      }
    }
    dataRoomFile2 {
      id
      name
      folderId
      createdAt
      fileType
      createdBy {
        id
        name
      }
    }
  }
}
    `;

export const useSimilarDataRoomFilesQuery = <
  TData = SimilarDataRoomFilesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: SimilarDataRoomFilesQueryVariables,
  options?: Omit<
    UseQueryOptions<SimilarDataRoomFilesQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      SimilarDataRoomFilesQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<SimilarDataRoomFilesQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ["SimilarDataRoomFiles"]
        : ["SimilarDataRoomFiles", variables],
    queryFn: fetcher<
      SimilarDataRoomFilesQuery,
      SimilarDataRoomFilesQueryVariables
    >(client, SimilarDataRoomFilesDocument, variables, headers),
    ...options,
  });
};

export const StartDeepSearchDocument = `
    mutation StartDeepSearch($input: StartDeepSearchInput!) {
  startDeepSearch(input: $input) {
    id
  }
}
    `;

export const useStartDeepSearchMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    StartDeepSearchMutation,
    TError,
    StartDeepSearchMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    StartDeepSearchMutation,
    TError,
    StartDeepSearchMutationVariables,
    TContext
  >({
    mutationKey: ["StartDeepSearch"],
    mutationFn: (variables?: StartDeepSearchMutationVariables) =>
      fetcher<StartDeepSearchMutation, StartDeepSearchMutationVariables>(
        client,
        StartDeepSearchDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const TaskDocument = `
    query Task($id: String!) {
  task(id: $id) {
    id
    title
    description
    createdAt
    status
    priority
    createdBy {
      id
      name
      firstName
      avatar
    }
    activity {
      id
      type
      createdAt
      status
      priority
      task {
        id
        title
        priority
        deal {
          id
          company {
            id
            name
            logo
            logoColor
          }
        }
        assignedTo {
          id
          name
          avatar
          avatarColor
        }
      }
      account {
        id
        name
        avatar
        email
      }
      comment {
        comment
      }
    }
    dueDate
    assignedTo {
      id
      name
      email
      avatar
      avatarColor
    }
    deal {
      id
      company {
        id
        name
        logo
        logoColor
      }
    }
  }
}
    `;

export const useTaskQuery = <TData = TaskQuery, TError = unknown>(
  client: GraphQLClient,
  variables: TaskQueryVariables,
  options?: Omit<UseQueryOptions<TaskQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<TaskQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<TaskQuery, TError, TData>({
    queryKey: ["Task", variables],
    queryFn: fetcher<TaskQuery, TaskQueryVariables>(
      client,
      TaskDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const TaskActivityDocument = `
    query TaskActivity($filters: TaskActivityFilters!) {
  taskActivity(filters: $filters) {
    activities {
      id
      type
      createdAt
      dueDate
      deal {
        id
        company {
          id
          name
          logo
          logoColor
        }
      }
      assignedTo {
        id
        name
        avatar
        avatarColor
      }
      status
      priority
      task {
        id
        title
        assignedTo {
          id
          name
          avatar
          avatarColor
        }
        priority
        deal {
          id
          company {
            id
            name
            logo
            logoColor
          }
        }
      }
      account {
        id
        name
        avatar
        email
      }
      comment {
        comment
      }
    }
    nextCursor
    total
  }
}
    `;

export const useTaskActivityQuery = <
  TData = TaskActivityQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: TaskActivityQueryVariables,
  options?: Omit<
    UseQueryOptions<TaskActivityQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<TaskActivityQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<TaskActivityQuery, TError, TData>({
    queryKey: ["TaskActivity", variables],
    queryFn: fetcher<TaskActivityQuery, TaskActivityQueryVariables>(
      client,
      TaskActivityDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const TasksDocument = `
    query Tasks($firmID: String!, $filters: TasksFilters) {
  firm(id: $firmID) {
    id
    accounts {
      id
      name
      email
      avatar
    }
    deals {
      id
      status
      company {
        id
        name
        logo
        logoColor
      }
    }
  }
  tasks(firmID: $firmID, filters: $filters) {
    id
    title
    createdAt
    status
    priority
    sortOrder
    activity {
      id
      type
    }
    createdBy {
      id
      name
      firstName
      avatar
    }
    description
    dueDate
    assignedTo {
      id
      name
      email
      avatar
    }
    deal {
      id
      status
      company {
        id
        name
        logo
        logoColor
      }
    }
  }
}
    `;

export const useTasksQuery = <TData = TasksQuery, TError = unknown>(
  client: GraphQLClient,
  variables: TasksQueryVariables,
  options?: Omit<UseQueryOptions<TasksQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<TasksQuery, TError, TData>["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<TasksQuery, TError, TData>({
    queryKey: ["Tasks", variables],
    queryFn: fetcher<TasksQuery, TasksQueryVariables>(
      client,
      TasksDocument,
      variables,
      headers,
    ),
    ...options,
  });
};

export const TrackActivityDocument = `
    mutation TrackActivity($type: DealActivityType!, $fileId: String, $dataRoomId: String, $folderId: String, $targetAccountID: String) {
  trackActivity(
    type: $type
    fileId: $fileId
    dataRoomId: $dataRoomId
    folderId: $folderId
    targetAccountID: $targetAccountID
  ) {
    id
  }
}
    `;

export const useTrackActivityMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    TrackActivityMutation,
    TError,
    TrackActivityMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    TrackActivityMutation,
    TError,
    TrackActivityMutationVariables,
    TContext
  >({
    mutationKey: ["TrackActivity"],
    mutationFn: (variables?: TrackActivityMutationVariables) =>
      fetcher<TrackActivityMutation, TrackActivityMutationVariables>(
        client,
        TrackActivityDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UnreadNotificationCountDocument = `
    query UnreadNotificationCount {
  account {
    unreadNotificationCount
  }
}
    `;

export const useUnreadNotificationCountQuery = <
  TData = UnreadNotificationCountQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: UnreadNotificationCountQueryVariables,
  options?: Omit<
    UseQueryOptions<UnreadNotificationCountQuery, TError, TData>,
    "queryKey"
  > & {
    queryKey?: UseQueryOptions<
      UnreadNotificationCountQuery,
      TError,
      TData
    >["queryKey"];
  },
  headers?: RequestInit["headers"],
) => {
  return useQuery<UnreadNotificationCountQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ["UnreadNotificationCount"]
        : ["UnreadNotificationCount", variables],
    queryFn: fetcher<
      UnreadNotificationCountQuery,
      UnreadNotificationCountQueryVariables
    >(client, UnreadNotificationCountDocument, variables, headers),
    ...options,
  });
};

export const UpdateAccessControlDocument = `
    mutation UpdateAccessControl($input: UpdateAccessControlInput!) {
  updateAccessControl(input: $input) {
    id
    permissionLevel
  }
}
    `;

export const useUpdateAccessControlMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateAccessControlMutation,
    TError,
    UpdateAccessControlMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateAccessControlMutation,
    TError,
    UpdateAccessControlMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateAccessControl"],
    mutationFn: (variables?: UpdateAccessControlMutationVariables) =>
      fetcher<
        UpdateAccessControlMutation,
        UpdateAccessControlMutationVariables
      >(client, UpdateAccessControlDocument, variables, headers)(),
    ...options,
  });
};

export const UpdateAccountDocument = `
    mutation UpdateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    id
    name
  }
}
    `;

export const useUpdateAccountMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateAccountMutation,
    TError,
    UpdateAccountMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateAccountMutation,
    TError,
    UpdateAccountMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateAccount"],
    mutationFn: (variables?: UpdateAccountMutationVariables) =>
      fetcher<UpdateAccountMutation, UpdateAccountMutationVariables>(
        client,
        UpdateAccountDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateDataRoomDocument = `
    mutation UpdateDataRoom($input: UpdateDataRoomInput!) {
  updateDataRoom(input: $input) {
    id
    permissions {
      id
      name
      description
      accessLevel
    }
  }
}
    `;

export const useUpdateDataRoomMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDataRoomMutation,
    TError,
    UpdateDataRoomMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDataRoomMutation,
    TError,
    UpdateDataRoomMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDataRoom"],
    mutationFn: (variables?: UpdateDataRoomMutationVariables) =>
      fetcher<UpdateDataRoomMutation, UpdateDataRoomMutationVariables>(
        client,
        UpdateDataRoomDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateDataRoomFileDocument = `
    mutation UpdateDataRoomFile($input: UpdateDataRoomFileInput!) {
  updateDataRoomFile(input: $input) {
    id
    name
  }
}
    `;

export const useUpdateDataRoomFileMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDataRoomFileMutation,
    TError,
    UpdateDataRoomFileMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDataRoomFileMutation,
    TError,
    UpdateDataRoomFileMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDataRoomFile"],
    mutationFn: (variables?: UpdateDataRoomFileMutationVariables) =>
      fetcher<UpdateDataRoomFileMutation, UpdateDataRoomFileMutationVariables>(
        client,
        UpdateDataRoomFileDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateDataRoomFolderDocument = `
    mutation UpdateDataRoomFolder($input: UpdateDataRoomFolderInput!) {
  updateDataRoomFolder(input: $input) {
    id
    name
  }
}
    `;

export const useUpdateDataRoomFolderMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDataRoomFolderMutation,
    TError,
    UpdateDataRoomFolderMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDataRoomFolderMutation,
    TError,
    UpdateDataRoomFolderMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDataRoomFolder"],
    mutationFn: (variables?: UpdateDataRoomFolderMutationVariables) =>
      fetcher<
        UpdateDataRoomFolderMutation,
        UpdateDataRoomFolderMutationVariables
      >(client, UpdateDataRoomFolderDocument, variables, headers)(),
    ...options,
  });
};

export const UpdateDdqlDocument = `
    mutation UpdateDdql($input: UpdateDdqlInput!) {
  updateDdql(input: $input) {
    id
  }
}
    `;

export const useUpdateDdqlMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDdqlMutation,
    TError,
    UpdateDdqlMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDdqlMutation,
    TError,
    UpdateDdqlMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDdql"],
    mutationFn: (variables?: UpdateDdqlMutationVariables) =>
      fetcher<UpdateDdqlMutation, UpdateDdqlMutationVariables>(
        client,
        UpdateDdqlDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateDealDocument = `
    mutation UpdateDeal($input: UpdateDealInput!) {
  updateDeal(input: $input) {
    id
    status
  }
}
    `;

export const useUpdateDealMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDealMutation,
    TError,
    UpdateDealMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDealMutation,
    TError,
    UpdateDealMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDeal"],
    mutationFn: (variables?: UpdateDealMutationVariables) =>
      fetcher<UpdateDealMutation, UpdateDealMutationVariables>(
        client,
        UpdateDealDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateDealAccountDocument = `
    mutation UpdateDealAccount($input: UpdateDealAccountInput!) {
  updateDealAccount(input: $input) {
    account {
      id
    }
    role
  }
}
    `;

export const useUpdateDealAccountMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDealAccountMutation,
    TError,
    UpdateDealAccountMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDealAccountMutation,
    TError,
    UpdateDealAccountMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDealAccount"],
    mutationFn: (variables?: UpdateDealAccountMutationVariables) =>
      fetcher<UpdateDealAccountMutation, UpdateDealAccountMutationVariables>(
        client,
        UpdateDealAccountDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateDealFirmDocument = `
    mutation UpdateDealFirm($input: UpdateDealFirmInput!) {
  updateDealFirm(input: $input) {
    id
    name
  }
}
    `;

export const useUpdateDealFirmMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDealFirmMutation,
    TError,
    UpdateDealFirmMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDealFirmMutation,
    TError,
    UpdateDealFirmMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDealFirm"],
    mutationFn: (variables?: UpdateDealFirmMutationVariables) =>
      fetcher<UpdateDealFirmMutation, UpdateDealFirmMutationVariables>(
        client,
        UpdateDealFirmDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateDealGroupDocument = `
    mutation UpdateDealGroup($input: UpdateDealGroupInput!) {
  updateDealGroup(input: $input) {
    id
    name
  }
}
    `;

export const useUpdateDealGroupMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDealGroupMutation,
    TError,
    UpdateDealGroupMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDealGroupMutation,
    TError,
    UpdateDealGroupMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDealGroup"],
    mutationFn: (variables?: UpdateDealGroupMutationVariables) =>
      fetcher<UpdateDealGroupMutation, UpdateDealGroupMutationVariables>(
        client,
        UpdateDealGroupDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateDealPropertyDocument = `
    mutation UpdateDealProperty($input: UpdateDealPropertyInput!) {
  updateDealProperty(input: $input) {
    id
    propertyKey
    updatedAt
    createdAt
  }
}
    `;

export const useUpdateDealPropertyMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDealPropertyMutation,
    TError,
    UpdateDealPropertyMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDealPropertyMutation,
    TError,
    UpdateDealPropertyMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDealProperty"],
    mutationFn: (variables?: UpdateDealPropertyMutationVariables) =>
      fetcher<UpdateDealPropertyMutation, UpdateDealPropertyMutationVariables>(
        client,
        UpdateDealPropertyDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateDealPropertyValueDocument = `
    mutation UpdateDealPropertyValue($input: UpdateDealPropertyValueInput!) {
  updateDealPropertyValue(input: $input) {
    id
  }
}
    `;

export const useUpdateDealPropertyValueMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDealPropertyValueMutation,
    TError,
    UpdateDealPropertyValueMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDealPropertyValueMutation,
    TError,
    UpdateDealPropertyValueMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDealPropertyValue"],
    mutationFn: (variables?: UpdateDealPropertyValueMutationVariables) =>
      fetcher<
        UpdateDealPropertyValueMutation,
        UpdateDealPropertyValueMutationVariables
      >(client, UpdateDealPropertyValueDocument, variables, headers)(),
    ...options,
  });
};

export const UpdateDealPropertyValuesDocument = `
    mutation UpdateDealPropertyValues($input: [UpdateDealPropertyValueInput!]!) {
  updateDealPropertyValues(input: $input) {
    success
  }
}
    `;

export const useUpdateDealPropertyValuesMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDealPropertyValuesMutation,
    TError,
    UpdateDealPropertyValuesMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDealPropertyValuesMutation,
    TError,
    UpdateDealPropertyValuesMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDealPropertyValues"],
    mutationFn: (variables?: UpdateDealPropertyValuesMutationVariables) =>
      fetcher<
        UpdateDealPropertyValuesMutation,
        UpdateDealPropertyValuesMutationVariables
      >(client, UpdateDealPropertyValuesDocument, variables, headers)(),
    ...options,
  });
};

export const UpdateDeepSearchDocument = `
    mutation UpdateDeepSearch($input: UpdateDeepSearchInput!) {
  updateDeepSearch(input: $input) {
    id
  }
}
    `;

export const useUpdateDeepSearchMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDeepSearchMutation,
    TError,
    UpdateDeepSearchMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDeepSearchMutation,
    TError,
    UpdateDeepSearchMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDeepSearch"],
    mutationFn: (variables?: UpdateDeepSearchMutationVariables) =>
      fetcher<UpdateDeepSearchMutation, UpdateDeepSearchMutationVariables>(
        client,
        UpdateDeepSearchDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateDeepSearchFileDocument = `
    mutation UpdateDeepSearchFile($input: UpdateDeepSearchFileInput!) {
  updateDeepSearchFile(input: $input) {
    id
  }
}
    `;

export const useUpdateDeepSearchFileMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDeepSearchFileMutation,
    TError,
    UpdateDeepSearchFileMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDeepSearchFileMutation,
    TError,
    UpdateDeepSearchFileMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDeepSearchFile"],
    mutationFn: (variables?: UpdateDeepSearchFileMutationVariables) =>
      fetcher<
        UpdateDeepSearchFileMutation,
        UpdateDeepSearchFileMutationVariables
      >(client, UpdateDeepSearchFileDocument, variables, headers)(),
    ...options,
  });
};

export const UpdateDeepSearchFileReferenceDocument = `
    mutation UpdateDeepSearchFileReference($input: UpdateDeepSearchFileReferenceInput!) {
  updateDeepSearchFileReference(input: $input) {
    id
  }
}
    `;

export const useUpdateDeepSearchFileReferenceMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDeepSearchFileReferenceMutation,
    TError,
    UpdateDeepSearchFileReferenceMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDeepSearchFileReferenceMutation,
    TError,
    UpdateDeepSearchFileReferenceMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDeepSearchFileReference"],
    mutationFn: (variables?: UpdateDeepSearchFileReferenceMutationVariables) =>
      fetcher<
        UpdateDeepSearchFileReferenceMutation,
        UpdateDeepSearchFileReferenceMutationVariables
      >(client, UpdateDeepSearchFileReferenceDocument, variables, headers)(),
    ...options,
  });
};

export const UpdateDeepSearchFilesDocument = `
    mutation UpdateDeepSearchFiles($input: UpdateDeepSearchFilesInput!) {
  updateDeepSearchFiles(input: $input) {
    id
  }
}
    `;

export const useUpdateDeepSearchFilesMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDeepSearchFilesMutation,
    TError,
    UpdateDeepSearchFilesMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDeepSearchFilesMutation,
    TError,
    UpdateDeepSearchFilesMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDeepSearchFiles"],
    mutationFn: (variables?: UpdateDeepSearchFilesMutationVariables) =>
      fetcher<
        UpdateDeepSearchFilesMutation,
        UpdateDeepSearchFilesMutationVariables
      >(client, UpdateDeepSearchFilesDocument, variables, headers)(),
    ...options,
  });
};

export const UpdateDeepSearchThreadMessageDocument = `
    mutation UpdateDeepSearchThreadMessage($input: UpdateDeepSearchThreadMessageInput!) {
  updateDeepSearchThreadMessage(input: $input) {
    id
  }
}
    `;

export const useUpdateDeepSearchThreadMessageMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDeepSearchThreadMessageMutation,
    TError,
    UpdateDeepSearchThreadMessageMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDeepSearchThreadMessageMutation,
    TError,
    UpdateDeepSearchThreadMessageMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDeepSearchThreadMessage"],
    mutationFn: (variables?: UpdateDeepSearchThreadMessageMutationVariables) =>
      fetcher<
        UpdateDeepSearchThreadMessageMutation,
        UpdateDeepSearchThreadMessageMutationVariables
      >(client, UpdateDeepSearchThreadMessageDocument, variables, headers)(),
    ...options,
  });
};

export const UpdateDueDiligenceChecklistItemDocument = `
    mutation UpdateDueDiligenceChecklistItem($input: UpdateDueDiligenceChecklistItemInput!) {
  updateDueDiligenceChecklistItem(input: $input) {
    ...DueDiligenceChecklistItem
  }
}
    ${DueDiligenceChecklistItemFragmentDoc}`;

export const useUpdateDueDiligenceChecklistItemMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateDueDiligenceChecklistItemMutation,
    TError,
    UpdateDueDiligenceChecklistItemMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateDueDiligenceChecklistItemMutation,
    TError,
    UpdateDueDiligenceChecklistItemMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateDueDiligenceChecklistItem"],
    mutationFn: (
      variables?: UpdateDueDiligenceChecklistItemMutationVariables,
    ) =>
      fetcher<
        UpdateDueDiligenceChecklistItemMutation,
        UpdateDueDiligenceChecklistItemMutationVariables
      >(client, UpdateDueDiligenceChecklistItemDocument, variables, headers)(),
    ...options,
  });
};

export const UpdateErrorCheckDocument = `
    mutation UpdateErrorCheck($input: UpdateErrorCheckInput!) {
  updateErrorCheck(input: $input) {
    id
  }
}
    `;

export const useUpdateErrorCheckMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateErrorCheckMutation,
    TError,
    UpdateErrorCheckMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateErrorCheckMutation,
    TError,
    UpdateErrorCheckMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateErrorCheck"],
    mutationFn: (variables?: UpdateErrorCheckMutationVariables) =>
      fetcher<UpdateErrorCheckMutation, UpdateErrorCheckMutationVariables>(
        client,
        UpdateErrorCheckDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateFirmDocument = `
    mutation UpdateFirm($input: UpdateFirmInput!) {
  updateFirm(input: $input) {
    id
    name
  }
}
    `;

export const useUpdateFirmMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateFirmMutation,
    TError,
    UpdateFirmMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateFirmMutation,
    TError,
    UpdateFirmMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateFirm"],
    mutationFn: (variables?: UpdateFirmMutationVariables) =>
      fetcher<UpdateFirmMutation, UpdateFirmMutationVariables>(
        client,
        UpdateFirmDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateQuestionDocument = `
    mutation UpdateQuestion($input: UpdateQuestionInput!) {
  updateQuestion(input: $input) {
    id
  }
}
    `;

export const useUpdateQuestionMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateQuestionMutation,
    TError,
    UpdateQuestionMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateQuestionMutation,
    TError,
    UpdateQuestionMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateQuestion"],
    mutationFn: (variables?: UpdateQuestionMutationVariables) =>
      fetcher<UpdateQuestionMutation, UpdateQuestionMutationVariables>(
        client,
        UpdateQuestionDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateTaskDocument = `
    mutation UpdateTask($input: UpdateTaskInput!) {
  updateTask(input: $input) {
    id
    title
    description
    createdAt
    status
    createdBy {
      id
      name
      firstName
      avatar
    }
    dueDate
    assignedTo {
      id
      name
      email
      avatar
      avatarColor
    }
    deal {
      id
      company {
        id
        name
        logo
        logoColor
      }
    }
  }
}
    `;

export const useUpdateTaskMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateTaskMutation,
    TError,
    UpdateTaskMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateTaskMutation,
    TError,
    UpdateTaskMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateTask"],
    mutationFn: (variables?: UpdateTaskMutationVariables) =>
      fetcher<UpdateTaskMutation, UpdateTaskMutationVariables>(
        client,
        UpdateTaskDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};

export const UpdateTasksDocument = `
    mutation UpdateTasks($input: UpdateTasksInput!) {
  updateTasks(input: $input) {
    id
    title
    createdAt
    status
    priority
    sortOrder
    activity {
      id
      type
    }
    createdBy {
      id
      name
      firstName
      avatar
    }
    description
    dueDate
    assignedTo {
      id
      name
      email
      avatar
    }
    deal {
      id
      company {
        id
        name
        logo
        logoColor
      }
    }
  }
}
    `;

export const useUpdateTasksMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateTasksMutation,
    TError,
    UpdateTasksMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) => {
  return useMutation<
    UpdateTasksMutation,
    TError,
    UpdateTasksMutationVariables,
    TContext
  >({
    mutationKey: ["UpdateTasks"],
    mutationFn: (variables?: UpdateTasksMutationVariables) =>
      fetcher<UpdateTasksMutation, UpdateTasksMutationVariables>(
        client,
        UpdateTasksDocument,
        variables,
        headers,
      )(),
    ...options,
  });
};
