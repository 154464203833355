import { AddFileModal, SelectedFile } from "@/src/components/AddFileModal";
import { FileIcon } from "@/src/components/FileIcon";
import { classNames } from "@/src/utils/cn";
import {
  DealThreadMessageRole,
  DealThreadMessageStatus,
  DeepSearchFileStatus,
  FileType,
  useCreateDeepSearchThreadMessageMutation,
  useDeepSearchThreadMessagesQuery,
  DeepSearchThreadMessageFileQuery,
  DataRoomFile,
  useDeepSearchThreadMessageFileQuery,
  useStartDeepSearchMutation,
} from "@/src/graphql/generated";
import { Button } from "@/src/components/tailwind/Button";
import { deepSearchSelectors } from "@/src/store/deep-search/selector";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  BotIcon,
  PlayIcon,
  PlusIcon,
  XIcon,
  FilePlusIcon,
  EyeIcon,
} from "lucide-react";
import {
  useRef,
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useDispatch } from "react-redux";
import { AppState } from "@/src/store";
import { actions } from "@/src/store/deep-search/slice";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import useGqlClient from "@/src/hooks/useGqlClient";
import {
  Reference,
  ReferenceSlideOver,
} from "@/src/components/ReferenceSlideOver";
import { animated, SpringValue, useTransition } from "react-spring";
import { v4 as uuidv4 } from "uuid";
import { DeepSearchThreadMessageUpdatedData } from "@/src/contexts/websockets";
import Markdown from "react-markdown";
import { Loader } from "@/src/components/Loading";
import { toasts } from "@/src/components/toasts/toasts";
import { getUnixTime } from "date-fns";

export function EmptyState(props: { deepSearchId: string }) {
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [openModal, setOpenModal] = useState<"add-documents" | "">("");
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const startDeepSearch = useStartDeepSearchMutation(client);

  return (
    <div className="flex-1 h-full">
      <div className="px-8 my-4 max-w-4xl">
        <p className="text-lg text-gray-700 font-semibold">
          Welcome to Document Referencing
        </p>
        <p className="text-sm text-gray-500 mt-2">
          This tool allows you to load documents, or different versions of the
          same document and compare and contrast the key points / the sections
          you care about.
        </p>
        <p className="text-sm text-gray-500 mt-2">
          All references are saved so you can easily come back at a later date
          and quickly catch up on what you were looking at.
        </p>

        <p className="text-sm text-gray-500 mt-4">
          Once you've added some documents, you'll have a dedicated AI assistant
          that can help you find the key information you're looking for.
        </p>

        <p className="text-sm text-gray-500 mt-2">
          <span className="font-semibold">To get started,</span> add the
          documents you want or use the assistant to find them.
        </p>

        <p className="text-sm text-gray-500 mt-2">
          Once you have all the documents you need, go ahead and{" "}
          <span className="font-semibold">click the "Start" button</span> to
          begin.
        </p>
      </div>

      <div className="px-8">
        <p className="text-md font-semibold text-gray-700">
          Use the assistant to find documents
        </p>
        <div className="w-full mt-2 max-w-3xl h-96 overflow-hidden rounded-md border border-gray-200 bg-gray-100">
          <Assistant
            deepSearchId={props.deepSearchId}
            onAddDocument={(file) => {
              if (
                selectedFiles.filter(
                  (f) =>
                    f.file.id === file.id &&
                    f.version.id === file.currentLiveVersion.id,
                ).length > 0
              ) {
                return;
              }

              setSelectedFiles([
                ...selectedFiles,
                { file, version: file.currentLiveVersion },
              ]);
            }}
          />
        </div>
      </div>

      <div className="absolute  bottom-0  w-full">
        <div className="px-8 mb-2 flex items-center gap-x-2">
          <ArrowDownIcon className="w-4 h-4" />
          <p className="text-md font-semibold text-gray-700">
            Or add documents yourself here
          </p>
        </div>
        <div className="h-20 bg-white border-t border-gray-200 p-8">
          <div className="flex h-full items-center justify-between">
            <div className="flex items-center gap-x-2">
              <div className="max-w-3xl overflow-x-auto flex items-center no-scrollbar py-2 gap-x-2">
                {selectedFiles.map((file) => (
                  <LocalFilePill
                    key={file.file.id}
                    name={file.file.name}
                    fileType={file.file.fileType}
                    version={file.version.versionNumber}
                    isLiveVersion={
                      file.file.currentLiveVersion.id === file.version.id
                    }
                    onRemove={() => {
                      setSelectedFiles(
                        selectedFiles.filter(
                          (f) =>
                            f.file.id !== file.file.id &&
                            f.version.id !== file.version.id,
                        ),
                      );
                    }}
                  />
                ))}
              </div>
              <Button
                onClick={() => {
                  setOpenModal("add-documents");
                }}
                variant="neutral"
                size="s"
                text={selectedFiles.length === 0 ? "Add documents" : ""}
                icon={PlusIcon}
              />
            </div>
            <Button
              isDisabled={selectedFiles.length === 0}
              icon={PlayIcon}
              variant="positive"
              text="Start"
              loadingText="Starting..."
              isLoading={startDeepSearch.isPending}
              onClick={() => {
                if (startDeepSearch.isPending) {
                  return;
                }

                startDeepSearch.mutate(
                  {
                    input: {
                      id: props.deepSearchId,
                      files: selectedFiles.map((sf) => {
                        return {
                          dataRoomFileID: sf.file.id,
                          dataRoomFileVersionID: sf.version.id,
                          deepSearchID: props.deepSearchId,
                          references: [],
                        };
                      }),
                    },
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: ["DeepSearch", { id: props.deepSearchId }],
                      });
                      queryClient.invalidateQueries({
                        queryKey: ["DeepSearches", {}],
                      });
                    },
                    onError: () => {
                      toasts.error("Failed to start document referencing");
                    },
                  },
                );
              }}
            />
          </div>
        </div>
      </div>

      <AddFileModal
        mode="versions"
        open={openModal === "add-documents"}
        onClose={() => setOpenModal("")}
        onFilesSelected={(files) => {
          setSelectedFiles([...selectedFiles, ...files]);
          setOpenModal("");
        }}
      />
    </div>
  );
}

interface ScrollContextType {
  triggerScroll: () => void;
}

export const ScrollContext = createContext<ScrollContextType>({
  triggerScroll: () => {},
});

function Assistant(props: {
  deepSearchId: string;
  onAddDocument: (file: DataRoomFile) => void;
}) {
  const chatRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [message, setMessage] = useState("");

  const scrollToBottom = useCallback(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, []);

  const deepSearchThreadMessages = useSelector((state: AppState) =>
    deepSearchSelectors.deepSearchThreadMessages(state, props.deepSearchId),
  );

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const deepSearchThreadMessagesQuery = useDeepSearchThreadMessagesQuery(
    client,
    {
      deepSearchId: props.deepSearchId,
    },
  );

  useEffect(() => {
    if (deepSearchThreadMessagesQuery.data) {
      dispatch(
        actions.setDeepSearchThreadMessages({
          deepSearchId: props.deepSearchId,
          messages:
            deepSearchThreadMessagesQuery.data.deepSearch.threadMessages.map(
              (m) => {
                return {
                  deepSearchId: props.deepSearchId,
                  deepSearchThreadMessageID: m.id,
                  deepSearchThreadMessage: {
                    id: m.id,
                    createdAt: getUnixTime(m.createdAt),
                    status: m.status,
                    role: m.role,
                    content: {
                      status: m.status,
                      role: m.role,
                      type: "text",
                      text: {
                        value: m.content.text,
                      },
                    },
                  },
                  deepSearchThreadMessageFileIds: m.files.map((f) => f.id),
                };
              },
            ),
        }),
      );
    }
  }, [deepSearchThreadMessagesQuery.data, dispatch, props.deepSearchId]);

  const createDeepSearchThreadMessage =
    useCreateDeepSearchThreadMessageMutation(client);

  useEffect(() => {
    scrollToBottom();
  }, [deepSearchThreadMessages, scrollToBottom]);

  const transitions = useTransition(deepSearchThreadMessages, {
    from: { transform: "translate3d(0,40px,0)", opacity: 0 },
    enter: { transform: "translate3d(0,0px,0)", opacity: 1 },
    leave: { transform: "translate3d(0,-40px,0)", opacity: 0 },
    keys: (message) => (message as any).deepSearchThreadMessageID,
  });

  const deepSearchThreadMessageIndexMap = useMemo(() => {
    return deepSearchThreadMessages.reduce(
      (acc, message, index) => {
        if (!message.deepSearchThreadMessageID) {
          return acc;
        }
        acc[message.deepSearchThreadMessageID] = index;
        return acc;
      },
      {} as { [key: string]: number },
    );
  }, [deepSearchThreadMessages]);

  return (
    <ScrollContext.Provider value={{ triggerScroll: scrollToBottom }}>
      <div ref={chatRef} className={classNames("flex flex-1 flex-col h-full")}>
        <div className="w-full flex items-center justify-between bg-concrete-50 border-b border-concrete-200 px-3 py-2 rounded-t-lg">
          <div className="flex items-center gap-2">
            <BotIcon className="h-4 w-4 text-gray-700" />
            <p className="font-semibold text-sm text-gray-700">Assistant</p>
          </div>
        </div>

        <div className="flex flex-1  overflow-y-auto">
          <div className="space-y-4 p-4 flex-1 overflow-y-scroll">
            {transitions((style, message) => {
              if (!message.deepSearchThreadMessage) {
                return null;
              }

              return (
                <AssistantMessage
                  style={style}
                  deepSearchId={props.deepSearchId}
                  deepThreadMessageId={message.deepSearchThreadMessageID}
                  index={
                    deepSearchThreadMessageIndexMap[
                      message.deepSearchThreadMessageID
                    ]
                  }
                  isLast={
                    deepSearchThreadMessageIndexMap[
                      message.deepSearchThreadMessageID
                    ] ===
                    deepSearchThreadMessages.length - 1
                  }
                  onAddDocument={(file) => {
                    props.onAddDocument(file);
                  }}
                />
              );
            })}
            <div ref={scrollRef} />
          </div>
          {/* {deepSearchThreadFileIds.length > 0 ? (
              <Sidebar
                deepSearchThreadFileIds={deepSearchThreadFileIds}
                onDocumentAdded={() => {}}
                deepSearchId={props.deepSearchId}
              />
            ) : null} */}
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            const newId = uuidv4();
            dispatch(
              actions.addDeepThreadMessage({
                deepSearchId: props.deepSearchId,
                message: {
                  deepSearchId: props.deepSearchId,
                  deepSearchThreadMessageID: newId,
                  deepSearchThreadMessageFileIds: [],
                  deepSearchThreadMessage: {
                    id: newId,
                    createdAt: getUnixTime(new Date()),
                    content: {
                      type: "text",
                      text: { value: message },
                      role: DealThreadMessageRole.User,
                      status: DealThreadMessageStatus.Sending,
                    },
                    role: DealThreadMessageRole.User,
                    status: DealThreadMessageStatus.Sending,
                  },
                },
              }),
            );
            setMessage("");
            createDeepSearchThreadMessage.mutate(
              {
                input: {
                  deepSearchID: props.deepSearchId,
                  message: message,
                  id: newId,
                },
              },
              {
                onError: () => {
                  dispatch(
                    actions.updateDeepSearchThreadMessage({
                      deepSearchId: props.deepSearchId,
                      messageId: newId,
                      message: {
                        deepSearchId: props.deepSearchId,
                        deepSearchThreadMessageID: newId,
                        deepSearchThreadMessageFileIds: [],
                        deepSearchThreadMessage: {
                          id: newId,
                          createdAt: getUnixTime(new Date()),
                          status: DealThreadMessageStatus.Failed,
                          role: DealThreadMessageRole.User,
                          content: {
                            type: "text",
                            text: { value: message },
                            role: DealThreadMessageRole.User,
                            status: DealThreadMessageStatus.Failed,
                          },
                        },
                      },
                    }),
                  );
                },
                onSuccess: (res) => {
                  deepSearchThreadMessagesQuery.refetch();

                  queryClient.invalidateQueries({
                    queryKey: ["DeepSearch", props.deepSearchId],
                  });
                  dispatch(
                    actions.updateDeepSearchThreadMessage({
                      deepSearchId: props.deepSearchId,
                      messageId: newId,
                      message: {
                        deepSearchId: props.deepSearchId,
                        deepSearchThreadMessageID: newId,
                        deepSearchThreadMessageFileIds: [],
                        deepSearchThreadMessage: {
                          id: newId,
                          createdAt: getUnixTime(new Date()),
                          content: {
                            type: "text",
                            text: { value: message },
                            role: DealThreadMessageRole.User,
                            status: DealThreadMessageStatus.Sent,
                          },
                          role: DealThreadMessageRole.User,
                          status: DealThreadMessageStatus.Sent,
                        },
                      },
                    }),
                  );
                },
              },
            );
          }}
          className={classNames(
            " p-3 flex items-center gap-x-2 flex-shrink-0",
            "border-t border-concrete-200 bg-concrete-50 rounded-b-lg",
          )}
        >
          <input
            type="text"
            className={classNames(
              "flex-1 border-0 text-gray-900 placeholder:text-gray-400 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6 bg-white",
              "rounded-full py-1.5 px-4 ring-1 ring-inset ring-gray-300",
            )}
            placeholder="I'm looking for all documents that mention the closing clauses for the deal..."
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
          />
          <button
            className={classNames(
              "p-1.5 bg-persian-600 hover:bg-persian-700 rounded-full shadow-sm",
              message.length > 0
                ? "opacity-100"
                : "opacity-50 cursor-not-allowed",
            )}
          >
            <ArrowUpIcon className="h-4 w-4 text-white" />
          </button>
        </form>
      </div>
    </ScrollContext.Provider>
  );
}
function LocalFilePill(props: {
  name: string;
  fileType: string;
  version?: number;
  isLiveVersion: boolean;
  onRemove: () => void;
}) {
  return (
    <div className="group cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
      <FileIcon size="s" fileType={props.fileType as FileType} />
      <div className="ml-2">
        <p className="flex items-center gap-x-1 text-xs leading-tight  text-gray-800 truncate">
          {props.name}
          {props.version ? (
            <>
              <span className="ml-1 text-xs items-center gap-x-1 text-gray-500 leading-tight">
                Version {props.version}
              </span>
              <span
                className={classNames(
                  "w-1 h-1 rounded-full",
                  props.isLiveVersion ? "bg-green-500" : "bg-gray-300",
                )}
              ></span>
            </>
          ) : null}
        </p>
      </div>
      <button
        className="absolute -top-2 -right-2 opacity-0 bg-gray-200 p-0.5 hover:bg-gray-300 rounded-full group-hover:opacity-100"
        onClick={props.onRemove}
      >
        <XIcon className="w-3 h-3" />
      </button>
    </div>
  );
}

export function AssistantMessage(props: {
  deepSearchId: string;
  deepThreadMessageId: string;
  style: {
    transform: SpringValue<string>;
    opacity: SpringValue<number>;
  };
  index: number;
  isLast: boolean;
  onAddDocument: (file: DataRoomFile) => void;
}) {
  const message = useSelector((state: AppState) =>
    deepSearchSelectors.deepThreadMessage(
      state,
      props.deepSearchId,
      props.deepThreadMessageId,
    ),
  );

  if (!message) return null;

  if (message.deepSearchThreadMessage.role === DealThreadMessageRole.Ai) {
    return (
      <AiMessage
        message={message}
        style={props.style}
        isLast={props.isLast}
        onAddDocument={props.onAddDocument}
      />
    );
  }

  return (
    <animated.div
      style={props.style}
      key={message.deepSearchThreadMessageID}
      className={classNames("flex items-center justify-end space-x-2")}
    >
      <div className="flex items-center gap-x-2">
        <div className="bg-persian-100/70 max-w-xs shadow rounded-md p-2 my-2">
          <p className="text-sm text-gray-700">
            {message.deepSearchThreadMessage.content.text.value}
          </p>
        </div>
      </div>
    </animated.div>
  );
}

function AiMessage(props: {
  message: DeepSearchThreadMessageUpdatedData;
  style: {
    transform: SpringValue<string>;
    opacity: SpringValue<number>;
  };
  isLast: boolean;
  onAddDocument: (file: DataRoomFile) => void;
}) {
  const { triggerScroll } = useContext(ScrollContext);
  const message = props.message;
  const [displayedText, setDisplayedText] = useState("");
  const [isRevealing, setIsRevealing] = useState(false);

  useEffect(() => {
    if (
      message.deepSearchThreadMessage.content.text.value.length >
      displayedText.length
    ) {
      setIsRevealing(true);
    }
  }, [message.deepSearchThreadMessage.content.text.value, displayedText]);

  // 3. Reveal text character by character
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRevealing && props.isLast) {
      intervalId = setInterval(() => {
        setDisplayedText((prev) => {
          // If we've caught up, stop
          if (
            prev.length >=
            message.deepSearchThreadMessage.content.text.value.length
          ) {
            clearInterval(intervalId);
            setIsRevealing(false);
            return prev;
          }
          // Reveal the next character
          return (
            prev +
            message.deepSearchThreadMessage.content.text.value[prev.length]
          );
        });
        triggerScroll();
      }, 15); // Adjust typing speed (ms) as needed
    } else {
      setDisplayedText(message.deepSearchThreadMessage.content.text.value);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isRevealing, message.deepSearchThreadMessage.content.text.value]);

  if (
    message.deepSearchThreadMessage.status === DealThreadMessageStatus.Failed
  ) {
    return (
      <animated.div
        style={props.style}
        key={message.deepSearchThreadMessageID}
        className="flex items-end justify-start space-x-2 my-2"
      >
        <BotIcon className="w-4 h-4 text-gray-500" />
        <div className="bg-gray-50 max-w-xs shadow rounded-md p-2 ">
          <p className="text-sm text-gray-500">Failed to generate response</p>
        </div>
      </animated.div>
    );
  }

  return (
    <animated.div
      style={props.style}
      key={message.deepSearchThreadMessageID}
      className="flex items-end justify-start space-x-2 my-2"
    >
      <BotIcon className="w-4 h-4 text-gray-500" />
      <div className="bg-gray-50 w-2/3 shadow rounded-md p-2 ">
        {message.deepSearchThreadMessage.content.text.value === "" ? (
          <Loader width={16} height={16} />
        ) : (
          <>
            <p className="whitespace-pre-wrap text-gray-700 text-sm">
              <Markdown>
                {message.deepSearchThreadMessage.content.text.value}
              </Markdown>
            </p>
          </>
        )}
        <div className="space-y-2 mt-2">
          {message.deepSearchThreadMessageFileIds.map((id, index) => (
            <div key={id}>
              <DeepSearchThreadMessageFile
                id={id}
                deepSearchId={props.message.deepSearchId}
                onAddDocument={props.onAddDocument}
              />
            </div>
          ))}
        </div>
      </div>
    </animated.div>
  );
}

function DeepSearchThreadMessageFile(props: {
  id: string;
  deepSearchId: string;
  onAddDocument: (file: DataRoomFile) => void;
}) {
  const client = useGqlClient();
  const deepSearchThreadMessageFileQuery = useDeepSearchThreadMessageFileQuery(
    client,
    {
      id: props.id,
    },
    {
      refetchInterval(query) {
        if (
          !query.state.data ||
          query.state.data.deepSearchThreadMessageFile.status !==
            DeepSearchFileStatus.Complete
        ) {
          return 1000;
        }
        return false;
      },
    },
  );

  return (
    <div className="p-2 rounded-md border border-gray-200 bg-white text-wrap">
      <DeepSearchThreadMessageFileContent
        query={deepSearchThreadMessageFileQuery}
        deepSearchId={props.deepSearchId}
        onAddDocument={props.onAddDocument}
      />
    </div>
  );
}

function DeepSearchThreadMessageFileContent(props: {
  query: UseQueryResult<DeepSearchThreadMessageFileQuery, unknown>;
  deepSearchId: string;
  onAddDocument: (file: DataRoomFile) => void;
}) {
  const [selectedReference, setSelectedReference] = useState<
    Reference | undefined
  >(undefined);

  if (props.query.error) {
    return (
      <div>
        <p className="text-xs text-gray-700">Failed to load file</p>
      </div>
    );
  }

  if (props.query.isLoading || !props.query.data) {
    return (
      <div className="flex items-center gap-x-2">
        <FileIcon size="s" fileType={FileType.Other} />
        <p className="text-xs text-gray-700">Loading...</p>
      </div>
    );
  }

  const data = props.query.data;

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <FileIcon
            size="s"
            fileType={data.deepSearchThreadMessageFile.file.fileType}
          />
          <p className="text-sm font-semibold text-gray-700">
            {data.deepSearchThreadMessageFile.file.name}
          </p>
        </div>
      </div>

      <div className="mt-1 flex items-center gap-x-2">
        <button
          onClick={() => {
            props.onAddDocument(
              data.deepSearchThreadMessageFile.file as DataRoomFile,
            );
          }}
          className="flex items-center gap-x-1 mt-2 px-2 py-1 rounded-full border border-gray-300 bg-gray-50 text-xs text-gray-700 hover:shadow-sm hover:border-gray-500 hover:bg-gray-50 transition-all duration-300"
        >
          <FilePlusIcon className="w-3 h-3 text-gray-700" />
          Add document
        </button>

        <button
          onClick={() => {
            setSelectedReference({
              file: data.deepSearchThreadMessageFile.file as DataRoomFile,
              fileVersionId: data.deepSearchThreadMessageFile.fileVersion.id,
              pageIndex: 0,
              quote: "",
              rectsOnPage: [],
            });
          }}
          className="flex items-center gap-x-1 mt-2 px-2 py-1 rounded-full border border-gray-300 bg-gray-50 text-xs text-gray-700 hover:shadow-sm hover:border-gray-500 hover:bg-gray-50 transition-all duration-300"
        >
          <EyeIcon className="w-3 h-3 text-gray-700" />
          Quick view
        </button>
      </div>

      <ReferenceSlideOver
        open={!!selectedReference}
        onClose={() => setSelectedReference(undefined)}
        reference={selectedReference}
      />
    </div>
  );
}
