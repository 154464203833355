import { DealThreadMessageCreatedData } from "@/src/contexts/websockets";
import { AppState } from "..";

function dealThreadMessages(
  state: AppState,
  id: string,
): DealThreadMessageCreatedData[] {
  if (!id) {
    return [];
  }

  return state.assistant.dealThreads[id];
}

function activeDealThreadId(state: AppState): string | undefined {
  return state.assistant.activeDealThreadId;
}

function assistantPanelState(state: AppState): "pinned" | "unpinned" {
  return state.assistant.assistantPanelState;
}

export const assistantSelectors = {
  dealThreadMessages,
  activeDealThreadId,
  assistantPanelState,
};
