import { NavLink, Route, Switch, useParams } from "react-router-dom";
import { useMergeDocumentsQuery } from "../../../../graphql/generated";
import useGqlClient from "../../../../hooks/useGqlClient";
import { classNames } from "@/src/utils/cn";
import { Overview } from "./Overview";
import { Comparison } from "./Comparison";

export function MergeDocumentsDetails() {
  const client = useGqlClient();
  const { fileId1, fileId2 } = useParams<{
    fileId1: string;
    fileId2: string;
  }>();

  const mergeDocumentsQuery = useMergeDocumentsQuery(client, {
    dataRoomFileID1: fileId1,
    dataRoomFileID2: fileId2,
  });

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col flex-1">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-1 px-2 mt-2" aria-label="Tabs">
            <NavLink
              exact
              to={`/deal/toolkit/merge-documents/${fileId1}/${fileId2}`}
              className={(isActive) =>
                classNames(
                  isActive
                    ? "bg-white text-gray-900 rounded-t-lg border-t border-l border-r border-gray-200"
                    : "bg-gray-50 text-gray-500 hover:text-gray-700 hover:bg-gray-100",
                  "whitespace-nowrap py-3 px-6 font-medium text-sm relative",
                  isActive ? "mb-[-1px]" : "",
                )
              }
            >
              Overview
            </NavLink>
            <NavLink
              to={`/deal/toolkit/merge-documents/${fileId1}/${fileId2}/comparison`}
              className={(isActive) =>
                classNames(
                  isActive
                    ? "bg-white text-gray-900 rounded-t-lg border-t border-l border-r border-gray-200"
                    : "bg-gray-50 text-gray-500 hover:text-gray-700 hover:bg-gray-100",
                  "whitespace-nowrap py-3 px-6 font-medium text-sm relative",
                  isActive ? "mb-[-1px]" : "",
                )
              }
            >
              Comparison
            </NavLink>
          </nav>
        </div>

        <div className="flex flex-col flex-1">
          <Switch>
            <Route path="/deal/toolkit/merge-documents/:fileId1/:fileId2" exact>
              <Overview query={mergeDocumentsQuery} />
            </Route>
            <Route path="/deal/toolkit/merge-documents/:fileId1/:fileId2/comparison">
              <Comparison query={mergeDocumentsQuery} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
