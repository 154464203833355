import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { classNames } from "../utils/cn";

export function TripleDotMenu(props: {
  children: React.ReactNode;
  width?: string;
  customButton?: React.ReactNode;
  mode?: "default" | "table";
}) {
  const width = props.width ? props.width : "w-32";

  if (props.mode === "table") {
    return (
      <Menu as="div" className="relative flex-none">
        <Menu.Button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
          }}
          className="right-0 opacity-40 group-hover:opacity-100 -m-2.5 block p-2.5 text-gray-700 hover:text-gray-900"
        >
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-4 w-4" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute right-0 z-10 mt-2 ${width} origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none `}
          >
            {props.children}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  return (
    <Menu as="div" className="relative flex-none ">
      {props.customButton ? (
        props.customButton
      ) : (
        <Menu.Button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
          }}
          className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-900"
        >
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      )}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute right-0 z-50 mt-2 ${width} origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none `}
        >
          {props.children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

// Add Button subcomponent
TripleDotMenu.Button = function TripleDotMenuButton(props: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  text: string;
  variant?: "danger" | "normal" | "positive";
  icon?: React.ReactNode;
}) {
  const variantClasses = {
    danger: "text-red-500 hover:text-red-600",
    normal: "text-gray-500 hover:text-gray-600",
    positive: "text-blue-500 hover:text-blue-600",
  };

  return (
    <button
      type="button"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        props.onClick(e);
      }}
      className={classNames(
        "px-2.5 py-2 hover:bg-gray-100 w-full text-left text-sm flex items-center gap-x-2",
        variantClasses[props.variant ?? "normal"]
      )}
    >
      {props.icon && <>{props.icon}</>}
      {props.text}
    </button>
  );
};
