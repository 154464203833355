import { Card } from "@/src/components/Card";
import {
  useDealPropertyQuery,
  useDealQuery,
  useUpdateDealPropertyValuesMutation,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";
import { useMemo, type ChangeEvent, useEffect, useState } from "react";
import { classNames } from "@/src/utils/cn";
import { TextInput } from "@/src/components/tailwind/TextInput";
import { Button } from "@/src/components/tailwind/Button";
import { toasts } from "@/src/components/toasts/toasts";

export const DealPropertyValue = () => {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const { data } = useDealQuery(client, { id: activeDealId ?? "" });
  const { mutate } = useUpdateDealPropertyValuesMutation(client);

  const dealPropertyValues = useMemo(() => {
    return [...(data?.deal?.dealPropertyValues ?? [])].sort(
      (a, b) => (a?.createdAt ?? 0) - (b?.createdAt ?? 0)
    );
  }, [data]);

  const [propertyValues, setPropertyValues] = useState<Record<string, string>>(
    {}
  );
  const [loading, setLoading] = useState(false);

  let initialDealProperties = useMemo(() => {
    if (dealPropertyValues.length > 0) {
      return dealPropertyValues.reduce<Record<string, string>>((acc, dp) => {
        acc[dp.id] = dp.propertyValue ?? "";
        return acc;
      }, {});
    }
    return {};
  }, [dealPropertyValues]);

  useEffect(() => {
    if (dealPropertyValues.length > 0) {
      setPropertyValues(initialDealProperties);
    }
  }, [dealPropertyValues, initialDealProperties]);

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    setPropertyValues((prev) => ({
      ...prev,
      [id]: e.target.value,
    }));
  };

  const hasChanged = useMemo(() => {
    return (
      JSON.stringify(initialDealProperties) !== JSON.stringify(propertyValues)
    );
  }, [propertyValues, initialDealProperties]);

  const handleUpdate = () => {
    if (loading) return;
    setLoading(true);

    //separate the changes
    const changes = Object.entries(propertyValues)
      .map(([key, value]) => ({ id: key, propertyValue: value }))
      .filter(
        ({ id, propertyValue }) => initialDealProperties[id] !== propertyValue
      );

    mutate(
      {
        input: changes,
      },
      {
        onSuccess: () => {
          for (const { id, propertyValue } of changes) {
            initialDealProperties[id] = propertyValue;
            setPropertyValues(initialDealProperties);
          }
          setLoading(false);
          toasts.success("Deal properties updated");
        },
        onError: (e) => {
          setLoading(false);
          toasts.error("Failed to update deal properties");
        },
      }
    );
  };

  if (data?.deal.dealPropertyValues.length === 0) {
    return (
      <section>
        <h1>No Deal Properties Yet!</h1>
      </section>
    );
  }

  return (
    <div className="w-full xl:w-1/2">
      <p className="font-semibold text-gray-800">Custom properties</p>
      <Card margin="m 0 0 0" padding="m">
        {dealPropertyValues.map((dpv, i) => (
          <div
            className={classNames(
              "flex items-center justify-between border py-3 border-gray-200 p-2",
              i === 0 ? "rounded-t-md" : "",
              i === dealPropertyValues.length - 1 ? "rounded-b-md" : "",
              i > 0 ? "border-t-0" : ""
            )}
            key={dpv.id}
          >
            <p className="font-semibold text-gray-700 text-md">
              {dpv.propertyKey}
            </p>
            <div className="w-1/2">
              <DealPropertyValueInput
                disabled={loading}
                value={propertyValues[dpv.id] ?? ""}
                onChange={(e) => handleTextChange(e, dpv.id)}
                propertyId={dpv.dealProperty.id}
                deapPropertyValueId={dpv.id}
              />
            </div>
          </div>
        ))}

        <div className="mt-4 flex justify-end gap-x-2">
          <Button
            variant="neutral"
            text="Discard changes"
            onClick={() => setPropertyValues(initialDealProperties)}
            isDisabled={!hasChanged}
          />

          <Button
            variant="positive"
            isDisabled={!hasChanged}
            onClick={handleUpdate}
            text="Save"
            isLoading={loading}
            loadingText="Saving..."
          />
        </div>
      </Card>
    </div>
  );
};

function DealPropertyValueInput(props: {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  propertyId: string;
  deapPropertyValueId: string;
}) {
  const client = useGqlClient();
  const dealPropertyQuery = useDealPropertyQuery(client, {
    id: props.propertyId,
  });

  const [showSuggestions, setShowSuggestions] = useState(false);

  return (
    <div className="relative">
      <TextInput
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        onFocus={() => setShowSuggestions(true)}
        onBlur={() => {
          setTimeout(() => setShowSuggestions(false), 200);
        }}
      />
      {dealPropertyQuery.data &&
        dealPropertyQuery.data.dealProperty.dealPropertyValues.filter(
          (dpv) =>
            dpv.propertyValue.length > 0 &&
            dpv.id !== props.deapPropertyValueId &&
            dpv.propertyValue.toLowerCase().includes(props.value.toLowerCase())
        ).length > 0 &&
        showSuggestions && (
          <div
            className={`absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 block`}
          >
            <p className="text-sm text-gray-500 p-2">Previous values</p>
            <ul className="py-1 max-h-60 overflow-auto">
              {dealPropertyQuery.data.dealProperty.dealPropertyValues
                .filter(
                  (v) =>
                    v.propertyValue &&
                    v.propertyValue
                      .toLowerCase()
                      .includes(props.value.toLowerCase())
                )
                .map((value) => (
                  <li
                    key={value.id}
                    className="px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      const e = {
                        target: {
                          value: value.propertyValue || "",
                        },
                      } as ChangeEvent<HTMLInputElement>;
                      props.onChange(e);
                    }}
                  >
                    {value.propertyValue}
                  </li>
                ))}
            </ul>
          </div>
        )}
    </div>
  );
}
