import {
  DocumentIcon,
  DocumentChartBarIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/solid";
import { FileType } from "../graphql/generated";
import { useTheme } from "../hooks/useTheme";

interface FileIconProps {
  fileType: FileType;
  size?: "s" | "m" | "l";
  mode?: "tailwind" | "innerHtml";
}

const sizes = {
  s: 15,
  m: 20,
  l: 30,
};

export function FileIcon(props: FileIconProps) {
  const theme = useTheme();

  switch (props.fileType) {
    case FileType.Pdf:
      return (
        <DocumentIcon
          style={
            props.mode === "innerHtml"
              ? {
                  color: "#b91c1c",
                  opacity: 0.7,
                  flexShrink: 0,
                }
              : {}
          }
          className="text-red-700/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    case FileType.Xlsx:
      return (
        <DocumentIcon
          style={
            props.mode === "innerHtml"
              ? {
                  color: "#15803d",
                  opacity: 0.7,
                  flexShrink: 0,
                }
              : {}
          }
          className="text-green-700/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    case FileType.Docx:
      return (
        <DocumentIcon
          style={
            props.mode === "innerHtml"
              ? {
                  color: "#1d4ed8",
                  opacity: 0.7,
                  flexShrink: 0,
                }
              : {}
          }
          className="text-blue-700/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    case FileType.Pptx:
      return (
        <DocumentChartBarIcon
          className="text-pink-600/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    case FileType.Zip:
      return (
        <ArchiveBoxIcon
          className="text-gray-700/70 flex-shrink-0"
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
        />
      );
    default:
      return (
        <DocumentIcon
          width={props.size ? sizes[props.size] : sizes["m"]}
          height={props.size ? sizes[props.size] : sizes["m"]}
          fill={theme.color.typography.secondary}
        />
      );
  }
}
